import state from "../state/state";
import _ from "lodash";

import { getSelectedNodePath, saveUndoState } from "./actions";
import { generateUUID } from "../utils/utils";
import { showError } from "./alertActions";

export const COMPARISON_OPERATOR = "comparisonOperator";
export const LOGICAL_OPERATOR = "logicalOperator";
export const ARITHMETIC_OPERATOR = "artithmeticOperator";
export const ID_REF = "idRef"; // variable
export const VALUE = "value";

const PROP_IFTTT = "ifttt";

const sampleSchema = { // eslint-disable-line
	id: "myCalcId",
	definition: {
		type: "logicalOperator",
		value: "and",
		id: "and1",
		items: [
			{
				type: "comparisonOperator",
				value: "equals",
				id: "equals1",
				items: [
					{
						type: "idRef",
						value: "mySampleId",
						id: "idRef1"
					},
					{
						type: "value",
						value: "Some Value",
						id: "value1"
					}
				]
			},
			{
				type: "comparisonOperator",
				value: "equals",
				id: "equals2",
				items: [
					{
						type: "idRef",
						value: "mySampleId2",
						id: "idRef2"
					},
					{
						type: "value",
						value: "Some Other Value",
						id: "value2"
					}
				]
			}
		]
	}
};

export function getBlankLogicComparator(value = "and") {
	return {
		type: LOGICAL_OPERATOR,
		value,
		id: generateUUID(),
		items: []
	};
}

export function getBlankVisibleIf(ignoreEmptyValues = false) {
	const wrappedItem = getBlankLogicComparator("and");

	wrappedItem.items.push(getBlankComparisonOperator(ignoreEmptyValues));

	return wrappedItem;
}

export function getBlankComparisonOperator(ignoreEmptyValues = false) {
	return {
		type: COMPARISON_OPERATOR,
		config: {
			ignoreEmptyValues: ignoreEmptyValues
		},
		value: "equals",
		id: generateUUID(),
		items: [
			getBlankIdRef(),
			getBlankValue()
		]
	};
}

export function getBlankArithemticOperator(value = "add") {
	return {
		type: ARITHMETIC_OPERATOR,
		value,
		id: generateUUID(),
		items: [
			getBlankIdRef()
		]
	};
}

function getBlankIdRef(value = "") {
	return {
		type: ID_REF,
		value,
		id: generateUUID()
	};
}

function getBlankValue(value = "") {
	return {
		type: VALUE,
		value,
		id: generateUUID()
	};
}

function getBlankDefaultItem(type) {
	switch (type) {
		case LOGICAL_OPERATOR: {
			return getBlankLogicComparator();
		}
		case COMPARISON_OPERATOR: {
			return getBlankComparisonOperator(true);
		}
		case ARITHMETIC_OPERATOR: {
			return getBlankArithemticOperator();
		}
		case VALUE: {
			return getBlankValue();
		}
		case ID_REF: {
			return getBlankIdRef();
		}
		default: {
			console.error("Expected either", LOGICAL_OPERATOR, COMPARISON_OPERATOR);
		}
	}
}

export function handleWrapInLogicComparator(prop, calcAtomId) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	const fullPath = _.concat(getSelectedNodePath(), "entity", prop, "definition", path);
	const item = state.get(fullPath); // Replace the item with a wrapped version
	const wrappedItem = getBlankLogicComparator("and");
	wrappedItem.items.push(item);
	// This is the top level
	state.set(fullPath, wrappedItem);
	saveUndoState();
}

export function handleAdd(prop, calcAtomId, addType) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	const fullPath = _.concat(getSelectedNodePath(), "entity", prop, "definition", path);
	const item = state.get(fullPath); // Replace the item with a wrapped version
	// Verify that this is a logicOperator ... only one you can add things to
	if (addType === LOGICAL_OPERATOR || addType === COMPARISON_OPERATOR) {
		if (item.type !== "logicalOperator") {
			showError("Add Item", "Unable to add this item, has to be added to a logic operator such as AND/OR.");
		} else {
			// Ok proceed
			const newComponent = getBlankDefaultItem(addType);
			state.push(_.concat(getSelectedNodePath(), "entity", prop, "definition", path, "items"), newComponent);
		}
	} else {
		if (item.type !== ARITHMETIC_OPERATOR) {
			showError("Add Item", "Unable to add this item, has to be added to a arithemtic operator such as Add, Subtract, ...");
		} else {
			const newComponent = getBlankDefaultItem(addType);
			state.push(_.concat(getSelectedNodePath(), "entity", prop, "definition", path, "items"), newComponent);
		}
	}
	saveUndoState();
}

export function handleChangeType(prop, calcAtomId, newType) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	state.set(_.concat(getSelectedNodePath(), "entity", prop, "definition", path), getBlankDefaultItem(newType));
	state.commit();
	saveUndoState();
}

export function handleDelete(prop, calcAtomId) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	const fullPath = _.concat(getSelectedNodePath(), "entity", prop, "definition", path);

	if (_.last(fullPath) === "definition") {
		// Unset the whole prop
		const targetPath = _.isArray(prop) && _.indexOf(prop, "ifThis") !== -1 ? prop.slice(0, _.indexOf(prop, "ifThis")) : prop;
		console.log("Handle delete", _.concat(getSelectedNodePath(), "entity", targetPath));
		state.unset(_.concat(getSelectedNodePath(), "entity", targetPath));
	} else {
		state.unset(fullPath); // Replace the item with a wrapped version
	}
	saveUndoState();

}

export function handleChangeConfig(prop, calcAtomId, newVal) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	state.set(_.concat(getSelectedNodePath(), "entity", prop, "definition", path, "config"), newVal);
	state.commit();
	saveUndoState();
}

export function handleChangeValue(prop, calcAtomId, newVal) {
	const path = findCalculationAtomPathById(prop, calcAtomId);
	state.set(_.concat(getSelectedNodePath(), "entity", prop, "definition", path, "value"), newVal);
	state.commit();
	saveUndoState();
}

function findCalculationAtomPathById(prop, calcAtomId) {
	const nodePath = getSelectedNodePath();
	const tree = state;
	const calculation = tree.get(_.concat(nodePath, "entity", prop));
	return findPathToAtom(calculation.definition, calcAtomId);
}

function findPathToAtom(scope, id, basePath = []) {
	if (scope) {
		if (scope.id === id) {
			return basePath;
		} else if (scope.hasOwnProperty("items")) {
			let result = null;
			scope.items.forEach((item, idx) => {
				if (!result) {
					let newPath = Object.assign([], basePath);
					newPath.push("items", idx);
					result = findPathToAtom(item, id, newPath);
				}
			});
			return result;
		}
	}
}

export function fixIftttStructureIds(obj) {
	if (obj && _.isObject(obj)) {
		if (obj.hasOwnProperty(PROP_IFTTT)) {
			recurseIftttStructureAndReplaceIds(obj[PROP_IFTTT], obj);
		}

		_.keys(obj).forEach(key => {
			if (_.isObject(obj[key]) && obj[key].hasOwnProperty("type") && obj[key].type === PROP_IFTTT) {
				console.log("IFTTT Fixing object ....", JSON.stringify(obj[key]));
				const iftttObj = obj[key];
				if (iftttObj.ifThis && iftttObj.ifThis.type === "calculation" && iftttObj.thenThat && iftttObj.thenThat.type === "rule") {
					// This means that we have a scenario where an id link exists - calculation id becomes source id for rule
					iftttObj.id = generateUUID();
					const calcId = generateUUID();
					iftttObj.ifThis.id = calcId;
					iftttObj.thenThat.sourceId = calcId;
					iftttObj.thenThat.id = generateUUID();
				}
				recurseIftttStructureAndReplaceIds(obj[key], obj);
				console.log("IFTTT object fixed ....", JSON.stringify(obj[key]));
			}
		});

	}
	return obj;
}

function recurseIftttStructureAndReplaceIds(obj, skipId = false) {
	if (!skipId && obj.hasOwnProperty("id")) {
		obj.id = generateUUID();
	}

	if (obj.type === PROP_IFTTT) {
		recurseIftttStructureAndReplaceIds(obj.ifThis, true);
		recurseIftttStructureAndReplaceIds(obj.thenThat);
	}

	if (obj.hasOwnProperty("definition")) {
		recurseIftttStructureAndReplaceIds(obj.definition);
	}

	if (obj.hasOwnProperty("items")) {
		obj.items.forEach(item => {
			recurseIftttStructureAndReplaceIds(item);
		});
	}

	if (_.isArray(obj)) {
		obj.forEach(_obj => {
			recurseIftttStructureAndReplaceIds(_obj);
		});
	}
}
import React from "react";
import NavItems from "./app-nav-items.js";
import { ConnectionStatusIndicator } from "../shared/";

export default class NavPanel extends React.Component {
	render() {
		return (
			<div style={{ width: "200px", maxWidth: "200px", minWidth: "200px", backgroundColor: "rgba(255,255,255,.7)", height: "inherit", overflow: "auto", position: "relative", display:"flex", flexDirection:"column", justifyContent:"space-between" }}>
				<NavItems onFilter={this.props.onFilter} onFilterEnd={this.props.onFilterEnd} />
				<div style={{ width: "inherit", backgroundColor: "rgba(0,0,0,.5)", padding:"10px", color:"white" }}>
					<ConnectionStatusIndicator />
				</div>
			</div>
		);
	}
}
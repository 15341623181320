import React from "react";
import Modal from "react-responsive-modal";

import _ from "lodash";

import { getImageSize } from "../../utils/utils";

import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setImageHotspots, setImageKenBurnsEffect } from "../../actions/actions";

import globals from "../../constants/env";
import ColorPicker from "../shared/colorPicker";

import MarkupImageProperties from "./MarkupImageProperties";

import { v4 as uuidv4 } from 'uuid';

import AudioWaveform from "./AudioWaveform/AudioWaveform";
import { getUrlForValue } from "../../actions/drive";

import { getLs, setLs } from "../../utils/utils";

import { showEditorSpinner, saveChecklist } from "../../actions/actions";

import state from "../../state/state";
import { showSaveActivityError } from "../../actions/alertActions";

const imageOffset = 150;

export default class MarkupImageModal extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		// Support single object
		let imageKenBurnsEffect = props.imageKenBurnsEffect;

		let newImageKenBurnsEffect = null;

		if (imageKenBurnsEffect && imageKenBurnsEffect.hasOwnProperty("startTime")) {
			// If there is a hotspot use that as a base
			if (props.imageHotspots && props.imageHotspots.length > 0) {
				newImageKenBurnsEffect = [Object.assign({}, {
					id: "camera1",
					startTime: 0,
					endTime: 0,
					duration: 0,
					camera: {
						end: {
							x: props.imageHotspots[0].coords.x - 75,
							y: props.imageHotspots[0].coords.y - 150,
							width: props.imageHotspots[0].coords.width + 150,
							height: props.imageHotspots[0].coords.height + 300
						}
					},
					guid: props.imageHotspots[0].guid
				}, props.imageKenBurnsEffect)];

			} else {
				newImageKenBurnsEffect = [Object.assign({}, {
					id: "camera1",
					startTime: 0,
					endTime: 0,
					duration: 0,
					guid: props.imageHotspots[0].guid
				}, props.imageKenBurnsEffect)];
			}
		}

		let showItemIds = true;
		const lsShowItemIds = getLs("markupMediaShowItemIds");
		if (lsShowItemIds) {
			showItemIds = lsShowItemIds === "true" ? true : false;
		}

		let previewScale = 100;
		const lsPreviewScale = getLs("markupMediaPreviewScale");
		if (lsPreviewScale) {
			previewScale = Number(lsPreviewScale);
		}

		this.state = {
			firstCoord: null,
			secondCoord: null,
			coordsLayout: true,
			imageSize: null,
			lastMouseX: 0,
			lastMouseY: 0,
			mouseX: 0,
			mouseY: 0,
			mouseDown: false,
			hotspotShape: "rectangle",
			hotspotColor: "darkorange",
			hotspotBorderStyle: "solid",
			hotspotBorderWidth: 5,
			hotspotBorderRadius: 10,
			hotspotFillColor: "transparent",
			highlightShape: "rectangle",
			highlightColor: "blue",
			highlightBorderStyle: "solid",
			highlightBorderWidth: 5,
			highlightBorderRadius: 0,
			highlightFillColor: "transparent",
			imageHotspots: props.imageHotspots ? props.imageHotspots : [],
			imageKenBurnsEffect: newImageKenBurnsEffect ? newImageKenBurnsEffect : Array.isArray(props.imageKenBurnsEffect) ? props.imageKenBurnsEffect : [],
			componentType: "camera",
			selectedItem: null,
			loading: true,
			previewScale: previewScale,
			currentCursorTs: 0,
			currentRegion: null,
			showItemIds: showItemIds
		}

		this.svgRef = React.createRef();
	}

	async componentDidMount() {
		const imageSize = await getImageSize(this.props.url);

		this.setState({
			imageSize: {
				width: imageSize.width,
				height: imageSize.height
			}
		})
	}

	// componentWillUnmount() {
	// 	// Save authoring properties
	// 	setImageHotspots(this.state.imageHotspots);
	// 	setImageKenBurnsEffect(this.state.imageKenBurnsEffect);
	// }

	// cameraZoom(scale) {
	// 	const item = this.state.selectedItem;

	// 	const newWidth = item.camera.end.width * scale;

	// 	item.camera.end.x = item.camera

	// 	const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
	// 	let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
	// 	newImageKenBurnsEffect.splice(index, 1, item);

	// 	this.setState({
	// 		imageKenBurnsEffect: newImageKenBurnsEffect
	// 	})
	// }

	onToggleAllHotspotsVisible() {
		const hotspotItems = this.state.imageHotspots;

		let hotspotItemsAllCanvasVisible = true;
		hotspotItems.forEach((hotspotItem) => {
			if (hotspotItem.hasOwnProperty("canvasVisible") && !hotspotItem.canvasVisible) {
				hotspotItemsAllCanvasVisible = false;
			}
		});

		if (hotspotItemsAllCanvasVisible) {
			hotspotItems.forEach((hotspotItem, idx) => {
				this.onSetHotspotVisible(idx, false);
			});
		} else {
			hotspotItems.forEach((hotspotItem, idx) => {
				this.onSetHotspotVisible(idx, true);
			});
		}
	}

	onToggleHotspotVisible(idx) {
		const item = this.state.imageHotspots[idx];
		this.onSetHotspotVisible(idx, !item.hasOwnProperty("canvasVisible") ? false : !item.canvasVisible);
	}

	onSetHotspotVisible(idx, visible) {
		const item = this.state.imageHotspots[idx];

		item.canvasVisible = visible;

		let newImageHotspots = [...this.state.imageHotspots];
		newImageHotspots.splice(idx, 1, item);

		this.setState({
			imageHotspots: newImageHotspots
		});
	}

	onToggleAllKenBurnsEffectVisible() {
		const kenBurnsEffectItems = this.state.imageKenBurnsEffect;

		let kenBurnsEffectItemsAllCanvasVisible = true;
		kenBurnsEffectItems.forEach((kenBurnsEffectItem) => {
			if (kenBurnsEffectItem.hasOwnProperty("canvasVisible") && !kenBurnsEffectItem.canvasVisible) {
				kenBurnsEffectItemsAllCanvasVisible = false;
			}
		});

		if (kenBurnsEffectItemsAllCanvasVisible) {
			kenBurnsEffectItems.forEach((kenBurnsEffectItem, idx) => {
				this.onSetKenBurnsEffectVisible(idx, false);
			});
		} else {
			kenBurnsEffectItems.forEach((kenBurnsEffectItem, idx) => {
				this.onSetKenBurnsEffectVisible(idx, true);
			});
		}
	}

	onToggleKenBurnsEffectVisible(idx) {
		const item = this.state.imageKenBurnsEffect[idx];
		this.onSetKenBurnsEffectVisible(idx, !item.hasOwnProperty("canvasVisible") ? false : !item.canvasVisible);
	}

	onSetKenBurnsEffectVisible(idx, visible) {
		const item = this.state.imageKenBurnsEffect[idx];

		item.canvasVisible = visible;

		let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
		newImageKenBurnsEffect.splice(idx, 1, item);

		this.setState({
			imageKenBurnsEffect: newImageKenBurnsEffect
		});
	}

	deleteHotspot(idx) {
		// Delete imageHotspot from array
		const currentItem = this.state.imageHotspots[idx];

		const imageHotspots = [...this.state.imageHotspots];
		imageHotspots.splice(idx, 1);
		this.setState({
			imageHotspots: imageHotspots
		});
		if (this.state.selectedItem && currentItem.guid === this.state.selectedItem.guid) {
			this.setState({
				selectedItem: null
			});
		}
	}

	deleteCamera(idx) {
		// Delete camera from array
		const currentItem = this.state.imageKenBurnsEffect[idx];

		const imageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
		imageKenBurnsEffect.splice(idx, 1);
		this.setState({
			imageKenBurnsEffect: imageKenBurnsEffect
		});
		if (this.state.selectedItem && currentItem.guid === this.state.selectedItem.guid) {
			this.setState({
				selectedItem: null
			});
		}
	}

	render() {
		// Don't render until have the image size
		if (this.state.imageSize === null) {
			return null;
		}

		// Currently selected entity
		let cursor = state.select(state.select("selectedNodePath").get());
		let entity = cursor.get().entity;

		const imageHotspots = this.state.imageHotspots;

		let hotspotsComponent = null;
		if (imageHotspots && imageHotspots.length > 0) {
			hotspotsComponent = imageHotspots.map((hotspot, idx) => {
				if (hotspot.hasOwnProperty("canvasVisible") && !hotspot.canvasVisible) {
					return null;
				}

				let transparentHotspot = false;
				let strokeWidth = hotspot.style.hasOwnProperty("borderWidth") ? hotspot.style.borderWidth : 5;
				let stroke = hotspot.style.hasOwnProperty("borderColor") ? hotspot.style.borderColor : "darkorange";
				let strokeDashArray = null;

				if (hotspot.style.hasOwnProperty("borderWidth") && hotspot.style.borderWidth === 0) {
					transparentHotspot = true;
				} else if (hotspot.style.borderColor === "transparent" || (hotspot.style.borderColor.startsWith("rgba(") && hotspot.style.borderColor.endsWith(",0)")) || (hotspot.style.borderColor.startsWith("#") && hotspot.style.borderColor.endsWith("00"))) {
					transparentHotspot = true;
				}

				if (transparentHotspot) {
					strokeWidth = 1;
					stroke = "#888888";
					strokeDashArray = "10,10";
				}

				return (
					<g transform={`translate(${imageOffset} ${imageOffset})`} opacity={/*(this.state.selectedItem && this.state.selectedItem.guid === hotspot.guid) ? 0.5 : */1}>
						{(!hotspot.hasOwnProperty("shape") || hotspot.shape.toLowerCase() === "rectangle") &&
							<rect
								style={{ transformOrigin: `${hotspot.coords.x + (hotspot.coords.width * 0.5)}px ${hotspot.coords.y + (hotspot.coords.height * 0.5)}px` }}
								transform={`rotate(${hotspot.rotation ? hotspot.rotation : 0})`}
								rx={hotspot.style.borderStyle && hotspot.style.borderRadius ? hotspot.style.borderRadius : 0}
								x={hotspot.coords.x}
								y={hotspot.coords.y}
								width={hotspot.coords.width}
								height={hotspot.coords.height}
								strokeWidth={strokeWidth}
								stroke={stroke}
								strokeDasharray={strokeDashArray}
								fill={hotspot.style.borderStyle && hotspot.style.borderFillColor ? hotspot.style.borderFillColor : "transparent"}
								pointerEvents="none"
							/>
						}
						{(hotspot.hasOwnProperty("shape") && hotspot.shape.toLowerCase() === "arrow") &&
							<polygon
								style={{ transformOrigin: `${hotspot.coords.x + (hotspot.coords.width * 0.5)}px ${hotspot.coords.y + (hotspot.coords.height * 0.5)}px` }}
								transform={`rotate(${hotspot.rotation ? hotspot.rotation : 0})`}
								points={`${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.33)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height * 0.33)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y},${hotspot.coords.x + (hotspot.coords.width)} ${hotspot.coords.y + (hotspot.coords.height * 0.5)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height * 0.67)},${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.67)},${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.33)}`}
								strokeWidth={strokeWidth}
								stroke={stroke}
								strokeDasharray={strokeDashArray}
								fill={hotspot.style.borderStyle && hotspot.style.borderFillColor ? hotspot.style.borderFillColor : "transparent"}
								pointerEvents="none"
							/>
						}
						{this.state.showItemIds &&
							<g>
								<text fontSize="16" filter="url(#f1)" style={{ fontWeight: "bold" }} x={hotspot.coords.x + 5} y={hotspot.coords.y + (hotspot.coords.height - 5)} fill={(this.state.selectedItem && this.state.selectedItem.guid === hotspot.guid) ? "#00ff00" : "white"}>{hotspot.id}</text>
							</g>
						}
						<g style={{ cursor: "pointer" }} onClick={(e) => {
							this.deleteHotspot(idx);
						}}>
							<circle cx={hotspot.coords.x + hotspot.coords.width} cy={hotspot.coords.y} r={10} stroke="red" strokeWidth="1" fill="red" />
							<text style={{ fontWeight: "bold" }} x={hotspot.coords.x + hotspot.coords.width - 4} y={hotspot.coords.y + 4} fill="white">X</text>
						</g>
					</g>
				)
			});
		}

		const imageKenBurnsEffect = this.state.imageKenBurnsEffect;

		let kenBurnsEffectComponent = null;
		if (imageKenBurnsEffect && imageKenBurnsEffect.length > 0) {
			kenBurnsEffectComponent = imageKenBurnsEffect.map((camera, idx) => {
				if (camera.hasOwnProperty("canvasVisible") && !camera.canvasVisible) {
					return null;
				}

				return (
					<g transform={`translate(${imageOffset} ${imageOffset})`} opacity={/*(this.state.selectedItem && this.state.selectedItem.guid === camera.guid) ? 0.5 : */1}>
						<rect
							rx={0}
							x={camera.camera.end.x}
							y={camera.camera.end.y}
							width={camera.camera.end.width}
							height={camera.camera.end.height}
							strokeWidth={2}
							stroke={"black"}
							strokeDasharray={"5,5"}
							fill={"transparent"}
							pointerEvents="none"
						/>
						{this.state.showItemIds &&
							<g>
								<text fontSize="16" filter="url(#f1)" style={{ fontWeight: "bold" }} x={camera.camera.end.x + 5} y={camera.camera.end.y + (camera.camera.end.height - 5)} fill={(this.state.selectedItem && this.state.selectedItem.guid === camera.guid) ? "#00ff00" : "white"}>{camera.id}</text>
							</g>
						}
						<g style={{ cursor: "pointer" }} onClick={(e) => {
							this.deleteCamera(idx);
						}}>
							<circle cx={camera.camera.end.x + camera.camera.end.width} cy={camera.camera.end.y} r={10} stroke="red" strokeWidth="1" fill="red" />
							<text style={{ fontWeight: "bold" }} x={camera.camera.end.x + camera.camera.end.width - 4} y={camera.camera.end.y + 4} fill="white">X</text>
						</g>
					</g>
				)
			});
		}

		let newHotspot = null;
		if (this.state.mouseDown) {
			const width = Math.abs(this.state.mouseX - this.state.lastMouseX);
			const height = Math.abs(this.state.mouseY - this.state.lastMouseY);

			console.log("$#$#$# NEW HOTSPOT", this.state.lastMouseX, this.state.lastMouseY, width, height);

			if (this.state.componentType === "hotspot" || this.state.componentType === "hotspot-and-camera") {
				let transparentHotspot = false;
				let strokeWidth = Number(this.state.hotspotBorderWidth);
				let stroke = this.state.hotspotColor;
				let strokeDashArray = null;

				if (strokeWidth === 0) {
					transparentHotspot = true;
				} else if (stroke === "transparent" || (stroke.startsWith("rgba(") && stroke.endsWith(",0)")) || (stroke.startsWith("#") && stroke.endsWith("00"))) {
					transparentHotspot = true;
				}

				if (transparentHotspot) {
					strokeWidth = 1;
					stroke = "#888888";
					strokeDashArray = "10,10";
				}

				if (this.state.hotspotShape.toLowerCase() === "rectangle") {
					newHotspot = <rect
						rx={this.state.hotspotBorderRadius}
						x={this.state.lastMouseX + imageOffset}
						y={this.state.lastMouseY + imageOffset}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
						stroke={stroke}
						strokeDasharray={strokeDashArray}
						fill={this.state.hotspotFillColor}
					/>;
				} else if (this.state.hotspotShape.toLowerCase() === "arrow") {
					const x = this.state.lastMouseX + imageOffset;
					const y = this.state.lastMouseY + imageOffset;

					newHotspot = <polygon
						style={{ transformOrigin: `${x + (width * 0.5)}px ${y + (height * 0.5)}px` }}
						transform={`rotate(0)`}
						points={`${x} ${y + (height * 0.33)},${x + (width * 0.5)} ${y + (height * 0.33)},${x + (width * 0.5)} ${y},${x + (width)} ${y + (height * 0.5)},${x + (width * 0.5)} ${y + (height)},${x + (width * 0.5)} ${y + (height * 0.67)},${x} ${y + (height * 0.67)},${x} ${y + (height * 0.33)}`}
						strokeWidth={strokeWidth}
						stroke={stroke}
						strokeDasharray={strokeDashArray}
						fill={this.state.hotspotFillColor}
					/>
				}
			} else if (this.state.componentType === "highlight" || this.state.componentType === "highlight-and-camera") {
				let transparentHotspot = false;
				let strokeWidth = Number(this.state.highlightBorderWidth);
				let stroke = this.state.highlightColor;
				let strokeDashArray = null;

				if (strokeWidth === 0) {
					transparentHotspot = true;
				} else if (stroke === "transparent" || (stroke.startsWith("rgba(") && stroke.endsWith(",0)")) || (stroke.startsWith("#") && stroke.endsWith("00"))) {
					transparentHotspot = true;
				}

				if (transparentHotspot) {
					strokeWidth = 1;
					stroke = "#888888";
					strokeDashArray = "10,10";
				}

				if (this.state.highlightShape.toLowerCase() === "rectangle") {
					newHotspot = <rect
						rx={this.state.highlightBorderRadius}
						x={this.state.lastMouseX + imageOffset}
						y={this.state.lastMouseY + imageOffset}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
						stroke={stroke}
						strokeDasharray={strokeDashArray}
						fill={this.state.highlightFillColor}
					/>;
				} else if (this.state.highlightShape.toLowerCase() === "arrow") {
					const x = this.state.lastMouseX + imageOffset;
					const y = this.state.lastMouseY + imageOffset;

					newHotspot = <polygon
						style={{ transformOrigin: `${x + (width * 0.5)}px ${y + (height * 0.5)}px` }}
						transform={`rotate(0)`}
						points={`${x} ${y + (height * 0.33)},${x + (width * 0.5)} ${y + (height * 0.33)},${x + (width * 0.5)} ${y},${x + (width)} ${y + (height * 0.5)},${x + (width * 0.5)} ${y + (height)},${x + (width * 0.5)} ${y + (height * 0.67)},${x} ${y + (height * 0.67)},${x} ${y + (height * 0.33)}`}
						strokeWidth={strokeWidth}
						stroke={stroke}
						strokeDasharray={strokeDashArray}
						fill={this.state.highlightFillColor}
					/>
				}

			} else if (this.state.componentType === "camera") {
				newHotspot = <rect
					rx={0}
					x={this.state.lastMouseX + imageOffset}
					y={this.state.lastMouseY + imageOffset}
					width={width}
					height={height}
					strokeWidth={2}
					stroke={"black"}
					strokeDasharray={"5,5"}
					fill={"transparent"}
				/>;
			}
		}

		const borderWidthOptions =
			<>
				<option key="sw-0" value={0}>0</option>
				<option key="sw-1" value={1}>1</option>
				<option key="sw-2" value={2}>2</option>
				<option key="sw-3" value={3}>3</option>
				<option key="sw-4" value={4}>4</option>
				<option key="sw-5" value={5}>5</option>
				<option key="sw-6" value={6}>6</option>
				<option key="sw-7" value={7}>7</option>
				<option key="sw-8" value={8}>8</option>
				<option key="sw-9" value={9}>9</option>
				<option key="sw-10" value={10}>10</option>
				<option key="sw-11" value={11}>11</option>
				<option key="sw-12" value={12}>12</option>
				<option key="sw-13" value={13}>13</option>
				<option key="sw-14" value={14}>14</option>
				<option key="sw-15" value={15}>15</option>
				<option key="sw-16" value={16}>16</option>
			</>;

		const borderRadiusOptions =
			<>
				<option key="br-0" value={0}>0</option>
				<option key="br-1" value={1}>1</option>
				<option key="br-2" value={2}>2</option>
				<option key="br-3" value={3}>3</option>
				<option key="br-4" value={4}>4</option>
				<option key="br-5" value={5}>5</option>
				<option key="br-6" value={6}>6</option>
				<option key="br-7" value={7}>7</option>
				<option key="br-8" value={8}>8</option>
				<option key="br-9" value={9}>9</option>
				<option key="br-10" value={10}>10</option>
				<option key="br-11" value={11}>11</option>
				<option key="br-12" value={12}>12</option>
				<option key="br-13" value={13}>13</option>
				<option key="br-14" value={14}>14</option>
				<option key="br-15" value={15}>15</option>
				<option key="br-16" value={16}>16</option>
				<option key="br-17" value={17}>17</option>
				<option key="br-18" value={18}>18</option>
				<option key="br-19" value={19}>19</option>
				<option key="br-20" value={20}>20</option>
				<option key="br-21" value={21}>21</option>
				<option key="br-22" value={22}>22</option>
				<option key="br-23" value={23}>23</option>
				<option key="br-24" value={24}>24</option>
				<option key="br-25" value={25}>25</option>
				<option key="br-26" value={26}>26</option>
				<option key="br-27" value={27}>27</option>
				<option key="br-28" value={28}>28</option>
				<option key="br-29" value={29}>29</option>
				<option key="br-30" value={30}>30</option>
				<option key="br-31" value={31}>31</option>
				<option key="br-32" value={32}>32</option>
				<option key="br-33" value={33}>33</option>
				<option key="br-34" value={34}>34</option>
				<option key="br-35" value={35}>35</option>
				<option key="br-36" value={36}>36</option>
				<option key="br-37" value={37}>37</option>
				<option key="br-38" value={38}>38</option>
				<option key="br-39" value={39}>39</option>
				<option key="br-40" value={40}>40</option>
				<option key="br-41" value={41}>41</option>
				<option key="br-42" value={42}>42</option>
				<option key="br-43" value={43}>43</option>
				<option key="br-44" value={44}>44</option>
				<option key="br-45" value={45}>45</option>
				<option key="br-46" value={46}>46</option>
				<option key="br-47" value={47}>47</option>
				<option key="br-48" value={48}>48</option>
				<option key="br-49" value={49}>49</option>
				<option key="br-50" value={50}>50</option>
				<option key="br-51" value={51}>51</option>
				<option key="br-52" value={52}>52</option>
				<option key="br-53" value={53}>53</option>
				<option key="br-54" value={54}>54</option>
				<option key="br-55" value={55}>55</option>
				<option key="br-56" value={56}>56</option>
				<option key="br-57" value={57}>57</option>
				<option key="br-58" value={58}>58</option>
				<option key="br-59" value={59}>59</option>
				<option key="br-60" value={60}>60</option>
				<option key="br-61" value={61}>61</option>
				<option key="br-62" value={62}>62</option>
				<option key="br-63" value={63}>63</option>
				<option key="br-64" value={64}>64</option>
			</>;

		let component = <Modal closeOnEsc={false} classNames={{ modal: "custom-modal-markup" }} open={this.props.open} onClose={this.props.onClose}>
			<div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
				<h4 style={{ flex: 0, textAlign: "center" }}>Media Markup</h4>
				<ButtonToolbar style={{ flex: 0, backgroundColor: "#dddddd", padding: "5px", width: "100%" }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<ButtonGroup>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
								<Button style={{ marginLeft: "15px", marginRight: "25px", display: "flex", flexDirection: "row", justifyContent: "center" }} bsSize="large" bsStyle="primary" onClick={async () => {
									showEditorSpinner();

									setImageHotspots(this.state.imageHotspots);
									setImageKenBurnsEffect(this.state.imageKenBurnsEffect);

									await saveChecklist().catch((e) => {
										console.error(e);
										showSaveActivityError(e);
									});
								}} title="Save">
									<FontAwesomeIcon icon="save" />
									<div style={{ fontSize: "12px", paddingLeft: "5px" }}>Save</div>
								</Button>
								<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
									<div style={{ fontSize: "x-small" }}>Select Component</div>
									<select onChange={(e) => {
										this.setState({
											componentType: e.target.value
										})
									}} value={this.state.componentType}>
										<option key="component-1" value={"camera"}>Camera</option>
										<option key="component-2" value={"hotspot"}>Hotspot</option>
										<option key="component-3" value={"hotspot-and-camera"}>Hotspot and Camera</option>
										<option key="component-4" value={"highlight"}>Highlight</option>
										<option key="component-5" value={"highlight-and-camera"}>Highlight and Camera</option>
									</select>
								</div>

								{(this.state.componentType === "hotspot" || this.state.componentType === "hotspot-and-camera") &&
									<>
										<div style={{ marginLeft: '10px', marginRight: "10px" }}>|</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Shape</div>
											<select onChange={(e) => {
												if (e.target.value === "arrow") {
													this.setState({
														hotspotShape: e.target.value,
														hotspotFillColor: this.state.hotspotColor
													})
												} else if (e.target.value === "rectangle") {
													this.setState({
														hotspotShape: e.target.value,
														hotspotFillColor: "transparent"
													})
												} else {
													this.setState({
														hotspotShape: e.target.value
													})
												}
											}} value={this.state.hotspotShape}>
												<option key="shape-1" value={"rectangle"}>Rectangle</option>
												<option key="shape-2" value={"arrow"}>Arrow</option>
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Color</div>
											<ColorPicker id="popover-hotspot-color" title="Hotspot Color"
												onChangeColor={(color) => {
													this.setState({
														hotspotColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
													})
												}}
												onClearColor={() => {
												}}
												presetColors={globals.defaultColors}
												color={this.state.hotspotColor}
												placement="bottom"
												hideClear={true}
												disableAlpha={false}
											/>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Width</div>
											<select onChange={(e) => {
												this.setState({
													hotspotBorderWidth: e.target.value
												})
											}} value={this.state.hotspotBorderWidth}>
												{borderWidthOptions}
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Radius</div>
											<select onChange={(e) => {
												this.setState({
													hotspotBorderRadius: e.target.value
												})
											}} value={this.state.hotspotBorderRadius}>
												{borderRadiusOptions}
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Fill Color</div>
											<ColorPicker id="popover-hotspot-fill-color" title="Hotspot Fill Color"
												onChangeColor={(color) => {
													this.setState({
														hotspotFillColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
													})
												}}
												onClearColor={() => {
												}}
												presetColors={globals.defaultColors}
												color={this.state.hotspotFillColor}
												placement="bottom"
												hideClear={true}
												disableAlpha={false}
											/>
										</div>
									</>
								}
								{(this.state.componentType === "highlight" || this.state.componentType === "highlight-and-camera") &&
									<>
										<div style={{ marginLeft: '10px', marginRight: "10px" }}>|</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Shape</div>
											<select onChange={(e) => {
												if (e.target.value === "arrow") {
													this.setState({
														highlightShape: e.target.value,
														highlightFillColor: this.state.highlightColor
													})
												} else if (e.target.value === "rectangle") {
													this.setState({
														highlightShape: e.target.value,
														highlightFillColor: "transparent"
													})
												} else {
													this.setState({
														highlightShape: e.target.value
													})
												}
											}} value={this.state.highlightShape}>
												<option key="shape-1" value={"rectangle"}>Rectangle</option>
												<option key="shape-2" value={"arrow"}>Arrow</option>
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Color</div>
											<ColorPicker id="popover-highlight-color" title="Highlight Color"
												onChangeColor={(color) => {
													this.setState({
														highlightColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
													})
												}}
												onClearColor={() => {
												}}
												presetColors={globals.defaultColors}
												color={this.state.highlightColor}
												placement="bottom"
												hideClear={true}
												disableAlpha={false}
											/>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Width</div>
											<select onChange={(e) => {
												this.setState({
													highlightBorderWidth: e.target.value
												})
											}} value={this.state.highlightBorderWidth}>
												{borderWidthOptions}
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Radius</div>
											<select onChange={(e) => {
												this.setState({
													highlightBorderRadius: e.target.value
												})
											}} value={this.state.highlightBorderRadius}>
												{borderRadiusOptions}
											</select>
										</div>
										<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
											<div style={{ fontSize: "x-small" }}>Border Fill Color</div>
											<ColorPicker id="popover-highlight-fill-color" title="Highlight Fill Color"
												onChangeColor={(color) => {
													this.setState({
														highlightFillColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
													})
												}}
												onClearColor={() => {
												}}
												presetColors={globals.defaultColors}
												color={this.state.highlightFillColor}
												placement="bottom"
												hideClear={true}
												disableAlpha={false}
											/>
										</div>
									</>
								}
							</div>
						</ButtonGroup>
						<ButtonGroup>
							<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", paddingRight: 75 }}>
								{!this.state.showItemIds &&
									<Button style={{ marginRight: "25px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} bsStyle="primary" onClick={() => {
										setLs("markupMediaShowItemIds", "true");
										this.setState({
											showItemIds: true
										});
									}} title="Show item labels on canvas">
										<FontAwesomeIcon style={{ fontSize: "12px" }} icon="align-justify" />
										<div style={{ fontSize: "12px", paddingLeft: "5px" }}>Show Labels</div>
									</Button>
								}
								{this.state.showItemIds &&
									<Button style={{ marginRight: "25px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} bsStyle="primary" onClick={() => {
										setLs("markupMediaShowItemIds", "false");
										this.setState({
											showItemIds: false
										});
									}} title="Hide item labels on canvas">
										<FontAwesomeIcon style={{ fontSize: "12px" }} icon="align-slash" />
										<div style={{ fontSize: "12px", paddingLeft: "5px" }}>Hide Labels</div>
									</Button>
								}
								<div style={{ height: 50, display: "flex", flexDirection: "column", alignItems: "center", marginRight: 10 }}>
									<div style={{ fontSize: "x-small" }}>Zoom</div>
									<select style={{ borderRadius: "5px", border: "1px solid #cccccc", marginRight: "5px", marginBottom: "0px" }} value={this.state.previewScale} onChange={(e) => {
										setLs("markupMediaPreviewScale", e.target.value);
										this.setState({
											previewScale: e.target.value
										});
									}}>
										<option value="25">25%</option>
										<option value="33">33%</option>
										<option value="50">50%</option>
										<option value="66">66%</option>
										<option value="75">75%</option>
										<option value="90">90%</option>
										<option value="100">100%</option>
										<option value="110">110%</option>
										<option value="125">125%</option>
										<option value="150">150%</option>
										<option value="200">200%</option>
									</select>
								</div>
							</div>
						</ButtonGroup>

					</div>
				</ButtonToolbar>
				<div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
					<div style={{ flex: 4, overflow: "auto", postiion: "relative" }}>
						<svg transform={`scale(${this.state.previewScale / 100} ${this.state.previewScale / 100})`} style={{ cursor: "crosshair", transformOrigin: "0px 0px" }} ref={this.svgRef} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${this.state.imageSize.width + (imageOffset * 2)} ${this.state.imageSize.height + (imageOffset * 2)}`} width={"" + Number(this.state.imageSize.width + (imageOffset * 2))} height={"" + Number(this.state.imageSize.height + (imageOffset * 2))} onMouseDown={(e) => {
							e.preventDefault();
							this.setState({
								lastMouseX: e.nativeEvent.offsetX - imageOffset,
								lastMouseY: e.nativeEvent.offsetY - imageOffset,
								mouseDown: true
							});
						}}
							onMouseUp={(e) => {
								e.preventDefault();

								const width = Math.abs(this.state.mouseX - this.state.lastMouseX);
								const height = Math.abs(this.state.mouseY - this.state.lastMouseY);

								let newStartTime = 0;
								let newEndTime = -1;
								if (this.state.currentRegion !== null) {
									newStartTime = Math.round(this.state.currentRegion.start * 1000);
									newEndTime = Math.round(this.state.currentRegion.end * 1000);
								} else {
									newStartTime = Math.round(this.state.currentCursorTs * 1000);
								}

								if (width > 0 && height > 0) {
									if (this.state.componentType === "hotspot" || this.state.componentType === "hotspot-and-camera") {
										// Create new hotspot

										const newHotspot = {
											id: (this.state.hotspotShape === "arrow" ? "arrow" : "hotspot") + (this.state.imageHotspots.length + 1),
											startTime: newStartTime,
											endTime: newEndTime,
											shape: this.state.hotspotShape,
											coords: {
												x: this.state.lastMouseX,
												y: this.state.lastMouseY,
												width: width,
												height: height
											},
											rotation: 0,
											label: "",
											labelVisible: false,
											style: {
												borderRadius: Number(this.state.hotspotBorderRadius),
												borderColor: this.state.hotspotColor,
												borderStyle: this.state.hotspotBorderStyle,
												borderWidth: Number(this.state.hotspotBorderWidth),
												borderFillColor: this.state.hotspotFillColor
											},
											startTimeTransition: {
												type: "fade",
												duration: 500,
												fromOpacity: 0,
												toOpacity: 1
											},
											endTimeTransition: {
												type: "fade",
												duration: 500,
												toOpacity: 0
											},
											action: "gotoNext",
											guid: uuidv4()

										};

										const newImageHotspots = [...this.state.imageHotspots];
										newImageHotspots.push(newHotspot);

										if (this.state.componentType === "hotspot") {
											this.setState({
												mouseDown: false,
												imageHotspots: newImageHotspots,
												selectedItem: newHotspot
											});
										}

										if (this.state.componentType === "hotspot-and-camera") {
											const newKenBurnsEffect = {
												id: "camera" + (this.state.imageKenBurnsEffect.length + 1),
												startTime: newStartTime,
												endTime: this.state.currentRegion !== null ? newEndTime : newStartTime + 3000,
												duration: 0,
												camera: {
													end: {
														x: this.state.lastMouseX - 75,
														y: this.state.lastMouseY - 75,
														width: width + 150,
														height: height + 150
													},
													startZoomed: false
												},
												guid: uuidv4()
											};

											const newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
											newImageKenBurnsEffect.push(newKenBurnsEffect);

											// Reset and add new hotspot
											this.setState({
												mouseDown: false,
												imageHotspots: newImageHotspots,
												imageKenBurnsEffect: newImageKenBurnsEffect,
												selectedItem: newHotspot
											});
										}

									} else if (this.state.componentType === "highlight" || this.state.componentType === "highlight-and-camera") {
										// Create new hotspot
										const newHotspot = {
											id: (this.state.highlightShape === "arrow" ? "arrow" : "highlight") + (this.state.imageHotspots.length + 1),
											// label: "",
											// labelVisible: false,
											startTime: newStartTime,
											endTime: newEndTime,
											shape: this.state.highlightShape,
											coords: {
												x: this.state.lastMouseX,
												y: this.state.lastMouseY,
												width: width,
												height: height
											},
											rotation: 0,
											style: {
												borderRadius: Number(this.state.highlightBorderRadius),
												borderColor: this.state.highlightColor,
												borderStyle: this.state.highlightBorderStyle,
												borderWidth: Number(this.state.highlightBorderWidth),
												borderFillColor: this.state.highlightFillColor
											},
											startTimeTransition: {
												type: "fade",
												duration: 500,
												fromOpacity: 0,
												toOpacity: 1
											},
											endTimeTransition: {
												type: "fade",
												duration: 500,
												toOpacity: 0
											},
											action: "none",
											guid: uuidv4()

										};

										const newImageHotspots = [...this.state.imageHotspots];
										newImageHotspots.push(newHotspot);

										if (this.state.componentType === "highlight") {
											this.setState({
												mouseDown: false,
												imageHotspots: newImageHotspots,
												selectedItem: newHotspot
											});
										}

										if (this.state.componentType === "highlight-and-camera") {
											const newKenBurnsEffect = {
												id: "camera" + (this.state.imageKenBurnsEffect.length + 1),
												startTime: newStartTime,
												endTime: this.state.currentRegion !== null ? newEndTime : newStartTime + 3000,
												duration: 0,
												camera: {
													end: {
														x: this.state.lastMouseX - 75,
														y: this.state.lastMouseY - 75,
														width: width + 150,
														height: height + 150
													},
													startZoomed: false
												},
												guid: uuidv4()
											};

											const newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
											newImageKenBurnsEffect.push(newKenBurnsEffect);

											// Reset and add new hotspot
											this.setState({
												mouseDown: false,
												imageHotspots: newImageHotspots,
												imageKenBurnsEffect: newImageKenBurnsEffect,
												selectedItem: newHotspot
											});
										}
									} else if (this.state.componentType === "camera") {
										const newKenBurnsEffect = {
											id: "camera" + (this.state.imageKenBurnsEffect.length + 1),
											startTime: newStartTime,
											endTime: this.state.currentRegion !== null ? newEndTime : newStartTime + 3000,
											duration: 0,
											camera: {
												end: {
													x: this.state.lastMouseX,
													y: this.state.lastMouseY,
													width: width,
													height: height
												},
												startZoomed: false
											},
											guid: uuidv4()
										};

										const newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
										newImageKenBurnsEffect.push(newKenBurnsEffect);

										this.setState({
											mouseDown: false,
											imageKenBurnsEffect: newImageKenBurnsEffect,
											selectedItem: newKenBurnsEffect
										});
									}

								} else {
									this.setState({
										mouseDown: false
									});
								}
							}}
							onMouseMove={(e) => {
								e.preventDefault();

								const mouseX = e.nativeEvent.offsetX - imageOffset;
								const mouseY = e.nativeEvent.offsetY - imageOffset;

								this.setState({
									mouseX: mouseX,
									mouseY: mouseY
								});
							}}
							onClick={(e) => {
								e.preventDefault();

								if (this.state.firstCoord === null) {
									this.setState({
										firstCoord: {
											x: e.nativeEvent.offsetX - imageOffset,
											y: e.nativeEvent.offsetY - imageOffset
										}
									});
								} else if (this.state.secondCoord === null) {
									this.setState({
										secondCoord: {
											x: e.nativeEvent.offsetX - imageOffset,
											y: e.nativeEvent.offsetY - imageOffset
										}
									});
								} else {
									// alert("First Coordinate: " + this.state.firstCoord.x + ", " + this.state.firstCoord.y + "\n" + "Second Coordinate: " + this.state.secondCoord.x + ", " + this.state.secondCoord.y);

									this.setState({
										firstCoord: {
											x: e.nativeEvent.offsetX - imageOffset,
											y: e.nativeEvent.offsetY - imageOffset
										},
										secondCoord: null
									})
								}
							}}>
							<filter id="f1" x="-10%" y="-10%" width="120%" height="120%">
								<feFlood flood-color="#222222" />
								<feGaussianBlur stdDeviation="5" />
								<feComponentTransfer>
									<feFuncA type="table" tableValues="0 0 0 1" />
								</feComponentTransfer>

								<feComponentTransfer>
									<feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
								</feComponentTransfer>
								<feComposite operator="over" in="SourceGraphic" />
							</filter>
							{/* <g> */}
							{/* <rect x={0}
								y={0}
								width={this.state.imageSize.width + 400}
								height={this.state.imageSize.height + 400}
								strokeWidth={5}
								stroke={"green"}
								fill={"yellow"}
							/> */}
							<image transform={`translate(${imageOffset} ${imageOffset})`} href={this.props.url} />
							{/* </g> */}
							{hotspotsComponent}
							{kenBurnsEffectComponent}
							{newHotspot}
						</svg>
					</div>
					<div style={{ flex: 1.2, overflow: "auto" }}>
						<MarkupImageProperties
							// onDeleteHotspot={(idx) => {
							// 	this.deleteHotspot(idx);
							// }}
							// onDeleteCamera={(idx) => {
							// 	this.deleteCamera(idx);
							// }}
							onSetTimeInfo={() => {
								let newStartTime = 0;
								let newEndTime = -1;
								if (this.state.currentRegion !== null) {
									newStartTime = Math.round(this.state.currentRegion.start * 1000);
									newEndTime = Math.round(this.state.currentRegion.end * 1000);
								} else {
									newStartTime = Math.round(this.state.currentCursorTs * 1000);
								}

								const item = _.cloneDeep(this.state.selectedItem);
								item.startTime = newStartTime;
								item.endTime = newEndTime;

								if (item.hasOwnProperty("coords")) {
									// Find index of object replacing
									const index = this.state.imageHotspots.findIndex(x => x.guid === item.guid);
									let newImageHotspots = [...this.state.imageHotspots];
									newImageHotspots.splice(index, 1, item);

									this.setState({
										imageHotspots: newImageHotspots,
										selectedItem: item
									})
									// This means a kenBurnsEffect	
								} else if (item.hasOwnProperty("startTime")) {
									item.endTime = this.state.currentRegion !== null ? newEndTime : newStartTime + 3000

									// Find index of object replacing
									const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
									let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
									newImageKenBurnsEffect.splice(index, 1, item);

									this.setState({
										imageKenBurnsEffect: newImageKenBurnsEffect,
										selectedItem: item
									})
								}
							}}
							onToggleAllHotspotsVisible={() => {
								this.onToggleAllHotspotsVisible();
							}}
							onToggleHotspotVisible={(idx) => {
								this.onToggleHotspotVisible(idx);
							}}
							onToggleAllKenBurnsEffectVisible={() => {
								this.onToggleAllKenBurnsEffectVisible();
							}}
							onToggleKenBurnsEffectVisible={(idx) => {
								this.onToggleKenBurnsEffectVisible(idx);
							}}
							onHotspotDelete={() => {
								const currentItem = this.state.selectedItem;
								const index = this.state.imageHotspots.findIndex(x => x.guid === currentItem.guid);
								this.deleteHotspot(index);
								// this.onHotspotDelete();
							}}
							onKenBurnsEffectDelete={() => {
								const currentItem = this.state.selectedItem;
								const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === currentItem.guid);
								this.deleteCamera(index);
								// this.onKenBurnsEffectDelete();
							}}
							onHotspotClone={() => {
								const newItem = _.cloneDeep(this.state.selectedItem);

								let type = this.state.selectedItem.hasOwnProperty("label") ? ((this.state.selectedItem.hasOwnProperty("shape") && this.state.selectedItem.shape === "arrow") ? "arrow" : "hotspot") : (this.state.selectedItem.hasOwnProperty("shape") && this.state.selectedItem.shape === "arrow") ? "arrow" : "highlight";
								if (newItem.hasOwnProperty("type")) {
									type = newItem.type;
								}

								if (newItem.hasOwnProperty("label")) {
									newItem.label = "";
								}
								if (newItem.hasOwnProperty("labelVisible")) {
									newItem.labelVisible = false;
								}
								newItem.coords.x = this.state.selectedItem.coords.x + 20;
								newItem.coords.y = this.state.selectedItem.coords.y + 20;
								newItem.guid = uuidv4();
								newItem.id = type + (this.state.imageHotspots.length + 1);

								const imageHotspots = [...this.state.imageHotspots];
								imageHotspots.push(newItem);
								this.setState({
									imageHotspots: imageHotspots
								});
							}}
							onKenBurnsEffectClone={() => {
								const newItem = _.cloneDeep(this.state.selectedItem);

								newItem.camera.end.x = this.state.selectedItem.camera.end.x + 20;
								newItem.camera.end.y = this.state.selectedItem.camera.end.y + 20;
								newItem.guid = uuidv4();
								newItem.id = "camera" + (this.state.imageKenBurnsEffect.length + 1);

								const imageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
								imageKenBurnsEffect.push(newItem);
								this.setState({
									imageKenBurnsEffect: imageKenBurnsEffect
								});
							}}
							onKenBurnsEffectZoom={(scale) => {
								const item = this.state.selectedItem;

								const newWidth = Math.round(item.camera.end.width * scale);
								const newHeight = Math.round(item.camera.end.height * scale);

								if (scale >= 1) {
									item.camera.end.x = Math.round(item.camera.end.x - ((newWidth - item.camera.end.width) / 2));
									item.camera.end.y = Math.round(item.camera.end.y - ((newHeight - item.camera.end.height) / 2));
									item.camera.end.width = newWidth;
									item.camera.end.height = newHeight;
								} else {
									item.camera.end.x = Math.round(item.camera.end.x + ((item.camera.end.width - newWidth) / 2));
									item.camera.end.y = Math.round(item.camera.end.y + ((item.camera.end.height - newHeight) / 2));
									item.camera.end.width = newWidth;
									item.camera.end.height = newHeight;
								}

								const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
								let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
								newImageKenBurnsEffect.splice(index, 1, item);

								this.setState({
									imageKenBurnsEffect: newImageKenBurnsEffect
								});
							}}
							onKenBurnsEffectPan={(direction) => {
								const newItem = _.cloneDeep(this.state.selectedItem);

								newItem.guid = uuidv4();
								if (direction === "right") {
									newItem.camera.end.x = this.state.selectedItem.camera.end.x + this.state.selectedItem.camera.end.width + 100;
									newItem.id = this.state.selectedItem.id + "PanRight";
								} else if (direction === "left") {
									newItem.camera.end.x = this.state.selectedItem.camera.end.x - this.state.selectedItem.camera.end.width - 100;
									newItem.id = this.state.selectedItem.id + "PanLeft";
								} else if (direction === "up") {
									newItem.camera.end.y = this.state.selectedItem.camera.end.y - this.state.selectedItem.camera.end.height - 100;
									newItem.id = this.state.selectedItem.id + "PanUp";
								} else if (direction === "down") {
									newItem.camera.end.y = this.state.selectedItem.camera.end.y + this.state.selectedItem.camera.end.height + 100;
									newItem.id = this.state.selectedItem.id + "PanDown";
								}

								// Default 3 second pan
								if (this.state.selectedItem.endTime > 0) {
									newItem.startTime = this.state.selectedItem.endTime + 250;
									newItem.endTime = newItem.startTime + 3000;
									newItem.duration = 0;
								} else {
									newItem.startTime = this.state.selectedItem.duration + 250;
									newItem.endTime = 0;
									newItem.duration = 3000;
								}

								const imageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
								imageKenBurnsEffect.push(newItem);
								this.setState({
									imageKenBurnsEffect: imageKenBurnsEffect
								});
							}}
							onHotspotMove={(direction, offset = 1) => {
								const item = this.state.selectedItem;

								if (direction === "left") {
									item.coords.x = item.coords.x - offset;
								} else if (direction === "right") {
									item.coords.x = item.coords.x + offset;
								} else if (direction === "up") {
									item.coords.y = item.coords.y - offset;
								} else if (direction === "down") {
									item.coords.y = item.coords.y + offset;
								}

								const index = this.state.imageHotspots.findIndex(x => x.guid === item.guid);
								let newImageHotspots = [...this.state.imageHotspots];
								newImageHotspots.splice(index, 1, item);

								this.setState({
									imageHotspots: newImageHotspots
								});
							}}
							onKenBurnsEffectMove={(direction, offset = 1) => {
								const item = this.state.selectedItem;

								if (direction === "left") {
									item.camera.end.x = item.camera.end.x - offset;
								} else if (direction === "right") {
									item.camera.end.x = item.camera.end.x + offset;
								} else if (direction === "up") {
									item.camera.end.y = item.camera.end.y - offset;
								} else if (direction === "down") {
									item.camera.end.y = item.camera.end.y + offset;
								}

								const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
								let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
								newImageKenBurnsEffect.splice(index, 1, item);

								this.setState({
									imageKenBurnsEffect: newImageKenBurnsEffect
								});
							}}
							onHotspotRotate={(direction, offset = 1) => {
								const item = this.state.selectedItem;

								if (!item.hasOwnProperty("rotation")) {
									item.rotation = 0;
								}

								if (direction === "left") {
									item.rotation = item.rotation - offset;
								} else if (direction === "right") {
									item.rotation = item.rotation + offset;
								}

								const index = this.state.imageHotspots.findIndex(x => x.guid === item.guid);
								let newImageHotspots = [...this.state.imageHotspots];
								newImageHotspots.splice(index, 1, item);

								this.setState({
									imageHotspots: newImageHotspots
								});
							}}
							// onKenBurnsEffectRotate={(direction, offset = 1) => {
							// 	const item = this.state.selectedItem;

							// 	if (direction === "left") {
							// 		item.rotation = item.rotation - offset;
							// 	} else if (direction === "right") {
							// 		item.rotation = item.rotation + offset;
							// 	}

							// 	const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
							// 	let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
							// 	newImageKenBurnsEffect.splice(index, 1, item);

							// 	this.setState({
							// 		imageKenBurnsEffect: newImageKenBurnsEffect
							// 	});
							// }}
							selectedItem={this.state.selectedItem}
							imageHotspots={this.state.imageHotspots}
							onImageHotspotsChange={(imageHotspots) => {
								this.setState({
									imageHotspots
								});
							}}
							imageKenBurnsEffect={this.state.imageKenBurnsEffect}
							onImageKenBurnsEffectChange={(cameras) => {
								this.setState({
									imageKenBurnsEffect: cameras
								});
							}}
							onSelectedItemSelectionChange={(selectedItem) => {
								this.setState({
									selectedItem
								});
							}}
							onSelectedItemChange={(item) => {
								// This means a hotspot
								if (item.hasOwnProperty("coords")) {
									// Find index of object replacing
									const index = this.state.imageHotspots.findIndex(x => x.guid === item.guid);
									let newImageHotspots = [...this.state.imageHotspots];
									newImageHotspots.splice(index, 1, item);

									this.setState({
										imageHotspots: newImageHotspots,
										selectedItem: item
									})
									// This means a kenBurnsEffect	
								} else if (item.hasOwnProperty("startTime")) {
									// Find index of object replacing
									const index = this.state.imageKenBurnsEffect.findIndex(x => x.guid === item.guid);
									let newImageKenBurnsEffect = [...this.state.imageKenBurnsEffect];
									newImageKenBurnsEffect.splice(index, 1, item);

									this.setState({
										imageKenBurnsEffect: newImageKenBurnsEffect,
										selectedItem: item
									})

								}
							}}
						/>
					</div>
				</div>
				<div style={{ width: "100%" }}>
					<AudioWaveform src={getUrlForValue(entity.label1AudioFile)} onRegionSelected={(region) => {
						console.log("REGION SELECTED", region);
						this.setState({
							currentRegion: region
						});
					}} onCursorSelected={(cursorTs) => {
						this.setState({
							currentCursorTs: cursorTs
						});
					}} />
					{/* <audio src={getUrlForValue(entity.label1AudioFile)} id="markupImageModalAudioElement" hidden crossOrigin="use-credentials" onLoadedData={() => {
						this.setState({
							loading: false
						});
					}} />
					{!this.state.loading &&
						<AudioWaveform type="MediaElement" audioElementId="markupImageModalAudioElement" />
					} */}
				</div>
				<div className="modal-footer-container" style={{ flex: 0, justifyContent: "flex-end" }}>
					<Button style={{ marginRight: "10px" }} bsStyle="danger" onClick={this.props.onClose}>Cancel</Button>
					<Button bsStyle="primary" onClick={() => {
						// Save authoring properties
						setImageHotspots(this.state.imageHotspots);
						setImageKenBurnsEffect(this.state.imageKenBurnsEffect);

						this.props.onClose();
					}} style={{ marginRight: "10px" }}>OK</Button>
				</div>
				<div onDoubleClick={() => {
					this.setState({
						coordsLayout: !this.state.coordsLayout
					})
				}} style={{ fontFamily: "Courier", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "absolute", left: !this.state.coordsLayout ? 5 : null, right: this.state.coordsLayout ? 5 : null, top: 50, height: "auto", backgroundColor: "lightblue", borderRadius: 5, padding: 5 }}>
					<div style={{ fontWeight: "bold" }}>{this.state.mouseX}, {this.state.mouseY}</div>
					{/* {(this.state.firstCoord !== null && this.state.secondCoord === null) &&
						<div style={{ fontSize: "x-small", marginTop: 10 }}>{`"coords": {`}<br />&nbsp;&nbsp;&nbsp;{`"x": ${this.state.firstCoord.x},`}<br />&nbsp;&nbsp;&nbsp;{`"y": ${this.state.firstCoord.y},`}<br />&nbsp;&nbsp;&nbsp;{`"width": null,`}<br />&nbsp;&nbsp;&nbsp;{`"height": null`}<br />{`},`}</div>
					}
					{(this.state.firstCoord !== null && this.state.secondCoord !== null) &&
						<div style={{ fontSize: "x-small", marginTop: 10 }}>{`"coords": {`}<br />&nbsp;&nbsp;&nbsp;{`"x": ${this.state.firstCoord.x},`}<br />&nbsp;&nbsp;&nbsp;{`"y": ${this.state.firstCoord.y},`}<br />&nbsp;&nbsp;&nbsp;{`"width": ${this.state.secondCoord.x - this.state.firstCoord.x},`}<br />&nbsp;&nbsp;&nbsp;{`"height": ${this.state.secondCoord.y - this.state.firstCoord.y}`}<br />{`},`}</div>
					} */}
				</div>
			</div>
		</Modal>
		return component;
	}
}

import React, { useState, useEffect } from 'react';

import state from '../../state/state';

import './playlistsSearch.css';

import algoliasearch from 'algoliasearch/lite';
import {
	InstantSearch,
	SearchBox,
	InfiniteHits,
	Highlight,
	RefinementList,
	ClearRefinements,
	Configure
} from 'react-instantsearch-hooks-web';
import { reloadActivitiesFast } from '../../actions/playerActions';
import { hidePlaylistsModal } from '../../actions/actions';
import { getOrgId } from '../../actions/orgsActions';

import { Panel } from "react-bootstrap";
import { truncateWithEllipses } from '../../utils/utils';

import { postMessage } from '../../actions/mobileAppCommunicationActions';

const searchClient = algoliasearch('A6ZL85XHSB', '1d3430002be29a0a2b9d09230a2bfb84');

function Hit({ hit }) {
	let cardStyle = { position: "absolute", overflow: "hidden", width: "100%", minHeight: "200px", display: "flex", flexDirection: "column", justifyContent: "center" };

	let heroImageUrl = "https://public-assets.ambifi.com/app/playlist-default.png";
	if (hit.heroImageUrl && hit.heroImageUrl !== "") {
		heroImageUrl = hit.heroImageUrl;
	}

	return (
		<div style={{
			userSelect: "none",
			WebkitUserSelect: "none",
			MozUserSelect: "none",
			msUserSelect: "none",
			backgroundColor: "white", width: "100%", position: "relative", overflow: "hidden", minHeight: "100px", borderTopStyle: "solid", borderTopColor: "rgb(192,192,192)", borderTopWidth: "2px"
		}}>
			{/* <div style={{ display: "flex", wifth: "100%", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center" }}> */}
			<img style={{ maskImage: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0))", WebkitMaskImage: "linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0))", position: "absolute", left: "0px", top: "-40px", height: "180px", maxWidth: "320px", objectFit: "cover" }} src={heroImageUrl} alt="" />
			{/* </div> */}
			<div style={{ maskImage: "linear-gradient(to bottom, rgba(0,0,0,0.75), rgba(0,0,0,0.55))", WebkitMaskImage: "linear-gradient(to bottom, rgba(0,0,0,0.75), rgba(0,0,0,0.55))", position: "absolute", inset: "0px", backgroundColor: "black" }} />
			<div style={{ padding: "10px", position: "absolute", inset: "0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ color: "white", flex: 1, display: "flex", flexDirection: "column" }}>
					<div style={{ fontWeight: "bold" }}>
						<Highlight attribute="name" hit={hit} />
					</div>
					<div style={{ marginTop: "3px", fontSize: "small" }}><Highlight attribute="description" hit={hit} /></div>
				</div>
			</div>
			<div id={hit.id} onTouchStart={() => {
				document.getElementById(hit.id).style.opacity = "0.7";
			}} onTouchEnd={() => {
				document.getElementById(hit.id).style.opacity = "0";
			}} onClick={() => {
				postMessage({
					playlistId: hit.id
				});
			}} style={{ cursor: "pointer", position: "absolute", overflow: "hidden", width: "100%", minHeight: "200px", display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "black", opacity: 0 }}>
			</div>

		</div>
	);
}

function PlaylistsSearchMobile() {
	const [showPanel, setShowPanel] = useState(true);
	// Pass in orgId as param to URL
	// This needs to post back to mobile app when we open a playlist
	return (
		<InstantSearch searchClient={searchClient} indexName={`prod_PLAYLISTS_75a99644-d769-11ec-8330-069463faa5ff`}>
			<Configure hitsPerPage={100} />
			<div style={{ backgroundColor: "#ffffff", position: "relative", display: "flex", flexDirection: "column", height: "100vh" }}>
				<div style={{ width: "100%", backgroundColor: "#cccccc" }}>
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 0, margin: "5px" }}>
						<SearchBox placeholder='Search here' style={{ flex: 1, width: "100%" }} />
					</div>
				</div>
				<div style={{ width: "100%", overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
					<div style={{ width: "100%", textAlign: "center", fontWeight: "bold", paddingTop: "5px", paddingLeft: "10px", backgroundColor: "#444444", color: "white" }}>Filters</div>
					<div style={{ padding: "10px", backgroundColor: "#444444", color: "white", overflow: "auto", flex: 3, width: "100%", display: "flex", flexDirection: "column" }}>
						<ClearRefinements style={{ flex: 0, marginTop: "10px", marginLeft: "5px", marginRight: "5px", marginBottom: "10px" }} />
						<div style={{ flex: 1, overflow: "auto" }}>
							<RefinementList style={{ margin: "5px", overflow: "auto" }}
								attribute="tags"
							/>

						</div>
					</div>
					<div style={{ overflow: "auto", flex: 7, display: "flex", flexDirection: "column", width: "100%" }}>
						{/* <div style={{ flex: 1, overflow: "auto", width: "100%" }}> */}
						<InfiniteHits showPrevious={false} hitComponent={Hit} />
						{/* </div> */}
					</div>
				</div>
			</div>
		</InstantSearch>
	);

}

export default PlaylistsSearchMobile;


import React, { PureComponent } from "react";
import { Button, Panel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmoothCollapse from "react-smooth-collapse";
import _ from "lodash";
import Glyphicon from "../app-icon";

const headerComponentStyles = {
	backgroundColor: "royalblue", color: "white", paddingTop: "4px", paddingBottom: "4px"
};
export class AmbifiPanel extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			expanded: this.props.hasOwnProperty("expanded") ? this.props.expanded : true
		};
	}
	render() {
		const { titleText, titleIcon, headerContent, footerContent, footerButton, footerButtonText, footerButtonAction, footerButton2, footerButtonIcon, footerButton2Text, footerButton2Action, footerButton2Icon, hideHeader, hideFooter = false, children, footerButtonIconRight, footerButton2IconRight, footerButtonDisabled = false, footerButton2Disabled = false, footerButtonLinkStyle = false, footerButton2LinkStyle = false, collapsible = false, className = null } = this.props;
		let headerComponent;
		if (!hideHeader) {
			let _headerContent = headerContent;
			if (!_headerContent) {
				let textComponent = (titleIcon) ? <span><FontAwesomeIcon icon={titleIcon} />&nbsp;&nbsp;{titleText}</span> : titleText;
				_headerContent = (
					<div className="row" style={{ fontSize: "1.5em" }}>
						<div className="col-xs-12 vcenter">
							{textComponent}
						</div>
					</div>
				);
			}

			if (collapsible) {
				let chevronDown = <span><Glyphicon glyph='chevron-down' /></span>;
				let chevronUp = <span><Glyphicon glyph='chevron-up' /></span>;
				_headerContent = (
					<div className="row-eq-height" style={{ fontSize: "1.5em", verticalAlign: "middle" }}>
						<div className="col-xs-8 vcenter">
							{headerContent ? _headerContent : (titleIcon) ? <span><FontAwesomeIcon icon={titleIcon} />&nbsp;&nbsp;{titleText}</span> : titleText}
						</div>
						<div className="col-xs-4 vcenter" style={{ justifyContent: "flex-end" }}>
							<Button onClick={() => this.setState({ expanded: !this.state.expanded })} bsStyle="primary" style={{ backgroundColor: "#FF8D27" }}>{this.state.expanded ? chevronUp : chevronDown}</Button>
						</div>
					</div>
				);
			}

			headerComponent = (
				<Panel.Heading style={headerComponentStyles}>
					{_headerContent}
				</Panel.Heading>
			);
		}

		let footerComponent;
		if (!hideFooter) {
			let _footerContent = footerContent;
			if (!_footerContent) {
				let _footerButton = footerButton, _footerButton2 = footerButton2;
				if (footerButtonText && footerButtonAction && !_footerButton) {
					let textComponent = buildTextComponent(footerButtonText, footerButtonIcon, footerButtonIconRight);
					const bsStyle = (footerButtonLinkStyle) ? "link" : "primary";
					const style = (footerButtonLinkStyle) ? { backgroundColor: "inherit", color: "white" } : { backgroundColor: "#FF8D27" };
					_footerButton = <Button disabled={footerButtonDisabled} style={style} bsStyle={bsStyle} onClick={footerButtonAction}>{textComponent}</Button>;
				}

				if (footerButton2Text && footerButton2Action && !_footerButton2) {
					let textComponent = buildTextComponent(footerButton2Text, footerButton2Icon, footerButton2IconRight);
					const bsStyle = (footerButton2LinkStyle) ? "link" : "primary";
					const style = (footerButton2LinkStyle) ? { backgroundColor: "inherit", color: "white" } : { backgroundColor: "#FF8D27" };
					_footerButton2 = <Button disabled={footerButton2Disabled} style={style} bsStyle={bsStyle} onClick={footerButton2Action}>{textComponent}</Button>;
				}

				if (_footerButton && _footerButton2) {
					_footerContent = (
						<div className="row" style={{ fontSize: "1.5em", padding: 0 }}>
							<div className="col-xs-6">
								{_footerButton}
							</div>
							<div className="col-xs-6" style={{ textAlign: "right" }}>
								{_footerButton2}
							</div>
						</div>
					);
				} else {
					_footerContent = (<div className="row" style={{ fontSize: "1.5em", textAlign: "right", padding: 0 }}>
						<div className="col-xs-12">
							{_footerButton}
						</div>
					</div>);
				}
			}

			let footerComponentStyles = _.clone(headerComponentStyles);

			if (!this.state.expanded) {
				footerComponentStyles.display = "none";
			}

			footerComponent = (
				<Panel.Footer style={footerComponentStyles}>
					{_footerContent}
				</Panel.Footer>
			);
		}

		let body = <Panel.Body style={{ padding: "1.5em", overflow: "auto" }}>
			{children}
		</Panel.Body>;
		if (collapsible) {
			body = <SmoothCollapse expanded={this.state.expanded}>{body}</SmoothCollapse>;
		}

		let additionalProps = {
			className: "ambifi-panel "
		};
		if (className) {
			additionalProps.className += className;
		}

		return (
			<Panel style={{ borderColor: "royalblue", position: "relative" }} {...additionalProps}>
				{headerComponent}
				{body}
				{footerComponent}
			</Panel>
		);
	}
}

function buildTextComponent(text, icon, iconRight) {
	let textComponent = text;
	if (icon) {
		if (iconRight) {
			textComponent = <span>{text}&nbsp;&nbsp;<FontAwesomeIcon icon={icon} /></span>;
		} else {
			textComponent = <span><FontAwesomeIcon icon={icon} />&nbsp;&nbsp;{text}</span>;
		}
	}
	return textComponent;
}

export default AmbifiPanel;
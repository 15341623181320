import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Icon extends React.PureComponent {
	render() {
		/*
		return (
			<span onClick={this.props.onClick} style={this.props.style} className={"fa fa-" + this.props.glyph} />
		);
		*/
		return (
			<span onClick={this.props.onClick} style={this.props.style}>
				<FontAwesomeIcon icon={this.props.glyph} />
			</span>
		);

	}
}

export default Icon;
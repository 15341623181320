import React, { useState } from "react";
import _ from "lodash";
import { Button, FormControl, FormGroup, Col, Form, ControlLabel, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Drive from "../drive/drive";
import { getExternalResource, addExternalResource, updateExternalResource } from "../../actions/activityActions";
import { getResourceReference, getDriveObject, setActiveTreeCursorForExternalResource } from "../../actions/drive";
import { handleChangeProperty } from "../../actions/actions";

export const SELECT_TYPE_SINGLE = "single";
export const SELECT_TYPE_MULTI = "multi";

export default (props) => {
	const { externalResourceReferences: _externalResourceReferences, onChange, labelEmptyResources, type = SELECT_TYPE_SINGLE, acceptType, propName = null } = props;
	const [externalResourceReferences, setExternalResourceReferences] = useState(_externalResourceReferences && _.isArray(_externalResourceReferences) ? _externalResourceReferences : []);
	const [showAdd, setShowAdd] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [resource, setResource] = useState(null);
	// const [editIndex, setEditIndex] = useState(-1);
	const [canAdd, setCanAdd] = useState(type !== SELECT_TYPE_SINGLE || _externalResourceReferences.length === 0);

	const handleChangeExternalResourceReferences = (_externalResourceReferences) => {
		setExternalResourceReferences(_externalResourceReferences);
		setCanAdd(type !== SELECT_TYPE_SINGLE || _externalResourceReferences.length === 0);
		if (propName) {
			handleChangeProperty(undefined,
				propName,
				_externalResourceReferences
			);
		}
		if (onChange && _.isFunction(onChange)) {
			onChange(_externalResourceReferences);
		}
	};

	const handleDeleteResource = (idx) => {
		console.log("Handle delete", idx);
		let _externalResourceReference = _.get(externalResourceReferences, idx, null);
		if (_externalResourceReference) {
			_externalResourceReference = _.clone(_externalResourceReference);
			_externalResourceReference.deleted = true;
			const _externalResourceReferences = _.clone(externalResourceReferences);
			_externalResourceReferences.splice(idx, 1);
			handleChangeExternalResourceReferences(_externalResourceReferences);
		}
	};

	const handleSelectDriveResource = (ref) => {
		console.log("Handle select datasource", ref, resource);
		setResource(_.assign({}, resource, {
			ref
		}));
	};

	const handleChangeResourceName = (e) => {
		const name = e.target.value;
		setResource(_.assign({}, resource, {
			name
		}));
	};
	const handleChangeResourceDescription = (e) => {
		const description = e.target.value;
		setResource(_.assign({}, resource, {
			description
		}));
	};

	const handleAddResource = () => {
		const { ref, name, description } = resource;
		console.log("Adding resource", ref, name);

		const _externalResourceReferences = _.clone(externalResourceReferences);
		const metaData = {
			name,
			description
		};

		const id = addExternalResource(ref, metaData);
		const newRef = getResourceReference(id, null, true);
		_externalResourceReferences.push(newRef);
		handleChangeExternalResourceReferences(_externalResourceReferences);
		handleCancelAddEditResource();
	};

	const handleCancelAddEditResource = () => {
		setResource(null);
		setShowAdd(false);
		setShowEdit(false);
	};

	const handleEditResource = (res) => {

		const ref = getDriveObject(res.guid);
		console.log("EDIT", ref, res);
		setActiveTreeCursorForExternalResource(ref);


		let resource = {
			ref,
			refId: res.id
		};
		if (res.metaData) {
			resource = _.assign({}, resource, res.metaData);
		}

		setResource(resource);
		// setEditIndex(idx);
		setShowEdit(true);
	};

	const handleSaveResource = () => {
		const { ref, name, description } = resource;
		const metaData = {
			name,
			description
		};
		const _externalResourceReferences = _.clone(externalResourceReferences);
		console.log("Save resource", resource);
		updateExternalResource(ref, resource.refId, metaData);
		// const newRef = getResourceReference(id, null, true);
		// // _externalResourceReferences.push(newRef);
		// _externalResourceReferences.splice(editIndex, 1, newRef);
		setResource(null);
		// setEditIndex(-1);
		setShowEdit(false);
		handleChangeExternalResourceReferences(_externalResourceReferences);
	};

	const handleMoveResourceUp = (idx) => {
		if (idx > 0) {
			const _externalResourceReferences = _.clone(externalResourceReferences);
			const _externalResourceReference = _externalResourceReferences.splice(idx, 1);
			_externalResourceReferences.splice(idx - 1, 0, _externalResourceReference[0]);
			handleChangeExternalResourceReferences(_externalResourceReferences);
		}
	};

	const handleMoveResourceDown = (idx) => {
		if (idx + 1 < _externalResourceReferences.length) {
			const _externalResourceReferences = _.clone(externalResourceReferences);
			const _externalResourceReference = _externalResourceReferences.splice(idx, 1);
			_externalResourceReferences.splice(idx + 1, 0, _externalResourceReference[0]);
			handleChangeExternalResourceReferences(_externalResourceReferences);
		}
	};

	const additionalDriveProps = {};
	if (acceptType) {
		additionalDriveProps.acceptType = acceptType;
	}

	return <>
		{
			(!externalResourceReferences || _.isEmpty(externalResourceReferences)) && <div>{labelEmptyResources}
				<hr />
			</div>
		}
		{
			externalResourceReferences && !_.isEmpty(externalResourceReferences) && <div>
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							<th>Name</th>
							<th>Description</th>
							<th style={{ maxWidth: "150px" }}></th>
						</tr>
					</thead>
					<tbody>



						{
							externalResourceReferences.map((externalResourceReference, idx) => {
								if (externalResourceReference && !externalResourceReference.deleted) {
									const res = getExternalResource(externalResourceReference.refId);
									return <tr key={`selected-ds-${idx}`}>
										<td>{res.metaData.name}</td>
										<td>{res.metaData.description && !_.isEmpty(res.metaData.description) ? res.metaData.description : "-"}</td>
										<td style={{ textAlign: "right" }}>
											<Button disabled={showAdd || showEdit} bsSize="small" onClick={() => { handleEditResource(res, idx); }}><FontAwesomeIcon icon="pencil" /></Button>
											{canAdd && <Button bsSize="small" onClick={() => { handleMoveResourceUp(idx); }} disabled={idx === 0}><FontAwesomeIcon icon="arrow-up" /></Button>}
											{canAdd && <Button bsSize="small" onClick={() => { handleMoveResourceDown(idx); }} disabled={(idx + 1) === externalResourceReferences.length}><FontAwesomeIcon icon="arrow-down" /></Button>}
											<Button style={{ marginLeft: 5 }} bsSize="small" bsStyle="danger" onClick={() => { handleDeleteResource(idx); }}><FontAwesomeIcon icon="times" /></Button></td>
									</tr>;
								} else {
									return null;
								}
							})
						}
					</tbody>
				</Table>
				<hr />
			</div>
		}
		{
			(showAdd || showEdit) &&
			<Form style={{ marginTop: "10px" }} horizontal>
				<FormGroup controlId="dictionary-name">
					<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left", marginBottom: "10px" }}>
						1.) Select a Resource
					</Col>
					<Col sm={12}>
						<Drive inline={true} edit={true} onFileSelected={handleSelectDriveResource} value={resource && resource.ref ? resource.ref : null} {...additionalDriveProps} />
					</Col>
					{resource && resource.ref &&
						<Col sm={12}>
							<FontAwesomeIcon icon="check" /> Selected: {resource.ref.path}
						</Col>
					}

					<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left", marginBottom: "10px" }}>
						2.) Name
					</Col>
					<Col sm={12}>
						<FormControl value={resource && resource.name ? resource.name : ""} onChange={handleChangeResourceName} type="text" />
					</Col>
					<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left", marginBottom: "10px" }}>
						3.) (Optional) <i>Description</i>
					</Col>
					<Col sm={12}>
						<FormControl value={resource && resource.description ? resource.description : ""} onChange={handleChangeResourceDescription} type="textarea" />
					</Col>
					<Col sm={12} style={{ marginTop: "10px" }}>
						{showAdd && <Button disabled={!resource || !resource.name || !resource.ref} onClick={handleAddResource}><FontAwesomeIcon icon="check" /> Add</Button>}
						{showEdit && <Button disabled={!resource || !resource.name || !resource.ref} onClick={handleSaveResource}><FontAwesomeIcon icon="pencil" /> Save</Button>}
						<Button onClick={handleCancelAddEditResource}><FontAwesomeIcon icon="ban" /> Cancel</Button>
					</Col>
				</FormGroup>
			</Form>
		}
		{
			!showAdd && !showEdit && canAdd &&
			<Button onClick={() => { setShowAdd(true); }}><FontAwesomeIcon icon="plus-circle" /> Add</Button>
		}
	</>;
};
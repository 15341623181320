import React, {PureComponent} from "react";
import _ from "lodash";
import { FormGroup, ControlLabel, FormControl, HelpBlock, Row, Col, Checkbox } from "react-bootstrap";
import autoBind from "react-autobind";

class FieldGroup extends PureComponent {

	constructor(props) {
		super(props);
		this.inputFieldRef=null;
		autoBind(this);
	}

	toggleShowPassword() {
		console.log("Switching type ", this.inputFieldRef.type);
		let targetType = this.inputFieldRef.type === "password" ? "text" : "password";
		this.inputFieldRef.type = targetType;
		this.inputFieldRef.focus();
	}

	render() {
		let props = this.props;
		const { id, label, help, validationState } = props;
	
		let labelStyle = {};
		let fieldCols = 9;
		if (props.hideLabel) {
			labelStyle = {
				display: "none"
			};
			fieldCols = 12;
			props = _.omit(props, "hideLabel");
		}
		let showPasswordOptionComponent = this.props.showPasswordOption ? [
			<Checkbox key="save-password-checkbox" onChange={this.toggleShowPassword}>
				Show Password
    	</Checkbox>
		]:<span/>;

		return props.stretch ? (

			<FormGroup controlId={id} validationState={validationState}>
				<Row>
					<Col lg={3} style={labelStyle}>
						<ControlLabel>{label}</ControlLabel>
					</Col>
					<Col lg={fieldCols}>
						<FormControl inputRef={ref => { this.inputFieldRef = ref; }} key={id} {..._.omit(this.props, ["showPasswordOption"])} />
						{showPasswordOptionComponent}
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						{help && <HelpBlock>{help}</HelpBlock>}
					</Col>
				</Row>
			</FormGroup >
		)
			:
			(
				<FormGroup controlId={id} validationState={validationState}>
					<ControlLabel style={labelStyle}>{label}</ControlLabel>
					<FormControl inputRef={ref => { this.inputFieldRef = ref; }} key={id} {..._.omit(this.props, ["showPasswordOption"])} />
					{showPasswordOptionComponent}
					{help && <HelpBlock>{help}</HelpBlock>}
				</FormGroup >
			);
	
	}
}

export default FieldGroup;
import React, { Component } from "react";
import { branch, root } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import autoBind from "react-autobind";
// import { Button } from "react-bootstrap";

import { Page } from "../shared/";
import Drive from "./drive";
import { handlePageInit } from "../../utils/securityUtil";
import state from "../../state/state";
// import { handleSave } from "../../actions/drive";

class DrivePage extends Component {

	constructor(props) {
		super(props);
		handlePageInit(this.props.location, false, true);
		this.state = {
			showModal: false
		};
		autoBind(this);
	}


	render() {
		const { showModal } = this.state;
		return (
			<Page title="Drive" subTitle="Manage organization files" showSaveModal={showModal} flexLayout>
				{/* <Button onClic={() => handleSave({hello:"world"},"json")}>Show Modal</Button> */}
				<div style={{ flex: 1, flexGrow: 1, overflow: "hidden" }}>
					<Drive edit={true} />
				</div>
			</Page>
		);
	}
	componentDidMount() {
		this.context.tree.select(["appState", "navPanel"]).set("selected", "drive");
	}
}

DrivePage.contextTypes = {
	tree: PropTypes.baobab
};

export default root(state, branch({
	hierarchy: ["drive", "hierarchy"]
}, DrivePage));

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import Flexbox from "flexbox-react";
import { SketchPicker } from "react-color";

const ColorPicker = (props) => {
	const popoverColor =
		<Popover id={props.id} title={props.title}>
			<SketchPicker
				// disableAlpha={props.disableAlpha ? props.disableAlpha : true}
				color={props.color}
				onChangeComplete={props.onChangeColor}
				presetColors={props.presetColors}
			/>
		</Popover>;

	return (
		<Flexbox flexDirection="row">
			<OverlayTrigger trigger="click" rootClose placement={props.placement ? props.placement : "top"} overlay={popoverColor}>
				<div style={{ position: "relative", cursor: "pointer", border: "2px solid black", borderRadius: "5px", width: "32px", height: "32px", backgroundColor: props.color }}>
					{props.color === "" &&
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", left: 0, right: 0, bottom: 0, top: 0 }}>
							<FontAwesomeIcon style={{ color: "red", fontSize: 24 }} icon="ban" />
						</div>
					}
				</div>
			</OverlayTrigger>
			{(!props.hideClear && props.color !== "") &&
				<div style={{ marginLeft: "10px" }}>
					<Button bsSize="small" onClick={props.onClearColor}>Clear</Button>
				</div>
			}
		</Flexbox>
	);
};

export default ColorPicker;
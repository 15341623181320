import state from "../state/state";

export function clearStatusItems(tree) {
	tree.set(["appState", "statusItems"], []);
}

export function setStatusItems(items) {
	state.set(["appState", "statusItems"], items);
}

export function addStatusItem(tree, item) {
	tree.select(["appState", "statusItems"]).push(item);
}

export function selectStatusItem(item) {
	if (state.get(["appCapabilities", "multiSelect"])) {
		let selected = state.get(["appState", "selectedStatusItems"]);

		const index = selected.findIndex((newItem) => item.instanceId === newItem.instanceId)

		// Check if already selected, if so splice, if not push
		// const index = selected.indexOf(itemId);
		if (index > -1) {
			selected.splice(index, 1);
		} else {
			selected.push(item);
		}

		state.set(["appState", "selectedStatusItems"], Object.assign([], selected));
		console.log("Selected Status Items " + selected);
	}
}

export function resetSelectedStatusItems(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		tree.set(["appState", "selectedStatusItems"], Object.assign([]));
	}
}

export function selectAllStatusItems(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		const statusItems = tree.get(["appState", "statusItems"]);

		let selected = tree.get(["appState", "selectedStatusItems"]);
		for (let i = 0; i < statusItems.length; i++) {
			const statusItem = statusItems[i];
			if (!selected.find(item => item.instanceId === statusItem.instanceId)) {
				selected.push(statusItem);
			}
		}


		tree.set(["appState", "selectedStatusItems"], Object.assign([], selected));
	}
}

import "core-js/features/array/from";
import "core-js/features/array/find-index";
import "core-js/features/symbol";
import "core-js/features/symbol/iterator";
import "core-js/features/number/is-integer";
import "core-js/features/string/includes";

import React from "react";
import { render } from "react-dom";

import PlaylistsSearchMobile from "./components/playlists/playlistsSearchMobile";
import mainState from "./state/state";
import { isBrowserSupported, captureQueryString, setLs, isValidUrlWithParams } from "./utils/utils";

import qs from "qs";
import ReactModal from "react-modal";

import App from "./App";
import { restoreSession } from "./actions/portalActions";
import globals from "./constants/env";
import { showAlert, showConfirm } from "./actions/alertActions";
import { captureInviteCode, configureAmplify } from "./utils/securityUtil";
import { initializeConnectivityScreening } from "./utils/connectivityUtil";
import { initializePostMessageListeners } from "./actions/mobileAppCommunicationActions";
import * as Types from "./constants/types";
import { registerDriveEditors } from "./actions/drive";
import { registerOrgPreferences } from "./actions/orgsActions";
import StylesheetEditor from "./components/styling/editor";
import ThemeEditor from "./components/theme/editor";
import JsonEditor from "./components/drive/editors/jsonEditor";
import JsEditor from "./components/drive/editors/jsEditor";
import TextEditor from "./components/drive/editors/textEditor";
import PhoneticsManager from "./components/ttsPhonetics/phoneticsManager";
import "./utils/fontawesome";
import ls from "local-storage";

if (mainState.get(["removeAllLogs"])) {
	// console.info = function () { }
	console.log = function () { }
	console.warn = function () { }
	console.error = function () { }
}

if (!isBrowserSupported()) {
	// alert("Sorry, IE 11 is not supported :(\n\nIE 11 was released in 2013 and Microsoft has announced its end of life. AmbiFi runs on all modern browsers from Chrome to Firefox, to Microsoft Edge, to Apple Safari.");
	const renderError =
		<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "25px" }}>
			<div style={{ color: "white", maxWidth: "800px" }}>
				<div>
					<h1>Sorry, IE 11 is not supported :(</h1>
					<p>IE 11 was released in 2013 and Microsoft has announced its end of life. AmbiFi runs on all modern browsers from Chrome to Firefox, to Microsoft Edge, to Apple Safari.</p>
				</div>
			</div>
		</div>;
	render(renderError, document.getElementById("root"));
} else {
	// render(<PlaylistsSearchMobile />, document.getElementById("root"));
	// Actually check for what we consider valid urls!
	// if (window.location.pathname !== "" && window.location.pathname !== "/" && window.location.pathname !== "/login") {

	// Only accept certain URLs for redirect
	if (isValidUrlWithParams(window.location.pathname)) {
		console.info("SET ORIGIN", window.location.pathname);
		setLs("origin", window.location.pathname);
	} else {
		console.info("DONT SET ORIGIN BECAUSE / or /login");
	}


	// Delete if exist
	if (ls.get("ambifi-user-orgs")) {
		ls.remove("ambifi-user-orgs")
	}
	if (ls.get("ambifi-last-login-org")) {
		ls.remove("ambifi-last-login-org")
	}

	configureAmplify();
	initializeConnectivityScreening();
	initializePostMessageListeners();
	require("string.prototype.startswith"); // eslint-disable-line no-undef


	// kick off the polyfill!
	// smoothscroll.polyfill();

	// global function for inline linking
	//window.playerActionsInlineLinkUrl = ambifiWebPlayerActions.inlineLinkUrl;

	ReactModal.setAppElement('#root')

	// Creating our top-level component

	/**
	 * HS: This will override the default alerts, we can always remove this and use the
	 * fancy growls more selectively.
	 */
	if (globals.config.useFancyAlerts) {
		(() => {
			window.nativeAlert = window.alert;
			window.alert = function (message, cb) {
				showAlert(message, cb);
				// return proxied.apply(this, arguments);
				return false;
			};
			window.nativeConfirm = window.confirm;
			window.confirm = function (message, cb) {
				showConfirm(message, "Confirm", cb);
			};

		})(window.alert);
	} else {
		const alert2 = window.alert;
		window.nativeAlert = window.alert;
		window.alert = (message, cb) => {
			alert2(message);
			if (cb) {
				cb();
			}
		};
		const confirm2 = window.confirm;
		window.nativeConfirm = window.confirm;
		window.confirm = (message, cb) => {
			if (confirm2(message) && cb) {
				cb();
			}
		};
	}
	captureQueryString();
	captureInviteCode();

	const urlParsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	if (urlParsed) {
		mainState.set(["appState", "urlParams"], urlParsed);

		// Set any params here need throughout
		if (urlParsed.hasOwnProperty("separateOutlineAndProps")) {
			mainState.set(["editor", "separateOutlineAndProps"], urlParsed.separateOutlineAndProps === "true" ? true : false);
			mainState.set(["appState", "editor", "separateOutlineAndProps"], urlParsed.separateOutlineAndProps === "true" ? true : false);
		}

		if (urlParsed.hasOwnProperty("previewVisible")) {
			mainState.set(["editor", "previewVisible"], urlParsed.previewVisible === "true" ? true : false);
			mainState.set(["appState", "editor", "previewVisible"], urlParsed.previewVisible === "true" ? true : false);
		}

	} else {
		console.warn("Parsed URL is not a valid Object", urlParsed);
	}

	window.addEventListener("beforeunload", (e) => {
		if (window.location.href.indexOf("/editor/") !== -1 || window.location.href.indexOf("/player/") !== -1) {
			const message = "Are you sure you want to leave this page? All unsaved changes will be lost.";
			(e || window.event).returnValue = message;
			return message;
		} else {
			return null;
		}
	}, true);

	const { TYPE_AMBIFI_PHONETICS, TYPE_AMBIFI_THEME, TYPE_AMBIFI_STYLESHEET, TYPE_AMBIFI_PLAYLISTS, TYPE_AMBIFI_WORKFLOW_STATES } = Types;

	registerDriveEditors({
		"extension/json": {
			title: "JSON Document",
			icon: ["far", "file-code"],
			type: "application/json",
			contentType: "application/json",
			extension: "json",
			component: JsonEditor
		},
		"extension/js": {
			title: "Javascript Document",
			icon: ["far", "file-code"],
			type: "text/javascript",
			contentType: "text/javascript",
			extension: "js",
			component: JsEditor
		},
		[TYPE_AMBIFI_PHONETICS]: {
			title: "AmbiFi TTS Phonetics",
			icon: "language",
			type: TYPE_AMBIFI_PHONETICS,
			contentType: "application/json",
			extension: "json",
			component: PhoneticsManager
		},
		[TYPE_AMBIFI_THEME]: {
			title: "Activity Theme",
			icon: "paint-brush",
			type: TYPE_AMBIFI_THEME,
			contentType: "application/json",
			extension: "json",
			component: ThemeEditor
		},
		[TYPE_AMBIFI_STYLESHEET]: {
			title: "Activity Stylesheet",
			icon: "paint-brush",
			type: TYPE_AMBIFI_STYLESHEET,
			contentType: "application/json",
			extension: "json",
			component: StylesheetEditor
		},
		[TYPE_AMBIFI_STYLESHEET]: {
			title: "Playlists",
			icon: ["far", "file-code"],
			type: TYPE_AMBIFI_PLAYLISTS,
			contentType: "application/json",
			extension: "json",
			component: JsonEditor
		},
		[TYPE_AMBIFI_WORKFLOW_STATES]: {
			title: "Activity Wokflow States",
			icon: "cogs",
			type: TYPE_AMBIFI_WORKFLOW_STATES,
			contentType: "application/json",
			extension: "json",
			component: JsonEditor
		},
		"ambifi/transcription": {
			title: "Transcription",
			icon: ["far", "file-code"],
			type: "ambifi/transcription",
			contentType: "application/json",
			extension: "json",
			component: JsonEditor
		},
		"extension/vtt": {
			title: "Video Text Tracks",
			icon: ["far", "file-code"],
			type: "text/vtt",
			contentType: "text/vtt",
			extension: "vtt",
			component: TextEditor
		},
		"extension/srt": {
			title: "SubRip File (Subtitles)",
			icon: ["far", "file-code"],
			type: "application/x-subrip",
			contentType: "application/x-subrip",
			extension: "srt",
			component: TextEditor
		},
		// "ambifi/subtitles": {
		// 	title: "SubRip File (Subtitles)",
		// 	icon: ["far", "file-code"],
		// 	type: "ambifi/subtitles",
		// 	contentType: "text/vtt",
		// 	extension: "srt",
		// 	component: TextEditor
		// },
		// "ambifi/subtitles-vtt": {
		// 	title: "SubRip File (Subtitles)",
		// 	icon: ["far", "file-code"],
		// 	type: "ambifi/subtitles-vtt",
		// 	contentType: "application/x-subrip",
		// 	extension: "vtt",
		// 	component: TextEditor
		// }
	});

	registerOrgPreferences([
		{
			title: "Text-to-Speech Phonetics",
			description: "Select or create a dictionary of phonetics to help your assistant to pronounce your organizations vocabulary properly.",
			driveResource: true,
			driveResourceType: TYPE_AMBIFI_PHONETICS,
			singleSelect: true,
			orgPreferenceName: "tts-phonetics",
			enableEditing: true,
			showEditorWhenEmpty: true,
			savePathWhenEmpty: "private/_system/tts-phonetics/tts-phonetics.json"
		},
		{
			title: "Activity Theme",
			description: "Select or create a global activity theme that will be applied to all activities of your organization.",
			driveResource: true,
			driveResourceType: TYPE_AMBIFI_THEME,
			singleSelect: true,
			orgPreferenceName: "theme",
			enableEditing: true,
			showEditorWhenEmpty: true,
			savePathWhenEmpty: "private/_system/themes/organization-theme.json"
		},
		{
			title: "Activity Stylesheet",
			description: "Select or create a global activity stylesheet that will be applied to all activities of your organization. It allows finer level control over the look and feel of the activity and also allows to define classes that can then be applied to items.",
			driveResource: true,
			driveResourceType: TYPE_AMBIFI_STYLESHEET,
			singleSelect: true,
			orgPreferenceName: "stylesheet",
			enableEditing: true,
			showEditorWhenEmpty: true,
			savePathWhenEmpty: "private/_system/stylesheets/organization-stylesheet.json"
		},
		{
			title: "Fonts",
			description: "Paste the URL to one or more fonts, these fonts will then be included in your activities for use.",
			driveResource: false,
			componentType: "codemirror",
			componentOptions: {
				mode: "htmlmixed",
				lineNumbers: true
			},
			multiline: true,
			orgPreferenceName: "fonts",
			enableEditing: true
		},
		{
			title: "Playlists",
			description: "Select or create global playlists.",
			driveResource: true,
			driveResourceType: TYPE_AMBIFI_PLAYLISTS,
			singleSelect: true,
			orgPreferenceName: "playlists",
			enableEditing: true,
			showEditorWhenEmpty: true,
			savePathWhenEmpty: "private/_system/playlists/organization-playlists.json"
		},
		{
			title: "Organization Search Index",
			description: "Configuration properties for the organization search index.",
			driveResource: false,
			componentType: "codemirror",
			componentOptions: {
				mode: "javascript",
				lineNumbers: true
			},
			multiline: true,
			orgPreferenceName: "org-search-index",
			enableEditing: true
		},
		{
			title: "Status Search Index",
			description: "Configuration properties for the status search index.",
			driveResource: false,
			componentType: "codemirror",
			componentOptions: {
				mode: "javascript",
				lineNumbers: true
			},
			multiline: true,
			orgPreferenceName: "status-search-index",
			enableEditing: true
		}
	]);

	// Rendering the app
	render(<App />, document.getElementById("root"), restoreSession);

	// (async () => {
	// 	try {
	// 		await restoreSession();

	// 		// Rendering the app
	// 		render(<App />, document.getElementById("root"));
	// 	} catch (e) {
	// 	}
	// })();
}

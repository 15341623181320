
/* @jsx node */

import React from "react";
import ReactDOM from 'react-dom';
import * as zoid from "zoid/dist/zoid.frameworks";
import { node, dom } from 'jsx-pragmatic'; //eslint-disable-line
import env from "../../constants/env";
import { getOrgId } from "../../actions/orgsActions";

let { tag, path } = env.zoid.components.ambifiPlayerWeb;

let style = `
	html, body {
		width: 100%;
		height: 100%;
		background-color: rgb(102, 102, 102);
		overflow: hidden;
	}

	.spinner {
		position: fixed;
		max-height: 60vmin;
		max-width: 60vmin;
		height: 40px;
		width: 40px;
		top: 50%;
		left: 50%;
		box-sizing: border-box;
		border: 3px solid rgba(0, 0, 0, .2);
		border-top-color: rgba(33, 128, 192, 0.8);
		border-radius: 100%;
		animation: rotation .7s infinite linear;
	}

	@keyframes rotation {
		from {
			transform: translateX(-50%) translateY(-50%) rotate(0deg);
		}
		to {
			transform: translateX(-50%) translateY(-50%) rotate(359deg);
		}
	}
`;

const launchUrl = window.location.href.replace("https://", "");
const launchUrlParts = launchUrl.split(".");

const zoidUrl = `https://${launchUrlParts[0]}.ambifi.com/zoidComponents`;

let fullZoidUrl = `${zoidUrl}${path}`;

// If running localhost, point to local zoid instance
if (window.location.href.startsWith("http://localhost:3000")) {
	fullZoidUrl = "http://localhost:3001/index.html";
}

let AmbifiPlayerWebZoid = zoid.create({
	tag: tag,
	url: fullZoidUrl,
	dimensions: {
		width: "100%",
		height: "100%"
	},
	prerenderTemplate: function ({ doc }) {
		return (
			<html>
				<body>
					<div class="spinner"></div>
					<style>{style}</style>
				</body>
			</html>
		).render(dom({ doc }));
	},
	defaultContext: "popup"
});

const PlayerZoidReact = AmbifiPlayerWebZoid.driver("react", {
	React: React,
	ReactDOM: ReactDOM
});

export default PlayerZoidReact;
import request from "superagent";
import Baobab from "baobab";
import _ from "lodash";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid';

import env from "../constants/env";
import * as userActions from "./user";
import state from "../state/state";
import { showError, showError2, showSuccess } from "./alertActions";
import { refreshCredentials } from "../utils/securityUtil";
import { navigateTo } from "../actions/navActions";
import { getLs, setLs } from "../utils/utils";
import { isValidEmail, isValidExtendedEmail, getExtendedEmail } from "../utils/validationUtils";

let fetchPendingInvitesAfterInit = false;

export function selectMember(memberId) {
	if (state.get(["appCapabilities", "multiSelect"])) {
		let selectedMembers = state.get(["appState", "selectedMembers"]);

		// Check if already selected, if so splice, if not push
		const index = selectedMembers.indexOf(memberId);
		if (index > -1) {
			selectedMembers.splice(index, 1);
		} else {
			selectedMembers.push(memberId);
		}

		state.set(["appState", "selectedMembers"], Object.assign([], selectedMembers));
		console.log("Selected Members " + selectedMembers);

	}
}

export function resetSelectedMembers(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		tree.set(["appState", "selectedMembers"], Object.assign([]));
	}
}

export function selectAllMembers(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		const members = tree.get(["selectedOrg", "members"]);

		let selectedMembers = [];
		for (let i = 0; i < members.length; i++) {
			const member = members[i];

			selectedMembers.push(member.userSubId);
		}

		tree.set(["appState", "selectedMembers"], Object.assign([], selectedMembers));

	}
}

function addOrgsToLocalStorage(org) {
	let lcOrgs = getLs("ambifi-user-orgs");
	if (!lcOrgs || lcOrgs === "") {
		lcOrgs = "[]";
	}

	let _lcOrgs = JSON.parse(lcOrgs);
	const _newLcOrgs = [org];
	_lcOrgs.forEach(_org => {
		if (_org.id !== org.id) {
			// found it@
			_newLcOrgs.push(_org);
		}
	});
	setLs("ambifi-user-orgs", JSON.stringify(_newLcOrgs));
}

function setLastLoginOrg(org) {
	setLs("ambifi-last-login-org", JSON.stringify(org));
}

export function deleteLastLoginOrg() {
	setLs("ambifi-last-login-org", "");
}

export function getLastLoginOrg() {
	let lsOrgs = getLs("ambifi-last-login-org");
	let result = null;
	if (lsOrgs) {
		try {
			result = JSON.parse(lsOrgs);
		} catch (e) {
			console.warn("Unable to parse orgs from local storage", e);
		}
	}
	return result;
}

export function initializeOrganizationsV2(orgInfoResult) {
	return new Promise((resolve, reject) => {

		console.log("Fetched orgs", orgInfoResult);
		const orgs = orgInfoResult;
		state.set(["orgs"], orgs);
		if (orgs && orgs.length > 0) {
			const selectedOrg = orgs[0];
			// setLastLoginOrg(selectedOrg);
			// addOrgsToLocalStorage(selectedOrg);
			// Ok now we also want to make sure that this is captured in local storage for use later


			if (selectedOrg.member) {
				state.set(["user", "name"], selectedOrg.member.displayName);
			}

			if (!selectedOrg.hasOwnProperty("members")) {
				selectedOrg.members = [];
			}

			selectedOrg._groups = _.clone(selectedOrg.groups);

			const monkey = Baobab.monkey;

			selectedOrg.membersMap = monkey({
				cursors: {
					members: ["selectedOrg", "members"]
				},
				get: (data) => {

					const membersMap = {};
					if (data.members) {
						data.members.forEach(member => {
							membersMap[member.id] = member;
							membersMap[member.userSubId] = member;
						});
					}
					return membersMap;
				}
			});

			selectedOrg.groups = monkey({
				cursors: {
					groups: ["selectedOrg", "_groups"],
					membersMap: ["selectedOrg", "membersMap"]
				},
				get: (data) => {
					const result = [];
					if (data.groups) {
						data.groups.forEach(group => {
							const groupMembers = [];

							group.members.forEach(memberId => {
								if (data.membersMap[memberId]) {
									groupMembers.push(data.membersMap[memberId]);
								}

							});

							result.push({
								id: group.id,
								displayName: group.displayName,
								members: groupMembers,
								description: group.description
							});
						});
					}
					return result;
				}
			});

			selectedOrg.groupsMap = monkey({
				cursors: {
					groups: ["selectedOrg", "groups"]
				},
				get: (data) => {
					const result = [];
					if (data.groups) {
						data.groups.forEach(group => {
							result[group.id] = group;
						});
					}
					return result;
				}
			});

			state.set(["selectedOrg"], selectedOrg);

			// injectMonkeysMemberSelected();

			if (fetchPendingInvitesAfterInit) {
				fetchPendingInvitesAfterInit = false;
				getPendingInvites();
			}

			resolve();
			// Single member of no org
		} else {
			reject("Not a member of an org.");
		}

	});
}

export function fetchOrganizationsForUser(credentials) {
	return new Promise((resolve, reject) => {
		request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.getCurrentUserMembershipInfoEndpoint}${userActions.getIdentityId()}`).set({ Authorization: userActions.getJwtToken(credentials) }).then(res => {

			console.log("Fetched orgs", res.body);
			const orgs = res.body;
			state.set(["orgs"], orgs);
			if (orgs && orgs.length > 0) {
				const selectedOrg = orgs[0];

				if (selectedOrg.member) {
					state.set(["user", "name"], selectedOrg.member.displayName);
				}

				if (!selectedOrg.hasOwnProperty("members")) {
					selectedOrg.members = [];
				}

				selectedOrg._groups = _.clone(selectedOrg.groups);

				const monkey = Baobab.monkey;

				selectedOrg.membersMap = monkey({
					cursors: {
						members: ["selectedOrg", "members"]
					},
					get: (data) => {

						const membersMap = {};
						if (data.members) {
							data.members.forEach(member => {
								membersMap[member.id] = member;
								membersMap[member.userSubId] = member;
							});
						}
						return membersMap;
					}
				});

				selectedOrg.groups = monkey({
					cursors: {
						groups: ["selectedOrg", "_groups"],
						membersMap: ["selectedOrg", "membersMap"]
					},
					get: (data) => {
						const result = [];
						if (data.groups) {
							data.groups.forEach(group => {
								const groupMembers = [];

								group.members.forEach(memberId => {
									if (data.membersMap[memberId]) {
										groupMembers.push(data.membersMap[memberId]);
									}

								});

								result.push({
									id: group.id,
									displayName: group.displayName,
									members: groupMembers,
									description: group.description
								});
							});
						}
						return result;
					}
				});

				selectedOrg.groupsMap = monkey({
					cursors: {
						groups: ["selectedOrg", "groups"]
					},
					get: (data) => {
						const result = [];
						if (data.groups) {
							data.groups.forEach(group => {
								result[group.id] = group;
							});
						}
						return result;
					}
				});

				state.set(["selectedOrg"], selectedOrg);

				// injectMonkeysMemberSelected();

				if (fetchPendingInvitesAfterInit) {
					fetchPendingInvitesAfterInit = false;
					getPendingInvites();
				}

				resolve(res.body);
				// Single member of no org
			} else {
				reject("Not a member of an org.");
			}
		}).catch(err => {
			console.error(err);
			reject(err);
		});

	});
}

function injectMonkeysMemberSelected() {
	const members = state.get(["selectedOrg", "members"]);

	// Filter out only ones selected
	for (let i = 0; i < members.length; i++) {
		let member = members[i];

		// Create a monkey to get selected state for multi-selection
		state.set(["selectedOrg", "members", i, "selected"],
			Baobab.monkey({
				cursors: {
					selectedMembers: ["appState", "selectedMembers"]
				},
				get: (data) => {
					if (data) {
						const index = data.selectedMembers.indexOf(member.userSubId);

						if (index > -1) {
							return true;
						} else {
							return false;
						}
					}
					return false;
				}
			}));
	}
}

export function captureInviteKeyValue(key, value) {
	state.select(["inviteMember"]).set(key, value);
}

export function showMemberPanelSpinner() {
	state.set(["appState", "spinners", "orgs", "showMembersPanelSpinner"], true);
}

export function hideMemberPanelSpinner() {
	state.set(["appState", "spinners", "orgs", "showMembersPanelSpinner"], false);
}

export function showSpinner(spinner = "page") {
	state.set(["appState", "spinners", "orgs", spinner], true);
}
export function hideSpinner(spinner = "page") {
	state.set(["appState", "spinners", "orgs", spinner], false);
}

function showSaveOrgSpinner() {
	showSpinner("showSaveOrgSpinner");
}

function hideSaveOrgSpinner() {
	hideSpinner("showSaveOrgSpinner");
}

export function inviteMember(email, role, displayName) {
	let newRole = role;
	// If no role supplied then make a 0 which is User role
	if (!newRole) {
		newRole = 0;
	}

	return new Promise((resolve, reject) => {
		// showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const organizationId = state.get(["selectedOrg", "id"]);
			// const email = state.get(["inviteMember", "email"]);
			// const role = state.get(["inviteMember", "role"]);
			// const displayName = state.get(["inviteMember", "displayName"]);

			if (isValidEmail(email)) {
				// No op
			} else if (isValidExtendedEmail(email)) {
				// Get email from extended email
				const extEmail = getExtendedEmail(email);
				email = extEmail.email;
				if (extEmail.name && extEmail.name !== "") {
					displayName = extEmail.name;
				}
			} else {
				showError("Invite Member", "The email " + email + " is invalid.");
				reject("The email " + email + " is invalid");
				return;
			}

			const payload = {
				organizationId,
				email,
				role
			};

			if (displayName && displayName !== "") {
				payload.displayName = displayName;
			}

			console.log(payload);

			// JJB: Ideally would have an endpoint where could send a batch of email addresses
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgInvite}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(payload)).then(async res => {
					console.log(res);

					// hideMemberPanelSpinner();
					state.set(["selectedOrg", "members"], res.body.members);
					// showSuccess("Member Invite", `Invite to ${email} has been sent.`);
					// getPendingInvites();
					resolve(res);
				}).catch(err => {
					console.error(err);
					// hideMemberPanelSpinner();
					reject(err);
				});
		});

	});
}

export function createInviteLink(email, role, displayName) {
	let newRole = role;
	// If no role supplied then make a 0 which is User role
	if (!newRole) {
		newRole = 0;
	}

	return new Promise((resolve, reject) => {
		// showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const organizationId = state.get(["selectedOrg", "id"]);
			// const email = state.get(["inviteMember", "email"]);
			// const role = state.get(["inviteMember", "role"]);
			// const displayName = state.get(["inviteMember", "displayName"]);

			if (isValidEmail(email)) {
				// No op
			} else if (isValidExtendedEmail(email)) {
				// Get email from extended email
				const extEmail = getExtendedEmail(email);
				email = extEmail.email;
				if (extEmail.name && extEmail.name !== "") {
					displayName = extEmail.name;
				}
			} else {
				showError("Invite Member", "The email " + email + " is invalid.");
				reject("The email " + email + " is invalid");
				return;
			}

			const payload = {
				organizationId,
				email,
				role
			};

			if (displayName && displayName !== "") {
				payload.displayName = displayName;
			}

			console.log(payload);

			// JJB: Ideally would have an endpoint where could send a batch of email addresses
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgCreateInviteLink}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(payload)).then(async res => {
					console.log(res);

					// hideMemberPanelSpinner();
					state.set(["selectedOrg", "members"], res.body.members);
					// showSuccess("Member Invite", `Invite to ${email} has been sent.`);
					// getPendingInvites();
					resolve(res);
				}).catch(err => {
					console.error(err);
					// hideMemberPanelSpinner();
					reject(err);
				});
		});

	});
}

export function uploadImage(file) {
	showSaveOrgSpinner();
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const selectedOrg = state.select(["selectedOrg"]).get();
			if (!selectedOrg) {
				showError("An organization must be selected before attempting to upload a logo.");
				return;
			}
			if (file === "none") {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgs}`)
					.set("Authorization", userActions.getJwtToken())
					.set("Content-Type", "application/json")
					.send(JSON.stringify({
						id: selectedOrg.id,
						logoUrl: null
					}))
					.then(() => {
						state.select(["selectedOrg"]).set("logoUrl", null);
						hideSaveOrgSpinner();
						showSuccess("Organization Logo", "Successfully cleared organization logo");
					}).catch(err => {
						hideSaveOrgSpinner();
						showError("Organization Logo Reset Failed", `Failed to reset organization logo, reason: ${err && err.response ? err.response.body.message : err}`, () => {
							reject(err);
						});
					});
			} else if (file) {
				const s3 = new AWS.S3({ useDualStack: true, apiVersion: "2006-03-01" });
				const Key = `${selectedOrg.id}/assets/${uuidv4()}.${_.last(file.name.split("."))}`;
				s3.putObject({
					Bucket: env.s3.assetsBucket,
					Key,
					Body: file
				}, (err) => {
					if (err) {
						console.error(err);
						hideSaveOrgSpinner();
						showError("Organization Logo", `Failed to upload the organization logo, reason: ${err.message}`);
					} else {
						const logoUrl = `${env.assetsUrl}/${Key}`;
						request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgs}`)
							.set("Authorization", userActions.getJwtToken())
							.set("Content-Type", "application/json")
							.send(JSON.stringify({
								id: selectedOrg.id,
								logoUrl
							}))
							.then(() => {
								state.select(["selectedOrg"]).set("logoUrl", logoUrl);
								hideSaveOrgSpinner();
								showSuccess("Organization Logo", "Uploaded new logo successfully");
							}).catch(err => {
								hideSaveOrgSpinner();
								showError("Organization Logo Save Failed", `Failed to save the new organization logo, reason: ${err && err.response ? err.response.body.message : err}`, () => {
									reject(err);
								});
							});
					}
				});
			}


		}).catch(err => {
			hideSaveOrgSpinner();
			showError(`Organization Logo", "Unable to perform action, failed to refersh credentials, reason: ${err.message}`);
		});
	});
}

export function uploadProfilePicture(file, identityId) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(async () => {
			const selectedOrg = state.select(["selectedOrg"]).get();

			if (file === "none") {
				// Update Cognito profilePicture
				// await userActions.saveProfilePicture("");

				resolve("");
			} else if (file) {
				const s3 = new AWS.S3({ useDualStack: true, apiVersion: "2006-03-01" });
				const Key = `${selectedOrg.id}/assets/profilePictures/${identityId}.${_.last(file.name.split("."))}`;
				s3.putObject({
					Bucket: env.s3.assetsBucket,
					Key,
					Body: file
				}, async (err) => {
					if (err) {
						console.error(err);

						showError("Profile Picture", `Failed to upload the profile picture, reason: ${err.message}`);

						reject(err);
					} else {
						// const profilePicture = `${env.assetsUrl}/${Key}`;

						// Update Cognito profilePicture
						// await userActions.saveProfilePicture(profilePicture);

						resolve(`${env.assetsUrl}/${Key}`);
					}
				});
			}
		}).catch(err => {
			showError(`Profile Picture", "Unable to perform action, failed to refersh credentials, reason: ${err.message}`);

			reject(err);
		})
	});
}

export function saveOrg() {
	showSaveOrgSpinner();
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const selectedOrg = state.select(["selectedOrg"]).get();
			const name = getEditOrgProp("name");
			if (name) {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgs}`)
					.set("Authorization", userActions.getJwtToken())
					.set("Content-Type", "application/json")
					.send(JSON.stringify({
						id: selectedOrg.id,
						name
					}))
					.then(() => {
						hideSaveOrgSpinner();
						state.select(["selectedOrg"]).set("name", name);
						showSuccess("Organization Updated", "Uploaded organization successfully");
					}).catch(err => {
						hideSaveOrgSpinner();
						showError("Organization Update Failed", `Failed to save organization changes, reason: ${err && err.response ? err.response.body.message : err}`, () => {
							reject(err);
						});
					});
			} else {
				hideSaveOrgSpinner();
				resolve();
			}
		});

	});
}

export function setEditOrgProp(name, value) {
	state.set(["editOrg", name], value);
}

export function getEditOrgProp(name) {
	return state.get(["editOrg", name]);
}

let inviteProcessed = false;

export function handleInviteAccept() {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const secret = state.get(["inviteMember", "inviteCode"]);
			const displayName = state.get(["signup", "displayName"]);
			if (!inviteProcessed && secret) {
				inviteProcessed = true;
				const payload = {
					secret
				};
				if (displayName) {
					payload.displayName = displayName;
				}
				// Ok accept it
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.acceptOrgInvite}`)
					.set("Authorization", userActions.getJwtToken())
					.set("Content-Type", "application/json")
					.send(JSON.stringify(payload))
					.then(res => {
						state.set(["inviteMember", "inviteCode"], null); // Clear the code if the invite accept worked
						showSuccess("Invite Accepted", `Your invite code has been redeemed, welcome to ${res.body.name}.`, () => {
							state.set(["appState", "loading"], true); // Logged in
							navigateTo("myChecklists");
							resolve(res);
						});
					}).catch(err => {
						showError("Invite Not Accepted", `Failed to redeem the provided invite code, reason: ${err.response.body.message}`, () => {
							navigateTo("login");
							reject(err);
						});
					});
			} else {
				resolve();
			}
		});
	});
}

/**
 * NOTE: This is a very rudimentary first version of this capability, eventually roles should be better managed.
 * @param {*} memberId 
 * @param {*} role 
 */
export function changeRole(memberId, role) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const fullMember = state.get(["selectedOrg", "membersMap", memberId]);

			const member = _.pick(_.cloneDeep(state.get(["selectedOrg", "membersMap", memberId])), ["id", "administrator", "author"]);

			switch (role) {
				case 0: {
					member.administrator = false;
					member.author = false;
					break;
				}
				case 1: {
					member.administrator = false;
					member.author = true;
					break;
				}
				case 2: {
					member.administrator = true;
					member.author = false;
					break;
				}
				default: {
					throw new Error("Expecting role to be a number between 0 and 2.");
				}
			}

			member.userSubId = fullMember.userSubId;

			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`)
				.set("Authorization", userActions.getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify(member))
				.then(res => {
					let _updatedMember = _.cloneDeep(state.get(["selectedOrg", "membersMap", memberId]));
					_updatedMember.administrator = member.administrator;
					_updatedMember.author = member.author;
					updateMember(_updatedMember);
					showSuccess("Member Updated", `Member ${_updatedMember.displayName} has been updated successfully.`, () => {
						resolve(res);
					});
				}).catch(err => {
					console.error(err);
					showError("Member Update Failed", `Failed to perform the updates for the selected member, reason: ${err.response.body.message}`, () => {
						reject(err);
					});
				});
		});
	});
}

function updateMember(member) {
	const members = state.get(["selectedOrg", "members"]);

	const idx = _.findIndex(members, { id: member.id });
	if (idx > -1) {
		state.select(["selectedOrg", "members"]).splice([idx, 1, member]);
	}
}

export function updateMemberInfoInDb(memberId, memberUserSubId, memberInfo) {
	console.log("$#$#$# UPDATE MEMBER INFO", `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`, userActions.getJwtToken(), memberId, memberInfo);

	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`)
				.set("Authorization", userActions.getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify({
					id: memberId,
					memberUserSubId: memberUserSubId,
					memberInfo: JSON.stringify(memberInfo)
				}))
				.then(res => {
					resolve(res);
				}).catch(err => {
					console.error(err);
					showError("Member Info Update Failed", `Failed to perform the updates for the selected member, reason: ${err.response.body.message}`, () => {
						reject(err);
					});
				});
		});
	});
}

export function updateMemberInDb(memberId, displayName, timeZone, profilePicture, displayNameChanged, profilePictureChanged) {
	console.log("$#$#$# MEMBER", `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`, userActions.getJwtToken(), memberId, profilePicture);

	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`)
				.set("Authorization", userActions.getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify({
					id: memberId,
					displayName: displayName,
					timeZone: timeZone,
					userProfileImage: profilePicture !== "" ? profilePicture : "none",
					userSubId: userActions.getIdentityId(),
					displayNameChanged: displayNameChanged,
					userProfileImageChanged: profilePictureChanged
				}))
				.then(res => {
					resolve(res);
				}).catch(err => {
					console.error(err);
					showError("Member Update Failed", `Failed to perform the updates for the selected member, reason: ${err.response.body.message}`, () => {
						reject(err);
					});
				});
		});
	});
}

export function getOrgId() {
	return state.get(["selectedOrg", "id"]);
}

export function removeUser(memberId) {
	return new Promise((resolve, reject) => {
		showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const orgId = state.get(["selectedOrg", "id"]);
			request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.removeMember(orgId, memberId)}`)
				.set("Authorization", userActions.getJwtToken())
				.then(res => {
					hideMemberPanelSpinner();
					state.set(["selectedOrg", "members"], res.body.members);
					resolve(res);
				}).catch(err => {
					showError("Delete Member", `Failed to remove member, reason: ${err && err.response && err.response.body ? err.response.body.message : "Unknown"}`, () => {
						hideMemberPanelSpinner();
						reject(err);
					});
				});
		});
	});
}

export function reactivateUser(memberId) {
	return new Promise((resolve, reject) => {
		showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const orgId = state.get(["selectedOrg", "id"]);
			request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.reactivateMember(orgId, memberId)}`)
				.set("Authorization", userActions.getJwtToken())
				.then(res => {
					hideMemberPanelSpinner();
					state.set(["selectedOrg", "members"], res.body.members);
					resolve(res);
				}).catch(err => {
					showError("Reactivate Member", `Failed to reactivate member, reason: ${err && err.response && err.response.body ? err.response.body.message : "Unknown"}`, () => {
						hideMemberPanelSpinner();
						reject(err);
					});
				});
		});
	});
}

export function getSearchIndexName() {
	const orgId = state.get(["selectedOrg", "id"]);
	return `${env.algolia.index}_org_${orgId}`;
}

export function getHistoryIndexName() {
	const orgId = state.get(["selectedOrg", "id"]);
	return `${env.algolia.indexHistory}_org_${orgId}`;
}

export function getGroups() {
	let result = [];
	try {
		result = state.get(["selectedOrg", "groups"]);
	} catch (e) {
		console.error(e);
	}
	return result;
}

export function getMembers() {
	let result = [];
	try {
		result = state.get(["selectedOrg", "members"]);
	} catch (e) {
		console.error(e);
	}
	return result;
}

export function getMemberBySubForSelectedOrg(sub) {
	return state.get(["selectedOrg", "membersMap", sub]);
}

export function getSelectedOrg() {
	return state.get("selectedOrg");
}

export function getDefaultMembers(ignoreSelf = true, filter) {

	// Don't allow to share with logged in user
	const selectedOrg = getSelectedOrg();

	let defaultMembers = [];

	if (selectedOrg) {
		// If filter, apply

		let selectedOrgMembers = selectedOrg.members;
		if (filter) {
			selectedOrgMembers = _.filter(selectedOrgMembers, filter);
		}

		for (let i = 0; i < selectedOrgMembers.length; i++) {
			let member = selectedOrgMembers[i];
			let currentMember = state.get(["selectedOrg", "member"]);
			if (!ignoreSelf || (ignoreSelf && member.userSubId !== currentMember.userSubId)) {

				let memberStatus = "Active";
				if (!member.active) {
					// Removed member!
					memberStatus = member.cognitoUserSubId && member.cognitoUserSubId !== "" ? "Suspended" : "Pending Invite";
				}

				let displayName = member.displayName && member.displayName !== "" ? member.displayName : member.inviteEmail;

				if (!member.active) {
					displayName = `${displayName} (${memberStatus})`
				}

				if (memberStatus !== "Suspended") {
					// Ignore suspended users
					defaultMembers.push({
						value: member.userSubId,
						label: displayName
					});
				}
			}
		}
	}
	return defaultMembers;
}

export function getDefaultMembersForGroup(groupNames) {
	const groups = state.get(["selectedOrg", "groups"]);

	const newUsers = [];

	groups.forEach(element => {
		// Hardwired for now
		// Want this to be an org preference and then use guid for the group
		if (groupNames.includes(element.displayName)) {
			element.members.forEach(element => {
				if (element.userSubId && element.userSubId !== null && element.userSubId !== "null") {
					if (!newUsers.includes(element.userSubId)) {
						newUsers.push({
							value: element.userSubId,
							label: element.displayName
						});
					}
				}
			})
		}
	});

	return newUsers;
}


/** Group Management */

export function addMembersToGroup(memberIds, groupId) {
	memberIds.forEach(memberId => {
		addMemberToGroup(memberId, groupId);
	});
}

export function addMemberToGroup(memberId, groupId) {
	console.log("Adding member to group", memberId, groupId);
}

function showCreateEditGroupSpinner() {
	state.set(["appState", "groupManagement", "showCreateUpdateSpinner"], true);
}

function hideCreateEditGroupSpinner() {
	state.set(["appState", "groupManagement", "showCreateUpdateSpinner"], false);
}

export function editGroup(groupId) {
	let group = getGroupById(groupId);
	if (group) {
		state.set(["groupManagement"], group);
		showCreateEditGroupModal();
	} else {
		showError("Group Edit", "Unable to find selected group, therefore unable to edit.");
	}
}

export function createGroup() {
	return new Promise((resolve, reject) => {
		showCreateEditGroupSpinner();
		return persistGroup().then((groups) => {
			state.set(["selectedOrg", "_groups"], groups);
			hideCreateEditGroupSpinner();
			hideCreateEditGroupModal();
			resolve(groups);
		}).catch(err => {
			console.error(err);
			hideCreateEditGroupSpinner();
			reject(err);
		});
	});

}

export function updateGroup() {
	return new Promise((resolve, reject) => {
		showCreateEditGroupSpinner();
		return persistGroup(true).then((groups) => {
			state.set(["selectedOrg", "_groups"], groups);
			hideCreateEditGroupSpinner();
			hideCreateEditGroupModal();
			resolve(groups);
		}).catch(err => {
			console.error(err);
			hideCreateEditGroupSpinner();
			reject(err);
		});
	});
}

export function getPendingInvites() {
	return new Promise((resolve, reject) => {
		showSpinner("page");
		setTimeout(() => {
			refreshCredentials().then(() => {
				const selectedOrg = getSelectedOrg();
				if (!selectedOrg || !selectedOrg.id) {
					fetchPendingInvitesAfterInit = true;
					resolve();
				} else {
					request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.members(selectedOrg.id)}?pending=true`)
						.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
						.then(res => {
							hideSpinner("page");
							if (res.status !== 200) {
								reject(res.body);
							} else {
								state.set(["selectedOrg", "_pendingInvites"], res.body);
								resolve(res.body);
							}
						}).catch(err => {
							console.error(err);
							hideSpinner("page");
							reject(err);
						});
				}

			}).catch(err => {
				console.error(err);
				hideSpinner("page");
				reject(err);
			});
		}, 100);

	});
}

export function resendInvite(email) {
	return new Promise((resolve, reject) => {
		showSpinner("page");
		refreshCredentials().then(() => {
			const organizationId = state.get(["selectedOrg", "id"]);
			console.log({
				organizationId,
				email
			});
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgInvite}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(
					{
						organizationId,
						email
					}
				)).then(res => {
					console.log(res);
					hideSpinner("page");
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

export function getInviteLink(email) {
	return new Promise((resolve, reject) => {
		showSpinner("page");
		refreshCredentials().then(() => {
			const organizationId = state.get(["selectedOrg", "id"]);
			console.log({
				organizationId,
				email
			});
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgCreateInviteLink}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(
					{
						organizationId,
						email
					}
				)).then(res => {
					console.log(res);
					hideSpinner("page");
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

export function deleteInvite(id) {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showSpinner("page");
		refreshCredentials().then(() => {
			request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgInviteDelete(selectedOrg.id, id)}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.then(async res => {
					console.log(res);
					await getPendingInvites();
					hideSpinner("page");
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

function persistGroup(update = false) {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const payload = state.get(["groupManagement"]);
			console.log(payload);
			const _func = update ? request.put : request.post;
			_func(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.group(selectedOrg.id)}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(payload)).then(res => {

					console.log(res);
					hideMemberPanelSpinner();
					if (res.status !== 200) {
						reject(res.body);
					} else {
						resolve(res.body);
					}
				}).catch(err => {
					console.error(err);
					hideMemberPanelSpinner();
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideMemberPanelSpinner();
			reject(err);
		});
	});
}

export function deleteGroup(groupId) {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showMemberPanelSpinner();
		refreshCredentials().then(() => {
			const payload = state.get(["orgManagement"]);
			console.log(payload);
			request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.group(selectedOrg.id)}/${groupId}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send().then(res => {
					console.log(res);
					state.set(["selectedOrg", "_groups"], res.body);
					hideMemberPanelSpinner();
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideMemberPanelSpinner();
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideMemberPanelSpinner();
			reject(err);
		});
	});
}

export function captureGroupManagementProp(name, value) {
	state.set(["groupManagement", name], value);
}

export function getGroupManagementProp(name) {
	return state.get(["groupManagement", name]);
}

export function getGroupById(groupId) {
	let result = null;
	state.get(["selectedOrg", "_groups"]).forEach(group => {
		if (group.id === groupId) {
			result = group;
		}
	});
	return result;
}

export function getDefaultGroups() {
	const selectedOrg = getSelectedOrg();
	let defaultGroups = [];
	if (selectedOrg) {
		defaultGroups = selectedOrg.groups.map((group) => {
			return (
				{
					value: group.id,
					label: group.displayName
				}
			);
		});
	}
	return defaultGroups;
}

export function showCreateEditGroupModal(create = false) {
	if (create) {
		state.set(["groupManagement"], {});
		state.set(["appState", "groupManagement", "edit"], false);
	} else {
		state.set(["appState", "groupManagement", "edit"], true);
	}
	state.set(["appState", "groupManagement", "showCreateEditModal"], true);
}

export function hideCreateEditGroupModal() {
	state.set(["appState", "groupManagement", "showCreateEditModal"], false);
}

/** Scheduling */

export function captureScheduleManagementProp() { }
export function showCreateEditScheduleModal() { }
export function hideCreateEditScheduleModal() { }
export function createSchedule() { }
export function updateSchedule() { }
export function editSchedule() { }

/**
 * Preferences
 */

export function addUpdatePreference(prefs) {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showSpinner("page");
		refreshCredentials().then(() => {
			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgPreferences(selectedOrg.id)}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(prefs))
				.then(async res => {
					console.log(res);
					await getPreferences();
					hideSpinner("page");
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

export function deletePreference(name) {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showSpinner("page");
		refreshCredentials().then(() => {
			request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgPreference(selectedOrg.id, name)}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.then(async res => {
					console.log(res);
					await getPreferences();
					hideSpinner("page");
					resolve(res);
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

export function getPreferences() {
	return new Promise((resolve, reject) => {
		const selectedOrg = getSelectedOrg();
		showSpinner("page");
		refreshCredentials().then(() => {
			request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.orgPreferences(selectedOrg.id)}`)
				.set({ Authorization: userActions.getJwtToken(), "Content-Type": "application/json" })
				.then(async res => {
					console.log(res);

					hideSpinner("page");
					if (res.status !== 200 || !res.body.success) {
						reject(res.body);
					} else {
						state.set(["selectedOrg", "preferences"], res.body.preferences);
						state.set(["selectedOrg", "preferencesDefinition"], res.body.preferencesDefinition);
						resolve(res.body);
					}
				}).catch(err => {
					console.error(err);
					hideSpinner("page");
					reject(err);
				});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

export function indexPlaylists(content) {
	const newContent = JSON.parse(content);

	newContent.forEach((playlist, idx) => {
		playlist.objectID = playlist.guid;
		playlist.sequence = idx
	});

	refreshCredentials().then(({ cognitoUser }) => {
		const event = {
			orgId: getOrgId(),
			jsonPlaylists: JSON.stringify(newContent)
		};

		const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });
		const params = {
			FunctionName: "ambifi-assets-index-prod",
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: JSON.stringify(event),
		};

		lambda.invoke(params, (error, data) => {
			if (error) {
				alert(error);
			} else if (data.hasOwnProperty("FunctionError") && data.FunctionError === "Handled") {
				const objError = JSON.parse(data.Payload);
				alert(objError.errorMessage);
			} else {
				const results = JSON.parse(data.Payload);

				// Success...get preferences
				getPreferences();
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Update failed", err);
	});
}

export function registerOrgPreferences(prefs) {
	state.set(["orgPreferences"], prefs);
}

export function getOrgInfo() {
	return new Promise((resolve, reject) => {
		showSpinner("page");
		refreshCredentials().then(() => {
			fetchOrganizationsForUser().then(orgInfos => {
				if (orgInfos && _.isArray(orgInfos)) {
					hideSpinner("page");
					resolve(_.first(orgInfos));
				} else {
					hideSpinner("page");
					resolve(orgInfos);
				}
			}).catch(err => {
				console.error(err);
				hideSpinner("page");
				reject(err);
			});
		}).catch(err => {
			console.error(err);
			hideSpinner("page");
			reject(err);
		});
	});
}

const KNOWN_SUBDOMAINS = ["app", "dev-app"];

export function detectLoginUi() {
	const hostName = window.location.hostname;
	let lookupOrgDomain = null;
	if (hostName) {
		if (hostName === "localhost") {
			const params = state.get(["appState", "urlParams"]);
			if (params.hasOwnProperty("orgDomain") && _.isString(params.orgDomain) && _.trim(params.orgDomain) !== "") {
				// Determine if this
				lookupOrgDomain = params.org;
			}
		} else {
			let hostNameSubDomain = hostName.replace(".ambifi.com", "");
			if (hostNameSubDomain && hostNameSubDomain.trim() !== "") {
				// Not empty, check if unknown
				let isKnownSubdomain = false;
				const _hostNameSubDomain = _.trim(hostNameSubDomain);
				KNOWN_SUBDOMAINS.forEach(knownSubDomain => {
					if (knownSubDomain === _hostNameSubDomain) {
						isKnownSubdomain = true;
					}
				});

				if (!isKnownSubdomain) {
					lookupOrgDomain = _hostNameSubDomain;
				}
			}
		}
	}

	if (lookupOrgDomain) {
		// Need to look it up
	}
}
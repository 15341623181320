import Baobab from "baobab";

const tree = new Baobab(
	{
		appCapabilities: {
			views: {
				player: {
					procedure: {
						autoSelectOnScroll: false,
						focusInputOnSelect: true
					}
				}
			},
			showMode: true
		},
		appState: {
			alerts: [],
		},
		appPreferences: {
			checkbarLocation: "hidden",
			checkbarSize: 40,
			checkedColor: "green"
		},
		player: {
			navStack: [],
			showSpinner: false,
			showComments: false,
			showCommentsIdx: 0,
			readOnly: false,
			breadcrumb: {
				expandedView: null
			},
			fonts: {
				ambifiFontsCache: {
				}
			},
			scrollToSelectedItem: false,
			promptModalMoveForwardVisible: false,
			showNavigationView: false
		},
		// Data from the SCORM adapter
		sco: {},
		// Data from the activity
		// In future could ahve multiple activites to support our linking concept
		// Would store each of them in map or array and then load proper one
		// We should have our own packaging spec to pull off easily
		rootActivityId: "",
		activity: {},
		activities: [],
		user: {
			// Simulate user
			identityId: "test-identity-id"
		},
		associatedContentCard: {
			show: false
		},
		storeCloudHistory: true,
		focusedMode: true,
		moveForward: false,
		genres: [
			{
				value: "auto",
				name: "Automobile",
				icon: "car"
			},
			{
				value: "aviation",
				name: "Aviation",
				icon: "plane"
			},
			{
				value: "drone",
				name: "Drone",
				icon: "fighter-jet"
			},
			{
				value: "bike",
				name: "Bike",
				icon: "bicycle"
			},
			{
				value: "boat",
				name: "Boat",
				icon: "ship"
			},
			{
				value: "fun",
				name: "Fun",
				icon: "puzzle-piece"
			},
			{
				value: "food",
				name: "Food",
				icon: "utensil-spoon"
			},
			{
				value: "games",
				name: "Games",
				icon: "gamepad"
			},
			{
				value: "groceries",
				name: "Groceries",
				icon: "shopping-cart"
			},
			{
				value: "health",
				name: "Health",
				icon: "ambulance"
			},
			{
				value: "holidays",
				name: "Holidays",
				icon: "tree"
			},
			{
				value: "home",
				name: "Home",
				icon: "home"
			},
			{
				value: "kids",
				name: "Kids",
				icon: "smile"
			},
			{
				value: "legal",
				name: "Legal",
				icon: "gavel"
			},
			{
				value: "learning",
				name: "Learning",
				icon: "book"
			},
			{
				value: "maintenance",
				name: "Maintenance",
				icon: "wrench"
			},
			{
				value: "money",
				name: "Money",
				icon: "money-bill"
			},
			{
				value: "movies",
				name: "Movies",
				icon: "ticket"
			},
			{
				value: "music",
				name: "Music",
				icon: "music"
			},
			{
				value: "motorcycle",
				name: "Motorcycle",
				icon: "motorcycle"
			},
			{
				value: "people",
				name: "People",
				icon: "users"
			},
			{
				value: "pets",
				name: "Pets",
				icon: "paw"
			},
			{
				value: "photo",
				name: "Photography",
				icon: "camera-retro"
			},
			{
				value: "restaurants",
				name: "Restaurants",
				icon: "utensils"
			},
			{
				value: "school",
				name: "School",
				icon: "graduation-cap"
			},
			{
				value: "science",
				name: "Science",
				icon: "flask"
			},
			{
				value: "shopping",
				name: "Shopping",
				icon: "shopping-bag"
			},
			{
				value: "social",
				name: "Social",
				icon: "beer"
			},
			{
				value: "sports",
				name: "Sports",
				icon: "football-ball"
			},
			{
				value: "tv",
				name: "Television",
				icon: "tv"
			},
			{
				value: "travel",
				name: "Travel",
				icon: "hotel"
			},
			{
				value: "video",
				name: "Video",
				icon: "video"
			},
			{
				value: "work",
				name: "Work",
				icon: "briefcase"
			},
			{
				value: "misc",
				name: "Miscellaneous",
				icon: "question"
			}
		],
		loaded: false
	},
	{
		immutable: false
	}
);

export default tree;
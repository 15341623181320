import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import _ from "lodash";
import request from "superagent";
import { decode, encode } from "url-safe-base64";
import { v4 as uuidv4 } from 'uuid';
import Amplify, { Auth } from "aws-amplify";

import * as playerActions from "../actions/playerActions";
import { default as env, default as globals } from "../constants/env";
import PlayerPaths from "../constants/paths/playerPaths";
import browserHistory from "../history";
import * as awsS3 from "../persistence/s3";
import * as s3Actions from "../persistence/s3";
import { searchOrgActivities, initOrgIndex, initHistoryIndex } from "../search/algolia";
import state from "../state/state";
import { initializeSecurityContextV2, logout as handleLogout, refreshCredentials, startCredentialRefreshInterval } from "../utils/securityUtil";
import * as utils from "../utils/utils";
import { getQueryStringParam, setLocalStorage, uncompressResult } from "../utils/utils";
// import { isDev } from "../utils/utils";
import { listDriveOnEditActivity, navigate } from "./actions";
import { showError, showSuccess } from "./alertActions";
import { getAssignmentScheduleForCurrentUser, initializeScheduling } from "./assignmentActions";
// import { initializeContentManagementV2 } from "./contentManagementActions";
import { initializeDrive, initializeDriveMap } from "./drive";
import { hasMaxActivities, initializeInventoryV2 } from "./inventoryActions";
import { postMessage, sendAssignmentsMessage, sendHideModalEditorMessage, sendInventoryAndUserInfoMessage, sendRestoreSession } from "./mobileAppCommunicationActions";
import { initializeOrganizationsV2, /*getLastLoginOrg, TODO*/deleteLastLoginOrg, getPreferences, getOrgId } from "./orgsActions";
import {
	isOrganizationAdministrator,/*, isOrganizationUser*/
	isOrganizationUser
} from "./user";
import { initializeWorkflowParticipants, initializeWorkflows } from "./workflowActions";

import { getLastActivity, setLastActivity } from "../actions/user";
import { showYesNo, showConfirm } from "../actions/alertActions";
import * as editorActions from "./editorActions";
import { resetTree, saveUndoState, setSelectedFolderFromDriveRootPath } from "./actions";
import { transformToChecklistEntities } from "../utils/transformUtils.js";

// Stream Chat and Dolby initialization
import VoxeetSdk from "@voxeet/voxeet-web-sdk";  // eslint-disable-line
import { StreamChat } from 'stream-chat';  // eslint-disable-line 
import { getJwtTokenStream, /*getJwtTokenDolby, */initJwtTokenDolby } from "../utils/securityUtil";  // eslint-disable-line
import { updateUser } from "../utils/streamChatUtils";  // eslint-disable-line

import moment from "moment-timezone";
import { fetchDriveTree } from "../drive/driveTreeBuilder";
import { resetDrive, listDrive } from "../actions/drive";
import { uploadAiFileToS3 } from '../ai/aiAssistant.js';

const chatClient = StreamChat.getInstance("bymefp455ef2");
// chatClient.setBaseURL('https://chat.stream-io-api.com'); // eslint-disable-line

AWS.config.update({ region: "us-east-1" });

const poolData = {
	UserPoolId: globals.cognito.userPoolId, // Your user pool id here
	ClientId: globals.cognito.clientId, // Your client id here
};

export function signUp(user) {
	state.set("user", user);

	registerUser(_.toLower(user.username), user.password, _.toLower(user.email), user.name);
}

export function setUsername(tree, username) {
	tree.set(["user", "username"], _.toLower(username));
}

export function verifyUser(username, code) {
	confirmRegisteredUser(username, code);
}

export function resendVerifyCode(username) {
	setUsername(state, username);
	resendConfirmation(username);
}

export function forgotPasswordForUser(username) {
	forgotPassword(username);
}

export function changeForgottenPasswordForUser(username, code, newPassword) {
	changeForgottenPassword(username, code, newPassword);
}

export function login(tree) {
	const username = tree.get(["user", "username"]);
	const password = tree.get(["user", "password"]);
	try {
		clearUser();
	} catch (e) {
		console.warn("Failed to clear previous credentials", e);
	}
	if (window.location.href.indexOf("/auth") !== -1) {
		authenticateUser(tree, username, password);
	} else {
		loginUser(tree, username, password);
	}
}

export function upload() {
	//uploadToS3();
}
export function uploadFile(file) {
	s3Actions.uploadFileToS3("", "", file.name);
}

export function uploadAiFile(bucket, keyPrefix, file) {
	uploadAiFileToS3(bucket, keyPrefix, file);
}

export function uploadFiles(files) {
	if (hasMaxActivities()) {
		return;
	}

	s3Actions.uploadFilesToS3(globals.s3.contentBucket, files);
}


export async function uploadFilesFromContentReview(files, srcFormat) {
	await s3Actions.uploadFilesFromContentReviewToS3(globals.s3.contentBucket, files, srcFormat);
}

export async function uploadFilesFromOrgImport(files, method) {
	await s3Actions.uploadFilesFromOrgImportToS3(globals.s3.contentBucket, files, method);
}

export function uploadMediaFile(file, fileName, type) {
	return s3Actions.uploadMediaFileToS3(globals.s3.converterBucket, file, fileName, type);
}

export function logout() {
	logoutUser(state.get(["user", "username"]));
}

function startsOrEndsWithWhitespace(str) {
	if (str === "") {
		return false;
	} else {
		return /^\s|\s$/.test(str);
	}
}



function registerUser(username, password, email, name, nickname = "", website = "", profile = "", picture = "") {
	if (startsOrEndsWithWhitespace(username)) {
		alert("There is a leading or trailing space on the Username. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(password)) {
		alert("There is a leading or trailing space on the Password. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(email)) {
		alert("There is a leading or trailing space on the Email. Please correct this.");
		return;
	}
	if (startsOrEndsWithWhitespace(name)) {
		alert("There is a leading or trailing space on the Name. Please correct this.");
		return;
	}

	let userPool = new CognitoUserPool(poolData);

	let attributeList = [];

	let dataEmail = {
		Name: "email",
		Value: email
	};

	let dataName = {
		Name: "name",
		Value: name
	};

	let attributeEmail = new CognitoUserAttribute(dataEmail);
	let attributeName = new CognitoUserAttribute(dataName);

	attributeList.push(attributeEmail);
	attributeList.push(attributeName);

	let dataNickname = {
		Name: "nickname",
		Value: nickname
	};

	let attributeNickname = new CognitoUserAttribute(dataNickname);
	attributeList.push(attributeNickname);

	let dataWebsite = {
		Name: "website",
		Value: website
	};

	let attributeWebsite = new CognitoUserAttribute(dataWebsite);
	attributeList.push(attributeWebsite);

	let dataProfile = {
		Name: "profile",
		Value: profile
	};

	let attributeProfile = new CognitoUserAttribute(dataProfile);
	attributeList.push(attributeProfile);

	let dataPicture = {
		Name: "picture",
		Value: picture
	};

	let attributePicture = new CognitoUserAttribute(dataPicture);
	attributeList.push(attributePicture);

	userPool.signUp(username, password, attributeList, null, function (err, result) {
		if (err) {
			if (err.code === "InvalidPasswordException" || (err.code === "InvalidParameterException" && err.message.toLowerCase().includes("password"))) {
				alert("Make sure your password has a minimum of 8 characters, and at least one uppecase character, one lowercase character and one numeral.");
			} else {
				alert(err.message);
			}
			return;
		}
		let cognitoUser = result.user;
		console.log("user name is " + cognitoUser.getUsername());

		browserHistory.push(globals.routes.verify);
	});
}

function resendConfirmation(username) {

	let userPool = new CognitoUserPool(poolData);
	let userData = {
		Username: _.toLower(username),
		Pool: userPool
	};

	let cognitoUser = new CognitoUser(userData);

	cognitoUser.resendConfirmationCode(function (err) {
		if (err) {
			showError("Resend confirmation", `There was a problem resending the confirmation code: ${err.message}`);
			return;
		}

		browserHistory.push(globals.routes.verify);
	});
}

function confirmRegisteredUser(username, code) {

	let userPool = new CognitoUserPool(poolData);
	let userData = {
		Username: _.toLower(username),
		Pool: userPool
	};

	let cognitoUser = new CognitoUser(userData);
	cognitoUser.confirmRegistration(code, true, function (err, result) {
		if (err) {
			showError("Account confirmation", `There was a problem confirming your account: ${err.message}`);
			return;
		}

		console.log("call result: " + result);

		browserHistory.push(globals.routes.login);
	});
}

function forgotPassword(username) {

	let userPool = new CognitoUserPool(poolData);
	let userData = {
		Username: _.toLower(username),
		Pool: userPool
	};

	let cognitoUser = new CognitoUser(userData);
	cognitoUser.forgotPassword({
		onSuccess: function (result) {
			console.log("call result: " + result);
		},
		onFailure: function (err) {
			showError("Password reset", `There was a problem requesting the password reset: ${err.message}`);
		},
		//Optional automatic callback
		inputVerificationCode: function (data) {
			console.log("Code sent to: " + data);
			browserHistory.push(globals.routes.forgotPassword);
		}
	});
}

function changeForgottenPassword(username, code, newPassword) {

	let userPool = new CognitoUserPool(poolData);
	let userData = {
		Username: _.toLower(username),
		Pool: userPool
	};

	let cognitoUser = new CognitoUser(userData);
	cognitoUser.confirmPassword(code, newPassword, {
		onSuccess: function onSuccess() {
			showSuccess("Password Reset", "Your password was successfully reset!");
			browserHistory.push(globals.routes.login);
		},
		onFailure: function onFailure(err) {
			showError("Password reset", `There was a problem resetting your password. ${err.message}`);
		}
	});
}

async function updateAttribute(cognitoUser, name, value) {
	try {
		if (cognitoUser) {
			const params = {
				[name]: _.isString(value) ? value : JSON.stringify(value)
			};
			await Auth.updateUserAttributes(cognitoUser, params);
		}
	} catch (e) {
		console.error("Failed to update user attribute...", name, value, e);
		// alert(e);
	}
}

function deviceExists(device, devices) {
	for (let i = 0; i < devices.length; i++) {
		if (device.deviceId === devices[i].deviceId) {
			return true;
		}
	}

	return false;
}

export function updateItunesSubscription(itunesSubscription) {
	refreshCredentials().then(({ cognitoUser }) => {
		updateAttribute(cognitoUser, "custom:itunesSubscription", itunesSubscription);
	}).catch((err) => {
		console.error(err);
		alert(err.message);
	});
}

async function initializeUserAttributes(cognitoUser) {
	return new Promise(async (resolve) => {
		const userAttributes = _.get(cognitoUser, "idToken.payload");
		console.log("Initialize user attributes", userAttributes);

		// Set initially all empty
		state.set(["user", "email"], "");
		state.set(["user", "emailChatNotify"], true);
		state.set(["user", "phone_number"], "");
		state.set(["user", "phoneNumChatNotify"], false);
		state.set(["user", "profilePicture"], "");

		var params = {
			AccessToken: cognitoUser.accessToken.jwtToken
		};
		var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

		const user = await cognitoidentityserviceprovider.getUser(params).promise();

		for (let i = 0; i < user.UserAttributes.length; i++) {
			const userAttrib = user.UserAttributes[i];
			if (userAttrib.Name === "email") {
				userAttributes.email = userAttrib.Value;
			} else if (userAttrib.Name === "custom:emailChatNotify") {
				userAttributes["custom:emailChatNotify"] = userAttrib.Value;
			} else if (userAttrib.Name === "phone_number") {
				userAttributes["phone_number"] = userAttrib.Value;
			} else if (userAttrib.Name === "custom:phoneNumChatNotify") {
				userAttributes["custom:phoneNumChatNotify"] = userAttrib.Value;
			} else if (userAttrib.Name === "custom:profilePicture") {
				userAttributes["custom:profilePicture"] = userAttrib.Value;
			}
		}

		state.set(["user", "nickname"], "");
		state.set(["user", "website"], "");
		state.set(["user", "profile"], "");
		state.set(["user", "picture"], "");
		if (!userAttributes) {
			resolve();
			return;
		}
		_.keys(userAttributes).forEach(key => {
			const userAttrValue = userAttributes[key];
			if (key === "email_verified") {
				state.set(["user", "emailVerified"], userAttrValue);
			} else if (key === "custom:emailChatNotify") {
				state.set(["user", "emailChatNotify"], (userAttrValue && userAttrValue !== "") ? userAttrValue.toLowerCase() === "true" : false);
			} else if (key === "custom:phoneNumChatNotify") {
				state.set(["user", "phoneNumChatNotify"], (userAttrValue && userAttrValue !== "") ? userAttrValue.toLowerCase() === "true" : false);
			} else if (key === "custom:subscriptionId") {
				state.set(["user", "subscriptionId"], userAttrValue);
			} else if (key === "custom:profilePicture") {
				state.set(["user", "profilePicture"], userAttrValue);
			} else if (key === "custom:subscriptionPlan") {
				const plan = state.get(["user", "subscriptionPlan"]);

				if (plan === null) {
					state.set(["user", "subscriptionPlan"], userAttrValue);
				} else {
					if (plan !== "pro-plan-monthly-itunes" && plan !== "pro-plan-yearly-itunes") {
						state.set(["user", "subscriptionPlan"], userAttrValue);
					}
				}
			} else if (key === "custom:deviceLimit") {
				state.set(["user", "deviceLimit"], Number(userAttrValue));
			} else if (key === "custom:stripeCustomerId") {
				state.set(["user", "stripeCustomerId"], userAttrValue);
			} else if (key === "custom:devices") {
				if (userAttrValue.length > 0) {

					state.set(["devices"], JSON.parse(userAttrValue));
					console.log(JSON.stringify(state.get(["devices"])));
				}
			} else if (key === "custom:itunesSubscription") {
				if (userAttrValue.length > 0) {
					// Only if not already a pro plan
					//if (!state.get(["subscription"]).startsWith("pro")) {
					let sub = JSON.parse(userAttrValue);
					state.set(["user", "subscriptionPlan"], sub.plan);
					state.set(["user", "subscriptionExpiration"], sub.expiration);
					//}
				}
			} else if (key === "custom:devicesBlacklist") {
				if (userAttrValue.length > 0) {
					postMessage(JSON.parse(userAttrValue));
				}
			} else if (key === "custom:historyUpdated") {
				state.set(["user", "historyUpdated"], userAttrValue === "true");
			} else if (key === "sub") {
				// THIS IS NEW - use "sub" as the identity id
				// state.set(["user", "identityId"], userAttrValue); 

				// HSC (08/21/2020) The above line was commented out because we used to use the Cognito users "sub" as the
				// main identifier in AmbiFi - the problem used to be the lack of flexibility. In that a Cognito user had to exist
				// in order to add a user to a group or assign to a user. PLUS a huge issue is user transition - if a user moves from
				// one identity provider to another, for example we transition a user group to their own Active Directory!

				state.set(["user", "_injected_security_credentials", "iid"], userAttrValue);
				state.set(["user", "sub"], userAttrValue);
			} else {
				state.set(["user", key], userAttrValue);
			}
		});

		console.log("$#$#$# STATE", state);

		resolve();
	});
}

function finishUserinitialization(cognitoUser) {
	const user = state.get(["user"]);

	if (!user.hasOwnProperty("historyUpdated") || !user.historyUpdated) {
		awsS3.touchHistoryFiles(user.identityId);
	}

	if (!user.hasOwnProperty("subscriptionPlan")) {
		const subscriptionPlan = env.config.defaultPlan;
		state.set(["user", "subscriptionPlan"], subscriptionPlan);
		updateAttribute(cognitoUser, "custom:subscriptionPlan", subscriptionPlan);
	} else if (isOrganizationAdministrator()) {
		let newHistoryUsers = [];

		let member = state.get(["selectedOrg", "member"]);
		newHistoryUsers.push({ identityId: member.userSubId, name: member.displayName });

		let members = state.get(["selectedOrg", "members"]);
		for (let i = 0; i < members.length; i++) {
			if (member.userSubId !== members[i].userSubId) {
				newHistoryUsers.push({ identityId: members[i].userSubId, name: members[i].displayName });

			}
		}

		state.set(["user", "historyUsers"], newHistoryUsers);
	}

	console.log("$#$#$# STATE", state);
}

function initializeDevices(cognitoUser, username) {
	return new Promise((resolve, reject) => {
		try {
			let redirectRemoveDevices = false;

			// if sso login we should call endpoint, otherwise do everything below
			state.set(["user", "username"], username);

			let user = state.get(["user"]);

			const maxDevices = user.hasOwnProperty("deviceLimit") ? user.deviceLimit : 5;

			if (!user.hasOwnProperty("historyUpdated") || !user.historyUpdated) {
				awsS3.touchHistoryFiles(user.identityId);
			}

			let currentDevice = state.get(["device"]);
			let devices = state.get(["devices"]);
			let deviceFound = false;
			// If device array is empty then add and good to go
			// Check if the current device is in the devices array
			// If it is we are good to go
			// If it is not then redirect to remove a device
			if (currentDevice !== null) {
				if (!user.hasOwnProperty("subscriptionPlan")) {
					if (!deviceExists(currentDevice, devices)) {
						devices.push(currentDevice);
						updateAttribute(cognitoUser, "custom:devices", devices);
					}
				} else if (user.subscriptionPlan === "basic-plan" && devices.length < 1) {
					if (!deviceExists(currentDevice, devices)) {
						devices.push(currentDevice);
						updateAttribute(cognitoUser, "custom:devices", devices);
					}
				} else if (user.subscriptionPlan === "basic-plan-checkmate" && devices.length < 3) {
					if (!deviceExists(currentDevice, devices)) {
						devices.push(currentDevice);
						updateAttribute(cognitoUser, "custom:devices", devices);
					}
					//} else if (user.subscriptionPlan.substring( 0, "standard-plan".length ) === "standard-plan" && devices.length < 3) {
				} else if (user.subscriptionPlan.startsWith("standard-plan") && devices.length < 3) {
					if (!deviceExists(currentDevice, devices)) {
						devices.push(currentDevice);
						updateAttribute(cognitoUser, "custom:devices", devices);
					}
				} else if (user.subscriptionPlan.startsWith("pro-plan") && (devices.length < maxDevices || maxDevices === 0)) {
					//} else if (user.subscriptionPlan.substring( 0, "pro-plan".length ) === "pro-plan" && devices.length < 5) {
					if (!deviceExists(currentDevice, devices) && maxDevices !== 0) {
						devices.push(currentDevice);
						updateAttribute(cognitoUser, "custom:devices", devices);
					}
				} else {
					for (let i = 0; i < devices.length; i++) {
						let device = devices[i];
						if (currentDevice.deviceId === device.deviceId) {
							deviceFound = true;
						}
					}

					redirectRemoveDevices = !deviceFound;
				}
			}

			resolve(redirectRemoveDevices);
		} catch (e) {
			console.error("Failed to initialize devices for user", username, e);
			reject(e);
		}

	});
}

let callAlertTimeout;

export async function handlePostAuthenticateV2(loginResult, cognitoUser, tree, username) {
	if (utils.isMobile()) {
		postMessage({
			method: "showSpinnerForLogin"
		});
	}

	utils.showLoader(tree);

	console.log("handlePostAuthenticateV2", loginResult);
	setLocalStorage("loginDetected", true);

	await initializeUserAttributes(loginResult);
	const redirectRemoveDevices = await initializeDevices(cognitoUser, username);

	if (redirectRemoveDevices) {
		utils.hideLoader(tree);
		alert("You have too many devices registered.", () => {
			browserHistory.push(globals.routes.devices);
		});
	} else {
		finishUserinitialization(cognitoUser);
		try {
			const res = await request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.userInitAuth}`)
				.set({ Authorization: loginResult.idToken.jwtToken, "Content-Type": "application/json" })
				.send(JSON.stringify({}));

			const userData = res.body;
			console.log("Got:", userData);

			state.set(["appState", "loadingMessage"], "Loading organization...");
			if (utils.isMobile()) {
				postMessage({
					method: "loginStatus",
					label: "Loading organization..."
				});
			}

			let jwt = loginResult.idToken.jwtToken
			let orgs = await fetchOrgInfo(jwt);

			// Get display name
			const displayName = _.get(orgs, "0.member.displayName", null);
			if (!displayName) {
				throw new Error({
					message: "Unable to obtain displayName from logged in user."
				});
			}
			state.set(["user", "displayName"], displayName);
			// state.set(["user", "name"], displayName);

			const chatEnabled = _.get(orgs, "0.member.chatEnabled", false);
			state.set(["user", "chatEnabled"], chatEnabled === 1 ? true : false);

			// Get timezone
			let timeZone = _.get(orgs, "0.member.timeZone", null);

			if (!timeZone || timeZone === "") {
				timeZone = moment.tz.guess()
			}

			if (!timeZone) {
				throw new Error({
					message: "Unable to obtain timezone from logged in user."
				});
			}
			state.set(["user", "timeZone"], timeZone);

			// Now to fetch the member info and finish the logged in user state
			const userSubId = _.get(orgs, "0.member.userSubId", null);
			if (!userSubId) {
				throw new Error({
					message: "Unable to obtain userSubId from logged in user."
				});
			}

			// alert(userSubId);
			state.set(["user", "identityId"], userSubId);

			console.log("Setting remember me", tree.get(["user", "username"]), tree.get(["user", "rememberMe"]));
			utils.setLs(tree.get(["user", "username"]) + ";rememberMe", tree.get(["user", "rememberMe"]));

			const { /*contentModels,*/
				//drive,
				federationTokenBuilderResult,
				//orgs,
				schedules,
				timerTemplates,
				workflows,
				workflowParticipants,
				//inventory 
			} = userData;

			await initializeSecurityContextV2(cognitoUser, federationTokenBuilderResult, loginResult);
			console.log("Initialized Security Context");

			await initializeOrganizationsV2(orgs);
			console.log("Initialized Org Info");

			const user = tree.get(["user"]);
			// Let's initialize stream chat and dolby here
			// This is earliest we know user is all logged in
			// const userTokenDolby = await getJwtTokenDolby(jwt);
			// state.set(["appState", "videoCallOauthToken"], userTokenDolby);
			// TODO

			// Make sure org and history index is initialized for Algolia
			await initOrgIndex();
			await initHistoryIndex();

			if (state.get(["user", "chatEnabled"])) {

				try {
					await initJwtTokenDolby(jwt);
					try {
						await VoxeetSdk.session.open({ externalId: user.username, name: user.name })
					} catch (err) {
						console.log("!@!@!@", err.message, err);
					}
					VoxeetSdk.notification.on("invitation", (invitation) => {
						// console.log("!@!@!@ INVITE", invitation);

						callAlertTimeout = setTimeout(() => {
							state.set(["appState", "alerts"], []);
						}, 20000);

						// You have a call coming in, do you want to answer it?
						showConfirm("You have an incoming call. Answer?", "Incoming call from " + invitation.participant.info.name, () => {
							VoxeetSdk.conference.fetch(invitation.conferenceId).then((conference) => {
								// console.log("!@!@!@ CONFERENCE", invitation.conferenceId, conference);
								clearTimeout(callAlertTimeout);

								state.set(["appState", "videoCall"], {
									visible: true,
									autoJoin: true,
									conferenceAlias: invitation.conferenceAlias
								});
							});
						});
					});

					state.set(["appState", "videoSdkInitialized"], true);
				} catch (err) {
					state.set(["appState", "videoSdkInitialized"], false);
					showError("Video Calling", "Error connecting to video calling.\n\n" + err.message);
				}

				// state.set(["appState", "videoCallConnected"], true);
				// const newUsername = user.username.split("@")[0].replaceAll(".", "_").replaceAll("#", "_");

				try {
					console.log("$#$#$# USERNAME", user.username, user.username.split("@"), user.username.split("@")[0]);
					const newUsername = user.username.split("@")[0].replace(/\./g, "_").replace(/#/gi, "_").toLowerCase();
					const userToken = await getJwtTokenStream(jwt, newUsername);

					const isAdmin = isOrganizationAdministrator();

					await updateUser(jwt, newUsername, user.name, user.username.toLowerCase(), user.profilePicture, isAdmin ? "admin" : "user", user.identityId);
					let chatUser;
					try {
						chatUser = await chatClient.connectUser({ id: newUsername, team: state.get(["selectedOrg", "id"]), image: user.profilePicture && user.profilePicture !== "" ? user.profilePicture : null, identityId: user.identityId }, userToken);
					} catch (err) {
						console.log("!@!@!@", err.message, err);
					}
					if (chatUser) {
						console.log(`!@!@!@ you have ${chatUser.me.total_unread_count} unread messages on ${chatUser.me.unread_channels} channels.`);
						state.set(["appState", "streamChat", "unreadMessagesCount"], chatUser.me.total_unread_count);
						state.set(["appState", "streamChat", "unreadChannelsCount"], chatUser.me.unread_channels);
						// subscribe to all client events and log the unread_count field
						chatClient.on(event => {
							if (event.total_unread_count !== undefined) {
								console.log(`!@!@!@ unread messages count is now: ${event.total_unread_count}`);
								state.set(["appState", "streamChat", "unreadMessagesCount"], event.total_unread_count);
							}

							if (event.unread_channels !== undefined) {
								console.log(`!@!@!@ unread channels count is now: ${event.unread_channels}`);
								state.set(["appState", "streamChat", "unreadChannelsCount"], event.unread_channels);
							}
						});

						state.set(["appState", "chatConnected"], true);
					}
				} catch (err) {
					state.set(["appState", "chatConnected"], false);

					showError("Chat", "Error connectiong to chat.\n\n" + err.message);
				}

			}

			const apiVersion = getQueryStringParam("app-api-version");

			let inventory = [];

			if (utils.isMobile() && isOrganizationUser() && apiVersion >= 8) {
				// No Op
			} else {
				await initializeWorkflows(workflows); // Before inventory
				console.log("Initialized Workflows");
				await initializeWorkflowParticipants(workflowParticipants); // Before inventory
				console.log("Initialized Workflow Participants");

				state.set(["appState", "loadingMessage"], "Loading inventory...");
				if (utils.isMobile()) {
					postMessage({
						method: "loginStatus",
						label: "Loading inventory..."
					});
				}

				let fullInventory = await fetchInventory(jwt);
				inventory = fullInventory.inventory;

				await initializeInventoryV2(inventory);
				console.log("Initialized Inventory");
			}

			// state.set(["appState", "loadingMessage"], "");

			const prefs = await getPreferences();

			if (prefs.preferences && prefs.preferences.playlists) {
				state.set(["playlists"], prefs.preferences.playlists);
			}

			// No longer loading entire drive here on login, instead the drive
			// will be loaded when viewing the drive page, authoring page or 
			// launching activities through the web player

			if (utils.isMobile()) {
				postMessage({
					method: "loginStatus",
					label: "Loading scheduling..."
				});
			}

			await initializeScheduling(schedules);
			console.log("Initialized Scheduling");
			// await initializeContentManagementV2(contentModels);
			// console.log("Initialized Content Management");

			const templates = await s3Actions.getPrintTemplates();

			let filteredTemplates = [];
			for (let i = 0; i < templates.length; i++) {
				const template = templates[i];
				if (template.name.startsWith("ambifi-")) {
					filteredTemplates.push(template);
				}
			}

			state.set(["printTemplates"], filteredTemplates);

			console.log("Initialized Print Templates");

			state.set(["timerTemplates"], timerTemplates);
			state.set(["user", "loggedIn"], true);

			if (utils.isMobile()) {
				console.log("Mobile");
				// const apiVersion = getQueryStringParam("app-api-version");

				console.log("!#!#!# INVENTORY", inventory);

				if (utils.isMobile()) {
					postMessage({
						method: "loginStatus",
						label: "Finalizing..."
					});
				}
				sendInventoryAndUserInfoMessage(inventory).then(() => {
					if (utils.isMobile()) {
						postMessage({
							method: "loginStatus",
							label: ""
						});
					}

					sendHideModalEditorMessage();
					// if (apiVersion > 3) {

					getAssignmentScheduleForCurrentUser().then(assignments => {

						sendAssignmentsMessage(assignments);


					});
					// }
					// if (apiVersion > 4) {
					// 	sendOrganizationInfoMessage(orgs);
					// }
					setTimeout(function () {
						startCredentialRefreshInterval();
						navigate(true);
					}, 1000);
				}).catch(err => {
					console.error(err);
					showError("Mobile Inventory", "Failed to build mobile inventory of activities, this might be due to network connectivity issues.");
					utils.hideLoader(tree);
				});
			} else {
				console.log("Not mobile");
				if (state.get(["appCapabilities", "views", "nav", "homeVisible"])) {
					console.log("Home visible / Initialize player");
					state.set(PlayerPaths.SELECTED_HOME_TAB, "myActivities");
					playerActions.initializePlayer(inventory).then(async () => {
						// This can be set as an alternative target to MyChecklists
						// When a user without anything in their inventory logs in they should see
						// either the org search index (to add to their inventory), OR the public
						// search index ... much like YouTube
						console.log("here1");
						let altMyChecklistsTarget = null;

						// let hasNonLearningActivities = false;
						// if (inventory && _.isArray(inventory)) {
						// 	inventory.forEach(act => {
						// 		if (!act.category || act.category !== "learning") {
						// 			hasNonLearningActivities = true;
						// 		}
						// 	});
						// }

						console.log("here2");
						// if (!hasNonLearningActivities) {
						// 	console.log("Empty inventory ... ", inventory);
						// 	let numActivities = [];
						// 	try {
						// 		numActivities = await searchOrgActivities();
						// 	} catch (err) {
						// 		// This will happen for an empty org index, if it has never been used
						// 		console.warn("Err testing org index", err);
						// 	}
						// 	// const orgActivities = await searchOrgActivities();
						// 	altMyChecklistsTarget = "/search";
						// 	if (numActivities > 0) {
						// 		// Check to see if public activities
						// 		state.set(["appState", "searchInitialTab"], "org");
						// 	}
						// }

						console.log("here3");
						let lastActivity = getLastActivity();
						if (lastActivity && _.isObject(lastActivity) && lastActivity.hasOwnProperty("type")) {
							// navigate(true, altMyChecklistsTarget);
							console.log("here4a");
							showYesNo("It looks like you may not have properly exited the editor. You have an activity named <b>" + lastActivity.name + "</b> that can be restored.<br/<br/><br/>If you decide to restore you will get a chance to review the activity before deciding if you want to save it. If you know that you properly saved and got confirmation that the save was successful then you can safely answer No.", "Do you want to restore it?", async (yes) => {
								console.log("here4b");
								if (yes) {
									window.restoredFromLs = true;

									// Load the drive if not yet initialized
									await listDriveOnEditActivity();

									const srcTree = transformToChecklistEntities(lastActivity);

									// Let's have this act on dstTree, not srcTree
									srcTree.root.selected = true;
									srcTree.root.expanded = true;
									resetTree(srcTree.root.children);
									editorActions.resetActivityEditMetaData();
									editorActions.setActivityMetaData("revision", srcTree.root.revision);
									state.set("selectedChecklistId", srcTree.root.id);
									state.set("selectedNodePath", ["tree", "root"]);
									state.set("history", []);
									state.set("historyIndex", -1);
									//state.set("clipboard",{});
									//state.set("clipboardMode","");
									state.set("tree", srcTree);

									saveUndoState();

									setLastActivity({}, true);

									setSelectedFolderFromDriveRootPath(srcTree.root?.entity?.driveRootPath);

									navigate(true, "/editor/" + lastActivity.id);

									setTimeout(() => {
										if (window.restoredFromLs) {
											alert("Your activity has been restored.<br/><br/>Important Note: It is not saved yet. You can first review it to make sure it is satisfactory. Auto-save happens at a one-minute interval, so there may be a little data loss. If you don't save, it will revert to the activity prior to this restored version.");
											delete window.restoredFromLs;
											return;
										}
									}, 3000);

									// Open editor

									// const path = "/editor/" + lastActivity.id;
									// browserHistory.push(path);
								} else {

									console.log("here4");
									setLastActivity({}, true);
									navigate(true, altMyChecklistsTarget);
								}
							});
						} else {

							console.log("here5");
							navigate(true, altMyChecklistsTarget);
						}

					}).catch((err) => {
						console.error("Load Activities", err, err.stack);
						showError("Load Activities", "Unable to load activities, this might be due to network connectivity issues.");
						utils.hideLoader(tree);
					});
				} else {

					console.log("here6");
					console.log("Home not visible");
					navigate(true);
				}
			}
		} catch (err) {
			console.error("Failed to retrieve user info after login.", err);

			utils.hideLoader(tree);
			if (!err.handled) {
				showError("Error Retrieving User Info", "An error occurred while attempting to get user info such as inventory, drive content and similar information after a successful login, reason: " + err);
			}
		}

	}


}

// function createUserDoc(tree, username, bucket, keyPrefix) {
// 	// Shouldn't need to add to S3 now 
// 	const _username = _.toLower(username);
// 	const _bucket = new AWS.S3({ useDualStack: true, params: { Bucket: globals.s3.contentBucket, ResponseContentType: "application/json" , ResponseCacheControl: "no-cache" } });

// 	// First check if bucket exists for user...if not create
// 	_bucket.getObject({ Key: keyPrefix + "/user.json" }, function (err) {
// 		if (err) {
// 			let putBatch = function putBatch(files) {
// 				return Promise.all(files.map(function (file) {
// 					let body;
// 					if (file === "user.json") {
// 						let user = _.cloneDeep(state.get(["user"]));
// 						delete user.loggedIn;
// 						delete user.password;
// 						delete user.code;
// 						delete user.email_verified;
// 						body = JSON.stringify(user);
// 					}

// 					return _bucket.putObject({ Key: keyPrefix + "/" + file, ContentType: "application/json", Body: body }).promise();
// 				}));
// 			};

// 			let files = ["user.json"];

// 			putBatch(files)
// 				.then(function () {
// 					s3Actions.getChecklistsCollection(tree, _username, _bucket, keyPrefix, []);
// 					state.set(["user", "loggedIn"], true);
// 				})
// 				.catch((e) => {
// 					console.error("Error putiing user!", e);
// 				});

// 		} else {
// 			// TODO: Could compare user attributes and update if necessary
// 			s3Actions.getChecklistsCollection(tree, _username, _bucket, keyPrefix, []);
// 			state.set(["user", "loggedIn"], true);
// 		}
// 	});
// }

export function handleChangePassword(tree, password) {
	tree.set(["user", "password"], password);

	if (password !== "") {
		let sharedDevice = false;
		if (this && this.props && this.props.user.hasOwnProperty("sharedDevice")) {
			sharedDevice = this.props.user.sharedDevice;
		}

		tree.set(["user", "rememberMe"], !sharedDevice);
		utils.setLs(tree.get(["user", "username"]) + ";rememberMe", !sharedDevice);
		tree.set(["user", "savePassword"], !sharedDevice);
		utils.setLs(tree.get(["user", "username"]) + ";savePassword", !sharedDevice);
	}

	tree.commit();
}

export function handleChangeUsername(tree, username) {
	tree.set(["user", "username"], username);
	tree.commit();
}

export function handleCheckSavePassword(tree, savePassword) {
	tree.set(["user", "savePassword"], savePassword);
	utils.setLs(tree.get(["user", "username"]) + ";savePassword", savePassword);
}

export function handleCheckRememberMe(tree, rememberMe) {
	tree.set(["user", "rememberMe"], rememberMe);
	utils.setLs(tree.get(["user", "username"]) + ";rememberMe", rememberMe);
}

function validateAndParseAuthParams(authParams) {
	if (_.isEmpty(authParams)) {
		throw new Error("auth parameters can not be empty");
	}
	let params = null;

	try {
		params = JSON.parse(atob(decode(authParams)));
	} catch (e) {
		throw new Error("Expecting auth parameters to be a valid Object");
	}

	let validationErrors = [];

	if (!params.hasOwnProperty("redirectUri") || _.isEmpty(params.redirectUri) || !_.isString(params.redirectUri)) {
		validationErrors.push(new Error("Expecting a redirect URI parameter that communicates where to redirect to after successful auth."));
	}

	if (!_.isEmpty(validationErrors)) {
		throw validationErrors;
	}

	return params;
}

/**
 * This function will be used for links that require auth but this wo't go through the whole login process.
 * @param {*} tree 
 * @param {*} username 
 * @param {*} password 
 */
function authenticateUser(tree = state, username, password) {
	return new Promise(async (resolve, reject) => {
		console.log("About to authenticte user");
		const authParams = getQueryStringParam("auth");
		let _authParams = null;
		try {
			_authParams = validateAndParseAuthParams(authParams);
		} catch (e) {
			console.error(e);
			reject(e);
		}

		if (_authParams) {
			try {
				const _username = _.toLower(username);

				let authenticationData = {
					Username: _username,
					Password: password,
				};
				let authenticationDetails = new AuthenticationDetails(authenticationData);
				console.log("About to login");
				const loginResult = await authenticateUserWithBackoff(authenticationDetails);
				console.log("Successfully logged in");

				const tmpCredentialsFilename = `${uuidv4()}.json`;
				const fedTokenEndpoint = `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.auth}`;
				const _fedTokenResponse = await request.post(fedTokenEndpoint)
					.set({ Authorization: loginResult.signInUserSession.idToken.jwtToken, "Content-Type": "application/json" })
					.send(JSON.stringify({ tmpCredentialsFilename }));
				const fedTokenResponse = _fedTokenResponse.body;


				const { redirectUri } = _authParams;
				let _redirectUri = redirectUri;

				const identityId = _.get(loginResult, "idToken.payload.sub", null);


				const authCallback = {
					success: true,
					tmpCredentials: fedTokenResponse.body,
					tmpCredentialsFilename,
					tmpCredentialsBucket: env.s3.tmpCredentialsBucket
				};

				const authPayload = {
					success: true,
					loginResult,
					tokens: {
						id: loginResult.signInUserSession.idToken.jwtToken,
						refresh: loginResult.signInUserSession.refreshToken.token
					},
					fedTokenResponse,
					fedTokenEndpoint,
					cognito: {
						ClientId: globals.cognito.clientId
					},
					identityId
				};

				AWS.config.credentials = new AWS.Credentials(fedTokenResponse.body.accessKeyId, fedTokenResponse.body.secretAccessKey, fedTokenResponse.body.sessionToken);
				await awsS3.uploadObject(authPayload, env.s3.tmpCredentialsBucket, tmpCredentialsFilename, true);

				if (redirectUri.indexOf("?") !== -1) {
					_redirectUri = `${redirectUri}&authCallbackV2=${encode(btoa(JSON.stringify(authCallback)))}`;
				} else {
					_redirectUri = `${redirectUri}?authCallbackV2=${encode(btoa(JSON.stringify(authCallback)))}`;
				}
				utils.hideLoader(tree);
				window.location.href = `${_redirectUri}`;
				resolve(loginResult);

			} catch (err) {
				console.log("Login Failure", err);
				utils.hideLoader(tree);
				let message = "Please verify your credentials and try again, you can also use the forgot password link to reset your password.";
				if (err.code && err.code === "NetworkError") {
					message = "Network connection failed, please check your internet connection and try again.";
				}
				showError("Login Unsuccessful", message, () => {
					reject(err);
				});
			}
		} else {
			let message = "Unable to perform operation, missing required parameters for authentication process.";
			showError("Login Unsuccessful", message, () => {
				reject(message);
			});
		}

	});

}

function doAuthenticateUserPromisified(authenticationDetails) {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await Auth.signIn(authenticationDetails);
			resolve(result);
		} catch (err) {
			reject(err);
		}
	});
}

/**
 * Special function with backoff retry
 */
function authenticateUserWithBackoff(authenticationDetails) {
	return new Promise((resolve, reject) => {
		let loginSuccess = false;
		let lastException = null;
		let timeoutException = false;
		let result = null;
		try {
			result = doAuthenticateUserPromisified(authenticationDetails);
			loginSuccess = true;
		} catch (e) {
			timeoutException = e && e.code && e.code === "TooManyRequestsException";
			lastException = e;
			console.error(e);
		}

		if (loginSuccess) {
			resolve(result);
			return;
		} else if (!timeoutException) {
			reject(lastException);
			return;
		} else {
			//Backoff 1
			loginSuccess = false;
			lastException = null;
			timeoutException = false;
			result = null;
			setTimeout(() => {
				try {
					result = doAuthenticateUserPromisified(authenticationDetails);
					loginSuccess = true;
				} catch (e) {
					timeoutException = e && e.code && e.code === "TooManyRequestsException";
					lastException = e;
					console.error(e);
				}

				if (loginSuccess) {
					resolve(result);
					return;
				} else if (!timeoutException) {
					reject(lastException);
					return;
				} else {
					//Backoff 2
					loginSuccess = false;
					lastException = null;
					timeoutException = false;
					result = null;
					setTimeout(() => {
						try {
							result = doAuthenticateUserPromisified(authenticationDetails);
							loginSuccess = true;
						} catch (e) {
							timeoutException = e && e.code && e.code === "TooManyRequestsException";
							lastException = e;
							console.error(e);
						}

						if (loginSuccess) {
							resolve(result);
							return;
						} else if (!timeoutException) {
							reject(lastException);
							return;
						} else {
							//Backoff 3 
							loginSuccess = false;
							lastException = null;
							timeoutException = false;
							result = null;
							setTimeout(() => {
								try {
									result = doAuthenticateUserPromisified(authenticationDetails);
									loginSuccess = true;
								} catch (e) {
									timeoutException = e && e.code && e.code === "TooManyRequestsException";
									lastException = e;
									console.error(e);
								}

								if (loginSuccess) {
									resolve(result);
									return;
								} else if (!timeoutException) {
									reject(lastException);
									return;
								} else {
									//Backoff 4 Final
									setTimeout(() => {
										try {
											result = doAuthenticateUserPromisified(authenticationDetails);
											resolve(result);
										} catch (e) {
											reject(e);
										}
									}, 8000);
								}
							}, 4000);
						}
					}, 2000);
				}
			}, 1000);
		}
	});

}

function loginUser(tree = state, username, password) {
	try {
		const _username = _.toLower(username);

		// Force USER_SRP_AUTH in case magic link was last auth method used which sets 
		// auth flow to CUSTOM_AUTH, also, reset user pool and client ID if impersonated
		// user is in different user pool
		const currentConfig = Auth.configure();
		console.log("Current config", currentConfig);

		if (currentConfig.Auth) {
			currentConfig.Auth.userPoolId = globals.cognito.userPoolId;
			currentConfig.Auth.userPoolWebClientId = globals.cognito.clientId;
		}
		currentConfig.userPoolId = globals.cognito.userPoolId;
		currentConfig.userPoolWebClientId = globals.cognito.clientId;

		Auth.configure(_.assign(currentConfig, {
			authenticationFlowType: 'USER_SRP_AUTH'
		}));

		Auth.signIn(_username, password).catch(err => {
			console.log("Login Failure", err);
			utils.hideLoader(tree);
			let message = "Please verify your credentials and try again, you can also use the forgot password link to reset your password.";
			if (err.code && err.code === "NetworkError") {
				message = "Network connection failed, please check your internet connection and try again.";
			}
			showError("Login Unsuccessful", message);
		});
	} catch (e) {
		console.error(e);
		utils.hideLoader(tree);
		showError("Login Unsuccessful", "Please make sure your internet connection is working. ");
	}
}

export async function restoreSession() {
	console.log("RESTORE SESSION");
	// alert("TRY LOGGING IN");

	if (window.location.hostname.toLowerCase() === "hca.ambifi.com") {
		const forceLogin = utils.getLs("forceLoginV3");

		console.log("FORCE LOGIN", forceLogin);

		if ((forceLogin === null || forceLogin !== "false")) {
			try {
				// Do this prior to trying to check the users session
				state.set(["restoreSession"], false);

				await handleConfigureAmplify();

				Auth.federatedSignIn();

				utils.setLs("forceLoginV3", "false");

				return;
			} catch (e) {
				// Ok so we don't have a session, this means we have to determine what our Amplify settings are!
				state.set(["restoreSession"], false);

				console.log('No session');
				if (state.get(["appState", "enterprise"])) {
					Auth.federatedSignIn();
				} else {
					utils.hideLoader(state);
					state.set(["appState", "initialized"], true);
				}

				return;
			}
		}
	}

	state.set(["appState", "needsSessionRestore"], false);

	try {
		// Do this prior to trying to check the users session
		await handleConfigureAmplify();


		let cognitoUser = await Auth.currentAuthenticatedUser();// userPool.getCurrentUser();
		console.log("restoreSession, HAVE A SESSION");
		let session = await Auth.currentSession();

		console.log("restoreSession, session validity: " + session.isValid());

		const username = cognitoUser.username;
		const rememberMe = utils.getLs(username + ";rememberMe");
		console.log("restoreSession, got user", cognitoUser);
		const restore = session.isValid() && rememberMe;

		sendRestoreSession(restore);

		// TODO JJB, not sure why if enterprise it goes in here and logout
		state.set(["appState", "initialized"], true);

		if (!state.get(["appState", "enterprise"]) && (!restore || state.get(["inviteMember", "inviteCode"]))) {
			console.log("restoreSession, not restoring this session", rememberMe, restore, state.get(["inviteMember", "inviteCode"]));
			//return Promise.resolve();
			if (session.isValid()) {
				Auth.signOut();
			}
			utils.hideLoader(state);
		} else {
			// utils.showLoader(state);
			if ((window.location.href.indexOf("/auth") !== -1 && window.location.href.indexOf("/authenticate-magic-link") === -1) || window.location.href.indexOf("/security-context") !== -1) {
				try {
					await handleRemotePlayerAuth(session);
				} catch (e) {
					console.log('ERROR', e);
				}
			} else {
				await handlePostAuthenticateV2(session, cognitoUser, state, username);
			}
		}
	} catch (e) {
		// Ok so we don't have a session, this means we have to determine what our Amplify settings are!
		sendRestoreSession(false);
		console.log('No session');
		if (state.get(["appState", "enterprise"])) {
			Auth.federatedSignIn();
		} else {
			utils.hideLoader(state);
			state.set(["appState", "initialized"], true);
		}
	}
}

export async function handleRemotePlayerAuth(session) {
	const authParams = getQueryStringParam("auth");
	let _authParams = validateAndParseAuthParams(authParams);

	const loginResult = session;
	const tmpCredentialsFilename = `${uuidv4()}.json`;
	const fedTokenEndpoint = `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.auth}`;

	const _fedTokenResponse = await request.post(fedTokenEndpoint)
		.set({ Authorization: loginResult.idToken.jwtToken, "Content-Type": "application/json" })
		.send(JSON.stringify({ tmpCredentialsFilename }));

	const fedTokenResponse = _fedTokenResponse.body;
	AWS.config.credentials = new AWS.Credentials(fedTokenResponse.body.accessKeyId, fedTokenResponse.body.secretAccessKey, fedTokenResponse.body.sessionToken);
	// console.log("Got:", userData);


	const { redirectUri } = _authParams;
	let _redirectUri = redirectUri;

	const identityId = _.get(loginResult, "idToken.payload.sub", null);


	const authCallback = {
		success: true,
		tmpCredentials: fedTokenResponse.body,
		tmpCredentialsFilename,
		tmpCredentialsBucket: env.s3.tmpCredentialsBucket
	};

	const authPayload = {
		success: true,
		loginResult,
		tokens: {
			id: loginResult.idToken.jwtToken,
			refresh: loginResult.refreshToken.token
		},
		fedTokenResponse,
		fedTokenEndpoint,
		cognito: {
			ClientId: globals.cognito.clientId
		},
		identityId
	};

	await awsS3.uploadObject(authPayload, env.s3.tmpCredentialsBucket, tmpCredentialsFilename, true);

	if (redirectUri.indexOf("?") !== -1) {
		_redirectUri = `${redirectUri}&authCallbackV2=${encode(btoa(JSON.stringify(authCallback)))}`;
	} else {
		_redirectUri = `${redirectUri}?authCallbackV2=${encode(btoa(JSON.stringify(authCallback)))}`;
	}
	utils.hideLoader(state);
	window.location.href = `${_redirectUri}`; // eslint-disable-line require-atomic-updates
	return loginResult;
}

// function getLcOrgs() {

// }

// function fetchOrgConfiguration() {

// }

async function handleConfigureAmplify() {
	const host = window.location.hostname;
	const subDomain = host.split(".")[0];
	state.set(["appState", "subDomain"], subDomain);
	// const lastOrgConfig = getLastLoginOrg();
	let amplifyConfig = null;

	if (isNaN(subDomain.charAt(0)) && !subDomain.startsWith("172") && subDomain !== "dev-app" && subDomain !== "app" && subDomain !== "localhost") {
		// TODO Custom!

		// if (lastOrgConfig && lastOrgConfig.domain === subDomain && lastOrgConfig.enterprise_userPoolConfiguration && _.isString(lastOrgConfig.enterprise_userPoolConfiguration)) {
		// 	// Got it!
		// 	try {
		// 		amplifyConfig = JSON.parse(lastOrgConfig.enterprise_userPoolConfiguration);
		// 		console.log("No need to fetch config, had it locally:", amplifyConfig);
		// 	} catch (e) {
		// 		console.error("Failed to parse enterprise login configuration", e);
		// 	}
		// } else {
		// Fetch it!
		const res = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.userInitAuthGetOrgLoginInfo(subDomain)}`);
		const orgLoginInfo = res.body;
		if (orgLoginInfo.enterprise === 1) {
			state.set(["appState", "enterprise"], true);
		}
		console.log("Needed to fetch login config:", orgLoginInfo);
		if (orgLoginInfo.enterprise_userPoolConfiguration) {

			try {
				amplifyConfig = JSON.parse(orgLoginInfo.enterprise_userPoolConfiguration);
			} catch (e) {
				console.error("Failed to parse enterprise login configuration", e);
			}


		}
		// }

	} else {
		// Check and see if we have previous orgs that are custom and need redirected
		// if (lastOrgConfig && lastOrgConfig.domain && lastOrgConfig.enterprise === 1) {
		// 	window.location.href = `https://${lastOrgConfig.domain}.ambifi.com`;
		// 	return;
		// } else {
		// All good, normal login!

		console.log("Globals cognito", globals.cognito);

		amplifyConfig = {
			identityPoolId: globals.cognito.identityPoolId,
			// REQUIRED - Amazon Cognito Region
			region: "us-east-1",
			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: globals.cognito.userPoolId,
			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: globals.cognito.clientId,
			// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
			mandatorySignIn: true
		};
	}

	if (amplifyConfig) {

		console.log("Configuring Amplify", amplifyConfig);
		await new Promise((resolve) => {
			setTimeout(() => {
				Amplify.configure({
					Auth: amplifyConfig
				});
				console.log("(Done) Configuring Amplify");
				resolve();
			}, 1000)
		})


	} else {
		console.log("Not configuring Amplify, no config");
	}
}

export function clearUser() {
	state.set(["user", "loggedIn"], false);
	// Clear impersonation flag
	utils.removeSs("impersonate");
	resetDrive();

	try {
		if (AWS.config.credentials && AWS.config.credentials.clearCachedId) {
			AWS.config.credentials.clearCachedId();
		}
		if (window.credentials && window.credentials.clearCachedId) {
			window.credentials.clearCachedId();
		}
	} catch (e) {
		console.error("Failed to clear cached identity id.", e);
	}
}

export async function logoutUser(redirect = true) {

	try {
		if (state.get(["user", "chatEnabled"])) {
			await VoxeetSdk.session.close();
			await chatClient.disconnectUser();
		}
	} catch (err) {
		console.log("Error", err);
	}

	try {
		deleteLastLoginOrg();
		resetDrive();
		state.set(["appState", "initialized"], false);
		state.set(["appState", "needsSessionRestore"], true);
		utils.removeSs("impersonate");
		postMessage({ method: "logout" });
		handleLogout();
		await Auth.signOut();

		utils.hideLoader(state);
		if (redirect) {
			setTimeout(function () {
				browserHistory.push(globals.routes.login);
			}, 1000);
		}
	} catch (error) {
		console.log('error signing out: ', error);
	}


}

export function removeDevice(deviceKey) {

	refreshCredentials(true).then(({ cognitoUser }) => {
		let devices = [];
		let devicesBlacklist = [];
		cognitoUser.getUserAttributes(async (err, result) => {

			if (err) {
				showError("User Attributes", err);
				return;
			}
			for (let i = 0; i < result.length; i++) {
				if (result[i].getName() === "custom:devices") {
					devices = JSON.parse(result[i].getValue());
				} else if (result[i].getName() === "custom:devicesBlacklist") {
					devicesBlacklist = JSON.parse(result[i].getValue());
				}
			}

			if (devices.length > 0) {
				let index = -1;
				for (let i = 0; i < devices.length; i++) {
					let device = devices[i];
					if (device.deviceId === deviceKey) {
						index = i;
					}
				}

				devices.splice(index, 1);
				updateAttribute(cognitoUser, "custom:devices", devices);

				devicesBlacklist.push(deviceKey);
				updateAttribute(cognitoUser, "custom:devicesBlacklist", devicesBlacklist);

				state.set(["devices"], devices);
				//state.set(["devicesBlacklist"], devicesBlacklist);
				// Need to post this to mobile app
			}
		});

	}).catch((err) => {
		console.error(err);
		showError("Remove Device", err.message);
	});
}

export function removeDevice2(deviceKey) {
	// Set the region where your identity pool exists (us-east-1, eu-west-1)
	AWS.config.region = "us-east-1";

	// Configure the credentials provider to use your identity pool
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: globals.cognito.identityPoolId,
	});

	// Make the call to obtain credentials
	AWS.config.credentials.get(function () {

		let user = state.get(["user"]);
		let event = {
			username: user.username,
			deviceKey: deviceKey
		};

		let lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: "2015-03-31" });
		let params = {
			FunctionName: "forgetDevice",
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: JSON.stringify(event)
		};

		lambda.invoke(params, function (error) {
			let index = -1;
			if (error) {
				alert(error.message);
			} else {
				let devices = state.get(["devices"]);
				for (let i = 0; i < devices.length; i++) {
					let device = devices[i];
					if (device.DeviceKey === deviceKey) {
						index = i;
					}
				}

				state.splice(["devices"], [index, 1]);
			}
		});
	});
}

export function sendMagicLink(email) {
	return new Promise((resolve, reject) => {
		const currentConfig = Auth.configure();
		//const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
		const baseUrl = utils.getAppBaseUrl();
		request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.generateMagicLink}`)
			.set({ "Content-Type": "application/json" })
			.send(JSON.stringify({
				email,
				userPoolId: currentConfig.userPoolId,
				baseUrl
			})).then(res => {

				console.log("Fetched orgs", res.body);
				resolve();
			}).catch(err => {
				console.error(err);
				reject(err);
			});

	});
}

export function handleCustomSignIn(usernameOrEmail, challengeResponse, userPoolId, userPoolWebClientId) {
	console.log("Handling custom sign in");
	const currentConfig = Auth.configure();
	if (currentConfig.Auth) {
		currentConfig.Auth.userPoolId = userPoolId;
		currentConfig.Auth.userPoolWebClientId = userPoolWebClientId;
	}
	currentConfig.userPoolId = userPoolId;
	currentConfig.userPoolWebClientId = userPoolWebClientId;
	Auth.configure(_.assign(currentConfig, {
		authenticationFlowType: 'CUSTOM_AUTH'
	}));

	console.log("Current config", currentConfig);

	Auth.signIn(usernameOrEmail, null)
		.then(user => {
			if (user.challengeName === 'CUSTOM_CHALLENGE') {
				// to send the answer of the custom challenge
				Auth.sendCustomChallengeAnswer(user, challengeResponse)
					.then(user => console.log(user))
					.catch(err => console.log(err));
			} else {
				console.log(user);
			}
		})
		.catch(err => console.log(err));
}

export async function fetchOrgInfo(jwt) {
	let orgInfo = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.orgInfo}`)
		.set("Authorization", jwt)
		.set({ "Accept": "application/json" });

	return uncompressResult(orgInfo.body).orgs;
}

export async function fetchDrive2(jwt) {
	// Let's fetch in two steps for a bit more scalability
	let drive = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.drive}`)
		.set("Authorization", jwt)
		.set({ "Accept": "application/json" });

	drive = uncompressResult(drive.body);

	let driveMap = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.driveMap}`)
		.set("Authorization", jwt)
		.set({ "Accept": "application/json" });

	driveMap = uncompressResult(driveMap.body);

	// Merge the two requests
	let retDrive = {
		tree: drive.drive,
		objectGuidToObject: driveMap.drive
	}

	return retDrive;
}

export async function fetchDrive(jwt) {
	const drive = await fetchDriveTree("tree", false, true);

	return drive;
}

export async function fetchInventory(jwt, playlistId) {
	let inventory = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.inventory}${(playlistId && playlistId !== "") ? ("?playlistId=" + playlistId) : ""}`)
		.set("Authorization", jwt)
		.set({ "Accept": "application/json" });

	//console.log("Inventory", JSON.stringify(uncompressResult(inventory.body), null, 4));

	return uncompressResult(inventory.body);
}
import React, { useState, useEffect } from 'react';
import {
	closestCenter,
	DndContext,
	// DragOverlay,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// import { HotspotItem } from './HotspotItem';
import { SortableHotspotPropertyItem } from './SortableHotspotPropertyItem';
// import { KenBurnsEffectItem } from './KenBurnsEffectItem';
import { SortableKenBurnsEffectPropertyItem } from './SortableKenBurnsEffectPropertyItem';

import { JSONEditor } from 'react-json-editor-viewer';

import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import Glyphicon from "../../components/app-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let pressAndHoldInterval;
let pressAndHoldCount = 0;

export default function MarkupImageProperties(props) {
	const [selectedItem, setSelectedItem] = useState(null);
	// const [hotspotActiveId, setHotspotActiveId] = useState(null);
	// const [kenBurnsEffectActiveId, setKenBurnsEffectActiveId] = useState(null);

	const hotspotItems = props.imageHotspots;

	let hotspotItemsAllCanvasVisible = true;
	let hotspotItemsAllCanvasHidden = true;
	hotspotItems.forEach((hotspotItem) => {
		if (hotspotItem.hasOwnProperty("canvasVisible") && !hotspotItem.canvasVisible) {
			hotspotItemsAllCanvasVisible = false;
		} else if (!hotspotItem.hasOwnProperty("canvasVisible") || hotspotItem.canvasVisible) {
			hotspotItemsAllCanvasHidden = false;
		}
	});

	const kenBurnsEffectItems = props.imageKenBurnsEffect;

	let kenBurnsEffectItemsAllCanvasVisible = true;
	let kenBurnsEffectItemsAllCanvasHidden = true;
	kenBurnsEffectItems.forEach((kenBurnsEffectItem) => {
		if (kenBurnsEffectItem.hasOwnProperty("canvasVisible") && !kenBurnsEffectItem.canvasVisible) {
			kenBurnsEffectItemsAllCanvasVisible = false;
		} else if (!kenBurnsEffectItem.hasOwnProperty("canvasVisible") || kenBurnsEffectItem.canvasVisible) {
			kenBurnsEffectItemsAllCanvasHidden = false;
		}
	});

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	useEffect(() => {
		setSelectedItem(props.selectedItem);
	}, [props.selectedItem]);

	const jsonEditorStyles = {
		dualView: {
			display: "flex",
		},
		jsonViewer: {
			// borderLeft: "1px dashed white",
			lineHeight: 1.25,
			width: "50%",
			borderLeft: "1px solid lightgrey",
			margin: 10,
		},
		jsonEditor: {
			width: "50%",
			fontSize: 12,
			// fontFamily: "Lucida Console, monospace",
			lineHeight: 1.25,
		},
		root: {
			fontSize: 12,
			// fontFamily: "Lucida Console, monospace",
			lineHeight: 1.25,
			/*color: "#3E3D32"*/
		},
		label: {
			color: "black",
			marginTop: 3,
			width: "175px",
			fontWeight: "bold"
		},
		value: {
			marginLeft: 10,
		},
		row: {
			display: "flex",
		},
		// withChildrenLabel: {
		// 	color: "DeepPink",
		// },
		select: {
			borderRadius: 3,
			border: "1px solid lightgray",
			// borderColor: "grey",
			// backgroundColor: "DimGray",
			// color: "khaki",
		},
		input: {
			borderRadius: 3,
			border: "1px solid lightgray",
			padding: 2,
			// fontFamily: "Lucida Console, monospace",
			fontSize: 12,
			// backgroundColor: "gray",
			// color: "khaki"
		},
		addButton: {
			display: "none"
		},
		removeButton: {
			display: "none"
		},
		saveButton: {
			display: "none"
		},
		builtin: {
			color: "green",
			fontSize: 12,
		},
		text: {
			color: "black",
			fontSize: 12,
		},
		// number: {
		// 	color: "purple",
		// 	fontSize: 12,
		// },
		// property: {
		// 	color: "DeepPink",
		// 	fontSize: 12,
		// },
		collapseIcon: {
			cursor: "pointer",
			fontSize: 10,
			color: "teal",
		},
	};

	const pressAndHoldMove = (direction) => {
		pressAndHoldInterval = setInterval(() => {
			pressAndHoldCount++;
			if (selectedItem && selectedItem.hasOwnProperty("coords")) {
				props.onHotspotMove(direction, 1);
			} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
				props.onKenBurnsEffectMove(direction, 1);
			}

			if (pressAndHoldCount > 5) {
				clearInterval(pressAndHoldInterval);

				pressAndHoldInterval = setInterval(() => {
					pressAndHoldCount++;
					if (selectedItem && selectedItem.hasOwnProperty("coords")) {
						props.onHotspotMove(direction, pressAndHoldCount > 50 ? 20 : 5);
					} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
						props.onKenBurnsEffectMove(direction, pressAndHoldCount > 50 ? 20 : 5);
					}
				}, 50);
			}
		}, 200);
	}

	const pressAndHoldRotate = (direction) => {
		pressAndHoldInterval = setInterval(() => {
			pressAndHoldCount++;
			if (selectedItem && selectedItem.hasOwnProperty("coords")) {
				props.onHotspotRotate(direction, 1);
			}/* else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
				props.onKenBurnsEffectRotate(direction, 1);
			}*/

			if (pressAndHoldCount > 5) {
				clearInterval(pressAndHoldInterval);

				pressAndHoldInterval = setInterval(() => {
					pressAndHoldCount++;
					if (selectedItem && selectedItem.hasOwnProperty("coords")) {
						props.onHotspotRotate(direction, pressAndHoldCount > 50 ? 20 : 5);
					}/* else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
						props.onKenBurnsEffectRotate(direction, pressAndHoldCount > 50 ? 20 : 5);
					}*/
				}, 50);
			}
		}, 200);
	}

	return (
		<div style={{ display: "flex", flexDirection: "column", backgroundColor: "#eeeeee", width: "100%", height: "100%", padding: 5, borderLeftStyle: "solid", borderLeftWidth: "1px", borderLeftColor: "#cccccc", overflow: "auto" }}>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<ButtonToolbar style={{ marginBottom: "10px", flex: 0, backgroundColor: "#ddddddcc", padding: "5px", width: "100%" }}>
					<ButtonGroup>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotClone();
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectClone();
								}
							}}
							title="Clone the selected item">
							<Glyphicon glyph="clone" />
						</Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotDelete();
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectDelete();
								}
							}}
							title="Delete the selected item">
							<Glyphicon glyph="trash" />
						</Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotMove("left");
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectMove("left");
								}
							}}
							onMouseDown={() => {
								pressAndHoldMove("left");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Move left">
							<Glyphicon glyph="arrow-left" />
						</Button>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotMove("right");
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectMove("right");
								}
							}}
							onMouseDown={() => {
								pressAndHoldMove("right");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Move right">
							<Glyphicon glyph="arrow-right" />
						</Button>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotMove("up");
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectMove("up");
								}
							}}
							onMouseDown={() => {
								pressAndHoldMove("up");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Move up">
							<Glyphicon glyph="arrow-up" />
						</Button>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotMove("down");
								} else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectMove("down");
								}
							}}
							onMouseDown={() => {
								pressAndHoldMove("down");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Move down">
							<Glyphicon glyph="arrow-down" />
						</Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotRotate("left");
								}/* else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectRotate("left");
								}*/
							}}
							onMouseDown={() => {
								pressAndHoldRotate("left");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Rotate left">
							{/* <Glyphicon glyph="undo-alt" /> */}
							<FontAwesomeIcon fixedWidth={true} title="Rotate left" style={{ transform: "rotate(105deg)" }} icon={["fas", "undo-alt"]} />
						</Button>
						<Button disabled={!selectedItem}
							onClick={() => {
								if (selectedItem && selectedItem.hasOwnProperty("coords")) {
									props.onHotspotRotate("right");
								}/* else if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
									props.onKenBurnsEffectRotate("right");
								}*/
							}}
							onMouseDown={() => {
								pressAndHoldRotate("right");
							}}
							onMouseUp={() => {
								clearInterval(pressAndHoldInterval);
								pressAndHoldCount = 0;
							}}
							title="Rotate right">
							<FontAwesomeIcon fixedWidth={true} title="Rotate right" style={{ transform: "rotate(70deg)" }} icon={["fas", "redo-alt"]} />
							{/* <Glyphicon glyph="redo-alt" /> */}
						</Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button disabled={!selectedItem}
							onClick={() => {
								props.onSetTimeInfo();
							}}
							title="Set the currently selected time info in the selected item.">
							<Glyphicon glyph="clock" />
						</Button>
					</ButtonGroup>

				</ButtonToolbar>

				<div style={{ fontWeight: "bold" }}>
					Hotspots &amp; Highlights
				</div>
				{hotspotItems.length === 0 &&
					<div style={{ margin: "5px" }}><i>Add a hotspot or highlight by drawing a rectangle on the canvas.</i></div>
				}
				{hotspotItems.length > 0 &&
					<div style={{ marginRight: "10px", marginLeft: "5px", cursor: "pointer", borderBottomStyle: "solid", borderBottomColor: "#dddddd", marginBottom: "5px" }} onClick={() => {
						props.onToggleAllHotspotsVisible();
					}}>
						<Glyphicon style={{ fontSize: "12px", color: "#444444" }} glyph={hotspotItemsAllCanvasVisible ? "eye" : hotspotItemsAllCanvasHidden ? "eye-slash" : "minus-circle"} />
					</div>
				}
				<div>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						// onDragStart={handleHotspotDragStart}
						onDragEnd={handleHotspotsDragEnd}
					>
						<SortableContext
							items={hotspotItems.map(item => item.guid)}
							strategy={verticalListSortingStrategy}
						>
							{hotspotItems.map((hotspotItem, idx) => <SortableHotspotPropertyItem onToggleHotspotVisible={() => { props.onToggleHotspotVisible(idx) }} onDeleteHotspot={() => { props.onDeleteHotspot(idx) }} onClone={props.onHotspotClone} tabIndex={idx} key={hotspotItem.guid} id={hotspotItem.guid} hotspotItem={hotspotItem} selected={selectedItem && selectedItem.hasOwnProperty("coords") && selectedItem.guid === hotspotItem.guid} onSelectItem={(item) => {
								props.onSelectedItemSelectionChange(item);
							}} />)}
						</SortableContext>
						{/* <DragOverlay>
							{hotspotActiveId ? (
								<HotspotItem id={`${hotspotItems.find(x => x.guid === hotspotActiveId).id}`} />
							) : null}
						</DragOverlay> */}
					</DndContext>
				</div>
				<div style={{ fontWeight: "bold", marginTop: "15px" }}>
					Cameras
				</div>
				{kenBurnsEffectItems.length === 0 &&
					<div style={{ margin: "5px" }}><i>Add a camera by drawing a rectangle on the canvas.</i></div>
				}
				{kenBurnsEffectItems.length > 0 &&
					<div style={{ marginRight: "10px", marginLeft: "5px", cursor: "pointer", borderBottomStyle: "solid", borderBottomColor: "#dddddd", marginBottom: "5px" }} onClick={() => {
						props.onToggleAllKenBurnsEffectVisible();
					}}>
						<Glyphicon style={{ fontSize: "12px", color: "#444444" }} glyph={kenBurnsEffectItemsAllCanvasVisible ? "eye" : kenBurnsEffectItemsAllCanvasHidden ? "eye-slash" : "minus-circle"} />
					</div>
				}
				<div>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						// onDragStart={handleKenBurnsEffectDragStart}
						onDragEnd={handleKenBurnsEffectDragEnd}
					>
						<SortableContext
							items={kenBurnsEffectItems.map(item => item.guid)}
							strategy={verticalListSortingStrategy}
						>
							{kenBurnsEffectItems.map((kenBurnsEffect, idx) => <SortableKenBurnsEffectPropertyItem onToggleKenBurnsEffectVisible={() => { props.onToggleKenBurnsEffectVisible(idx) }} onDeleteCamera={() => { props.onDeleteCamera(idx) }} onClone={props.onKenBurnsEffectClone} tabIndex={idx} key={kenBurnsEffect.guid} id={kenBurnsEffect.guid} kenBurnsEffect={kenBurnsEffect} selected={selectedItem && selectedItem.hasOwnProperty("startTime") && selectedItem.guid === kenBurnsEffect.guid} onSelectItem={(item) => {
								props.onSelectedItemSelectionChange(item);
							}} />)}
						</SortableContext>
						{/* <DragOverlay>
							{kenBurnsEffectActiveId ? (
								<KenBurnsEffectItem id={`${kenBurnsEffectItems.find(x => x.guid === kenBurnsEffectActiveId).id}`} />
							) : null}
						</DragOverlay> */}
					</DndContext>
				</div>
			</div>
			<ButtonToolbar style={{ marginTop: "15px", flex: 0, backgroundColor: "#ddddddcc", padding: "5px", width: "100%" }}>
				<ButtonGroup>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")}
						onClick={() => {
							if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
								props.onKenBurnsEffectZoom(1.1);
							}
						}} onMouseDown={() => {
							pressAndHoldInterval = setInterval(() => {
								props.onKenBurnsEffectZoom(1.1);
							}, 300);
						}} onMouseUp={() => {
							clearInterval(pressAndHoldInterval);
						}}
						title="Zoom camera out">
						<Glyphicon glyph="search-minus" />
					</Button>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")}
						onClick={() => {
							if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
								props.onKenBurnsEffectZoom(0.9);
							}
						}} onMouseDown={() => {
							pressAndHoldInterval = setInterval(() => {
								props.onKenBurnsEffectZoom(0.9);
							}, 300);
						}} onMouseUp={() => {
							clearInterval(pressAndHoldInterval);
						}}
						title="Zoom camera in">
						<Glyphicon glyph="search-plus" />
					</Button>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")} onClick={() => {
						if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
							props.onKenBurnsEffectPan("left");
						}
					}} title="Pan camera left">
						<Glyphicon glyph="arrow-square-left" />
					</Button>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")} onClick={() => {
						if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
							props.onKenBurnsEffectPan("right");
						}
					}} title="Pan camera right">
						<Glyphicon glyph="arrow-square-right" />
					</Button>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")} onClick={() => {
						if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
							props.onKenBurnsEffectPan("up");
						}
					}} title="Pan camera up">
						<Glyphicon glyph="arrow-square-up" />
					</Button>
					<Button disabled={!selectedItem || selectedItem.hasOwnProperty("coords")} onClick={() => {
						if (selectedItem && selectedItem.hasOwnProperty("startTime")) {
							props.onKenBurnsEffectPan("down");
						}
					}} title="Pan camera down">
						<Glyphicon glyph="arrow-square-down" />
					</Button>
				</ButtonGroup>
			</ButtonToolbar>
			<div style={{ fontWeight: "bold", marginTop: "15px" }}>
				Properties
			</div>
			{selectedItem === null &&
				<div style={{ margin: "5px" }}><i>There is no component selected.</i></div>
			}
			{selectedItem !== null &&
				<div>
					<JSONEditor
						data={selectedItem}
						collapsible={false}
						onChange={(name, value, parent, data) => {
							console.log(name, value, parent, data);
							setSelectedItem(data);
							props.onSelectedItemChange(data);
						}}
						styles={jsonEditorStyles}
					/>
				</div>
			}

		</div>
	);

	// function handleHotspotDragStart(event) {
	// 	console.log("!@#!@# EVENT", event);
	// 	const { active } = event;

	// 	setHotspotActiveId(active.id);
	// }

	function handleHotspotsDragEnd(event) {
		const { active, over } = event;

		if (active.id !== over.id) {
			// setHotspotItems((items) => {
			// 	const oldIndex = items.findIndex(x => x.id === active.id);
			// 	const newIndex = items.findIndex(x => x.id === over.id);

			// 	return arrayMove(items, oldIndex, newIndex);
			// });

			const oldIndex = hotspotItems.findIndex(x => x.guid === active.id);
			const newIndex = hotspotItems.findIndex(x => x.guid === over.id);

			props.onImageHotspotsChange(arrayMove(hotspotItems, oldIndex, newIndex));
		}
	}

	// function handleKenBurnsEffectDragStart(event) {
	// 	console.log("!@#!@# EVENT", event);
	// 	const { active } = event;

	// 	setKenBurnsEffectActiveId(active.id);
	// }

	function handleKenBurnsEffectDragEnd(event) {
		const { active, over } = event;

		if (active.id !== over.id) {
			// setKenBurnsEffectItems((items) => {
			// 	const oldIndex = items.findIndex(x => x.id === active.id);
			// 	const newIndex = items.findIndex(x => x.id === over.id);

			// 	return arrayMove(items, oldIndex, newIndex);
			// });

			const oldIndex = kenBurnsEffectItems.findIndex(x => x.guid === active.id);
			const newIndex = kenBurnsEffectItems.findIndex(x => x.guid === over.id);

			props.onImageKenBurnsEffectChange(arrayMove(kenBurnsEffectItems, oldIndex, newIndex));
		}
	}
}

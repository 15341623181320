import _ from "lodash";

export const PlayerProperties = {
	ROOT: "player",
	CHECKLIST: "checklist",
	ACTIVITIES: "activities",
	ACTIVITIES_LOADED: "activitiesLoaded",
	SESSIONS: "sessions",
	SESSIONS_MAP: "sessionsMap",
	SORTED_SESSIONS: "sortedSessions",
	STATUS_DETAIL_SESSIONS: "statusDetailSessions",
	CHECKLIST_ORIGINAL: "checklistOriginal",
	SELECTED_ACTIVITY_IDENTITY_ID: "selectedActivityIdentityId",
	HISTORY: "history",
	CHILDREN: "children",
	HISTORY_GEOLOCATION: "historyGeolocation",
	LOGBOOK: "logbook",
	TIME_OUT: "timeOut",
	TIME_IN: "timeIn",
	START_IN_SHOW_MODE: "startInShowMode",
	LISTS: "lists",
	SECTIONS: "sections",
	SHOW_SPINNER: "showSpinner",
	SELECTED_ACTIVITY_ID: "selectedActivityId",
	SELECTED_ACTIVITY_INSTANCE_ID: "selectedActivityInstanceId",
	SELECTED_ACTIVITY_ASSIGNMENT_ID: "selectedActivityAssignmentId",
	SHOW_SESSIONS_MODAL: "showSessionsModal",
	LAST_UPDATED_DATE_TIME: "lastUpdatedDateTime",
	GLOBAL_VARS: "globalVars",
	SELECTED: "selected",
	SELECTED_LIST_INDEX: "listIndex",
	SELECTED_SECTION_INDEX: "sectionIndex",
	SELECTED_ITEM_INDEX: "itemIndex",
	VARIABLES: "variables",
	VARIABLE_GROUPS: "variableGroups",
	OVERRIDES: "overrides",
	GUID: "guid",
	NAV_STACK: "navStack",
	RELOAD_ACTIVITIES: "reloadActivities",
	SELECTED_HOME_TAB: "selectedHomeTab"
};

const CHECKLIST_ROOT = ["currentChecklist"];

export default {
	ROOT: [PlayerProperties.ROOT],
	CHECKLIST: [...CHECKLIST_ROOT],
	ACTIVITIES: [PlayerProperties.ROOT, PlayerProperties.ACTIVITIES],
	ACTIVITIES_LOADED: [PlayerProperties.ROOT, PlayerProperties.ACTIVITIES_LOADED],
	SESSIONS: [PlayerProperties.ROOT, PlayerProperties.SESSIONS],
	SESSIONS_MAP: [PlayerProperties.ROOT, PlayerProperties.SESSIONS_MAP],
	SORTED_SESSIONS: [PlayerProperties.ROOT, PlayerProperties.SORTED_SESSIONS],
	CHECKLIST_ORIGINAL: [PlayerProperties.ROOT, PlayerProperties.CHECKLIST_ORIGINAL],
	SELECTED_ACTIVITY_IDENTITY_ID: [PlayerProperties.ROOT, PlayerProperties.SELECTED_ACTIVITY_IDENTITY_ID],
	CHECKLIST_HISTORY: [...CHECKLIST_ROOT, PlayerProperties.HISTORY],
	CHECKLIST_HISTORY_GEOLOCATION: [...CHECKLIST_ROOT, PlayerProperties.HISTORY_GEOLOCATION],
	CHECKLIST_LOGBOOK: [...CHECKLIST_ROOT, PlayerProperties.LOGBOOK],
	CHECKLIST_LOGBOOK_TIME_OUT: [...CHECKLIST_ROOT, PlayerProperties.LOGBOOK, PlayerProperties.TIME_OUT],
	CHECKLIST_LOGBOOK_TIME_IN: [...CHECKLIST_ROOT, PlayerProperties.LOGBOOK, PlayerProperties.TIME_IN],
	CHECKLIST_START_IN_SHOW_MODE: [...CHECKLIST_ROOT, PlayerProperties.START_IN_SHOW_MODE],
	CHECKLIST_LISTS: [...CHECKLIST_ROOT, PlayerProperties.CHILDREN],
	SHOW_SPINNER: [PlayerProperties.ROOT, PlayerProperties.SHOW_SPINNER],
	SELECTED_ACTIVITY_ID: [PlayerProperties.ROOT, PlayerProperties.SELECTED_ACTIVITY_ID],
	SELECTED_ACTIVITY_INSTANCE_ID: [PlayerProperties.ROOT, PlayerProperties.SELECTED_ACTIVITY_INSTANCE_ID],
	SELECTED_ACTIVITY_ASSIGNMENT_ID: [PlayerProperties.ROOT, PlayerProperties.SELECTED_ACTIVITY_ASSIGNMENT_ID],
	SHOW_SESSIONS_MODAL: [PlayerProperties.ROOT, PlayerProperties.SHOW_SESSIONS_MODAL],
	NAV_STACK: [PlayerProperties.ROOT, PlayerProperties.NAV_STACK],
	RELOAD_ACTIVITIES: [PlayerProperties.ROOT, PlayerProperties.RELOAD_ACTIVITIES],
	SELECTED_HOME_TAB: [PlayerProperties.ROOT, PlayerProperties.SELECTED_HOME_TAB],
	STATUS_DETAIL_SESSIONS: [PlayerProperties.ROOT, PlayerProperties.STATUS_DETAIL_SESSIONS],
	CHECKLIST_PROPERTY: (prop) => {
		return [...CHECKLIST_ROOT, prop];
	},
	CHECKLIST_LIST: (listIndex) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex];
	},
	CHECKLIST_LIST_PROPERTY: (listIndex, prop) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, prop];
	},
	CHECKLIST_SECTIONS: (listIndex) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN];
	},
	CHECKLIST_SECTION: (listIndex, sectionIndex) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex];
	},
	CHECKLIST_SECTION_PROPERTY: (listIndex, sectionIndex, prop) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex, prop];
	},
	CHECKLIST_ITEMS: (listIndex, sectionIndex) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex, PlayerProperties.CHILDREN];
	},
	CHECKLIST_ITEM: (listIndex, sectionIndex, itemIndex) => {
		return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex, PlayerProperties.CHILDREN, itemIndex];
	},
	CHECKLIST_ITEM_PROPERTY: (listIndex, sectionIndex, itemIndex, property) => {
		if (_.isArray(property)) {
			return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex, PlayerProperties.CHILDREN, itemIndex, ...property];
		} else {
			return [...CHECKLIST_ROOT, PlayerProperties.CHILDREN, listIndex, PlayerProperties.CHILDREN, sectionIndex, PlayerProperties.CHILDREN, itemIndex, property];
		}
	},
	SESSIONS_BY_ACTIVITY_ID: (activityId) => {
		return [PlayerProperties.ROOT, PlayerProperties.SESSIONS, activityId];
	},
	SESSION_LAST_UPDATED_DATE_TIME: (activityId, idx) => {
		return [PlayerProperties.ROOT, PlayerProperties.SESSIONS, activityId, idx, PlayerProperties.LAST_UPDATED_DATE_TIME];
	},
	GLOBAL_VARS: (name) => {
		return [PlayerProperties.ROOT, PlayerProperties.GLOBAL_VARS, name];
	},
	CHECKLIST_SELECTED_LIST_INDEX: [...CHECKLIST_ROOT, PlayerProperties.SELECTED, PlayerProperties.SELECTED_LIST_INDEX],
	CHECKLIST_SELECTED_SECTION_INDEX: [...CHECKLIST_ROOT, PlayerProperties.SELECTED, PlayerProperties.SELECTED_SECTION_INDEX],
	CHECKLIST_SELECTED_ITEM_INDEX: [...CHECKLIST_ROOT, PlayerProperties.SELECTED, PlayerProperties.SELECTED_ITEM_INDEX],
	CHECKLIST_VARIABLES: [...CHECKLIST_ROOT, PlayerProperties.VARIABLES],
	CHECKLIST_VARIABLE: (guid) => {
		return [...CHECKLIST_ROOT, PlayerProperties.VARIABLES, guid];
	},
	CHECKLIST_VARIABLE_GROUPS: [...CHECKLIST_ROOT, PlayerProperties.VARIABLE_GROUPS],
	CHECKLIST_VARIABLE_GROUP: (groupName) => {
		return [...CHECKLIST_ROOT, PlayerProperties.VARIABLE_GROUPS, groupName];
	},
	CHECKLIST_VARIABLE_GROUP_VARIABLE: (groupName, variable) => {
		return [...CHECKLIST_ROOT, PlayerProperties.VARIABLE_GROUPS, groupName, variable];
	},
	CHECKLIST_OVERRIDES: [...CHECKLIST_ROOT, PlayerProperties.OVERRIDES],
	CHECKLIST_OVERRIDE: (guid) => {
		return [...CHECKLIST_ROOT, PlayerProperties.OVERRIDES, guid];
	},
	CHECKLIST_OVERRIDE_PROPERTY: (guid, prop) => {
		return [...CHECKLIST_ROOT, PlayerProperties.OVERRIDES, guid, prop];
	}
};
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {

	const { title, subTitle, children, style, icon } = props;


	const pageSectionProps = {
		className: "pageSection"
	};
	if (style) {
		pageSectionProps.style = style;
	}

	return (
		<div {...pageSectionProps}>
			<div className="header">
				{icon && <FontAwesomeIcon icon={icon} />}
				<div className="title">{title}</div>
				<div className="subTitle">{subTitle}</div>
			</div>
			<div className="content">
				{children}
			</div>
		</div>
	);
};

import _ from "lodash";
import request from "superagent";

import { refreshCredentials } from "../utils/securityUtil";
import env from "../constants/env";
import state from "../state/state";
import { getJwtToken } from "../actions/user";
import { getActivityById } from "./inventoryActions";

export function initializeWorkflows(workflowResult) {
	return new Promise((resolve, reject) => {
		try {
			console.log("Fetched workflows", workflowResult);
			// state.set(["workflows"], workflowResult);
			if (workflowResult && _.isArray(workflowResult)) {

				state.set(["workflows"], workflowResult);
				workflowResult.forEach(workflow => {
					state.set(["workflowGuidToWorkflow", workflow.guid], workflow);
					workflow.stages.forEach(stage => {
						state.set(["workflowStages", stage.guid], stage);
					});
				});
			}
			resolve();
			// Single member of no or
		} catch (e) {
			reject(e);
		}
	});
}

export function initializeWorkflowParticipants(workflowParticipantsResult) {
	return new Promise((resolve, reject) => {
		try {
			console.log("Fetched workflow participants", workflowParticipantsResult);
			// state.set(["workflows"], workflowResult);

			// Store them in a more palletable way
			state.set(["workflowParticipants"], {});
			if (workflowParticipantsResult && _.isPlainObject(workflowParticipantsResult)) {
				if (_.isArray(workflowParticipantsResult.members)) {
					workflowParticipantsResult.members.forEach(memberParticipation => {
						const keyPath = ["workflowParticipants", "members", memberParticipation.activityId, memberParticipation.workflowStageGuid];
						if (!state.exists(keyPath)) {
							state.set(keyPath, []);
						}
						state.push(keyPath, memberParticipation);
					});
				}

				if (_.isArray(workflowParticipantsResult.groups)) {
					workflowParticipantsResult.groups.forEach(groupParticipation => {
						const keyPath = ["workflowParticipants", "groups", groupParticipation.activityId, groupParticipation.workflowStageGuid];
						if (!state.exists(keyPath)) {
							state.set(keyPath, []);
						}
						state.push(keyPath, groupParticipation);
					});
				}
			}
			console.log("Assigned workflow participants", state.get(["workflowParticipants"]));
			resolve();
			// Single member of no or
		} catch (e) {
			reject(e);
		}
	});
}
export function ensureActivityHasParticipantsDataStructure(activityId) {
	console.log("Ensure path exists", activityId);
	const keyPathMembers = ["workflowParticipants", "members", activityId];
	const keyPathGroups = ["workflowParticipants", "members", activityId];
	if (!state.exists(keyPathMembers)) {
		state.set(keyPathMembers, []);
	}

	if (!state.exists(keyPathGroups)) {
		state.set(keyPathGroups, []);
	}
}

export function associateActivityWithWorkflow(activityId, workflowGuid) {
	console.debug("associateActivityWithWorkflow", activityId, workflowGuid);
	return new Promise((resolve, reject) => {
		try {
			refreshCredentials().then(() => {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.workflow.associateActivityWithWorkflow(activityId, workflowGuid)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send().then(res => {
						const result = res.body;
						if (result.success) {
							resolve(result);
						} else {
							reject(result);
						}
					}).catch(err => {
						reject(err);
					});
			});
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
}

export function associateUsersAndGroupsWithActivityWorkflowStages(activityId, stageUserGroupInfo) {
	console.debug("associateUsersAndGroupsWithActivityWorkflowStages", stageUserGroupInfo);
	return new Promise((resolve, reject) => {
		try {
			refreshCredentials().then(() => {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.workflow.associateUsersAndGroupsWithWorkflowStages(activityId)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(JSON.stringify(stageUserGroupInfo)).then(res => {
						const result = res.body;
						if (result.success) {
							resolve(result);
						} else {
							reject(result);
						}
					}).catch(err => {
						reject(err);
					});
			});
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
}


export function transitionActivityToWorkflowStage(activityId, stageGuid) {
	console.debug("transitionActivityToWorkflowStage", activityId, stageGuid);
	return new Promise((resolve, reject) => {
		try {
			refreshCredentials().then(() => {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.workflow.transitionActivityToStage(activityId, stageGuid)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send().then(res => {
						const result = res.body;
						if (result.success) {
							resolve(result);
						} else {
							reject(result);
						}
					}).catch(err => {
						reject(err);
					});
			});
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
}

export function getWorkflow(workflowGuid) {
	if (!workflowGuid) {
		return null;
	}
	return state.get(["workflowGuidToWorkflow", workflowGuid]);
}

export function transitionActivityToFirstWorkflowStage(activityId) {
	console.debug("transitionActivityToFirstWorkflowStage", activityId);
	return new Promise(async (resolve, reject) => {
		try {
			const activity = getActivityById(activityId);
			const { workflowGuid } = activity;
			if (workflowGuid && _.isString(workflowGuid)) {
				const workflow = getWorkflow(workflowGuid);
				if (workflow && workflow.transitions && _.isArray(workflow.transitions)) {
					const initialTransition = _.find(workflow.transitions, (transition) => transition.start === 1);
					if (initialTransition) {
						await transitionActivityToWorkflowStage(activityId, initialTransition.fromStageGuid);
					}
				}
			}
			resolve();
		} catch (e) {
			console.error(e);
			reject(e);
		}
	});
}
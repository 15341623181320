import React from "react";
import { branch } from "baobab-react/higher-order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flexbox from "flexbox-react";

import MultiSelectToolbar from "./app-multiselect-toolbar";

import { selectAllStatusItems, resetSelectedStatusItems } from "../../actions/statusActions";
import { getOrgId } from "../../actions/orgsActions";

class MultiSelectStatusToolbar extends React.PureComponent {
	render() {
		let multiSelectToolbar =
			<MultiSelectToolbar
				selectedItems={this.props.selectedStatusItems}
				onClickClose={() => {
					this.props.dispatch(
						resetSelectedStatusItems
					);
				}}
				onClickSelectAll={() => {
					this.props.dispatch(
						selectAllStatusItems
					);
				}}>
				<Flexbox flexDirection="row" alignItems="center">
					{getOrgId() === "332a9d47-6e36-11e8-a392-061714f61e3e" &&
						<div style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => {
							window.globalSearchHistoryView.setWorkflowStatuses();
						}}>
							<FontAwesomeIcon icon="users-cog" />
						</div>
					}
					<div style={{ cursor: "pointer" }} onClick={() => {
						window.globalSearchHistoryView.deleteInstances();
					}}>
						<FontAwesomeIcon icon="trash" />
					</div>
				</Flexbox>
			</MultiSelectToolbar>;

		if (this.props.selectedStatusItems.length === 0) {
			multiSelectToolbar = null;
		}

		return multiSelectToolbar;
	}
}

export default branch({
	selectedStatusItems: ["appState", "selectedStatusItems"],
}, MultiSelectStatusToolbar);
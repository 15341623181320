import React from "react";
import Modal from "react-responsive-modal";
import autoBind from "react-autobind";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertStringToDriveFileName, generatePastedImageFileName } from "../../utils/utils";
import state from "../../state/state";

import { getLs, setLs } from "../../utils/utils";

export default class PasteImageModal extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		autoBind(this);
		this.state = {
			imageName: ""
		};

		this.count = 0;
		this.found = false;
	}

	handlePaste(event) {
		//console.log("HANDLE PASTE", event);
		if (this.props.onPaste) {
			let newEvent = {
				imageName: this.state.imageName,
				pasteEvent: event
			}
			this.props.onPaste(newEvent);
		}
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	padWithZero(num, targetLength) {
		return String(num).padStart(targetLength, '0')
	}

	componentDidMount() {
		const pasteImageLastFilenameMode = getLs("pasteImageLastFilenameMode");

		if (pasteImageLastFilenameMode === "imageIndex") {
			this.autoNameByImageIndex();
		} else if (pasteImageLastFilenameMode === "outline") {
			this.autoNameByOutline();
		} else if (pasteImageLastFilenameMode === "timestampe") {
			this.autoNameByTimestamp();
		} else {
			this.autoNameByImageIndex();
		}

		document.addEventListener("paste", this.handlePaste);
	}

	componentWillUnmount() {
		document.removeEventListener("paste", this.handlePaste);
	}

	recurseFindIndicesByGuid(arr, guid) {
		for (let i = 0; i < arr.length; i++) {
			if (!this.found) {
				let entity = arr[i].entity;

				// Count items
				if (entity.type.startsWith("item")) {
					this.count++;
				}

				if (entity.guid === guid) {
					this.found = true;
				} else {
					if (arr[i].hasOwnProperty("children")) {
						this.recurseFindIndicesByGuid(arr[i].children, guid);
					}
				}
			}
		}
	}

	autoNameByImageIndex() {
		setLs("pasteImageLastFilenameMode", "imageIndex");

		let cursor = state.select(this.props.nodePath);
		let entity = cursor.get().entity;

		this.found = false;
		this.count = 0;
		this.recurseFindIndicesByGuid(state.get(["tree", "root", "children"]), entity.guid);

		const itemCount = this.count;

		console.log("ITEM COUNT", itemCount);

		this.setState({
			// imageName: generatePastedImageFileName()
			imageName: "image-" + this.padWithZero(this.count, 3) + ".png"
		})
	}

	autoNameByOutline() {
		setLs("pasteImageLastFilenameMode", "outline");

		let childrenCount = 0;

		for (let i = 0; i < this.props.nodePath.length; i++) {
			const node = this.props.nodePath[i];

			if (node === "children") {
				childrenCount++;
			}
		}

		// List
		if (childrenCount === 3) {
			const listIndex = this.props.nodePath[3];
			const sectionIndex = this.props.nodePath[5];
			const itemIndex = this.props.nodePath[7];

			const list = state.get(["tree", "root", "children", listIndex, "entity"]);
			const section = state.get(["tree", "root", "children", listIndex, "children", sectionIndex, "entity"]);

			this.setState({
				imageName: convertStringToDriveFileName(list.label) + "_" + convertStringToDriveFileName(section.label) + "_image-" + this.padWithZero(itemIndex + 1, 3) + ".png"
			})

			// Section
		} else if (childrenCount === 2) {
			const sectionIndex = this.props.nodePath[3];
			const itemIndex = this.props.nodePath[5];

			console.log("%$#@! OUTLINE", sectionIndex, itemIndex);

			const section = state.get(["tree", "root", "children", sectionIndex, "entity"]);

			console.log("%$#@! OUTLINE", section);

			this.setState({
				imageName: convertStringToDriveFileName(section.label) + "_image-" + this.padWithZero(itemIndex + 1, 3) + ".png"
			})
			// Item
		} else if (childrenCount === 1) {
			const itemIndex = this.props.nodePath[3];

			this.setState({
				imageName: "image-" + this.padWithZero(itemIndex + 1, 3) + ".png"
			})
		}
	}

	autoNameByTimestamp() {
		setLs("pasteImageLastFilenameMode", "timestamp");

		this.setState({
			imageName: generatePastedImageFileName()
		})
	}

	render() {
		let component = <Modal classNames={{ modal: "custom-modal" }} open={this.props.open} onClose={this.props.onClose}>
			<h3 style={{ textAlign: "center" }}>Paste Image from Clipboard</h3>
			<br />
			<div>
				<p>Press CTRL+V to paste an image (Windows).</p>
				<p>Press CMD+V to paste an image (Mac).</p>
				<hr />
				<div style={{ display: "flex", marginBottom: "15px", flexDirection: "column", color: "gray" }}>
					<label>
						Image name
					</label>
					<div>
						<input className="form-control" type="text" name="imageName" placeholder="image.png" value={this.state.imageName} onChange={this.handleChange} />
					</div>
				</div>
				<label style={{ color: "gray" }}>
					Auto naming
				</label>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex" }}>
						<Button style={{ marginRight: "5px" }} bsStyle="primary" onClick={() => { this.autoNameByImageIndex() }}>Image Index</Button>
						<Button style={{ marginRight: "5px" }} bsStyle="primary" onClick={() => { this.autoNameByOutline() }}>Outline</Button>
						<Button style={{ marginRight: "5px" }} bsStyle="primary" onClick={() => { this.autoNameByTimestamp() }}>Timestamp</Button>
					</div>
					<div style={{ display: "flex" }}>
						<Button bsStyle="danger" onClick={this.props.onClose}><FontAwesomeIcon icon="ban" /> Cancel</Button>
					</div>
				</div>
			</div>
		</Modal>

		return component;
	}
}

import request from "superagent";
import _ from "lodash";
// import moment from "moment-timezone";
import { Auth } from 'aws-amplify';

import env from "../constants/env";
import * as userActions from "./user";
import state from "../state/state";
import { refreshCredentials } from "../utils/securityUtil";
// import { getOrgId } from "./orgsActions";
import { getLs, setLs } from "../utils/utils";
import { updateMemberInDb, updateMemberInfoInDb } from "./orgsActions";

export function getIdentityId() {
	return state.select(["user", "identityId"]).get();
}

export function getUsername() {
	return state.select(["user", "username"]).get();
}

export function getTimezone() {
	return state.get(["user", "timeZone"]);

	// const org = state.get(["selectedOrg"]);
	// const tz = _.get(org, "member.timeZone", moment.tz.guess());
	// return tz ? tz : moment.tz.guess();
}

export function getInjectedSecurityContext() {
	return btoa(JSON.stringify(state.get(["user", "_injected_security_credentials"])));
}

export function getJwtToken(credentials = window.credentials) {
	return state.get(["user", "jwt"]);
}

export function isOrganizationAdministrator() {
	const org = state.get(["selectedOrg"]);
	return _.get(org, "member.administrator", false) === 1 ? true : false;
}

export function isOrganizationAuthor() {
	const org = state.get(["selectedOrg"]);
	return _.get(org, "member.author", false) === 1 ? true : false;
}

export function isOrganizationUser() {
	if (isOrganizationAdministrator() || isOrganizationAuthor()) {
		return false;
	} else {
		return true;
	}
}

export function getDisplayName() {
	return state.get(["user", "displayName"]);

	// const org = state.get(["selectedOrg"]);
	// return _.get(org, "member.displayName", "?");
}

export function getEmail() {
	return state.get(["user", "email"]);
}

export function getEmailChatNotify() {
	return state.get(["user", "emailChatNotify"]);
}

export function getPhoneNumber() {
	return state.get(["user", "phone_number"]);
}

export function getPhoneNumChatNotify() {
	return state.get(["user", "phoneNumChatNotify"]);
}

export function getProfilePicture() {
	return state.get(["user", "profilePicture"]);
}

/**
 * Determine the current users role
 * @return<int> 0 - normal user, 1 - author, 2 -administrator
 */
export function getUserRole() {
	let role = 0;

	if (isOrganizationAdministrator()) {
		role = 2;
	} else if (isOrganizationAuthor()) {
		role = 1;
	}

	return role;
}

export function getGroupMemberships() {
	return state.get(["selectedOrg", "member", "groups"]);
}

export function isSelf(member) {
	return state.get(["selectedOrg", "member", "id"]) === member.id;
}

export const SUBSCRIPTION_PLAN_BASIC = "basic-plan";
export function getSubscriptionPlan() {
	return state.get(["user", "subscriptionPlan"]);
}

export async function updateAttribute(cognitoUser, name, value) {
	try {

		const params = {
			[name]: _.isString(value) ? value : JSON.stringify(value)
		};

		let user = await Auth.currentAuthenticatedUser();
		if (user) {
			await Auth.updateUserAttributes(user, params);
		}
	} catch (e) {
		console.warn("Failed to update user attribute", name, value, e);
		// alert("Failed to set user attribute " + e);
	}
}

function setMemberProp(prop, value) {
	state.set(["selectedOrg", "member", prop], value);
}

export function setDisplayName(displayName) {
	setMemberProp("displayName", displayName);
	state.set(["user", "displayName"], displayName);
}

export function setTimezone(timeZone) {
	setMemberProp("timeZone", timeZone);
	state.set(["user", "timeZone"], timeZone);
}

export function saveChangesForMemberAdmin(member, memberInfo) {
	const memberId = member.id;
	const memberUserSubId = member.userSubId;

	// Need to call api
	return new Promise((resolve, reject) => {
		refreshCredentials().then(async () => {
			try {
				await updateMemberInfoInDb(memberId, memberUserSubId, memberInfo);
				resolve();
			} catch (err) {
				alert("There was an error saving your user profile.")
				reject(err)
			}
		});
	});
}

export function saveChanges(displayName, timeZone, email, emailChatNotify, phoneNumber, phoneNumChatNotify, profilePicture) {
	// Save all attributes that are editable
	const memberId = state.get(["selectedOrg", "member", "id"]);

	// profilePicture = profilePicture.replace("http://", "https://");

	// Need to call api
	return new Promise((resolve, reject) => {
		refreshCredentials().then(async () => {
			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editCognitoUser(state.get(["user", "username"]))}`)
				.set("Authorization", userActions.getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify({
					displayName: displayName,
					timeZone: timeZone,
					email: email,
					emailChatNotify: emailChatNotify ? "true" : "false",
					phoneNumber: phoneNumber,
					phoneNumChatNotify: phoneNumChatNotify ? "true" : "false",
					profilePicture: profilePicture
				}))
				.then(async (res) => {
					// Update the database
					let displayNameChanged = state.get(["user", "displayName"]) !== displayName;
					let profilePictureChanged = state.get(["user", "profilePicture"]) !== profilePicture;

					await updateMemberInDb(memberId, displayName, timeZone, profilePicture, displayNameChanged, profilePictureChanged);

					state.set(["user", "displayName"], displayName);
					setMemberProp("displayName", displayName);

					state.set(["user", "profilePicture"], profilePicture);
					setMemberProp("profilePicture", timeZone);

					state.set(["user", "timeZone"], timeZone);
					setMemberProp("timeZone", timeZone);

					state.set(["user", "email"], email);
					setMemberProp("email", timeZone);

					state.set(["user", "emailChatNotify"], emailChatNotify);
					state.set(["user", "phone_number"], phoneNumber);
					state.set(["user", "phoneNumChatNotify"], phoneNumChatNotify);

					alert("Your user profile has been successfully saved.")

					resolve(res);
				}).catch(err => {
					alert("There was an error saving your user profile.")
					reject(err);
				});
		});

		// return new Promise((resolve, reject) => {
		// 	refreshCredentials().then(() => {
		// 		debugger;
		// 		const orgMember = state.get(["selectedOrg", "member"]);
		// 		// Ok accept it
		// 		request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editOrgMember(getOrgId())}`)
		// 			.set("Authorization", userActions.getJwtToken())
		// 			.set("Content-Type", "application/json")
		// 			.send(JSON.stringify(orgMember))
		// 			.then(res => {
		// 				resolve(res);
		// 			}).catch(err => {
		// 				reject(err);
		// 			});

		// 	});
	});
}

export function saveProfilePicture(profilePicture) {
	// Save all attributes that are editable
	// profilePicture = profilePicture.replace("http://", "https://");

	// Need to call api
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			console.log("$#$#$# CALL: ", `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editCognitoUser(state.get(["user", "username"]))}`);

			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.editCognitoUser(state.get(["user", "username"]))}`)
				.set("Authorization", userActions.getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify({
					profilePicture: profilePicture
				}))
				.then(res => {
					state.set(["user", "profilePicture"], profilePicture);

					resolve(res);
				}).catch(err => {
					alert("There was an error saving your user profile.")
					reject(err);
				});
		});
	});
}

export function getLastOrg() {
	let result = getLs("_ambifi_last_org");
	if (result) {
		if (_.isString(result)) {
			try {
				result = JSON.parse(result);
			} catch (e) {
				console.warn("Failed to parse last org", e);
			}
		}
	}
	return result;
}

export function setLastOrg(org) {

	let value = org;
	if (!_.isString(org)) {
		value = JSON.stringify(value);
	}

	setLs("_ambifi_last_org", value);
}

export function setLastActivity(activity, forceSave = false) {
	if (forceSave || (_.isObject && activity.hasOwnProperty("type"))) {
		setLs("_ambifi_last_activity", JSON.stringify(activity))
	}
}

export function getLastActivity() {
	let result = getLs("_ambifi_last_activity");
	if (_.isString(result)) {
		try {
			result = JSON.parse(result);
			return result;
		} catch (e) {
			console.warn("Failed to parse last org", e);
			return null;
		}
	}
}

export function getAllOrgs() {
	let result = [];

	const _allOrgs = getLs("_ambifi_all_orgs");
	if (_allOrgs) {
		if (_.isString(_allOrgs)) {
			try {
				result = JSON.parse(_allOrgs);
			} catch (e) {
				console.warn("Failed to parse orgs", e);
			}
		} else if (_.isArray(_allOrgs)) {
			result = _allOrgs;
		}

	}
	return result;
}
import AWS from "aws-sdk";
import { CookieStorage } from "amazon-cognito-identity-js";
import request from "superagent";
import setCookie from "set-cookie";
import _ from "lodash";
import moment from "moment";
import { Auth } from 'aws-amplify';
import fetchIntercept from 'fetch-intercept';

import env from "../constants/env";
import state from "../state/state";
import { isOrganizationAdministrator, isOrganizationAuthor, getJwtToken } from "../actions/user";
import { postMessage, sendTokenUpdatedMessage } from "../actions/mobileAppCommunicationActions";
import { forceLogin } from "../actions/actions";
import browserHistory from "../history";
import { showError } from "../actions/alertActions";
import { getQueryStringParam, isMobile } from "./utils";
import { handlePostAuthenticateV2, handleRemotePlayerAuth } from "../actions/portalActions";

import VoxeetSdk from "@voxeet/voxeet-web-sdk";

import { Hub } from "aws-amplify";
// window.LOG_LEVEL = 'DEBUG';
// https://login-haralds-test-org.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=4m07lh8puago45hb1poi030ktv&redirect_uri=https://haralds-test-org.ambifi.com:3000

// This really sucks but we have a flaw in the amplify library where token requests get duplicated...
let lastTokenCall = 0;



let amplifyConfigured = false;
export function configureAmplify(config) {
	async function onAuthEvent(payload) {
		if (payload && payload.event) {
			switch (payload.event) {
				case "signIn": {
					if (!state.get(["appState", "enterprise"])) {
						// console.log("onAuthEvent (signIn)", payload);
						const authParams = getQueryStringParam("auth");
						if (authParams && true) {
							let session = await Auth.currentSession();
							handleRemotePlayerAuth(session);
						} else {
							await handlePostAuthenticateV2(payload.data.signInUserSession, payload.data, state, payload.data.username);
						}
					}

					break;
				}
				default: {
					console.debug("onAuthEvent (not signIn)", payload);
				}
			}
		}
	}
	if (!amplifyConfigured) {
		fetchIntercept.register({
			request: function (url, config) {
				// Modify the url or config here
				// console.log("INTERCEPTED FETCH", url, config);
				if (url && url.endsWith && url.endsWith("oauth2/token")) {
					const rightNow = new Date().getTime();
					if (lastTokenCall < rightNow - 1000) {
						// console.log("INTERCEPTED oauth2/token FETCH (continue)", url, config);
						// Longer than one second ago :)
						lastTokenCall = rightNow;
						return [url, config];
					} else {
						return null;
					}
				} else {
					return [url, config];
				}
			},

			requestError: function (error) {
				// Called when an error occured during another 'request' interceptor call
				return Promise.reject(error);
			},

			response: function (response) {
				// Modify the reponse object
				if (response.url === "https://cognito-idp.us-east-1.amazonaws.com/") {
					const reader = response.body.getReader();
					const stream = new ReadableStream({
						start(controller) {
							// The following function handles each data chunk
							function push() {
								// "done" is a Boolean and value a "Uint8Array"
								reader.read().then(({ done, value }) => {


									const strValue = new TextDecoder("utf-8").decode(value);
									console.log("GOT RESPONSE", strValue);
									let val = value;
									try {
										const objValue = JSON.parse(strValue);
										if (_.isPlainObject(objValue)) {
											// Inspect and see if we can find the email_verfied prop
											const newUserAttributes = [];
											if (objValue.UserAttributes && _.isArray(objValue.UserAttributes)) {
												objValue.UserAttributes.forEach(attr => {
													if (attr.Name === "email_verified") {
														let val = false;
														if (attr.Value === "True" || attr.Value === "true" || attr.Value === true) {
															val = true;
														}
														newUserAttributes.push({
															Name: attr.Name,
															Value: val
														});
													} else {
														newUserAttributes.push(attr);
													}
												});
											}
											objValue.UserAttributes = newUserAttributes;
											val = new TextEncoder("utf-8").encode(JSON.stringify(objValue));
										}
									} catch (e) {

									}


									// Is there no more data to read?
									if (done) {
										// Tell the browser that we have finished sending data
										controller.close();
										return;
									}

									// Get the data and send it to the browser via the controller
									controller.enqueue(val);
									push();
								});
							};

							push();
						}
					});

					return new Response(stream, { headers: { "Content-Type": "application/x-amz-json-1.1" } });
				} else {
					return response;
				}

			},

			responseError: function (error) {
				// Handle an fetch error
				return Promise.reject(error);
			}
		});
		Hub.listen("auth", (data) => {
			const { payload } = data;
			onAuthEvent(payload);
			// console.log("A new auth event has happened: ", payload);
		});
		amplifyConfigured = true;
	}
	// if (amplifyConfigured) {
	// 	return;
	// }

	// Amplify.configure({
	// 	Auth: {

	// 		// REQUIRED - Amazon Cognito Region
	// 		region: "us-east-1",

	// 		// OPTIONAL - Amazon Cognito User Pool ID
	// 		userPoolId: "us-east-1_CiDLIeTjb",

	// 		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	// 		userPoolWebClientId: "4m07lh8puago45hb1poi030ktv",

	// 		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	// 		mandatorySignIn: true,

	// 		// OPTIONAL - Configuration for cookie storage
	// 		// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
	// 		// cookieStorage: {
	// 		// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
	// 		// 	domain: ".ambifi.com",
	// 		// 	// OPTIONAL - Cookie path
	// 		// 	path: "/",
	// 		// 	// OPTIONAL - Cookie expiration in days
	// 		// 	expires: 365,
	// 		// 	// OPTIONAL - Cookie secure flag
	// 		// 	// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
	// 		// 	secure: true
	// 		// },

	// 		// // OPTIONAL - Hosted UI configuration
	// 		oauth: {
	// 			domain: "login-haralds-test-org.auth.us-east-1.amazoncognito.com", ///oauth2/authorize 
	// 			scope: ["email", "openid", "aws.cognito.signin.user.admin"],
	// 			redirectSignIn: "https://haralds-test-org.ambifi.com:3000",
	// 			redirectSignOut: "https://haralds-test-org.ambifi.com:3000",
	// 			responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
	// 		}
	// 	}
	// });
	// console.log("Amplify configured!");
	// amplifyConfigured = true;
}



AWS.config.update({ region: env.aws.defaultRegion });

// const poolData = {
// 	UserPoolId: env.cognito.userPoolId, // Your user pool id here
// 	ClientId: env.cognito.clientId, // Your client id here
// };

// const userPool = new CognitoUserPool(poolData);

const REFRESH_INTERVAL_MINUTES = 5;
let intervalId = null;

let lastRefresh = null;
let lastRefreshFailed = false;

let cookieStorage = null;

// This may be something for later on, doesn't quite work as I've been testing it ... claims it's missing an ID token in local storage
export function getCookieStorage() {
	if (!cookieStorage) {
		cookieStorage = new CookieStorage({ domain: "localhost", secure: false });
	}

	return cookieStorage;
}

export function handlePageInit(location, requiresAdminPrivilege = false, requiresAuthorPrivilege = false) {
	const user = state.get(["user"]);
	if (!user.loggedIn) {
		forceLogin(browserHistory, location.pathname);
	} else if ((!isOrganizationAdministrator() && requiresAdminPrivilege) || (!isOrganizationAuthor() && !isOrganizationAdministrator() && requiresAuthorPrivilege)) {
		browserHistory.push("/home");
	}
}

export function getSecurityContext() {
	return {
		cognitoUserPool: {
			UserPoolId: env.cognito.userPoolId, // Your user pool id here
			ClientId: env.cognito.clientId, // Your client id here
		},
		region: env.aws.defaultRegion,
		federationTokenEndpoint: `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.auth}`
	};
}

export function initializeSecurityContextV2(cognitoUser, federationTokenResponse, loginResult) {
	console.log("V2 Initialize Security", federationTokenResponse);
	return new Promise((resolve, reject) => {
		cognitoUser.getSession((err, session) => {
			if (err) {
				reject(err);
				return;
			} else {
				const creds = federationTokenResponse;
				// Hate it but no matter what I tried the browser wouldn't accept the cookies from
				// the set-cookie headers, follow up requests to Cloud Front would fail and we see that
				// the cookies aren't included in the request, expected the browser to just deal with this (Chrome)
				if (creds.cookies && !_.isEmpty(creds.cookies)) {
					const domain = ".ambifi.com";
					const expiresMoment = moment().add(7, "days");
					const expires = expiresMoment.toDate();
					const cookies = [];
					_.forEach(creds.cookies, (value, key) => {
						setCookie(key, value, {
							domain,
							path: "/",
							expires
						});

						state.set(["security", "cookies", key], value);
						cookies.push({
							name: key,
							value,
							domain,
							origin: window.location.href,
							path: "/",
							version: "1",
							expiration: expiresMoment.format("YYYY-MM-DDTHH:mm:ss.sssZ")
						});

					});
					postMessage({
						method: "cookiesSetRequest",
						cookies
					});
				}
				lastRefresh = Date.now();
				window.awsTempCredentials = AWS.config.credentials = new AWS.Credentials(creds.body.accessKeyId, creds.body.secretAccessKey, creds.body.sessionToken);
				const credentials = new AWS.CognitoIdentityCredentials({
					IdentityPoolId: env.cognito.identityPoolId, // your identity pool id here
					Logins: {
						// Change the key below according to the specific region your user pool is in.
						[env.cognito.loginContext]: loginResult.idToken.jwtToken,
					},
					region: env.aws.defaultRegion,
				});
				window.credentials = credentials;
				postMessage({
					method: "setAwsTempCredentials",
					creds: {
						accessKeyId: creds.body.accessKeyId,
						secretAccessKey: creds.body.secretAccessKey,
						sessionToken: creds.body.sessionToken
					}
				});
				state.set(["user", "_injected_security_credentials"], {
					ak: creds.body.accessKeyId,
					sak: creds.body.secretAccessKey,
					st: creds.body.sessionToken
				});


				setCredentials(session, cognitoUser, resolve, reject, true);

				resolve(federationTokenResponse);


			}
		});
	});
}

const doFetchFederationToken = (jwt = getJwtToken()) => {
	return new Promise((resolve, reject) => {
		request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.auth}`)
			.set("Authorization", jwt)
			.set("Accept", "application/json")
			.then(res => {
				const creds = res.body;
				// Hate it but no matter what I tried the browser wouldn't accept the cookies from
				// the set-cookie headers, follow up requests to Cloud Front would fail and we see that
				// the cookies aren't included in the request, expected the browser to just deal with this (Chrome)
				if (creds.cookies && !_.isEmpty(creds.cookies)) {
					const domain = ".ambifi.com";
					const expiresMoment = moment().add(7, "days");
					const expires = expiresMoment.toDate();
					const cookies = [];
					_.forEach(creds.cookies, (value, key) => {
						setCookie(key, value, {
							domain,
							path: "/",
							expires
						});

						state.set(["security", "cookies", key], value);
						cookies.push({
							name: key,
							value,
							domain,
							origin: window.location.href,
							path: "/",
							version: "1",
							expiration: expiresMoment.format("YYYY-MM-DDTHH:mm:ss.sssZ")
						});

					});
					postMessage({
						method: "cookiesSetRequest",
						cookies
					});
				}
				lastRefresh = Date.now();
				window.awsTempCredentials = AWS.config.credentials = new AWS.Credentials(creds.body.accessKeyId, creds.body.secretAccessKey, creds.body.sessionToken);


				postMessage({
					method: "setAwsTempCredentials",
					creds: {
						accessKeyId: creds.body.accessKeyId,
						secretAccessKey: creds.body.secretAccessKey,
						sessionToken: creds.body.sessionToken
					}
				});
				state.set(["user", "_injected_security_credentials"], {
					ak: creds.body.accessKeyId,
					sak: creds.body.secretAccessKey,
					st: creds.body.sessionToken
				});
				resolve(creds.body);
			}).catch(err => {
				reject(err);
			});
	});
};

export function urlToSecureParameterObject(url) {
	return {
		url,
		withCredentials: true
	};
}

export function getSecureCookiesAsString() {
	const cookies = [];
	const cookiesMap = state.get(["security", "cookies"]);
	_.keys(cookiesMap).forEach(key => {
		cookies.push(`${key}=${cookiesMap[key]}`);
	});
	return cookies.join("; ");
}
function outOfSync() {
	const curTime = Date.now();
	return !lastRefresh || (curTime - lastRefresh) > 1000 * 60 * REFRESH_INTERVAL_MINUTES || lastRefreshFailed;
}


export function refreshCredentials(force = false) {
	console.log("REFRESH CREDENTIALS");
	return new Promise(async (resolve, reject) => {
		const _outOfSync = outOfSync();
		if (_outOfSync || force) {
			console.log("REFRESH REQUIRED");
			try {
				const session = await Auth.currentSession();
				const cognitoUser = await Auth.currentAuthenticatedUser();
				setCredentials(session, cognitoUser, resolve, reject);
			} catch (err) {
				console.error(err);
				reject(err);
			}
			// if (cognitoUser !== null) { // eslint-disable-line
			// 	try {
			// 		const needsRefresh = (window.credentials && window.credentials.needsRefresh());
			// 		cognitoUser.getSession((err, session) => {
			// 			if (err) {
			// 				console.error(err);
			// 				reject(err);
			// 			} else if (needsRefresh) {
			// 				const refresh_token = session.getRefreshToken();
			// 				cognitoUser.refreshSession(refresh_token, (err, session) => {
			// 					if (err) {
			// 						reject(err);
			// 						return;
			// 					} else {
			// 						setCredentials(session, cognitoUser, resolve, reject);
			// 					}
			// 				});
			// 			} else {
			// 				setCredentials(session, cognitoUser, resolve, reject);
			// 			}
			// 		});
			// 	} catch (err) {
			// 		console.error(err);
			// 		reject(err);
			// 	}
			// } else {
			// 	//... What now???
			// 	reject(new Error("Unable to determine current session, please make sure to log in again."));
			// }
		} else {
			console.log("REFRESH NOT REQUIRED");
			let cognitoUser = null;
			try {
				cognitoUser = await Auth.currentAuthenticatedUser();
			} catch (e) {
				console.warn("Unable to fetch current cognito user", e);
			}

			resolve({ cognitoUser, credentials: window.credentials, awsTempCredentials: window.awsTempCredentials, awsTempCredentialsPojo: getAwsTempCredentialsPojo(window.awsTempCredentials) });
		}
	}).catch(err => {
		console.error(err);

		if (!isMobile() && err === "No current user") {
			showError("Credentials Expired", "Sorry for the inconvenience but your security credentials have expired. No worries, your data is safe but we will require you to refresh the browser. If this error keeps showing up please contact us at support@ambifi.com. Reason:" + err);
			return Promise.resolve();
		} else if (!isMobile()) {
			showError("Credentials Refresh Error", "Sorry for the inconvenience but refresh of your security credentials has failed. No worries, your data is safe but we will require you to log in again. If this error keeps showing up please contact us at support@ambifi.com. Reason:" + err);

			err.handled = true;
			return Promise.reject(err);
		}
	});
}

function setCredentials(session, cognitoUser, resolve, reject, skipFederationToken = false) {
	const jwtToken = session?.idToken?.jwtToken;
	console.log("SET CREDENTIALS", session);
	// const credentials = new AWS.CognitoIdentityCredentials({
	// 	IdentityPoolId: env.cognito.identityPoolId, // your identity pool id here
	// 	Logins: {
	// 		// Change the key below according to the specific region your user pool is in.
	// 		[env.cognito.loginContext]: jwtToken,
	// 	},
	// 	region: env.aws.defaultRegion,
	// });
	sendTokenUpdatedMessage({ jwt: jwtToken });
	lastRefreshFailed = false;
	if (!skipFederationToken) {
		doFetchFederationToken(jwtToken).then(() => {
			resolve({ cognitoUser, awsTempCredentials: window.awsTempCredentials, awsTempCredentialsPojo: getAwsTempCredentialsPojo(window.awsTempCredentials) });
		}).catch(err => {
			reject(err);
		});
	} else {
		resolve({ cognitoUser, awsTempCredentials: window.awsTempCredentials, awsTempCredentialsPojo: getAwsTempCredentialsPojo(window.awsTempCredentials) });
	}
	// window.credentials = credentials;
	// // console.log("JWT Token", jwtToken);

	// credentials.refresh(() => {
	// 	credentials.get(async (err) => {
	// 		if (!err) {
	// 			lastRefreshFailed = false;
	// 			if (!skipFederationToken) {
	// 				await doFetchFederationToken();
	// 			}

	// 		} else {
	// 			reject(err);
	// 		}
	// 	});
	// });
}

export function captureInviteCode() {
	const code = getQueryStringParam("code");

	// If code returned is a guid then coming from Azure AD and ignore
	// Should handle this better
	if (code && code.length === 36) {
		return;
	}

	if (code && state.get(["inviteMember", "inviteCode"]) === null) {
		console.debug("Got an invite code", code);
		// Ok so there is a code
		state.set(["inviteMember", "inviteCode"], code);
	}
}

/**
 * Stub, currently we only allow admins to edit activities but this will be extened to consult an actual ACL
 * @param {*} activityId 
 */
export function allowedToEditActivity(activityId = null) { //eslint-disable-line
	return isOrganizationAdministrator() || isOrganizationAuthor();
}

export function logout() {

	const user = state.get(["user"]);

	const { rememberMe, savePassword, sharedDevice } = user;

	const newUser = {
		sub: "",
		name: "",
		username: "",
		email: "",
		password: "",
		code: "",
		identityId: "",
		loggedIn: false,
		historyUsers: [],
		entryPoint: "",
		org: null,
		savePassword: !!savePassword,
		rememberMe: !!rememberMe,
		sharedDevice: !!sharedDevice
	};

	state.set(["user"], newUser);
	delete window.credentials;
	cancelCredentialRefreshInterval();
}

export function startCredentialRefreshInterval() {
	if (!intervalId) {
		intervalId = window.setInterval(() => {
			refreshCredentials();
		}, 1000 * 60 * REFRESH_INTERVAL_MINUTES);
	}
}

export function cancelCredentialRefreshInterval() {
	if (intervalId) {
		window.clearInterval(intervalId);
	}
}

function getAwsTempCredentialsPojo(awsTempCredentials) {

	let { secretAccessKey, accessKeyId, sessionToken } = awsTempCredentials;

	return {
		secretAccessKey,
		accessKeyId,
		sessionToken
	}
}

export async function getJwtTokenStream(jwt, userId) {
	return new Promise(async (resolve, reject) => {
		try {
			let oauth2Jwt = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.jwtOauth2Stream}?userId=${userId}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			console.log("!*!*!* jwt " + JSON.stringify(oauth2Jwt));

			resolve(oauth2Jwt.body.access_token);
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}


export async function getJwtTokenDolby(jwt) {
	return new Promise(async (resolve, reject) => {
		try {
			const oauth2Jwt = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.jwtOauth2Dolby}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			resolve(oauth2Jwt.body.access_token);
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}

export async function initJwtTokenDolby(jwt) {
	return new Promise(async (resolve, reject) => {
		try {
			const oauth2Jwt = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.jwtOauth2Dolby}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			state.set(["appState", "videoCallOauthToken"], oauth2Jwt);

			console.log("!*!*!* jwt " + JSON.stringify(oauth2Jwt));
			VoxeetSdk.initializeToken(oauth2Jwt.body.access_token, async () => {
				// This callback is called when the token needs to be refreshed.

				try {
					const oauth2JwtRefresh = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.jwtOauth2Dolby}`)
						.set("Authorization", jwt)
						.set({ "Accept": "application/json" });

					state.set(["appState", "videoCallOauthRefreshToken"], oauth2JwtRefresh);

					return oauth2JwtRefresh.body.access_token;
				} catch (err) {
					// showError("Error", err.message);
				}

				// Call the server to get a new access token
				// return await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.jwtOauth2Dolby}`)
				// 	.set("Authorization", jwt)
				// 	.set({ "Accept": "application/json" }).body.access_token;
			});

			resolve();
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}

// import markdownLinkExtractor from "markdown-link-extractor";
import { marked } from "marked";
import qs from "qs";
import _ from "lodash";

import state from "../state/state";

import { showError } from "../actions/alertActions";

import AppConstants from "../constants/appConstants";

// JJB Nasty...after a while this gets a stack exceeded error if look for images
// I don't believe we care about images for these links
const markdownLinkExtractor = (markdown, extended = false) => {
	const links = [];

	// Taken from https://github.com/markedjs/marked/issues/1279
	// removed ? after first ! so that it only matches images.
	// const image = /^!\[((?:\[[^\[\]]*\]|\\[\[\]]?|`[^`]*`|[^\[\]\\])*?)\]\(\s*(<(?:\\[<>]?|[^\s<>\\])*>|(?:\\[()]?|\([^\s\x00-\x1f()\\]*\)|[^\s\x00-\x1f()\\])*?(?:\s+=(?:[\w%]+)?x(?:[\w%]+)?)?)(?:\s+("(?:\\"?|[^"\\])*"|'(?:\\'?|[^'\\])*'|\((?:\\\)?|[^)\\])*\)))?\s*\)$/;

	// const tokenizer = {
	// 	link(src) {
	// 		const match = src.match(image);
	// 		if (match) {
	// 			return {
	// 				type: 'image',
	// 				raw: src,
	// 				href: match[2] ? match[2].replace(/ =\d*%?x\d*%?$/, "") : null,
	// 				title: match[3] ? match[3] : null,
	// 				text: match[1] ? match[1] : null,
	// 			};
	// 		}
	// 		return false;
	// 	}
	// };

	const walkTokens = (token) => {
		if (token.type === 'link'/* || token.type === 'image'*/) {
			links.push(extended ? token : token.href);
		}
	};

	marked.setOptions({
		mangle: false, // don't escape autolinked email address with HTML character references.
	});
	marked.use({ /*tokenizer, */walkTokens });
	marked.parse(markdown);

	return links;
};

const markdownScanner = (text) => {
	// The following was a workaround becuase getting stack exceeded error
	// Fixed root of the problem above which was images

	try {
		const urls = markdownLinkExtractor(text);

		const results = [];
		if (urls && _.isArray(urls)) {
			urls.forEach(url => {
				results.push(linkTextScanner(url));
			});
		}

		return results;

	} catch (err) {
		showError("Save Activity", "There was an issue saving the activity. Sorry for the inconvenience, but you will need to refresh the app before you will be able to save. If you did a lot of work since your last save, you will have the opportunity to restore any lost changes.");
		return [];
	}
};

const linkTextScanner = (url) => {


	console.log("Checking URL", url);
	let isNavigationUrl = false;
	if (url && _.isString(url) && /^\w+:[^/]#?[^#?]+(?:#[^#?]+)?(?:\?(?:\w+=\w+(?:&)?)+)?$/.test(url)) {

		console.log("Passed regex", url);

		const trimmedUrl = url.trim();

		state.get(["linkActionTypes"]).forEach(linkActionType => {
			console.log("Test for nav URL", linkActionType.nav, linkActionType.action);
			if ((linkActionType.nav || linkActionType.action === "external") && trimmedUrl.startsWith(`${linkActionType.action}:`)) {
				isNavigationUrl = true;
			}
		});
	}
	if (!isNavigationUrl) {
		return {
			type: "plainUrl",
			url
		};
	} else {

		const urlParts = url.split(":");
		const actionType = urlParts[0];
		const remainingUrlParts = _.tail(urlParts).join(":");
		// Test if have a checklist to navigate to. If do, set the checklistIndex.
		let pathParts = [remainingUrlParts];
		let queryString = {};
		if (remainingUrlParts.indexOf("?") !== -1) {
			pathParts = remainingUrlParts.split("?");
			// console.log("PARSING", pathParts[1])
			queryString = qs.parse(pathParts[1]);
			// console.log("PARSING result", queryString);
		}
		let path = pathParts[0];
		let targetId = null;
		let targetActivityId = path;


		if (path.indexOf("#") !== -1) {
			const _path = path.split("#");
			targetId = _path[1];
			targetActivityId = _path[0];
		}

		let externalUrl = null;
		if (actionType === "external") {
			externalUrl = targetActivityId;
			targetActivityId = null;
		}

		return {
			actionType,
			externalUrl,
			targetActivityId,
			targetId,
			path,
			queryString
		};
	}

};

export function validateExternalDependencies(node, collapseErrors = false) {
	const result = [];
	const dependencies = [];
	scanForDependencies(node, dependencies);
	if (dependencies && !_.isEmpty(dependencies)) {
		const checklistIdToActivityIdMap = {};
		state.get("activities").forEach(activity => {
			const { checklistId, id } = activity;
			if (!_.isEmpty(checklistId) && !_.isEmpty(id)) {
				checklistIdToActivityIdMap[checklistId] = id;
			}
		});
		const collapsedErrors = [];
		dependencies.forEach(dependency => {
			const { targetActivityId } = dependency;
			if (!_.isEmpty(targetActivityId)) {

				if (!checklistIdToActivityIdMap[targetActivityId]) {
					if (collapseErrors) {
						collapsedErrors.push(targetActivityId);
					} else {
						result.push({
							targetActivityId,
							validationError: true,
							validationErrorType: "MISSING_ACTIVITY_ID",
							validationErrorSeverity: 1, // 0 - minor, 1 - normal, 2 - severe
							message: `Unable to link to missing activity with ID: ${targetActivityId}`
						});
					}

				}

			}
		});

		if (collapsedErrors && !_.isEmpty(collapsedErrors)) {
			result.push({
				validationError: true,
				validationErrorType: "MISSING_ACTIVITY_ID",
				validationErrorSeverity: 1, // 0 - minor, 1 - normal, 2 - severe
				message: `Unable to link to missing activities with IDs: ${collapsedErrors.join(", ")}`
			});
		}
	}
	return result;
}

export function scanForExternalDependencies(node, guidOnly = false) {
	const result = [];
	const dependencies = [];
	scanForDependencies(node, dependencies);
	// console.log("VALIDATED LINKS", dependencies);
	if (dependencies && !_.isEmpty(dependencies)) {
		const checklistIdToActivityIdMap = {};
		state.get("activities").forEach(activity => {
			const { checklistId, id } = activity;
			if (!_.isEmpty(checklistId) && !_.isEmpty(id)) {
				checklistIdToActivityIdMap[checklistId] = id;
			}
		});
		dependencies.forEach(dependency => {
			const { targetActivityId } = dependency;
			if (!_.isEmpty(targetActivityId)) {

				if (checklistIdToActivityIdMap[targetActivityId]) {
					if (guidOnly) {
						result.push(checklistIdToActivityIdMap[targetActivityId]);
					} else {
						result.push(_.assign({
							targetActivityGuid: checklistIdToActivityIdMap[targetActivityId]
						}, dependency));
					}
				} else {
					console.warn(`The following checklist id doesn't map to an activity: ${targetActivityId}`);
				}

			}
		});
	}
	return result;
}

/**
 * Given an activity will scan for and return all dependencies, a dependency is one to another activity
 * @param {*} activity 
 */
export function scanForDependencies(node, dependencies = []) {
	const { type } = node;
	switch (type) {
		case "checklist": {
			const _node = normalizeActivity(node);
			_node.children.forEach(child => {
				scanForDependencies(child, dependencies);
			});
			if (_node.relatedItems && _.isArray(_node.relatedItems)) {
				_node.relatedItems.forEach(relatedItem => {
					// console.log("Scan related item", relatedItem);
					const _dependencies = scanItemForDependencies(relatedItem);
					if (!_.isEmpty(_dependencies)) {
						dependencies.push(..._dependencies);
					}
				});
			}
			break;
		}
		case "list":
		case "section": {
			node.children.forEach(child => {
				scanForDependencies(child, dependencies);
			});
			break;
		}
		case "item":
		default: {
			if (type === "item" || (type && type.startsWith("item"))) {
				const _dependencies = scanItemForDependencies(node);
				if (!_.isEmpty(_dependencies)) {
					dependencies.push(..._dependencies);
				}
				const { label1, label2 } = node;
				const label1Links = markdownScanner(label1);
				if (label1Links && !_.isEmpty(label1Links)) {
					dependencies.push(...label1Links);
				}
				const label2Links = markdownScanner(label2);
				if (label2Links && !_.isEmpty(label2Links)) {
					dependencies.push(...label2Links);
				}
			} else {
				console.warn(`Unknown entity type ${type}`);
			}
		}
	}
}

function scanItemForDependencies(item) {
	const results = [];
	if (item.linkId && !_.isEmpty(item.linkId)) {
		results.push(linkTextScanner(`${item.linkActionType}:${item.linkId}`));
	} else if (item.linkUrl && !_.isEmpty(item.linkUrl)) {
		results.push(linkTextScanner(item.linkUrl));
	}

	switch (item.type) {
		case "itemPicker": {
			scanPickerItemForDependencies(item, results);
			break;
		}
		case "itemYesNo": {
			scnaYesNoItemForDependencies(item, results);
			break;
		}
		default: {
			// Nothing
		}
	}

	return results;
}

function scanPickerItemForDependencies(item, dependencies = []) {
	// console.log("SCAN DEPS ITEM PICKER");
	if (item.pickerItems && !_.isEmpty(item.pickerItems)) {
		// See if there are links by virtue of "link when select"

		if (item.pickerLinkOnSelect) {
			item.pickerItems.forEach(pickerItem => {
				dependencies.push(linkTextScanner(`${item.pickerLinkActionType}:${pickerItem.value}`));
			});
		}
		item.pickerItems.forEach(pickerItem => {
			// console.log("SCAN DEPS ITEM PICKER, CHECKING", pickerItem.properties, _.isString(pickerItem.properties));
			if (pickerItem.properties && _.isString(pickerItem.properties) && pickerItem.properties !== "") {
				try {
					const { linking } = JSON.parse(pickerItem.properties);
					// console.log("SCAN DEPS ITEM PICKER, ADDING", linking);
					dependencies.push(linkTextScanner(linking));
				} catch (e) {
					console.warn("Unable to extract linking information from properties", e);
				}
			}
		});
	}
}

function scnaYesNoItemForDependencies(item, dependencies = []) {
	if (item.yesNoLinkOnSelect) {
		const { yesNoLinkActionType, yesLinkActionType, noLinkActionType, naLinkActionType } = item;
		// Yes
		if (!_.isEmpty(item.yesNoYesLinkId)) {
			let action = !_.isEmpty(yesLinkActionType) && yesLinkActionType === "inherit" ? yesNoLinkActionType : yesLinkActionType;
			let value = item.yesNoYesLinkId;

			if (action && value) {
				dependencies.push(linkTextScanner(`${action}:${value}`));
			}
		}
		// No
		if (!_.isEmpty(item.yesNoNoLinkId)) {
			let action = !_.isEmpty(noLinkActionType) && noLinkActionType === "inherit" ? yesNoLinkActionType : noLinkActionType;
			let value = item.yesNoNoLinkId;

			if (action && value) {
				dependencies.push(linkTextScanner(`${action}:${value}`));
			}
		}
		// Na
		if (!_.isEmpty(item.yesNoNaLinkId)) {
			let action = !_.isEmpty(naLinkActionType) && naLinkActionType === "inherit" ? yesNoLinkActionType : naLinkActionType;
			let value = item.yesNoNaLinkId;

			if (action && value) {
				dependencies.push(linkTextScanner(`${action}:${value}`));
			}
		}
	}
}

export function extractLinksFromMarkdown(markdownText) {
	return markdownScanner(markdownText);
}

export function extractUrlFromLinkText(linkText) {
	return linkTextScanner(linkText);
}

export function normalizeActivity(activity) {
	if (isActivityOfLists(activity)) {
		activity.firstLevel = "lists";
		return activity;
	} else if (isActivityOfSections(activity)) {
		activity.firstLevel = "sections";
		return normalizeActivityFromSections(activity);
	} else if (isActivityOfItems(activity)) {
		activity.firstLevel = "items";
		return normalizeActivityFromItems(activity);
	} else {
		activity.firstLevel = "null";
		return activity;
	}
}

function isActivityOfType(activity, type) {
	if (activity.children.length > 0 && activity.children[0].type.startsWith(type)) {
		return true;
	} else {
		return false;
	}
}


export function isActivityOfLists(activity) {
	return isActivityOfType(activity, "list");
}

export function isActivityOfSections(activity) {
	return isActivityOfType(activity, "section");
}

export function isActivityOfItems(activity) {
	return isActivityOfType(activity, "item");
}

function normalizeActivityFromSections(activity) {
	let newActivity = _.omit(activity, ["children"]);

	let list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "inherit",
		"color": "black",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": _.cloneDeep(activity.children)
	};

	newActivity.children = [];
	newActivity.children.push(list);

	return newActivity;
}

function normalizeActivityFromItems(activity) {
	let newActivity = _.omit(activity, ["children"]);

	let list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "inherit",
		"color": "black",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": [
			{
				"type": "section",
				"completionState": "n",
				"name": "Items",
				"vertScrollPos": 0,
				"children": _.cloneDeep(activity.children)
			}
		]
	};

	newActivity.children = [];
	newActivity.children.push(list);

	return newActivity;
}

export function createExperiencesInventoryFromActivities(activities) {

	let clone = _.cloneDeep(activities);

	for (let i = 0; i < clone.length; i++) {
		clone[i] = createExperiencesInventoryItemFromActivity(clone[i]);
	}
	return clone;
}

export function createExperiencesInventoryItemFromActivity(activity) {
	return _.pick(activity, AppConstants.experiencesInventory.props);
}

export function isActivityAssignment(activityId) {
	let assignments = state.get(["assignments", "schedule"])

	if (_.isArray(assignments)) {
		for (const assignment of assignments) {
			if (assignment.activityId === activityId) {
				return true;
			}
		}
	}

	return false;
}

export function isActivityAssignmentsArrayComplete(activityAssignments = []) {
	for (const assignment of activityAssignments) {
		if (!assignment.submitted) {
			return false;
		}
	}
	return true;
}

export function isActivitySharedOrOwner(activityMetadata) {
	if (activityMetadata.owner || activityMetadata.sharedWithType) {
		return true;
	}
	return false;
}

export function isActivityHasShares(shareData) {
	if (shareData &&
		((shareData && shareData.groups && shareData.groups.length !== 0) ||
			(shareData && shareData.members && shareData.members.length !== 0))) {
		return true;
	}
	return false;
}
import state from "../state/state";

export function copy(value) {
	state.set(["generalClipboard"], value);
}

export function getClipboard() {
	return state.get(["generalClipboard"]);
}

export function clearClipboard() {
	copy(null);
}

export function isClipboardEmpty() {
	return getClipboard() === null;
}
import request from "superagent";
import _ from "lodash";

import env from "../constants/env";
import state from "../state/state";
import { getJwtToken } from "./user";

import { refreshCredentials } from "../utils/securityUtil";
import { getUtcTimestamp } from "../utils/utils";
import { getIdentityId } from "./user";
import { getOrgId, getGroupById } from "./orgsActions";
import { getActivityById } from "./inventoryActions";
import { normalizeActivity } from "../utils/activity";
import { addActivityInstance } from "./actions";
import { getActivity, saveActivityInstance, getActivityInstance } from "../persistence/s3";
// import { edit } from "./activityActions";

const SCOPE_CREATE_NEW = "createNew";
const SCOPE_EDIT = "edit";

export function getAssignments() {

}


export function showAssigneeModal() {
	state.set(["appState", "assignments", "showAssigneeModal"], true);
}

export function hideAssigneeModal() {
	state.set(["appState", "assignments", "showAssigneeModal"], false);
}

export function showCreateModal() {
	state.unset(["assignments", "createNew"]);
	showModal("showCreateModal");
}

export function hideCreateModal() {
	hideModal("showCreateModal");
	state.unset(["assignments", "createNew"]);
}

function selectAssignmentForEdit(assignmentId) {
	if (!assignmentId) {
		throw new Error("An assignment id must be provided");
	}
	const assignments = state.get(["assignments", "list"]);
	let found = false;
	assignments.forEach(assignment => {

		const id = assignment.id;
		if (!found && assignmentId === id) {
			console.log(id, assignmentId);
			found = true;
			state.set(["assignments", "edit"], _.assign({ id }, getSanitizedEditInfo(assignment.editInfo)));
		}
	});

	return found;
}

export function showEditModal(assignmentId) {

	if (selectAssignmentForEdit(assignmentId)) {
		showModal("showEditModal");
	}
}

export function hideEditModal() {
	hideModal("showEditModal");
}

function showModal(prop) {
	state.set(["appState", "assignments", prop], true);
}

function hideModal(prop) {
	state.set(["appState", "assignments", prop], false);
}

function showSpinner() {
	state.set(["assignments", "showSpinner"], true);
}

function hideSpinner() {
	state.set(["assignments", "showSpinner"], false);
}

function showLoadScheduleSpinner() {
	state.set(["assignments", "showLoadScheduleSpinner"], true);
}
function hideLoadScheduleSpinner() {
	state.set(["assignments", "showLoadScheduleSpinner"], false);
}

function showLoadOrgScheduleSpinner() {
	state.set(["assignments", "showLoadOrgScheduleSpinner"], true);
}
function hideLoadOrgScheduleSpinner() {
	state.set(["assignments", "showLoadOrgScheduleSpinner"], false);
}

export function listAssignments() {
	console.log("Listing assignments...");
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.list}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.then(res => {
						state.set(["assignments", "list"], res.body);
						hideSpinner();
						resolve(res.body);
					}).catch(err => {
						console.error(err);
						hideSpinner();
						reject(err);
					});
			}).catch(err => {
				hideSpinner();
				reject(err);
			});
		});
	});
}

export function selectActivityForNewAssignment(activityIds) {
	setNewAssignmentProperty("activityIds", activityIds);
	determineAddScheduleValid();
}

export function selectActivityForEditAssignment(activityIds) {
	setEditAssignmentProperty("activityIds", activityIds);
	determineAddScheduleValid(SCOPE_EDIT);
}

export function selectAssigneesForNewAssignment(assignmentInfo) {
	selectAssignees(assignmentInfo);
}

export function selectAssigneesForEditAssignment(assignmentInfo) {
	selectAssignees(assignmentInfo, SCOPE_EDIT);
}

function selectAssignees(assignmentInfo, scope = SCOPE_CREATE_NEW) {
	console.log("Adjusting assignees", assignmentInfo);
	let info = state.get(["assignments", scope]);
	const _assignmentInfo = _.assign(info && info.assignmentInfo ? info.assignmentInfo : {}, assignmentInfo);
	state.set(["assignments", scope, "assignmentInfo"], _assignmentInfo);
	determineAddScheduleValid(scope);
}

export function addScheduleForNewAssignment(expression, humanReadable, tzOffsetByAssignee) {
	addSchedule({
		expression, humanReadable, tzOffsetByAssignee
	});
}

export function addScheduleForEditAssignment(expression, humanReadable, tzOffsetByAssignee) {
	addSchedule({
		expression, humanReadable, tzOffsetByAssignee
	}, SCOPE_EDIT);
}
export function updateScheduleForEditAssignment(expression, humanReadable, tzOffsetByAssignee, idx) {
	updateSchedule({
		expression, humanReadable, tzOffsetByAssignee
	}, idx, SCOPE_EDIT);
}
function addSchedule({ expression, humanReadable, tzOffsetByAssignee }, scope = SCOPE_CREATE_NEW) {
	console.log("Adjusting schedule", expression);
	const scheduleEntry = {
		expression,
		humanReadable,
		tzOffset: new Date().getTimezoneOffset(),
		tzOffsetByAssignee
	};
	if (!state.exists(["assignments", scope, "schedules"])) {
		state.set(["assignments", scope, "schedules"], [scheduleEntry]);
	} else {
		state.push(["assignments", scope, "schedules"], scheduleEntry);
	}
	determineAddScheduleValid(scope);
}

function updateSchedule({ expression, humanReadable, tzOffsetByAssignee }, idx, scope = SCOPE_CREATE_NEW) {
	console.log("Adjusting schedule", expression);
	const scheduleEntry = {
		expression,
		humanReadable,
		tzOffset: new Date().getTimezoneOffset(),
		tzOffsetByAssignee
	};
	if (state.exists(["assignments", scope, "schedules"])) {
		console.log("Splice ", idx, 1);
		state.splice(["assignments", scope, "schedules"], [idx, 1, scheduleEntry]);
	}
	determineAddScheduleValid(scope);
}

export function removeSelectedSchedulesForNewAssignment() {
	removeSelectedSchedules();
}

export function removeSelectedSchedulesForEditAssignment() {
	removeSelectedSchedules(SCOPE_EDIT);
}

function removeSelectedSchedules(scope = SCOPE_CREATE_NEW) {
	if (!state.exists(["assignments", scope, "schedules"])) {
		throw new Error("Unable to find schedules.");
	}
	const indices = _.keys(state.get(["assignments", scope, "schedulesSelect"]));
	console.log("Selected", indices);

	if (indices && indices.length > 0) {
		let schedules = Object.assign([], state.get(["assignments", scope, "schedules"]));
		_.pullAt(schedules, _.map(indices, (idx) => parseInt(idx)));
		state.set(["assignments", scope, "schedules"], schedules);
		state.set(["assignments", scope, "schedulesSelect"], []);
		determineAddScheduleValid(scope);
	}
}

export function selectScheduleForNewAssignment(idx) {
	selectSchedule(idx);
}

export function deselectScheduleForNewAssignment(idx) {
	deselectSchedule(idx);
}

export function selectScheduleForEditAssignment(idx) {
	selectSchedule(idx, SCOPE_EDIT);
}

export function deselectScheduleForEditAssignment(idx) {
	deselectSchedule(idx, SCOPE_EDIT);
}

function selectSchedule(idx, scope = SCOPE_CREATE_NEW) {
	state.set(["assignments", scope, "schedulesSelect", idx], true);
}

function deselectSchedule(idx, scope = SCOPE_CREATE_NEW) {
	state.unset(["assignments", scope, "schedulesSelect", idx]);
}

export function setNewAssignmentNotes(notes) {
	setNewAssignmentProperty("notes", notes);
}

export function setEditAssignmentNotes(notes) {
	setEditAssignmentProperty("notes", notes);
}
function setNewAssignmentProperty(name, value) {
	state.set(["assignments", SCOPE_CREATE_NEW, name], value);
}

function setEditAssignmentProperty(name, value) {
	state.set(["assignments", SCOPE_EDIT, name], value);
}

function determineAddScheduleValid(scope = SCOPE_CREATE_NEW) {
	let info = state.get(["assignments", scope]);
	let valid = true;

	if (!info.hasOwnProperty("activityIds") || _.isEmpty(info.activityIds)) {
		valid = false;
	}

	if (!info.hasOwnProperty("assignmentInfo") || _.isEmpty(info.assignmentInfo) || _.isEmpty(getAssigneeIds(scope))) {
		valid = false;
	}

	if (!info.hasOwnProperty("schedules") || _.isEmpty(info.schedules)) {
		valid = false;
	}
	state.set(["assignments", scope, "valid"], valid);
	return valid;
}

export function getAssignmentSchedulesForOrg() {
	//getSchedule
	console.log("Getting current users schedule...");
	return new Promise((resolve, reject) => {
		showLoadOrgScheduleSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.getSchedule}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.then(res => {
						state.set(["assignments", "orgSchedule"], res.body);
						hideLoadOrgScheduleSpinner();
						resolve(res.body);
					}).catch(err => {
						console.error(err);
						hideLoadOrgScheduleSpinner();
						reject(err);
					});
			}).catch(err => {
				hideLoadOrgScheduleSpinner();
				reject(err);
			});
		});
	});
}

export function initializeScheduling(schedulingResponse) {
	return new Promise((resolve) => {
		state.set(["assignments", "schedule"], schedulingResponse);
		resolve(schedulingResponse);
	});
}

export function getAssignmentScheduleForCurrentUser() {
	console.log("Getting current users schedule...");
	return new Promise((resolve, reject) => {
		if (state.get(["appCapabilities", "assignments"])) {
			showLoadScheduleSpinner();
			setTimeout(() => {
				refreshCredentials().then(() => {
					request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.getScheduleForMemberId(getIdentityId())}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.then(res => {
							state.set(["assignments", "schedule"], res.body);
							hideLoadScheduleSpinner();
							resolve(res.body);
						}).catch(err => {
							console.error(err);
							hideLoadScheduleSpinner();
							reject(err);
						});
				}).catch(err => {
					hideLoadScheduleSpinner();
					reject(err);
				});
			});
		} else {
			resolve([]);
		}

	});
}

export function submitNewAssignment() {
	return new Promise((resolve, reject) => {
		showCreateNewSpinner();
		setTimeout(() => {
			//createAssignment

			if (state.get(["assignments", "createNew", "valid"])) {
				refreshCredentials().then(() => {
					const message = {
						schedules: getSchedules(SCOPE_CREATE_NEW),
						orgId: getOrgId(),
						assigneeIds: getAssigneeIds(SCOPE_CREATE_NEW),
						assignerId: getIdentityId(),
						notes: getNotes(SCOPE_CREATE_NEW),
						activities: getActivityInfos(SCOPE_CREATE_NEW),
						editInfo: getSanitizedEditInfo(state.get(["assignments", "createNew"]))
					};
					request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.create}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(message))
						.then(res => {
							listAssignments();
							hideCreateNewSpinner();
							resolve(res);
						}).catch(err => {
							console.error(err);
							hideLoadScheduleSpinner();
							reject(err);
						});
				}).catch(err => {
					hideLoadScheduleSpinner();
					reject(err);
				});
			} else {
				hideCreateNewSpinner();
				reject("The assignment is not valid and must be fixed prior to committing.");
			}
		});

	});
}

export function submitAssignmentChanges() {
	return new Promise((resolve, reject) => {
		showEditSpinner();
		setTimeout(() => {
			//createAssignment

			if (state.get(["assignments", "edit", "valid"])) {
				refreshCredentials().then(() => {
					const editInfo = getSanitizedEditInfo(state.get(["assignments", "edit"]));
					const { id } = editInfo;
					const message = {
						id,
						schedules: getSchedules(SCOPE_EDIT),
						orgId: getOrgId(),
						assigneeIds: getAssigneeIds(SCOPE_EDIT),
						assignerId: getIdentityId(),
						activities: getActivityInfos(SCOPE_EDIT),
						notes: getNotes(SCOPE_EDIT),
						editInfo
					};
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.update}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(message))
						.then(res => {
							listAssignments().then(() => {
								selectAssignmentForEdit(id); // Need to select this again
								hideEditSpinner();
								resolve(res);
							});
						}).catch(err => {
							console.error(err);
							hideLoadScheduleSpinner();
							reject(err);
						});
				}).catch(err => {
					hideLoadScheduleSpinner();
					reject(err);
				});
			} else {
				hideEditSpinner();
				reject("The assignment is not valid and must be fixed prior to committing.");
			}
		});

	});
}

function getSanitizedEditInfo(obj) {
	return _.omit(obj, ["showSpinner"]);
}

function showCreateNewSpinner() {
	setNewAssignmentProperty("showSpinner", true);
}

function hideCreateNewSpinner() {
	setNewAssignmentProperty("showSpinner", false);
}

function showEditSpinner() {
	setEditAssignmentProperty("showSpinner", true);
}

function hideEditSpinner() {
	setEditAssignmentProperty("showSpinner", false);
}

function getSchedules(scope = SCOPE_CREATE_NEW) {
	return state.get(["assignments", scope, "schedules"]);
}

function getNotes(scope = SCOPE_CREATE_NEW) {
	return state.get(["assignments", scope, "notes"]);
}


function getAssigneeIds(scope = SCOPE_CREATE_NEW) {
	const assignmentInfo = state.get(["assignments", scope, "assignmentInfo"]);

	// Identity ids for members is simple ... just split the comma, however the groups need to be resolved.
	const assigneesMap = {};

	if (assignmentInfo.members && !_.isEmpty(assignmentInfo.members)) {
		assignmentInfo.members.split(",").forEach(memberId => {
			assigneesMap[memberId] = true;
		});
	}
	if (assignmentInfo.groups && !_.isEmpty(assignmentInfo.groups)) {
		assignmentInfo.groups.split(",").forEach(groupId => {
			const group = getGroupById(groupId);
			if (group && group.hasOwnProperty("members")) {
				group.members.forEach(memberId => {
					assigneesMap[memberId] = true;
				});
			}
		});
	}
	console.log(assignmentInfo);
	return _.keys(assigneesMap);
}

function getActivityInfos(scope = SCOPE_CREATE_NEW) {
	const activityIds = state.get(["assignments", scope, "activityIds"]);
	const activities = [];
	activityIds.split(",").forEach(activityId => {
		const activity = getActivityById(activityId);
		if (activity) {
			if (!activity.hasOwnProperty("identityId")) {
				activity.identityId = getIdentityId();
			}
			activities.push(_.omit(activity, ["children", "share", "selected", "comments", "flightTimer", "logbook", "selectedListIndex"]));
		}
	});
	return activities;
}

export function selectAssignment(selected, assignmentId) {
	state.set(["assignments", "selected", assignmentId], selected);
}

export function deleteSelectedAssignments() {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			const targetIds = [];
			_.forEach(state.get(["assignments", "selected"]), (value, key) => {
				if (value) {
					targetIds.push(key);
				}
			});

			if (targetIds.length > 0) {


				refreshCredentials().then(() => {
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assignments.remove}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(targetIds)
						.then(res => {
							listAssignments();
							hideSpinner();
							resolve(res.body);
						}).catch(err => {
							console.error(err);
							hideSpinner();
							reject(err);
						});
				}).catch(err => {
					hideSpinner();
					reject(err);
				});


			} else {
				resolve();
			}
		});
	});
}

// Actions for the assignee



export function setAssignments(assignments) {
	state.set(["assignments", "schedule"], assignments);
}


export function markAssignmentSubmitted(assignmentId, instanceId, submitted = null) {

	let assignmentIdx = -1;
	const scheduledAssigments = state.get(["assignments", "schedule"]);
	const n = scheduledAssigments.length;
	console.log("Trying to find schedule item", assignmentId, instanceId, scheduledAssigments);
	for (let i = 0; i < n; i++) {
		const scheduledAssigment = scheduledAssigments[i];
		if (scheduledAssigment.assignmentId === assignmentId && scheduledAssigment.instanceId === instanceId) {
			console.log("FOUND schedule item", scheduledAssigment, i);
			assignmentIdx = i;
			break;
		}
	}

	if (assignmentIdx > -1) {
		const submitDate = submitted ? submitted : new Date();
		state.set(["currentChecklist", "submitted"], submitDate);
		state.set(["assignments", "schedule", assignmentIdx, "submitted"], submitDate);
	}
}

export function submitAssignment(assignmentInfo, existingSession) {
	// See if we have an instance for this assignment

	// checklists: ['checklists'],
	// 	instances: ['instances'],
	showSpinner();
	setTimeout(async () => {

		try {
			const submitted = getUtcTimestamp().replace(" ", "T");
			const { activityId, instanceId } = assignmentInfo;
			// let filePath = "/checklists/" + "checklist-" + activityId + "_" + instanceId;

			// const instanceExists = await exists(filePath);
			// const instanceExists = false;
			let instance = null;
			if (existingSession) {
				// instance = await readFileAsJson(filePath);
				// instance.submitted = submitted;

				const _instance = await getActivityInstance(existingSession.url);
				instance = _.assign({}, _instance, {
					submitted
				});
				console.log("Get instance", existingSession, instance);
				// This is messed up but even if we find the instance, we have to make sure that it's part of the instances
				await saveActivityInstance(instance);

				addActivityInstance(activityId, {
					id: instanceId,
					lastUpdatedDate: getUtcTimestamp().replace(" ", "T"),
					sync: true,
					submitted
				}, true);
			} else {
				// Create a new one!
				console.log("Get activity for assignment", assignmentInfo);
				const activityInfo = getActivityById(activityId);
				const { id, identityId } = activityInfo;
				const activity = await getActivity(id, identityId);
				if (activity) {
					console.log("Submit non existing", activity);
					instance = _.assign({}, normalizeActivity(activity), {
						instanceId,
						submitted
					});

					await saveActivityInstance(instance);

					addActivityInstance(activityId, {
						id: instanceId,
						lastUpdatedDate: getUtcTimestamp().replace(" ", "T"),
						sync: true,
						submitted
					}, true);
				}
			}

			if (instance) {
				// await submitChecklist(state, Object.assign({}, instance));
				// Mark assignment as completed locally
				markAssignmentSubmitted(assignmentInfo.assignmentId, instanceId, submitted);
			}
			hideSpinner();
		} catch (e) {
			console.error(e);
			hideSpinner();
		}
	}, 100);
}
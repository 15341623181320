import request from "superagent";
import _ from "lodash";
import FileSaver from "file-saver";

import state from "../state/state";
import { getIdentityId, getJwtToken } from "./user";
import { refreshCredentials } from "../utils/securityUtil";
import env from "../constants/env";
import { getActivityById } from "./inventoryActions";
import { getObject } from "../persistence/s3";
import { showError } from "./alertActions";
import { getPreferences } from "./orgsActions";
import { showDocumentsSpinner, hideDocumentsSpinner } from "./actions";
import { downloadFileFromUrl } from "../utils/utils";


const RUNTIME_DEFAULT = "default"; // no additional perisstence API, just a standalone player
const RUNTIME_SCORM12 = "scorm1.2";
const RUNTIME_SCORM2004 = "scorm2004";
const RUNTIME_TINCAN = "tincan";

const RUNTIMES = [
	RUNTIME_DEFAULT,
	RUNTIME_SCORM12,
	RUNTIME_SCORM2004,
	RUNTIME_TINCAN
];

const PUBLISH_COMPLETE_MODAL_OPTIONS = ["showDownloadButton", "showCopyUrlButton", "body", "url", "fileName"];

export function showPublishModal(activity) {
	state.set(["publish", "newPublishConfig", "activities"], []);
	state.set(["publish", "newPublishConfig"], {
		delivery: "standalone",
		targetRuntime: "default",
		config: {},
		targetEnv: "make-public"
	});
	selectActivityForNewPublication(activity.id);
	state.set(["publish", "showConfigModal"], true);
}

export function showExportToZipModal(activity) {
	state.set(["exportToZip", "config"], {
		delivery: "standalone",
		targetRuntime: "default",
		config: {},
		activities: []
	});
	selectActivityForExportToZip(activity.id);
	state.set(["exportToZip", "showConfigModal"], true);
}

export function setNewPublishRuntime(runtime) {
	setNewPublishOption("targetRuntime", runtime);
}

export function setNewPublishDelivery(delivery) {
	setNewPublishOption("targetEnv", delivery);
	// Right now this will only be available for published URLs
	if (delivery !== "make-public") {
		setNewPublishConfigOption("enableDataTracking", false);
	}
}


export function setNewPublishOption(option, value) {
	state.set(["publish", "newPublishConfig", option], value);
}

export function setNewPublishConfigOption(option, value) {
	state.set(["publish", "newPublishConfig", "params", option], value);
	if (option === "enableDataTracking") {
		if (value) {
			state.set(["publish", "newPublishConfig", "params", "authUri"], `https://${window.location.href.split("//")[1].split("/")[0]}/auth`);
		} else {
			state.unset(["publish", "newPublishConfig", "params", "authUri"]);
		}
	}
}

export function hidePublishModal() {
	state.set(["publish", "newPublishConfig"], {});
	state.set(["publish", "showConfigModal"], false);
}

export function hideExportToZipModal() {
	state.set(["exportToZip", "config"], {});
	state.set(["exportToZip", "showConfigModal"], false);
}

export function showPublishSpinner(message = null) {
	state.set(["publish", "showSpinner"], true);
	state.set(["publish", "spinnerMessage"], message);
}

export function hidePublishSpinner() {
	state.set(["publish", "showSpinner"], false);

	state.set(["publish", "spinnerMessage"], null);
	hideDocumentsSpinner();
}

export function updatePublishSpinnerMessage(message) {
	state.set(["publish", "spinnerMessage"], message);
}

export function setExportToZipConfigOption(option, value) {
	state.set(["exportToZip", "config", "params", option], value);
}
export function exportActivitiesToZip() {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const exportToZipInfo = _.cloneDeep(state.get(["exportToZip", "config"]));
			exportToZipInfo.isExportToZip = true;
			exportToZipInfo.delivery = "standalone"; // Need to re-evaluate this

			getPreferences().then(orgPreferences => {
				if (orgPreferences && orgPreferences.success) {
					exportToZipInfo.orgPreferences = orgPreferences.preferences;
				}
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.publish}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(JSON.stringify(exportToZipInfo))
					.then((res) => {
						const { jobToken } = res.body;
						state.set(["publish", "status", jobToken], res.body);
						trackPublishStatus(jobToken, resolve, reject);
						// This already works ... now we just have to integrate some status tracking to make this more intuitive
						// resolve(res.body);
					}).catch(err => {
						reject(err);
					});
			}).catch(err => {
				console.error(err);
				reject(err);
			});
		});
	});
}

export function publishActivities() {
	return new Promise((resolve, reject) => {
		showPublishSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				validatePublicationInfo();
				const publishInfo = _.cloneDeep(state.get(["publish", "newPublishConfig"]));

				let activities = publishInfo.activities;

				if (publishInfo.primaryActivity) {
					const primaryActivityId = publishInfo.primaryActivity.id;
					activities = [publishInfo.primaryActivity];
					publishInfo.activities.forEach(activity => {
						if (activity.id !== primaryActivityId) {
							activities.push(activity);
						}
					});
				}
				publishInfo.activities = activities;
				publishInfo.timerTemplates = state.get(["timerTemplates"]);
				getPreferences().then(orgPreferences => {
					if (orgPreferences && orgPreferences.success) {
						publishInfo.orgPreferences = orgPreferences.preferences;
					}
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.publish}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(publishInfo))
						.then((res) => {
							const { jobToken } = res.body;
							state.set(["publish", "status", jobToken], res.body);
							trackStatus(jobToken);
							// This already works ... now we just have to integrate some status tracking to make this more intuitive
							resolve(res.body);
						}).catch(err => {
							hidePublishSpinner();
							reject(err);
						});
				}).catch(err => {
					console.error(err);
					hidePublishSpinner();
					reject(err);
				});
			});
		});
	});
}

async function trackStatus(jobToken) {
	try {
		const status = await getJobStatus(jobToken);
		console.log("tracking publication", status);
		if (status.progress === -1) {
			showError("Error", "Publication failed.");
			hidePublishSpinner();
		} else if (status.progress !== 100) {
			setTimeout(() => {
				trackStatus(jobToken);
			}, 3000);
		} else {
			console.log("DONE", status);
			savePublication(jobToken);
		}
	} catch (e) {
		hidePublishSpinner();
		console.error(e);
	}
}

async function trackPublishStatus(jobToken, resolve, reject, download = true) {
	try {
		const status = await getJobStatus(jobToken);
		console.log("tracking publication", status);
		if (status.progress === -1) {
			reject(status.message);
		} else if (status.progress !== 100) {
			setTimeout(() => {
				trackPublishStatus(jobToken, resolve, reject, download);
			}, 3000);
		} else {
			await savePublication(jobToken, download);
			if (!download) {
				resolve(global.lastPublishedActivity[0]);
			} else {
				resolve(jobToken);
			}
			console.log("DONE", status);
		}
	} catch (e) {
		hidePublishSpinner();
		console.error(e);
	}
}

async function trackPublishStatusForWorkflow(jobToken, resolve, reject) {
	try {
		const status = await getJobStatus(jobToken);
		console.log("tracking publication", status);
		if (status.progress === -1) {
			reject(status.message);
		} else if (status.progress !== 100) {
			setTimeout(() => {
				trackPublishStatusForWorkflow(jobToken, resolve, reject);
			}, 3000);
		} else {
			resolve(jobToken);
			console.log("DONE", status);
		}
	} catch (e) {
		hidePublishSpinner();
		console.error(e);
	}
}



export function selectActivityForNewPublication(activityIds) {
	state.set(["publish", "newPublishConfig", "activityIds"], activityIds);
	if (activityIds && activityIds.split) {
		const newActivities = [];
		activityIds.split(",").forEach(activityId => {
			const activity = getActivityById(activityId);
			if (activity) {
				newActivities.push({
					id: activity.id,
					scope: activity.identityId && !_.isEmpty(activity.identityId) ? activity.identityId : getIdentityId(),
					name: activity.name
				});
			}
		});
		state.set(["publish", "newPublishConfig", "activities"], newActivities);
	}
}

export function selectActivityForExportToZip(activityIds) {
	state.set(["exportToZip", "config", "activityIds"], activityIds);
	if (activityIds && activityIds.split) {
		const newActivities = [];
		activityIds.split(",").forEach(activityId => {
			const activity = getActivityById(activityId);
			if (activity) {
				newActivities.push({
					id: activity.id,
					scope: activity.identityId && !_.isEmpty(activity.identityId) ? activity.identityId : getIdentityId(),
					name: activity.name
				});
			}
		});
		state.set(["exportToZip", "config", "activities"], newActivities);
	}
}

export function savePublication(jobToken, download = true) {

	return new Promise((resolve, reject) => {
		const statusInfo = state.get(["publish", "status", jobToken]);
		state.set(["publish", "status", jobToken, "downloading"], true);
		const { progress, outputArtifactBucket, outputArtifactKey, publishInfo: publishInfoStr, started, outputUrl } = statusInfo;

		const publishInfo = JSON.parse(publishInfoStr);

		if (progress === 100 && outputArtifactBucket && outputArtifactKey && !outputUrl) {
			getObject(outputArtifactBucket, outputArtifactKey, true).then(content => {
				let blob;
				let exportFileName;

				if (publishInfo.isGetActivitiesWithPublicResources || publishInfo.op === "getActivitiesWithPublicResources") {
					blob = new Blob([content], { type: "application/json" });
					exportFileName = `${_.first(publishInfo.activities).name}-activitiesWithPublicResources-${started}.json`;
				} else {
					blob = new Blob([content], { type: "application/zip" });
					exportFileName = `${_.first(publishInfo.activities).name}-${publishInfo.targetRuntime}-${started}.zip`;
				}

				if (download) {
					FileSaver.saveAs(blob, exportFileName);
					hidePublishSpinner();
				} else {
					let utf8decoder = new TextDecoder()

					const jsonResult = JSON.parse(utf8decoder.decode(content));

					global.lastPublishedActivity = jsonResult;
					// Need to signal that got the data
				}
				state.set(["publish", "status", jobToken, "downloading"], false);
				resolve();
			}).catch(err => {
				hidePublishSpinner();
				state.set(["publish", "status", jobToken, "downloading"], false);
				console.error(err);
				reject(err);
			});
		} else if (progress === 100 && outputUrl) {

			let modalConfig = {
				body: `<a target="_blank" href="${outputUrl}">Click here</a> to view your publication in a new tab, or copy the following URL:<br/> ${outputUrl}`,
				url: outputUrl,
				showCopyUrlButton: true
			};

			if (publishInfo.isExportToVideo) {
				modalConfig.showDownloadButton = true;
				modalConfig.fileName = `${_.first(publishInfo.activities).name}-video-${started}.mp4`;
			}

			showPublishCompleteModal(state, modalConfig);
			hidePublishSpinner();
			resolve();
		} else {
			hidePublishSpinner();
			resolve();
		}
	});
}



export function selectPrimaryActivityForNewPublication(activity) {
	state.set(["publish", "newPublishConfig", "primaryActivity"], activity);
}

// function queryJobStatus() {

// }

const REQ_PROPERTIES = ["delivery", "targetRuntime", "activities"];

export function validatePublicationInfo() {
	const publishInfo = state.get(["publish", "newPublishConfig"]);

	if (!publishInfo) {
		throw new Error("No publish info in state yet.");
	}

	const issues = [];

	REQ_PROPERTIES.forEach(prop => {
		if (!publishInfo.hasOwnProperty(prop)) {
			issues.push(`Missing required property ${prop}`);
		}
	});

	if (publishInfo.hasOwnProperty("delivery") && publishInfo.delivery !== "standalone") {
		issues.push("Expecting a property 'delivery' with value 'standalone'");
	}

	if (publishInfo.hasOwnProperty("targetRuntime") && _.indexOf(RUNTIMES, publishInfo.targetRuntime) === -1) {
		issues.push(`Expecting a property 'targetRuntime' with value matching one of ${RUNTIMES}`);
	}

	if (publishInfo.hasOwnProperty("activities") && _.isEmpty(publishInfo.activities)) {
		issues.push("Expecting a property 'activities' with at least one activity entry");
	}

	if (publishInfo.hasOwnProperty("activities") && publishInfo.activities.length > 1 && (publishInfo.hasOwnProperty("primaryActivity") && _.isEmpty(publishInfo.primaryActivity))) {
		issues.push("Expecting a property 'primaryActivity' when more than one activities have been selected.");
	}

	if (issues.length > 0) {
		throw new Error({
			message: "Validation of new publish info failed",
			issues
		});
	}
}

export function getJobStatus(jobToken) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {

			request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.status(jobToken)}`)
				.set("Authorization", getJwtToken())
				.then((res) => {
					state.set(["publish", "status", jobToken], res.body);
					resolve(res.body);
				}).catch(err => {
					reject(err);
				});
		});

	});
}

function showPageSpinner() {
	state.set(["publish", "showPageSpinner"], true);
}

function hidePageSpinner() {
	state.set(["publish", "showPageSpinner"], false);
}

export function listStatus() {
	return new Promise((resolve, reject) => {
		showPageSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.status("all")}`)
					.set("Authorization", getJwtToken())
					.then((res) => {
						state.set(["publish", "status"], res.body);
						hidePageSpinner();
						resolve(res.body);
					}).catch(err => {
						hidePageSpinner();
						reject(err);
					});
			});
		});

	});
}

/**
 * This will create a published version of the activity for a workflow
 * @param {*} publicationInfo 
 */
export function createPublication(publicationInfo) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const publishInfo = _.cloneDeep(publicationInfo);
			publishInfo.isPublication = true;
			publishInfo.delivery = "standalone"; // Need to re-evaluate this
			if (publishInfo.activity && !publishInfo.activity.hasOwnProperty("scope")) {

				publishInfo.activity.scope = publishInfo.activity.identityId && !_.isEmpty(publishInfo.activity.identityId) ? publishInfo.activity.identityId : getIdentityId();
			}
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.publish}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(publishInfo))
				.then((res) => {
					const { jobToken } = res.body;
					state.set(["publish", "status", jobToken], res.body);
					trackPublishStatusForWorkflow(jobToken, resolve, reject);
					// This already works ... now we just have to integrate some status tracking to make this more intuitive
				}).catch(err => {
					reject(err);
				});
		});
	});
}

/**
 * This will take an activity and generate some exported 
 * @param {*} activity
 */
export async function publishActivityToVideo(activity) {
	showDocumentsSpinner();

	let activities = [activity];

	try {
		state.set(["publish", "newPublishConfig", "activities"], []);
		state.set(["publish", "newPublishConfig"], {
			config: {}
		});

		let activityIds = activities.map((activity) => {
			return activity.id;
		});

		selectActivityForNewPublication(activityIds.join(","));

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				refreshCredentials().then(() => {
					const publishInfo = _.cloneDeep(state.get(["publish", "newPublishConfig"]));

					publishInfo.isExportToVideo = true;
					publishInfo.delivery = "standalone"; // Need to re-evaluate this

					let activities = publishInfo.activities;

					if (publishInfo.primaryActivity) {
						const primaryActivityId = publishInfo.primaryActivity.id;
						activities = [publishInfo.primaryActivity];
						publishInfo.activities.forEach(activity => {
							if (activity.id !== primaryActivityId) {
								activities.push(activity);
							}
						});
					}
					publishInfo.activities = activities;

					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.publish}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(publishInfo))
						.then(async (res) => {
							const { jobToken } = res.body;
							state.set(["publish", "status", jobToken], res.body);
							trackStatus(jobToken);
						}).catch(err => {
							reject(err);
						});
				});
			});
		});
	} catch (err) {
		console.log(err);
		showError("Error", "Publish activity video failed.");
		hideDocumentsSpinner();
	}
}

/**
 * This will take an activity and generate signed urls for all media
 * This is using the publishing infrastructure which is not ideal.
 * Ideally there would be an API Gateway function where you pass an activity and get back one with signed url's
 * @param {*} activity
 */
export async function publishActivityToSignedUrls(activity, fileName = "") {
	// showDocumentsSpinner();

	let activities = [activity];

	try {
		state.set(["publish", "newPublishConfig", "activities"], []);
		state.set(["publish", "newPublishConfig"], {
			config: {}
		});

		let activityIds = activities.map((activity) => {
			return activity.id;
		});

		selectActivityForNewPublication(activityIds.join(","));

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				refreshCredentials().then(async () => {
					const publishInfo = _.cloneDeep(state.get(["publish", "newPublishConfig"]));

					publishInfo.isGetActivitiesWithPublicResources = true;
					publishInfo.delivery = "standalone"; // Need to re-evaluate this

					// If we are passing in an instance of an activity
					if (fileName !== "" && activity.hasOwnProperty("instanceId") && activity.instanceId.length === 36) {
						publishInfo.activities[0].instanceId = activity.instanceId;
						publishInfo.activities[0].instanceFilename = fileName;

						// publishInfo.activities = [activity];
					} else {
						let activities = publishInfo.activities;

						// Reordering so primary activity is first if more than one activity
						if (publishInfo.primaryActivity) {
							const primaryActivityId = publishInfo.primaryActivity.id;
							activities = [publishInfo.primaryActivity];
							publishInfo.activities.forEach(activity => {
								if (activity.id !== primaryActivityId) {
									activities.push(activity);
								}
							});
						}

						publishInfo.activities = activities;
					}

					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.publish}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(publishInfo))
						.then((res) => {
							const { jobToken } = res.body;
							state.set(["publish", "status", jobToken], res.body);
							trackPublishStatus(jobToken, resolve, reject, false);
						}).catch(err => {
							reject(err);
						});
				});
			});
		});
	} catch (err) {
		console.log(err);
		showError("Error", "Publish activity to signed url's failed.");
		hideDocumentsSpinner();
	}
}

export function changeApproval(approvalInfo) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const { jobToken, approved, changeReason, revertToPreviousPublishedVersion } = approvalInfo;
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.approval(jobToken)}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify({ revertToPreviousPublishedVersion, approved, changeReason }))
				.then((res) => {
					const { success, message } = res.body;
					if (success) {
						resolve();
					} else {
						console.error(res.body);
						reject(message);
					}
				}).catch(err => {
					reject(err);
				});
		});
	});
}

export function deletePublication(deleteInfo) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const { jobToken, revertToPreviousPublishedVersion } = deleteInfo;
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.publications.delete(jobToken)}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify({ revertToPreviousPublishedVersion }))
				.then((res) => {
					const { success, message } = res.body;
					if (success) {
						resolve();
					} else {
						console.error(res.body);
						reject(message);
					}
				}).catch(err => {
					reject(err);
				});
		});
	});
}



export function showPublishCompleteModal(tree, options = {}) {
	tree.set(["publish", "publishCompleteModal", "show"], true);
	_.each(PUBLISH_COMPLETE_MODAL_OPTIONS, (prop) => {
		tree.set(["publish", "publishCompleteModal", prop], options[prop]);
	});
}

export function hidePublishCompleteModal(tree) {
	tree.set(["publish", "publishCompleteModal", "show"], false);
	_.each(PUBLISH_COMPLETE_MODAL_OPTIONS, (prop) => {
		tree.set(["publish", "publishCompleteModal", prop], null);
	});
}

export async function downloadPublication(tree, url, fileName) {
	showPublishSpinner();
	showDocumentsSpinner();

	await downloadFileFromUrl(url, fileName);
	hidePublishSpinner();
}
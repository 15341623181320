import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import alphaNumSort from "alphanum-sort";
import AWS from "aws-sdk";
import castit from "castit";
import copy from "copy-to-clipboard";
import FileSaver from "file-saver";
import * as Handlebars from "handlebars/dist/handlebars";
import isWebView from "is-webview";
import ls from "local-storage";
import _ from "lodash";
import { marked } from "marked";
import moment from "moment";
import qs from "qs";
import React from "react";
import request from "superagent";

import { getDatasourceForRef } from "../actions/datasource";
import { getUrlForValue, isDriveReference } from "../actions/drive";
import { getActivityById } from "../actions/inventoryActions";
import { getObjectByGuid } from "../actions/modelActions";
import { getOrgId } from "../actions/orgsActions";
import env from "../constants/env";
import PlayerPaths from "../constants/paths/playerPaths";
import state from "../state/state";
import { getMediaPanelRequiredProperties } from "./mediaUtils";
import AppConstants from "../constants/appConstants";
import { INLINE_VARIABLE_PROPS } from "../actions/editorActions";
import { handleScanForVariables } from "../actions/actions";

import { postMessage } from "../actions/mobileAppCommunicationActions";

import * as pako from "pako";
import { transformFromChecklistEntities } from "./transformUtils";
import { getChatCompletions } from "../ai/aiAssistant";

let pathCache = {};
let findNode = null;

export function getDisplayNamesMap() {
	const membersMap = state.get(["selectedOrg", "membersMap"]);
	const newMembersMap = {};

	for (const [key, value] of Object.entries(membersMap)) {
		console.log(`${key}: ${value}`);
		if (key.length === 36) {
			newMembersMap[key] = value.displayName;
		}
	}

	return newMembersMap;
}

export function isValidUrlWithParams(path) {
	const validPaths = [
		"/search",
		"/chat",
		"/portalHome",
		"/checklists",
		"/searchHistory",
		"/dashboard",
		"/history",
		"/timers",
		"/import",
		"/editor",
		"/organization",
		"/drive",
		"/activity",
		"/player",
		"/assignments",
		"/publications"
	];

	let foundValidPath = false;
	validPaths.forEach((item) => {
		if (path.startsWith(item)) {
			foundValidPath = true;;
		}
	})

	return foundValidPath;
}

function get_browser() {
	var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: 'IE', version: (tem[1] || '') };
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR\/(\d+)/)
		if (tem != null) { return { name: 'Opera', version: tem[1] }; }
	}
	if (window.navigator.userAgent.indexOf("Edge") > -1) {
		tem = ua.match(/Edge\/(\d+)/)
		if (tem != null) { return { name: 'Edge', version: tem[1] }; }
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
	return {
		name: M[0],
		version: +M[1]
	};
}

export function isBrowserSupported() {
	var browser = get_browser();

	var supported = true;
	if ((browser.name === "MSIE" || browser.name === "IE")) {
		supported = false;
	}

	// var supported = false;
	// if (browser.name === "Chrome" && browser.version >= 48) {
	// 	supported = true;
	// } else if ((browser.name === "MSIE" || browser.name === "IE")) {
	// 	supported = false;
	// } else if (browser.name === "Edge") {
	// 	supported = true;
	// }
	return supported;
}

export function uncompressResult(res) {
	const strData = atob(res);

	// Convert binary string to character-number array
	const charData = strData.split("").map((x) => { return x.charCodeAt(0); });

	// Turn number array into byte-array
	const binData = new Uint8Array(charData);

	return JSON.parse(pako.inflate(binData, { to: "string" }));
}

export function pathToArray(path) {
	const arrPath = [];

	const pathParts = path.split(",");

	for (let i = 0; i < pathParts.length; i++) {
		const pathPart = pathParts[i];

		if (isNumeric(pathPart)) {
			arrPath.push(Number(pathPart));
		} else {
			arrPath.push(pathPart);
		}
	}

	return arrPath;
}


function isNumeric(str) {
	if (typeof str != "string") return false // we only process strings!  
	return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export function getRealName(name) {
	let names = name.split("|");

	return names[0].trim();
}

export function findItemById(id) {
	if (id === "") {
		return null;
	}

	const findItemResult = recurseFindIndicesById(state.get(["tree", "root", "children"]), state, id, null, null);

	return findItem(findItemResult.listIndex, findItemResult.sectionIndex, findItemResult.itemIndex);
}

export function findItem(listIndex, sectionIndex, itemIndex) {
	if (listIndex !== null && sectionIndex !== null) {
		return state.get(["tree", "root", "children", listIndex, "children", sectionIndex, "children", itemIndex, "entity"]);
	} else if (listIndex === null && sectionIndex !== null) {
		return state.get(["tree", "root", "children", sectionIndex, "children", itemIndex, "entity"]);
	} else if (listIndex === null && sectionIndex === null) {
		return state.get(["tree", "root", "children", itemIndex, "entity"]);
	}

	return null;
}

export function recurseFindIndicesById(arr, tree, id, listIndex, sectionIndex, foundItem = null) {
	for (let i = 0; i < arr.length; i++) {
		let entity = arr[i].entity;

		if (entity.type === "list") {
			listIndex = i;
		} else if (entity.type === "section") {
			sectionIndex = i;
		} else if (entity.type.startsWith("item")) {
			if (entity.id === id) {
				foundItem = {
					listIndex: listIndex,
					sectionIndex: sectionIndex,
					itemIndex: i
				};
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			foundItem = recurseFindIndicesById(arr[i].children, tree, id, listIndex, sectionIndex, foundItem);
		}
	}

	return foundItem;
}

export function zeroPrefix(value) {
	if (value < 10) {
		return "0" + value;
	} else {
		return String(value);
	}
}

export function getLs(key) {
	return getFromLocalStorage(key);
}

export function setLs(key, value) {
	setLocalStorage(key, value);
}

export function removeLs(key) {
	ls.remove(key);
}

export function setLocalStorage(key, value) {
	ls(key, value);
}

export function getFromLocalStorage(key) {
	return ls(key);
}

export function setSs(key, value) {
	sessionStorage.setItem(key, value);
}

export function getSs(key) {
	return sessionStorage.getItem(key);
}

export function removeSs(key) {
	sessionStorage.removeItem(key);
}

export function clearSs() {
	sessionStorage.clear();
}

export function isNull(value) {
	return value === null || value === "undefined";
}

export function hasElement(arr, type, key) {
	const result = _.find(arr, [type, key]);

	return result ? result : null;
}

export function isDev() {
	// console.log("HOST", window.location.hostname);
	return (window.location.hostname === "localhost" || window.location.hostname === "dev-app.ambifi.com");
}

export function arrayToMap(arr, key, skipInvalid = false) {
	let result = {};
	if (_.isArray(arr)) {
		arr.forEach(entry => {
			if (_.isString(key) && entry.hasOwnProperty(key) && key !== "") {
				result[entry[key]] = entry;
			} else if (_.isFunction(key)) {
				result[key(entry)] = entry;
			} else if (!skipInvalid) {
				console.error(`Found an Object that does not contain the required key ${key}`, entry);
			}
		});
	}
	return result;
}

export function generateUUID() {
	let d = Date.now();
	let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16); // eslint-disable-line no-mixed-operators
	});
	return uuid;
}

export function getKeyPrefix(identityId, document = null) {
	if (document !== null) {
		const doc = getActivityById(document.id);

		if (doc.identityId && doc.sharedWithType !== "self") {
			return doc.identityId;
		}
	}

	let keyPrefix;

	if (identityId.indexOf(":") >= 0) {
		keyPrefix = identityId.split(":")[1];
	} else {
		keyPrefix = identityId;
	}

	//keyPrefix = "af861e5e-2fc6-49ee-b590-6ce4bb769a65";

	return keyPrefix;
}

export function truncateWithEllipses(text, max) {
	return text.substr(0, max - 1) + (text.length > max ? "..." : "");
}

export function getLabelFromValue(items, value, pickerItemsDatasource = null, activity = null) {
	let retValue = value;
	//pickerItemsDatasource

	// console.log("Get label", items, value, pickerItemsDatasource, activity);
	let valueKey = "value";
	let labelKeys = ["label"];

	if (pickerItemsDatasource) {


		const dsDefinition = pickerItemsDatasource;
		valueKey = _.first(dsDefinition.valueKeys);
		labelKeys = dsDefinition.labelKeys;
		if (!items || _.isEmpty(items)) {
			const ds = getDatasourceForRef(pickerItemsDatasource.refId, activity);
			items = ds.content ? ds.content : [];
		}
	}

	for (let i = 0; i < items.length; i++) {
		const _item = items[i];
		if (_item[valueKey] === value) {
			let label = [];
			labelKeys.forEach(lk => {
				label.push(_item[lk]);
			});
			retValue = label.join(" ");
		}
	}

	return retValue;
}

export function itemGetLabelFromValue(node) {
	if (node) {
		if (node.type.startsWith("itemChoice")) {
			return getLabelFromValue(node.choiceItems, node.value);
		} else if (node.type.startsWith("itemPicker")) {
			return getLabelFromValue(node.pickerItems, node.value);
		} else {
			return "";
		}
	} else {
		return "";
	}
}

// Must be array of values
export function itemGetLabelsFromValues(node) {
	if (node) {
		if (node.type.startsWith("itemChoiceMulti")) {
			const choiceItemsFormatted = [];
			// Loop through choice items an return labels
			node.value.forEach(choiceItem => choiceItemsFormatted.push(getLabelFromValue(node.choiceItems, choiceItem.value)));

			return choiceItemsFormatted.join(", ");
		} else {
			return "";
		}
	} else {
		return "";
	}
}

export function isMobile() {
	if (window.ReactNativeWebView) {
		return true;
	} else {
		return false;

		// const isMobileView = isWebView(navigator.userAgent)

		// return isMobileView;
	}
}

export function showLoader(tree) {
	tree.set(["appState", "loading"], true);
}

export function hideLoader(tree) {
	tree.set(["appState", "loading"], false);
	tree.set(["appState", "loadingMessage"], "");
}

function isChecklistOfType(checklist, type) {
	if (checklist.children.length > 0 && checklist.children[0].type.startsWith(type)) {
		return true;
	} else {
		return false;
	}
}

export function isChecklistLists(checklist) {
	return isChecklistOfType(checklist, "list");
}

export function isChecklistSections(checklist) {
	return isChecklistOfType(checklist, "section");
}

export function isChecklistItems(checklist) {
	return isChecklistOfType(checklist, "item");
}

export function getFirstLevelItemType(checklist) {
	if (checklist && checklist.hasOwnProperty("children") && checklist.children.length > 0) {
		return checklist.children[0].entity.type;
	} else {
		return null;
	}
}

function normalizeChecklistFromSections(checklist) {
	let newChecklist = _.cloneDeep(checklist);

	let list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "inherit",
		"startInBirdseyeView": false,
		"ignoreCompletion": false,
		"hideStatus": false,
		"color": "white",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": _.cloneDeep(checklist.children)
	};

	newChecklist.children = [];
	newChecklist.children.push(list);

	return newChecklist;
}

function normalizeChecklistFromItems(checklist) {
	let newChecklist = _.cloneDeep(checklist);

	let list = {
		"type": "list",
		"completionState": "n",
		"name": "Sections",
		"defaultView": "inherit",
		"startInBirdseyeView": false,
		"ignoreCompletion": false,
		"hideStatus": false,
		"color": "white",
		"horzScrollPos": 0,
		"vertScrollPos": 0,
		"children": [
			{
				"type": "section",
				"completionState": "n",
				"name": "Items",
				"vertScrollPos": 0,
				"children": _.cloneDeep(checklist.children)
			}
		]
	};

	newChecklist.children = [];
	newChecklist.children.push(list);

	return newChecklist;
}

export function normalizeActivity(checklist) {
	if (isChecklistLists(checklist)) {
		checklist.firstLevel = "lists";
		return checklist;
	} else if (isChecklistSections(checklist)) {
		checklist.firstLevel = "sections";
		return normalizeChecklistFromSections(checklist);
	} else if (isChecklistItems(checklist)) {
		checklist.firstLevel = "items";
		return normalizeChecklistFromItems(checklist);
	} else {
		checklist.firstLevel = "null";
		return checklist;
	}
}

export function setFullSessionName(checklist) {
	let sessionName = "";
	if (checklist.hasOwnProperty("sessionName")) {
		// Index that as well
		// Add this as a name
		const nameParts = [];
		alphaNumSort(_.keys(checklist.sessionName)).forEach(key => {
			if (checklist.sessionName[key] !== "") {
				nameParts.push(checklist.sessionName[key]);
			}
		});
		sessionName = nameParts.join(" - ");
		checklist.sessionName = sessionName;
	}

	return checklist;
}

export async function publishActivity(activity) {
	if (activity.hasOwnProperty("contentModel") && activity.contentModel !== "") {
		// Set Content Model...for now just support one, but eventually multiple
		const s3 = new AWS.S3({ useDualStack: true });
		const dataFileName = activity.contentModel;
		const s3Data = await s3.getObject({ Bucket: env.s3.contentBucket, Key: `${getOrgId()}/mockData/${dataFileName}` }).promise();
		const data = JSON.parse(s3Data.Body.toString());

		await recursePublishActivity(activity.children, data);
	}
}

export async function recursePublishActivity(arr, data) {
	for (let i = 0; i < arr.length; i++) {
		let node = arr[i];
		if (node.type.startsWith("item")) {
			if (node.hasOwnProperty("commentsTemplate") && node.commentsTemplate !== "") {
				// Go to s3 and get template
				const s3 = new AWS.S3({ useDualStack: true });

				const sourceFileName = node.commentsTemplate;
				const s3Source = await s3.getObject({ Bucket: env.s3.contentBucket, Key: `${getOrgId()}/templates/activity/${sourceFileName}` }).promise();
				const source = s3Source.Body.toString();
				// Used in eval
				let contentModel = data; //eslint-disable-line
				let newData = null; //eslint-disable-line
				if (node.hasOwnProperty("commentsDataPath") && node.commentsDataPath !== "") { //eslint-disable-line
					// For security reasons should only allow paths to be selected
					newData = eval(node.commentsDataPath); //eslint-disable-line
				} //eslint-disable-line

				const mergedTemplate = mergeTemplate(source, newData);
				arr[i].comments = mergedTemplate;//eslint-disable-line
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			await recursePublishActivity(arr[i].children, data);
		}
	}
}

function mergeTemplate(source, data) {
	let template = Handlebars.compile(source);

	return template(data);
}

function speed(x, type) {
	let stripStartSquare = x.substring(7);
	let stripEndSquare = stripStartSquare.substring(0, stripStartSquare.length - 1);
	let speeds = stripEndSquare.split(";");

	if (type === "MPH") {
		return speeds[1] + " MPH";
	} else {
		return speeds[0] + " KIAS";
	}
}

export function recurseReplaceSpeeds(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type.startsWith("item")) {
			arr[i].label1 = arr[i].label1.replace(/\[speed [^]+;[^]+\]/g, function myFunction(x) { return speed(x, "KIAS"); });
			arr[i].label2 = arr[i].label2.replace(/\[speed [^]+;[^]+\]/g, function myFunction(x) { return speed(x, "KIAS"); });
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseReplaceSpeeds(arr[i].children);
		}
	}
}

var he = require('he');

export function recurseReplaceHtmlEntities(arr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list" || arr[i].type === "section") {
			arr[i].name = he.encode(arr[i].name);
			arr[i].nameAudio = he.encode(arr[i].nameAudio);
		} else if (arr[i].type.startsWith("item")) {
			arr[i].label1 = he.encode(arr[i].label1);
			arr[i].label1Audio = he.encode(arr[i].label1Audio);
			arr[i].label2 = he.encode(arr[i].label2);
			arr[i].label2Audio = he.encode(arr[i].label2Audio);
			arr[i].comments = he.encode(arr[i].comments);
			arr[i].commentsAudio = he.encode(arr[i].commentsAudio);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseReplaceHtmlEntities(arr[i].children);
		}
	}
}

export function recursePopulateIdsForEntities(arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		let entity = arr[i].entity;

		if (entity.type === "list") {
			if (entity.id !== null && entity.id !== "" && !_.isUndefined(entity.id)) {
				tree.set(["idMap", entity.id], { listIndex: i, sectionIndex: 0, itemIndex: 0 });
			}
			listIndex = i;
		} else if (entity.type === "section") {
			if (entity.id !== null && entity.id !== "" && !_.isUndefined(entity.id)) {
				tree.set(["idMap", entity.id], { listIndex: listIndex, sectionIndex: i, itemIndex: 0 });
			}
			sectionIndex = i;
		} else if (entity.type.startsWith("item")) {
			if (entity.id !== null && entity.id !== "" && !_.isUndefined(entity.id)) {
				tree.set(["idMap", entity.id], { listIndex: listIndex, sectionIndex: sectionIndex, itemIndex: i });
			}
			INLINE_VARIABLE_PROPS.forEach(propName => {
				if (entity[propName]) {
					handleScanForVariables(tree, propName, entity);
				}
			})
		}

		if (arr[i].hasOwnProperty("children")) {
			recursePopulateIdsForEntities(arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function recurseDriveRefsToUrlsForEntities(arr, activityId) {
	for (let i = 0; i < arr.length; i++) {
		let entity = arr[i].entity;

		if (entity.type === "list" || entity.type === "section" || entity.type.startsWith("item")) {


			getMediaPanelRequiredProperties().forEach(type => {
				const propName = type;
				const propVal = entity[propName];
				if (propVal) {
					if (isDriveReference(propVal)) {
						entity[`${propName}Drive`] = _.cloneDeep(propVal);
					}
					entity[propName] = getUrlForValue(propVal, activityId);
				}
			});
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseDriveRefsToUrlsForEntities(arr[i].children, activityId);
		}
	}
}

export function recurseRestoreDriveRefs(arr) {
	for (let i = 0; i < arr.length; i++) {
		let entity = arr[i].entity;

		if (entity.type === "list" || entity.type === "section" || entity.type.startsWith("item")) {
			getMediaPanelRequiredProperties().forEach(type => {
				const propName = type;
				const drivePropName = `${propName}Drive`;
				const propValDrive = entity[drivePropName];
				if (propValDrive) {
					entity[propName] = _.cloneDeep(propValDrive);
					delete entity[drivePropName];
				}
			});
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseRestoreDriveRefs(arr[i].children);
		}
	}
}

export function recurseClearTtsAudio(arr) {
	for (let i = 0; i < arr.length; i++) {
		let entity = arr[i].entity;

		if (entity.type === "list" || entity.type === "section") {
			entity.labelAudioFile = "";
		} else if (entity.type.startsWith("item")) {
			entity.label1AudioFile = "";
			entity.label2AudioFile = "";
			entity.commentsAudioFile = "";
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseClearTtsAudio(arr[i].children);
		}
	}
}

export function recurseDriveRefsToUrls(arr, activityId, lastList, lastSection) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			lastList = {
				list: arr[i].name
			};

			if (arr[i].image) {
				arr[i].image = getUrlForValue(arr[i].image, activityId);
			}
		} else if (arr[i].type === "section") {
			lastSection = {
				section: arr[i].name
			};

			if (arr[i].image) {
				arr[i].image = getUrlForValue(arr[i].image, activityId);
			}
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].image) {
				arr[i].image = getUrlForValue(arr[i].image, activityId);
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseDriveRefsToUrls(arr[i].children, activityId, lastList, lastSection);
		}
	}
}


export function recurseToFlatArray(arr, lastList, lastSection, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			lastList = {
				list: arr[i].name
			};
		} else if (arr[i].type === "section") {
			lastSection = {
				section: arr[i].name
			};
		} else if (arr[i].type.startsWith("item")) {
			let arrNode = [];
			if (lastList !== "") {
				arrNode.push("\"" + lastList.list.replace(/"/g, "\"\"") + "\"");
			} else {
				arrNode.push("\"Sections\"");
			}
			if (lastSection !== "") {
				arrNode.push("\"" + lastSection.section.replace(/"/g, "\"\"") + "\"");
			} else {
				arrNode.push("\"Items\"");
			}
			arrNode.push("\"" + arr[i].label1.replace(/"/g, "\"\"") + "\"");
			arrNode.push("\"" + arr[i].label2.replace(/"/g, "\"\"") + "\"");
			arrNode.push(arr[i].labelOnly);
			arrNode.push(arr[i].labelOnlyBackgroundColor);
			arrNode.push(arr[i].mandatory);

			resArr.push(arrNode);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToFlatArray(arr[i].children, lastList, lastSection, resArr);
		}
	}
}


export function recurseToFlatArrayAdvanced(checklist, arr, lastList, lastSection, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			lastList = {
				id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
				list: arr[i].name,
				listAudio: arr[i].hasOwnProperty("nameAudio") ? arr[i].nameAudio : "",
				listPrint: arr[i].hasOwnProperty("namePrint") ? arr[i].namePrint : "",
				defaultView: arr[i].hasOwnProperty("defaultView") ? arr[i].defaultView : "inherit",
				color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
				ignoreCompletion: arr[i].ignoreCompletion,
				dontPrint: arr[i].suppressPrint
			};
		} else if (arr[i].type === "section") {
			lastSection = {
				id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
				section: arr[i].name,
				sectionAudio: arr[i].hasOwnProperty("nameAudio") ? arr[i].nameAudio : "",
				sectionPrint: arr[i].hasOwnProperty("namePrint") ? arr[i].namePrint : "",
				color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
				iconColor: arr[i].hasOwnProperty("iconColor") ? arr[i].iconColor : "",
				backgroundColor: arr[i].hasOwnProperty("backgroundColor") ? arr[i].backgroundColor : "",
				borderColor: arr[i].hasOwnProperty("borderColor") ? arr[i].borderColor : "",
				ignoreCompletion: arr[i].ignoreCompletion,
				dontPrint: arr[i].suppressPrint
			};
		} else if (arr[i].type.startsWith("item")) {
			let node = {
				list: lastList === "" ? "" : lastList.list,
				section: lastSection === "" ? "" : lastSection.section,
				id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
				type: arr[i].hasOwnProperty("type") ? arr[i].type : "",
				showModeTextAlign: arr[i].hasOwnProperty("showModeTextAlign") ? arr[i].type : "",
				useCustomShowModeStyling: arr[i].hasOwnProperty("useCustomShowModeStyling") ? arr[i].type : false,
				navTitle: arr[i].hasOwnProperty("navTitle") ? arr[i].navTitle : "",
				excludeFromNavigation: arr[i].hasOwnProperty("excludeFromNavigation") ? arr[i].excludeFromNavigation : "",
				relatedItemRefs: arr[i].hasOwnProperty("relatedItemRefs") ? arr[i].relatedItemRefs : "",
				label1: arr[i].label1,
				label2: arr[i].label2,
				comments: arr[i].comments,
				commentsUrl: arr[i].commentsUrl,
				commentsInline: arr[i].hasOwnProperty("commentsInline") ? arr[i].commentsInline : "",
				mandatory: arr[i].mandatory,
				ignoreCompletion: arr[i].ignoreCompletion,
				labelOnly: arr[i].labelOnly,
				labelOnlyBackgroundColor: arr[i].labelOnlyBackgroundColor,
				label1Color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
				label1Audio: arr[i].hasOwnProperty("label1Audio") ? arr[i].label1Audio : "",
				label1Print: arr[i].hasOwnProperty("label1Print") ? arr[i].label1Print : "",
				label2Color: arr[i].hasOwnProperty("label2Color") ? arr[i].label2Color : "",
				label2Audio: arr[i].hasOwnProperty("label2Audio") ? arr[i].label2Audio : "",
				label2Print: arr[i].hasOwnProperty("label2Print") ? arr[i].label2Print : "",

				textInputPlaceholder: arr[i].hasOwnProperty("textInputPlaceholder") ? arr[i].textInputPlaceholder : "",
				textInputDefaultValue: arr[i].hasOwnProperty("textInputDefaultValue") ? arr[i].textInputDefaultValue : "",
				textInputMaxLength: arr[i].hasOwnProperty("textInputMaxLength") ? arr[i].textInputMaxLength : "",
				textInputKeyboardType: arr[i].hasOwnProperty("textInputKeyboardType") ? arr[i].textInputKeyboardType : "",
				textInputKeyboardAutoCapitalize: arr[i].hasOwnProperty("textInputKeyboardAutoCapitalize") ? arr[i].textInputKeyboardAutoCapitalize : "",
				textInputKeyboardAutoCorrect: arr[i].textInputKeyboardAutoCorrect,
				textInputKeyboardReturnKeyType: arr[i].hasOwnProperty("textInputKeyboardReturnKeyType") ? arr[i].textInputKeyboardReturnKeyType : "",
				textInputMaskType: arr[i].hasOwnProperty("textInputMaskType") ? arr[i].textInputMaskType : "",
				textInputCustomMask: arr[i].hasOwnProperty("textInputCustomMask") ? arr[i].textInputCustomMask : "",
				textInputCustomMask2: arr[i].hasOwnProperty("textInputCustomMask2") ? arr[i].textInputCustomMask2 : "",
				textInputCurrencySymbol: arr[i].hasOwnProperty("textInputCurrencySymbol") ? arr[i].textInputCurrencySymbol : "",
				textInputCurrencySeparator: arr[i].hasOwnProperty("textInputCurrencySeparator") ? arr[i].textInputCurrencySeparator : "",
				textInputNumberOfLines: arr[i].hasOwnProperty("textInputNumberOfLines") ? arr[i].textInputNumberOfLines : "",

				pickerItemViewType: arr[i].hasOwnProperty("pickerItemViewType") ? arr[i].pickerItemViewType : "",
				pickerItemsDatasource: arr[i].hasOwnProperty("pickerItemsDatasource") ? _.omit(arr[i].pickerItemsDatasource, ["content"]) : "",
				itemsDatasource: arr[i].hasOwnProperty("itemsDatasource") ? _.omit(arr[i].itemsDatasource, ["content"]) : "",
				associatedContentTemplate: arr[i].hasOwnProperty("associatedContentTemplate") ? arr[i].associatedContentTemplate : "",
				config: arr[i].hasOwnProperty("config") ? arr[i].config : {},
				pickerItemPlaceholder: arr[i].hasOwnProperty("pickerItemPlaceholder") ? arr[i].pickerItemPlaceholder : "",
				pickerItemDefaultValue: arr[i].hasOwnProperty("pickerItemDefaultValue") ? arr[i].pickerItemDefaultValue : "",
				pickerItems: arr[i].hasOwnProperty("pickerItems") ? arr[i].pickerItems : "",
				items: arr[i].hasOwnProperty("items") ? arr[i].items : [],
				pickerAdvanceOnSelect: arr[i].pickerAdvanceOnSelect,
				pickerLinkOnSelect: arr[i].pickerLinkOnSelect,
				pickerLinkActionType: arr[i].hasOwnProperty("pickerLinkActionType") ? arr[i].pickerLinkActionType : "",

				yesNoLinkOnSelect: arr[i].yesNoLinkOnSelect,
				yesNoLinkActionType: arr[i].hasOwnProperty("yesNoLinkActionType") ? arr[i].yesNoLinkActionType : "",
				yesLinkActionType: arr[i].hasOwnProperty("yesLinkActionType") ? arr[i].yesLinkActionType : "",
				noLinkActionType: arr[i].hasOwnProperty("noLinkActionType") ? arr[i].noLinkActionType : "",
				naLinkActionType: arr[i].hasOwnProperty("naLinkActionType") ? arr[i].naLinkActionType : "",
				yesNoYesLinkId: arr[i].hasOwnProperty("yesNoYesLinkId") ? arr[i].yesNoYesLinkId : "",
				yesNoNoLinkId: arr[i].hasOwnProperty("yesNoNoLinkId") ? arr[i].yesNoNoLinkId : "",
				yesNoNaLinkId: arr[i].hasOwnProperty("yesNoNaLinkId") ? arr[i].yesNoNaLinkId : "",
				yesNoShowNa: arr[i].yesNoShowNa,
				yesNoAdvanceOnSelect: arr[i].yesNoAdvanceOnSelect,

				dateTimeType: arr[i].hasOwnProperty("dateTimeType") ? arr[i].dateTimeType : "",
				dateTimeInitialDate: arr[i].hasOwnProperty("dateTimeInitialDate") ? arr[i].dateTimeInitialDate : "",
				dateTimeMinuteInterval: arr[i].hasOwnProperty("dateTimeMinuteInterval") ? arr[i].dateTimeMinuteInterval : "",
				dateTimeAdvanceOnSelect: arr[i].dateTimeAdvanceOnSelect,

				imagePickerAddMediaButton: arr[i].hasOwnProperty("imagePickerAddMediaButton") ? arr[i].imagePickerAddMediaButton : "",
				imagePickerUploadTitle: arr[i].hasOwnProperty("imagePickerUploadTitle") ? arr[i].imagePickerUploadTitle : "",
				imagePickerCaptureMediaTitle: arr[i].hasOwnProperty("imagePickerCaptureMediaTitle") ? arr[i].imagePickerCaptureMediaTitle : "",

				sketchPadBackgroundColor: arr[i].hasOwnProperty("sketchPadBackgroundColor") ? arr[i].sketchPadBackgroundColor : "",
				sketchPadPenColor: arr[i].hasOwnProperty("sketchPadPenColor") ? arr[i].sketchPadPenColor : "",
				sketchPadPenWidth: arr[i].hasOwnProperty("sketchPadPenWidth") ? arr[i].sketchPadPenWidth : "",

				dontPrint: arr[i].suppressPrint,
				startTimer: arr[i].startTimer,
				stopTimer: arr[i].stopTimer,

				linkOnCheck: arr[i].linkOnCheck,
				linkId: arr[i].hasOwnProperty("linkId") ? arr[i].linkId : "",
				linkActionType: arr[i].hasOwnProperty("linkActionType") ? arr[i].linkActionType : ""
			};

			let arrNode = [];

			let tags = JSON.stringify(checklist.tags);

			arrNode.push(formatQuotes((checklist.hasOwnProperty("checklistId") ? checklist.checklistId : "")));
			arrNode.push(formatQuotes(checklist.name));
			arrNode.push(formatQuotes(checklist.nameAudio));
			arrNode.push(formatQuotes(checklist.namePrint));
			arrNode.push(formatQuotes(checklist.description));
			arrNode.push(checklist.suppressAudio);
			arrNode.push(checklist.suppressAudioTitle);
			arrNode.push(checklist.hideTitleInAppBar);
			arrNode.push(checklist.suppressAudioEntityType);
			arrNode.push(checklist.suppressAudioCompletion);
			arrNode.push(checklist.procedureViewType);
			arrNode.push(checklist.trackData);
			arrNode.push(checklist.trackLocation);
			arrNode.push(formatQuotes(checklist.genre));
			arrNode.push(tags);

			if (lastList !== "") {
				arrNode.push(formatQuotes(lastList.id));
				arrNode.push(formatQuotes(lastList.list));
				arrNode.push(formatQuotes(lastList.listAudio));
				arrNode.push(formatQuotes(lastList.listPrint));
				arrNode.push(lastList.defaultView);
				arrNode.push(lastList.color);
				arrNode.push(lastList.ignoreCompletion);
				arrNode.push(lastList.dontPrint);
			} else {
				arrNode.push("");
				arrNode.push(formatQuotes("Sections"));
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
			}


			if (lastSection !== "") {
				arrNode.push(formatQuotes(lastSection.id));
				arrNode.push(formatQuotes(lastSection.section));
				arrNode.push(formatQuotes(lastSection.sectionAudio));
				arrNode.push(formatQuotes(lastSection.sectionPrint));
				arrNode.push(lastSection.color);
				arrNode.push(lastSection.iconColor);
				arrNode.push(lastSection.backgroundColor);
				arrNode.push(lastSection.borderColor);
				arrNode.push(lastSection.ignoreCompletion);
				arrNode.push(lastSection.dontPrint);
			} else {
				arrNode.push("");
				arrNode.push(formatQuotes("Items"));
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
				arrNode.push("");
			}

			arrNode.push(formatQuotes(node.id));
			arrNode.push(formatQuotes(node.type));
			arrNode.push(formatQuotes(node.showModeTextAlign));
			arrNode.push(formatQuotes(node.navTitle));
			arrNode.push(node.excludeFromNavigation);
			arrNode.push(node.relatedItemRefs);
			arrNode.push(formatQuotes(node.label1));
			arrNode.push(formatQuotes(node.label2));
			arrNode.push(formatQuotes(node.comments));
			arrNode.push(formatQuotes(node.commentsUrl));
			arrNode.push(node.commentsInline);
			arrNode.push(node.mandatory);
			arrNode.push(node.ignoreCompletion);
			arrNode.push(node.labelOnly);
			arrNode.push(node.labelOnlyBackgroundColor);
			arrNode.push(node.label1Color);
			arrNode.push(formatQuotes(node.label1Audio));
			arrNode.push(formatQuotes(node.label1Print));
			arrNode.push(node.label2Color);
			arrNode.push(formatQuotes(node.label2Audio));
			arrNode.push(formatQuotes(node.label2Print));

			arrNode.push(formatQuotes(node.textInputPlaceholder));
			arrNode.push(formatQuotes(node.textInputDefaultValue));
			arrNode.push(node.textInputMaxLength);
			arrNode.push(formatQuotes(node.textInputKeyboardType));
			arrNode.push(formatQuotes(node.textInputKeyboardAutoCapitalize));
			arrNode.push(node.textInputKeyboardAutoCorrect);
			arrNode.push(formatQuotes(node.textInputKeyboardReturnKeyType));
			arrNode.push(formatQuotes(node.textInputMaskType));
			arrNode.push(formatQuotes(node.textInputCurrencySymbol));
			arrNode.push(formatQuotes(node.textInputCurrencySeparator));
			arrNode.push(node.textInputNumberOfLines);

			arrNode.push(formatQuotes(node.pickerItemViewType));
			arrNode.push(formatQuotesForObject(node.pickerItemsDatasource));
			arrNode.push(formatQuotesForObject(node.itemsDatasource));
			arrNode.push(formatQuotes(node.associatedContentTemplate));
			arrNode.push(formatQuotesForObject(node.config));
			arrNode.push(formatQuotes(node.pickerItemPlaceholder));
			arrNode.push(formatQuotes(node.pickerItemDefaultValue));
			arrNode.push(formatQuotesForObject(node.pickerItems));
			arrNode.push(formatQuotesForObject(node.items));
			arrNode.push(node.pickerAdvanceOnSelect);
			arrNode.push(node.pickerLinkOnSelect);
			arrNode.push(formatQuotes(node.pickerLinkActionType));

			arrNode.push(node.yesNoLinkOnSelect);
			arrNode.push(formatQuotes(node.yesNoLinkActionType));
			arrNode.push(formatQuotes(node.yesNoYesLinkId));
			arrNode.push(formatQuotes(node.yesNoNoLinkId));
			arrNode.push(formatQuotes(node.yesNoNaLinkId));
			arrNode.push(node.yesNoShowNa);
			arrNode.push(node.yesNoAdvanceOnSelect);

			arrNode.push(formatQuotes(node.dateTimeType));
			arrNode.push(formatQuotes(node.dateTimeInitialDate));
			arrNode.push(formatQuotes(node.dateTimeMinuteInterval));
			arrNode.push(node.dateTimeAdvanceOnSelect);

			arrNode.push(formatQuotes(node.imagePickerAddMediaButton));
			arrNode.push(formatQuotes(node.imagePickerUploadTitle));
			arrNode.push(formatQuotes(node.imagePickerCaptureMediaTitle));

			arrNode.push(node.sketchPadBackgroundColor);
			arrNode.push(node.sketchPadPenColor);
			arrNode.push(node.sketchPadPenWidth);

			arrNode.push(node.dontPrint);
			arrNode.push(node.startTimer);
			arrNode.push(node.stopTimer);

			arrNode.push(node.linkOnCheck);
			arrNode.push(formatQuotes(node.linkId));
			arrNode.push(formatQuotes(node.linkActionType));

			resArr.push(arrNode);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToFlatArrayAdvanced(checklist, arr[i].children, lastList, lastSection, resArr);
		}
	}
}

function formatQuotes(str) {
	return "\"" + str.replace(/"/g, "\"\"") + "\"";
}

function formatQuotesForObject(obj) {
	if (_.isEmpty(obj)) {
		return "";
	} else {
		return "\"" + JSON.stringify(obj).replace(/"/g, "\"\"") + "\"";
	}
}

export function createBlobCsv2(csvData, exportFilename) {
	const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
	FileSaver.saveAs(blob, exportFilename);
}

export function createMarkupForLabel(label) {
	let myRenderer = new marked.Renderer();
	myRenderer.link = function (href, title, text) {
		let external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === "newWindow";
		out = "<a href=\"javascript:void(0)\"";
		if (newWindow) {
			out += " target=\"_blank\"";
		}
		if (title && title !== "newWindow") {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function (href, title, text) {
		return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
	};

	marked.use({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	return { __html: marked.parse(label) };
}

export function createMarkup(label, columns) {
	let myRenderer = new marked.Renderer();

	let width = "240";
	let height = "180";

	if (columns) {
		width = "120";
		height = "90";
	}

	myRenderer.link = function (href, title, text) {
		let external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === "newWindow";
		out = "<a href=\"" + href + "\"";
		if (newWindow) {
			out += " target=\"_blank\"";
		}
		if (title && title !== "newWindow") {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function (href, title, text) {
		return "<img src=\"" + href + "\" width=\"" + width + "\" height=\"" + height + "\" /><br/><div>" + text + "</div>";
	};

	// myRenderer.paragraph = function (text) {
	// 	return text;
	// };

	marked.use({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	return marked.parse(label);
}


export function createMarkupForComments(comments) {
	let myRenderer = new marked.Renderer();
	myRenderer.link = function (href, title, text) {
		let external, newWindow, out;
		external = /^https?:\/\/.+$/.test(href);
		newWindow = external || title === "newWindow";
		out = "<a href=\"" + href + "\"";
		if (newWindow) {
			out += " target=\"_blank\"";
		}
		if (title && title !== "newWindow") {
			out += " title=\"" + title + "\"";
		}
		return out += ">" + text + "</a>";
	};

	myRenderer.image = function (href, title, text) {
		return "<img style=\"max-width: 100%\" src=\"" + href + "\" /><br/><div>" + text + "</div>";
	};

	// myRenderer.paragraph = function (text) {
	// 	return text;
	// };

	marked.setOptions({
		renderer: myRenderer,
		gfm: true,
		tables: true,
		breaks: true,
		pedantic: false,
		sanitize: false,
		smartLists: true,
		smartypants: false
	});

	return marked(comments);
}

export function recurseConvertChecklistToMarkdown(arr, columns) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type.startsWith("item")) {
			arr[i].label1 = createMarkup(arr[i].label1, columns);
			arr[i].label2 = createMarkup(arr[i].label2, columns);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseConvertChecklistToMarkdown(arr[i].children, columns);
		}
	}
}

export function exportContentDumpToJson(activity, convertToCsv = false) {
	const content = exportContentDump(activity);

	if (convertToCsv) {
		console.log("Converting JSON to CSV...");
		const { Parser } = require("json2csv");

		const fields = ["scope", "text", "revisedText"];
		const opts = { fields, withBOM: true };

		try {
			const parser = new Parser(opts);
			const csv = parser.parse(content.content);
			createBlobCsv2(csv, `${activity.name} - ${activity.id}.csv`);
		} catch (err) {
			console.error(err);
		}
	} else {
		const blob = new Blob([JSON.stringify(content, null, 3)], { type: "application/json;charset=utf-8" });
		FileSaver.saveAs(blob, `${activity.name} - ${activity.id}.json`);
	}

}

export function exportContentDump(activity) {
	const activities = [activity];

	const contentRows = [];

	const contentProps = [
		"name",
		"nameAudio",
		"namePrint",
		"itemButtonOkLabel",
		"submitLabel",
		"submitLabelWhenComplete",
		"label",
		"labelAudio",
		"labelPrint",
		"label1",
		"label1Audio",
		"label1Print",
		"label2",
		"label2Audio",
		"label2Print",
		"comments",
		"commentsAudio",
		"commentsPrint",
		"pickerItems"
	];

	activities.forEach(activity => {

		extractContentProps(activity, contentProps, contentRows);

		if (activity.hasOwnProperty("children")) {
			activity.children.forEach(list => {

				extractContentProps(list, contentProps, contentRows);

				if (list.hasOwnProperty("children")) {
					list.children.forEach(section => {

						extractContentProps(section, contentProps, contentRows);

						if (section.hasOwnProperty("children")) {
							section.children.forEach(item => {

								extractContentProps(item, contentProps, contentRows);
							});
						}
					});
				}
			});
		}
	});

	return { "content": contentRows };
}

function addContentRow(scope, text, contentRows) {
	contentRows.push({
		scope,
		text,
		revisedText: ""
	});
}

function extractContentProps(entity, contentProps, contentRows) {
	if (!entity) {
		return;
	}

	let id = "";
	if (entity.type === "checklist") {
		id = entity.id;
	} else {
		id = entity.guid;
	}

	contentProps.forEach(prop => {
		const val = entity[prop];
		if (val && _.isString(val) && val !== "") {
			addContentRow(`${entity.type}.${id}.${prop}`, val, contentRows);
		} else if (_.isArray(val)) {
			val.forEach((entry, idx) => {
				contentProps.forEach(prop2 => {
					const val2 = entry[prop2];
					if (val2 && _.isString(val2) && val2 !== "") {
						addContentRow(`${entity.type}.${id}.${prop}.[${idx}]`, val2, contentRows);
					}
				});
			});
		}
	});
}

export function exportContentDump2(activity) {
	let output = {
		"content": []
	};

	// Get content at top level
	// Include things like Submit button content
	if (activity.name && activity.name !== "") {
		output.content.push({
			scope: activity.type + "." + activity.guid + ".name",
			text: activity.name
		});
	}

	recurseContentDump(activity.children, output);

	return output;
}

export function recurseContentDump(arr, output) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type.startsWith("checklist") || arr[i].type.startsWith("activity") || arr[i].type.startsWith("list") || arr[i].type.startsWith("section")) {
			if (arr[i].name && arr[i].name !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".name",
					text: arr[i].name
				});
			}
		} else if (arr[i].type.startsWith("item")) {

			if (arr[i].label1 && arr[i].label1 !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".label1",
					text: arr[i].label1
				});
			}

			if (arr[i].label1Audio && arr[i].label1Audio !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".label1Audio",
					text: arr[i].label1Audio
				});
			}

			if (arr[i].label2 && arr[i].label2 !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".label2",
					text: arr[i].label2
				});
			}

			if (arr[i].label2Audio && arr[i].label2Audio !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".label2Audio",
					text: arr[i].label2Audio
				});
			}

			if (arr[i].comments && arr[i].comments !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".comments",
					text: arr[i].comments
				});
			}

			if (arr[i].commentsAudio && arr[i].commentsAudio !== "") {
				output.content.push({
					scope: arr[i].type + "." + arr[i].guid + ".commentsAudio",
					text: arr[i].commentsAudio
				});
			}

			if (arr[i].type.startsWith("itemPicker")) {
				for (let i = 0; i < arr[i].pickerItems.length; i++) {
					let pickerItem = arr[i].pickerItems[i];

					output.content.push({
						scope: arr[i].type + "." + arr[i].guid + ".pickerItems.[" + i + "]",
						text: pickerItem[i].label
					});
				}

			}
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseContentDump(arr[i].children, output);
		}
	}
}

export function extractActivityNotes(activity) {
	let notes = [];

	if (activity.hasOwnProperty("mediaAttachmentsUrls") && activity.mediaAttachmentsUrls !== null && activity.mediaAttachmentsUrls.length > 0) {
		notes.push(
			{
				mediaAttachmentsUrls: activity.mediaAttachmentsUrls,
				type: "activity"
			}
		);
	}

	return notes;
}

export function recurseExtractNotes(arr, notes, lastListIndex, lastSectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			lastListIndex = i;
		} else if (arr[i].type === "section") {
			lastSectionIndex = i;
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].hasOwnProperty("notes")) {
				if (arr[i].notes !== "") {
					notes.push(
						{
							listIndex: lastListIndex,
							sectionIndex: lastSectionIndex,
							itemIndex: i,
							label1: arr[i].label1,
							label2: arr[i].label2,
							notes: arr[i].notes,
							type: "item"
						}
					);
				}
			}

			if (arr[i].hasOwnProperty("mediaAttachmentsUrls") && arr[i].mediaAttachmentsUrls !== null && arr[i].mediaAttachmentsUrls.length > 0) {
				notes.push(
					{
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						label1: arr[i].label1,
						label2: arr[i].label2,
						mediaAttachmentsUrls: arr[i].mediaAttachmentsUrls,
						type: "item"
					}
				);
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseExtractNotes(arr[i].children, notes, lastListIndex, lastSectionIndex);
		}
	}
}

// Remove empty values
export function summarizeActivity(activity) {
	removeEmptyValues(activity);
	removeEmptySections(activity);
	removeEmptyLists(activity);

	return activity;
}

export async function aiSummarizeActivityContent() {
	// Get checklist in memory
	let activity = transformFromChecklistEntities(state.get(["tree", "root"]));

	const activitySummary = summarizeActivityContent(activity);


	const chatCompletionsTemplate = {
		messages: [
			{
				role: "system",
				content: "You are a helpful assistant."
			},
			{
				role: "user",
				content: "Can you create a descriptive abstract for the following content as concise as possible in paragraph form and remove all html tagging? " + activitySummary
			}
		],
		model: "gpt-4-turbo"
	}

	const chatResponse = await getChatCompletions(JSON.stringify(chatCompletionsTemplate));

	return chatResponse;
}

export async function aiPrompt(content) {
	const chatCompletionsTemplate = {
		messages: [
			{
				role: "system",
				content: "You are a helpful assistant."
			},
			{
				role: "user",
				content: content
			}
		],
		model: "gpt-4-turbo"
	}

	const chatResponse = await getChatCompletions(JSON.stringify(chatCompletionsTemplate));

	return chatResponse;
}

export async function aiSummarizeContent(content) {
	const chatCompletionsTemplate = {
		messages: [
			{
				role: "system",
				content: "You are a helpful assistant."
			},
			{
				role: "user",
				content: "Can you summarize the following content as concise as possible in paragraph form and remove all html tagging? " + content
			}
		],
		model: "gpt-4-turbo"
	}

	const chatResponse = await getChatCompletions(JSON.stringify(chatCompletionsTemplate));

	return chatResponse;
}

export async function aiSummarizeContentToMaxWords(content, maxWords = 15) {
	const chatCompletionsTemplate = {
		messages: [
			{
				role: "system",
				content: "You are a helpful assistant."
			},
			{
				role: "user",
				content: "Can you create a super brief description of not more than " + maxWords + " words and remove any html tagging if necessary from the following? " + content
			}
		],
		model: "gpt-4-turbo"
	}

	const chatResponse = await getChatCompletions(JSON.stringify(chatCompletionsTemplate));

	return chatResponse;
}

export async function aiSummarizeContentToBullets(content, maxBullets) {
	let maxBulletsText = "";
	if (maxBullets) {
		maxBulletsText = ` with no more than ${maxBullets}`;
	}

	const chatCompletionsTemplate = {
		messages: [
			{
				role: "system",
				content: "You are a helpful assistant."
			},
			{
				role: "user",
				content: "Can you summarize the following content as concise as possible as a series of bullets" + maxBulletsText + "  and remove all html tagging? " + content
			}
		],
		model: "gpt-4-turbo"
	}

	const chatResponse = await getChatCompletions(JSON.stringify(chatCompletionsTemplate));

	return chatResponse;
}

export function summarizeActivityContent(activity) {
	activity = normalizeActivity(activity);

	const summaryItems = [];
	activity.children.forEach((list, idx) => {
		// const listName = list.name;
		const listIndex = idx;

		list.children.forEach((section, idx) => {
			// const sectionName = section.name;
			const sectionIndex = idx;

			section.children.forEach((item, idx) => {
				const itemIndex = idx;

				if ((item.label1 !== "") && (item.label2 === "")) {
					summaryItems.push("<p>" + item.label1 + "</p>");
				} else if ((item.label1 !== "") && (item.label2 !== "")) {

					summaryItems.push("<p>" + item.label1 + "<br />" + item.label2 + "</p>");
				}

			})
		})
	});

	return summaryItems.join("");
}

function removeEmptyValues(checklist) {
	var lists = checklist.children;
	removeEmptyValuesRecurse(lists);
}

function removeEmptyValuesRecurse(arr) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			if (arr[i].hasOwnProperty("children")) {
				removeEmptyValuesRecurse(arr[i].children);
			}
		} else if (arr[i].type === "section") {
			if (arr[i].hasOwnProperty("children")) {
				let sectionChildren = arr[i].children.filter(function (e) {
					/*if (e.hasOwnProperty("mediaAttachmentsUrls") && e.mediaAttachmentsUrls && e.mediaAttachmentsUrls.length > 0) {
						return true;
					} else if (e.hasOwnProperty("sketchPadImage") && e.sketchPadImage && e.sketchPadImage.startsWith("data:")) {
						return true;
					} else */if (e.hasOwnProperty("checked") && e.checked && e.hasOwnProperty("value")) {
						if (typeof e.value === "boolean") {
							return true;
						} else if (Array.isArray(e.value) && e.value.length === 0) {
							return false;
						} else if (Array.isArray(e.value) && e.value.length > 0) {
							return true;
						} else if (e.value && e.value !== null && e.value !== "") {
							if (e.type === "itemYesNo") {
								if (e.value === "no") {
									return false;
								}
							}

							return true;
						}
					}

					return false;
				});

				arr[i].children = sectionChildren;
			}
		}
	}
}

function removeEmptySections(checklist) {
	var lists = checklist.children;
	removeEmptySectionsRecurse(lists);
}

function removeEmptySectionsRecurse(arr) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			if (arr[i].hasOwnProperty("children")) {
				let listChildren = arr[i].children.filter(function (e) {
					if (e.hasOwnProperty("children")) {
						if (e.children.length > 0) {
							return true;
						}
					}

					return false;
				});

				arr[i].children = listChildren;
			}
		}
	}
}

function removeEmptyLists(checklist) {
	if (checklist.hasOwnProperty("children")) {
		let checklistChildren = checklist.children.filter(function (e) {
			if (e.hasOwnProperty("children")) {
				if (e.children.length > 0) {
					return true;
				}
			}

			return false;
		});

		checklist.children = checklistChildren;
	}
}

export function getImageSize(uri) {
	return new Promise(async (resolve, reject) => {
		try {
			var img = document.createElement('img');

			img.src = uri;

			img.onload = function (e) { resolve({ width: e.currentTarget.naturalWidth, height: e.currentTarget.naturalHeight }) }
		} catch (err) {
			reject(err);
		}
	});
}

export function getIconForType(type, entity = null) {
	let icon = "";

	state.get(["itemTypes"]).forEach(itemType => {
		if (itemType.type === type || (itemType.type === "itemLabelOnly" && entity !== null && entity.labelOnly)) {
			icon = itemType.icon;
		}
	});

	return icon;
}

export function getGenreIconForType(tree, type) {
	let genres = tree.get(["genres"]);

	for (let i = 0; i < genres.length; i++) {
		let genre = genres[i];
		if (type === genre.value) {
			return genre.icon;
		}
	}

	return "check-square";
}

export function getAdditionalInfoComponent(entity) {
	let additionalInfo = null;
	let additionalInfoComponents = [];

	try {
		if (entity.id && !_.isEmpty(entity.id)) {
			additionalInfoComponents.push(<div className="id-info" key={`id-view-${entity.id}`} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} title={`ID: ${entity.id} (Can be used for conditions and calculations)`}><b style={{ marginRight: "3px" }}>ID:</b>{entity.id}</div>);
		}

		if (entity.conditionalVisible && !_.isEmpty(entity.conditionalVisible)) {
			additionalInfoComponents.push(<div className="cond-visible-info" key={`cond-visible-view-${entity.id}`} title="Conditional Visibility"><FontAwesomeIcon icon="eye" /></div>);
		}

		if (entity.valueTrigger && !_.isEmpty(entity.valueTrigger)) {
			additionalInfoComponents.push(<div className="value-trigger-info" key={`value-trigger-view-${entity.id}`} title="Value Trigger"><FontAwesomeIcon icon="code" /></div>);
		}

		if (entity.functions && !_.isEmpty(entity.functions)) {
			additionalInfoComponents.push(<div className="value-trigger-info" key={`functions-view-${entity.id}`} title="Functions"><FontAwesomeIcon icon="code" /></div>);
		}

		if (entity.authorProperties && !_.isEmpty(entity.authorProperties) && Object.keys(JSON.parse(entity.authorProperties)).length > 0) {
			additionalInfoComponents.push(<div className="author-properties-info" key={`author-properties-view-${entity.id}`} title="Author Properties"><FontAwesomeIcon icon="file-user" /></div>);
		}

		if (entity.groupNames && !_.isEmpty(entity.groupNames)) {
			const groupsNames = _.join(entity.groupNames, ", ");
			additionalInfoComponents.push(<div className="group-info" key={`group-view-${entity.id}`} title={`Member of groups: ${groupsNames}`}><b style={{ marginRight: "3px" }}>Groups:</b>{groupsNames}</div>);
		}

		if (entity.filterTags && !_.isEmpty(entity.filterTags)) {
			const filterTags = _.join(entity.filterTags, ", ");
			additionalInfoComponents.push(<div className="filter-tags-info" key={`filter-tags-view-${entity.id}`} title={`Filter Tags: ${filterTags}`}><b style={{ marginRight: "3px" }}>Filter Tags:</b>{filterTags}</div>);
		}
	} catch (err) {

	}

	if (!_.isEmpty(additionalInfoComponents)) {
		additionalInfo = (
			<div className="additional-info">
				{additionalInfoComponents}
			</div>
		);
	}

	return additionalInfo;
}

export function convertValueToMoney(value, currencySymbol, currencySeparator) {
	if (_.isUndefined(value)) { return value; }
	value = value.split(currencySymbol).join("");
	value = value.replace(/\D/g, "");
	value = currencySymbol + value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1" + currencySeparator);
	return value;
}

export function convertValueToPhoneNumber(value) {
	if (_.isUndefined(value)) { return value; }
	value = value.replace(/\D/g, "");
	value = value.replace(/^(\d\d\d)(\d)/g, "($1) $2");
	value = value.replace(/(\d{3})(\d)/, "$1-$2");
	return value;
}

export function getExtension(path) {
	const pathParts = path.split(".");
	if (pathParts.length === 1) {
		return "";
	} else {
		return pathParts[pathParts.length - 1].toLowerCase();
	}
}

export function getExtensionFromUrl(url) {
	let parts = url.split("?");
	return getExtension(parts[0]);
}

export function isVideo(path) {
	if (isVideoMov(path) || isVideoMp4(path)) {
		return true;
	} else {
		return false;
	}
}

export function isVideoMov(path) {
	const ext = getExtension(path);
	if (ext === "mov") {
		return true;
	} else {
		return false;
	}
}

export function isVideoMp4(path) {
	const ext = getExtension(path);
	if (ext === "mp4") {
		return true;
	} else {
		return false;
	}
}

export function convertGoogleMapPlaceToValue(place) {

	let value = {};

	let addressComponents = {};

	_.each(place.address_components, (addressComponent) => {
		addressComponents[addressComponent.types[0]] = addressComponent.long_name;
	});

	value.address_components = addressComponents;

	if (place.formatted_address) { value.address = place.formatted_address; }
	if (place.formatted_phone_number) { value.phoneNumber = place.formatted_phome_number; }

	let lngLat = place.geometry.location;

	value.longitude = lngLat.lng();
	value.latitude = lngLat.lat();

	let viewport = place.geometry.viewport;
	value.south = viewport.south;
	value.west = viewport.west;
	value.north = viewport.north;
	value.east = viewport.east;

	if (place.icon) { value.icon = place.icon; }
	if (place.id) { value.id = place.id; }
	if (place.international_phone_number) { place.internationalPhoneNumber = place.international_phone_number; }
	if (place.name) { value.name = place.name; }
	if (place.place_id) { value.placeID = place.place_id; }
	if (place.plus_code) { value.plusCode = place.plus_code; }
	if (place.price_level) { value.priceLevel = place.price_level; }
	if (place.rating) { value.rating = place.rating; }
	if (place.reference) { value.reference = place.reference; }
	if (place.scope) { value.scope = place.scope; }
	if (place.types) { value.types = place.types; }
	if (place.url) { value.url = place.url; }
	if (place.utc_offset) { value.utcOffset = place.utc_offset; }
	if (place.vicinity) { value.vicinity = place.vicinity; }
	if (place.website) { value.website = place.website; }

	return value;
}

export function getLocationValueString(location) {
	let value = "";
	if (!_.isUndefined(location) && location !== null) {
		if (location.hasOwnProperty("name") && location.hasOwnProperty("address")) {
			value = location.name + "<br/>" + location.address;
		} else if (location.hasOwnProperty("address")) {
			value = location.address;
		} else if (location.hasOwnProperty("name")) {
			value = location.name;
		} else if (location.hasOwnProperty("latitude")) {
			value = "Latitude: " + location.latitude + "<br/>Longitude: " + location.longitude;
		} else if (location !== "") {
			//value = stateValue + "";
		} else {
			value = "No location";
		}
	}

	return value;
}

export function getChecklistItemHistoryPath(listIndex, sectionIndex, itemIndex) {
	return [listIndex, sectionIndex, itemIndex].join(",");
}

export function mcMoment(item, initial = null) {
	if (hasDateTimeUtc(item)) {
		if (initial !== null) {
			return moment.utc(initial);
		} else {
			return moment().utc();
		}
	} else {
		if (initial !== null) {
			return moment(initial);
		} else {
			return moment();
		}
	}
}

export function hasDateTimeUtc(item) {
	return item.hasOwnProperty("dateTimeType") && item.dateTimeType === "utc";
}

export function captureQueryString() {
	const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
	const _queryString = {};
	_.forEach(queryString, (value, key) => {
		_queryString[key] = castit(value);
	});
	console.log("CAPTURED QUERY STRING", _queryString);
	state.set(["queryString"], _queryString);
}

export function getQueryStringParam(key) {
	return state.get(["queryString", key]);
}


export function getFileTypeIcon(ext) {
	let result = "file-text";
	switch (_.toLower(ext)) {
		case "jpg":
		case "jpeg":
		case "gif":
		case "tiff":
		case "png":
		case "svg": {
			result = ["far", "file-image"];
			break;
		}
		case "hbs":
		case "html":
		case "xml":
		case "json": {
			result = ["far", "file-code"];
			break;
		}
		case "mp4":
		case "mpeg": {
			result = ["far", "file-video"];
			break;
		}
		case "mp3":
		case "wav": {
			result = ["far", "file-audio"];
			break;
		}
		case "xlsx":
		case "xls": {
			result = ["far", "file-excel"];
			break;
		}
		case "doc":
		case "docx": {
			result = ["far", "file-word"];
			break;
		}
		case "pdf":
		case "tex": {
			result = ["far", "file-pdf"];
			break;
		}
		default: {
			result = ["far", "file"];
		}
	}
	return result;
}

export function getNodeType(node) {
	return node.children ? node.root ? "root" : "folder" : "file";
}

export function validateUrl(value) {

	const _value = value.replace(/ /g, "%20");

	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(_value);
}

export function handleOpenInNewTab(url) {
	let win = window.open(url, "_blank");
	win.focus();
}
export function isVisible(node, skipResolve = false) {
	let obj = skipResolve ? node : getObjectByGuid(node.guid, node);
	return !obj.hasOwnProperty("visible") || obj.visible;
}

export function getActivityLastUpdatedDateTime(activity) {
	return activity.history.length > 0 ? Math.round(moment.utc(activity.history[activity.history.length - 1].timestamp).valueOf() / 1000) : 0;
}

export function getActivityPath(identityId, activity) {
	let lastTimestamp = (activity.history[0].timestamp).replace(" ", "T");
	let fileName = identityId + "/" + activity.id + "/" + lastTimestamp + "_" + activity.instanceId + ".json";
	return fileName;
}

export function createActivitySessionListItem(activity) {
	let session = {
		"objectID": activity.instanceId,
		"id": activity.id,
		"checklistId": activity.checklistId,
		"instanceId": activity.instanceId,
		"displayName": activity.displayName,
		"name": activity.name,
		"description": activity.description,
		"items": [],
		"genre": activity.genre,
		"tags": activity.tags,
		"url": getActivityPath(state.get(["user", "identityId"]), activity),
		"createdDateTime": Math.round(moment.utc(activity.history[0].timestamp).valueOf() / 1000),
		"lastUpdatedDateTime": getActivityLastUpdatedDateTime(activity)
	};
	return session;
}

export function findNodeById(tree, id) {
	// const cacheKey = `id:${id}`;
	// if (this.pathCache[cacheKey]) {
	// 	const path = this.pathCache[cacheKey];
	// 	return {
	// 		node: tree.get(path),
	// 		path
	// 	}
	// } else {
	findNode = null;
	let lists = tree.get(PlayerPaths.CHECKLIST_LISTS);
	findNodeByIdRecurse(id, lists, tree, 0, 0);
	// if (this.findNode && !_.isEmpty(this.findNode.path)) {
	// 	// Cache it
	// 	this.pathCache[cacheKey] = this.findNode.path;
	// }
	//}	
}


export function findNodeByIdRecurse(id, arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			if (arr[i].id !== null && arr[i].id === id && !_.isUndefined(arr[i].id)) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_LIST(i)),
					path: PlayerPaths.CHECKLIST_LIST(i)
				};
			}
			listIndex = i;
		} else if (arr[i].type === "section") {
			if (arr[i].id !== null && arr[i].id === id && !_.isUndefined(arr[i].id)) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_SECTION(listIndex, i)),
					path: PlayerPaths.CHECKLIST_SECTION(listIndex, i)
				};
			}
			sectionIndex = i;
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].id !== null && arr[i].id === id && !_.isUndefined(arr[i].id)) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_ITEM(listIndex, sectionIndex, i)),
					path: PlayerPaths.CHECKLIST_ITEM(listIndex, sectionIndex, i)
				};
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			findNodeByIdRecurse(id, arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function findNodeByGuid(tree, guid) {
	const cacheKey = `guid:${guid}`;
	if (pathCache[cacheKey]) {
		const path = pathCache[cacheKey];
		return {
			node: tree.get(path),
			path
		};
	} else {
		findNode = null;
		let lists = tree.get(PlayerPaths.CHECKLIST_LISTS);
		findNodeByGuidRecurse(guid, lists, tree, 0, 0);
		if (findNode && !_.isEmpty(findNode.path)) {
			// Cache it
			pathCache[cacheKey] = findNode.path;
		}
	}

}

export function findNodeByGuidRecurse(guid, arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			if (arr[i].guid !== null && arr[i].guid === guid) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_LIST(i)),
					path: PlayerPaths.CHECKLIST_LIST(i)
				};
			}
			listIndex = i;
		} else if (arr[i].type === "section") {
			if (arr[i].guid !== null && arr[i].guid === guid) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_SECTION(listIndex, i)),
					path: PlayerPaths.CHECKLIST_SECTION(listIndex, i)
				};
			}
			sectionIndex = i;
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].guid !== null && arr[i].guid === guid) {
				findNode = {
					node: tree.get(PlayerPaths.CHECKLIST_ITEM(listIndex, sectionIndex, i)),
					path: PlayerPaths.CHECKLIST_ITEM(listIndex, sectionIndex, i)
				};
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			findNodeByGuidRecurse(guid, arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function getLastFoundNode() {
	//TODO: put in baobab
	return findNode;
}

//TODO: Put these in own utils

export function initializeIdsAndVariableState(tree) {
	if (!tree.exists(PlayerPaths.CHECKLIST_VARIABLES)) {
		tree.set(PlayerPaths.CHECKLIST_VARIABLES, {});
	}
	if (!tree.exists(PlayerPaths.CHECKLIST_VARIABLE_GROUPS)) {
		tree.set(PlayerPaths.CHECKLIST_VARIABLE_GROUPS, {});
	}
	if (!tree.exists(PlayerPaths.CHECKLIST_OVERRIDES)) {
		tree.set(PlayerPaths.CHECKLIST_OVERRIDES, {});
	}
	let lists = tree.get(PlayerPaths.CHECKLIST_LISTS);
	initializeIdsAndVariableStateRecurse(lists, tree, 0, 0);
}

export function initializeIdsAndVariableStateRecurse(arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			listIndex = i;
			if (!tree.get(PlayerPaths.CHECKLIST_LIST_PROPERTY(listIndex, "guid"))) {
				const listCursor = tree.select(PlayerPaths.CHECKLIST_LIST(listIndex));
				const currentId = listCursor.get(["id"]);
				const currentGuid = listCursor.get(["guid"]);
				const targetGuid = currentGuid && !_.isEmpty(_.trim(currentGuid)) ? currentGuid : currentId && !_.isEmpty(_.trim(currentId)) ? currentId : generateUUID();
				if (!tree.exists(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid))) {
					tree.set(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid), {});
				}
				tree.set(PlayerPaths.CHECKLIST_LIST_PROPERTY(listIndex, "guid"), targetGuid);
			}
		} else if (arr[i].type === "section") {
			sectionIndex = i;
			if (!tree.get(PlayerPaths.CHECKLIST_SECTION_PROPERTY(listIndex, sectionIndex, "guid"))) {
				const sectionCursor = tree.select(PlayerPaths.CHECKLIST_SECTION(listIndex, sectionIndex));
				const currentId = sectionCursor.get(["id"]);
				const currentGuid = sectionCursor.get(["guid"]);
				const targetGuid = currentGuid && !_.isEmpty(_.trim(currentGuid)) ? currentGuid : currentId && !_.isEmpty(_.trim(currentId)) ? currentId : generateUUID();
				if (!tree.exists(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid))) {
					tree.set(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid), {});
				}
				tree.set(PlayerPaths.CHECKLIST_SECTION_PROPERTY(listIndex, sectionIndex, "guid"), targetGuid);
			}
		} else if (arr[i].type.startsWith("item")) {
			// if (!tree.get(PlayerPaths.CHECKLIST_ITEM_PROPERTY(listIndex, sectionIndex, i, "guid"))) {
			const itemCursor = tree.select(PlayerPaths.CHECKLIST_ITEM(listIndex, sectionIndex, i));
			const currentId = itemCursor.get(["id"]);
			const currentGuid = itemCursor.get(["guid"]);
			const targetGuid = currentGuid && !_.isEmpty(_.trim(currentGuid)) ? currentGuid : currentId && !_.isEmpty(_.trim(currentId)) ? currentId : generateUUID();
			if (currentId && !tree.exists(PlayerPaths.CHECKLIST_VARIABLE(currentId))) {
				let variableValue = null;
				if (tree.exists(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid))) {
					variableValue = tree.get(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid));
				} else if (tree.exists(PlayerPaths.CHECKLIST_ITEM_PROPERTY(listIndex, sectionIndex, i, "value"))) {
					variableValue = tree.get(PlayerPaths.CHECKLIST_ITEM_PROPERTY(listIndex, sectionIndex, i, "value"));
				}
				const defaultVariable = {
					id: currentId,
					value: variableValue ? variableValue : ""
				};
				tree.set(PlayerPaths.CHECKLIST_VARIABLE(currentId), defaultVariable);
				const groupNames = itemCursor.get(["groupNames"]);
				if (groupNames && !_.isEmpty(groupNames)) {
					groupNames.forEach(groupName => {
						if (!tree.exists(PlayerPaths.CHECKLIST_VARIABLE_GROUP(groupName))) {
							tree.set(PlayerPaths.CHECKLIST_VARIABLE_GROUP(groupName), {
								[currentId]: true
							});
						} else {
							tree.set(PlayerPaths.CHECKLIST_VARIABLE_GROUP_VARIABLE(groupName, currentId), true);
						}
					});
				}
			}

			if (!tree.exists(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid))) {
				if (currentId) {
					// This will leave a pointer to the actual location of the overrie
					tree.set(PlayerPaths.CHECKLIST_OVERRIDE(currentId), { targetGuid });
				}
				tree.set(PlayerPaths.CHECKLIST_OVERRIDE(targetGuid), {});
			}
			tree.set(PlayerPaths.CHECKLIST_ITEM_PROPERTY(listIndex, sectionIndex, i, "guid"), targetGuid);
			// }			
		}

		if (arr[i].hasOwnProperty("children")) {
			initializeIdsAndVariableStateRecurse(arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function populateIds(tree) {
	let lists = tree.get(PlayerPaths.CHECKLIST_PROPERTY("children"));
	populateIdsRecurse(lists, tree, 0, 0);
}

export function populateIdsRecurse(arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			if (arr[i].id !== null && arr[i].id !== "" && !_.isUndefined(arr[i].id)) {
				tree.set(["idMap", arr[i].id], { listIndex: i, sectionIndex: 0, itemIndex: 0 });
			}
			listIndex = i;
		} else if (arr[i].type === "section") {
			if (arr[i].id !== null && arr[i].id !== "" && !_.isUndefined(arr[i].id)) {
				tree.set(["idMap", arr[i].id], { listIndex: listIndex, sectionIndex: i, itemIndex: 0 });
			}
			sectionIndex = i;
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].id !== null && arr[i].id !== "" && !_.isUndefined(arr[i].id)) {
				tree.set(["idMap", arr[i].id], { listIndex: listIndex, sectionIndex: sectionIndex, itemIndex: i });
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			populateIdsRecurse(arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function buildGroups(tree) {
	// Should clear?
	tree.set(["groups"], {});
	let lists = tree.get(PlayerPaths.CHECKLIST_PROPERTY("children"));
	buildGroupsRecurse(lists, tree, 0, 0);
}

export function buildGroupsRecurse(arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			listIndex = i;
			let groupNames = tree.get(PlayerPaths.CHECKLIST_LIST_PROPERTY(listIndex, "groupNames"));
			addGroup(tree, groupNames, arr[i]);
		} else if (arr[i].type === "section") {
			sectionIndex = i;
			let groupNames = tree.get(PlayerPaths.CHECKLIST_SECTION_PROPERTY(listIndex, sectionIndex, "groupNames"));
			addGroup(tree, groupNames, arr[i]);
		} else if (arr[i].type.startsWith("item")) {
			let groupNames = tree.get(PlayerPaths.CHECKLIST_ITEM_PROPERTY(listIndex, sectionIndex, i, "groupNames"));
			addGroup(tree, groupNames, arr[i]);
		}

		if (arr[i].hasOwnProperty("children")) {
			buildGroupsRecurse(arr[i].children, tree, listIndex, sectionIndex);
		}
	}
}

export function addGroup(tree, groupNames, node) {
	if (groupNames && groupNames.length > 0) {
		for (let i = 0; i < groupNames.length; i++) {
			let groupName = groupNames[i];
			let group = tree.get(["groups", groupName]);
			if (group) {
				group.push(node);
			} else {
				group = [node];
			}
			tree.set(["groups", groupName], group);
		}
	}
}

export function buildFilters(tree, checklist) {
	if (checklist.hasOwnProperty("children")) {
		let lists = checklist.children;
		if (lists.length) {
			buildFiltersRecurse(lists, tree, 0, 0);
		}
	}
}

export function buildFiltersRecurse(arr, tree, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			addFilterBuild(tree, arr[i]);

			listIndex = i;

			if (arr[i].hasOwnProperty("children")) {
				buildFiltersRecurse(arr[i].children, tree, listIndex, sectionIndex);
			}
		} else if (arr[i].type === "section") {
			addFilterBuild(tree, arr[i]);

			sectionIndex = i;

			if (arr[i].hasOwnProperty("children")) {
				buildFiltersRecurse(arr[i].children, tree, listIndex, sectionIndex);
			}
		} else if (arr[i].type.startsWith("item")) {
			addFilterBuild(tree, arr[i]);
		}
	}
}

export function addFilterBuild(tree, node) {
	if (node.hasOwnProperty("filterTags") && node.filterTags.length > 0) {
		let filters = tree.get(["filters"]);
		for (let i = 0; i < node.filterTags.length; i++) {
			let inFilter = false;
			for (let j = 0; j < filters.length; j++) {
				if (filters[j].name === node.filterTags[i]) {
					inFilter = true;
				}
			}

			if (!inFilter) {
				filters.push({
					name: node.filterTags[i],
					value: true
				});
			}
		}
		tree.set(["filters"], filters);
	}
}

export function applyFilter(tree, filter, retVal) {
	retVal.dstActivity = _.cloneDeep(tree.get(PlayerPaths.CHECKLIST));
	delete retVal.dstActivity.children;

	let lists = tree.get(PlayerPaths.CHECKLIST_PROPERTY("children"));
	if (lists.length) {
		applyFilterRecurse(lists, tree, 0, null, 0, null, filter, retVal);
	}
}

export function applyFilterRecurse(arr, tree, listIndex, dstList, sectionIndex, dstSection, filter, retVal) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			let foundList = inFilter(tree, arr[i], filter);

			if (foundList) {
				if (!retVal.dstActivity.hasOwnProperty("children")) {
					retVal.dstActivity.children = [];
				}

				let dstList = _.cloneDeep(arr[i]);
				delete dstList.children;

				retVal.dstActivity.children.push(dstList);

				listIndex = i;

				if (arr[i].hasOwnProperty("children")) {
					applyFilterRecurse(arr[i].children, tree, listIndex, dstList, sectionIndex, dstSection, filter, retVal);
				}
			}
		} else if (arr[i].type === "section") {
			let foundSection = inFilter(tree, arr[i], filter);

			if (foundSection) {
				if (!dstList.hasOwnProperty("children")) {
					dstList.children = [];
				}

				let dstSection = _.cloneDeep(arr[i]);
				delete dstSection.children;

				dstList.children.push(dstSection);

				sectionIndex = i;

				if (arr[i].hasOwnProperty("children")) {
					applyFilterRecurse(arr[i].children, tree, listIndex, dstList, sectionIndex, dstSection, filter, retVal);
				}
			}
		} else if (arr[i].type.startsWith("item")) {
			let foundItem = inFilter(tree, arr[i], filter);

			if (foundItem) {
				if (!dstSection.hasOwnProperty("children")) {
					dstSection.children = [];
				}

				let dstItem = _.cloneDeep(arr[i]);

				dstSection.children.push(dstItem);
			}
		}
	}
}

export function inFilter(tree, node, filter) {
	let found = false;

	if (node.hasOwnProperty("filterTags") && node.filterTags.length > 0) {
		for (let i = 0; i < node.filterTags.length; i++) {
			// If in filter
			for (let j = 0; j < filter.length; j++) {
				if (filter[j].name === node.filterTags[i] && filter[j].value) {
					found = true;
				}
			}
		}
	} else {
		found = true;
	}

	return found;
}


export function clearPathCaches() {
	pathCache = {};
}

export function openUrl(e, url) {
	if (isMobile()) {
		e.preventDefault();
		postMessage({
			external_url_open: url
		});
	} else {
		handleOpenInNewTab(url);
	}
}

export function isValidItem(tree, listIndex, sectionIndex, itemIndex) {
	let currentChecklist = tree.get(PlayerPaths.CHECKLIST);

	if (currentChecklist.hasOwnProperty("children")) {
		if (listIndex < currentChecklist.children.length) {
			if (currentChecklist.children[listIndex].hasOwnProperty("children")) {
				if (sectionIndex < currentChecklist.children[listIndex].children.length) {
					if (currentChecklist.children[listIndex].children[sectionIndex].hasOwnProperty("children")) {
						if (itemIndex < currentChecklist.children[listIndex].children[sectionIndex].children.length) {
							return true;
						}
					}
				}
			}
		}
	}

	return false;
}

export function itemHasVideo(item) {
	return item.hasOwnProperty("video") && item.video !== null && item.video !== "undefined" && item.video !== "";
}

export function itemHasPdf(item) {
	return item.hasOwnProperty("pdf") && item.pdf !== null && item.pdf !== "undefined" && item.pdf !== "";
}

export function itemHasThreeD(item) {
	return item.hasOwnProperty("threeD") && item.threeD !== null && item.threeD !== "undefined" && item.threeD !== "";
}

export function itemHasImage(item) {
	return item.hasOwnProperty("image") && item.image !== null && item.image !== "undefined" && item.image !== "";
}

export function isMediaFile(file) {
	const result = file && file.type && (file.type.indexOf("image/") !== -1 || file.type.indexOf("video/") !== -1 || file.type.indexOf("audio/") !== -1);
	console.log("Media file?", file, result);
	return result;
}

export function dataURItoBlob(dataURI, type) {
	let binary = atob(dataURI.split(",")[1]);
	let array = [];
	for (let i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], { type: type });
}

export function fixFileNameForS3(fileName) {
	console.log("Fix fileName", fileName);
	if (fileName && _.isString(fileName)) {
		const parts = fileName.split(".");

		const newParts = [];
		_.each(parts, part => {
			newParts.push(part.replace(/\W+/g, "-"));
		});
		console.log("Fixed", newParts);
		return newParts.join(".");
	} else {
		return fileName;
	}
}

export function isBlank(item) {
	return _.isUndefined(item) || item === null || item === "";
}

export function getMimeTypeFromPath(path) {
	return getMimeTypeFromExtension(getExtension(path));
}

export function getMimeTypeFromExtension(ext) {
	switch (ext) {
		case "mov":
			return "video/quicktime";
		case "mp4":
			return "video/mp4";
		case "jpg":
			return "image/jpeg";
		case "png":
			return "image/png";
		case "gif":
			return "image/gif";
		default:
			return "";
	}
}

function resolveStyles(styles) {
	let result = styles;
	if (result && result.hasOwnProperty("default")) {
		const platformOverridesProp = "webOverride";
		if (result.hasOwnProperty("web")) {
			result = result.web;
		} else if (result.hasOwnProperty(platformOverridesProp)) {
			result = _.assign({}, result.default, result[platformOverridesProp]);
		} else {
			result = result.default;
		}

	}
	return result;
}

export function getStyleAttribute(entity, propNameStyle = "style", propNameClass = "class", defaultResult = {}) {
	// JJB Put this in until fixed
	if (!entity) {
		return {};
	}

	let result = defaultResult;
	let classStyles = {};
	const globalStylesheet = state.get(["currentChecklist", "stylesheet"]);
	const classNames = entity[propNameClass];
	if (classNames && globalStylesheet) {

		const classNamesSplit = classNames.replace(/\s+/g, " ").split(" ");
		const styles = [];

		classNamesSplit.forEach(className => {
			if (globalStylesheet.hasOwnProperty(className)) {
				styles.push(resolveStyles(globalStylesheet[className]));
			}
		});

		if (styles.length > 0) {
			classStyles = _.assign({}, ...styles);
		}

	}

	if (entity && entity.hasOwnProperty(propNameStyle)) {
		const style = entity[propNameStyle];
		try {
			result = style ? _.isPlainObject(style) ? style : JSON.parse(style) : defaultResult;
		} catch (e) {
			// console.warn(e);
		}
		if (!_.isPlainObject(result) || _.isEmpty(result)) {
			result = defaultResult;
		}

		result = resolveStyles(result);
	}
	return _.assign({}, classStyles, result);
}

/**
 * Convert string to boolean only if it is 'true' or 'false'
 */
export function convertStringToBooleanOnlyIfTrueOrFalse(str) {
	let retValue;
	if (str === "true") {
		retValue = true;
	} else if (str === "false") {
		retValue = false;
	} else {
		retValue = str;
	}
	return retValue;
}

export function filterLearningActivities(activities) {
	if (activities && _.isArray(activities)) {
		activities = _.filter(activities, (obj) => { return !obj.hasOwnProperty("category") || obj.category !== "learning"; });
	}
	return activities;
}

export function getUtcTimestamp() {
	// ISO 8601
	return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
}


function isEditorChecklistOfType(checklist, type) {
	if (checklist.children.length > 0 && checklist.children[0].entity.type.startsWith(type)) {
		return true;
	} else {
		return false;
	}
}

export function isEditorChecklistLists(checklist) {
	return isEditorChecklistOfType(checklist, "list");
}

export function isEditorChecklistSections(checklist) {
	return isEditorChecklistOfType(checklist, "section");
}

export function isEditorChecklistItems(checklist) {
	return isEditorChecklistOfType(checklist, "item");
}

export function copyToClipboard(text) {
	copy(text);
}

export async function downloadFileFromUrl(url, fileName) {
	let response = await request.get(url).responseType("blob");
	FileSaver.saveAs(response.body, fileName);
}

export function getSortedPollyVoices() {
	return _.sortBy(AppConstants.audio.tts.pollyVoices, ["LanguageName", "Name"]);
}

export function convertStringToDriveRootPath(str) {
	if (_.isString(str)) {
		return _.replace(_.replace(str, /\W+/g, "-"), /-+/g, "-").toLowerCase();
	} else {
		return str;
	}
}


export function convertStringToDriveFileName(str) {
	if (_.isString(str)) {
		return _.replace(_.replace(str, /\W+/g, "-"), /-+/g, "-").toLowerCase();
	} else {
		return str;
	}
}
export function generatePastedImageFileName(extension = "png") {
	//for some reason browser always bring back image.png regardless if file or pasted image
	let name = "image-" + generateFileNameTimestamp() + "." + extension;
	return name;
}

export function generateFileNameTimestamp() {
	return (new Date()).toISOString().split('.')[0].replace(/:/g, "");
}

export function getFileNameExtension(fileName) {
	let parts = fileName.split(".");
	let ext = parts[parts.length - 1];
	return ext;
}

export function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]';
};

export function hasMediaAttachments(notes) {
	for (let i = 0; i < notes.length; i++) {
		const note = notes[i];
		if (note.hasOwnProperty("mediaAttachmentsUrls") && note.mediaAttachmentsUrls.length > 0) {
			return true;
		}
	}

	return false;
}

export function getFirstMediaAttachment(notes) {
	if (notes.length > 0) {
		for (let i = 0; i < notes.length; i++) {
			const noteItem = notes[i];

			if (noteItem.hasOwnProperty("mediaAttachmentsUrls")) {
				if (noteItem.hasOwnProperty("mediaAttachmentsUrls") && noteItem.mediaAttachmentsUrls !== null && noteItem.mediaAttachmentsUrls.length > 0) {
					for (let i = 0; i < noteItem.mediaAttachmentsUrls.length; i++) {
						const item = noteItem.mediaAttachmentsUrls[i];

						if (!isObject(item)) {
							// Need to loop through all values
							let imgUrlParts = item.split(".");
							let ext = imgUrlParts[imgUrlParts.length - 1];

							if (ext === "png" || ext === "gif" || ext === "bmp" || ext === "jpg" || ext === "jpeg" || ext === "webp") {
								return item;
							}
						}
					}
				}
			}
		}
	}

	return null;
}


export function getSecondMediaAttachment(notes) {
	let firstOne = true;

	if (notes.length > 0) {
		for (let i = 0; i < notes.length; i++) {
			const noteItem = notes[i];

			if (noteItem.hasOwnProperty("mediaAttachmentsUrls")) {
				if (noteItem.hasOwnProperty("mediaAttachmentsUrls") && noteItem.mediaAttachmentsUrls !== null && noteItem.mediaAttachmentsUrls.length > 0) {
					for (let i = 0; i < noteItem.mediaAttachmentsUrls.length; i++) {
						const item = noteItem.mediaAttachmentsUrls[i];

						if (!isObject(item)) {
							// Need to loop through all values
							let imgUrlParts = item.split(".");
							let ext = imgUrlParts[imgUrlParts.length - 1];

							if (ext === "png" || ext === "gif" || ext === "bmp" || ext === "jpg" || ext === "jpeg" || ext === "webp") {
								if (firstOne) {
									firstOne = false;
								} else {
									return item;
								}
							}
						}
					}
				}
			}
		}
	}

	return null;
}

export function stripOrgIdFromDriveRootDirs(path, orgId) {
	return path.replace(`public/${orgId}/`, "public/").replace(`private/${orgId}/`, "private/");
}

export function convertPathToGuid(path) {
	return path.split("/").join("#");
}

export const getAllChannelMembers = (channel, lastMember, allMembers = [], origResolve, origReject) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await channel.queryMembers({}, { created_at: 1 }, { created_at_after: lastMember ? lastMember.created_at : null });

			allMembers = allMembers.concat(response.members);
			resolve = (origResolve) ? origResolve : resolve
			reject = (origReject) ? origReject : reject

			if (response.members.length === 100) {
				lastMember = response.members[response.members.length - 1];

				getAllChannelMembers(channel, lastMember, allMembers, resolve, reject);
			} else {
				resolve(allMembers);
			}
		} catch (err) {
			reject(err);
		}
	});
}

export function getAppBaseUrl() {
	return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

export function isInActivityEditor() {
	return window.location.href.indexOf("/editor/") !== -1
}

/**
 * Creates a map that that uses the object prop value in an array as the key. 
 * Each key will reference an array so objects with the same key will
 * be in the same array.
 * 
 * Example:
 * 
 * arr = 
 * [
 * 	 {id: 1, value: "val1"},
 * 	 {id: 1, value: "val1-2"},
 * 	 {id: 2, value: "val2"},
 * 	 {id: 3, value: "val3"}
 * ]
 * 
 * prop = "id"
 * 
 * Map:
 * {
 * 	 1: [{id: 1, value: "val1"},  {id: 1, value: "val1-2"}],
 * 	 2: [{id: 2, value: "val2"}],
 * 	 3: [{id: 3, value: "val3"}]
 * }
 * 
 * 
 * @param {*} arr The array of objects
 * @param {*} key The object prop whose value will be used as a key
 */
export function arrayToMapArray(arr, prop) {
	let map = {};
	if (_.isArray(arr)) {
		for (const item of arr) {

			if (!map[item[prop]]) {
				map[item[prop]] = [];
			}
			map[item[prop]].push(item);
		}
	}
	return map;
}

export function getGroupsForUser(identityId) {
	const groups = state.get(["selectedOrg", "_groups"]);

	const newGroups = groups.filter((group) => {
		return group.members.indexOf(identityId) >= 0;
	});

	return newGroups;
}
export default {
	"system": {
		"AmbiFi": "Ambafigh",
		"Amba Fie": "Ambafigh",
		"Amba Fy": "Ambafigh"
	},
	// Platforms
	"platforms": {
		"ios": {
			"AmbiFi": "Amba figh",
			"Amba Fie": "Amba figh",
			"Amba Fy": "Amba figh"
		},
		"android": {
		}
	},
	// Manufacturers
	"manufacturers": {
		"Amazon": {
		},
		"Samsung": {
		},
		"Huwaei": {
		}
	},
	// Models
	"models": {
		"iPhone X": {
		},
		"Nexus 6P": {
		}
	}
};
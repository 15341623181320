
import _ from "lodash";
import { monkey } from "baobab";
import AWS from "aws-sdk";
import request from "superagent";
import FileSaver from "file-saver";
import ls from "local-storage";

import * as utils from "../utils/utils.js";
import { transformFromChecklistEntities, transformToChecklistEntities } from "../utils/transformUtils.js";
import * as entityUtils from "../utils/entityUtils.js";
import env from "../constants/env";
import * as awsS3 from "../persistence/s3";
import * as dynamoDb from "../persistence/dynamoDb";
import state from "../state/state";
import browserHistory from "../history";
import { showError, showInfo, showConfirm, showError2 } from "./alertActions";
import { replaceActivity, updateActivity, getActivityById } from "./inventoryActions";
import { generateUUID } from "../utils/utils";
import { fixIftttStructureIds, getBlankArithemticOperator, /*getBlankComparisonOperator,*/ getBlankVisibleIf } from "./logicEditorActions";
import PlayerPaths from "../constants/paths/playerPaths";
import { cleanUpExternalResources, addExternalResource, getActivityContent, getDriveObjectForReference, cleanUpRelatedItemReferences } from "./activityActions";
import { refreshCredentials } from "../utils/securityUtil.js";
import { normalizeActivity, validateExternalDependencies } from "../utils/activity";
import { getOrgId } from "./orgsActions";
import { getJwtToken, getIdentityId, isOrganizationAdministrator, isOrganizationAuthor, isOrganizationUser } from "./user";
import { listDrive, getResourceReference, getDriveObjectByPath, getUrlForValue, isDriveReference, listDriveMap, getDriveObject } from "./drive.js";
import { fixUtterance, replaceTtsPhonetics } from "../utils/ttsUtils";
import AppConstants from "../constants/appConstants";
import * as editorActions from "./editorActions";
import { getExternalResources, getRelatedItems } from "./activityActions";

import { StreamChat } from 'stream-chat';
// const chatClient = StreamChat.getInstance("fcfa7htpwnqq");
const chatClient = StreamChat.getInstance("bymefp455ef2");
// chatClient.setBaseURL('https://chat.stream-io-api.com');

const EXTERNAL_RESOURCES_PROP = "externalResources";
const EXTERNAL_RESOURCES_STATE_PATH = ["tree", "root", "entity", EXTERNAL_RESOURCES_PROP];
const RELATED_ITEMS_PROP = "relatedItems";
const RELATED_ITEMS_STATE_PATH = ["tree", "root", "entity", RELATED_ITEMS_PROP];

AWS.config.update({ region: "us-east-1" });

let _visiblePaths = [];
let changePropertyCount = 0; //eslint-disable-line no-unused-vars

export function clearAppState(tree = state) {
	tree.set(["appState", "previewChecklist"], {
		checklist: {},
		showModal: false,
		showInline: false,
		live: false,
		allVisible: true
	})
}

export function navigate(nav, altMyChecklistsTarget = null) {
	console.info("APP START", utils.getLs("origin"));
	const origin = utils.getLs("origin");

	if (state.get(["user", "loggedIn"]) && origin && origin !== "") {
		if (origin === "/login" || origin === "/") {
			ls.remove("origin");
		} else {
			console.info("APP START REDIRECT", origin);
			// Remove key if about to set
			ls.remove("origin");
			browserHistory.push(origin);
			return;
		}
	}

	const user = state.get(["user"]);
	console.log("Nav request", user, nav);
	const mobileOrHomeHidden = utils.isMobile() || state.get(["appCapabilities", "views", "nav", "homeVisible"]) === false;

	// Have to deal with iTunes subscription and if expired
	let expired = false;
	const current = Date.now();
	let subscriptionPlan;
	if (user.hasOwnProperty("subscriptionPlan")) {
		subscriptionPlan = state.get(["user", "subscriptionPlan"]);
	} else {
		subscriptionPlan = env.config.defaultPlan;
	}

	if ((user.subscriptionPlan === "pro-plan-yearly-itunes" || user.subscriptionPlan === "pro-plan-monthly-itunes") && current > user.subscriptionExpiration) {
		expired = true;
	} else {
		expired = false;
	}
	if (nav/* && !mobile */) {
		state.set(["appState", "initialized"], true);
		const urlParams = state.get(["appState", "urlParams"]);
		if ((urlParams.subscriptionPlan === "basic-plan" || urlParams.subscriptionPlan === "standard-plan" || urlParams.subscriptionPlan === "pro-plan")) {
			if (subscriptionPlan === "pro-plan-unlimited") {
				alert("You are already in the Pro Plan (Unlimited) plan. This plan has all features and never expires. There is no need to change plans.");
			} else if (subscriptionPlan.includes("itunes")) {
				alert("You are already in a plan purchased through the app. You will need to let that expire before you can purchase a plan from the website. Email support@ambifi.com if you have questions.");
			}
		}

		if (urlParams.updateCard === "true") {
			const path = "/updateCustomerCard";
			browserHistory.push(path);
		} else if (urlParams.purchaseCustom === "true") {
			const path = "/purchaseCustom";
			browserHistory.push(path);
		} else if (expired ||
			((urlParams.subscriptionPlan === "basic-plan" || urlParams.subscriptionPlan === "standard-plan" || urlParams.subscriptionPlan === "pro-plan") &&
				(subscriptionPlan === "basic-plan" || subscriptionPlan === "basic-plan-checkmate" || subscriptionPlan === "standard-plan-unlimited" || subscriptionPlan === "pro-plan-monthly" || subscriptionPlan === "pro-plan-yearly"))) {
			const path = "/subscribe";
			browserHistory.push(path);
		} else {
			//if mobile, checklists is default entry point, if not mobile, home is default
			let path = mobileOrHomeHidden ? (isOrganizationAdministrator() || isOrganizationAuthor()) ? "/checklists" : "/search" : "/home";
			const userInfo = state.select("user").get();
			/**
															 * entryPoint['appState','navPanel']
															 */
			// entryPoint
			let targetNavPanel = mobileOrHomeHidden ? (isOrganizationAdministrator() || isOrganizationAuthor()) ? "checklists" : "search" : "home";

			if (userInfo && userInfo.entryPoint && userInfo.entryPoint !== "") {
				if (userInfo.entryPoint.indexOf("/search") !== -1) {
					targetNavPanel = "search";
				} else if (userInfo.entryPoint.indexOf("/chat") !== -1) {
					targetNavPanel = "chat";
				} else if (userInfo.entryPoint.indexOf("/searchHistory") !== -1) {
					targetNavPanel = "searchHistory";
				} else if (userInfo.entryPoint.indexOf("/dashboard") !== -1) {
					targetNavPanel = "dashboard";
				} else if (userInfo.entryPoint.indexOf("/checklists") !== -1) {
					targetNavPanel = "myChecklists";
				} else if (userInfo.entryPoint.indexOf("/history") !== -1) {
					targetNavPanel = "history";
				} else if (userInfo.entryPoint.indexOf("/timers") !== -1) {
					targetNavPanel = "timers";
				} else if (userInfo.entryPoint.indexOf("/checkMateImport") !== -1) {
					targetNavPanel = "checkMateImport";
				} else if (userInfo.entryPoint.indexOf("/player") !== -1) {
					targetNavPanel = "player";
				} else if (userInfo.entryPoint.indexOf("/home") !== -1) {
					targetNavPanel = "home";
				}
				path = userInfo.entryPoint;
			}

			if (((path === "/home" && targetNavPanel === "home") || (path === "/checklists" && targetNavPanel === "myChecklists")) && altMyChecklistsTarget && _.isString(altMyChecklistsTarget)) {
				path = altMyChecklistsTarget;
				if (altMyChecklistsTarget.indexOf("/search") !== -1) {
					targetNavPanel = "search";
				} else if (altMyChecklistsTarget.indexOf("/chat") !== -1) {
					targetNavPanel = "chat";
				} else if (altMyChecklistsTarget.indexOf("/searchHistory") !== -1) {
					targetNavPanel = "searchHistory";
				} else if (altMyChecklistsTarget.indexOf("/dashboard") !== -1) {
					targetNavPanel = "dashboard";
				} else if (altMyChecklistsTarget.indexOf("/checklists") !== -1) {
					targetNavPanel = "myChecklists";
				} else if (altMyChecklistsTarget.indexOf("/history") !== -1) {
					targetNavPanel = "history";
				} else if (altMyChecklistsTarget.indexOf("/timers") !== -1) {
					targetNavPanel = "timers";
				} else if (altMyChecklistsTarget.indexOf("/checkMateImport") !== -1) {
					targetNavPanel = "checkMateImport";
				} else if (altMyChecklistsTarget.indexOf("/player") !== -1) {
					targetNavPanel = "player";
				} else if (altMyChecklistsTarget.indexOf("/home") !== -1) {
					targetNavPanel = "home";
				}
			}

			state.set(["appState", "navPanel", "selected"], targetNavPanel);
			browserHistory.push(path);
		}
	}
}

export function deleteChecklistInstance(tree, identityId, checklistId, instanceId, timestamp) {
	let lastTimestamp = timestamp.replace(" ", "T");

	awsS3.deleteJsonFileFromS3WithKeyPrefix(env.s3.contentBucket, identityId, checklistId + "/" + lastTimestamp + "_" + instanceId + ".json");
}

export function performSaveActivityPreflightValidation() {
	//showEditorSpinner();
	let result = {
		blockSave: false,
		optionalSave: false,
		validationErrors: [],
		getValidationErrorsAsText: () => {
			const _result = result.validationErrors.map(error => {
				return error.message;
			});
			return _result.join("\n");
		},
		getValidationErrorAsArray: () => {
			return result.validationErrors.map(error => {
				return error.message;
			});
		}
	};
	let activity = transformFromChecklistEntities(state.get(["tree", "root"]));

	const linkValidationErrors = validateExternalDependencies(activity, true);
	if (!_.isEmpty(linkValidationErrors)) {
		result.validationErrors = linkValidationErrors;
	}

	result.validationErrors.forEach(error => {
		if (error.validationErrorSeverity > 1) {
			result.blockSave = true;
		}
		if (error.validationErrorSeverity > 0) {
			result.optionalSave = true;
		}
	});
	console.log("Validation result:", result);
	return result;
}

export function hideEditorSpinner() {
	state.set(["appState", "editor", "showSpinner"], false);

}

export function showEditorSpinner() {
	state.set(["appState", "editor", "showSpinner"], true);
}

export function setEditorSpinnerMessage(message) {
	state.set(["appState", "editor", "spinnerMessage"], message);
}

export function togglePreviewVisible(tree = state) {
	if (tree.get(["tree", "root"]).children.length === 0) {
		alert("No content in your activity yet! Add a List, Section or Item in the outline to get started.");
	} else {

		tree.set(["appState", "previewChecklist", "allVisible"], !tree.get(["appState", "previewChecklist", "allVisible"]));

		previewChecklistInline();
	}
}

export function togglePreviewLive(tree = state) {
	if (tree.get(["tree", "root"]).children.length === 0) {
		alert("No content in your activity yet! Add a List, Section or Item in the outline to get started.");
	} else {
		tree.set(["appState", "previewChecklist", "live"], !tree.get(["appState", "previewChecklist", "live"]));

		previewChecklistInline();
	}
}

export function downloadActivity(activity) {
	if (_.isUndefined(activity)) {
		activity = transformFromChecklistEntities(state.get(["tree", "root"]));
	}
	cleanUpExternalResources(activity);
	cleanUpRelatedItemReferences(activity);
	const blob = new Blob([JSON.stringify(activity)], { type: "application/json;charset=utf-8" });
	FileSaver.saveAs(blob, `${activity.id}.json`);
}

export async function downloadActivityFromS3(activityId) {
	showDocumentsSpinner();
	let activityInfo = getActivityById(activityId);
	let activity = await awsS3.getActivity(activityInfo.id, activityInfo.identityId);
	const blob = new Blob([JSON.stringify(activity)], { type: "application/json;charset=utf-8" });
	hideDocumentsSpinner();
	FileSaver.saveAs(blob, `${activity.id}.json`);
}


export async function saveChecklistNoUi(checklist = null) {
	return new Promise((resolve, reject) => {
		setTimeout(async () => {
			await refreshCredentials(true);
			console.log("About to save checklist", checklist);
			let _checklist = checklist;

			awsS3.updateChecklistsAndPushToS3(_checklist, null, { message: "The activity was successfully saved!" }).then(() => {
				resolve();
			}).catch((e) => {
				console.error(e);
				reject(e);
			});
		});
	});
}

export async function saveChecklist(checklist = null, opts = { openEditorAfterSave: true }) {
	showEditorSpinner();
	state.set(PlayerPaths.RELOAD_ACTIVITIES, true);

	return new Promise((resolve, reject) => {
		setTimeout(async () => {
			await refreshCredentials(true);
			try {
				console.log("About to save checklist", checklist);
				const providedChecklist = !!checklist;
				let _checklist = providedChecklist ? checklist : transformFromChecklistEntities(state.get(["tree", "root"]), false, false, true);
				// if (providedChecklist) {

				const revision = editorActions.getActivityMetaData("revision");
				_checklist.revision = _.isNumber(revision) ? revision + 1 : 1;
				// }

				// First lets figure out if this should be saved?
				let currentContent = null;
				try {
					currentContent = await getActivityContent(_checklist.id);
				} catch (err) {
					console.warn("Unable to get current content to verify revision integrity, this can be due to the fact that the activity doesn't yet exist in S3", err);
				}

				// We decide to increase the checklist revision in the transform above, therefore the revision will be one ahead.
				if (window.restoredFromLs || currentContent === null || currentContent.revision === (_checklist.revision - 1)) {
					// Ok all good!
					cleanUpExternalResources(_checklist);
					cleanUpRelatedItemReferences(_checklist);
					await utils.publishActivity(_checklist);

					// let srcTree = transformUtils.transformToChecklistEntities(checklist, null);
					// state.set("tree", srcTree);

					//Usually we redirect before the save is done, then wait for the save to finish to remove the busy spinner, sometimes we
					//want the save to be done before doing anything else so we resolve a promise for those cases
					awsS3.updateChecklistsAndPushToS3(_checklist, null, { message: "The activity was successfully saved!" }).then(() => {
						editorActions.setActivityMetaData("revision", _checklist.revision);
						resolve();
					}).catch((e) => {
						console.error(e);
						reject(e);
					});

					if (providedChecklist && opts.openEditorAfterSave) {
						// Open it for editing
						prepareActivityAndEdit(_checklist, _checklist.id, getIdentityId(), true);
					}
				} else {
					let errorMsg = `("Unable to save changes, the content on S3 is more recent than the in-memory activity, current revision: ${currentContent.revision}, checklist revision: ${_checklist.revision}`;
					console.error(errorMsg);

					hideEditorSpinner();

					showConfirm("Unable to save activity, the content in the editor is out of date, that means you or someone else saved changes elsewhere while you were editing in this window. This can happen if you have multiple windows open and saved in a different one. If you really no what you are doing you can click Ok, but it is highly recommended to click Cancel.", "Save Activity", async () => {
						// Ok all good!
						cleanUpExternalResources(_checklist);
						cleanUpRelatedItemReferences(_checklist);
						await utils.publishActivity(_checklist);

						// let srcTree = transformUtils.transformToChecklistEntities(checklist, null);
						// state.set("tree", srcTree);

						//Usually we redirect before the save is done, then wait for the save to finish to remove the busy spinner, sometimes we
						//want the save to be done before doing anything else so we resolve a promise for those cases
						awsS3.updateChecklistsAndPushToS3(_checklist, null, { message: "The activity was successfully saved!" }).then(() => {
							editorActions.setActivityMetaData("revision", _checklist.revision);
							resolve();
						}).catch((e) => {
							console.error(e);
							reject(e);
						});

						if (providedChecklist && opts.openEditorAfterSave) {
							// Open it for editing
							await prepareActivityAndEdit(_checklist, _checklist.id, getIdentityId(), true);
						}
					});

					// showError("Save Activity", "Unable to save activity, the content in the editor is out of date, that means you or someone else saved changes elsewhere while you were editing in this window. This can happen if you have multiple windows open and saved in a different one.", () => {
					// 	hideEditorSpinner();
					// });
					reject(new Error(errorMsg));
				}

			} catch (e) {
				console.error(e);
				hideEditorSpinner();
				reject(e);
			}
		}, 100);
	});

}

export function previewChecklist() {
	let checklist = transformFromChecklistEntities(state.get(["tree", "root"]));
	if (checklist.children.length > 0) {
		console.log("Previewing checklist!");
		// Need to set drive here to get this to work, in this case we send the entire drive,
		// when previewing from an activity card we need to get the drive specific to the activity
		state.set(["appState", "previewChecklist", "drive"], state.get(["drive"]));
		state.set(["appState", "previewChecklist", "checklist"], checklist);
		state.set(["appState", "previewChecklist", "showModal"], true);
	} else {
		alert("No content in your activity yet! Add a List, Section or Item in the outline to get started.");
	}
}

export function setPreviewActivity(activityId) {
	return new Promise((resolve, reject) => {
		const activityInfo = getActivityById(activityId);
		console.log("Load activity", activityInfo, activityId);
		awsS3.getActivity(activityId, activityInfo.identityId).then((content) => {
			console.log("Setting preview checklist", content);
			state.set(["appState", "previewChecklist", "checklist"], content);
			resolve();
		}).catch(err => {
			reject(err);
		});
	});
}

export function previewChecklistInline() {
	let checklist = transformFromChecklistEntities(state.get(["tree", "root"]));
	if (checklist.children.length > 0) {
		console.log("Previewing checklist!");

		state.unset(["appState", "stateUpdates"]);

		const previewChecklist = state.get(["appState", "previewChecklist"]);
		let session = 1;
		if (previewChecklist && _.isNumber(previewChecklist.session)) {
			session = previewChecklist.session + 1;
		}
		state.set(["appState", "previewChecklist", "session"], session);

		state.set(["appState", "previewChecklist", "showInline"], true);
		state.set(["appState", "previewChecklist", "checklist"], checklist);
		// if (state.get(["appState", "previewChecklist", "showInline"])) {
		// 	state.set(["appState", "previewChecklist", "showInline"], false);
		// } else {
		// 	state.set(["appState", "previewChecklist", "showInline"], true);
		// }
		// setTimeout(state.set(["appState", "previewChecklist", "showInline"], true), 10000);
	} else {
		alert("No content in your activity yet! Add a List, Section or Item in the outline to get started.");
	}
}

export function closeChecklistInline() {
	state.set(["appState", "previewChecklist", "showInline"], false);
	console.log(state.get(["appState", "previewChecklist", "showInline"]));

}

export async function replaceWithCode(identityId, activity) {
	if (activity.shareCodeReceived === "") {
		const shareCodeReceived = prompt("Please enter a share code (e.g. N51212)", "");

		if (shareCodeReceived !== null) {
			try {
				const data = await dynamoDb.getPrivateSharePromise(shareCodeReceived);
				if (!_.isEmpty(data)) {
					const shareCodeObject = data.Item;
					if (shareCodeObject.publisher === "checkmate" && (activity.publisher !== "checkmate" || (activity.publisher === "checkmate" && activity.cloned))) {
						alert("This checklist does not have a CheckMate license. Please purchase a checklist with a CheckMate license from the Home or Search page before replacing.");
					} else {
						activity.shareCodeReceived = shareCodeReceived; // eslint-disable-line

						await dynamoDb.putPrivateShareReplacedPromise({ shareCode: shareCodeReceived, checklistId: activity.id, identityId: identityId, publisher: activity.publisher });

						updateActivity(activity);

						replaceActivity(activity, shareCodeObject.identityId, shareCodeObject.checklistId);
					}
				} else {
					alert("The Share Code " + shareCodeReceived + " was not found.");
				}
			} catch (e) {
				console.error(e);
				alert(e);
			}
		}

	} else {
		try {
			const shareCodeReceived = activity.shareCodeReceived;
			const data = await dynamoDb.getPrivateSharePromise(shareCodeReceived);
			if (!_.isEmpty(data)) {
				const shareCodeObject = data.Item;
				// We now have the identityId and checkistId we need to replace checklist
				replaceActivity(activity, shareCodeObject.identityId, shareCodeObject.checklistId);
			} else {
				alert("The Share Code " + shareCodeReceived + " was not found.");
			}
		} catch (e) {
			console.error(e);
			alert(e);
		}
	}
}

export function toggleForceShowNav() {
	state.set(["appState", "editor", "forceShowNav"], !state.get(["appState", "editor", "forceShowNav"]));
}

// export function receiveDocuments(documents) {
// 	let newDocument = {
// 		"id": utils.generateUUID(),
// 		"newDoc": true,
// 		"name": "",
// 		"description": "",
// 		"genre": "",
// 		"tags": [],
// 		"publisher": "self",
// 		"store": "",
// 		"productPlaneId": "",
// 		"shareCode": "",
// 		"shareCodeReceived": "",
// 		"version": "1.0",
// 		"cloned": false,
// 		"visible": true,
// 		"speedType": "KIAS"
// 	};
// 	documents.unshift(newDocument);
// 	console.log("In receive documents!");
// 	if (!state.get(["appState", "documents", "initialSpinnerShown"])) {
// 		state.set(["appState", "documents", "showSpinner"], true);
// 	}	
// 	window.setTimeout(() => {
// 		injectShares(documents).then(finalDocuments => {
// 			console.log("Setting final documents", finalDocuments);
// 			setActivities(finalDocuments);
// 			state.set(["appState", "documents", "showSpinner"], false);
// 		}).catch(err => {
// 			showError("Receive Activities", "Failed to receive activities.");
// 			console.error(err);
// 		});
// 	}, 100);



// 	// Route to editor
// 	//const path = "/search";
// 	//browserHistory.push(path);	
// }

export function goHome(tree) {
	tree.unset(["appState", "urlParams", "subscriptionPlan"]);
	tree.set(["appState", "navPanel", "selected"], "home");
	// const path = "/home";
	const path = "/checklists"; // For now
	browserHistory.push(path);
}

export async function prepareActivityAndEdit(srcTree, checklistId, identityId = null, startupModalDontShowAgain = false) {
	// Load the drive if not yet initialized
	await listDriveOnEditActivity();

	srcTree = transformToChecklistEntities(srcTree, identityId);

	// Let's have this act on dstTree, not srcTree
	srcTree.root.selected = true;
	srcTree.root.expanded = true;
	resetTree(srcTree.root.children);
	editorActions.resetActivityEditMetaData();
	editorActions.setActivityMetaData("revision", srcTree.root.revision);
	state.set("selectedChecklistId", srcTree.root.id);
	state.set("selectedNodePath", ["tree", "root"]);
	state.set("history", []);
	state.set("historyIndex", -1);
	//state.set("clipboard",{});
	//state.set("clipboardMode","");
	state.set("tree", srcTree);

	// JJB: DO WE NEED?
	// utils.recursePopulateIdsForEntities(srcTree.root.children, state, null, null);

	saveUndoState();

	// Force reset for testing
	//utils.setLs(state.get(["user", "username"]) + ";startupModalDontShowAgain", false);

	const _startupModalDontShowAgain = startupModalDontShowAgain || utils.getLs(state.get(["user", "username"]) + ";startupModalDontShowAgain");

	state.set(["appState", "startupModal", "showModal"], !_startupModalDontShowAgain);

	setSelectedFolderFromDriveRootPath(srcTree.root?.entity?.driveRootPath);

	// Route to editor
	const path = "/editor/" + checklistId;
	browserHistory.push(path);
}

export function forceLogin(browserHistory, origin) {
	if (origin === "/editor") {
		origin = "/checklists";
	}
	state.set(["user", "entryPoint"], origin);

	const path = "/login";
	browserHistory.push(path);

}

export function saveUndoState() {
	// Always splice anything to right
	if (state.get("history").length >= 0 && (state.get("historyIndex") < state.get("history").length - 1)) {
		state.get("history").splice([state.get("historyIndex") + 1]);
	}

	state.get("history").push(
		{
			tree: state.get("tree"),
			selectedNodePath: Object.assign([], state.get("selectedNodePath")),
			typeahead: state.get("typeahead")
		}
	);
	state.set("historyIndex", state.get("historyIndex") + 1);

	changePropertyCount = 0;
}

export function resetTree(arr) {
	for (let i = 0; i < arr.length; i++) {
		arr[i].selected = false;
		arr[i].expanded = false;
		if (arr[i].hasOwnProperty("children")) {
			resetTree(arr[i].children);
		}
	}
}

export function idExists(tree, id) {
	let typeahead = tree.get("typeahead");
	for (let i = 0; i < typeahead.length; i++) {
		if (typeahead[i].id === id) {
			return true;
		}
	}
}

export function handleChangeItemType(tree, value) {
	// const user = tree.get(["user"]);

	// if (!user.subscriptionPlan.startsWith("pro-plan") && value !== "item") {
	// 	alert("This item type is only supported for Pro plan subscribers. If you want to test the capability, you can turn on Preview Pro in the mobile app to see how these work.");
	// }

	let arrPath = tree.select("selectedNodePath").get();
	let oldItem = tree.select(arrPath).get(["entity"]);

	let newItem = _.cloneDeep(oldItem);

	newItem.type = value;

	let deltaProps;
	// Set default content for each type
	if (value === "itemTextInput" || value === "itemTextInputMultiline") {
		deltaProps =
		{
			textInputPlaceholder: newItem.hasOwnProperty("textInputPlaceholder") ? newItem.textInputPlaceholder : "",
			textInputDefaultValue: newItem.hasOwnProperty("textInputDefaultValue") ? newItem.textInputDefaultValue : "",
			textInputMaxLength: newItem.hasOwnProperty("textInputMaxLength") ? newItem.textInputMaxLength : 1000,
			textInputKeyboardType: newItem.hasOwnProperty("textInputKeyboardType") ? newItem.textInputKeyboardType : "default",
			textInputKeyboardAutoCapitalize: newItem.hasOwnProperty("textInputKeyboardAutoCapitalize") ? newItem.textInputKeyboardAutoCapitalize : "sentences",
			textInputKeyboardAutoCorrect: newItem.hasOwnProperty("textInputKeyboardAutoCorrect") ? newItem.textInputKeyboardAutoCorrect : true,
			textInputKeyboardReturnKeyType: newItem.hasOwnProperty("textInputKeyboardReturnKeyType") ? newItem.textInputKeyboardReturnKeyType : "default",
			textInputMaskType: newItem.hasOwnProperty("textInputMaskType") ? newItem.textInputMaskType : "",
			textInputCustomMask: newItem.hasOwnProperty("textInputCustomMask") ? newItem.textInputCustomMask : "",
			textInputCustomMas2: newItem.hasOwnProperty("textInputCustomMask2") ? newItem.textInputCustomMask2 : "",
			textInputCurrencySymbol: newItem.hasOwnProperty("textInputCurrencySymbol") ? newItem.textInputCurrencySymbol : "$",
			textInputCurrencySeparator: newItem.hasOwnProperty("textInputCurrencySeparator") ? newItem.textInputCurrrencySeparator : ",",
			textInputNumberOfLines: newItem.hasOwnProperty("textInputNumberOfLines") ? newItem.textInputNumberOfLines : 4
		};
	} else if (value === "itemDate" || value === "itemTime" || value === "itemDateTime") {
		deltaProps = {
			dateTimeType: newItem.hasOwnProperty("dateTimeType") ? newItem.dateTimeType : "local",
			dateTimeInitialDate: newItem.hasOwnProperty("dateTimeInitialDate") ? newItem.dateTimeInitialDate : "today",
			dateTimeMinuteInterval: newItem.hasOwnProperty("dateTimeMinuteInterval") ? newItem.dateTimeMinuteInterval : 1,
			dateTimeAdvanceOnSelect: newItem.hasOwnProperty("dateTimeAdvanceOnSelect") ? newItem.dateTimeAdvanceOnSelect : true,
		};
	} else if (value === "itemYesNo") {
		deltaProps = {
			yesNoLinkOnSelect: newItem.hasOwnProperty("yesNoLinkOnSelect") ? newItem.yesNoLinkOnSelect : false,
			yesNoLinkActionType: newItem.hasOwnProperty("yesNoLinkActionType") ? newItem.yesNoLinkActionType : "goto",
			yesLinkActionType: newItem.hasOwnProperty("yesLinkActionType") ? newItem.yesLinkActionType : "inherit",
			noLinkActionType: newItem.hasOwnProperty("noLinkActionType") ? newItem.noLinkActionType : "inherit",
			naLinkActionType: newItem.hasOwnProperty("naLinkActionType") ? newItem.naLinkActionType : "inherit",
			yesNoYesLinkId: newItem.hasOwnProperty("yesNoYesLinkId") ? newItem.yesNoYesLinkId : "",
			yesNoNoLinkId: newItem.hasOwnProperty("yesNoNoLinkId") ? newItem.yesNoNoLinkId : "",
			yesNoNaLinkId: newItem.hasOwnProperty("yesNoNaLinkId") ? newItem.yesNoNaLinkId : "",
			yesNoShowNa: newItem.hasOwnProperty("yesNoShowNa") ? newItem.yesNoShowNa : false,
			yesNoAdvanceOnSelect: newItem.hasOwnProperty("yesNoAdvanceOnSelect") ? newItem.yesNoAdvanceOnSelect : true,
		};
	} else if (value === "itemPicker") {
		deltaProps = {
			pickerItemViewType: newItem.hasOwnProperty("pickerItemViewType") ? newItem.pickerItemViewType : "picker",
			pickerItemPlaceholder: newItem.hasOwnProperty("pickerItemPlaceholder") ? newItem.pickerItemPlaceholder : "",
			pickerItemDefaultValue: newItem.hasOwnProperty("pickerItemDefaultValue") ? newItem.pickerItemDefaultValue : "",
			pickerItems: newItem.hasOwnProperty("pickerItems") ? newItem.pickerItems : [],
			pickerAdvanceOnSelect: newItem.hasOwnProperty("pickerAdvanceOnSelect") ? newItem.pickerAdvanceOnSelect : false,
			pickerLinkOnSelect: newItem.hasOwnProperty("pickerLinkOnSelect") ? newItem.pickerLinkOnSelect : false,
			pickerLinkActionType: newItem.hasOwnProperty("pickerLinkActionType") ? newItem.pickerLinkActionType : "goto"
		};
	} else if (value === "itemRanking") {
		deltaProps = {
			config: newItem.hasOwnProperty("config") ? newItem.config : "",
			items: newItem.hasOwnProperty("items") ? newItem.items : [],
		};
	} else if (value === "itemChoiceSingle" || value === "itemChoiceMulti") {
		deltaProps = {
			choiceItems: newItem.hasOwnProperty("choiceItems") ? newItem.choiceItems : []
		};
	} else if (value === "itemBarcodeScanner") {
		deltaProps = {
			barcodeTypes: newItem.hasOwnProperty("barcodeTypes") ? newItem.barcodeTypes : []
		};
	} else if (value === "itemImagePicker") {
		deltaProps = {
			imagePickerMediaType: newItem.hasOwnProperty("imagePickerMediaType") ? newItem.imagePickerMediaType : "photo",
			imagePickerAddMediaButton: newItem.hasOwnProperty("imagePickerAddMediaButton") ? newItem.imagePickerAddMediaButton : "Add Photo",
			imagePickerUploadTitle: newItem.hasOwnProperty("imagePickerUploadTitle") ? newItem.imagePickerUploadTitle : "Upload",
			imagePickerCaptureMediaTitle: newItem.hasOwnProperty("imagePickerCaptureMediaTitle") ? newItem.imagePickerCaptureMediaTitle : "Take Photo..."
		};
	} else if (value === "itemSketchPad") {
		deltaProps = {
			sketchPadBackgroundColor: newItem.hasOwnProperty("sketchPadBackgroundColor") ? newItem.sketchPadBackgroundColor : "",
			sketchPadPenColor: newItem.hasOwnProperty("sketchPadPenColor") ? newItem.sketchPadPenColor : "",
			sketchPadPenWidth: newItem.hasOwnProperty("sketchPadPenWidth") ? newItem.sketchPadPenWidth : 5,
			sketchPadHeight: newItem.hasOwnProperty("sketchPadHeight") ? newItem.sketchPadHeight : 190
		};
	}

	let newItemMerged = _.merge({}, newItem, deltaProps);

	tree.select(arrPath).set(["entity"], newItemMerged);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleAddPickerItem(tree, prop, label, value, image, properties) {
	try {
		if (properties && properties !== "") {
			JSON.parse(properties);
		}

		console.log("****** ADD: " + JSON.stringify(image));

		let arrPath = tree.select("selectedNodePath").get();
		tree.select(arrPath).push(prop, { label: label, value: value, image: image, properties: properties });

		// Future use for blocks of undo
		changePropertyCount++;

		saveUndoState();
	} catch (e) {
		alert("The properties must be valid JSON.");
	}

}

export function updatePickerItem(idx, prop, label, value, image, properties) {
	try {
		if (properties && properties !== "") {
			JSON.parse(properties);
		}

		let arrPath = state.select("selectedNodePath").get();
		state.select(_.concat(arrPath, prop)).splice([idx, 1, { label: label, value: value, image: image, properties: properties }]);

		// Future use for blocks of undo
		changePropertyCount++;

		saveUndoState();
	} catch (e) {
		alert("The properties must be valid JSON.");
	}

}

export function handleDeletePickerItem(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();
	tree.select(arrPath).splice(prop, [index, 1]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleMovePickerItemUp(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();

	let moveItem = tree.select(arrPath).get(prop)[index];

	tree.select(arrPath).splice(prop, [index, 1]);
	tree.select(arrPath).splice(prop, [index - 1, 0, moveItem]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleMovePickerItemDown(tree, prop, index) {
	let arrPath = tree.select("selectedNodePath").get();

	let moveItem = tree.select(arrPath).get(prop)[index];

	tree.select(arrPath).splice(prop, [index, 1]);
	tree.select(arrPath).splice(prop, [index + 1, 0, moveItem]);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

function findNodePath(nodeId, context = ["tree", "root"]) {
	const currentNode = state.get(context);
	if (currentNode.id === nodeId) {
		return context;
	} else {
		if (currentNode.children) {
			for (let i = 0; i < currentNode.children.length; i++) {
				const _context = Object.assign([], context);
				_context.push("children", i);
				const result = findNodePath(nodeId, _context);
				if (result) {
					return result;
				}
			}
		}
	}
}

let expectedAnchorTarget = null;
export function handleAnchor(anchorTarget) {
	if (anchorTarget && anchorTarget === expectedAnchorTarget) {
		window.setTimeout(() => {
			const el = window.document.getElementById(anchorTarget);
			if (el) {
				el.scrollIntoView();
			}
			// state.unset(["edit", "anchor"]); Will have to think about this, using this information to keep the panel open
			expectedAnchorTarget = null;
		}, 500);
	}
}

export function setImageHotspots(imageHotspots) {
	// Author Properties are a string so need to deal with that
	let arrPath = getSelectedNodePath();
	const propPath = _.concat(arrPath, "entity");

	let authorProperties = {};

	const entity = state.get(propPath);

	if (entity.authorProperties) {
		try {
			authorProperties = JSON.parse(entity.authorProperties);
		} catch (err) {
			authorProperties = {};
		}
	}

	authorProperties.imageHotspots = imageHotspots;

	state.set(_.concat(propPath, "authorProperties"), JSON.stringify(authorProperties, null, 2));
}

export function setImageKenBurnsEffect(cameras) {
	// Author Properties are a string so need to deal with that
	let arrPath = getSelectedNodePath();
	const propPath = _.concat(arrPath, "entity");

	let authorProperties = {};

	const entity = state.get(propPath);

	if (entity.authorProperties) {
		try {
			authorProperties = JSON.parse(entity.authorProperties);
		} catch (err) {
			authorProperties = {};
		}
	}

	authorProperties.imageKenBurnsEffect = cameras;

	state.set(_.concat(propPath, "authorProperties"), JSON.stringify(authorProperties, null, 2));
}

export function setProp(path, key, value, expectedId = null) {
	let _path = Object.assign([], path);
	let doSetProp = true;
	if (expectedId) {
		const existingObj = state.get(path);
		if (existingObj.id !== expectedId) {
			_path = findNodePath(expectedId);
			doSetProp = !!_path;
		}
	}
	if (doSetProp) {
		state.set(_.concat(_path, key), value);
	}
}
export function setNodeStateProp(node, key, value) {
	state.set(["appState", node.id, key], value);
}
export function getNodeStateProp(node, key, value) {
	return state.get(["appState", node.id, key], value);
}
export function getNodeState(node) {
	let result = {};
	if (node && node.id) {
		const appStateNodePath = ["appState", "nodes", node.id];
		const appStateNodeCursor = state.select(appStateNodePath);
		if (!appStateNodeCursor.exists()) {
			state.set(appStateNodePath, {});
		} else {
			result = appStateNodeCursor.get();
		}
	}
	return result;
}
export function getSelectedNodePath() {
	const result = Object.assign([], state.get("selectedNodePath"));
	return result;
}

export function handleToggleBetweenLogicEditors(tree) {
	let arrPath = getSelectedNodePath();
	const propPath = _.concat(arrPath, "entity", "conditionalVisible");
	if (tree.exists(propPath) && _.isObject(tree.get(propPath))) {
		const currentMode = tree.get(_.concat(arrPath, "entity", "conditionalVisible", "ifThis", "mode"));

		if (currentMode === "basic") {
			tree.set(_.concat(arrPath, "entity", "conditionalVisible", "ifThis", "mode"), "advanced");
		} else {
			tree.set(_.concat(arrPath, "entity", "conditionalVisible", "ifThis", "mode"), "basic");
		}

		tree.commit();

		saveUndoState();
	}
}

export function handleToggleLogicEditor(tree, prop) {
	let arrPath = getSelectedNodePath();
	const propPath = _.concat(arrPath, "entity", prop);
	if (tree.exists(propPath) && _.isObject(tree.get(propPath))) {
		// Reset it
		tree.unset(_.concat(arrPath, "entity", prop));
	} else {
		const calcId = generateUUID();
		const ifThis = {
			id: calcId,
			mode: "basic",
			type: "calculation",
			definition: getBlankVisibleIf(true)
		};
		const thenThat = {
			id: generateUUID(),
			type: "rule",
			sourceId: calcId,
			actions: [
				{
					"type": "valueAssignment",
					"source": "result",
					"target": [
						"currentChecklist", "overrides", "${self.id}", "visible" // eslint-disable-line
					]
				}
			]
		};

		tree.set(_.concat(arrPath, "entity", prop), {
			id: generateUUID(),
			type: "ifttt",
			ifThis,
			thenThat
		});
	}
}

export function handleToggleCalcEditor(tree, prop) {
	let arrPath = getSelectedNodePath();
	const propPath = _.concat(arrPath, "entity", prop);
	if (tree.exists(propPath) && _.isObject(tree.get(propPath))) {
		// Reset it
		tree.unset(_.concat(arrPath, "entity", prop));
	} else {
		const calcId = generateUUID();
		const ifThis = {
			id: calcId,
			type: "calculation",
			definition: getBlankArithemticOperator()
		};
		const thenThat = {
			id: generateUUID(),
			type: "rule",
			sourceId: calcId,
			actions: [
				{
					"type": "valueAssignment",
					"source": "result",
					"target": [
						"currentChecklist", "overrides", "${self.id}", "value" // eslint-disable-line
					]
				}
			]
		};

		tree.set(_.concat(arrPath, "entity", prop), {
			id: generateUUID(),
			type: "ifttt",
			ifThis,
			thenThat
		});
	}
}


export function getProperty(tree, property, value, nodePath = null, expectedId = null) {
	let arrPath = nodePath ? nodePath : getSelectedNodePath();
	const existingObject = tree.select(arrPath);
	if (expectedId && existingObject.id !== expectedId) {
		// Find new path
		arrPath = findNodePath(expectedId);
	}
	console.log("Changing property", property, value);
	return tree.get(_.concat(arrPath, ["entity", property]));
}

export function handleScanForVariables(tree = state, property, nodeOrPath = null) {
	console.time("Scan for variables");
	console.log("Scan for variables", nodeOrPath);
	let existingObject = null;
	if (_.isPlainObject(nodeOrPath)) {
		existingObject = { entity: nodeOrPath };
	} else if (nodeOrPath === null || _.isArray(nodeOrPath)) {
		let arrPath = nodeOrPath ? nodeOrPath : getSelectedNodePath();
		existingObject = tree.get(arrPath);
	}

	if (existingObject) {
		const value = existingObject.entity[property];
		const guid = existingObject.entity.guid;
		let guidVariables = {};
		const inlineVariablePath = ["editActivityMetaData", "inlineVariables", guid, property];
		// Declarations will track where a variable has been declared, this will allow us to highlight any
		// issues that relate to variables being declared multiple times.
		// const inlineVariableDeclarationsPath = ["editActivityMetaData", "inlineVariableDeclarations"];
		const inlineVariablesMapMonkeyPath = ["editActivityMetaData", "inlineVariablesMap"];
		const inlineVariablesMap = tree.get(inlineVariablesMapMonkeyPath);
		const variables = tree.get(["tree", "variables"]);
		const variablesMap = {};
		variables.forEach(_var => {
			variablesMap[_var.id] = true;
		});
		// When declaring a variable track this info, this way we can make sure BEFORE adding
		// variables that we don't have a naming conflict, if declared in a different location
		const declarationInfo = {
			guid,
			property,
			content: value // Capture this so we can show it in visible if
		};
		console.log("Global variable matches (scan)", inlineVariablesMap, variablesMap);
		if (_.isString(value) && value.indexOf("${") !== -1 && value.indexOf("}") !== -1) {
			// Candidate!
			const matches = [...value.matchAll(/\$\{.*?globalVar\.(.+?)=/g)];

			let duplicatesDetected = {};

			for (const _match of matches) {
				const _var = _match[1];

				const existingVar = inlineVariablesMap ? inlineVariablesMap[_var] : null;
				if ((existingVar && (existingVar.guid !== guid || existingVar.property !== property)) || (variablesMap && variablesMap[_var])) {
					// Track which variables are actual dupes, we will use this later on to reconsile markers
					duplicatesDetected[_var] = true;
					// Adding the marker for a dupe also adds a key `dup-var-${_var}` - this is to ensure that only one marker is ever added for this issue and it doesn't
					// keep adding them
					// The metaData attached we will use later on to filter out dupes that are no longer dupes.

					let additionalInfo = "";
					if (existingVar) {
						additionalInfo = `In text: "${existingVar.content}"`;
					} else {
						additionalInfo = "By assigning ID to an item.";
					}

					editorActions.addEntityMarker(guid, editorActions.createErrorMarker(`A variable with name ${_var} has already been declared elsewhere. ${additionalInfo}`, `dup-var-${_var}`, {
						type: "duplicate-variable",
						variable: _var
					}), property)
					console.warn("Detected a duplicate variable declaration", _var, existingVar, declarationInfo, variablesMap);
					// Skip the rest
					continue;
				}
				console.log("Global variable matches and valid assignment", _var);
				guidVariables[_var] = _.assign({}, declarationInfo, { id: _var });
			}

			// In this step, since we could've made changes elsewhere that would warrant markers from being removed we have to check
			// and make sure that all markers are still valid. For example there might be a marker that claims that variable "a" is a duplicate
			// however that may have changed and "a" is now available for assignment.
			editorActions.removeEntityMarkers(guid, property, (marker) => {
				let res = true; // Return unless we prove this to be something that should be removed
				const { metaData } = marker;
				if (metaData && metaData.type && metaData.type === "duplicate-variable" && !duplicatesDetected[metaData.variable]) {
					res = false;
				}
				return res;
			});

			tree.set(inlineVariablePath, guidVariables);
		} else if (tree.exists(inlineVariablePath)) {
			// No longer any variables so remove them
			tree.unset(inlineVariablePath);
		}
		const currentInlineVariables = state.get(["editActivityMetaData", "inlineVariables"]);
		if (_.isEmpty(currentInlineVariables)) {
			// No more inline variables ...
			tree.unset(inlineVariablesMapMonkeyPath); // PERFORMANCE, remove unnecessary monkey ... no variables!
		} else {
			tree.set(inlineVariablesMapMonkeyPath, monkey({
				cursors: {
					inlineVariables: ["editActivityMetaData", "inlineVariables"]
				},
				get: ({ inlineVariables }) => {
					if (inlineVariables) {
						const res = {};
						_.keys(inlineVariables).forEach(guid => {

							const entity = inlineVariables[guid];
							_.keys(entity).forEach(property => {
								const variables = entity[property];
								_.keys(variables).forEach(_var => {
									res[_var] = variables[_var];
								})
							})
						});
						return res;
					} else {
						return {};
					}
				}
			}));
		}


	}

	console.timeEnd("Scan for variables");
}
// These need to pass in node working on instead of selected node
export function handleChangeProperty(tree = state, property, value, nodePath = null, expectedId = null) {
	let arrPath = nodePath ? nodePath : getSelectedNodePath();
	const existingObject = tree.select(arrPath);
	if (expectedId && existingObject.id !== expectedId) {
		// Find new path
		arrPath = findNodePath(expectedId);
	}
	// console.log("Changing property", property, value);

	let targetPath = ["entity", property];
	if (_.isArray(property)) {
		targetPath = _.concat(["entity"], property);
	}

	tree.select(arrPath).set(targetPath, value);
	if (property === "id") {
		const entityId = existingObject.get(["entity", "entityId"]);
		// Ok have a variable for that
		if (!tree.get("tree", "variablesMap", entityId)) {
			if (value.trim() !== "") {
				tree.set(["tree", "variablesMap", entityId], true);
				tree.push(["tree", "variables"], {
					id: value,
					entityId
				});
			}
		} else if (value.trim() !== "") {
			// The id has a value
			let varIdx = -1;
			tree.get(["tree", "variables"]).forEach((variable, idx) => {
				if (variable.entityId === entityId) {
					varIdx = idx;
				}
			});
			if (varIdx > -1) {
				tree.set(["tree", "variables", varIdx, "id"], value);
			}
		} else {
			// The id is empty and needs to be removed
			tree.unset(["tree", "variablesMap", entityId]);
			let varIdx = -1;
			tree.get(["tree", "variables"]).forEach((variable, idx) => {
				if (variable.entityId === entityId) {
					varIdx = idx;
				}
			});
			if (varIdx > -1) {
				tree.splice(["tree", "variables"], [varIdx, 1]);
			}
		}
	}
	// console.log("Current variable states", tree.get(["tree", "variables"]), tree.get(["tree", "variablesMap"]));

	// JJB: MONITOR IF A PROBLEM COMMENTING OUT
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	// JJB: DO WE NEED?
	// Need to rethink...should be better place to do
	// utils.recursePopulateIdsForEntities(tree.get(["tree"]).root.children, tree, null, null);

	saveUndoState();
}

export function handleCopyPasteProperty(tree, srcProperty, dstProperty) {
	var arrPath = tree.select('selectedNodePath').get();
	var value = tree.select(arrPath).get(["entity", srcProperty]);
	tree.select(arrPath).set(["entity", dstProperty], value);
	tree.commit();

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleCopyPastePropertyFromContent(tree, content, dstProperty) {
	var arrPath = tree.select('selectedNodePath').get();
	tree.select(arrPath).set(["entity", dstProperty], content);
	tree.commit();

	tree.set(["appState", "forceEditorRefresh"], tree.get(["appState", "forceEditorRefresh"]) + 1);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePrintProperty(tree, property, value) {
	const arrPath = tree.select("selectedNodePath").get();
	tree.select(arrPath).set(["entity", "printProperties", property], value);

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangePropertyForComments(tree, value) {
	let arrPath = tree.select("selectedNodePath").get();
	//tree.select(arrPath).set(["entity","commentsAuthor"],value);
	tree.select(arrPath).set(["entity", "commentsAuthor"], "");
	tree.select(arrPath).set(["entity", "comments"], utils.createMarkupForComments(value));

	// Future use for blocks of undo
	changePropertyCount++;

	saveUndoState();
}

export function handleChangeValuesProperty(tree, property, value) {
	let arrPath = tree.select("selectedNodePath").get();
	tree.select(arrPath).set(["entity", "values", property], value);

	changePropertyCount++;

	saveUndoState();
}

export function handleDeleteTag(tree, i) {
	let arrPath = tree.select("selectedNodePath").get();
	let tags = tree.select(arrPath).select(["entity", "tags"]);

	tags.splice([i, 1]);
}

export function handleAddTag(tree, tag) {
	let arrPath = tree.select("selectedNodePath").get();
	let tags = tree.select(arrPath).select(["entity", "tags"]);

	tags.push(tag);
}

export function handleDeleteGroupName(tree, i) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).select(["entity", "groupNames"]);

	groupNames.splice([i, 1]);

	// Also remove from global state
	// Would need to check if any other items are referencing
}

export function handleAddGroupName(tree, groupName) {
	if (groupName.indexOf(" ") > -1) {
		alert("Sorry, a group name should not have any spaces.");
		return;
	}

	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).select(["entity", "groupNames"]);

	groupNames.push(groupName);

	// Also add to global state
	let globalGroupNames = tree.get("groupNames");
	if (globalGroupNames.indexOf(groupName) === -1) {
		globalGroupNames.push(groupName);
	}
	tree.set(["groupNames"], globalGroupNames);
}

export function handleDeleteFilterTag(tree, i) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).select(["entity", "filterTags"]);

	filterTags.splice([i, 1]);

	// Also remove from global state
	// Would need to check if any other items are referencing
}

export function handleCopyGroupNames(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).get(["entity", "groupNames"]);

	global.groupNames = groupNames; // eslint-disable-line
}

export function handlePasteGroupNames(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let groupNames = tree.select(arrPath).get(["entity", "groupNames"]);
	let groupNamesCursor = tree.select(arrPath).select(["entity", "groupNames"]);

	for (let i = 0; i < global.groupNames.length; i++) { // eslint-disable-line
		let groupName = global.groupNames[i]; // eslint-disable-line

		if (groupNames.indexOf(groupName) === -1) {
			groupNamesCursor.push(groupName);
		}
	}

	saveUndoState();
}

export function handleAddFilterTag(tree, filterTag) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).select(["entity", "filterTags"]);

	filterTags.push(filterTag);

	// Also add to global state
	let globalFilterTags = tree.get("filterTags");
	if (globalFilterTags.indexOf(filterTag) === -1) {
		globalFilterTags.push(filterTag);
	}
	tree.set(["filterTags"], globalFilterTags);
}

export function handleCopyFilterTags(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).get(["entity", "filterTags"]);

	global.filterTags = filterTags; // eslint-disable-line no-undef
}

export function handlePasteFilterTags(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let filterTags = tree.select(arrPath).get(["entity", "filterTags"]);
	let filterTagsCursor = tree.select(arrPath).select(["entity", "filterTags"]);
	/* eslint-disable no-undef */
	for (let i = 0; i < global.filterTags.length; i++) {
		let filterTag = global.filterTags[i];
		/* eslint-enable no-undef */
		if (filterTags.indexOf(filterTag) === -1) {
			filterTagsCursor.push(filterTag);
		}
	}

	saveUndoState();
}

export function handleCopyConditionalVisible(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	let conditionalVisible = tree.select(arrPath).get(["entity", "conditionalVisible"]);

	global.conditionalVisible = conditionalVisible; // eslint-disable-line no-undef
}

export function handlePasteConditionalVisible(tree) {
	const arrPath = tree.select("selectedNodePath").get();
	// let conditionalVisible = tree.select(arrPath).get(["entity", "conditionalVisible"]);
	let conditionalVisibleCursor = tree.select(arrPath).select(["entity", "conditionalVisible"]);

	// Need to fix id's

	/* eslint-disable no-undef */
	conditionalVisibleCursor.set(global.conditionalVisible);

	// Need to fix id's
	let newNode = _.cloneDeep(tree.get(arrPath));

	newNode.entity = fixIftttStructureIds(newNode.entity);

	conditionalVisibleCursor.set(newNode.entity.conditionalVisible);

	saveUndoState();
}

function unselectNode(tree) {
	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	oldSelectedNodePath.push("selected");
	tree.set(oldSelectedNodePath, false);

	tree.set(["selectedNodePath"], []);
}

export function handleSelectNodeAtPath(tree, arrPath, anchor = null) {
	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	oldSelectedNodePath.push("selected");
	tree.set(oldSelectedNodePath, false);

	tree.set(["selectedNodePath"], arrPath);

	let selectedNodePath = _.cloneDeep(arrPath);
	selectedNodePath.push("selected");
	tree.set(selectedNodePath, true);

	if (anchor) {
		expectedAnchorTarget = anchor;
		state.set(["edit", "anchor"], anchor);
	}
}

export function handleUndo(tree) {
	let historyIndex = tree.get("historyIndex");
	if (historyIndex > 0) {
		historyIndex--;
		let history = tree.get(["history", historyIndex]);
		tree.set("tree", history.tree);
		tree.set("selectedNodePath", history.selectedNodePath);
		tree.set("historyIndex", historyIndex);
	}
}

export function handleRedo(tree) {
	let historyIndex = tree.get("historyIndex");
	if (historyIndex < tree.get("history").length - 1) {
		historyIndex++;
		let history = tree.get(["history", historyIndex]);
		tree.set("tree", history.tree);
		tree.set("selectedNodePath", history.selectedNodePath);
		tree.set("historyIndex", historyIndex);
	}

}

// Clean up select code
export function handleSelectNodeParent(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}

	let selectedNodeCursor = oldSelectedNodeCursor.up().up();
	if (selectedNodeCursor !== null && selectedNodeCursor.path.length > 0) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		// Weirdness if use path directly
		let arrPath = Object.assign([], selectedNodeCursor.path);
		tree.set(["selectedNodePath"], arrPath);

		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		scrollSelectedNodeIntoView();
	}
}

export function handleSelectNodeFirstChild(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (!oldSelectedNodeCursor.get().hasOwnProperty("children")) {
		return;
	}

	// Expand if not already
	if (!isNodeExpanded(oldSelectedNodeCursor)) {
		handleToggleNode(oldSelectedNodeCursor);
	}

	let selectedNodeCursor = oldSelectedNodeCursor.select("children").down();
	if (selectedNodeCursor !== null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		// Weirdness if use path directly
		let arrPath = Object.assign([], selectedNodeCursor.path);
		tree.set(["selectedNodePath"], arrPath);

		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		scrollSelectedNodeIntoView();
	}
}

function refreshVisible(tree) {
	_visiblePaths = [];
	let cursor = tree.select(["tree", "root"]);
	recurseRefreshVisible(null, cursor);
}

export function handleSelectNodeDownNew(tree) {
	refreshVisible(tree);

	if (nothingSelected(tree)) {
		return;
	}

	let selectedIndex;

	for (let i = 0; i < _visiblePaths.length; i++) {
		if (JSON.stringify(tree.select("selectedNodePath").get()) === JSON.stringify(_visiblePaths[i])) {
			selectedIndex = i;
		}
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	/*
	 if (isTreeRoot(oldSelectedNodeCursor)) {
	 return;
	 }
	 */

	//	var selectedNodeCursor = oldSelectedNodeCursor.right();
	//	if (selectedNodeCursor !== null) {


	if (selectedIndex < _visiblePaths.length - 1) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		let arrPath = Object.assign([], _visiblePaths[selectedIndex + 1]);
		tree.set(["selectedNodePath"], arrPath);


		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		scrollSelectedNodeIntoView();
	}

	/*
	 // Weirdness if use path directly
	 var arrPath = Object.assign([],selectedNodeCursor.path);
	 tree.set(['selectedNodePath'],arrPath);


	 var selectedNodePath = arrPath;
	 selectedNodePath.push("selected");
	 tree.set(selectedNodePath,true);
	 */
	//	}
}

export function handleSelectNodeUpNew(tree) {
	_visiblePaths = [];
	let cursor = tree.select(["tree", "root"]);
	recurseRefreshVisible(null, cursor);

	if (nothingSelected(tree)) {
		return;
	}

	let selectedIndex;

	for (let i = 0; i < _visiblePaths.length; i++) {
		if (JSON.stringify(tree.select("selectedNodePath").get()) === JSON.stringify(_visiblePaths[i])) {
			selectedIndex = i;
		}
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}

	if (selectedIndex > 0) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		let arrPath = Object.assign([], _visiblePaths[selectedIndex - 1]);
		tree.set(["selectedNodePath"], arrPath);

		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		scrollSelectedNodeIntoView();
	}
}


export function handleSelectNodeDown(tree = state) {
	if (nothingSelected(tree)) {
		return;
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	if (isTreeRoot(oldSelectedNodeCursor)) {
		return;
	}

	let selectedNodeCursor = oldSelectedNodeCursor.right();
	if (selectedNodeCursor !== null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		// Weirdness if use path directly
		let arrPath = Object.assign([], selectedNodeCursor.path);
		tree.set(["selectedNodePath"], arrPath);

		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		console.log("Same?", selectedNodePath.join(":") === oldSelectedNodePath.join(":"));
		scrollSelectedNodeIntoView();
		return selectedNodePath.join(":") === oldSelectedNodePath.join(":");
	} else {
		return true;
	}
}

export function handleSelectNodeUp(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	if (isNothingSelected(oldSelectedNodeCursor)) {
		return;
	}
	if (isTreeRoot(oldSelectedNodeCursor)) {
		return;
	}

	let selectedNodeCursor = oldSelectedNodeCursor.left();
	if (selectedNodeCursor !== null) {
		oldSelectedNodePath.push("selected");
		tree.set(oldSelectedNodePath, false);

		// Weirdness if use path directly
		let arrPath = Object.assign([], selectedNodeCursor.path);
		tree.set(["selectedNodePath"], arrPath);

		let selectedNodePath = arrPath;
		selectedNodePath.push("selected");
		tree.set(selectedNodePath, true);
		scrollSelectedNodeIntoView();
	}
}

function getTreeRoot(tree) {
	return tree.select(["tree", "root"]);
}


function nothingSelected(tree) {
	let oldSelectedNodePath = tree.select("selectedNodePath").get();
	let oldSelectedNodeCursor = tree.select(oldSelectedNodePath);

	return isNothingSelected(oldSelectedNodeCursor);
}

function isNothingSelected(cursor) {
	if (state.get("editingNodePath")) {
		return true;
	}

	return cursor.path.length === 0;
}

function isTreeRoot(cursor) {
	return cursor.path.length === 2;
}

function isNodeExpanded(cursor) {
	return cursor.get().expanded === true;
}

export function handleCollapseAll(tree) {
	const checklist = tree.get(["tree", "root"]);

	if (!checklist.hasOwnProperty("children") || checklist.children.length === 0) {
		return;
	}

	let cursor = tree.select(tree.select("selectedNodePath").get());

	if (cursor.path.length === 0) {
		cursor = getTreeRoot(tree);
	}

	if (cursor.select("children").isBranch()) {
		cursor.set("expanded", false);
		recurseCollapse(cursor.select("children").down());
	}
}

export function handleExpandAll(tree) {
	const checklist = tree.get(["tree", "root"]);

	if (!checklist.hasOwnProperty("children") || checklist.children.length === 0) {
		return;
	}

	let cursor = tree.select(tree.select("selectedNodePath").get());

	if (cursor.path.length === 0) {
		cursor = getTreeRoot(tree);
	}

	if (cursor.select("children").isBranch()) {
		cursor.set("expanded", true);
		recurseExpand(cursor.select("children").down());
	}
}

export function handleExpandNodeAll(cursor) {
	let cursorRoot = state.select(["tree", "root"]);

	cursorRoot.set("expanded", true);
	if (cursor.select("children").isBranch()) {
		cursor.set("expanded", true);
		recurseExpand(cursor.select("children").down());
	}
}

export function handleToggleNode(cursor) {
	cursor.set("expanded", !cursor.get("expanded"));
}

export function initializeNodeState(node) {
	if (node && node.id) {
		const appStateNodePath = ["appState", "nodes", node.id];
		const appStateNodeCursor = state.select(appStateNodePath);
		if (!appStateNodeCursor.exists()) {
			state.set(appStateNodePath, {});
		}
	}
}

export function handleEditNode(path, edit = true) {

	const currentEditingNodePath = state.get("editingNodePath");
	if (edit && currentEditingNodePath && path && currentEditingNodePath.join(":") === path.join(":")) {
		// Don't select again if already selected.
		return;
	}
	if (currentEditingNodePath) {
		const _path = currentEditingNodePath.concat("editing");
		console.log("Unset editor for ", _path);
		state.set(_path, false);
	}
	if (edit) {
		if (!path) {
			path = state.get("selectedNodePath");
		}
		if (path && _.last(path) === "selected") {

			path = _.dropRight(path);
		}
		const cursor = state.select(path);
		const selectPath = _.cloneDeep(path);
		console.log("BEFORE:", path);
		handleSelectNodeAtPath(state, selectPath);
		if (path && _.last(path) === "selected") {

			path = _.dropRight(path);
		}
		console.log("AFTER ", path);
		cursor.set("editing", true);
		state.set("editingNodePath", path);
		const appStateNodePath = ["appState", "nodes", cursor.get("id")];
		console.log("appStateNodePath=", appStateNodePath, cursor.get());
		const appStateNodeCursor = state.select(appStateNodePath);
		if (!appStateNodeCursor.exists()) {
			state.set(appStateNodePath, {});
		}
	} else {
		state.set(["appState", "forceEditorRefresh"], state.get(["appState", "forceEditorRefresh"]) + 1);
		state.set("editingNodePath", null);
	}

}

export function handleExpandNode(cursor) {
	cursor.set("expanded", true);
}

export function handleCollapseNode(cursor) {
	cursor.set("expanded", false);
}

export function handleRemoveNode(tree, cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	unselectNode(tree);
	const node = cursor.get();
	if (node.entity && !_.isEmpty(node.entity.entityId)) {
		const entityId = node.entity.entityId;
		// The id is empty and needs to be removed
		tree.unset(["tree", "variablesMap", entityId]);
		let varIdx = -1;
		tree.get(["tree", "variables"]).forEach((variable, idx) => {
			if (variable.entityId === entityId) {
				varIdx = idx;
			}
		});
		if (varIdx > -1) {
			tree.splice(["tree", "variables"], [varIdx, 1]);
		}
	}


	let index = cursor.path[cursor.path.length - 1];
	cursor.up().splice([index, 1]);

	saveUndoState();
}

export function handleCut(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	let cursor = tree.select(tree.select("selectedNodePath").get());
	let node = cursor.get();

	// Cutting the root tree node is not allowed
	if (node && node.entity.type !== "checklist") {

		tree.set("clipboardMode", "cut");
		tree.set("clipboard", node);
		tree.set(["clipboardExternalResources"], getExternalResources(true));
		tree.set(["clipboardRelatedItems"], getRelatedItems(true));
		tree.set(["clipboard", "selected"], false);

		let index = cursor.path[cursor.path.length - 1];

		unselectNode(tree);

		cursor.up().splice([index, 1]);

		saveUndoState();
	}
}

export function handleCopy(tree) {
	if (nothingSelected(tree)) {
		return;
	}

	tree.set("clipboardMode", "copy");
	let cursor = tree.select(tree.select("selectedNodePath").get());
	//tree.set("clipboard",tree.select('selectedNodePath').get());

	tree.set("clipboard", cursor.get());
	tree.set(["clipboardExternalResources"], getExternalResources(true));
	tree.set(["clipboardRelatedItems"], getRelatedItems(true));
	//saveUndoState();
}

function canPaste(tree) {
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let srcNode = cursor.get();

	let pasteNode = tree.get("clipboard");

	if (!pasteNode.hasOwnProperty("entity")) {
		return false;
	} else if (pasteNode.entity.type === "checklist") {
		return false;
		// If pasting on the same type
	} else if (srcNode.entity.type === pasteNode.entity.type) {
		return true;
	} else if (srcNode.entity.type.startsWith("item") && pasteNode.entity.type.startsWith("item")) {
		return true;
	} else {
		if (srcNode.entity.type === "checklist" && srcNode.children.length === 0) {
			return true;
		} else if (pasteNode.entity.type === "list" && srcNode.entity.type === "checklist") {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "list") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type === "section" && srcNode.entity.type === "checklist") {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "section") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type.startsWith("item") && srcNode.entity.type.startsWith("item")) {
			if ((srcNode.children.length > 0 && srcNode.children[0].entity.type === "list") || (srcNode.length === 0)) {
				return true;
			}
		} else if (pasteNode.entity.type === "section" && srcNode.entity.type === "list") {
			return true;
		} else if (pasteNode.entity.type.startsWith("item") && srcNode.entity.type === "section") {
			return true;
		}
	}

	showError("Invalid", "You can't paste this here.");

	return false;
}

function finalizePasteEntries(pastedExternalResources, pastedRelatedItems) {
	// Should have new map of pasted entries, just need to convert to array and add to pasted external references
	// For that convert destination resources to map, merge these, then write back out
	const externalResources = getExternalResources(true);
	const dstExternalResources = Object.assign({}, externalResources, pastedExternalResources);

	// Write out external resources
	const _externalResources = [];
	for (const [key, value] of Object.entries(dstExternalResources)) {
		console.log(`${key}: ${value}`);
		_externalResources.push(value);
	}

	console.log("!$!$!$ ARRAY", _externalResources);
	state.set(EXTERNAL_RESOURCES_STATE_PATH, _externalResources);

	const relatedItems = getRelatedItems(true);
	const dstRelatedItems = Object.assign({}, relatedItems, pastedRelatedItems);

	// Write out related items
	const _relatedItems = [];
	for (const [key, value] of Object.entries(dstRelatedItems)) {
		console.log(`${key}: ${value}`);
		_relatedItems.push(value);
	}

	console.log("!$!$!$ ARRAY", _externalResources);
	state.set(RELATED_ITEMS_STATE_PATH, _relatedItems);
}

// TODO: Try to be clever for default add when not a category
export function handlePaste(tree, positionType = "lastChild") {
	// Can only paste a list onto a checklist where all children are lists or another list
	// Can only paste a section onto a list where are children are sections or another section
	// Can only paste an item onto a section where all children are items or another item
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPaste(tree)) {
		return;
	}

	let newNode;

	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		console.log(`Handle paste, clipboardMode: ${tree.get("clipboardMode")}`);

		//console.log(`Handle paste, search for entity in tree by guid: ${node.entity.guid} ${entityUtils.findEntityByGuid(tree.get(["tree", "root"]), node.entity.guid)}`)

		// If node isn't currently in the tree for cut, paste node as-is, otherwise, create copy of the node to avoid duplicate GUIDs
		if (tree.get("clipboardMode") === "cut" && !entityUtils.findEntityByGuid(tree.get(["tree", "root"]), node.entity.guid)) {
			console.log(`Handle paste from cut by using clipboard node as-is, entity not found in tree`);
			newNode = node;
		} else {
			console.log(`Handle paste do copy, clipboardMode: ${tree.get("clipboardMode")}`);
			newNode = deepCloneNodeFromClipboard(tree, node);
			/*
			const srcExternalResources = tree.get("clipboardExternalResources");
			const srcRelatedItems = tree.get("clipboardRelatedItems");

			let entityId = utils.generateUUID();
			const guid = utils.generateUUID();
			newNode = _.cloneDeep(node);
			newNode.entity.entityId = entityId;
			newNode.entity.guid = guid;

			newNode.entity = fixIftttStructureIds(newNode.entity);

			if (newNode.entity.groupNames && newNode.entity.groupNames.indexOf("#internal-templates") > -1) {
				newNode.entity.groupNames.splice(newNode.entity.groupNames.indexOf("#internal-templates"), 1);
			}

			const pastedExternalResources = {};
			const pastedRelatedItems = {};
			recurseEntityProps(newNode.entity, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);

			//newNode.entity.id = entityId;
			newNode.selected = false;

			if (newNode.hasOwnProperty("children")) {
				recurseUpdateIds(newNode.children);
				recursePasteEntries(newNode.children, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
			}

			finalizePasteEntries(pastedExternalResources, pastedRelatedItems);
			*/
		}
	}

	/*
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}
	*/

	let index, arrPath;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === newNode.entity.type || (cursor.get().entity.type.startsWith("item") && newNode.entity.type.startsWith("item"))) {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
			case "firstChild":
				cursor.set("expanded", true);
				cursor.select("children").unshift(newNode);
				break;
			case "lastChild":
				cursor.set("expanded", true);
				cursor.select("children").push(newNode);
				break;
			case "before":
				unselectNode(tree);
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index, 0, newNode]);
				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				break;
			case "after":
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, newNode]);
				break;
		}
	}

	saveUndoState();
}

export function CSVToArray(CSV_string, delimiter) {
	delimiter = (delimiter || ","); // user-supplied delimeter or default comma

	var pattern = new RegExp( // regular expression to parse the CSV values.
		( // Delimiters:
			"(\\" + delimiter + "|\\r?\\n|\\r|^)" +
			// Quoted fields.
			"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
			// Standard fields.
			"([^\"\\" + delimiter + "\\r\\n]*))"
		), "gi"
	);

	var rows = [[]];  // array to hold our data. First row is column headers.
	// array to hold our individual pattern matching groups:
	var matches = false; // false if we don't find any matches
	// Loop until we no longer find a regular expression match
	while (matches = pattern.exec(CSV_string)) { // eslint-disable-line
		var matched_delimiter = matches[1]; // Get the matched delimiter
		// Check if the delimiter has a length (and is not the start of string)
		// and if it matches field delimiter. If not, it is a row delimiter.
		if (matched_delimiter.length && matched_delimiter !== delimiter) {
			// Since this is a new row of data, add an empty row to the array.
			rows.push([]);
		}
		var matched_value;
		// Once we have eliminated the delimiter, check to see
		// what kind of value was captured (quoted or unquoted):
		if (matches[2]) { // found quoted value. unescape any double quotes.
			matched_value = matches[2].replace(
				new RegExp("\"\"", "g"), "\""
			);
		} else { // found a non-quoted value
			matched_value = matches[3];
		}
		// Now that we have our value string, let's add
		// it to the data array.
		rows[rows.length - 1].push(matched_value);
	}
	return rows; // Return the parsed data Array
}

export function arrayToJson(arr) {
	const arrResult = [];

	// Keys
	const keys = arr[0];

	// Loop through records
	for (let i = 1; i < arr.length; i++) {
		const obj = {};

		const record = arr[i];

		for (let j = 0; j < record.length; j++) {
			const field = record[j];

			obj[keys[j]] = field;
		}

		arrResult.push(obj);
	}

	return arrResult;
}


export function arrayToActivity(arr) {
	try {
		const activity = {
			id: generateUUID(),
			name: "Magic Activity",
			genre: "health",
			defaultView: "vertView",
			children: []
		};

		// Keys
		const keys = arr[0];

		let lastListName = "";

		let list;

		// Loop through records
		for (let i = 1; i < arr.length; i++) {
			const record = arr[i];

			const section = {
				type: "section",
				name: record[2],
				children: []
			}

			for (let j = 3; j < record.length; j++) {
				const field = record[j];

				const item = {
					type: "item",
					labelOnly: true,
					label1: keys[j],
					label2: field
				}

				section.children.push(item);

			}

			// When list name changes need a new list
			if (lastListName !== record[1]) {
				lastListName = record[1];

				list = {
					type: "list",
					name: record[1],
					children: []
				};

				list.children.push(section);
				activity.children.push(list);
			} else {
				list.children.push(section);
			}
		}

		return activity;
	} catch (err) {
		showError("Magic Paste Error", "There was an error performing the Magic Paste. Make sure the content you are pasting is in the correct format. Currently it should be copied from a spreadsheet where the first row defines the fields, and the rest of the rows define the content. Column 1 should be an ID. Column 2 should be the name of the List, and Column 3 should be the name of the Section. All columns after that should be Item content.");
		return null;
	}
}



export function handleMagicPaste(tree) {
	window.confirm("WARNING: The Magic Paste will overwrite all content in this activity. Do you want to proceed?", async () => {
		const text = await navigator.clipboard.readText();

		try {
			const jsonActivity = JSON.parse(text);

			if (jsonActivity !== null) {
				// Mock example
				// this.context.tree.set(["clipboardPasteText"], text);
				// const srcTree = transformToChecklistEntities({
				// 	id: generateUUID(),
				// 	name: "Test",
				// 	children: [
				// 		{
				// 			type: "list",
				// 			name: "List 1",
				// 			children: [
				// 				{
				// 					type: "section",
				// 					name: "Section 1",
				// 					children: [
				// 						{
				// 							type: "item",
				// 							label1: "Hello World!"
				// 						},
				// 						{
				// 							type: "item",
				// 							label1: "Hello Again"
				// 						}
				// 					]
				// 				}
				// 			]
				// 		}
				// 	]
				// });

				const srcTree = transformToChecklistEntities(jsonActivity);

				const oldSrcTree = tree.get(["tree"]);

				srcTree.root.id = oldSrcTree.root.id;
				srcTree.root.revision = oldSrcTree.root.revision;
				srcTree.root.revisionTs = oldSrcTree.root.revisionTs;

				srcTree.root.entity.aiAssistant = oldSrcTree.root.entity.aiAssistant;
				srcTree.root.entity.aiAssistantId = oldSrcTree.root.entity.aiAssistantId;
				srcTree.root.entity.aiAssistantFiles = oldSrcTree.root.entity.aiAssistantFiles;
				srcTree.root.entity.aiAssistantChecklistFiles = oldSrcTree.root.entity.aiAssistantChecklistFiles;

				srcTree.root.entity.trackData = false;

				srcTree.root.selected = true;
				srcTree.root.expanded = true;
				resetTree(srcTree.root.children);
				editorActions.resetActivityEditMetaData();
				editorActions.setActivityMetaData("revision", srcTree.root.revision);
				tree.set("selectedChecklistId", srcTree.root.id);
				tree.set("selectedNodePath", ["tree", "root"]);
				tree.set("history", []);
				tree.set("historyIndex", -1);
				tree.set(["tree"], srcTree);
			}
		} catch (err) {
			try {
				// Convert content to array
				const arrContent = CSVToArray(text, "\t");
				const jsonActivity = arrayToActivity(arrContent);

				// console.log("JSON ACTIVITY", jsonActivity);

				if (jsonActivity !== null) {
					// Mock example
					// this.context.tree.set(["clipboardPasteText"], text);
					// const srcTree = transformToChecklistEntities({
					// 	id: generateUUID(),
					// 	name: "Test",
					// 	children: [
					// 		{
					// 			type: "list",
					// 			name: "List 1",
					// 			children: [
					// 				{
					// 					type: "section",
					// 					name: "Section 1",
					// 					children: [
					// 						{
					// 							type: "item",
					// 							label1: "Hello World!"
					// 						},
					// 						{
					// 							type: "item",
					// 							label1: "Hello Again"
					// 						}
					// 					]
					// 				}
					// 			]
					// 		}
					// 	]
					// });

					const srcTree = transformToChecklistEntities(jsonActivity);

					const oldSrcTree = tree.get(["tree"]);

					srcTree.root.id = oldSrcTree.root.id;
					srcTree.root.revision = oldSrcTree.root.revision;
					srcTree.root.revisionTs = oldSrcTree.root.revisionTs;

					srcTree.root.entity.aiAssistant = oldSrcTree.root.entity.aiAssistant;
					srcTree.root.entity.aiAssistantId = oldSrcTree.root.entity.aiAssistantId;
					srcTree.root.entity.aiAssistantFiles = oldSrcTree.root.entity.aiAssistantFiles;
					srcTree.root.entity.aiAssistantChecklistFiles = oldSrcTree.root.entity.aiAssistantChecklistFiles;

					srcTree.root.entity.trackData = false;

					srcTree.root.selected = true;
					srcTree.root.expanded = true;
					resetTree(srcTree.root.children);
					editorActions.resetActivityEditMetaData();
					editorActions.setActivityMetaData("revision", srcTree.root.revision);
					tree.set("selectedChecklistId", srcTree.root.id);
					tree.set("selectedNodePath", ["tree", "root"]);
					tree.set("history", []);
					tree.set("historyIndex", -1);
					tree.set(["tree"], srcTree);
				}
			} catch (err) {
				showError2("Error", "There was an error with the Magic Paste. Make sure you had a proper structure you were copying from.\n\n" + err.message);
			}
		}
	});
}

// Only allow to paste as last child if checklist node is selected, nextSibling if List node is selected
export function handlePasteLists(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}

	if (!canPasteLists(tree)) {
		return;
	}

	let newNode;

	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");

		console.log(`Handle paste lists, clipboardMode: ${tree.get("clipboardMode")}`);

		//console.log(`Handle paste lists, search for entity in tree by guid: ${node.entity.guid} ${entityUtils.findEntityByGuid(tree.get(["tree", "root"]), node.entity.guid)}`)

		// If node isn't currently in the tree for cut, paste node as-is, otherwise, create copy of the node to avoid duplicate GUIDs.
		// Lists for cut can only be done one at a time so we can handle this the same way as handlePaste
		if (tree.get("clipboardMode") === "cut" && !entityUtils.findEntityByGuid(tree.get(["tree", "root"]), node.entity.guid)) {
			console.log(`Handle paste lists from cut by using clipboard node as-is, entity not found in tree`);
			newNode = node;
		} else {
			console.log(`Handle paste lists do copy, clipboardMode: ${tree.get("clipboardMode")}`);
			newNode = deepCloneNodeFromClipboard(tree, node);
			/*
			const srcExternalResources = tree.get("clipboardExternalResources");
			const srcRelatedItems = tree.get("clipboardRelatedItems");

			let entityId = utils.generateUUID();
			const guid = utils.generateUUID();
			newNode = _.cloneDeep(node);
			newNode.entity.entityId = entityId;
			newNode.entity.guid = guid;
			newNode.entity = fixIftttStructureIds(newNode.entity);

			if (newNode.entity.groupNames && newNode.entity.groupNames.indexOf("#internal-templates") > -1) {
				newNode.entity.groupNames.splice(newNode.entity.groupNames.indexOf("#internal-templates"), 1);
			}

			const pastedExternalResources = {};
			const pastedRelatedItems = {};
			recurseEntityProps(newNode.entity, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);

			//newNode.entity.id = entityId;
			newNode.selected = false;

			if (newNode.hasOwnProperty("children")) {
				recurseUpdateIds(newNode.children);
				recursePasteEntries(newNode.children, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
			}

			finalizePasteEntries(pastedExternalResources, pastedRelatedItems);
			*/
		}
	}

	/*
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}
	*/

	let index, arrPath;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === "list") {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
			case "firstChild":
				cursor.set("expanded", true);
				cursor.select("children").unshift(newNode);
				break;
			case "lastChild":
				cursor.set("expanded", true);
				//cursor.select("children").push(newNode);

				// if selected node is a List then just add the List otherwise add the children	
				if (newNode.entity.type === "list") {
					cursor.select("children").push(newNode);
				} else {
					cursor.select("children").concat(newNode.children);
				}

				break;
			case "before":
				unselectNode(tree);
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index, 0, newNode]);
				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				break;
			case "after":
				// if selected node is a List then just add the List otherwise add the children	
				if (newNode.entity.type === "list") {
					index = cursor.path[cursor.path.length - 1];
					cursor.up().splice([index + 1, 0, newNode]);
				} else {
					let lists = cursor.up().get();

					index = cursor.path[cursor.path.length - 1];

					lists.splice(index + 1, 0, ...newNode.children);

					console.log(lists);

					cursor.up().set(["children"], lists);
				}

				break;
		}
	}

	saveUndoState();
}

function canPasteLists(tree) {
	// Check source content
	// All children must be a List node

	// Check destination
	// If Checklist node then append to children
	// If List node then append after selected

	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list") {
		alert("You have not copied any Lists to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" || selectedNode.entity.type === "list") {
		return true;

	}

	alert("You can only paste Lists on the Actvitiy node (top-level root node) or another List node.");

	return false;
}

export function handlePasteSections(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPasteSections(tree)) {
		return;
	}

	let newNode;
	let newSectionsChildren = [];

	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");
		const authoringActivity = tree.get(["tree", "root"]);

		console.log(`Handle paste sections, clipboardMode: ${tree.get("clipboardMode")}`);

		if (tree.get("clipboardMode") === "cut" &&
			node.entity.type === "section" &&
			!entityUtils.findEntityByGuid(authoringActivity, node.entity.guid)) {
			// If node is section and isn't currently in the tree for cut, paste node as-is

			console.log(`Handle paste sections from cut single section clipboard node as-is, entity not found in tree`);
			newNode = node;

		} else if (tree.get("clipboardMode") === "cut" &&
			node.entity.type === "list") {

			console.log(`Handle paste sections cut from from list`);

			// Node is list, but still doing a cut, get children and replace existing sections with clones
			newNode = node;

			if (newNode.hasOwnProperty("children")) {
				recurseToSectionsChildren(newNode.children, newSectionsChildren);

				// For each section, check if GUID exists in tree, if so, clone the section
				for (let i = 0; i < newSectionsChildren.length; i++) {
					const section = newSectionsChildren[i];

					if (entityUtils.findEntityByGuid(authoringActivity, section.entity.guid)) {
						// Replace section with clone since it already exists in tree
						console.log(`Pasted section already exists: ${section.entity.guid}, create copy instead`);
						newSectionsChildren[i] = deepCloneNodeFromClipboard(tree, section);
					}
				}
			}

		} else {
			// Do copy otherwise

			/*
			const srcExternalResources = tree.get("clipboardExternalResources");
			const srcRelatedItems = tree.get("clipboardRelatedItems");

			let entityId = utils.generateUUID();
			const guid = utils.generateUUID();
			newNode = _.cloneDeep(node);
			newNode.entity.entityId = entityId;
			newNode.entity.guid = guid;
			newNode.entity = fixIftttStructureIds(newNode.entity);

			if (newNode.entity.groupNames && newNode.entity.groupNames.indexOf("#internal-templates") > -1) {
				newNode.entity.groupNames.splice(newNode.entity.groupNames.indexOf("#internal-templates"), 1);
			}

			const pastedExternalResources = {};
			const pastedRelatedItems = {};
			recurseEntityProps(newNode.entity, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);

			newNode.selected = false;

			if (newNode.hasOwnProperty("children")) {
				recurseUpdateIds(newNode.children);
				recursePasteEntries(newNode.children, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
			}

			finalizePasteEntries(pastedExternalResources, pastedRelatedItems);
			*/
			console.log(`Handle paste sections do copy, clipboardMode: ${tree.get("clipboardMode")}`);

			newNode = deepCloneNodeFromClipboard(tree, node);

			if (newNode.hasOwnProperty("children")) {
				// Need to recurse from top and push each section into children arr
				recurseToSectionsChildren(newNode.children, newSectionsChildren);
			}
		}
	}

	/*
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}
	*/

	let index;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type === "section") {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
			case "lastChild":
				// if selected node is a Section then just add the Section otherwise add the children	
				if (newNode.entity.type === "section") {
					cursor.select("children").push(newNode);
				} else {
					cursor.select("children").concat(newSectionsChildren);
				}

				cursor.set("expanded", true);
				break;
			case "after":
				// if selected node is a List then just add the List otherwise add the children	
				if (newNode.entity.type === "section") {
					index = cursor.path[cursor.path.length - 1];
					cursor.up().splice([index + 1, 0, newNode]);
				} else {
					let sections = cursor.up().get();

					index = cursor.path[cursor.path.length - 1];

					sections.splice(index + 1, 0, ...newSectionsChildren);

					console.log(sections);

					cursor.up().set(["children"], sections);
				}




				break;
		}
	}

	saveUndoState();
}

function canPasteSections(tree) {
	// Check source content
	// All children must be a Section node

	// Check destination
	// If Checklist node then add list and append to children
	// If List node then append to children
	// If Section node then append after selected
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list" && clipboardNode.entity.type !== "section") {
		alert("You have not copied any Sections to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" && (selectedNode.children.length === 0 || selectedNode.children[0].entity.type === "section")) {
		return true;
	}

	if (selectedNode.entity.type === "list" || selectedNode.entity.type === "section") {
		return true;

	}

	alert("You can only paste Sections on a List node or another Section node or an Activity node (top-level root node) that already has Sections or is empty.");

	return false;
}

export function recurseToSectionsChildren(arr, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].entity.type === "list") {
			console.log("List: " + arr[i].entity.label);
			//resArr = resArr.concat(Object.assign([], arr[i].children));
		} else if (arr[i].entity.type === "section") {
			console.log("Section: " + arr[i].entity.label);
			resArr.push(Object.assign({}, arr[i]));
		} else if (arr[i].entity.type.startsWith("item")) {
			console.log("Item: " + arr[i].entity.label);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToSectionsChildren(arr[i].children, resArr);
		}
	}
}

export function handlePasteItems(tree, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}
	if (tree.get("clipboard") === null) {
		return;
	}
	if (!canPasteItems(tree)) {
		return;
	}

	let newNode;
	let newItemsChildren = [];

	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		//var copiedNodePath = tree.get("clipboard");

		let node = tree.get("clipboard");
		const authoringActivity = tree.get(["tree", "root"]);

		console.log(`Handle paste items, clipboardMode: ${tree.get("clipboardMode")}`);

		if (tree.get("clipboardMode") === "cut" &&
			node.entity.type === "item" &&
			!entityUtils.findEntityByGuid(authoringActivity, node.entity.guid)) {
			// If node is item and isn't currently in the tree for cut, paste node as-is

			console.log(`Handle paste items from cut single item clipboard node as-is, entity not found in tree`);
			newNode = node;

		} else if (tree.get("clipboardMode") === "cut" &&
			(node.entity.type === "list" || node.entity.type === "section")) {

			console.log(`Handle paste sections cut from from ${node.entity.type}`);

			// Node is list or section, but still doing a cut, get children and replace existing sections with clones
			newNode = node;

			if (newNode.hasOwnProperty("children")) {
				recurseToItemsChildren(newNode.children, newItemsChildren);

				// For each section, check if GUID exists in tree, if so, clone the section
				for (let i = 0; i < newItemsChildren.length; i++) {
					const item = newItemsChildren[i];

					if (entityUtils.findEntityByGuid(authoringActivity, item.entity.guid)) {
						// Replace item with clone since it already exists in tree
						console.log(`Pasted item already exists: ${item.entity.guid}, create copy instead`);
						newItemsChildren[i] = deepCloneNodeFromClipboard(tree, item);
					}
				}
			}
		} else {
			/*
			const srcExternalResources = tree.get("clipboardExternalResources");
			const srcRelatedItems = tree.get("clipboardRelatedItems");
	
			let entityId = utils.generateUUID();
			const guid = utils.generateUUID();
			newNode = _.cloneDeep(node);
			newNode.entity.entityId = entityId;
			newNode.entity.guid = guid;
			newNode.selected = false;
			newNode.entity = fixIftttStructureIds(newNode.entity);
	
			if (newNode.entity.groupNames && newNode.entity.groupNames.indexOf("#internal-templates") > -1) {
				newNode.entity.groupNames.splice(newNode.entity.groupNames.indexOf("#internal-templates"), 1);
			}
	
			const pastedExternalResources = {};
			const pastedRelatedItems = {};
			recurseEntityProps(newNode.entity, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
	
			if (newNode.hasOwnProperty("children")) {
				recurseUpdateIds(newNode.children);
				recursePasteEntries(newNode.children, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
			}
	
			finalizePasteEntries(pastedExternalResources, pastedRelatedItems);
			*/
			console.log(`Handle paste items do copy, clipboardMode: ${tree.get("clipboardMode")}`);
			newNode = deepCloneNodeFromClipboard(tree, node);

			if (newNode.hasOwnProperty("children")) {
				// Need to recurse from top and push each section into children arr
				recurseToItemsChildren(newNode.children, newItemsChildren);
			}
		}
	}

	/*	
	} else if (tree.get("clipboardMode") === "cut") {
		newNode = tree.get("clipboard");
	}
	*/

	let index;
	if (tree.get("clipboardMode") === "copy" || tree.get("clipboardMode") === "cut") {
		let cursor = tree.select(tree.select("selectedNodePath").get());
		// If pasting on the same type
		if (cursor.get().entity.type.startsWith("item")) {
			if (positionType === "lastChild") {
				positionType = "after";
			}
		}
		// eslint-disable-next-line default-case
		switch (positionType) {
			case "lastChild":
				cursor.set("expanded", true);
				// if selected node is an Item then just add the Section otherwise add the children	
				if (newNode.entity.type.startsWith("item")) {
					cursor.select("children").push(newNode);
				} else {
					cursor.select("children").concat(newItemsChildren);
				}
				break;
			case "after":
				// if selected node is an Item then just add the Item otherwise add the children	
				if (newNode.entity.type.startsWith("item")) {
					index = cursor.path[cursor.path.length - 1];
					cursor.up().splice([index + 1, 0, newNode]);
				} else {
					let items = cursor.up().get();

					index = cursor.path[cursor.path.length - 1];

					items.splice(index + 1, 0, ...newItemsChildren);

					console.log(items);

					cursor.up().set(["children"], items);
				}

				break;
		}
	}

	saveUndoState();
}

export function recurseToItemsChildren(arr, resArr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].entity.type === "list") {
			console.log("List: " + arr[i].entity.label);
		} else if (arr[i].entity.type === "section") {
			console.log("Section: " + arr[i].entity.label);
		} else if (arr[i].entity.type.startsWith("item")) {
			console.log("Item: " + arr[i].entity.label);
			resArr.push(Object.assign({}, arr[i]));
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseToItemsChildren(arr[i].children, resArr);
		}
	}
}

function canPasteItems(tree) {
	// Check source content
	// All children must be an Item node

	// Check destination
	// If Checklist node then append list and section then to children
	// If List node then append section then to children
	// If Section node then append to children
	// If Item node then append after selected
	let cursor = tree.select(tree.select("selectedNodePath").get());
	let selectedNode = cursor.get();
	let clipboardNode = tree.get("clipboard");

	if (!clipboardNode.hasOwnProperty("entity")) {
		return false;
	}

	if (clipboardNode.entity.type !== "checklist" && clipboardNode.entity.type !== "list" && clipboardNode.entity.type !== "section" && !clipboardNode.entity.type.startsWith("item")) {
		alert("You have not copied any Items to the clipboard.");
		return false;
	}

	if (selectedNode.entity.type === "checklist" && (selectedNode.children.length === 0 || selectedNode.children[0].entity.type.startsWith("item"))) {
		return true;
	}

	if (selectedNode.entity.type === "section" || selectedNode.entity.type.startsWith("item")) {
		return true;

	}

	alert("You can only paste Items on a Section node or another Item node or an Activity node (top-level root node) that already has Items or is empty.");

	return false;
}

// Need to deal with named id's as well
function recurseUpdateIds(arr, opts) {
	for (let i = 0; i < arr.length; i++) {
		let entityId = utils.generateUUID();
		let guid = utils.generateUUID();
		arr[i].entity.entityId = entityId;
		arr[i].entity.guid = guid;
		arr[i].entity = fixIftttStructureIds(arr[i].entity);
		if (arr[i].hasOwnProperty("children")) {
			recurseUpdateIds(arr[i].children);
		}
	}
}

function recursePasteEntries(arr, externalResources, pastedExternalResources, relatedItems, pastedRelatedItems) {
	for (let i = 0; i < arr.length; i++) {
		recurseEntityProps(arr[i].entity, externalResources, pastedExternalResources, relatedItems, pastedRelatedItems);

		if (arr[i].hasOwnProperty("children")) {
			recursePasteEntries(arr[i].children, externalResources, pastedExternalResources, relatedItems, pastedRelatedItems);
		}
	}
}

function recurseEntityProps(entity, externalResources, pastedExternalResources, relatedItems, pastedRelatedItems) {
	// Loop through every property of an entity
	for (const [key, value] of Object.entries(entity)) {
		console.log(`${key}: ${value}`);

		// If value has an array then can loop through them...e.g. choiceItems, pickerItems
		if (Array.isArray(value)) {
			value.forEach((item) => {
				recurseEntityProps(item, externalResources, pastedExternalResources, relatedItems, pastedRelatedItems);
			});
			// If valid drive entry
		} else if (typeof value === 'object' && value !== null && value.hasOwnProperty("type") && value.hasOwnProperty("refId") && externalResources.hasOwnProperty(value.refId)) {
			pastedExternalResources[value.refId] = externalResources[value.refId];
			// If has related item refs	
		} else if (key === "relatedItemRefs" && value !== "") {
			// Can be one to many related item refs
			const relatedItemRefsParts = value.split(",");

			relatedItemRefsParts.forEach((relatedItemRef) => {
				// This is cool, related items can also have drive entries so need to add those if that is the case
				recurseEntityProps(relatedItems[relatedItemRef], externalResources, pastedExternalResources, relatedItems, pastedRelatedItems);

				pastedRelatedItems[relatedItemRef] = relatedItems[relatedItemRef];
			})
		}
	}
}

export function handleMoveNode(tree, type, srcIndex, dstIndex) {
	unselectNode(tree);

	if (type === "root") {
		var arrList = tree.get(["tree", "root", "children"]);

		arrList.splice(dstIndex, 0, arrList.splice(srcIndex, 1)[0]);

		tree.set(["tree", "root", "children"], Object.assign([], arrList));

		var arrPath = Object.assign([], ["tree", "root", "children", dstIndex]);

		handleSelectNodeAtPath(tree, arrPath);

		saveUndoState();
	} else {
		var entityChildren = { found: false };
		entityUtils.findEntityChildrenById(tree.get(["tree", "root", "children"]), type, entityChildren);
		if (entityChildren.found) {
			var arrNode = entityChildren.result;
			arrNode.splice(dstIndex, 0, arrNode.splice(srcIndex, 1)[0]);

			var entity = { found: false };
			entityUtils.findEntityIndicesById(tree.get(["tree", "root", "children"]), type, entity);

			if (entity.found) {
				if (entity.result.type === "list" && entity.result.listIndex > -1) {
					tree.set(["tree", "root", "children", entity.result.listIndex, "children"], Object.assign([], arrNode));
					const arrPathList = Object.assign([], ["tree", "root", "children", entity.result.listIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathList);
				} else if (entity.result.type === "section" && entity.result.listIndex > -1) {
					tree.set(["tree", "root", "children", entity.result.listIndex, "children", entity.result.sectionIndex, "children"], Object.assign([], arrNode));
					const arrPathSection = Object.assign([], ["tree", "root", "children", entity.result.listIndex, "children", entity.result.sectionIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathSection);
				} else if (entity.result.type === "section" && entity.result.listIndex === -1) {
					tree.set(["tree", "root", "children", entity.result.sectionIndex, "children"], Object.assign([], arrNode));
					const arrPathSection = Object.assign([], ["tree", "root", "children", entity.result.sectionIndex, "children", dstIndex]);
					handleSelectNodeAtPath(tree, arrPathSection);
				}

				saveUndoState();
			}
		}
	}
}

export function handleMoveNodeUp(tree, cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	// TODO: Quick fix...proper way to do is figure out how things get out of sync
	handleCollapseNode(cursor);

	let node = cursor.get();

	let index = cursor.path[cursor.path.length - 1];
	if (index > 0) {
		unselectNode(tree);
		cursor.up().splice([index, 1]);
		cursor.up().splice([index - 1, 0, node]);

		let arrPath = Object.assign([], cursor.path);
		arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] - 1;
		handleSelectNodeAtPath(tree, arrPath);

		saveUndoState();
	}
}

export function handleMoveNodeDown(tree, cursor) {
	if (nothingSelected(tree)) {
		return;
	}
	if (isTreeRoot(cursor)) {
		return;
	}

	// TODO: Quick fix...proper way to do is figure out how things get out of sync
	handleCollapseNode(cursor);
	let node = cursor.get();

	let index = cursor.path[cursor.path.length - 1];
	if (index < (cursor.up().get().length - 1)) {
		unselectNode(tree);
		cursor.up().splice([index, 1]);
		cursor.up().splice([index + 1, 0, node]);

		let arrPath = Object.assign([], cursor.path);
		arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
		handleSelectNodeAtPath(tree, arrPath);

		saveUndoState();
	}
}

export function handleAddCategory(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	let entity = entityUtils.addCategoryEntity(utils.generateUUID(), "(New Category)", true);
	let node = entityUtils.addCategoryNode(entity, false);

	if (cursor.get().entity.type !== "category") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	let index, arrPath;
	switch (positionType) {
		case "firstChild":
			cursor.set("expanded", true);
			cursor.select("children").unshift(node);
			break;
		case "lastChild":
			cursor.set("expanded", true);
			cursor.select("children").push(node);
			break;
		case "before":
			unselectNode(tree);
			index = cursor.path[cursor.path.length - 1];
			cursor.up().splice([index, 0, node]);
			arrPath = Object.assign([], cursor.path);
			arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
			handleSelectNodeAtPath(tree, arrPath);
			break;
		case "after":
			index = cursor.path[cursor.path.length - 1];
			cursor.up().splice([index + 1, 0, node]);
			break;
		default:
	}
	saveUndoState();
}

export function handleAddList(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	let entity = entityUtils.addListEntity(utils.generateUUID(), "", true);
	let node = entityUtils.addCategoryNode(entity, true);
	let sectionEntity = entityUtils.addSectionEntity(utils.generateUUID(), "");
	let sectionNode = entityUtils.addCategoryNode(sectionEntity, true);
	node.children.push(sectionNode);
	let itemEntity = entityUtils.addItemEntity(utils.generateUUID(), "", "", false, false, "");
	let itemNode = entityUtils.addItemNode(itemEntity);
	sectionNode.children.push(itemNode);
	if (cursor.get().entity.type === "list") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	let index, len, arrPath;
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type === "checklist") {
				cursor.set("expanded", true);
				cursor.select("children").unshift(node);
				scrollSelectedNodeIntoView();
				break;
			}
			break;
		case "lastChild":
			if (cursor.get().entity.type === "checklist") {
				if (cursor.get("children").length === 0 || cursor.get("children")[0].entity.type === "list") {
					unselectNode(tree);

					cursor.set("expanded", true);
					cursor.select("children").push(node);

					len = cursor.get("children").length;

					arrPath = Object.assign([], cursor.path);
					arrPath.push("children");
					arrPath.push(len - 1);
					handleSelectNodeAtPath(tree, arrPath);
					scrollSelectedNodeIntoView();
					// Migrate collection of items to this section
				} else if (cursor.get("children")[0].entity.type === "section") {
					node.expanded = true;
					node.children = cursor.get("children");
					cursor.select("children").splice([0, node.children.length]);

					unselectNode(tree);

					cursor.set("expanded", true);
					cursor.select("children").push(node);

					len = cursor.get("children").length;

					arrPath = Object.assign([], cursor.path);
					arrPath.push("children");
					arrPath.push(len - 1);
					handleSelectNodeAtPath(tree, arrPath);
					scrollSelectedNodeIntoView();
				} else if (cursor.get("children")[0].entity.type.startsWith("item")) {
					showError("Invalid", "You can only add a List when all of the children of this node are Sections. First add a Section then you will be able to add a List.");
				} else {
					showError("Invalid", "You can't add a List here. You can only add a List to the root node which is the Checklist.");
				}
				break;
			} else {
				showError("Invalid", "You can't add a List here. You can only add a List to the root node which is the Checklist.");
			}
			break;
		case "before":
			if (cursor.get().entity.type === "list") {
				unselectNode(tree);
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index, 0, node]);
				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				break;
			}
			break;
		case "after":
			if (cursor.get().entity.type === "list") {
				unselectNode(tree);

				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, node]);

				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				break;
			} else {
				showError("Invalid", "You can't add a List here. You can only add a List to the root node which is the Checklist.");
			}
			break;
		default:
	}
	saveUndoState();
}

export function handleAddSection(tree, cursor, positionType = "lastChild") {
	if (nothingSelected(tree)) {
		return;
	}

	let entity = entityUtils.addSectionEntity(utils.generateUUID(), "", true);
	let node = entityUtils.addCategoryNode(entity, true);
	let itemEntity = entityUtils.addItemEntity(utils.generateUUID(), "", "", false, false, "");
	let itemNode = entityUtils.addItemNode(itemEntity);
	node.children.push(itemNode);

	if (cursor.get().entity.type === "section") {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	let index, len, arrPath;
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type === "checklist" || cursor.get().entity.type === "list") {
				cursor.set("expanded", true);
				cursor.select("children").unshift(node);
				scrollSelectedNodeIntoView();
				break;
			}
			break;
		case "lastChild":
			if (cursor.get().entity.type === "checklist" || cursor.get().entity.type === "list") {
				// Check to see that first child is a isection
				if (cursor.get("children").length === 0 || cursor.get("children")[0].entity.type === "section") {
					unselectNode(tree);

					cursor.set("expanded", true);
					cursor.select("children").push(node);

					len = cursor.get("children").length;

					arrPath = Object.assign([], cursor.path);
					arrPath.push("children");
					arrPath.push(len - 1);
					handleSelectNodeAtPath(tree, arrPath);
					scrollSelectedNodeIntoView();
					// Migrate collection of items to this section
				} else if (cursor.get("children")[0].entity.type.startsWith("item")) {
					node.expanded = true;
					node.children = cursor.get("children");
					cursor.select("children").splice([0, node.children.length]);

					unselectNode(tree);

					cursor.set("expanded", true);
					cursor.select("children").push(node);

					len = cursor.get("children").length;

					arrPath = Object.assign([], cursor.path);
					arrPath.push("children");
					arrPath.push(len - 1);
					handleSelectNodeAtPath(tree, arrPath);
					scrollSelectedNodeIntoView();
				} else {
					showError("Invalid", "You can't add a Section here. A Section should be added to a List.");
				}

				break;
			} else {
				showError("Invalid", "You can't add a Section here. A Section should be added to a List.");
			}
			break;
		case "before":
			if (cursor.get().entity.type === "section") {
				unselectNode(tree);
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index, 0, node]);
				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				break;
			}
			break;
		case "after":
			if (cursor.get().entity.type === "section") {
				unselectNode(tree);

				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, node]);

				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				break;
			} else {
				showError("Invalid", "You can't add a Section here. A Section should be added to a List.");
			}
			break;
		default:
	}
	saveUndoState();
}

export function handleAddItem(tree, cursor, positionType = "lastChild", type = "item") {
	if (nothingSelected(tree)) {
		return;
	}

	let entityId = utils.generateUUID();


	let entity = entityUtils.addItemEntity(entityId, "", "", type === "itemLabelOnly" ? true : false, false, "", type === "itemLabelOnly" ? "item" : type);

	if (type === "itemLabelOnly") {
		type = "item";
	}

	let node = entityUtils.addItemNode(entity, false);

	if (cursor.get().entity.type.startsWith("item")) {
		if (positionType === "lastChild") {
			positionType = "after";
		}
	}
	let index, len, arrPath;
	switch (positionType) {
		case "firstChild":
			if (cursor.get().entity.type === "section" || cursor.get().entity.type === "checklist") {
				cursor.set("expanded", true);
				cursor.select("children").unshift(node);
				scrollSelectedNodeIntoView();
				handleChangeItemType(tree, type);
				break;
			} else {
				showError("Invalid", "You can't add an Item here. An Item should be added to a Section.");
			}
			break;
		case "lastChild":
			if (cursor.get().entity.type === "section" || cursor.get().entity.type === "checklist") {
				// Check to see that first child is an item
				if (cursor.get("children").length === 0 || cursor.get("children")[0].entity.type.startsWith("item")) {
					unselectNode(tree);

					cursor.set("expanded", true);
					cursor.select("children").push(node);

					len = cursor.get("children").length;

					arrPath = Object.assign([], cursor.path);
					arrPath.push("children");
					arrPath.push(len - 1);
					handleSelectNodeAtPath(tree, arrPath);
					scrollSelectedNodeIntoView();
					handleChangeItemType(tree, type);

				} else {
					showError("Invalid", "You can't add an Item here. An Item should be added to a Section.");
				}
				break;
			} else {
				showError("Invalid", "You can't add an Item here. An Item should be added to a Section.");
			}
			break;
		case "before":
			if (cursor.get().entity.type.startsWith("item")) {
				unselectNode(tree);
				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index, 0, node]);
				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				handleChangeItemType(tree, type);
				break;
			}
			break;
		case "after":
			if (cursor.get().entity.type.startsWith("item")) {
				unselectNode(tree);

				index = cursor.path[cursor.path.length - 1];
				cursor.up().splice([index + 1, 0, node]);

				arrPath = Object.assign([], cursor.path);
				arrPath[arrPath.length - 1] = arrPath[arrPath.length - 1] + 1;
				handleSelectNodeAtPath(tree, arrPath);
				scrollSelectedNodeIntoView();
				handleChangeItemType(tree, type);
				break;
			} else {
				showError("Invalid", "You can't add an Item here. An Item should be added to a Section.");
			}
			break;
		default:
	}

	saveUndoState();
}

function isOffScreen(el, scrollView) {
	// console.log(el.offsetHeight, el.offsetTop, el.scrollTop, scrollView.offsetHeight, scrollView.clientHeight, scrollView.scrollHeight, scrollView.scrollTop);

	const elOffsetTop = el.offsetTop;
	const elOffsetHeight = el.offsetHeight;
	const scrollViewScrollTop = scrollView.scrollTop;
	// const scrollViewScrollHeight = scrollView.scrollHeight;
	const scrollViewOffsetHeight = scrollView.offsetHeight;

	return (elOffsetTop < scrollViewScrollTop) || (elOffsetHeight + elOffsetTop > scrollViewScrollTop + scrollViewOffsetHeight);
}

export function scrollSelectedNodeIntoView() {
	setTimeout(() => {
		const path = state.get("selectedNodePath");
		if (path && _.isArray(path)) {
			const scrollView = document.getElementById("editor-scroll-view");
			const el = document.getElementById(`node-${path.join(".")}`);
			if (el) {
				const offScreen = isOffScreen(el, scrollView);
				// const fullyVisible = inViewport(el, { offset: -100 });
				console.log("Off screen", offScreen);
				if (el && el.scrollIntoView && offScreen) {
					el.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			}
		}
	}, 100);
}

function recurseCollapse(cursor) {
	while (cursor !== null) {
		cursor.set("expanded", false);

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseCollapse(cursor.select("children").down());
		}

		cursor = cursor.right();
	}
}

function recurseExpand(cursor) {
	while (cursor !== null) {
		cursor.set("expanded", true);

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseExpand(cursor.select("children").down());
		}

		cursor = cursor.right();
	}
}

function recurseRefreshVisible(cursorParent, cursor) {
	while (cursor !== null) {
		if (cursor.get("expanded") === true || (cursorParent !== null && cursorParent.get("expanded") === true)) {
			//console.log(cursor.path);
			_visiblePaths.push(cursor.path);
		}

		if (cursor.get().hasOwnProperty("children") && cursor.get().children.length > 0) {
			recurseRefreshVisible(cursor, cursor.select("children").down());
		}

		if (cursor.path.length > 2) {
			cursor = cursor.right();
		} else {
			cursor = null;
		}
	}
}

export function receiveHistory(identityId) {
	awsS3.getHistory(state, identityId);

	// When gets history will set state which will cause re-render of history view
}

export function clearHistory(tree) {
	tree.set(["checklistHistory"], null);
}

export function openPreviewModal(tree, hit, scope) {
	// Get JSON and after retrieved set state

	awsS3.previewJsonFromS3(tree, env.s3.contentBucket, hit.id + ".json", scope, hit).then(() => {
		console.log("Select activity");
		tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, hit.id);
	});
}

export function showDocumentsSpinner() {
	state.set(["appState", "documents", "showSpinner"], true);
}

export function hideDocumentsSpinner() {
	state.set(["appState", "documents", "showSpinner"], false);
}

export function setDocumentsSpinnerMessage(message) {
	state.set(["appState", "documents", "spinnerMessage"], message);
}

export function openPreviewModalForActivities(tree, identityId, activityId, scope) {
	// Get JSON and after retrieved set state
	showDocumentsSpinner();
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);

	// Get JSON and after retrieved set state
	awsS3.previewJsonForIdentityFromS3(tree, env.s3.contentBucket, identityId, activityId + ".json", scope).finally(() => {
		hideDocumentsSpinner();
	});
}

export function closePreviewModal(tree) {
	try {
		if (window.speechSynthesis) {
			window.speechSynthesis.cancel();
		}
		if (window.audioFaultInterval) {
			clearInterval(window.audioFaultInterval);
			window.audioFaultInterval = null;
		}
		const audioElement = document.getElementById("page_audio");
		if (audioElement) {
			if (!audioElement.paused) {
				audioElement.pause();
				audioElement.currentTime = 0;
			}
			delete audioElement.src;
		}
	} catch (e) {
		console.warn(e);
	}
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, "");
	// tree.set(["appState", "previewChecklist", "showModal"], false);
	clearAppState(tree);
}

export function openStartupModal(tree) {
	tree.set(["appState", "startupModal", "showModal"], true);
}

export function closeStartupModal(tree, startupModalDontShowAgain) {
	// Use local storage
	utils.setLs(tree.get(["user", "username"]) + ";startupModalDontShowAgain", startupModalDontShowAgain);
	tree.set(["appState", "startupModal", "showModal"], false);
}


export function showWorkflowModalForMultiple(tree) {
	awsS3.showWorkflowForMultiple(tree, env.s3.contentBucket);
}

export async function saveWorkflowForMultiple(tree, data, fileName) {
	const orgId = tree.get(["selectedOrg", "id"])
	const identityId = tree.get(["user", "identityId"]);

	return await awsS3.saveWorkflow(env.s3.contentBucket, orgId, identityId, fileName, data);
}

export function closeWorkflowModalForMultiple(tree) {
	tree.set(["appState", "showWorkflowForMultiple", "showModal"], false);
}

export function showWorkflowModal(tree, url) {
	// Get JSON and after retrieved set state
	awsS3.showWorkflow(tree, env.s3.contentBucket, url);
}

export async function saveWorkflow(tree, data) {
	const orgId = tree.get(["selectedOrg", "id"])
	const identityId = tree.get(["user", "identityId"]);
	const fileName = tree.get(["appState", "showWorkflow", "url"]);

	return await awsS3.saveWorkflow(env.s3.contentBucket, orgId, identityId, fileName, data);
}

// Close either modal at once
export function closeWorkflowModal(tree) {
	tree.set(["appState", "showWorkflow", "showModal"], false);
	// tree.set(["appState", "showWorkflow", "checklist"], {});
	tree.set(["appState", "showWorkflow", "url"], "");
	tree.set(["appState", "showWorkflowForMultiple", "showModal"], false);
	// tree.set(["appState", "showWorkflowForMultiple", "checklist"], {});
}

export function showNotesModal(tree, hit) {
	// Get JSON and after retrieved set state
	tree.set(["appState", "showNotes", "instance"], hit);
	awsS3.showNotes(tree, env.s3.contentBucket, hit.url, hit.sessionName);
}

export function closeNotesModal(tree) {
	tree.set(["appState", "showNotes", "showModal"], false);
}

export function showStatusSpinner(tree = state) {
	console.log("SHOW STATUS SPINNER");
	tree.set(["appState", "status", "showSpinner"], true);
}
export function hideStatusSpinner(tree = state, delay = 0) {
	console.log("HIDE STATUS SPINNER");
	if (delay === 0) {
		tree.set(["appState", "status", "showSpinner"], false);
	} else {
		setTimeout(() => {
			tree.set(["appState", "status", "showSpinner"], false);
		}, delay);
	}
}
export function showDetailedStatusModal(tree, hit, allowEdit = true) {
	showStatusSpinner(tree);
	setTimeout(() => {
		tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, hit.id);
		tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, hit.instanceId);
		tree.set(PlayerPaths.SELECTED_ACTIVITY_IDENTITY_ID, hit.identityId);

		let statusDetailSessions = {};
		statusDetailSessions[hit.id] = [hit];

		tree.set(PlayerPaths.STATUS_DETAIL_SESSIONS, statusDetailSessions);

		// Get JSON and after retrieved set state
		awsS3.showDetailedStatus(tree, env.s3.contentBucket, hit.url, allowEdit);
	});

}

export function closeDetailedStatusModal(tree) {
	try {
		if (window.speechSynthesis) {
			window.speechSynthesis.cancel();
		}
		if (window.audioFaultInterval) {
			clearInterval(window.audioFaultInterval);
			window.audioFaultInterval = null;
		}
		const audioElement = document.getElementById("page_audio");
		if (audioElement) {
			if (!audioElement.paused) {
				audioElement.pause();
				audioElement.currentTime = 0;
			}
			delete audioElement.src;
		}
	} catch (e) {
		console.warn(e);
	}
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, "");
	tree.set(["appState", "showDetailedStatus", "showModal"], false);
}

export function showDetailedHistoryModal(tree, hit) {
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, hit.id);

	// Get JSON and after retrieved set state
	awsS3.showDetailedHistory(tree, env.s3.contentBucket, hit.url);
}

export function deleteStatusItem(url) {
	return awsS3.deleteObject(env.s3.contentBucket, url);
}

export function closeDetailedHistoryModal(tree) {
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, "");
	tree.set(["appState", "showDetailedHistory", "showModal"], false);
}


export async function channelExists(id) {
	return new Promise(async (resolve, reject) => {
		try {
			const filter = { id };
			const sort = [{ last_message_at: -1 }];
			const channels = await chatClient.queryChannels(filter, sort, {
				watch: false, // this is the default 
				state: false
			});
			if (channels.length > 0) {
				resolve(channels[0]);
			} else {
				resolve(null);
			}
		} catch (err) {
			console.log("ERROR " + err.message);
			reject(err);
		}
	});
}

export async function getChannelsFromInstances(instanceIds) {
	return new Promise(async (resolve, reject) => {
		try {
			const filter = { id: { $in: instanceIds } };

			const channels = await chatClient.queryChannels(filter, [{ last_message_at: -1 }], {
				watch: false, // this is the default 
				state: true,
				limit: 30
			});
			if (channels.length > 0) {
				resolve(channels);
			} else {
				resolve(null);
			}
		} catch (err) {
			console.log("ERROR " + err.message);
			reject(err);
		}
	});

}

export async function showCreateChatChannelModal(tree, hit) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
			bucket.getObject({ Key: `${hit.url}` }, async (err, data) => {
				if (err) {
					showError("Unable to get checklist", err);
					reject(err);
				} else {
					// Set previewChecklist
					let jsonChecklist = JSON.parse(data.Body.toString());
					// Should convert to make sure has at least one list and one section and set proper type
					jsonChecklist = normalizeActivity(jsonChecklist);

					let channel = null;
					try {
						channel = await channelExists("instance-" + hit.instanceId);
					} catch (err) {
						// No op
					}

					if (isOrganizationUser() && channel === null) {
						showInfo("Chat", "A Chat channel has not yet been created for this activity. Please contact your administrator for details.");
						return;
					}

					// Couldn't set in baobab
					global.streamChatActiveChannel = channel;
					// See if channel already exists

					tree.set(["appState", "showCreateChatChannel", "checklist"], jsonChecklist);
					tree.set(["appState", "showCreateChatChannel", "activeChannelId"], channel !== null ? channel.id : null);
					tree.set(["appState", "showCreateChatChannel", "instance"], hit);
					tree.set(["appState", "showCreateChatChannel", "showModal"], true);
					tree.set(["appState", "showCreateChatChannel", "url"], hit.url);

					tree.set(["appState", "tabChat"], "customer");

					// Also set invoice items if have them on an activity
					if (hit.hasOwnProperty("invoiceItems")) {
						tree.set(["appState", "activityInvoiceItems"], JSON.parse(JSON.stringify(hit.invoiceItems)));
					} else {
						tree.set(["appState", "activityInvoiceItems"], []);
					}

					let sessions = {};
					sessions[hit.id] = [hit];

					tree.set(["appState", "showCreateChatChannel", "sessions"], sessions);

					// Summary content
					await awsS3.setActivitySummary(tree, env.s3.contentBucket, hit.url, false, jsonChecklist);
					resolve();
				}
			});
		}).catch((err) => {
			console.error(err);
			reject(err);
		});
	});
}

export async function changeChatChannel(tree, activeChannelId) {
	let channel = null;
	try {
		if (activeChannelId.endsWith("-internal")) {
			channel = await channelExists(activeChannelId.replace("-internal", ""));
		} else {
			channel = await channelExists(activeChannelId + "-internal");
		}
	} catch (err) {
		// No op
	}

	if (isOrganizationUser() && channel === null) {
		showInfo("Chat", "A Chat channel has not yet been created for this activity. Please contact your administrator for details.");
		return;
	}

	// Couldn't set in baobab
	global.streamChatActiveChannel = channel;
	// See if channel already exists

	tree.set(["appState", "showCreateChatChannel", "activeChannelId"], channel !== null ? channel.id : null);
}

export function refreshActivitySummary(url, showAll = false) {
	awsS3.setActivitySummary(state, env.s3.contentBucket, url, showAll);
}

export function closeCreateChatChannelModal(tree) {
	tree.set(["appState", "showCreateChatChannel", "activeChannelId"], null);
	tree.set(["appState", "showCreateChatChannel", "instance"], {});
	tree.set(["appState", "showCreateChatChannel", "showModal"], false);

	tree.set(["appState", "activitySummary"], {});
	tree.set(["appState", "activityInvoiceItems"], []);
	tree.set(["appState", "showNotes", "checklist"], {});
	tree.set(["appState", "showNotes", "sessionName"], "");
}

export function toggleMultiSelect(tree) {
	let enabled = tree.select(["appState", "editor", "multiSelectMode"]).get();
	tree.set(["appState", "editor", "multiSelectMode"], !enabled);
}

export function setLastLocation(tree, location) {
	tree.set(["appState", "lastLocation"], location);
}

export function editDetailedStatus(tree) {
	tree.set(["appState", "showDetailedStatus", "edit"], true);

	//clone original checklist and save it
	let cloned = tree.select(PlayerPaths.CHECKLIST).deepClone();
	tree.set(["player", "checklistOriginal"], cloned);
}

export function cancelEditDetailedStatus(tree) {
	tree.set(["appState", "showDetailedStatus", "edit"], false);
	let original = tree.select(PlayerPaths.CHECKLIST_ORIGINAL).get();
	tree.set(PlayerPaths.CHECKLIST, original);
}

export function saveDetailedStatusSuccess(tree) {
	tree.set(["appState", "showDetailedStatus", "modal", "showSpinner"], false);
	tree.set(["appState", "showDetailedStatus", "edit"], false);
}

export function changeStatusLayout(layout) {
	state.set(["appState", "statusViewType"], layout);
	utils.setLs(state.get(["user", "username"]) + ";statusViewType", layout);
}


export async function addActivityInstance(activityId, { id, sync, openDate, lastUpdatedDate, submitted = null }) {
	let instanceIdx = -1;

	if (!state.get(["instances", activityId])) {
		state.set(["instances", activityId], []);
	}

	state.get(["instances", activityId]).forEach((instance, idx) => {
		if (instance.id === id) {
			instanceIdx = idx;
		}
	});


	if (instanceIdx === -1) {
		state.push(["instances", activityId],
			{
				id,
				sync,
				openDate,
				lastUpdatedDate,
				submitted
			});
	} else {
		state.set(["instances", activityId, instanceIdx], {
			id,
			sync,
			openDate,
			lastUpdatedDate,
			submitted
		});
	}
}

export function handleShowGenerateTtsAudioModal(tree) {
	initGenerateTtsAudioConfig(tree);
	tree.set(["audio", "modals", "generateTts", "show"], true);
}

export function handleHideGenerateTtsAudioModal(tree) {
	tree.set(["audio", "modals", "generateTts", "show"], false);
}

export function showReassignModalForActivity(tree, activityUrl) {
	tree.set(["appState", "reassignModal", "activityUrl"], activityUrl);
	tree.set(["appState", "reassignModal", "show"], true);
}

export function showReassignModal(tree, instanceUrl) {
	tree.set(["appState", "reassignModal", "instanceUrl"], instanceUrl);
	tree.set(["appState", "reassignModal", "show"], true);
}

export function hideReassignModal(tree) {
	tree.set(["appState", "reassignModal", "show"], false);
}

export function showPlaylistsModal(tree) {
	tree.set(["appState", "playlistsModal", "show"], true);
}

export function hidePlaylistsModal(tree) {
	tree.set(["appState", "playlistsModal", "show"], false);
}

export function showPricingModal(tree, url) {
	// Get JSON and after retrieved set state
	awsS3.showPricing(tree, env.s3.contentBucket, url);
}

export async function savePricing(tree, data) {
	const fileName = tree.get(["appState", "showPricing", "url"]);

	return await awsS3.savePricing(env.s3.contentBucket, fileName, data);
}

export async function saveTasks(tree, fileName, data) {
	console.log("SAVE!!!", fileName, data);
	// Tasks are part of pricing-- in the end just saving the session
	return await awsS3.savePricing(env.s3.contentBucket, fileName, data);
}

export function closeInvoiceModal(tree) {
	// If data has changed then also want to save
	// alert("NEW PRICING", tree.get(["appState", "showPricing", "invoiceItemsTemp"]));

	// Need to inject in activity and save

	tree.set(["appState", "showPricing", "showModal"], false);
	tree.set(["appState", "showPricing", "invoiceItemsTemp"], []);
	tree.set(["appState", "showPricing", "invoiceItems"], []);
	tree.set(["appState", "showPricing", "estimatesTemp"], []);
	tree.set(["appState", "showPricing", "estimates"], []);
	tree.set(["appState", "showPricing", "jobDetailsTemp"], {
		name: "",
		description: "",
		notes: "",
		parts: ""
	});
	tree.set(["appState", "showPricing", "jobDetails"], {
		name: "",
		description: "",
		notes: "",
		parts: ""
	});
	tree.set(["appState", "showPricing", "selectedValueTemp"], "");
	tree.set(["appState", "showPricing", "selectedValue"], "");
	tree.set(["appState", "showPricing", "url"], "");
}

export function handleGenerateExternalUrls(tree) {
	let entityRoot = tree.get(["tree", "root"]);

	if (entityRoot.entity.heroImage) {
		if (isDriveReference(entityRoot.entity.heroImage)) {
			entityRoot.entity.heroImageDrive = _.cloneDeep(entityRoot.entity.heroImage);
		}
		entityRoot.entity.heroImage = getUrlForValue(entityRoot.entity.heroImage, entityRoot.id);
	}
	if (entityRoot.entity.logoImage) {
		if (isDriveReference(entityRoot.entity.logoImage)) {
			entityRoot.entity.logoImageDrive = _.cloneDeep(entityRoot.entity.logoImage);
		}
		entityRoot.entity.logoImage = getUrlForValue(entityRoot.entity.logoImage, entityRoot.id);
	}
	if (entityRoot.entity.logoImageSmall) {
		if (isDriveReference(entityRoot.entity.logoImageSmall)) {
			entityRoot.entity.logoImageSmallDrive = _.cloneDeep(entityRoot.entity.logoImageSmall);
		}
		entityRoot.entity.logoImageSmall = getUrlForValue(entityRoot.entity.logoImageSmall, entityRoot.id);
	}
	if (entityRoot.entity.backgroundAudio) {
		if (isDriveReference(entityRoot.entity.backgroundAudio)) {
			entityRoot.entity.backgroundAudioDrive = _.cloneDeep(entityRoot.entity.backgroundAudio);
		}
		entityRoot.entity.backgroundAudio = getUrlForValue(entityRoot.entity.backgroundAudio, entityRoot.id);
	}
	if (entityRoot.entity.backgroundImage) {
		if (isDriveReference(entityRoot.entity.backgroundImage)) {
			entityRoot.entity.backgroundImageDrive = _.cloneDeep(entityRoot.entity.backgroundImage);
		}
		entityRoot.entity.backgroundImage = getUrlForValue(entityRoot.entity.backgroundImage, entityRoot.id);
	}
	if (entityRoot.entity.labelAudioFile) {
		if (isDriveReference(entityRoot.entity.labelAudioFile)) {
			entityRoot.entity.labelAudioFileDrive = _.cloneDeep(entityRoot.entity.labelAudioFile);
		}
		entityRoot.entity.labelAudioFile = getUrlForValue(entityRoot.entity.labelAudioFile, entityRoot.id);
	}

	utils.recurseDriveRefsToUrlsForEntities(entityRoot.children, entityRoot.id);
}

export function handleRestoreDriveRefs(tree) {
	let entityRoot = tree.get(["tree", "root"]);

	if (entityRoot.entity.heroImageDrive) {
		entityRoot.entity.heroImage = _.cloneDeep(entityRoot.entity.heroImageDrive);
		delete entityRoot.entity.heroImageDrive;
	}

	if (entityRoot.entity.logoImageDrive) {
		entityRoot.entity.logoImage = _.cloneDeep(entityRoot.entity.logoImageDrive);
		delete entityRoot.entity.logoImageDrive;
	}

	if (entityRoot.entity.logoImageSmallDrive) {
		entityRoot.entity.logoImageSmall = _.cloneDeep(entityRoot.entity.logoImageSmallDrive);
		delete entityRoot.entity.logoImageSmallDrive;
	}

	if (entityRoot.entity.backgroundAudioDrive) {
		entityRoot.entity.backgroundAudio = _.cloneDeep(entityRoot.entity.backgroundAudioDrive);
		delete entityRoot.entity.backgroundAudioDrive;
	}

	if (entityRoot.entity.backgroundImageDrive) {
		entityRoot.entity.backgroundImage = _.cloneDeep(entityRoot.entity.backgroundImageDrive);
		delete entityRoot.entity.backgroundImageDrive;
	}

	utils.recurseRestoreDriveRefs(entityRoot.children);
}

export function handleClearTtsAudio(tree) {
	let entityRoot = tree.get(["tree", "root"]);

	entityRoot.entity.labelAudioFile = "";

	utils.recurseClearTtsAudio(entityRoot.children);
}

export async function generateTtsAudio(tree, params = {}) {

	let content = [];

	let entityRoot = tree.get(["tree", "root"]);
	let entity = entityRoot.entity;

	params.pathPrefix = getGenerateTtsAudioPathPrefix(getOrgId(), entity.driveRootPath);

	createContentArrayForTtsAudio(entityRoot, content, params);

	try {
		showEditorSpinner();

		if (content.length > 0) {
			let response = await sendTtsAudioRequest(content, params);
			await listDrive();
			applyGeneratedTtsAudioResponseToActivity(tree, response, content);

			//console.log("Entity after tts audio generation", entityRoot);
			saveUndoState();
		} else {
			console.log("No tts audio to generate");
		}

		//console.log("TTS Request", ttsRequest);
		handleHideGenerateTtsAudioModal(tree);
		hideEditorSpinner();
	} catch (err) {
		console.log(err);
		showError("Error", "TTS audio generation failed.");
		handleHideGenerateTtsAudioModal(tree);
		hideEditorSpinner();
	}
}

function getGenerateTtsAudioPathPrefix(orgId, driveRootPath) {
	return "private/" + orgId + "/activities/" + driveRootPath + "/polly-generated-audio";
}

async function sendTtsAudioRequest(content, params) {

	let orgId = getOrgId();

	let chunks = _.chunk(content, env.apiGateway.endpoints.audio.generateTts.batchSize);

	let responseContent = [];
	try {

		for (let i = 0; i < chunks.length; i++) {

			let ttsRequest = {
				pathPrefix: params.pathPrefix,
				orgId: orgId,
				items: chunks[i],
				sampleRate: params.sampleRate,
				voiceId: params.voice.Id,
				lang: params.voice.LanguageCode,
				engine: params.engine
			};

			//console.log("TTS request", ttsRequest);

			let promise = new Promise((resolve, reject) => {
				refreshCredentials().then(() => {
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.audio.generateTts.generate}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(ttsRequest)
						.then(res => {
							_.each(res.body.items, (item) => {
								responseContent.push(item);
							});
							resolve();
						}).catch(err => {
							console.error(err);
							reject(err);
						});
				}).catch(err => {
					reject(err);
				});
			});
			await promise;
		}
	} catch (err) {
		console.log(err);
		throw new Error("TTS audio generation failed");
	}

	return responseContent;
}

function isTtsAudioGeneratedOrEmpty(entity, prop, pathPrefix, guid) {

	let fileRef = entity[prop];

	let type = getEntityTypeForTtsAudioGeneration(entity);

	if (!fileRef) { //no file ref, empty
		return true;
	} else if (_.isObject(fileRef) && fileRef.type === "resource/ref") {
		const externalResource = getDriveObjectForReference(fileRef);
		let managed = externalResource.path === pathPrefix + "/" + type + "-" + guid + "-" + prop + ".mp3";
		return managed;
	} else {
		return false;
	}
}


export async function generateTtsAudioForProperty(tree, params = {}) {

	let entityRoot = tree.get(["tree", "root"]);
	let entity = entityRoot.entity;

	params.pathPrefix = getGenerateTtsAudioPathPrefix(getOrgId(), entity.driveRootPath);

	try {
		showEditorSpinner();

		let response = await sendTtsAudioRequestForProperty(params);

		await listDriveMap();

		let item = response[0];
		let resourceRef = getResourceRefForGeneratedTtsAudioResponseItem(item);

		tree.set([...getSelectedNodePath(), "entity", params.propName], resourceRef);

		saveUndoState();


		handleHideGenerateTtsAudioForPropertyModal(tree);
		hideEditorSpinner();
	} catch (err) {
		console.log(err);
		showError("Error", "TTS audio generation failed.");
		hideEditorSpinner();
	}
}

async function sendTtsAudioRequestForProperty(params) {

	let currentNode = state.get(getSelectedNodePath());
	let entity = (currentNode.entity) ? currentNode.entity : currentNode;

	let type = getEntityTypeForTtsAudioGeneration(entity);
	let guid = (type === "activity") ? currentNode.id : entity.guid;

	let ttsRequest = {
		pathPrefix: params.pathPrefix,
		orgId: getOrgId(),
		items: [
			{
				entityGuid: guid,
				type,
				text: params.text,
				propName: params.propName
			}
		],
		sampleRate: params.sampleRate,
		voiceId: params.voice.Id,
		lang: params.voice.LanguageCode,
		engine: params.engine
	};

	let responseContent = [];

	try {
		let promise = new Promise((resolve, reject) => {
			refreshCredentials().then(() => {
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.audio.generateTts.generate}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(ttsRequest)
					.then(res => {
						_.each(res.body.items, (item) => {
							responseContent.push(item);
						});
						resolve();
					}).catch(err => {
						console.error(err);
						reject(err);
					});
			}).catch(err => {
				reject(err);
			});
		});
		await promise;
	} catch (e) {
		console.error(e);
		throw new Error("TTS audio generation failed");
	}

	return responseContent;
}





function createContentArrayForTtsAudio(entityRoot, content, params) {
	let entity = entityRoot.entity;

	if (isTtsAudioGeneratedOrEmpty(entity, "labelAudioFile", params.pathPrefix, entityRoot.id)) {

		let labelText = getEntityTextForAudioProperty(entity, "labelAudioFile");

		if (labelText) {
			content.push({
				text: labelText,
				type: getEntityTypeForTtsAudioGeneration(entity),
				propName: "labelAudioFile",
				entityGuid: entityRoot.id
			});
		}
	}

	recurseCreateContentArrayForTtsAudio(entityRoot.children, content, params, 0, 0);
}

function recurseCreateContentArrayForTtsAudio(arr, content, params, lastListIndex, lastSectionIndex) {
	for (let i = 0; i < arr.length; i++) {

		let entity = arr[i].entity;

		if (entity.type.startsWith("list")) {
			lastListIndex = i;

			if (isTtsAudioGeneratedOrEmpty(entity, "labelAudioFile", params.pathPrefix, entity.guid)) {

				let listText = getEntityTextForAudioProperty(entity, "labelAudioFile");

				if (listText) {
					content.push({
						text: listText,
						type: getEntityTypeForTtsAudioGeneration(entity),
						listIndex: i,
						propName: "labelAudioFile",
						entityGuid: entity.guid
					});
				}
			}
		} else if (entity.type.startsWith("section")) {
			lastSectionIndex = i;

			if (isTtsAudioGeneratedOrEmpty(entity, "labelAudioFile", params.pathPrefix, entity.guid)) {

				let sectionText = getEntityTextForAudioProperty(entity, "labelAudioFile");

				if (sectionText) {
					content.push({
						text: sectionText,
						type: getEntityTypeForTtsAudioGeneration(entity),
						listIndex: lastListIndex,
						sectionIndex: i,
						propName: "labelAudioFile",
						entityGuid: entity.guid
					});
				}
			}
		} else if (entity.type.startsWith("item")) {

			if (isTtsAudioGeneratedOrEmpty(entity, "label1AudioFile", params.pathPrefix, entity.guid)) {

				let itemLabelText = getEntityTextForAudioProperty(entity, "label1AudioFile");

				if (itemLabelText) {
					content.push({
						text: itemLabelText,
						type: getEntityTypeForTtsAudioGeneration(entity),
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						propName: "label1AudioFile",
						entityGuid: entity.guid
					});
				}
			}

			if (isTtsAudioGeneratedOrEmpty(entity, "label2AudioFile", params.pathPrefix, entity.guid)) {

				let itemLabel2Text = getEntityTextForAudioProperty(entity, "label2AudioFile");

				if (itemLabel2Text) {
					content.push({
						text: itemLabel2Text,
						type: getEntityTypeForTtsAudioGeneration(entity),
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						propName: "label2AudioFile",
						entityGuid: entity.guid
					});
				}
			}

			if (isTtsAudioGeneratedOrEmpty(entity, "commentsAudioFile", params.pathPrefix, entity.guid)) {

				let itemCommentsText = getEntityTextForAudioProperty(entity, "commentsAudioFile");

				if (itemCommentsText) {
					content.push({
						text: itemCommentsText,
						type: getEntityTypeForTtsAudioGeneration(entity),
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						propName: "commentsAudioFile",
						entityGuid: entity.guid
					});
				}
			}
		}
		if (arr[i].hasOwnProperty("children")) {
			recurseCreateContentArrayForTtsAudio(arr[i].children, content, params, lastListIndex, lastSectionIndex);
		}
	}
}

export function getEntityTextForAudioProperty(entity, propName) {

	let text = "";

	if (entity.type === "checklist") {

		if (propName === "labelAudioFile") {
			if (entity.labelSsml) {
				text = replaceTtsPhonetics(entity.labelSsml);
			} else if (entity.labelAudio) {
				text = fixUtterance(entity.labelAudio);
			} else if (entity.label) {
				text = fixUtterance(entity.label);
			}
		}

	} else if (entity.type === "list") {

		if (propName === "labelAudioFile") {
			if (entity.labelSsml) {
				text = replaceTtsPhonetics(entity.labelSsml);
			} else if (entity.labelAudio) {
				text = fixUtterance(entity.labelAudio);
			} else if (entity.label) {
				text = fixUtterance(entity.label);
			}
		}

	} else if (entity.type === "section") {

		if (propName === "labelAudioFile") {
			if (entity.labelSsml) {
				text = replaceTtsPhonetics(entity.labelSsml);
			} if (entity.labelAudio) {
				text = fixUtterance(entity.labelAudio);
			} else if (entity.label) {
				text = fixUtterance(entity.label);
			}
		}

	} else if (entity.type.startsWith("item")) {

		if (propName === "label1AudioFile") {
			if (entity.label1Ssml) {
				text = replaceTtsPhonetics(entity.label1Ssml);
			} else if (entity.label1Audio) {
				text = fixUtterance(entity.label1Audio);
			} else if (entity.label) {
				text = fixUtterance(entity.label);
			}
		}

		if (propName === "label2AudioFile") {
			if (entity.label2Ssml) {
				text = replaceTtsPhonetics(entity.label2Ssml);
			} else if (entity.label2Audio) {
				text = fixUtterance(entity.label2Audio);
			} else if (entity.label2) {
				text = fixUtterance(entity.label2);
			}
		}

		if (propName === "commentsAudioFile") {
			if (entity.commentsSsml) {
				text = replaceTtsPhonetics(entity.commentsSsml);
			} else if (entity.commentsAudio) {
				text = fixUtterance(entity.commentsAudio);
			} else if (entity.comments) {
				text = fixUtterance(entity.comments);
			}
		}
	}

	return text;
}

function applyGeneratedTtsAudioResponseToActivity(tree, ttsResponse, contentMetadata) {

	let activityRoot = tree.get(["tree", "root"]);

	_.each(ttsResponse, (item, i) => {

		let itemMetadata = contentMetadata[i];

		let resourceRef = getResourceRefForGeneratedTtsAudioResponseItem(item);

		switch (itemMetadata.type) {
			case "activity":
				tree.set(["tree", "root", "entity", itemMetadata.propName], resourceRef);
				break;
			case "list":
				tree.set(["tree", "root", "children", itemMetadata.listIndex, "entity", itemMetadata.propName], resourceRef);
				break;
			case "section":
				if (utils.isEditorChecklistSections(activityRoot)) {
					tree.set(["tree", "root", "children", itemMetadata.sectionIndex, "entity", itemMetadata.propName], resourceRef);
				} else {
					tree.set(["tree", "root", "children", itemMetadata.listIndex, "children", itemMetadata.sectionIndex, "entity", itemMetadata.propName], resourceRef);
				}
				break;
			default:
				if (itemMetadata.type.startsWith("item")) {
					if (utils.isEditorChecklistItems(activityRoot)) {
						tree.set(["tree", "root", "children", itemMetadata.itemIndex, "entity", itemMetadata.propName], resourceRef);
					} else if (utils.isEditorChecklistSections(activityRoot)) {
						tree.set(["tree", "root", "children", itemMetadata.sectionIndex, "children", itemMetadata.itemIndex, "entity", itemMetadata.propName], resourceRef);
					} else {
						tree.set(["tree", "root", "children", itemMetadata.listIndex, "children", itemMetadata.sectionIndex, "children", itemMetadata.itemIndex, "entity", itemMetadata.propName], resourceRef);
					}
				}
				break;
		}
	});
}

function getResourceRefForGeneratedTtsAudioResponseItem(item) {
	item.driveMetadata.isVideo = (item.propName) === "video";

	const refId = addExternalResource(getDriveObject(item.driveMetadata.guid));

	// const refId = addExternalResource(getDriveObjectByPath(item.driveMetadata.path));
	const resourceRef = getResourceReference(refId);
	return resourceRef;
}

export function handleShowGenerateTtsAudioForPropertyModal(tree, propName) {
	initGenerateTtsAudioConfig(tree);

	tree.set(["audio", "modals", "generateTtsForProperty", "show"], true);
	tree.set(["audio", "modals", "generateTtsForProperty", "propName"], propName);
	tree.set(["audio", "modals", "generateTtsForProperty", "previewUrl"], "");

	let currentNode = tree.get(getSelectedNodePath());

	let previewText = getEntityTextForAudioProperty(currentNode.entity ? currentNode.entity : currentNode, propName);

	tree.set(["audio", "modals", "generateTtsForProperty", "previewText"], previewText);
}

export function handleHideGenerateTtsAudioForPropertyModal(tree) {
	tree.set(["audio", "modals", "generateTtsForProperty", "show"], false);
	tree.set(["audio", "modals", "generateTtsForProperty", "propName"], "");
	tree.set(["audio", "modals", "generateTtsForProperty", "previewUrl"], "");
}

export async function generateTtsAudioForPropertyPreview(tree, params = {}) {

	try {
		showEditorSpinner();

		let ttsRequest = {
			text: tree.get(["audio", "modals", "generateTtsForProperty", "previewText"]),
			sampleRate: params.sampleRate,
			voiceId: params.voice.Id,
			lang: params.voice.LanguageCode,
			engine: params.engine
		};

		let promise = new Promise((resolve, reject) => {
			refreshCredentials().then(() => {
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.audio.generateTts.preview}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(ttsRequest)
					.then(res => {
						tree.set(["audio", "modals", "generateTtsForProperty", "previewUrl"], res.body.url);
						resolve();
					}).catch(err => {
						console.error(err);
						reject(err);
					});
			}).catch(err => {
				reject(err);
			});
		});
		await promise;


		hideEditorSpinner();
	} catch (err) {
		console.log(err);
		showError("Error", "TTS audio generation preview failed.");
		hideEditorSpinner();
	}
}

function getEntityTypeForTtsAudioGeneration(entity) {
	return (entity.type === "checklist") ? "activity" : (entity.type.startsWith("item") ? "item" : entity.type);
}

export function handleGenerateTtsAudioConfigOnChange(tree, property, value) {

	switch (property) {
		case "sampleRate":
			utils.setLs(AppConstants.audio.tts.generationParams.SAMPLE_RATE, value);
			tree.set(["audio", "generateTtsConfig", property], value);
			break;
		case "voiceId":
			utils.setLs(AppConstants.audio.tts.generationParams.VOICE_ID, value);
			tree.set(["audio", "generateTtsConfig", property], value);
			break;
		case "engine":
			utils.setLs(AppConstants.audio.tts.generationParams.ENGINE, value);
			tree.set(["audio", "generateTtsConfig", property], value);
			break;
		default:
			break;
	}
}

export function initGenerateTtsAudioConfig(tree) {

	let sampleRate = utils.getLs(AppConstants.audio.tts.generationParams.SAMPLE_RATE);

	if (sampleRate === null) {
		sampleRate = 24000;
	}

	let voiceId = utils.getLs(AppConstants.audio.tts.generationParams.VOICE_ID);

	if (voiceId === null) {
		voiceId = utils.getSortedPollyVoices()[0].Id;
	}

	let engine = utils.getLs(AppConstants.audio.tts.generationParams.ENGINE);

	if (engine === null) {
		engine = "standard";
	}

	tree.set(["audio", "generateTtsConfig", "sampleRate"], sampleRate);
	tree.set(["audio", "generateTtsConfig", "voiceId"], voiceId);
	tree.set(["audio", "generateTtsConfig", "engine"], engine);
}

export function resetGenerateTtsAudioConfig(tree) {

	let sampleRate = 24000;
	let voiceId = utils.getSortedPollyVoices()[0].Id;
	let engine = "standard";

	utils.setLs(AppConstants.audio.tts.generationParams.SAMPLE_RATE, sampleRate);
	utils.setLs(AppConstants.audio.tts.generationParams.VOICE_ID, voiceId);
	utils.setLs(AppConstants.audio.tts.generationParams.ENGINE, engine);

	tree.set(["audio", "generateTtsConfig", "sampleRate"], sampleRate);
	tree.set(["audio", "generateTtsConfig", "voiceId"], voiceId);
	tree.set(["audio", "generateTtsConfig", "engine"], engine);
}

export function setSelectedFolderFromDriveRootPath(driveRootPath) {
	// If there is a root drive path, set that as the selected folder
	if (driveRootPath) {
		const selectedFolder = `private/${getOrgId()}/activities/${driveRootPath}/`;
		if (selectedFolder !== state.get(["drive", "selectedFolder"])) {
			state.set(["drive", "selectedFolder"], selectedFolder);
		}
	}
}

export function showMediaPreviewModal(tree, url, propName = "") {
	//show only if url is valid
	if (url && utils.validateUrl(url)) {
		tree.set(["appState", "mediaPreviewModal", "show"], true);
		tree.set(["appState", "mediaPreviewModal", "url"], url);
		tree.set(["appState", "mediaPreviewModal", "propName"], propName);
	}
}

export function closeMediaPreviewModal(tree) {
	tree.set(["appState", "mediaPreviewModal", "show"], false);
}

function deepCloneNodeFromClipboard(tree, node) {
	const srcExternalResources = tree.get("clipboardExternalResources");
	const srcRelatedItems = tree.get("clipboardRelatedItems");

	let entityId = utils.generateUUID();
	const guid = utils.generateUUID();
	let newNode = _.cloneDeep(node);
	newNode.entity.entityId = entityId;
	newNode.entity.guid = guid;
	newNode.entity = fixIftttStructureIds(newNode.entity);

	if (newNode.entity.groupNames && newNode.entity.groupNames.indexOf("#internal-templates") > -1) {
		newNode.entity.groupNames.splice(newNode.entity.groupNames.indexOf("#internal-templates"), 1);
	}

	const pastedExternalResources = {};
	const pastedRelatedItems = {};
	recurseEntityProps(newNode.entity, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);

	newNode.selected = false;

	if (newNode.hasOwnProperty("children")) {
		recurseUpdateIds(newNode.children);
		recursePasteEntries(newNode.children, srcExternalResources, pastedExternalResources, srcRelatedItems, pastedRelatedItems);
	}

	finalizePasteEntries(pastedExternalResources, pastedRelatedItems);

	return newNode;
}

/**
 * List the drive before editing an activity, this will only load the drive if it has not 
 * been initialized yet.
 */
export async function listDriveOnEditActivity() {

	//Load drive if not yet available
	if (!state.get(["drive", "hierarchy"])) {

		// If in editor, show editor spinner, else, show document spinner
		const inActivityEditor = utils.isInActivityEditor();
		const spinnerMessage = state.get(["content", "loadingStatuses", "loadingDrive"])

		try {
			if (inActivityEditor) {
				setEditorSpinnerMessage(spinnerMessage);
				showEditorSpinner();
			} else {
				setDocumentsSpinnerMessage(spinnerMessage);
				showDocumentsSpinner();
			}

			await listDrive();
		} finally {

			if (inActivityEditor) {
				setEditorSpinnerMessage("");
				hideEditorSpinner();
			} else {
				setDocumentsSpinnerMessage("");
				hideDocumentsSpinner();
			}
		}
	}
}


import request from "superagent";
import _ from "lodash";

import { hasMaxActivities } from "./inventoryActions";
import { prepareActivityAndEdit, handleChangeProperty } from "../actions/actions";
import state from "../state/state";
import jsonTemplate from "../data/template.js";
import { showError, showError2, showYesNo, showSuccess } from "./alertActions";
import { refreshCredentials } from "../utils/securityUtil";
import { getKeyPrefix, convertStringToDriveRootPath } from "../utils/utils";
import { getPublicActivityClone, getActivityById } from "./inventoryActions";
import { saveUndoState } from "./actions";

import { getActivity, getActivityTag, putActivityTags, activityExists } from "../persistence/s3";
import { getIdentityId, getDisplayName, isOrganizationAdministrator, getJwtToken, getGroupMemberships } from "./user";
import { navigateTo } from "./navActions";
import { getResourceReference as getExternalResourceReference, getResourceEntry as getExternalResourceEntry, RESOURCE_REFERENCE, getDriveObject } from "./drive";
import { DATASOURCE_TYPE } from "./datasource";

import env from "../constants/env";


export function selectActivity(activityId) {
	if (state.get(["appCapabilities", "multiSelect"])) {
		let selectedActivities = state.get(["appState", "selectedActivities"]);

		// Check if already selected, if so splice, if not push
		const index = selectedActivities.indexOf(activityId);
		if (index > -1) {
			selectedActivities.splice(index, 1);
		} else {
			selectedActivities.push(activityId);
		}

		state.set(["appState", "selectedActivities"], Object.assign([], selectedActivities));
		console.log("Selected Activities: " + selectedActivities);

	}
}


function showSpinner() {
	state.set(["appState", "documents", "showSpinner"], true);
}

function hideSpinner() {
	state.set(["appState", "documents", "showSpinner"], false);
}


export function resetSelectedActivities(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		tree.set(["appState", "selectedActivities"], Object.assign([]));
	}
}

export function selectAllActivities(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		const activities = tree.get(["activities"]);

		let selectedActivities = [];
		for (let i = 0; i < activities.length; i++) {
			const activity = activities[i];

			if ((!activity.hasOwnProperty("newDoc") || !activity.newDoc) && (!activity.hasOwnProperty("filterVisible") || activity.filterVisible)) {
				selectedActivities.push(activity.id);
			}
		}

		tree.set(["appState", "selectedActivities"], Object.assign([], selectedActivities));

	}
}

export function edit({ activityId, doc, newActivity = false, selectedTemplate = null, newActivityName = null, newActivityDescription = null, trackData = false, newActivityWorkflowGuid = null }) {
	// if (window.restoredFromLs) {
	// 	alert("Your activity has been restored.<br/><br/>Important Note: It is not saved yet. You can first review it to make sure it is satisfactory. Auto-save happens at a one-minute interval, so there may be a little data loss. If you don't save, it will revert to the activity prior to this restored version.");
	// 	delete window.restoredFromLs;
	// 	return;
	// }

	if (!doc) {
		doc = state.get(["activitiesMap", activityId]);
	}

	if (!doc) {
		// Activity was neither provided nor does it exist in state ...
		// occurs when reloading without saving new activity.
		navigateTo("myChecklists");
	} else {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {

				if (newActivity) {
					state.set(["newActivityWorkflowGuid"], newActivityWorkflowGuid);
					if (!hasMaxActivities()) {
						new Promise((resolve, reject) => {
							if (selectedTemplate) {
								getPublicActivityClone(selectedTemplate).then(activity => {
									activity.revision = 0;
									activity.owner = true;
									resolve(activity);
								}).catch(err => {
									reject(err);
								});
							} else {
								resolve(jsonTemplate.template.blank);
							}
						}).then(activity => {
							activity.tags = [];
							if (newActivityName) {
								activity.name = newActivityName;
							}
							if (newActivityDescription) {
								activity.description = newActivityDescription;
							} else {
								activity.description = "";
							}
							activity.id = activityId;
							activity.heroImage = "";
							activity.logoImage = "";
							activity.logoImageSmall = "";
							activity.driveRootPath = convertStringToDriveRootPath(activity.name);
							activity.cloned = false;
							activity.publisher = "self";
							activity.trackData = trackData;

							activity.contributors = [{ name: state.get("user", "name"), username: state.get("user", "username"), nickname: state.get("user", "nickname") }];
							hideSpinner();
							prepareActivityAndEdit(activity, activityId, null);
							// console.log(err, err.stack);
						}).catch(err => {
							console.error(err);
							showError("Edit Activity", "An error occurred while constructing new activity:" + err);
							hideSpinner();
						});

					}
				} else {
					const fileName = `${activityId}.json`;
					const keyPrefix = doc.identityId && doc.sharedWithType !== "self" ? doc.identityId : getKeyPrefix(state.select(["user", "identityId"]).get());
					canEdit(activityId).then((res) => {
						if (!res) {
							showError("Permissioning", "You are not allowed to edit the selected activity, this might be because someone has removed authoring access to it. Please contact your administrator.");
							hideSpinner();
						} else {
							hasLock(activityId, keyPrefix).then(res => {

								const proceedLoad = () => {
									putLock(activityId, keyPrefix).then(() => {
										// Good to go
										getActivity(activityId, keyPrefix).then(activity => {
											if (doc) {
												activity.cloned = doc.cloned;
												if (doc.contributors === null) {
													activity.contributors = [{ name: state.get("user", "name"), username: state.get("user", "username"), nickname: state.get("user", "nickname") }];
												} else {
													activity.contributors = doc.contributors;
												}

											}

											hideSpinner();
											prepareActivityAndEdit(activity, activityId, doc.identityId && doc.sharedWithType !== "self" ? doc.identityId : null);
										}).catch(err => {
											if (err.code === "NetworkingError") {
												showError("Open activity failed", "Network error. Please check connectivity and try again.");
												return;
											}

											if (!hasMaxActivities()) {
												const id = fileName.split(".")[0];
												const activity = jsonTemplate;
												activity.id = id;
												activity.cloned = false;
												activity.publisher = "self";

												activity.contributors = [{ name: state.get("user", "name"), username: state.get("user", "username"), nickname: state.get("user", "nickname") }];
												hideSpinner();
												prepareActivityAndEdit(activity, activityId, doc.identityId && doc.sharedWithType !== "self" ? doc.identityId : null);
												// console.log(err, err.stack);
											}
										});
									}).catch(err => {
										console.error(err);
										showError("Edit Activity", "Unable to lock activity for editing, reason:" + err);
										hideSpinner();
									});
								};

								if (!res.unlocked) {
									// Lock that is not my own
									if (res.lockedInOtherInstance) {
										showYesNo("It appears that you are or were editing this activity in another browser window/tab or forgot to exit the editor previously, do you want to proceed editing?", "Activity Locked (By You)", (res) => {
											if (res === true) {
												proceedLoad();
											} else {
												hideSpinner();
											}
										});
									} else if (!isOrganizationAdministrator()) {
										showError("Activity Locked", `This activity has been locked by ${res.lockOwner} and can currently not be edited.`);
										hideSpinner();
									} else {
										const { lockOwner } = res;
										const msg = `This activity has been locked by, or is being edited by ${lockOwner}.As an administrator you can override the lock and edit anyway.WARNING: There is a risk of overwriting ${lockOwner}'s changes, or that ${lockOwner} could overwrite your changes. Would you like to proceed anyway?`;
										showYesNo(msg, "Activity Locked", (res) => {
											if (res === true) {
												proceedLoad();
											} else {
												hideSpinner();
											}
										});
									}
								} else {
									proceedLoad();
								}
							}).catch(err => {
								console.error(err);
								showError("Edit Activity", "Unable to obtain lock info, reason:" + err);
								hideSpinner();
							});
						}
					}).catch(err => {
						showError("Permissioning", "Unable to determine whether you are allowed to edit the selected activity, reason:" + err);
						hideSpinner();
					});

				}

			}).catch((err) => {
				console.error(err);
				showError2("Getting activity for editing failed", err);
				hideSpinner();
			});
		});

	}
}

export function canEdit(activityId) {
	return new Promise((resolve, reject) => {
		const activity = getActivityById(activityId);
		if (isMine(activityId) || (activity && activity.authoringGranted)) {
			resolve(true);
		} else {
			const groupMemberships = getGroupMemberships();
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.getShareAuthoringGrant(activityId)}`)
				.set("Authorization", getJwtToken())
				.set("Content-Type", "application/json")
				.send(JSON.stringify(groupMemberships))
				.then(res => {
					if (res && res.body && res.status === 200) {
						if (res.body.success) {
							resolve(res.body.authoringGrant);
						} else {
							reject();
						}
					} else {
						reject("An error occurred while trying to determine authoring permissions, status returned was " + res.status);
					}
				}).catch(err => {
					reject(err);
				});
		}
	});
}

export function hasLock(activityId, prefix) {

	return new Promise((resolve, reject) => {
		getActivityTag(activityId, ["locked", "lockOwnerId", "lockOwnerName"], prefix).then(info => {
			if (!info) {
				resolve({ unlocked: true });
			} else {
				if ((info.hasOwnProperty("locked") && info.locked !== "true") || !info.hasOwnProperty("locked")) {
					// Either it has a locked property and it doesn't have a value of true or it doesn't have the property at all
					// either case means it's not locked.
					resolve({ unlocked: true });
				} else {
					const lockedInOtherInstance = info.lockOwnerId === getIdentityId();
					resolve({ unlocked: !info.hasOwnProperty("lockOwnerId"), lockOwner: info.lockOwnerName, lockedInOtherInstance });
				}
			}
		}).catch(err => {
			reject(err);
		});
	});

}

export function breakLock(activityId = state.get(["selectedChecklistId"]), prefix) {
	console.log("BREAK LOCK");
	return new Promise((resolve, reject) => {
		if (!prefix) {
			const activity = state.get(["activitiesMap", activityId]);
			if (activity) {
				prefix = activity.identityId && activity.sharedWithType !== "self" ? activity.identityId : getKeyPrefix(state.select(["user", "identityId"]).get());
			}
		}
		if (prefix) {
			activityExists(activityId, prefix).then(res => {
				if (res) {
					putActivityTags(activityId, {
						locked: "false",
						lockOwnerId: "",
						lockOwnerName: ""
					}, prefix).then(() => {
						resolve();
					}).catch(err => {
						reject(err);
					});
				} else {
					resolve();
				}
			}).catch(err => {
				reject(err);
			});
		} else {
			resolve();
		}
	});
}

export function isMine(activityId) {
	const activity = state.get(["activitiesMap", activityId]);
	if (activity) {
		return !activity.identityId || activity.identityId === getIdentityId();
	} else {
		throw new Error("Unable to locate activity with id " + activityId + " in state.");
	}
}

export function breakLockIfMine(activityId = state.get(["selectedChecklistId"]), prefix) {
	console.log("BREAK LOCK IF MINE");
	if (!prefix) {
		const activity = state.get(["activitiesMap", activityId]);
		if (activity) {
			prefix = activity.identityId && activity.sharedWithType !== "self" ? activity.identityId : getKeyPrefix(state.select(["user", "identityId"]).get());
		}
	}

	if (prefix) {
		getActivityTag(activityId, ["locked", "lockOwnerId", "lockOwnerName"], prefix).then(info => {
			if (!info) {
				showSuccess("Unlock", "Activity wasn't locked.");
			} else {
				if ((info.hasOwnProperty("locked") && info.locked !== "true") || !info.hasOwnProperty("locked")) {
					// Either it has a locked property and it doesn't have a value of true or it doesn't have the property at all
					// either case means it's not locked.
					showSuccess("Unlock", "Activity wasn't locked.");
				} else {
					if (info.lockOwnerId === getIdentityId()) {
						showYesNo("Are you sure that you would like to unlock this Activity?", "Unlock Activity", (res) => {
							if (res) {
								breakLock(activityId, prefix).then(() => {
									showSuccess("Unlock", "Activity has been unlocked successfully");
								}).catch(err => {
									showError("Unlock", "Failed to unlock activity, reason: " + err);
								});
							}
						});
					} else {
						if (isOrganizationAdministrator()) {
							showYesNo(`This activity has been locked by, or is being edited by ${info.lockOwnerName}. As an administrator, you can override the lock and edit it anyway. WARNING: There is a risk of overwriting ${info.lockOwnerName}'s changes, or that ${info.lockOwnerName} could overwrite your changes. Would you like to proceed anyway?`, "Force Break Lock", (res) => {
								if (res) {
									breakLock(activityId, prefix).then(() => {
										showSuccess("Unlock", "Activity has been unlocked successfully");
									}).catch(err => {
										showError("Unlock", "Failed to unlock activity, reason: " + err);
									});
								}
							});
						} else {
							showError("Unlock", `Unlock failed, this document was locked by ${info.lockOwnerName}, please contact them to perform the unlock operation.`);
						}
					}
				}
			}
		}).catch(err => {
			showError("Unlock", "Failed to unlock activity, unable to get activity tag info, reason: " + err);
		});
	}
}

export function putLock(activityId = state.get(["selectedChecklistId"]), prefix) {
	if (!prefix) {
		const activity = state.get(["activitiesMap", activityId]);
		prefix = activity.identityId && activity.sharedWithType !== "self" ? activity.identityId : getKeyPrefix(state.select(["user", "identityId"]).get());
	}

	let lockOwnerName = getDisplayName();
	lockOwnerName = lockOwnerName.replace(/\W+/g, "_");
	console.log("PUT LOCK", {
		locked: "true",
		lockOwnerId: getIdentityId(),
		lockOwnerName
	});
	return putActivityTags(activityId, {
		locked: "true",
		lockOwnerId: getIdentityId(),
		lockOwnerName
	}, prefix);
}

export function getSelectedActivity() {
	return state.get(["activitiesMap", state.get(["selectedChecklistId"])]);
}

export function updateExternalResource(info, id, metaData) {
	const activity = state.get(["activitiesMap", state.get(["selectedChecklistId"])]);
	if (activity && id) {
		let externalResources = getExternalResources();
		const externalResource = getExternalResourceEntry(info, metaData, id);

		console.log("Updating external resource", externalResource);
		const _externalResouces = []; Object.assign([], externalResources ? externalResources : []);
		externalResources.forEach(_extResource => {
			if (_extResource.id === externalResource.id) {
				_externalResouces.push(externalResource);
			} else {
				_externalResouces.push(_extResource);
			}
		});

		state.set(EXTERNAL_RESOURCES_STATE_PATH, _externalResouces);
		return externalResource.id;

	} else {
		throw new Error("No selected activity, unable to lookup resource references.");
	}
}

export function addExternalResource(info, metaData) {
	const activity = state.get(["activitiesMap", state.get(["selectedChecklistId"])]);
	if (activity) {
		let externalResourcesMap = getExternalResources(true);
		let externalResources = getExternalResources();
		const externalResource = getExternalResourceEntry(info, metaData);
		if (!externalResourcesMap[info.guid] || externalResourcesMap[info.guid].revision !== externalResource.revision) {
			// Don't add this again
			console.log("Adding external resource", externalResource);
			const _externalResouces = Object.assign([], externalResources ? externalResources : []);
			_externalResouces.push(externalResource);
			state.set(EXTERNAL_RESOURCES_STATE_PATH, _externalResouces);
			return externalResource.id;
		} else {
			return externalResourcesMap[info.guid].id;
		}
	} else {
		throw new Error("No selected activity, unable to lookup resource references.");
	}
}

/**
 * Will force remove an external content resource
 * @param {*} guid 
 */
export function removeExternalResource(id) {
	console.log("Force remove", id);
	const activity = state.get(["activitiesMap", state.get(["selectedChecklistId"])]);
	if (activity) {
		const newExternalResources = [];
		activity.externalResources.forEach(resource => {
			if (resource.id !== id) {
				newExternalResources.push(resource);
			}
		});

		state.set(EXTERNAL_RESOURCES_STATE_PATH, newExternalResources);

	} else {
		throw new Error("No selected activity, unable to lookup resource references.");
	}
}

export function injectExternalResourceReference(propName, info, nodePath, resourceRefCallback = null) {
	info.isVideo = propName === "video";
	const refId = addExternalResource(info);
	const resourceRef = getExternalResourceReference(refId);
	if (resourceRefCallback && _.isFunction(resourceRefCallback)) {
		resourceRefCallback(resourceRef);
	} else {
		console.log("Inject resource reference", resourceRef);
		handleChangeProperty(state, propName, resourceRef, nodePath);
	}
}

export function getExternalResources(asMap = false) {
	const _result = state.get(EXTERNAL_RESOURCES_STATE_PATH);
	if (_result) {
		let result = _result;
		if (!result) {
			return asMap ? {} : [];
		} else if (!asMap) {
			return result;
		} else {
			const res = {};
			result.forEach(resource => {
				res[resource.id] = resource;
			});
			return res;
		}
	} else {
		return asMap ? {} : [];
	}
}

// export function getExternalResource(id) {
// 	const externalResources = getExternalResources(true);
// 	return externalResources[id];
// }

export function externalResourceExists(id) {
	const externalResources = getExternalResources(true);
	return externalResources.hasOwnProperty(id);
}

export function getActivityOrCurrentlySelected(activityId = null) {
	let result = null;
	const targetId = activityId ? activityId : null;
	if (targetId) {
		result = state.get(["activitiesMap", targetId]);
	} else {
		result = state.get(["tree", "root", "entity"]);
	}
	return result;
}

export function getExternalResource(id, activity) {
	let result = null;
	if (!activity) {
		activity = getActivityOrCurrentlySelected();
	}
	if (activity) {
		_.forEach(activity.externalResources, extResource => {
			if (extResource.id === id) {
				result = extResource;
			}
		});
	}
	return result;
}

export function getDriveObjectForReference(ref, activityId = null) {
	const { refId } = ref;
	// Get the current activity
	const activity = getActivityOrCurrentlySelected(activityId);
	console.log("Got activity for resource reference resolution", activity, ref);
	if (activity) {
		const resource = getExternalResource(refId, activity);
		if (resource) {
			const guid = resource.guid;
			if (!guid) {
				throw new Error(`Unable to find resource with guid ${guid} in activity resources.`);
			} else {
				// Now get the external resource info
				const info = getDriveObject(guid);
				if (info) {
					return info;
				} else {
					throw new Error(`Unable to find guid ${guid} in drive resources.`);
				}
			}
		} else {
			throw new Error(`Unable to locate resource with id ${refId} in activity ${activityId}`);
		}

	} else {
		throw new Error("No activity is currently selected.");
	}
}


export function setUploadingProps(entityId, propName, propValue) {
	state.set(["uploads", entityId, propName], propValue);
}

export function getDriveRootPath(stripSlashes = false) {
	let result = null;
	const activity = state.get(["tree", "root"]);
	if (activity) {
		console.log("ACTIVITY", activity);
		result = activity.entity.driveRootPath;
	}

	if (result && stripSlashes) {
		result = _.trim(result, "/");
	}
	return result;
}



export function isMediaOptimizationDisabled() {
	let result = false;

	const activity = state.get(["tree", "root"]);
	if (activity) {
		console.log("ACTIVITY", activity);
		result = activity.entity.disableMediaOptimization;
	}

	return result;
}

export function showTemplateModal(document) {
	state.set(["appState", "templateModal"], { show: true, document });
}

export function closeTemplateModal() {
	state.set(["appState", "selectedTemplate"], null);
	state.set(["appState", "templateModal"], { show: false, selectedTemplate: null });
}

export function selectTemplate(templateId) {
	const currentlySelected = state.get(["appState", "templateModal", "selectedTemplate"]);
	if (currentlySelected !== templateId) {
		state.set(["appState", "templateModal", "selectedTemplate"], templateId);
		state.unset(["appState", "templateModal", "selectedTemplateSubSelect"]);
	}
}

export function selectTemplateSubSelect(subSelectId) {
	state.set(["appState", "templateModal", "selectedTemplateSubSelect"], subSelectId);
}

/**
 * External Resources and references API
 */

const EXTERNAL_RESOURCES_PROP = "externalResources";
const RELATED_ITEMS_PROP = "relatedItems";
const EXTERNAL_RESOURCES_STATE_PATH = ["tree", "root", "entity", EXTERNAL_RESOURCES_PROP];
const RELATED_ITEMS_STATE_PATH = ["tree", "root", "entity", RELATED_ITEMS_PROP];


/**
 * Given a ref object or a string that communicates the refId, this function will return the referenced external
 * @param {Object|String} ref 
 * @param {*} activityId 
 */
export function getExternalReferenceForReference(ref, activityId = null) {
	let refId = null;
	if (_.isString(ref)) {
		refId = ref;
	} else {
		refId = ref.refId;
	}
	// const { refId } = ref;
	// Get the current activity
	const activity = getActivityOrCurrentlySelected(activityId);
	console.log("Got activity for resource reference resolution", activity, ref);
	if (activity && refId) {
		return getExternalResource(refId, activity);
	} else {
		throw new Error("No activity is currently selected.");
	}
}

/**
 * Given an activity will clean up all unused external resources, these are external resources never referenced from the content of the activity.
 * @param {*} activity 
 */
export function cleanUpExternalResources(activity) {
	if (activity) {
		console.log("About to clean up resource references", activity);
		let externalResourcesMap = getExternalResources(true);
		let externalResources = getExternalResources();
		let externalResourceReferences = [];
		getExternalResourceReferences(activity, externalResourceReferences);
		const newExternalResourcesMap = {};
		externalResourceReferences.forEach(ref => {
			if (externalResourcesMap.hasOwnProperty(ref.refId)) {
				// Needs to be removed!
				newExternalResourcesMap[ref.refId] = externalResourcesMap[ref.refId];
			}
		});
		activity.externalResources = _.concat(_.toArray(newExternalResourcesMap), _.filter(externalResources, (o) => { return o.metaData && o.metaData.subType === DATASOURCE_TYPE; }));
		console.log("new resources", externalResourceReferences, externalResourcesMap, _.toArray(newExternalResourcesMap));
		console.log(activity);
		// state.set(EXTERNAL_RESOURCES_STATE_PATH, _.toArray(newExternalResourcesMap));
	} else {
		throw new Error("No selected activity, unable to lookup resource references.");
	}
}

function getExternalResourceReferences(node, refs = []) {
	if (_.isPlainObject(node)) {
		if (node.type && node.type === RESOURCE_REFERENCE) {
			refs.push(node);
		} else {
			_.keys(node).forEach(key => {
				getExternalResourceReferences(node[key], refs);
			});
		}
	} else if (_.isArray(node)) {
		node.forEach(entry => {
			if (entry.type && entry.type === RESOURCE_REFERENCE) {
				refs.push(entry);
			} else {
				getExternalResourceReferences(entry, refs);
			}
		});
	}
}

/**
 * Will return the parsed activity content
 * @param {} activityId 
 */
export function getActivityContent(activityId) {
	return new Promise(async (resolve, reject) => {
		try {
			const doc = getActivityById(activityId);
			const keyPrefix = doc.identityId && doc.sharedWithType !== "self" ? doc.identityId : getKeyPrefix(state.select(["user", "identityId"]).get());

			const activityContent = await getActivity(activityId, keyPrefix);
			resolve(activityContent);
		} catch (err) {
			console.error(err);
			reject(err);
		}
	});
}

export function addTextTrack(videoTextTrack) {
	let arrPath = state.select("selectedNodePath").get();
	const editStatePath = _.concat(arrPath, ["entity", "videoTextTracks"]);
	const editState = state.get(editStatePath);

	if (editState) {
		const currentVideoTextTracks = editState;
		if (!currentVideoTextTracks || !_.isArray(currentVideoTextTracks)) {
			state.set(editStatePath, [videoTextTrack]);
		} else if (_.isArray(currentVideoTextTracks)) {
			state.push(editStatePath, videoTextTrack);
		}
		saveUndoState();
	}
}

export function deleteTextTrack(videoTextTrackId) {
	let arrPath = state.select("selectedNodePath").get();
	const editStatePath = _.concat(arrPath, ["entity", "videoTextTracks"]);
	const editState = state.get(editStatePath);

	if (editState) {
		const currentVideoTextTracks = editState;
		if (_.isArray(currentVideoTextTracks)) {
			const newVideoTextTracks = [];
			currentVideoTextTracks.forEach(videoTextTrack => {
				if (videoTextTrack.id !== videoTextTrackId) {
					newVideoTextTracks.push(videoTextTrack);
				}
			});
			state.set(editStatePath, newVideoTextTracks);
			saveUndoState();
		}
	}
}

export function getTextTrack(videoTextTrackId) {
	let arrPath = state.select("selectedNodePath").get();
	const editStatePath = _.concat(arrPath, ["entity", "videoTextTracks"]);
	const editState = state.get(editStatePath);

	let result = null;
	if (editState) {
		const currentVideoTextTracks = editState;
		if (_.isArray(currentVideoTextTracks)) {
			const n = currentVideoTextTracks.length;
			for (let i = 0; i < n; i++) {
				const videoTextTrack = currentVideoTextTracks[i];
				if (videoTextTrack.id === videoTextTrackId) {
					result = videoTextTrack;
					break;
				}
			}
		}
	}
	return result;
}

export function updateTextTrack(editVideoTextTrack) {
	let arrPath = state.select("selectedNodePath").get();
	const editStatePath = _.concat(arrPath, ["entity", "videoTextTracks"]);
	const editState = state.get(editStatePath);

	if (editState) {
		const currentVideoTextTracks = editState;
		if (_.isArray(currentVideoTextTracks)) {
			const newVideoTextTracks = [];
			currentVideoTextTracks.forEach(videoTextTrack => {
				if (videoTextTrack.id === editVideoTextTrack.id) {
					newVideoTextTracks.push(editVideoTextTrack);
				} else {
					newVideoTextTracks.push(videoTextTrack);
				}
			});
			state.set(_.concat(editStatePath, "videoTextTracks"), newVideoTextTracks);
			saveUndoState();
		}
	}
}

export function addRelatedItem(relatedItem) {
	const editState = state.get(["tree", "root", "entity"]);
	if (editState) {
		const currentRelatedItems = editState.relatedItems;
		if (!currentRelatedItems || !_.isArray(currentRelatedItems)) {
			state.set(["tree", "root", "entity", "relatedItems"], [relatedItem]);
		} else if (_.isArray(currentRelatedItems)) {
			state.push(["tree", "root", "entity", "relatedItems"], relatedItem);
		}
		saveUndoState();
	}
}

export function deleteRelatedItem(relatedItemId) {
	const editState = state.get(["tree", "root", "entity"]);
	if (editState) {
		const currentRelatedItems = editState.relatedItems;
		if (_.isArray(currentRelatedItems)) {
			const newRelatedItems = [];
			currentRelatedItems.forEach(relatedItem => {
				if (relatedItem.id !== relatedItemId) {
					newRelatedItems.push(relatedItem);
				}
			});
			state.set(["tree", "root", "entity", "relatedItems"], newRelatedItems);
			saveUndoState();
		}
	}
}

export function getRelatedItem(relatedItemId) {
	let result = null;
	const editState = state.get(["tree", "root", "entity"]);
	if (editState) {
		const currentRelatedItems = editState.relatedItems;
		if (_.isArray(currentRelatedItems)) {
			const n = currentRelatedItems.length;
			for (let i = 0; i < n; i++) {
				const relatedItem = currentRelatedItems[i];
				if (relatedItem.id === relatedItemId) {
					result = relatedItem;
					break;
				}
			}
		}
	}
	return result;
}

export function updateRelatedItem(editRelatedItem) {
	const editState = state.get(["tree", "root", "entity"]);
	if (editState) {
		const currentRelatedItems = editState.relatedItems;
		if (_.isArray(currentRelatedItems)) {
			const newRelatedItems = [];
			currentRelatedItems.forEach(relatedItem => {
				if (relatedItem.id === editRelatedItem.id) {
					newRelatedItems.push(editRelatedItem);
				} else {
					newRelatedItems.push(relatedItem);
				}
			});
			state.set(["tree", "root", "entity", "relatedItems"], newRelatedItems);
			saveUndoState();
		}
	}
}

export function copyRelatedItemRefsToClipboard(relatedItemRefs) {
	state.set(["appState", "relatedItems", "clipboard"], relatedItemRefs);
}

export function clearRelatedItemRefsToClipboard() {
	state.set(["appState", "relatedItems", "clipboard"], "");
}

export function getRelatedItemRefs(path = []) {
	return state.get(_.concat(path, ["entity", "relatedItemRefs"]));
}

export function getEntityAtPath(path = []) {
	return state.get(_.concat(path, ["entity"]));
}

export function getRelatedItems(asMap = false) {
	const _result = state.get(RELATED_ITEMS_STATE_PATH);
	if (_result) {
		let result = _result;
		if (!result) {
			return asMap ? {} : [];
		} else if (!asMap) {
			return result;
		} else {
			const res = {};
			result.forEach(resource => {
				res[resource.id] = resource;
			});
			return res;
		}
	} else {
		return asMap ? {} : [];
	}
}



export function cleanUpRelatedItemReferences(activity) {
	const relatedItems = activity.relatedItems || [];
	const relatedItemsMap = {};
	relatedItems.forEach(relatedItem => {
		relatedItemsMap[relatedItem.id] = true;
	});
	console.log("Related items map for clean up:", relatedItemsMap);
	if (activity && activity.children) {
		activity.children.forEach(node => {
			cleanUpRelatedItems(node, relatedItemsMap);
		})
	}
}

export function cleanUpRelatedItems(node, relatedItemsMap) {
	if (node && node.relatedItemRefs && _.isString(node.relatedItemRefs) && node.relatedItemRefs !== "") {
		console.log("Cleaning up node", node, node.relatedItemRefs);
		const newRelatedItemRefs = [];
		node.relatedItemRefs.split(",").forEach(refId => {
			if (relatedItemsMap[refId]) {
				newRelatedItemRefs.push(refId);
			}
		});
		node.relatedItemRefs = newRelatedItemRefs.join(",");
		console.log("Finished cleaning up node", node, node.relatedItemRefs);
	}

	if (node.children && _.isArray(node.children)) {
		node.children.forEach(child => {
			cleanUpRelatedItems(child, relatedItemsMap);
		})
	}
}
import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import _ from "lodash";
import autoBind from "react-autobind";
import { Tabs, Tab } from "react-bootstrap";
import { Controlled as CodeMirror } from "react-codemirror2";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import ChecklistPreviewInline from "../app-checklist-preview-inline";

import defaultTheme from "./defaultTheme";
import { setPreviewActivity } from "../../actions/actions";
import { codeMirrorOptionsJson } from "../../constants/appConstants";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line
require("codemirror/mode/htmlmixed/htmlmixed"); // eslint-disable-line
require("codemirror/addon/fold/foldcode"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter"); // eslint-disable-line
require('codemirror/addon/fold/brace-fold.js'); // eslint-disable-line
require("codemirror/addon/fold/foldgutter.css"); // eslint-disable-line
require('codemirror/addon/lint/lint');// eslint-disable-line
require('codemirror/addon/lint/json-lint');// eslint-disable-line
require("codemirror/addon/lint/lint.css");
// require("codemirror/addon/display/autorefresh");

class ThemeEditor extends PureComponent {

	constructor(props) {
		super(props);
		const { content, onChange } = props;
		let editContent = content;
		this.editorRef = React.createRef();
		if (!content || _.isEmpty(content)) {
			editContent = defaultTheme;

		}

		if (!_.isString(editContent)) {
			editContent = JSON.stringify(editContent, null, 4);
		}

		this.lastEdit = editContent;

		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}
		const initialState = {
			editContent,
			selectedActivityId: null,
			instanceCount: 0,
			loadingActivity: false
		};
		try {
			initialState.lastValidJson = JSON.parse(editContent);
		} catch (e) {
			console.warn(e);
		}

		this.state = initialState;
		autoBind(this);
	}

	handleChangeEditContent(editContent) {

		let lastValidJson = null;
		try {
			lastValidJson = JSON.parse(editContent);
		} catch (e) { } // eslint-disable-line

		let newState = {
			editContent
		};

		if (lastValidJson) {
			newState.lastValidJson = lastValidJson;
		}

		this.setState(newState);
		const { onChange } = this.props;
		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}
	}

	handleChangeValidity(valid, error) {
		const validity = {
			valid,
			error
		};
		this.setState({
			validity
		});

		const { onValidityChange } = this.props;
		if (onValidityChange && _.isFunction(onValidityChange)) {
			onValidityChange(validity);
		}
	}

	showLoadActivitySpinner() {
		this.setState({
			loadingActivity: true
		});
	}

	hideLoadActivitySpinner() {
		this.setState({
			loadingActivity: false
		});
	}

	handleSelectActivityForPreview(e) {
		const activityId = e.target.value;
		this.showLoadActivitySpinner();
		setTimeout(() => {
			setPreviewActivity(activityId).then(() => {
				setTimeout(() => {
					const instanceCount = this.state.instanceCount + 1;
					this.setState({
						selectedActivityId: activityId,
						instanceCount
					});
					this.hideLoadActivitySpinner();
				}, 200);
			}).catch(err => {
				console.error(err);
				this.hideLoadActivitySpinner();
			});
		});
	}

	handleForceRefresh(key) {
		if (key === 2) {
			const instanceCount = this.state.instanceCount + 1;
			this.setState({
				instanceCount
			});
		} else {
			try {
				console.log("REFRESH EDITOR", this.editor, this.editorRef);
				setTimeout(() => {
					this.editorRef.current.editor.focus();
					document.getElementById("root").click();
				}, 200);
			} catch (e) {
				console.warn(e);
			}
		}

	}

	render() {

		const { editContent/*, loadingActivity*/ } = this.state;

		const editorComponent = <CodeMirror
			value={editContent}
			ref={this.editorRef}
			options={codeMirrorOptionsJson}
			onBeforeChange={(editor, data, value) => {
				this.lastEdit = value;
				this.handleChangeEditContent(value);
			}}
			editorDidMount={editor => { this.editor = editor; setTimeout(() => { editor.refresh(); }, 200); }}
			className="react-codemirror2 stretch"
			// editorDidMount={editor => { this.instance = editor; }}
			onBlur={() => {
				try {
					if (!_.isEmpty(this.lastEdit)) {
						this.lastValidJson = JSON.parse(this.lastEdit);
						this.handleChangeValidity(true);
					}
				} catch (e) {
					this.handleChangeValidity(false, e);
					alert(e.message + "\n\nThe edited content is not valid JSON, please make sure to fix all issues before attempting to save.");
				}
			}}
		/>;

		// const previewComponent = <ChecklistPreviewInline cacheKey={this.state.instanceCount} disableVoice orgPreferences={{
		// 	theme: this.state.lastValidJson
		// }} />;
		const activitiesChoices = [];
		this.props.activities.forEach(activity => {

			// activitiesChoices.push({
			// 	value: activity.id,
			// 	label: activity.name
			// });
			activitiesChoices.push(<option value={activity.id} selected={activity.id === this.state.selectedActivityId}>{activity.name}</option>);

		});
		return <Tabs animation={false} style={{ minHeight: 0, height: "100%", display: "flex", flex: 1, flexDirection: "column" }} onSelect={this.handleForceRefresh}>
			<Tab eventKey={1} style={{ minHeight: 0, flex: 1 }} title="Theme JSON">
				<div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%", overflow: "hidden" }}>
					{editorComponent}
				</div>
			</Tab>
			{/* <Tab eventKey={2} style={{ minHeight: 0, flex: 1 }} title="Preview">
				<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", overflow: "hidden", padding: "10px" }}>
					<div style={{ paddingTop: "10px", paddingBottom: "10px", display: "flex", alignItems: "center" }}>
						<b>Select Activity:</b>
						<select style={{ margin: 0 }} onChange={this.handleSelectActivityForPreview}>
							{activitiesChoices}
						</select>
					</div>
					<div style={{ display: "flex", flexDirection: "row", height: "calc(100% - 80px)", width: "100%", overflow: "hidden", position: "relative" }}>
						{!loadingActivity && previewComponent}
						{loadingActivity && <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon="spinner" spin /> Loading Activity...</div>}
					</div>
				</div>
			</Tab> */}
			{/* <Tab eventKey={3} style={{ minHeight: 0, flex: 1 }} title="Side By Side">
				<div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
					<div style={{ flex: 1, height: "100%", display: "flex", flexDirection: "row", width: "100%", overflow: "hidden" }}>
						{editorComponent}
					</div>
					<div style={{ flex: 1, height: "100%", display: "flex", flexDirection: "row", width: "100%", overflow: "hidden" }}>
						{previewComponent}
					</div>
				</div>
			</Tab> */}
		</Tabs >;

	}

}

ThemeEditor.contextTypes = {
	tree: PropTypes.baobab
};
export default branch({
	activities: ["activities"]
}, ThemeEditor);

import { deepMerge } from "baobab/dist/helpers";

import systemUtteranceFindReplace from "../constants/utteranceFindReplace";

import state from "../state/state";

import _ from "lodash";

let striptags = require("striptags");
const removeMd = require("remove-markdown");

function replaceStringsUsingMap(string, replaceMap) {
	let replaceString = string;
	let regex;

	// Need to loop through map
	for (let key in replaceMap) {
		regex = new RegExp(key, "g");
		replaceString = replaceString.replace(regex, replaceMap[key]);
	}

	return replaceString;
}

export function fixUtterance(utterance) {
	// console.log(DeviceInfo.getManufacturer() + "; " + DeviceInfo.getModel());
	//const ttsPhonetics = _.cloneDeep(state.get(["selectedOrg", "preferences", "tts-phonetics", "ttsPhoneticsCompiled"]));

	//let systemMap = ttsPhonetics ? ttsPhonetics : systemUtteranceFindReplace;

	//console.log("*** System Map: " + JSON.stringify(systemMap));

	// Need to get from state any find replace maps for logged in user
	// May get them from organization level
	// May get the from activity level
	// Need to merge all starting with system, then organization, then activity, then platform, then manufacturer, then specific model

	// Simulate now
	//var findReplaceMap = simulateUtteranceFindReplace;

	//var srcMerge = deepmerge(systemMap, findReplaceMap);
	//let srcMerge = systemMap;

	//let dstMerge = srcMerge.system;
	//dstMerge = deepMerge(dstMerge, srcMerge.organization);
	//dstMerge = deepMerge(dstMerge, srcMerge.activity);
	//dstMerge = deepMerge(dstMerge, srcMerge.platforms["web"]);
	// dstMerge = deepMerge(dstMerge, srcMerge.manufacturers[DeviceInfo.getManufacturer()]);
	// dstMerge = deepMerge(dstMerge, srcMerge.models[DeviceInfo.getModel()]);

	let strip = striptags(utterance);
	let remove = removeMd(strip);
	//let fix = replaceStringsUsingMap(remove, dstMerge);
	let fix = replaceTtsPhonetics(remove);

	return fix;
}

export function replaceTtsPhonetics(utterance) {

	const ttsPhonetics = _.cloneDeep(state.get(["selectedOrg", "preferences", "tts-phonetics", "ttsPhoneticsCompiled"]));

	let systemMap = ttsPhonetics ? ttsPhonetics : systemUtteranceFindReplace;

	console.log("*** System Map: " + JSON.stringify(systemMap));

	let srcMerge = systemMap;

	let dstMerge = srcMerge.system;
	dstMerge = deepMerge(dstMerge, srcMerge.organization);
	dstMerge = deepMerge(dstMerge, srcMerge.activity);
	dstMerge = deepMerge(dstMerge, srcMerge.platforms["web"]);

	let replaced = replaceStringsUsingMap(utterance, dstMerge);
	return replaced;
}


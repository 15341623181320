import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import DriveContext from "../../driveContext";
import styles from "./listNode.module.css";

import { generateUUID, getFileTypeIcon } from "../../../../utils/utils";

class ListNode extends PureComponent {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleClick(event) {
		if (event.detail === 2) {
			this.handleDblClick();
		} else {
			this.handleSelect();
		}
	}

	handleSelect() {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.node);
		}
	}

	handleDblClick() {
		if (this.props.onDblClick) {
			this.props.onDblClick(this.props.node);
		}
	}

	renderHeader(name, active, type, node, meta, accept) {

		let icon = null;
		if (type === "folder") {
			icon = <FontAwesomeIcon icon={"folder"} />;
		} else if (type === "file") {
			icon = <FontAwesomeIcon icon={getFileTypeIcon(node.ext, node)} />;
		} else {
			icon = <FontAwesomeIcon icon="cloud" />;
		}
		let fnsSelect = {};
		let acceptable = (type === "folder" || !accept || (accept && node && node.metaData && node.metaData.type === accept));
		if (acceptable) {
			fnsSelect = {
				onClick: this.handleClick
			};
		}

		return (
			<div className={acceptable ? active ? "header selected" : "header" : "header disabled"} title={name}>
				<div className="name" style={{ flex: 1, padding: "5px", display: "flex", flexDirection: "row", width: "100%" }} {...fnsSelect}>
					<div className={styles.icon}>{icon}</div>
					<div className={styles["name-container"]}>{name}</div>
				</div>
			</div>
		);
	}

	render() {
		const { node, meta, accept } = this.props;
		const { guid, id, name, type } = node;
		const { active } = meta ? meta : node;

		const key = `node-${guid ? guid : id ? id : generateUUID()}`;

		// console.log("Render node", name, toggled, active);
		return <div key={key} className="node" id={`drive-node-${this.context?.driveComponentId}-${node.guid}`}>
			{this.renderHeader(name, active, type, node, meta, accept)}
		</div>;
	}
}

ListNode.contextType = DriveContext;

export default branch((props) => {
	// console.log("Mapping", props.node.guid, props.node);
	if (props.node.guid) {
		return {
			meta: ["drive", "hierarchyMetadata", props.node.guid]
		};
	} else {
		return {};
	}
}, ListNode);
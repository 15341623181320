import _ from "lodash";

// Helper functions for filtering
export const defaultMatcher = (filterText, filterTypesMap, node) => {
	let result = false;
	try {
		if (filterTypesMap && node && node.metaData && node.metaData.type && !filterTypesMap[node.metaData.type]) {
			return false;
		}
		if (node && node.name) {
			result = node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
		}


	} catch (e) {
		console.log("Filter text", filterText);
		console.log("Filter types map", filterTypesMap);
		console.error(e, filterText, node);
	}
	return result;
};

export const findNode = (node, filter, filterTypesMap, matcher) => {
	return matcher(filter, filterTypesMap, node) || // i match
		(node.children && // or i have decendents and one of them match
			node.children.length &&
			!!node.children.find(child => findNode(child, filter, filterTypesMap, matcher)));
};

export const filterList = (node, filter, filterTypesMap, matcher = defaultMatcher) => {
	const filtered = filterChildren(node.children, filter, filterTypesMap, matcher);
	return Object.assign({}, node, { children: filtered });
};

export const filterTree = (node, filter, filterTypesMap, matcher = defaultMatcher) => {
	// If im an exact match then all my children get to stay
	if (matcher(filter, filterTypesMap, node) || !node.children) { return node; }
	// If not then only keep the ones that match or have matching descendants
	const filtered = filterChildren(node.children, filter, filterTypesMap, matcher);
	return Object.assign({}, node, { children: filtered });
};

function filterChildren(children = [], filter, filterTypesMap, matcher) {
	const filtered = children
		.filter(child => findNode(child, filter, filterTypesMap, matcher))
		.map(child => filterTree(child, filter, filterTypesMap, matcher));
	return filtered;
}

export const expandFilteredNodes = (node, filter, filterTypesMap, matcher = defaultMatcher) => {
	let children = node.children;
	if (!children || children.length === 0) {
		return Object.assign({}, node, { toggled: false });
	}
	const childrenWithMatches = node.children.filter(child => findNode(child, filter, filterTypesMap, matcher));
	const shouldExpand = childrenWithMatches.length > 0;
	// If im going to expand, go through all the matches and see if thier children need to expand
	if (shouldExpand) {
		children = childrenWithMatches.map(child => {
			return expandFilteredNodes(child, filter, filterTypesMap, matcher);
		});
	}
	return Object.assign({}, node, {
		children: children,
		toggled: shouldExpand
	});
};

export function filter(node, filter, filterTypes) {
	let filterTypesMap = null;
	if (_.isArray(filterTypes)) {
		_.forEach(filterTypes, filterType => {
			if (!filterTypesMap) {
				filterTypesMap = {};
			}
			filterTypesMap[_.isString(filterType) ? filterType : filterType.type] = true;
		});
	}

	let filtered = filterList(node, filter, filterTypesMap);

	return filtered;
}
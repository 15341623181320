import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import autoBind from "react-autobind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

import * as actions from "../../actions/actions";

class PreviewWrapper extends PureComponent {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	refreshPreview() {
		actions.previewChecklistInline();
	}

	// Doing at runtime...could potentially do first time loads up in state
	render() {
		return (
			<>
				<div style={{ backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: this.props.compact ? "center" : "space-between", width: "100%", padding: "5px", height: this.props.compact ? "50px" : "75px" }}>
					{this.props.showTitle &&
						<div style={{ marginLeft: "0px", display: "flex", flexDirection: "column" }}>
							<div style={{ fontSize: "18px", fontWeight: "bold", display: "inline-block" }}>Preview</div>
							<div style={{ fontSize: "12px", color: "#444444" }}>{this.props.previewLive ? "(Live)" : "(Manual)"}</div>
						</div>
					}
					<div style={{ display: "flex" }}>
						<div style={{ marginTop: "0px", display: "flex", flexDirection: this.props.compact ? "row" : "column", alignItems: "center", justifyContent: "center" }}>
							<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
								<div style={{ fontSize: "10px", color: "#444444" }}><b>Resolution</b> <span style={{ color: "#444444", fontSize: "10px" }}>(% or px)</span></div>
								<div style={{ display: "flex" }}>
									<input disabled={this.props.preview.resolutionDisabled} style={{ borderRadius: "5px", border: "1px solid #cccccc", width: "50px" }} type="text" value={this.props.preview.previewWidth} onChange={(e) => {
										this.context.tree.set(["appState", "preview", "previewWidth"], e.target.value);
									}}></input>
									<div style={{ marginLeft: "3px", marginRight: "3px" }}>x</div>
									<input disabled={this.props.preview.resolutionDisabled} style={{ borderRadius: "5px", border: "1px solid #cccccc", width: "50px" }} type="text" value={this.props.preview.previewHeight} onChange={(e) => {
										this.context.tree.set(["appState", "preview", "previewHeight"], e.target.value);
									}}></input>
									<div style={{ marginLeft: "7px", transform: "rotate(-45deg)", cursor: "pointer" }} onClick={() => {
										this.context.tree.set(["appState", "preview", "previewWidth"], this.props.preview.previewHeight);
										this.context.tree.set(["appState", "preview", "previewHeight"], this.props.preview.previewWidth);
									}}>
										<FontAwesomeIcon size="sm" icon={["fas", "mobile-alt"]} />
									</div>

								</div>
							</div>
							<select style={{ marginLeft: this.props.compact ? "15px" : "0px", borderRadius: "5px", border: "1px solid #cccccc", marginTop: "5px", marginBottom: "0px", width: "135px" }} value={this.props.preview.resolution} onChange={(e) => {
								const resValue = e.target.value;
								if (resValue === "responsive") {
									this.context.tree.set(["appState", "preview"], {
										resolution: resValue,
										previewScale: this.context.tree.get(["appState", "preview", "previewScale"]),
										previewWidth: "100%",
										previewHeight: "100%",
										resolutionDisabled: false
									});
								} else if (resValue === "custom") {
									this.context.tree.set(["appState", "preview"], {
										resolution: resValue,
										previewScale: this.context.tree.get(["appState", "preview", "previewScale"]),
										previewWidth: "480",
										previewHeight: "640",
										resolutionDisabled: false
									});
								} else {
									const resParts = resValue.split(",");
									this.context.tree.set(["appState", "preview"], {
										resolution: resValue,
										previewScale: this.context.tree.get(["appState", "preview", "previewScale"]),
										previewWidth: resParts[0],
										previewHeight: resParts[1],
										resolutionDisabled: true
									});
								}
							}}>
								<option value="responsive">Responsive</option>
								<option value="custom">Custom</option>
								<option value="360,640">Moto G4</option>
								<option value="360,640">Galaxy S5</option>
								<option value="411,731">Pixel 2</option>
								<option value="411,823">Pixel 2 XL</option>
								<option value="320,568">iPhone 5/SE</option>
								<option value="375,667">iPhone 6/7/8</option>
								<option value="414,736">iPhone 6/7/8 Plus</option>
								<option value="375,812">iPhone X</option>
								<option value="768,1024">iPad</option>
								<option value="1024,1366">iPad Pro 12.9</option>
								<option value="540,720">Surface Duo</option>
								<option value="280,653">Galaxy Fold</option>
								<option value="480,480">Vuxiz Blade</option>
								<option value="854,480">Vuxiz M400/4000</option>
							</select>
						</div>
						<div style={{ marginTop: "0px", marginLeft: "15px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
							<div style={{ color: "#444444", fontSize: "10px", fontWeight: "bold" }}>Zoom</div>
							<select style={{ borderRadius: "5px", border: "1px solid #cccccc", marginRight: "5px", marginBottom: "0px" }} value={this.props.preview.previewScale} onChange={(e) => {
								this.context.tree.set(["appState", "preview", "previewScale"], e.target.value);
							}}>
								<option value="25">25%</option>
								<option value="33">33%</option>
								<option value="50">50%</option>
								<option value="66">66%</option>
								<option value="75">75%</option>
								<option value="100">100%</option>
								<option value="125">125%</option>
								<option value="150">150%</option>
								<option value="200">200%</option>
							</select>
						</div>
					</div>
					{this.props.showActions &&
						<div style={{ display: "flex", flexDirection: "row" }}>
							<Button onClick={() => this.props.dispatch(actions.togglePreviewVisible)} style={{ marginRight: "5px" }}><FontAwesomeIcon size="lg" icon={this.props.previewAllVisible ? "eye" : "eye-slash"} /></Button>
							<Button onClick={() => this.props.dispatch(actions.togglePreviewLive)} style={{ marginRight: "5px" }}><FontAwesomeIcon size="2x" icon={[this.props.previewLive ? "fas" : "far", "flux-capacitor"]} /></Button>
							<Button onClick={this.refreshPreview} style={{ marginRight: "0px" }}><FontAwesomeIcon size="lg" icon="sync" /></Button>
						</div>
					}
				</div>
				<div style={{ overflow: "auto", height: "100%", backgroundColor: "#666666" }}>
					<div style={{ width: (this.props.preview.previewWidth.endsWith("%") || this.props.preview.previewWidth.endsWith("px")) ? this.props.preview.previewWidth : this.props.preview.previewWidth + "px", height: (this.props.preview.previewHeight.endsWith("%") || this.props.preview.previewHeight.endsWith("px")) ? this.props.preview.previewHeight : this.props.preview.previewHeight + "px", transformOrigin: this.props.preview.previewScale > 100 ? "top left" : "top left", transform: "scale(" + Number(this.props.preview.previewScale) / 100 + ")" }}>
						{this.props.children}
					</div>
				</div>
			</>
		)
	}
}

PreviewWrapper.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	preview: ["appState", "preview"],
	previewLive: ["appState", "previewChecklist", "live"],
	previewAllVisible: ["appState", "previewChecklist", "allVisible"]
}, PreviewWrapper);

import _ from "lodash";
import request from "superagent";

import { listAllObjects, uploadFileToS3, deleteObject, deleteObjects, objectExists, getObject, moveObject, /*waitForKeyExists,*/ copyObject, putObject } from "../persistence/s3";
import env from "../constants/env";
import { getOrgId } from "./orgsActions";
import state from "../state/state";
import { getLs, setLs } from "../utils/utils";
import { getJwtToken, getIdentityId } from "./user";
import { generateUUID, isMediaFile, fixFileNameForS3, getFileNameExtension, uncompressResult } from "../utils/utils";
import { externalResourceExists, getDriveObjectForReference, getExternalResource } from "./activityActions";
import { refreshCredentials } from "../utils/securityUtil";
//import { uploadMediaFile } from "./portalActions";
import naturalCompare from "string-natural-compare";

import * as filters from "../utils/treebeard";

import { fetchDrive } from "./portalActions";
import { fetchAllFileObjectsForFolder, fetchDriveListForSelectedFolder, fetchDriveTree } from "../drive/driveTreeBuilder";

// import { handleChangeProperty, getProperty } from "./actions";
// import { getExternalResources } from "./activityActions";
export const RESOURCE_TYPE_DRIVE = "resource/external/drive";
export const RESOURCE_REFERENCE = "resource/ref";
// const PREFIX_DRIVE = "drive";
// let cachedDriveObjects = null;
// let lastHierarchyRequest = null;

// export function putFile(path, file) {

// }

export function showSpinner() {
	state.set(["drive", "showSpinner"], true);
}

export function hideSpinner() {
	state.set(["drive", "showSpinner"], false);
}

function applyToggleState(hierarchy) {
	const toggleState = state.get(["drive", "toggleState"]);
	if (toggleState && !_.isEmpty(toggleState)) {
		_applyToggleState(hierarchy, toggleState);
	}
}

function _applyToggleState(node, toggleState) {
	if (node && node.children) {
		const _path = _.join(node.path, "-");
		if (toggleState.hasOwnProperty(_path)) {
			node.toggled = toggleState[_path];
		}
		node.children.forEach(child => {
			_applyToggleState(child, toggleState);
		});
	}
}

function generatePathToTreePathMap(hierarchy, path = [], pathToTreePath = {}) {
	if (hierarchy._path) {
		pathToTreePath[hierarchy._path.join("/")] = path;
	}

	if (hierarchy.children) {
		hierarchy.children.forEach((child, idx) => {
			const _path = Object.assign([], path);
			_path.push("children", idx);
			generatePathToTreePathMap(child, _path, pathToTreePath);
		});
	}
}

export function listDriveMap(driveResponse) {
	console.log("Listing drive map...");
	return new Promise(async (resolve) => {
		const objectGuidToObject = await fetchDriveTree("mapofguids", true, false);

		state.set(["drive", "guidToResource"], objectGuidToObject);
		resolve(driveResponse);
	});
}

export function initializeDriveMap(driveResponse) {
	console.log("Listing drive...");
	return new Promise((resolve) => {
		const { objectGuidToObject } = driveResponse;
		state.set(["drive", "guidToResource"], objectGuidToObject);

		let availableTypeFilters = {};
		const editors = state.get(["driveEditors", "editors"]);
		_.forEach(objectGuidToObject, item => {
			if (item && item.type === "file" && item.metaData && item.metaData.type) {
				const { type } = item.metaData;
				if (!availableTypeFilters[type]) {
					let _type = editors[type] ? _.omit(editors[type], ["component"]) : type;
					availableTypeFilters[type] = {
						count: 0,
						type: _type
					};
				}
				availableTypeFilters[type].count++;
			}
		});
		console.log("Available type filters", availableTypeFilters);
		state.set(["drive", "typeFilters"], availableTypeFilters);

		hideSpinner();
		resolve(driveResponse);
	});
}


export function initializeDrive(driveResponse) {
	console.log("Listing drive...");
	return new Promise((resolve) => {
		let hierarchy = driveResponse.tree;
		applyToggleState(hierarchy);
		const pathToTreePath = {};
		const { objectGuidToObject } = driveResponse;
		generatePathToTreePathMap(hierarchy, [], pathToTreePath);
		console.log("Path to tree path", pathToTreePath);
		state.set(["drive", "initialState"], hierarchy);
		state.set(["drive", "baseHierarchy"], hierarchy);
		state.set(["drive", "hierarchy"], hierarchy);
		state.set(["drive", "pathToTreePath"], pathToTreePath);
		state.set(["drive", "guidToResource"], objectGuidToObject);

		let availableTypeFilters = {};
		const editors = state.get(["driveEditors", "editors"]);
		_.forEach(objectGuidToObject, item => {
			if (item && item.type === "file" && item.metaData && item.metaData.type) {
				const { type } = item.metaData;
				if (!availableTypeFilters[type]) {
					let _type = editors[type] ? _.omit(editors[type], ["component"]) : type;
					availableTypeFilters[type] = {
						count: 0,
						type: _type
					};
				}
				availableTypeFilters[type].count++;
			}
		});
		console.log("Available type filters", availableTypeFilters);
		state.set(["drive", "typeFilters"], availableTypeFilters);


		//sortHierarchy(["createdAt"]);
		filterHierarchy();
		hideSpinner();
		resolve(driveResponse);
	});
}

export function listDrive() {
	console.log("Listing drive...");
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				fetchDrive(getJwtToken())
					.then(res => {
						console.log("List drive response", res);
						let hierarchy = res.tree;
						applyToggleState(hierarchy);
						const pathToTreePath = {};
						generatePathToTreePathMap(hierarchy, [], pathToTreePath);
						console.log("Path to tree path", pathToTreePath);
						state.set(["drive", "initialState"], hierarchy);
						state.set(["drive", "baseHierarchy"], hierarchy);
						state.set(["drive", "hierarchy"], hierarchy);
						state.set(["drive", "pathToTreePath"], pathToTreePath);
						state.set(["drive", "guidToResource"], res.objectGuidToObject);
						state.set(["drive", "pathToResource"], res.objectPathToObject);
						console.log("Drive set", state.get(["drive"]));
						//sortHierarchy(["createdAt"]);
						filterHierarchy();
						hideSpinner();
						resolve(res);
					}).catch(err => {
						console.error(err);
						hideSpinner();
						reject(err);
					});
			}).catch(err => {
				hideSpinner();
				reject(err);
			});
		});
	});
}

/**
 * Sets the selected folder path, then refreshes the drive list
 * 
 * @param {string} selectedFolder The path of the selected folder
 */
export async function selectFolderAndListDrive(selectedFolder) {
	console.log(`Listing drive for selected folder: ${selectedFolder} ...`);
	state.set(["drive", "selectedFolder"], selectedFolder);

	//Clear filter every time folder is changed
	state.set(["drive", "filter"], "");
	await listDriveForSelectedFolder();
}

/**
 * Gets the top level folder hierarchy for the currently selected folder
 */
export async function listDriveForSelectedFolder() {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(async () => {
			try {
				// Only update the hierarchy from S3, don't get any file objects from DB
				let hierarchy = await fetchDriveListForSelectedFolder();
				const pathToTreePath = {};
				generatePathToTreePathMap(hierarchy, [], pathToTreePath);
				console.log("Path to tree path", pathToTreePath);
				state.set(["drive", "initialState"], hierarchy);
				state.set(["drive", "baseHierarchy"], hierarchy);
				state.set(["drive", "pathToTreePath"], pathToTreePath);
				state.set(["drive", "hierarchy"], hierarchy);
				console.log("Drive set", state.get(["drive"]));
				//sortHierarchy(["createdAt"]);
				filterHierarchy();
				hideSpinner();
				resolve();
			} catch (err) {
				console.error(err);
				hideSpinner();
				reject(err);
			}
		});
	});
}

export function selectFolderAndFile(tree, fileInfo) {
	let selectedFolder = fileInfo.path.substring(0, fileInfo.path.lastIndexOf("/") + 1);
	tree.set(["drive", "selectedFolder"], selectedFolder);

	// We want to clear the currently selected file and set a new one
	clearSelectionAndActiveTreeCursor();
	setNodeActive(fileInfo.guid, true);
}

export function listDriveBackup() {
	console.log("Listing drive...");
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.list}?mode=tree&compressResult=true&includeReferenceMap=true`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.then(res => {
						const uncompressedRes = uncompressResult(res.body);

						console.log(uncompressedRes);
						let hierarchy = uncompressedRes.tree;
						applyToggleState(hierarchy);
						const pathToTreePath = {};
						generatePathToTreePathMap(hierarchy, [], pathToTreePath);
						console.log("Path to tree path", pathToTreePath);
						state.set(["drive", "initialState"], hierarchy);
						state.set(["drive", "baseHierarchy"], hierarchy);
						state.set(["drive", "hierarchy"], hierarchy);
						state.set(["drive", "pathToTreePath"], pathToTreePath);
						state.set(["drive", "guidToResource"], uncompressedRes.objectGuidToObject);
						console.log("Drive set", state.get(["drive"]));
						//sortHierarchy(["createdAt"]);
						filterHierarchy();
						hideSpinner();
						resolve(uncompressedRes);
					}).catch(err => {
						console.error(err);
						hideSpinner();
						reject(err);
					});
			}).catch(err => {
				hideSpinner();
				reject(err);
			});
		});
	});

}

export function getDriveResourceForPath(path) {
	let result = null;
	const pathToTreePath = state.get(["drive", "pathToTreePath", path]);
	if (pathToTreePath && _.isArray(pathToTreePath)) {
		const hierarchy = state.get(["drive", "hierarchy"]);
		result = _.get(hierarchy, pathToTreePath.join("."));
	}
	return result;
}

export function getDriveResourceGuidForPath(path) {
	let result = null;
	const resource = getDriveResourceForPath(path);
	if (resource) {
		result = resource.guid;
	}
	return result;
}

export function getDriveObjectsAsMap() {
	return state.get(["drive", "guidToResource"]);
}

export function getDriveObject(guid) {
	const resourceInfos = getDriveObjectsAsMap();
	let result = null;
	if (resourceInfos[guid]) {
		result = resourceInfos[guid];
	}
	return result;
}

export function getDriveObjectByPath(path, isVideo = false) {
	console.log("!$!$!$ getDriveObjectByPath");
	const resourceInfos = getDriveObjectsAsMap();
	console.log("!$!$!$ getDriveObjectByPath", getDriveObjectsAsMap());
	let result = null;

	if (resourceInfos) {
		console.log("!$!$!$ Resource Infos", resourceInfos);
		const orgId = getOrgId();
		const expandedOrgPath = path.replace("public/", `public/${orgId}/`).replace("private/", `private/${orgId}/`);
		_.forEach(resourceInfos, info => {

			if (_.isString(info.path) && (info.path === path || info.path === expandedOrgPath)) {
				result = info;
			} else if (_.isArray(info.path) && (info.path.join("/") === path || info.path.join("/") === expandedOrgPath)) {
				result = info;
			}
		});

		if (isVideo && !result) {
			// Try to find with mp4 extension ... uploading a .mov file for example will result in mp4 after optimization
			const _path = _.concat(_.dropRight(path.split(".")), "mp4").join(".");
			const _expandedOrgPath = _path.replace("public/", `public/${orgId}/`).replace("private/", `private/${orgId}/`);
			_.forEach(resourceInfos, info => {

				if (_.isString(info.path) && (info.path === _path || info.path === _expandedOrgPath)) {
					result = info;
				} else if (_.isArray(info.path) && (info.path.join("/") === _path || info.path.join("/") === _expandedOrgPath)) {
					result = info;
				}
			});
		}
	}

	console.log("!$!$!$ RESULT", result);

	return result;
}

// export function renameFile(guid, destinationPath) {

// }

async function getFilesFromFolder(itemInfo) {
	let files = [];

	if (itemInfo) {
		if (itemInfo.type === "folder") {
			let folder = itemInfo.fullPath;
			let folderFiles = await fetchAllFileObjectsForFolder(folder);
			files = files.concat(folderFiles);
		} else if (itemInfo.type === "file") {
			files.push(itemInfo);
		}
	}
	return files;
}

export function copyFolder(folderInfo, destinationFolderPath) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(async () => {

				// Check for invalid folder		
				if (folderInfo.type === "folder" && isValidFolderForOperation(folderInfo.fullPath) === false) {
					throw new Error(`Source folder ${folderInfo.fullPath} is not valid for copy`);
				}

				let files = await getFilesFromFolder(folderInfo);
				new Promise((res, rej) => {
					if (!_.isEmpty(files)) {
						// const orgId = getOrgId();
						// Process them properly ... the all need to be moved.
						const orgId = getOrgId();
						const copyInfos = _.map(files, file => {
							const currentFilePath = file._path.join("/");
							const originFolderPath = folderInfo._path.join("/");
							const destinationPath = currentFilePath.replace(originFolderPath, destinationFolderPath);
							console.log(`Copy from ${file._path.join("/")} to ${file._path.join("/").replace(folderInfo._path.join("/"), destinationFolderPath)}`);
							return {
								orgId,
								guid: file.guid,
								destinationPath
							};
						});

						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.copyObject}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify(copyInfos))
							.then(() => {
								res();
							}).catch(err => {
								console.error(err);
								rej(err);
							});

					} else {
						res();
					}
				}).then(() => {
					if (_.isEmpty(files)) {
						const _path = `${getFullObjectPath(folderInfo._path.join("/"))}/`;
						const _newPath = `${getFullObjectPath(destinationFolderPath)}/`;
						console.log("Now delete folder from S3", _path);
						copyObject(env.s3.assetsBucket, _path, env.s3.assetsBucket, _newPath).then(() => {
							listDrive(true).then(() => {
								resolve();
							}).catch(err => { handleError(err, reject, true); });
						}).catch(err => { handleError(err, reject, true); });
					} else {
						listDrive(true).then(() => {
							resolve();
						}).catch(err => { handleError(err, reject, true); });
					}

				}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});
	});
}


export function moveFolder(folderInfo, destinationFolderPath) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(async () => {

				// Check for invalid folder		
				if (folderInfo.type === "folder" && isValidFolderForOperation(folderInfo.fullPath) === false) {
					throw new Error(`Source folder ${folderInfo.fullPath} is not valid for move`);
				}

				let files = await getFilesFromFolder(folderInfo);

				new Promise((res, rej) => {
					if (!_.isEmpty(files)) {
						// const orgId = getOrgId();
						// Process them properly ... the all need to be moved.
						const orgId = getOrgId();
						const moveInfos = _.map(files, file => {
							const currentFilePath = file._path.join("/");
							const originFolderPath = folderInfo._path.join("/");
							const destinationPath = currentFilePath.replace(originFolderPath, destinationFolderPath);
							console.log(`Move from ${file._path.join("/")} to ${file._path.join("/").replace(folderInfo._path.join("/"), destinationFolderPath)}`);
							return {
								orgId,
								guid: file.guid,
								destinationPath
							};
						});

						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.moveObject}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify({ srcPath: folderInfo.fullPath, moveObjectInfos: moveInfos }))
							.then(() => {
								res();
							}).catch(err => {
								console.error(err);
								rej(err);
							});

					} else {
						res();
					}
				}).then(() => {
					if (_.isEmpty(files)) {
						const _path = `${getFullObjectPath(folderInfo._path.join("/"))}/`;
						const _newPath = `${getFullObjectPath(destinationFolderPath)}/`;
						console.log("Now delete folder from S3", _path);
						moveObject(env.s3.assetsBucket, _path, env.s3.assetsBucket, _newPath).then(() => {
							clearSelectionAndActiveTreeCursor();

							listDrive(true).then(() => {
								resolve();
							}).catch(err => { handleError(err, reject, true); });
						}).catch(err => { handleError(err, reject, true); });
					} else {
						clearSelectionAndActiveTreeCursor();

						listDrive(true).then(() => {
							resolve();
						}).catch(err => { handleError(err, reject, true); });
					}
				}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});
	});
}
export function copyFile(guid, destinationPath) {

	// {
	// 	"guid" : "bb44e8da-2b52-4d20-9a70-a8e41905e467",
	// 		"destinationPath" : "public/blah/new/hello3.jpg",
	// 			"orgId" : "blah"
	// }

	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.copyObject}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(JSON.stringify({
						"orgId": getOrgId(),
						guid,
						destinationPath
					}))
					.then(() => {
						listDrive(true).then(() => {
							hideSpinner();
							resolve();
						}).catch(err => { handleError(err, reject, true); });
					}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});

	});
}
export function moveFile(guid, destinationPath) {

	// {
	// 	"guid" : "bb44e8da-2b52-4d20-9a70-a8e41905e467",
	// 		"destinationPath" : "public/blah/new/hello3.jpg",
	// 			"orgId" : "blah"
	// }

	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.moveObject}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(JSON.stringify({
						"orgId": getOrgId(),
						guid,
						destinationPath
					}))
					.then(() => {
						listDrive(true).then(() => {
							hideSpinner();
							resolve(getDriveTreeNodeByPath(destinationPath));
						}).catch(err => { handleError(err, reject, true); });
					}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});

	});
}

export function createFolder(path) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			const folderPath = getFullObjectPath(path);
			console.log("About to create folder at ", folderPath);
			uploadFileToS3(env.s3.assetsBucket, folderPath, null, true).then(() => {
				// Only refresh folders from S3 since we aren't adding/removing any files which requires
				// refreshing the guid/path object maps
				listDriveForSelectedFolder().then(() => {
					hideSpinner();
					resolve();
				}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});
	});
}

export function uploadContent(path, content, contentType, metaData, ignoreFileName = false) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				const folderPath = getFullObjectPath(path);
				const fileName = _.last(folderPath.split("/"));
				const folderPathNoFileName = folderPath.replace(`/${fileName}`, "");
				const _fileName = fixFileNameForS3(fileName);
				console.log("About to save content", folderPath, fileName, folderPathNoFileName);
				putObject(env.s3.assetsBucket, `${folderPathNoFileName}/${_fileName}`, content, contentType).then(() => {
					const _fileName = fixFileNameForS3(fileName);
					const payload = {
						orgId: getOrgId(),
						path: ignoreFileName ? folderPathNoFileName : `${folderPathNoFileName}/${_fileName}`
					};
					if (metaData) {
						payload.metaData = metaData;
					}
					request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.createObject}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(payload))
						.then(() => {
							listDrive(true).then(() => {
								hideSpinner();
								resolve();
							}).catch(err => { handleError(err, reject, true); });
						}).catch(err => { handleError(err, reject, true); });

				}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});
	});
}

export async function uploadFile(path, file, propName, ignoreFileName = false, overrides = {}) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				const folderPath = getFullObjectPath(path);
				uploadFileToS3(env.s3.assetsBucket, folderPath, file, false, ignoreFileName, overrides).then(() => {
					let fileName = fixFileNameForS3(overrides.fileName ? overrides.fileName : file.name);

					//override extension
					if (overrides.extension) {
						let ext = getFileNameExtension(fileName);
						fileName = fileName.replace(`.${ext}`, `.${overrides.extension}`);
					}

					let path = ignoreFileName ? folderPath : `${folderPath}/${fileName}`;
					request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.createObject}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify({
							"orgId": getOrgId(),
							path: path
						}))
						.then((result) => {
							listDrive(true).then(() => {
								hideSpinner();
								result.body.path = path;
								resolve(result.body);
							}).catch(err => { handleError(err, reject, true); });
						}).catch(err => { handleError(err, reject, true); });

				}).catch(err => { handleError(err, reject, true); });
			}).catch(err => { handleError(err, reject, true); });
		});
	});
}

// function insertFileIntoHierarchy(path) {

// }

export function deleteFile(guid) {
	return new Promise((resolve, reject) => {
		const _activeTreeCursor = getActiveTreeCursor();
		clearSelectionAndActiveTreeCursor();
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(() => {
				request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.deleteObject(guid)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.then(() => {

						listDrive(true).then(() => {

							hideSpinner();
							resolve();
						}).catch(err => { setActiveTreeCursor(_activeTreeCursor); handleError(err, reject, true); });
					}).catch(err => { setActiveTreeCursor(_activeTreeCursor); handleError(err, reject, true); });
			}).catch(err => { setActiveTreeCursor(_activeTreeCursor); handleError(err, reject, true); });
		});

	});
}

export function deleteFolder(folderInfo) {
	return new Promise((resolve, reject) => {
		const _activeTreeCursor = getActiveTreeCursor();
		clearSelectionAndActiveTreeCursor();
		showSpinner();
		setTimeout(() => {
			refreshCredentials().then(async () => {

				// Check for invalid folder		
				if (folderInfo.type === "folder" && isValidFolderForOperation(folderInfo.fullPath) === false) {
					throw new Error(`Source folder ${folderInfo.fullPath} is not valid for delete`);
				}

				let files = await getFilesFromFolder(folderInfo, files);

				new Promise((res, rej) => {
					if (!_.isEmpty(files)) {
						// const orgId = getOrgId();
						// Process them properly ... the all need to be moved.
						const deleteInfos = _.map(files, file => {
							console.log("Goind to delete", file);
							return file.guid;
						});
						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.deleteObjects}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify({ srcPath: folderInfo.fullPath, objectGuids: deleteInfos }))
							.then(() => {
								res();
							}).catch(err => {
								console.error(err);
								rej(err);
							});
					} else {
						console.log("No files, going to just delete folder");
						res();
					}
				}).then(() => {
					// Now delete the S3 folder
					const _path = `${getFullObjectPath(folderInfo._path.join("/"))}/`;
					console.log("Now delete folder from S3", _path);
					listDrive(true).then(() => {
						resolve();
					}).catch(err => { handleError(err, reject, true); });
				}).catch(err => { setActiveTreeCursor(_activeTreeCursor); handleError(err, reject, true); });
			}).catch(err => { setActiveTreeCursor(_activeTreeCursor); handleError(err, reject, true); });
		});
	});
}

function handleError(err, reject, doHideSpinner) {
	console.error(err);
	if (doHideSpinner) {
		hideSpinner();
	}
	reject(err);
}

export function deleteFolderOld(path) {
	return new Promise((resolve, reject) => {
		const _path = getFullObjectPath(path);
		if (_.endsWith(path, "/")) {
			listAllObjects(env.s3.assetsBucket, _path).then(results => {
				const _deleteObjects = results.map(result => {
					return { Key: result.Key };
				});
				console.log("Need to delete", _deleteObjects);
				deleteObjects(env.s3.assetsBucket, _deleteObjects).then(() => {
					listDrive(true).then(() => {
						setActiveTreeCursor(null);
						resolve();
					}).catch(err => {
						console.error(err);
						reject(err);
					});
				}).catch(err => {
					console.error(err);
					reject(err);
				});
			});
		} else {
			deleteObject(env.s3.assetsBucket, _path).then(() => {
				listDrive(true).then(() => {
					resolve();
				}).catch(err => { handleError(err, reject, false); });
			}).catch(err => { handleError(err, reject, false); });
		}
	});
}

export function getFullObjectPath(path) {
	const orgId = getOrgId();
	if (_.isArray(path)) {
		path = path.join("/");
	}
	if (_.startsWith(path, `public/${orgId}`) || _.startsWith(path, `private/${orgId}`)) {
		return path; // Good!
	} else if (_.startsWith(path, "public/") || path === "public") {
		return path === "public" ? `public/${orgId}` : path.replace("public/", `public/${orgId}/`);
	} else if (_.startsWith(path, "private/") || path === "private") {
		return path === "private" ? `private/${orgId}` : path.replace("private/", `private/${orgId}/`);
	} else {
		throw new Error(`Invalid and unfixable path ${path} provided`);
	}
}

/**
 * Will get the content of an object from the drive
 * @param {*} guid the guid of the object that shall be retrieved
 */
export function getContent(guid) {
	return new Promise((resolve, reject) => {
		const resourceInfo = getDriveObject(guid);
		console.log(resourceInfo);
		const { path } = resourceInfo;

		objectExists(env.s3.assetsBucket, path).then(res => {
			if (!res) {
				reject(new Error(`Request Object does not exist in drive at relative path ${path}`));
			} else {
				getObject(env.s3.assetsBucket, path).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
			}
		}).catch(err => {
			reject(err);
		});

	});
}

/**
 * An object that references a resource entry in the activity, note that the entry MUST 
 * @param {*} refId this must be to an existing resource
 */
export function getResourceReference(refId, metaData = null, verifyExistance = false) {
	if (verifyExistance && !externalResourceExists(refId)) {
		throw new Error(`External Resource with id ${refId} doesn't exist in current activity.`);
	}
	let result = {
		type: RESOURCE_REFERENCE,
		refId
	};
	if (metaData) {
		result.metaData = metaData;
	}
	return result;
}

export function isResourceEntry(obj) {
	return obj && obj.type === RESOURCE_TYPE_DRIVE;
}

export function getResourceEntry(info, metaData = null, _id = null) {
	const id = _id ? _id : generateUUID();
	let result = {
		id,
		type: RESOURCE_TYPE_DRIVE,
		guid: info.guid,
		revision: info.revision,
		scope: getOrgId()
	};
	if (metaData) {
		result.metaData = metaData;
	}
	return result;
}

export function isDriveReference(obj) {
	return obj && obj.hasOwnProperty("type") && obj.type === RESOURCE_REFERENCE;
}

export function isNonEmptyResourceReference(obj) {
	return obj && obj.hasOwnProperty("type") && obj.type === RESOURCE_REFERENCE && obj.refId !== "" && obj.refId;
}

function validURL(str) {
	let pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
		"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
		"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
		"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
		"(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
	return !!pattern.test(str);
}

export function isNonEmptyResourceReferenceOrNonEmptyExternalResource(obj) {
	return obj && ((obj.hasOwnProperty("type") && obj.type === RESOURCE_REFERENCE && obj.refId !== "" && obj.refId) || (_.isString(obj) && validURL(obj)));
}
export function handleSave(content, extension = null) {
	state.set(["drive", "modals", "saveModal"], {
		show: true,
		content,
		extension,
		path: null,
		fileName: null
	});
}

export function showSaveModal() {
	console.log("Show modal");
	state.set(["drive", "modals", "saveModal", "show"], true);
}

export function hideSaveModal() {
	// Reset state on hide
	state.set(["drive", "modals", "saveModal"], {
		show: false,
		content: null,
		path: null,
		fileName: null
	});
}

function setPropForPath(path, propName, value) {
	const pathToTreePath = state.get(["drive", "pathToTreePath"]);
	const treePath = pathToTreePath[path.join("/")];
	if (treePath) {
		const targetPath = _.concat(["drive", "hierarchy"], treePath, [propName]);
		console.log("SETTING propName", propName, " on path ", targetPath, " to ", value);
		state.set(targetPath, value);
	}
}

export function setPathToggle(path, toggled) {
	state.set(["drive", "toggleState", _.join(path, "-")], toggled);
	setPropForPath(path, "toggled", toggled);
}

export function setPathActive(path, active) {
	state.set(["drive", "activeState", _.join(path, "-")], active);
	setPropForPath(path, "active", active);
}

export function setNodeActive(guid, active, cursor) {
	if (guid) {
		console.log("Setting node active", guid, cursor);
		if (active && cursor) {
			setActiveTreeCursor(cursor);
		} else {
			setActiveTreeCursor(null);
		}
		setNodeMetadataProp(guid, "active", active);
		state.set(["drive", "lastActiveGuid"], guid);
	}
}

export function clearSelection() {
	const lastActiveGuid = state.get(["drive", "lastActiveGuid"]);
	if (lastActiveGuid) {
		state.unset(["drive", "lastActiveGuid"]);
		setNodeMetadataProp(lastActiveGuid, "active", false);
	}
}

export function setNodeToggle(guid, toggled) {
	setNodeMetadataProp(guid, "toggled", toggled);
}

export function setNodeMetadataProp(guid, propName, propValue) {
	state.set(["drive", "hierarchyMetadata", guid, propName], propValue);
}

export function setActiveTreeCursor(cursor) {
	console.log("Setting active cursor", cursor);
	state.set(["drive", "hierarchyActiveCursor"], cursor);
}

export function setActiveTreeCursorForExternalResource(externalResource, expandToNode = true) {
	if (!externalResource || !externalResource.hasOwnProperty("guid")) {
		return;
	}
	const cursor = getDriveTreeNodeByGuid(externalResource.guid);
	clearSelection();
	setNodeActive(cursor.guid, true, cursor);
	if (expandToNode) {
		expandDownToTargetNode(cursor.guid);
	}
}

export function setActiveTreeCursorForExternalResourceReference(ref, activity = null, expandToNode = true) {
	if (!ref || !ref.hasOwnProperty("refId")) {
		return;
	}
	console.log("setActiveTreeCursorForExternalResourceReference", ref);
	const externalResource = getExternalResource(ref.refId, activity);
	if (externalResource) {
		const cursor = getDriveTreeNodeByGuid(externalResource.guid);
		// setActiveTreeCursor(cursor);
		clearSelection();
		setNodeActive(cursor.guid, true, cursor);
		if (expandToNode) {
			expandDownToTargetNode(cursor.guid);
		}
	}
}

export function setActiveTreeCursorForDriveGuid(guid) {
	const cursor = getDriveTreeNodeByGuid(guid);
	clearSelection();
	if (cursor) {
		setNodeActive(cursor.guid, true, cursor);
	}
}

/**
 * Given a target which can either be a tree node id (string) or a string based path such as /private/folder1/folder2/... or an array path such as ["chuldren",0,"children",1,...] this funcion will work from the top down and expand all expandable nodes.
 * @param {String|Array} target 
 */
export function expandDownToTargetNode(target) {
	console.log("Expand down to node", target);
	if (!target) {
		return;
	}
	let _targetPath = null;
	if (_.isArray(target)) {
		_targetPath = target;
	} else if (_.isString(target)) {
		// See if this is a path
		_targetPath = getDriveTreeNodePathForPath(target);
		if (!_targetPath) {
			const node = getDriveTreeNodeByGuid(target);
			_targetPath = getDriveTreeNodePathForPath(node.path);
		}
	}

	if (_targetPath && _.isArray(_targetPath)) {
		console.log("Got target path", _targetPath);
		let interimPath = [];
		const hierarchy = state.get(["drive", "hierarchy"]);
		_targetPath.forEach(pathFragment => {
			interimPath.push(pathFragment);
			const node = _.get(hierarchy, interimPath.join("."), null);
			if (node && _.isPlainObject(node) && node.hasOwnProperty("guid")) {
				console.log("Toggling node", node);
				setNodeToggle(node.guid, true);
			}
		});
	}
}

export function getActiveTreeCursor() {
	return _.cloneDeep(state.get(["drive", "hierarchyActiveCursor"]));
}

export function getUrlForResource(resource) {
	const externalResource = getDriveObject(resource.guid);
	if (externalResource) {

		return getUrlForExternalResource(externalResource);

	} else {
		throw new Error(`Unable to find external resoruce for guid ${resource.guid}`);
	}
}

export function getUrlForExternalResource(externalResource) {
	const urlPrefix = (externalResource.private) ? env.cloudFront.assetUrls.private : env.cloudFront.assetUrls.public;
	if (externalResource.path && externalResource.path.replace) {
		return `${urlPrefix}/${externalResource.path.replace(/^public\//, "").replace(/^private\//, "")}?v=${externalResource.revision}`;
	} else {
		return "";
	}
}

export function getSelectedItemLabel(resource) {
	const externalResource = getDriveObject(resource ? resource.guid : null);
	if (externalResource) {
		return externalResource.path.replace(`/${getOrgId()}/`, "/");

	} else {
		throw new Error(`Unable to find external resoruce for guid ${resource.guid}`);
	}
}

export function getUrlForValue(value, activityId = null) {
	let result = value;
	if (!_.isString(value) && isDriveReference(value)) {
		try {
			const expandedValue = getDriveObjectForReference(value, activityId);
			result = getUrlForResource(expandedValue, activityId);
		} catch (err) {
			console.warn(err);
		}
	}

	// If asset is from published drive, fow now return nothing
	if (_.isObject(result) && result.hasOwnProperty("type") && result.type === "resource/ref") {
		return "";
	} else {
		return result;
	}
}

const supportedForOptimization = ["image", "backgroundImage", "audio", "video", "heroImage"];

function propNameForMediaType(file) {
	let result = null;

	if (file && file.type) {
		if (file.type.indexOf("video/") === 0) {
			result = "video";
		} else if (file.type.indexOf("image/") === 0) {
			result = "image";
		} else if (file.type.indexOf("audio/") === 0) {
			result = "audio";
		}
	}

	return result;
}

export async function uploadAndOptimizeMediaFile(path, file, propName = null, ignoreFileName = false, overrides = {}) {

	if ((propName && _.indexOf(supportedForOptimization, propName) === -1) || !isMediaFile(file)) {
		console.log("SKIP OPTIMIZE UPLOAD");
		// Fallback
		return uploadFile(path, file, propName, ignoreFileName, overrides);
	} else {
		console.log("OPTIMIZE UPLOAD");
		return new Promise((resolve, reject) => {
			showSpinner();
			setTimeout(() => {
				refreshCredentials().then(async () => {
					let fileName = generateUUID();
					const identityId = getIdentityId();

					console.log("Selected file:", file, propName);

					let fileObjectName = (overrides.fileName) ? overrides.fileName : file.name;

					propName = propName ? propName : propNameForMediaType(file);
					//let parts = fileObjectName.split(".");
					//let ext = parts[parts.length - 1];

					let ext = getFileNameExtension(fileObjectName);
					let overrideType = "";
					if (overrides.hasOwnProperty("type")) {
						overrideType = overrides.type;
					}

					const typeTarget = (propName === "heroImage" || overrideType === "relatedItemCard") ? "image" : propName === "backgroundImage" ? "background-image" : propName === "backgroundAudio" ? "audio" : propName;
					let newExt;
					if (propName === "video") {
						newExt = "mp4";
					} else if (propName === "audio" || propName === "backgroundAudio") {
						newExt = "mp3";
					} else {
						newExt = ext;
					}

					let destinationPath = getFullObjectPath(path);
					let targetFileName = fixFileNameForS3(fileObjectName);
					if (ext !== newExt && _.endsWith(targetFileName, `.${ext}`)) {
						targetFileName = targetFileName.replace(`.${ext}`, `.${newExt}`);

						//override extension if different for optimized format
						overrides.extension = newExt;
					}

					let finalPathAndFile = `${destinationPath}/${targetFileName}`;
					let finalPath = ignoreFileName ? destinationPath : finalPathAndFile;

					let uploadResponse = await uploadFile(path, file, propName, ignoreFileName, overrides);

					//copy file to conversion bucket					
					let driveObjectGuid = uploadResponse.guid;
					await copyObject(env.s3.assetsBucket, finalPath, env.s3.converterBucket, typeTarget + `/${identityId}_${fileName}.${newExt}`, {
						Metadata: {
							"drive-object-guid": driveObjectGuid
						},
						MetadataDirective: "REPLACE"
					});

					resolve(uploadResponse);
					/*
					// Step 1: Upload this to the bucket that utilizes the media converter										
					uploadMediaFile(file, identityId + "_" + fileName + "." + ext, typeTarget).then(() => {
						const key = `${identityId}/assets/${typeTarget}/${fileName}.${newExt}`;
						const bucket = env.s3.contentBucket;
						const destinationBucket = env.s3.assetsBucket;
	
						// Step 2: Now wait until it actually exists (media converter output)
						waitForKeyExists(bucket, key).then(() => {
							console.log("Found it!");
							let destinationPath = getFullObjectPath(path);
							let targetFileName = fixFileNameForS3(fileObjectName);
							if (ext !== newExt && _.endsWith(targetFileName, `.${ext}`)) {
								// Replace it!
								targetFileName = targetFileName.replace(`.${ext}`, `.${newExt}`);
							}
							// Step 3: Move the Object into the AmbiFi drive - which is then equivalent with a normal upload.
							console.log("Move object", bucket, key, destinationBucket, destinationPath);
							moveObject(bucket, key, destinationBucket, ignoreFileName ? destinationPath : `${destinationPath}/${targetFileName}`).then(() => {
								// Ok it's now in the right location!
	
								request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.createObject}`)
									.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
									.send(JSON.stringify({
										"orgId": getOrgId(),
										path: ignoreFileName ? destinationPath : `${destinationPath}/${targetFileName}`
									}))
									.then(() => {
										listDrive(true).then(() => {
											hideSpinner();
											resolve();
										}).catch(err => { handleError(err, reject, true); });
									}).catch(err => { handleError(err, reject, true); });
	
							}).catch(err => { handleError(err, reject, true); });
						}).catch(err => { handleError(err, reject, true); });
					}).catch(err => { handleError(err, reject, true); });
					*/

				}).catch(err => { handleError(err, reject, true); });
			});
		});
	}
}

export function updateUploadInfos(scope, uploadInfos) {
	state.set(["drive", "uploads", scope], uploadInfos);
}

function findNodeForPrefix(node, prefix) {
	// console.log("Find node for prefix", prefix, node._path);
	if (node._path && _.isArray(node._path) && _.isArray(prefix) && node._path.join("/") === prefix.join("/")) {
		return node;
	} else if (node.hasOwnProperty("children") && _.isArray(node.children)) {
		let result = null;
		node.children.forEach(child => {
			if (!result) {
				result = findNodeForPrefix(child, prefix);
			}
		});
		return result;
	}
}

function getRootNode(name, path = "private", children = []) {
	return {
		root: true,
		allowDelete: false,
		allowCollapse: false,
		allowCreate: true,
		name,
		toggled: true,
		type: "folder",
		children,
		path,
		_path: path
	};
}

export function trimHierarchyToPrefix(hierarchy, prefix = null) {

	if (prefix && _.isString(prefix)) {
		prefix = prefix.split("/");
	}

	if (!prefix || !_.isArray(prefix) || prefix.length === 0 || _.isArray(hierarchy)) {
		return hierarchy;
	} else {
		const newRoot = findNodeForPrefix(hierarchy, prefix);
		if (newRoot && newRoot.type === "folder") {
			// Rebuild the drive root and adjust the functions
			const initialRoot = _.clone(hierarchy);
			initialRoot.children = newRoot.children;
			initialRoot.name = `Drive/${prefix.join("/")}/`;
			initialRoot._path = newRoot._path;
			initialRoot.path = newRoot.path;
			initialRoot.allowCreate = true;
			return initialRoot;
		} else if (_.first(prefix) === "private" || _.first(prefix) === "public") {
			console.warn(`Unable to find node for prefix ${prefix}`);
			return getRootNode(`Drive/${prefix.join("/")}/`, prefix);
		} else {
			console.warn(`Unable to find node for prefix ${prefix}, this prefix is not valid, must start with public|private`);
			return hierarchy;
		}
	}
}

export function handleLoadMore() {

}

export function filterHierarchy(filter = "_not_defined_++", typeFilters = null) {
	const _filter = filter !== "_not_defined_++" ? filter : state.get(["drive", "filter"]);
	const hierarchy = state.get(["drive", "baseHierarchy"]);

	let targetHierarchy = state.get(["drive", "baseHierarchy"]);

	let filterTypes = null;
	if (typeFilters && _.isArray(typeFilters) && !_.isEmpty(typeFilters)) {
		filterTypes = typeFilters;
	}
	if (_filter && !_.isEmpty(_filter)) {
		targetHierarchy = filters.filter(hierarchy, _filter, filterTypes);
	}

	state.set(["drive", "hierarchy"], targetHierarchy);
	state.set(["drive", "filter"], _filter);
}


function getItemsFromHierarchy(node, items = []) {
	if (node.children) {
		node.children.forEach(child => {
			getItemsFromHierarchy(child, items);
		});
	} else if (node.guid && node.type === "file") {
		items.push(node);
	}
}

/**
 * Transforms hierarchy based on mode. No longer needed.
 * @deprecated 
 * @param {@} hierarchy 
 * @param {*} targetMode 
 * @returns 
 */
function transformToMode(hierarchy, targetMode) {
	const _mode = targetMode ? targetMode : getDriveSetting("mode");
	if (!_mode || _mode === "tree") {
		return hierarchy;
	} else {
		let items = [];
		//Filter out folders from children
		getItemsFromHierarchy(hierarchy, items);
		hierarchy.children = items;
		return hierarchy;
	}
}

export function sortHierarchy(sortProps, asc = null) {
	const sortInfo = getDriveSetting("sort");
	const mode = getDriveSetting("mode");
	if (mode === "list") {
		let _sortProps = sortProps ? sortProps : sortInfo ? sortInfo.sortProps : null;
		let _asc = asc !== null ? asc : sortInfo ? sortInfo.asc : null;

		if (_sortProps) {
			const current = state.get(["drive", "baseHierarchy"]);
			if (_.isArray(current)) {
				const sorted = Object.assign([], current);
				sorted.sort((a, b) => naturalCompare(a.createdAt, b.createdAt));
				if (!_asc) {
					sorted.reverse();
				}
				console.log("SORTED", sorted);
				state.set(["drive", "baseHierarchy"], sorted);
				setDriveSetting("sort", {
					sortProps,
					asc
				});
			}
		}
	} else {
		return state.get(["drive", "baseHierarchy"]);
	}
}

export function handleSwitchHierarchyMode(targetMode) {
	setDriveSetting("mode", targetMode);
	setLs("driveUi", targetMode);
	state.set(["drive", "baseHierarchy"], _.cloneDeep(state.get(["drive", "initialState"])));
	filterHierarchy();
}

export function getDriveSetting(settingName) {
	return state.get(["drive", "settings", settingName]);
}

export function setDriveSetting(settingName, settingValue) {
	state.set(["drive", "settings", settingName], settingValue);
}

/**
 * API Enhancements
 * Based on some additional requirements to incorprorate editing and editors that want to take advantage of the drive
 */

/**
* Will return info for a specific drive resource
* @param {*} guid the drive resource to get info for
*/
export function getDriveResourceInfo(guid) {
	return getDriveObject(guid);
}

/**
 * Will return a map of drive resource guids to drive resources.
 */
export function getDriveResourceInfos() {
	return getDriveObjectsAsMap();
}

export function getDriveResourcesByMetaDataProperty(prop, value) {
	let results = [];
	_.forEach(getDriveResourceInfos, info => {
		if (info.metaData && info.metaData[prop] && info.metaData[prop] === value) {
			results.push(info);
		}
	});
	return results;
}

export function getDriveResourcesByType(type) {
	return getDriveResourcesByMetaDataProperty("type", type);
}

/**
 * Save to state a collection of editors given a type of file!
 */
export function registerDriveEditors(editors) {
	if (!state.get(["driveEditors", "editorsRegistered"])) {
		console.log("REGISTER DRIVE EDITORS", editors);
		state.set(["driveEditors", "editors"], editors);
		state.set(["driveEditors", "editorsRegistered"], true);
	}
}

/**
 * This is different than the drive resource in that it is the node in the drive tree that is visually rendered for the user
 * @param {*} path this can be the path including the orgId or without
 */
export function getDriveTreeNodeByPath(path) {
	console.log("getDriveTreeNodeByPath", path);
	let driveTreeNode = null;
	const treePath = getDriveTreeNodePathForPath(path);
	if (treePath && _.isArray(treePath)) {
		const hierarchy = state.get(["drive", "hierarchy"]);
		driveTreeNode = _.get(hierarchy, treePath.join("."), null);
	}
	return driveTreeNode;
}

/**
 * Gicen a path to a drive resource, return the tree node path.
 * @param {*} path the path to a drive resource, e.g.: private/{orgId}/folder/file.ext
 */
export function getDriveTreeNodePathForPath(path) {
	if (!path || !_.isString(path)) {
		return null;
	}
	path = path.replace(`/${getOrgId()}/`, "/");

	return state.get(["drive", "pathToTreePath", path]);
}

export function getDriveTreeNodeByGuid(guid) {
	console.log("getDriveTreeNodeByGuid", guid);

	let driveTreeNode = null;
	const driveObject = state.get(["drive", "guidToResource", guid]);
	if (driveObject) {
		driveTreeNode = getDriveTreeNodeByPath(driveObject.path);
	}
	return driveTreeNode;
}

export function clearSelectionAndActiveTreeCursor() {
	clearSelection();
	setActiveTreeCursor(null);
}

export function resetDrive() {
	state.set(["drive"], {
		settings: {
			mode: getLs("driveUi") ? getLs("driveUi") : "list"
		},
		children: null,
		modals: {
			saveModal: {
				show: false,
				content: null,
				path: null,
				fileName: null
			}
		},
		uploads: {},
		hierarchyMetadata: {},
		selectedFolder: ""
	});
}

/**
 * Check if a folder is valid when doing operations such as copying, moving, deleting, and renaming, this is to prevent things
 * like all folders in an orgs bucket from being deleted
 * @param {*} folder 
 */
function isValidFolderForOperation(folder) {

	let orgId = getOrgId();

	if (
		!folder ||
		folder === "/" ||
		folder === "private/" ||
		folder === "public/" ||
		folder === `private/${orgId}/` ||
		folder === `public/${orgId}/`
	) {
		return false;
	} else {
		return true;
	}
}

/**
 * List the drive before editing an activity, this will only load the drive if it has not 
 * been initialized yet.
 */
export async function getDriveForActivity(activity) {

	try {
		console.log(`Listing drive resources for activity id: ${activity.id}, name: ${activity.name}`);
		const Authorization = getJwtToken();
		if (!Authorization) {
			throw new Error("Unable to list drive resources for activity, no token found in state.");
		} else {

			let body = {
				mode: "mapofguids",
				compressResult: true
			}

			let objectGuids = [];

			_.each(activity.externalResources, (externalResource) => {
				if (externalResource.type === "resource/external/drive") {
					objectGuids.push(externalResource.guid);
				}
			});

			body.objectGuids = objectGuids;

			console.log("Payload", JSON.stringify(body, null, 4));

			let retVal = {};
			if (objectGuids.length > 0) {
				let promise = new Promise((resolve, reject) => {
					refreshCredentials().then(() => {
						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.drive.list}?v=${new Date().getTime()}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(body)
							.then(res => {
								resolve(res.body);
							}).catch(err => {
								console.error(err);
								reject(err);
							});
					}).catch(err => {
						reject(err);
					});
				});

				//Need to uncompress
				let responseText = await promise;

				const newResponseText = uncompressResult(responseText);
				retVal = newResponseText;
			} else {
				retVal = {};
			}

			console.log("Finished listing drive resources for activity", retVal);

			return retVal;
		}
	} catch (e) {
		console.error("Error listing drive", e);
		throw e;
	}

}
import React from "react";
import { FormControl } from "react-bootstrap";
import state from "../../state/state";
class LinkActionType extends React.PureComponent {
	render() {

		const options = state.get(["linkActionTypes"]).map(linkActionType => {
			const { action, label } = linkActionType;
			return <option key={`link-action-${action}`} value={action}>{label}</option>;
		});

		if (this.props.includeNone) {
			options.unshift(<option key="link-action-none" value="">No Action</option>);
		}


		return (
			<FormControl componentClass="select" value={this.props.value} onChange={this.props.onChange}>
				{this.props.includeInherit && <option value="inherit">Inherit</option>}
				{options}
			</FormControl>
		);
	}
}

export default LinkActionType;
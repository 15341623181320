import React from "react";
import { branch } from "baobab-react/higher-order";

import NavItem from "./app-nav-item.js";
import NavItemMyChecklists from "./app-nav-item-my-checklists.js";
import { navigateTo } from "../../actions/navActions";
import { isOrganizationAdministrator, isOrganizationAuthor, isOrganizationUser } from "../../actions/user";
import { isDev, isMobile, handleOpenInNewTab } from "../../utils/utils";
import { postMessage } from "../../actions/mobileAppCommunicationActions";

import state from "../../state/state";


class NavItems extends React.Component {

	render() {
		const { appCapabilities } = this.props;
		let home = (isMobile() || this.props.capabilitiesNav.homeVisible === false) ? null :
			<NavItemMyChecklists onFilter={this.props.onFilter} onFilterEnd={this.props.onFilterEnd} id="home" label="Home" icon="home" onClick={() => navigateTo("home")} selected={this.props.navPanel.selected === "home" ? true : false} filtersAll={this.props.filtersAll} />;

		let myChecklists =
			<NavItem id="myChecklists" label="Authoring" icon="edit" onClick={() => navigateTo("myChecklists")} selected={this.props.navPanel.selected === "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;

		if (this.props.capabilitiesMyChecklists.filterVisible) {
			myChecklists =
				<NavItemMyChecklists id="myChecklists" label="Authoring" icon="edit" onClick={() => navigateTo("myChecklists")} selected={this.props.navPanel.selected === "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />;
		}

		const searchHistoryNavItem = <NavItem id="searchHistory" key="nav:searchHistory" label="Status" icon="chart-bar" onClick={() => navigateTo("searchHistory")} selected={this.props.navPanel.selected === "searchHistory" ? true : false} />;
		const dashboardNavItem = <NavItem id="dashboard" key="nav:dashboard" label="Dashboard" icon="chart-line" onClick={() => navigateTo("dashboard")} selected={this.props.navPanel.selected === "dashboard" ? true : false} />;
		const timersNavItem = <NavItem id="timers" key="nav:timers" label="Timers" icon={["far", "clock"]} onClick={() => navigateTo("timers")} selected={this.props.navPanel.selected === "timers" ? true : false} />;
		// const historyNavItem = <NavItem id="history" key="nav:history" label="History" icon="history" onClick={() => navigateTo("history")} selected={this.props.navPanel.selected === "history" ? true : false} />;
		const importNavItem = <NavItem id="import" key="nav:import" label="Import" icon="upload" onClick={() => navigateTo("import")} selected={this.props.navPanel.selected === "import" ? true : false} />;
		const organizationNavItem = <NavItem id="organization" key="nav:organization" label="Organization" icon={["far", "building"]} onClick={() => navigateTo("organization")} selected={this.props.navPanel.selected === "organization" ? true : false} />;
		const reportingNavItem = <NavItem id="reporting" key="nav:reporting" label="Reporting" icon={["far", "file-pdf"]} onClick={() => navigateTo("reporting")} selected={this.props.navPanel.selected === "reporting" ? true : false} />;
		const contentManagementNavItem = <NavItem id="cms" key="nav:cms" label="Content Mgmt" icon={["far", "file-alt"]} onClick={() => navigateTo("cms")} selected={this.props.navPanel.selected === "cms" ? true : false} />;
		const driveNavItem = <NavItem id="drive" key="nav:drive" label="Drive" icon={["far", "hdd"]} onClick={() => navigateTo("drive")} selected={this.props.navPanel.selected === "drive" ? true : false} />;
		const assignmentsNavItem = <NavItem id="assignments" key="nav:assignments" label="Assignments" icon={["far", "calendar-check"]} onClick={() => navigateTo("assignments")} selected={this.props.navPanel.selected === "assignments" ? true : false} />;
		const publicationsNavItem = <NavItem id="publications" key="nav:publications" label="Publications" icon="books" onClick={() => navigateTo("publications")} selected={this.props.navPanel.selected === "publications" ? true : false} />;
		let additionalItems = [];
		if ((isOrganizationAdministrator() || isOrganizationAuthor()) && appCapabilities.assignments) {
			additionalItems.push(assignmentsNavItem);
		}
		if (isOrganizationAdministrator()) {
			additionalItems.push(searchHistoryNavItem);
			additionalItems.push(dashboardNavItem);
			additionalItems.push(timersNavItem);
			if (appCapabilities.publications) {
				additionalItems.push(publicationsNavItem);
			}
			additionalItems.push(importNavItem);
			additionalItems.push(driveNavItem);
			additionalItems.push(organizationNavItem);

		} else if (isOrganizationAuthor()) {
			additionalItems.push(searchHistoryNavItem);
			additionalItems.push(timersNavItem);
			additionalItems.push(importNavItem);
			additionalItems.push(driveNavItem);
		} else if (isOrganizationUser()) {
			additionalItems.push(searchHistoryNavItem);
		}
		if ((isOrganizationAdministrator() || isOrganizationAuthor())) {
			additionalItems.push(<NavItem id="support" key="nav:support" label="Getting Started" icon="info-circle" onClick={(e) => {
				if (isMobile()) {
					e.preventDefault();
					postMessage({
						external_url_open: "https://docs.ambifi.com"
					});
				} else {
					handleOpenInNewTab("https://docs.ambifi.com");
				}
			}} />);
			additionalItems.push(<NavItem id="developers" key="nav:developers" label="Developers" icon="info-circle" onClick={(e) => {
				if (isMobile()) {
					e.preventDefault();
					postMessage({
						external_url_open: "https://ambifi.atlassian.net/wiki/spaces/DOC/overview"
					});
				} else {
					handleOpenInNewTab("https://ambifi.atlassian.net/wiki/spaces/DOC/overview");
				}
			}} />);
		}
		if (isDev() && isOrganizationAdministrator()) {
			additionalItems.push([<div key="dev-only-label" style={{ padding: "10px" }}>Dev Only:</div>, reportingNavItem, contentManagementNavItem]);
		}


		const myChecklistsComponent = (isOrganizationAdministrator() || isOrganizationAuthor()) ? myChecklists : null;

		return (
			<div style={{ overflowY: "auto", flexDirection: "column", cursor: "pointer" }}>
				{/* <NavItem id="home" label="Home" icon="home" onClick={() => navigateTo("home")} selected={this.props.navPanel.selected === "home" ? true : false} /> */}
				{home}
				{myChecklistsComponent}
				<NavItem id="search" label="Search" icon="search" onClick={() => navigateTo("search")} selected={this.props.navPanel.selected === "search" ? true : false} />
				{state.get(["user", "chatEnabled"]) &&
					<NavItem id="chat" label="Chat" bubbleCount={this.props.chatUnreadCount} icon="comments-alt" onClick={() => navigateTo("chat")} selected={this.props.navPanel.selected === "chat" ? true : false} />
				}
				{additionalItems}
			</div>
		);
	}
}

export default branch({
	appCapabilities: ["appCapabilities"],
	navPanel: ["appState", "navPanel"],
	filtersAll: ["appState", "filters", "all"],
	capabilitiesMyChecklists: ["appCapabilities", "views", "myChecklists"],
	capabilitiesNav: ["appCapabilities", "views", "nav"],
	orgId: ["selectedOrg", "id"],
	urlParams: ["appState", "urlParams"],
	chatUnreadCount: ["appState", "streamChat", "unreadMessagesCount"]
}, NavItems);


import state from "../state/state";
import env from "../constants/env";

let initialized = false;

export function initializeConnectivityScreening() {
	if (!initialized) {
		initialized = true;
		console.log(" [] Initialize connectivity screening");
		if (navigator.onLine) {
			handleConnectivityChange({
				type: "online"
			});
		} else {
			handleConnectivityChange({
				type: "offline"
			});
		}
		window.addEventListener("offline", e => {
			handleConnectivityChange({
				e,
				type: "offline"
			});
		});
		window.addEventListener("online", e => {
			handleConnectivityChange({
				e,
				type: "online"
			});
		});

		setInterval(determineConnectivity, 15000);
		return determineConnectivity();
	} else {
		return Promise.resolve();
	}
}

function handleConnectivityChange(connectionInfo) {
	state.set(["connection", "networkType"], connectionInfo.type.toLowerCase());
}

function determineConnectivity() {
	return new Promise(resolve => {
		// state.set(["connection", "internetConnection"], true);
		// resolve();		/*
		try {
			fetch(env.pingUrl, { method: "GET", mode: "no-cors" })
				.then((res, err) => {
					if (err) {
						console.log("ERROR", err);
					}
					if (res && (res.status === 200 || res.status === 0)) {
						// Res status 0 means we got a response but there is a CORS violation!
						state.set(["connection", "internetConnection"], true);
						// console.debug("Online");
						resolve();
					} else {
						state.set(["connection", "internetConnection"], false);
						// console.debug("Offline");
						resolve();
					}
				}).catch(() => {
					state.set(["connection", "internetConnection"], false);
					// console.debug("Offline");
					resolve();

				});
		} catch (e) {
			console.log(e);
		}
	});

}

export function isConnected() {
	const networkType = state.get(["connection", "networkType"]);
	const internetConnection = state.get(["connection", "internetConnection"]);
	return internetConnection && (networkType !== null && networkType !== "offline");
}

export function isConnectedAndLoggedIn() {
	return isConnected() && state.get(["user", "loggedIn"]);
}
import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default (props) => {

	const [expanded, setExpanded] = useState(false);
	const showExpandCollapse = props.hasOwnProperty("showExpandCollapse") ? props.showExpandCollapse : true;

	return (
		<div className={`label-wrapper ${expanded ? "expanded" : "collapsed"}`} >
			<div className="label-content">
				{props.children}
			</div>
			{showExpandCollapse && <>
				<div className="expand-collapse more">
					<Button onClick={() => { setExpanded(true); }} bsStyle="link" bsSize="small">Show More</Button>
				</div>
				<div className="expand-collapse less">
					<Button onClick={() => { setExpanded(false); }} bsStyle="link" bsSize="small">Show Less</Button>
				</div>
			</>}

			<div className="overflow" />
		</div >
	);
};
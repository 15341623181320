import jsreport from "jsreport-browser-client-dist";
import AWS from "aws-sdk";
import _ from "lodash";
import moment from "moment";

// import * as utils from "../utils/utils";
import {
	getKeyPrefix,
	generateUUID,
	fixFileNameForS3,
	normalizeActivity,
	setFullSessionName,
	recurseReplaceSpeeds,
	recurseConvertChecklistToMarkdown,
	recurseDriveRefsToUrls,
	recurseReplaceHtmlEntities,
	isMobile,
	filterLearningActivities,
	isChecklistItems,
	isChecklistSections,
	isChecklistLists,
	createBlobCsv2,
	recurseToFlatArray,
	recurseToFlatArrayAdvanced,
	arrayToMap,
	exportContentDumpToJson
} from "../utils/utils";
import state from "../state/state";
import env from "../constants/env";
import * as utils from "../utils/utils";
import { refreshCredentials } from "../utils/securityUtil";
import { showError, showSuccess, showError2, showSuccessToast, showInfo } from "../actions/alertActions";
import { updateSharedDocument } from "../actions/shareActivityActions";
import { getOrgId } from "../actions/orgsActions";
import { updateAttribute, getIdentityId, getEmail, isOrganizationAdministrator, isOrganizationAuthor, getJwtToken } from "../actions/user";
import { getExternalResource, putLock } from "../actions/activityActions";
import { getActivityById, setActivities, updateActivity, initializeInventory, INVENTORY_EXTERNAL_CONTENT_PERSISTENT_PROPS } from "../actions/inventoryActions";
import { sendInventoryAndUserInfoMessage, postMessage } from "../actions/mobileAppCommunicationActions";
import PlayerPaths from "../constants/paths/playerPaths";
import { associateActivityWithWorkflow } from "../actions/workflowActions";
import { showStatusSpinner, hideStatusSpinner } from "../actions/actions";
import { hidePlayerSpinner, reloadActivities, showPlayerSpinner } from "../actions/playerActions";

import { publishActivityToSignedUrls } from "../actions/publish";

import FileSaver from "file-saver";
import { getContent, getDriveForActivity } from "../actions/drive";

import request from "superagent";

import { createExperiencesInventoryFromActivities, createExperiencesInventoryItemFromActivity } from "../utils/activity";
import { getChatCompletions } from "../ai/aiAssistant";

// import timerTemplates from "../data/timerTemplates.json";

AWS.config.update({ region: env.aws.defaultRegion });

export const PUBLIC_KEY_PREFIX = "public";
// const TIMER_TEMPLATES_FILE_NAME = "timerTemplates.json";

export function promoteChecklist(keyPrefix, srcChecklistId) {
	refreshCredentials().then(() => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${srcChecklistId}.json` }, (_err2, data) => {
			if (_err2) {
				alert(_err2, _err2.stack);
			} else {
				const checklist = JSON.parse(data.Body.toString());
				addChecklistForPromote(checklist);
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Add failed", err);
	});
}

function addChecklistForPromote(checklist) {
	const baseId = checklist.id;
	checklist.id = generateUUID();
	if (checklist.hasOwnProperty("baseIds")) {
		checklist.baseIds.push(baseId);
	} else {
		const baseIds = [];
		baseIds.push(baseId);
		checklist.baseIds = baseIds;
	}
	// Need to think through all scenarios...for now allow people to delete anything
	checklist.cloned = false;

	const newDocument = {
		id: checklist.id,
		name: checklist.name,
		description: checklist.description,
		cloned: false,
		genre: checklist.genre,
		tags: checklist.tags,
		contributors: checklist.hasOwnProperty("contributors") ? checklist.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
		publisher: checklist.publisher,
		version: "1.0",
		revision: 1,
		revisionTs: new Date().getTime(),
		visible: true,
		speedType: "KIAS",
	};

	updateChecklistsAndPushToS3(checklist, newDocument);

	showSuccess("Added", "This activity has been successfuly added to My Activities.");
}

export function getHistory(tree, identityId) {
	refreshCredentials().then(async () => {

		const checklistHistory = [];

		const keyPrefix = getKeyPrefix(identityId);

		const s3 = new AWS.S3({ useDualStack: true });

		const params = {
			Bucket: env.s3.contentBucket,
			Delimiter: "/",
			Prefix: `${keyPrefix}/`,
		};

		const listFoldersData = await s3.listObjects(params).promise();

		const folders = listFoldersData.CommonPrefixes;
		for (let i = 0; i < folders.length; i++) {
			const folder = folders[i];
			// Ignore assets folders
			if (!folder.Prefix.includes("/assets/") && !folder.Prefix.includes("/userAssets/")) {
				params.Prefix = folder.Prefix;

				const checklistId = folder.Prefix.split("/")[1];
				// console.log("Checklist ID: " + checklistId);

				const checklistHistoryItem = {
					type: "checklistHistory",
					id: checklistId,
					name: "",
					description: "",
					instances: [],
				};

				checklistHistory.push(checklistHistoryItem);

				const filesData = await s3.listObjects(params).promise();

				// console.log(filesData);
				const files = filesData.Contents;
				for (let j = 0; j < files.length; j++) {
					const file = files[j].Key;

					// Need to get checklist instance using async await
					// console.log("File: " + file);
					const data = await s3.getObject({ Bucket: env.s3.contentBucket, Key: file }).promise();

					const checklistInstance = JSON.parse(data.Body.toString());
					checklistHistoryItem.name = checklistInstance.name;
					checklistHistoryItem.description = checklistInstance.description;
					checklistHistoryItem.instances.push(checklistInstance);
				}

			}
		}

		tree.set(["checklistHistory"], checklistHistory);
	}).catch((err) => {
		console.error(err);
		showError2("Get History", err);
	});
}

// export function updateMp3Metadata(prefix) {
// 	return new Promise((resolve, reject) => {
// 		refreshCredentials().then(() => {
// 			recursiveListObjectsUpdateMp3Metadata(env.s3.assetsBucket, prefix, (err, data) => {
// 				if (err) {
// 					reject(err);
// 				} else {
// 					data.forEach(async (fileObj) => {
// 						console.log("!@#$% MP3 METADATA", fileObj.Key);
// 						const s3 = new AWS.S3({ useDualStack: true });
// 						await s3.copyObject({ MetadataDirective: "REPLACE", CopySource: `${env.s3.assetsBucket}/` + fileObj.Key, Bucket: env.s3.contentBucket, Key: fileObj.Key, ContentType: 'audio/mpeg' }).promise();
// 					})

// 					resolve(data);
// 				}
// 			});
// 		}).catch((err) => {
// 			reject(err);
// 		});
// 	});
// }

// function recursiveListObjectsUpdateMp3Metadata(bucketName, prefix = null, cb = () => { }, marker = null, files = []) {
// 	const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
// 	const params = {
// 		Bucket: bucketName
// 	};

// 	// are we paging from a specific point?
// 	if (marker) {
// 		params.Marker = marker;
// 	}

// 	if (prefix) {
// 		params.Prefix = prefix;
// 	}

// 	bucket.listObjects(params, function (err, data) {
// 		if (err) {
// 			return cb(err);
// 		}

// 		// concat the list of files into our collection
// 		files = files.concat(data.Contents);

// 		// are we paging?
// 		if (data.IsTruncated) {
// 			let length = data.Contents.length;
// 			let marker = data.Contents[length - 1].Key;
// 			// recursion!
// 			recursiveListObjectsUpdateMp3Metadata(bucketName, prefix, cb, marker, files);
// 		} else {
// 			cb(undefined, files);
// 		}
// 	});
// }

// export function updateMp3Metadata2(prefix) {
// 	refreshCredentials().then(async ({ cognitoUser }) => {
// 		let s3 = new AWS.S3({ useDualStack: true });

// 		let params = {
// 			Bucket: env.s3.assetsBucket,
// 			Delimiter: "/",
// 			Prefix: prefix
// 		};

// 		let listFoldersData = await s3.listObjects(params).promise();

// 		let folders = listFoldersData.CommonPrefixes;
// 		for (let i = 0; i < folders.length; i++) {
// 			let folder = folders[i];

// 			params.Prefix = folder.Prefix;

// 			let filesData = await s3.listObjects(params).promise();

// 			let files = filesData.Contents;
// 			for (let j = 0; j < files.length; j++) {
// 				let file = files[j].Key;

// 				if (file.toLowerCase().endsWith(".mp3")) {
// 					console.log("!@#$% COPY", `${env.s3.assetsBucket}/` + file);
// 					// await s3.copyObject({ MetadataDirective: "REPLACE", CopySource: `${env.s3.assetsBucket}/` + file, Bucket: env.s3.contentBucket, Key: file, ContentType: 'audio/mpeg' }).promise();
// 				}
// 			}
// 		}
// 	}).catch((err) => {
// 		console.error(err);
// 		showError2("History", err);
// 	});
// }

export function touchHistoryFiles(identityId) {
	refreshCredentials().then(async ({ cognitoUser }) => {
		let keyPrefix = getKeyPrefix(identityId);

		let s3 = new AWS.S3({ useDualStack: true });

		let params = {
			Bucket: env.s3.contentBucket,
			Delimiter: "/",
			Prefix: keyPrefix + "/"
		};

		let listFoldersData = await s3.listObjects(params).promise();

		let folders = listFoldersData.CommonPrefixes;
		for (let i = 0; i < folders.length; i++) {
			let folder = folders[i];
			// Ignore assets folders
			if (!folder.Prefix.includes("/assets/") && !folder.Prefix.includes("/userAssets/")) {
				params.Prefix = folder.Prefix;

				let filesData = await s3.listObjects(params).promise();

				let files = filesData.Contents;
				for (let j = 0; j < files.length; j++) {
					let file = files[j].Key;

					// Need to get checklist instance using async await
					console.log("File: " + file);

					await s3.copyObject({ MetadataDirective: "REPLACE", CopySource: `${env.s3.contentBucket}/` + file, Bucket: env.s3.contentBucket, Key: file }).promise();
				}

			}
		}

		updateAttribute(cognitoUser, "custom:historyUpdated", "true");
	}).catch((err) => {
		console.error(err);
		showError2("History", err);
	});
}

export function uploadFileToS3(bucket, keyPrefix, file, emptyBody = false, ignoreFileName = false, overrides = {}) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			// Instantiate aws sdk service objects now that the credentials have been updated.
			let fileName = (overrides.fileName) ? overrides.fileName : (file && file.name) ? file.name : "";

			if (overrides.extension) {
				let ext = utils.getFileNameExtension(fileName);
				fileName = fileName.replace(`.${ext}`, `.${overrides.extension}`);
			}

			const _bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucket } });
			const params = { Key: `${keyPrefix}${file && fileName && !ignoreFileName ? `/${fixFileNameForS3(fileName)}` : ""}` };
			if (!emptyBody) {
				params.ContentType = file.type;
				params.Body = file;
			} else {
				// params.Body = "Empty";
				params.ContentLength = 0;
				params.Body = "";
			}
			_bucket.upload(params, (err) => {
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			}).on("httpUploadProgress", function (evt) {
				state.set(["drive", "showSpinner", "progressPercent"], parseInt((evt.loaded * 100) / evt.total));
				state.set(["drive", "showSpinner", "progressMessage"], fixFileNameForS3(fileName));
			});
		}).catch((err) => {
			console.error(err);
			showError2("Failed to obtain session to upload file to S3", err);
			reject(err);
		});
	});

}


export function uploadFilesToS3(bucketName, files) {
	refreshCredentials().then(() => {

		const keyPrefix = `${getKeyPrefix(state.select(["user", "identityId"]).get())}/assets`;

		// Instantiate aws sdk service objects now that the credentials have been updated.
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file) {
				// get file contents in memory
				const reader = new FileReader();
				reader.readAsText(file, "UTF-8");
				reader.onload = (evt) => {
					const csvContent = evt.target.result;

					const event = {
						csvContent,
						name: file.name.split(".")[0],
						checklistId: generateUUID(),
					};

					console.log(csvContent);
					// invoke lambda to do import
					const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

					const params = {
						FunctionName: env.lambda.importCsvFunction,
						InvocationType: "RequestResponse",
						LogType: "None",
						Payload: JSON.stringify(event),
					};

					lambda.invoke(params, (error, data) => {
						if (error) {
							alert(error);
						} else {
							console.log(data.Payload);
							const checklist = JSON.parse(data.Payload);

							checklist.contributors = [{ name: state.get("user", "name"), username: state.get("user", "username"), nickname: state.get("user", "nickname") }];

							// Upload new file
							importChecklistFromCsv(checklist);
						}
					});
				};
				reader.onerror = () => {
					alert("Error reading file");
				};

				const ts = Date.now();

				// Upload to S3 and S3 triggers conversion
				const params = { Key: `${keyPrefix}/${ts}-${file.name}`, ContentType: file.type, Body: file };
				bucket.upload(params, (err) => {
					// Successfully have file on S3
					const result = err ? "ERROR!" : "SAVED.";
					console.log(result);
				});
			}
		}

	}).catch((err) => {
		console.error(err);
		showError2("Upload file to S3 failed", err);
	});
}


export function uploadFilesFromContentReviewToS3(bucketName, files, srcFormat) {
	refreshCredentials().then(() => {
		state.set(["import", "showSpinner"], true);

		const identityPrefix = getKeyPrefix(state.get(["user", "identityId"]));
		const keyPrefix = `${getKeyPrefix(state.select(["user", "identityId"]).get())}/assets`;

		// Instantiate aws sdk service objects now that the credentials have been updated.
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });

		const ts = Date.now();
		const file = files[0];

		if (file && file.name) {
			const params = { Key: `${keyPrefix}/${ts}-${file.name}`, ContentType: file.type, Body: file };
			bucket.upload(params, (err) => {
				// Successfully have file on S3
				if (err) {
					state.set(["import", "showSpinner"], false);

					showError2("Upload file to S3 failed", err);
				} else {
					const event = {
						identityPrefix: identityPrefix,
						importFilePath: `${keyPrefix}/${ts}-${file.name}`,
						srcFormat: srcFormat,
						destFormat: "json"
					};

					const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

					const params = {
						FunctionName: env.lambda.importFunction,
						InvocationType: "RequestResponse",
						LogType: "None",
						Payload: JSON.stringify(event),
					};

					lambda.invoke(params, (error) => {
						state.set(["import", "showSpinner"], false);

						if (error) {
							alert(error);
						} else {

							// console.log(data.Payload);

							// const convertedJson = JSON.parse(data.Payload);

							alert("The content has been successfully imported.");
							// alert(JSON.stringify(convertedJson, null, 3));
						}
					});
				}
			});
		} else {
			state.set(["import", "showSpinner"], false);

			showError2("There was an unknown error uploading a file to S3");
		}
	});
}


export function uploadFilesFromOrgImportToS3(bucketName, files, method) {
	refreshCredentials().then(() => {
		state.set(["import", "showSpinner"], true);

		const keyPrefix = `${getKeyPrefix(state.select(["user", "identityId"]).get())}/assets`;

		// Instantiate aws sdk service objects now that the credentials have been updated.
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });

		const ts = Date.now();
		const file = files[0];

		if (file && file.name) {
			const params = { Key: `${keyPrefix}/${ts}-${file.name}`, ContentType: file.type, Body: file };
			bucket.upload(params, (err) => {
				// Successfully have file on S3
				if (err) {
					state.set(["import", "showSpinner"], false);

					showError2("Upload file to S3 failed", err);
				} else {
					const event = {
						method: method,
						orgId: getOrgId(),
						importFilePath: `${keyPrefix}/${ts}-${file.name}`
					};

					const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

					const params = {
						FunctionName: env.lambda.importFunctionForOrg,
						InvocationType: "RequestResponse",
						LogType: "None",
						Payload: JSON.stringify(event),
					};

					lambda.invoke(params, (error) => {
						state.set(["import", "showSpinner"], false);

						if (error) {
							alert(error);
						} else {

							// console.log(data.Payload);

							// const convertedJson = JSON.parse(data.Payload);

							alert("The content has been successfully imported.");
							// alert(JSON.stringify(convertedJson, null, 3));
						}
					});
				}
			});
		} else {
			state.set(["import", "showSpinner"], false);

			showError2("There was an unknown error uploading a file to S3");
		}
	});
}

const waitForTries = {};

export function waitForKeyExists(Bucket, Key) {
	return new Promise(async (resolve, reject) => {
		if (!waitForTries[Key]) {
			waitForTries[Key] = 0;
		}

		let result = null;
		let err = null;

		while (waitForTries[Key] <= 6 && result === null) {
			err = null;
			waitForTries[Key]++;
			console.log("Upload polling try", waitForTries[Key]);
			try {
				result = await doWaitForExists(Bucket, Key);
			} catch (_err) {
				console.warn(_err);
				err = _err;
			}
		}
		delete waitForTries[Key];
		if (!result) {
			reject(err);
		} else {
			resolve(result);
		}
	});
}

function doWaitForExists(Bucket, Key) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const s3 = new AWS.S3({ useDualStack: true });
			let params = {
				Bucket,
				Key
			};
			s3.waitFor("objectExists", params, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	});
}

export function uploadMediaFileToS3(bucketName, file, fileName, type) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const keyPrefix = type;

			// Instantiate aws sdk service objects now that the credentials have been updated.
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });

			// var ts = Date.now();

			// Upload to S3 and S3 triggers conversion
			const params = { Key: `${keyPrefix}/${fileName}`, ContentType: file.type, Body: file };
			bucket.upload(params, (err) => {
				// Successfully have file on S3
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			});

		}).catch((err) => {
			reject(err);
		});
	});
}

export function reportError(error, errorInfo) {

	state.set(["appState", "errorSpinner", "show"], true);
	setTimeout(async () => {
		const s3 = new AWS.S3({ useDualStack: true });

		const _errorInfo = {
			identityId: getIdentityId(),
			orgId: getOrgId(),
			url: window.location,
			error,
			errorInfo,
			browser: navigator.userAgent,
			cloudAppVersion: state.get(["version"])
		};


		await s3.putObject({
			Bucket: env.s3.errorReportsBucket, Key: "webApp/" + generateUUID() + ".json", Body: JSON.stringify(_errorInfo), ContentType: "application/json"
		}).promise();

		state.set(["appState", "errorSpinner", "show"], false);
	});


}

export function logToCloudWatch(logData) {
	refreshCredentials().then(() => {
		const event = {
			logData,
		};

		// invoke lambda to do import
		const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

		const params = {
			FunctionName: "logToCloudWatch",
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: JSON.stringify(event),
		};

		lambda.invoke(params, (error, data) => {
			if (error) {
				alert(error);
			} else {
				alert(data.Payload);
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("logToCloudWatch", err);
	});
}

// Getting image data directly from S3
// Our new approach uses Cloudfront to get the image
export function showImage() {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: "assets.pricer.miralouaero.com" } });

		bucket.getObject({ Key: `${keyPrefix}/DSCN0008.JPG` }, (err) => {
			if (err) {
				console.log(err, err.stack);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Error in show image", err);
	});
}

// TODO: Complete one-off...can reuse function above if make async/await
export function previewJsonFromS3(tree, bucketName, fileName, scope, hit) {
	return new Promise((resolve, reject) => {
		console.log("PREVIEW activity", scope, bucketName, fileName);
		refreshCredentials().then(async () => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });

			const Key = scope === "learning" ? `${env.s3.ambiFiLearningKey}/${fileName}` : scope === "org" ? `${getOrgId()}/${PUBLIC_KEY_PREFIX}/${fileName}` : `${PUBLIC_KEY_PREFIX}/${fileName}`;

			bucket.getObject({ Key }, async (err, data) => {
				if (err) {
					console.error(err);
					showError("Unable to get activity", err);
					reject(err);
				} else {
					// Set previewChecklist
					console.log("SETTING CHECKLIST FOR PREVIEW");

					let jsonChecklist = JSON.parse(data.Body.toString());
					// Should convert to make sure has at least one list and one section and set proper type
					console.log(jsonChecklist);

					jsonChecklist = normalizeActivity(jsonChecklist);

					tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, hit.id);
					tree.set(["appState", "previewChecklist", "checklist"], jsonChecklist);
					setTimeout(() => {
						tree.set(["appState", "previewChecklist", "showModal"], true);
						resolve();
					});
				}
			});

		}).catch((err) => {
			console.error(err);
			showError2("Preview failed", err);
			reject(err);
		});
	});

}

export function previewJsonForIdentityFromS3(tree, bucketName, identityId, fileName) {
	return new Promise(async (resolve, reject) => {
		try {
			await refreshCredentials();
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });

			const Key = `${identityId}/${fileName}`;

			bucket.getObject({ Key }, async (err, data) => {
				if (err) {
					showError("Activity Preview", "Unable to get activity for preview:" + err);
					reject(err);
				} else {
					// Set previewChecklist
					let activity = JSON.parse(data.Body.toString());

					// Get activity specific drive
					const activityDrive = {
						guidToResource: await getDriveForActivity(activity)
					}
					tree.set(["appState", "previewChecklist", "drive"], activityDrive);

					// Should convert to make sure has at least one list and one section and set proper type
					activity = normalizeActivity(activity);

					tree.set(["appState", "previewChecklist", "checklist"], activity);
					tree.set(["appState", "previewChecklist", "showModal"], true);
					resolve();
				}
			});
		} catch (e) {
			showError("Activity Preview", "Unable to get activity for preview: " + e);
			console.error("Preview failed", e);
			reject(e);
		}
	});
}

export function showNotes(tree, bucketName, fileName, sessionName) {
	refreshCredentials().then(() => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${fileName}` }, (err, data) => {
			if (err) {
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());
				// Should convert to make sure has at least one list and one section and set proper type
				jsonChecklist = normalizeActivity(jsonChecklist);

				tree.set(["appState", "showNotes", "checklist"], jsonChecklist);
				tree.set(["appState", "showNotes", "showModal"], true);
				tree.set(["appState", "showNotes", "sessionName"], sessionName);
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Preview failed", err);
	});
}



export async function setActivitySummary(tree, bucketName, fileName, showAll = false, jsonChecklist) {
	if (jsonChecklist) {
		// This allows showing attachments in summary
		tree.set(["appState", "showNotes", "checklist"], jsonChecklist);

		let jsonChecklistForSummary = _.cloneDeep(jsonChecklist);

		// Summarize
		if (!showAll) {
			utils.summarizeActivity(jsonChecklistForSummary);
		}
		tree.set(["appState", "activitySummary"], jsonChecklistForSummary);
	} else {
		return new Promise((resolve, reject) => {
			refreshCredentials().then(() => {
				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
				bucket.getObject({ Key: `${fileName}` }, (err, data) => {
					if (err) {
						showError("Unable to get checklist", err);
						reject(err);
					} else {
						// Set previewChecklist
						let jsonChecklist = JSON.parse(data.Body.toString());
						// Should convert to make sure has at least one list and one section and set proper type
						jsonChecklist = normalizeActivity(jsonChecklist);

						// This allows showing attachments in summary
						tree.set(["appState", "showNotes", "checklist"], jsonChecklist);

						let jsonChecklistForSummary = _.cloneDeep(jsonChecklist);

						// Summarize
						if (!showAll) {
							utils.summarizeActivity(jsonChecklistForSummary);
						}
						tree.set(["appState", "activitySummary"], jsonChecklistForSummary);

						// // Also set invoice items if have them on an activity
						// if (jsonChecklist.hasOwnProperty("invoiceItems")) {
						// 	tree.set(["appState", "activityInvoiceItems"], jsonChecklist.invoiceItems);
						// } else {
						// 	tree.set(["appState", "activityInvoiceItems"], []);
						// }
						resolve();
					}
				});
			}).catch((err) => {
				console.error(err);
				showError2("Preview failed", err);
				reject(err);
			});
		});
	}
}

export function showPricing(tree, bucketName, fileName) {
	showStatusSpinner();

	refreshCredentials().then(() => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${fileName}` }, async (err, data) => {
			if (err) {
				hideStatusSpinner();
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());
				// Should convert to make sure has at least one list and one section and set proper type
				jsonChecklist = normalizeActivity(jsonChecklist);

				tree.set(["appState", "showPricing", "checklist"], jsonChecklist);
				tree.set(["appState", "showPricing", "selectedValue"], jsonChecklist.selectedValue ? jsonChecklist.selectedValue : "");
				tree.set(["appState", "showPricing", "invoiceItems"], jsonChecklist.invoiceItems ? jsonChecklist.invoiceItems : []);
				tree.set(["appState", "showPricing", "estimates"], jsonChecklist.estimates ? jsonChecklist.estimates : []);
				tree.set(["appState", "showPricing", "jobDetails"], jsonChecklist.jobDetails ? jsonChecklist.jobDetails : {
					"name": "",
					"description": "",
					"notes": "",
					"parts": ""
				});
				tree.set(["appState", "showPricing", "showModal"], true);
				tree.set(["appState", "showPricing", "url"], fileName);

				hideStatusSpinner();
			}
		});
	}).catch((err) => {
		hideStatusSpinner();

		console.error(err);
		showError2("Preview failed", err);
	});
}

export function savePricing(bucketName, fileName, data) {
	return new Promise((resolve, reject) => {
		try {
			refreshCredentials().then(() => {
				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });

				const body = JSON.stringify(data);

				bucket.putObject({ Key: `${fileName}`, Body: body, ContentType: "application/json" }, (err, data) => {
					if (err) {
						showError("Unable to put activity saving pricing", err);
						reject(err);
					} else {
						resolve(data);
					}
				});
			}).catch((err) => {
				console.error(err);
				showError2("Save pricing failed", err);
				reject(err);
			});
		} catch (err) {
			reject(err);
		}
	});
}

export function showWorkflowForMultiple(tree, bucketName) {
	showStatusSpinner();

	refreshCredentials().then(() => {
		// We could get the first instance document-- there could be a scenario where multiple are selected and they have different workflows-- we could may check this as trying to update each instance document

		const selectedStatusItems = state.get(["appState", "selectedStatusItems"]);

		hideStatusSpinner();

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${selectedStatusItems[0].url}` }, async (err, data) => {
			if (err) {
				hideStatusSpinner();
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());
				// Should convert to make sure has at least one list and one section and set proper type
				jsonChecklist = normalizeActivity(jsonChecklist);

				// Hardwire DS here to fix brothers issue? This is hardwired only to the Converge Custom Job Submission
				// If in the future he ever uses a different workflow file for this activity this needs to be removed
				if (getOrgId() === "332a9d47-6e36-11e8-a392-061714f61e3e" && jsonChecklist.id === "05382b26-9db0-45d3-b8d9-313912a26364" && !jsonChecklist.hasOwnProperty("workflowStatesDs")) {
					jsonChecklist.workflowStatesDs = [
						{
							"type": "resource/ref",
							"refId": "bf8725eb-aeac-47c6-84cc-a5702c34c513"
						}
					]
				} else if (getOrgId() === "332a9d47-6e36-11e8-a392-061714f61e3e" && jsonChecklist.id === "50d90f3d-21e4-462a-8a81-ef9656a5e4b5" && !jsonChecklist.hasOwnProperty("workflowStatesDs")) {
					jsonChecklist.workflowStatesDs = [
						{
							"type": "resource/ref",
							"refId": "8263302b-547c-4ae2-a205-3eda4b4c2e48"
						}
					]
				}

				// 01ac8372-65e0-4ee4-b071-8facb93c9e4d

				// If using a datasource then get the latest content instead of the cached content in the activity
				if (jsonChecklist.hasOwnProperty("workflowStatesDs") && _.isArray(jsonChecklist.workflowStatesDs) && jsonChecklist.workflowStatesDs.length > 0 && jsonChecklist.workflowStatesDs[0].hasOwnProperty("refId")) {
					try {
						console.log("GET CONTENT BY ID", jsonChecklist.workflowStatesDs[0].refId);
						const externalResource = getExternalResource(jsonChecklist.workflowStatesDs[0].refId, jsonChecklist);
						console.log("EXTERNAL RESOURCE", externalResource);
						if (!externalResource) {
						} else {
							console.log("GET CONTENT WITH GUID", externalResource.guid);
							const newWorkflowData = await getContent(externalResource.guid);
							console.log("GOT CONTENT", newWorkflowData);
							jsonChecklist.workflowStates[0] = newWorkflowData;
						}

						// Ideally should re-save this back to S3 which would fix the cached data
					} catch (err) {
						// No op
						console.log("ERROR", err);
					}
				}

				tree.set(["appState", "showWorkflowForMultiple", "checklist"], jsonChecklist);
				tree.set(["appState", "showWorkflowForMultiple", "showModal"], true);

				hideStatusSpinner();
			}
		});
	}).catch((err) => {
		hideStatusSpinner();

		console.error(err);
		showError2("Preview failed", err);
	});

}

export function showWorkflow(tree, bucketName, fileName) {
	showStatusSpinner();

	refreshCredentials().then(() => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${fileName}` }, async (err, data) => {
			if (err) {
				hideStatusSpinner();
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());
				// Should convert to make sure has at least one list and one section and set proper type
				jsonChecklist = normalizeActivity(jsonChecklist);

				// Hardwire DS here to fix brothers issue? This is hardwired only to the Converge Custom Job Submission
				// If in the future he ever uses a different workflow file for this activity this needs to be removed
				if (getOrgId() === "332a9d47-6e36-11e8-a392-061714f61e3e" && jsonChecklist.id === "05382b26-9db0-45d3-b8d9-313912a26364" && !jsonChecklist.hasOwnProperty("workflowStatesDs")) {
					jsonChecklist.workflowStatesDs = [
						{
							"type": "resource/ref",
							"refId": "bf8725eb-aeac-47c6-84cc-a5702c34c513"
						}
					]
				} else if (getOrgId() === "332a9d47-6e36-11e8-a392-061714f61e3e" && jsonChecklist.id === "50d90f3d-21e4-462a-8a81-ef9656a5e4b5" && !jsonChecklist.hasOwnProperty("workflowStatesDs")) {
					jsonChecklist.workflowStatesDs = [
						{
							"type": "resource/ref",
							"refId": "8263302b-547c-4ae2-a205-3eda4b4c2e48"
						}
					]
				}

				// 01ac8372-65e0-4ee4-b071-8facb93c9e4d

				// If using a datasource then get the latest content instead of the cached content in the activity
				if (jsonChecklist.hasOwnProperty("workflowStatesDs") && _.isArray(jsonChecklist.workflowStatesDs) && jsonChecklist.workflowStatesDs.length > 0 && jsonChecklist.workflowStatesDs[0].hasOwnProperty("refId")) {
					try {
						console.log("GET CONTENT BY ID", jsonChecklist.workflowStatesDs[0].refId);
						const externalResource = getExternalResource(jsonChecklist.workflowStatesDs[0].refId, jsonChecklist);
						console.log("EXTERNAL RESOURCE", externalResource);
						if (!externalResource) {
						} else {
							console.log("GET CONTENT WITH GUID", externalResource.guid);
							const newWorkflowData = await getContent(externalResource.guid);
							console.log("GOT CONTENT", newWorkflowData);
							jsonChecklist.workflowStates[0] = newWorkflowData;
						}

						// Ideally should re-save this back to S3 which would fix the cached data
					} catch (err) {
						// No op
						console.log("ERROR", err);
					}
				}

				tree.set(["appState", "showWorkflow", "checklist"], jsonChecklist);
				tree.set(["appState", "showWorkflow", "showModal"], true);
				tree.set(["appState", "showWorkflow", "url"], fileName);

				hideStatusSpinner();
			}
		});
	}).catch((err) => {
		hideStatusSpinner();

		console.error(err);
		showError2("Preview failed", err);
	});
}


// Specific use case
// Save to s3
// Add in algolia index
// When finished can refresh search
export function saveWorkflow(bucketName, orgId, identityId, fileName, data) {
	return new Promise((resolve, reject) => {
		try {
			refreshCredentials().then(() => {
				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });

				const body = JSON.stringify(data);

				const event = {
					jsonChecklist: JSON.stringify(data),
					key: fileName,
					orgId: orgId,
					identityId: identityId,
					activityId: data.id,
					instanceId: data.instanceId,
					putObject: false
				};

				bucket.putObject({ Key: `${fileName}`, Body: body, ContentType: "application/json" }, (err, data) => {
					if (err) {
						showError("Unable to put activity saving workflow", err);
						reject(err);
					} else {
						resolve(data);

						// Was running indexing twice and causing major issues when ran the lambda

						// const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

						// const params = {
						// 	FunctionName: env.lambda.searchIndexFunction,
						// 	InvocationType: "RequestResponse",
						// 	LogType: "None",
						// 	Payload: JSON.stringify(event),
						// };

						// lambda.invoke(params, (error) => {
						// 	if (error) {
						// 		alert(error + "; In Invoke.");
						// 		reject(error);
						// 	} else {
						// 		// The instance has been indexed
						// 		resolve(data);
						// 	}
						// });
					}
				});
			}).catch((err) => {
				console.error(err);
				showError2("Save workflow failed", err);
				reject(err);
			});
		} catch (err) {
			reject(err);
		}
	});
}

export function showDetailedStatus(tree, bucketName, fileName, allowEdit = true) {
	refreshCredentials().then(() => {
		loadActivityInstanceFromS3(bucketName, fileName).then((result) => {
			tree.set(PlayerPaths.CHECKLIST, result);
			// tree.set(PlayerPaths.SELECTED_ACTIVITY_IDENTITY_ID, fileName.substring(0, fileName.indexOf("/")));
			tree.set(["appState", "showDetailedStatus", "showModal"], true);
			tree.set(["appState", "showDetailedStatus", "edit"], false);
			tree.set(["appState", "showDetailedStatus", "allowEdit"], allowEdit);
			tree.set(["appState", "status", "showSpinner"], false);
		}).catch((err) => {
			console.error(err);
			tree.set(["appState", "status", "showSpinner"], false);
			showError2("Load activity failed", err);
		});
	}).catch((err) => {
		console.error(err);
		tree.set(["appState", "status", "showSpinner"], false);
		showError2("Preview failed", err);
	});
}

export function showDetailedHistory(tree, bucketName, fileName) {
	refreshCredentials().then(() => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${fileName}` }, (err, data) => {
			if (err) {
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());
				// Should convert to make sure has at least one list and one section and set proper type
				jsonChecklist = normalizeActivity(jsonChecklist);

				if (!jsonChecklist.hasOwnProperty("history")) {
					alert("Thre currently is no history for this activity.");
				} else {
					tree.set(["appState", "showDetailedHistory", "checklist"], jsonChecklist);
					tree.set(["appState", "showDetailedHistory", "showModal"], true);
				}
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Preview failed", err);
	});
}

export function printInstancesForInvoice(tree, bucketName, template = "ambifi-activity-group-tasks", instances, docTypeExt, shippingTotal, totalValue) {
	refreshCredentials().then(async () => {
		console.log(instances);

		state.set(["appState", "status", "showSpinner"], true);

		const allInvoices = {};

		const newInstances = [];

		// Loop through instances and get full content for each instance
		for (let i = 0; i < instances.length; i++) {
			const instanceSearchData = instances[i];

			if (instanceSearchData.hasOwnProperty("invoiceItems")) {
				newInstances.push({
					"instanceId": instanceSearchData.instanceId,
					"sessionNameResolved": instanceSearchData.sessionNameResolved,
					"invoiceItems": instanceSearchData.invoiceItems
				});
			}
		}

		allInvoices.instances = newInstances;

		let priceListMap = state.get(["appState", "priceListMap"]);
		let customerMap = state.get(["appState", "customerMap"]);

		if (priceListMap === null) {
			// Need to add price list as well
			state.set(["appState", "documents", "showSpinner"], true);
			state.set(["appState", "status", "showSpinner"], true);
			state.set(["appState", "editor", "showSpinner"], true);

			const url = 'https://script.google.com/macros/s/AKfycbzxNBUz66mYRfb1GtCwXBLVCg4Fi3cLFwO-YpVP2CbueQuaZI3RmayvN1zi4bLAShYz/exec?sheetId=1ooKByEUqSD900Ph7xQP_lUIgISKrsgsPU7INipt27U8&sheetName=Price%20List';

			const response = await fetch(url);
			if (!response.ok) {
				const message = "An error has occured: " + response.status;
				alert(message);
			}

			const responseJson = await response.json();

			priceListMap = arrayToMap(responseJson.priceSheet, "id");

			state.set(["appState", "priceListMap"], priceListMap);
		}

		if (customerMap === null) {
			// Need to add price list as well
			state.set(["appState", "documents", "showSpinner"], true);
			state.set(["appState", "status", "showSpinner"], true);
			state.set(["appState", "editor", "showSpinner"], true);

			const urlCustomers = 'https://api.ambifi.com/artifacts/googleScriptResult?sheetId=1939X5GjBmYFgnK14tHxI2LzfapbqIceN_VYvM0Bvmdo';

			const responseCustomers = await fetch(urlCustomers, {
				method: 'GET',
				headers: {
					"Authorization": getJwtToken()
				}
			});
			if (!responseCustomers.ok) {
				const message = "An error has occured: " + responseCustomers.status;
				alert(message);
			}

			const responseCustomersJson = await responseCustomers.json();

			customerMap = arrayToMap(responseCustomersJson, "customerName");
		}

		allInvoices.priceListMap = priceListMap;

		// Pass customer object
		console.log("CUSTOMER", customerMap[instances[0].company]);
		allInvoices.customer = customerMap[instances[0].company];

		if (shippingTotal) {
			allInvoices.shippingTotal = shippingTotal;
		}

		if (totalValue) {
			allInvoices.totalValue = totalValue;
		}

		const displayNamesMap = utils.getDisplayNamesMap();
		allInvoices.displayNamesMap = displayNamesMap;

		console.log("arrInstances", { data: allInvoices }, JSON.stringify(allInvoices).length);

		let printFilename = template + "-" + new Date().toISOString();

		state.set(["appState", "status", "showSpinner"], false);

		printTemplate(printFilename, allInvoices, template, docTypeExt ? { "docTypeExt": docTypeExt } : {});
	}).catch((err) => {
		state.set(["appState", "documents", "showSpinner"], false);
		state.set(["appState", "status", "showSpinner"], false);
		state.set(["appState", "editor", "showSpinner"], false);

		console.error(err);
		showError2("Print Instances failed", err);
	});
}

export function printInstances(tree, bucketName, template = "ambifi-activity-group", instances, instanceSearchDataOnly = false) {
	refreshCredentials().then(async () => {
		console.log(instances);

		const s3 = new AWS.S3({ useDualStack: true });

		state.set(["appState", "status", "showSpinner"], true);

		const arrInstances = [];
		// Loop through instances and get full content for each instance
		for (let i = 0; i < instances.length; i++) {
			const instanceSearchData = instances[i];

			let instanceData = null;
			if (!instanceSearchDataOnly) {
				instanceData = await s3.getObject({ Bucket: env.s3.contentBucket, Key: instanceSearchData.url }).promise();

				instanceData = JSON.parse(instanceData.Body.toString())

				// instanceData = await publishActivityToSignedUrls(instanceData, instanceSearchData.url);
				// Should convert to make sure has at least one list and one section and set proper type
				instanceData = normalizeActivity(instanceData);
				instanceData = setFullSessionName(instanceData);

				recurseReplaceSpeeds(instanceData.children);
				// recurseConvertChecklistToMarkdown(instanceData.children, false);
			}

			if (instanceData) {
				arrInstances.push({
					instanceSearchData: instanceSearchData,
					instanceData: instanceData
				});
			} else {
				arrInstances.push({
					instanceSearchData: instanceSearchData
				});
			}
		}

		console.log("arrInstances", { data: arrInstances }, JSON.stringify(arrInstances).length);

		let printFilename = template + "-" + new Date().toISOString();

		state.set(["appState", "status", "showSpinner"], false);

		printTemplate(printFilename, { data: arrInstances }, template, { "docTypeExt": "xlsx" });
	}).catch((err) => {
		state.set(["appState", "status", "showSpinner"], false);

		console.error(err);
		showError2("Print Instances failed", err);
	});
}

export function printInstance(tree, bucketName, fileName, template = "ambifi-lists-form-remove-empty-values", hit, generateSignedUrls = true) {
	refreshCredentials().then(() => {
		state.set(["appState", "status", "showSpinner"], true);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${fileName}` }, async (err, data) => {
			if (err) {
				state.set(["appState", "status", "showSpinner"], false);
				showError("Unable to get checklist", err);
			} else {
				// Set previewChecklist
				let jsonChecklist = JSON.parse(data.Body.toString());

				if (template === "cc-label") {
					console.log("HIT", hit);

					const activitySummary = utils.summarizeActivityContent(jsonChecklist);

					const chatCompletionsTemplate = `{
    "messages": [{"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Can you summarize the following content as concise as possible in paragraph form and remove all html tagging? ${activitySummary.replace("\"", "'")}"}],
    "model": "gpt-4-turbo"
  }`;

					const chatResponse = await getChatCompletions(chatCompletionsTemplate);

					const chatCompletionsTemplate2 = `{
    "messages": [{"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": "Can you create a super brief description of not more than 15 words from the following? ${chatResponse}"}],
    "model": "gpt-4-turbo"
  }`;

					const chatResponse2 = await getChatCompletions(chatCompletionsTemplate2);

					jsonChecklist = {
						sessionName: hit.sessionNameResolved,
						instanceId: hit.instanceId,
						company: hit.company,
						displayName: hit.displayName,
						createdDateTimeIsoString: hit.createdDateTimeIsoString,
						lastUpdatedDateTimeIsoString: hit.lastUpdatedDateTimeIsoString,
						dueDateIsoString: hit.dueDateIsoString,
						thumbnailImage1: utils.getFirstMediaAttachment(hit.notes),
						thumbnailImage2: utils.getSecondMediaAttachment(hit.notes),
						tasks: hit.invoiceItems,
						briefDescription: chatResponse2,
						instructions: chatResponse,
						notes: "These are some notes about the job. And more notes. And more notes. And more notes."
					}
				} else {
					if (generateSignedUrls) {
						jsonChecklist = await publishActivityToSignedUrls(jsonChecklist, fileName);
					}
					// Should convert to make sure has at least one list and one section and set proper type
					jsonChecklist = normalizeActivity(jsonChecklist);
					jsonChecklist = setFullSessionName(jsonChecklist);

					recurseReplaceSpeeds(jsonChecklist.children);
					recurseConvertChecklistToMarkdown(jsonChecklist.children, false);
				}

				let printFilename = "";
				let lastUpdated = "";

				if (template === "cc-label") {
					lastUpdated = hit.sessionNameResolved;
					printFilename = hit.name + " - " + lastUpdated;
				} else {
					if (jsonChecklist.hasOwnProperty("history") && jsonChecklist.history.length > 0) {
						lastUpdated = jsonChecklist.history[jsonChecklist.history.length - 1].timestamp;
					}

					if (lastUpdated !== "") {
						printFilename = jsonChecklist.name + " - " + lastUpdated;

					} else {
						printFilename = jsonChecklist.name;
					}
				}


				state.set(["appState", "status", "showSpinner"], false);

				printTemplate(printFilename, jsonChecklist, template);
			}
		});
	}).catch((err) => {
		state.set(["appState", "status", "showSpinner"], false);

		console.error(err);
		showError2("Print Instance failed", err);
	});
}

// export function getAllChecklists(tree) {
// 	return refreshCredentials().then(({ cognitoUser }) => {
// 		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());

// 		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json" , ResponseCacheControl: "no-cache" } });

// 		return getChecklists(cognitoUser.getUsername(), keyPrefix, bucket, false).then(() => {
// 			hideLoader(tree);
// 		}).catch((err) => {
// 			actions.postMessage({ "method": "error", "message": `Error in call to getChecklists: ${err}` });
// 			hideLoader(tree);
// 			throw err;
// 		});

// 	}).catch((err) => {
// 		console.error(err);
// 		hideLoader(tree);
// 		showError2("Get checklists failed", err);

// 		actions.postMessage({ "method": "error", "message": `Error in getAllChecklists; ${err}` });
// 		throw err;
// 	});
// }


// Beginning of reassign avtivity.
export async function reassignActivity(activityUrl, dstKeyPrefix, displayName) {
	return new Promise((resolve, reject) => {
		try {
			showPlayerSpinner();

			const srcPrefix = activityUrl.replace(".json", "");
			const activityId = srcPrefix.split("/")[1];

			// Let's add some content to the activity for log of assignees
			refreshCredentials().then(() => {
				request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.artifacts.transferActivity(activityId, dstKeyPrefix)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.then(res => {
						// Make sure get back success

						hidePlayerSpinner();
						state.set(["appState", "reassignModal", "show"], false);

						showSuccessToast("Successfully reassigned the activity to " + displayName + ".");

						// showSuccess("Reassign", "Successfully reassigned the activity to " + displayName + ".");
						resolve();
					}).catch(err => {
						hidePlayerSpinner();
						state.set(["appState", "reassignModal", "show"], false);
						console.error(err);
						reject(err);
					});
			}).catch((err) => {
				hidePlayerSpinner();
				state.set(["appState", "reassignModal", "show"], false);
				console.error(err);
				showError("Failed to reassign the activity.", err);

				reject(err);
			});
		} catch (err) {
			hidePlayerSpinner();
			state.set(["appState", "reassignModal", "show"], false);
			console.error(err);
			showError("Failed to reassign the activity.", err);

			reject(err);
		}
	});
}

// export function reassignActivity(activityUrl, dstKeyPrefix, displayName) {
// 	return new Promise((resolve, reject) => {
// 		try {
// 			state.set(["appState", "status", "showSpinner"], true);

// 			const userIdentityId = state.get(["user", "identityId"]);

// 			// Let's add some content to the activity for log of assignees
// 			refreshCredentials().then(() => {
// 				state.set(["appState", "status", "showSpinner"], true);

// 				// Get source activity
// 				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
// 				bucket.getObject({ Key: `${activityUrl}` }, async (err, data) => {
// 					if (err) {
// 						state.set(["appState", "status", "showSpinner"], false);
// 						showError("Unable to get activity", err);

// 						reject(err);
// 					} else {
// 						let jsonActivity = JSON.parse(data.Body.toString());

// 						let newActivityUrl = activityUrl.substring(activityUrl.indexOf("/") + 1);
// 						newActivityUrl = dstKeyPrefix + "/" + newActivityUrl;

// 						// Upload to new destination
// 						const params = { Key: `${newActivityUrl}`, ContentType: "application/json", Body: JSON.stringify(jsonActivity) };

// 						console.log("COPY ACTIVITY", `${activityUrl}`, jsonActivity);

// 						bucket.upload(params, async (err) => {
// 							// Replace with notification
// 							if (!err) {
// 								state.set(["appState", "status", "showSpinner"], false);
// 								state.set(["appState", "reassignModal", "show"], false);

// 								showSuccess("Reassign", "Successfully reassigned the activity to " + displayName + ".<br/><br/>NOTE: Sometimes it can take a few seconds so you may need to press the Refresh button to see the result.");
// 								resolve();
// 							} else {
// 								state.set(["appState", "status", "showSpinner"], false);

// 								showError("Failed to reassign the activity.", err.message);

// 								reject(err);
// 							}
// 						});
// 					}
// 				});
// 			}).catch((err) => {
// 				state.set(["appState", "status", "showSpinner"], false);
// 				console.error(err);
// 				showError("Failed to reassign the activity.", err);

// 				reject(err);
// 			});
// 		} catch (err) {
// 			state.set(["appState", "status", "showSpinner"], false);
// 			console.error(err);
// 			showError("Failed to reassign the activity.", err);

// 			reject(err);
// 		}
// 	});
// }

export function reassignInstance(instanceUrl, dstKeyPrefix, displayName) {
	return new Promise((resolve, reject) => {
		try {
			state.set(["appState", "status", "showSpinner"], true);

			const userIdentityId = state.get(["user", "identityId"]);

			// Let's add some content to the activity for log of assignees
			refreshCredentials().then(() => {
				state.set(["appState", "status", "showSpinner"], true);

				// Get source activity
				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
				bucket.getObject({ Key: `${instanceUrl}` }, async (err, data) => {
					if (err) {
						state.set(["appState", "status", "showSpinner"], false);
						showError("Unable to get activity", err);

						reject(err);
					} else {
						let jsonInstance = JSON.parse(data.Body.toString());
						jsonInstance.displayName = displayName;

						let newInstanceUrl = instanceUrl.substring(instanceUrl.indexOf("/") + 1);
						newInstanceUrl = dstKeyPrefix + "/" + newInstanceUrl;

						// Create assigness array if doesn't exist, otherwise push
						if (!jsonInstance.hasOwnProperty("assignees")) {
							if (userIdentityId === dstKeyPrefix) {
								state.set(["appState", "status", "showSpinner"], false);
								showError("Reassign", "Sorry, it is already assigned to " + displayName + ".");
								resolve();
								return;
							}

							jsonInstance.assignees = [];
						}

						if (jsonInstance.assignees.length > 0 && jsonInstance.assignees[jsonInstance.assignees.length - 1].identityId === dstKeyPrefix) {
							state.set(["appState", "status", "showSpinner"], false);
							showError("Reassign", "Sorry, it is already assigned to " + displayName + ".");
							resolve();
							return;
						}

						jsonInstance.assignees.push({
							timestamp: getUtcTimestamp(),
							identityId: dstKeyPrefix,
							srcInstanceUrl: instanceUrl,
							dstInstanceUrl: newInstanceUrl
						});


						// Also need to move userAssets and fix references!!!
						let notes = [];
						utils.recurseExtractNotes(jsonInstance.children, notes, 0, 0);

						for (let i = 0; i < notes.length; i++) {
							let note = notes[i];
							if (note.hasOwnProperty("mediaAttachmentsUrls") && note.mediaAttachmentsUrls.length > 0) {
								for (let j = 0; j < note.mediaAttachmentsUrls.length; j++) {
									let media = note.mediaAttachmentsUrls[j];

									// copy object
									const srcUrl = media;
									const srcKey = srcUrl.replace("https://s3.amazonaws.com/content.ambifi.com/", "");

									const dstKey = dstKeyPrefix + "/" + srcKey.substring(srcKey.indexOf("/") + 1)

									console.log(srcKey, dstKey);
									await copyObject(env.s3.contentBucket, srcKey, env.s3.contentBucket, dstKey);

									// update
									jsonInstance.children[note.listIndex].children[note.sectionIndex].children[note.itemIndex].mediaAttachments[j].remoteUri = "https://s3.amazonaws.com/content.ambifi.com/" + dstKey;
									jsonInstance.children[note.listIndex].children[note.sectionIndex].children[note.itemIndex].mediaAttachmentsUrls[j] = "https://s3.amazonaws.com/content.ambifi.com/" + dstKey;
								}
							}

						}

						// Upload to new destination
						const params = { Key: `${newInstanceUrl}`, ContentType: "application/json", Body: JSON.stringify(jsonInstance) };

						console.log("COPY ACTIVITY", `${instanceUrl}`, jsonInstance);

						bucket.upload(params, async (err) => {
							// Replace with notification
							if (!err) {
								state.set(["appState", "status", "showSpinner"], false);
								state.set(["appState", "reassignModal", "show"], false);

								showSuccess("Reassign", "Successfully reassigned the activity to " + displayName + ".<br/><br/>NOTE: Sometimes it can take a few seconds so you may need to press the Refresh button to see the result.");
								resolve();
							} else {
								state.set(["appState", "status", "showSpinner"], false);

								showError("Failed to reassign the activity.", err.message);

								reject(err);
							}
						});
					}
				});
			}).catch((err) => {
				state.set(["appState", "status", "showSpinner"], false);
				console.error(err);
				showError("Failed to reassign the activity.", err);

				reject(err);
			});
		} catch (err) {
			state.set(["appState", "status", "showSpinner"], false);
			console.error(err);
			showError("Failed to reassign the activity.", err);

			reject(err);
		}
	});
}

export function saveJsonToS3WithKeyPrefix(object, bucketName, keyPrefix, fileName, message = "") {
	refreshCredentials().then(() => {
		// Instantiate aws sdk service objects now that the credentials have been updated.
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		const params = { Key: `${keyPrefix}/${fileName}`, ContentType: "application/json", Body: JSON.stringify(object) };
		bucket.upload(params, (err) => {
			const result = err ? "ERROR!" : "SAVED.";
			console.log(result);
			// Replace with notification
			if (!err) {
				if (message !== "") {
					showSuccess("Successfully saved", message);
				}
			} else {
				showError("Failed to save", err.message);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Save failed", err);
	});
}

export function saveHistory(bucketName, files, keyPrefixOverride = "", refreshChecklists = false) {
	let keyPrefix = keyPrefixOverride;
	if (keyPrefix === "") {
		keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());
	}

	return putBatch(bucketName, keyPrefix, files)
		.then(() => {
			// Send message to client that history has been saved so can set sync to true on all instances
			postMessage({ method: "saveHistory", result: "success", refreshChecklists: refreshChecklists });
		});
	// .catch((err) => {
	// 	//alert(err);
	// 	//actions.postMessage({ method: "saveHistory", result: err });
	// 	throw err;
	// });
}


// Need to send in batch instead of loop
export function saveJsonToS3ForHistory(object, bucketName, fileName) {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());

		// Instantiate aws sdk service objects now that the credentials have been updated.
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		const params = { Key: `${keyPrefix}/${fileName}`, ContentType: "application/json", Body: JSON.stringify(object) };
		console.log(`SAVE HISTORY: ${keyPrefix}/${fileName}`);
		bucket.upload(params, (err) => {
			const result = err ? "ERROR!" : "SAVED.";
			console.log(result);
			// Replace with notification
			if (err) {
				showError("History save failed", err.message);
			}
		});
	}).catch((err) => {
		console.error(err);
		showErrorNoDetail("Save history failed", err);
	});
}

export function saveJsonToS3(object, bucketName, fileName, message = "", getChecklists = true) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());

			// Instantiate aws sdk service objects now that the credentials have been updated.
			uploadObject(object, bucketName, keyPrefix).then(() => {
				// If getChecklists then refresh content on mobile device
				if (getChecklists) {
					sendInventoryAndUserInfoMessage().then(() => {
						if (message !== "") {
							showSuccess("Save successful", message);
							state.set(["appState", "editor", "showSpinner"], false);
						}
					}).catch(err => {
						showError("Failed to save", err.message);
						reject(err);
					});

					// receiveDocuments sets the state in the app
				} else {
					console.debug("In saveJsonToS3", object);
					initializeInventory(false).catch(err => {
						showError("Failed to save", err.message);
						reject(err);
					});
				}
			}).catch(err => {
				showError("Failed to save", err.message);
				reject(err);
			});
		}).catch((err) => {
			console.error(err);
			showError2("Save failed", err);
			reject(err);
		});
	});
}

export function getJsonFileFromS3(bucketName, fileName) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(async () => {
			const keyPrefix = getKeyPrefix(state.get(["user", "identityId"]));

			const s3 = new AWS.S3({ useDualStack: true });

			try {
				const data = await s3.getObject({ Bucket: bucketName, Key: keyPrefix + "/" + fileName }).promise();

				console.log("GET!: " + data.Body.toString());

				resolve(JSON.parse(data.Body.toString()));

			} catch (err) {
				reject("Error: " + err.message);
			}
		}).catch((err) => {
			console.error(err);
			showError2("Save failed", err);
			reject(err);
		});
	});
}

export function putJsonFileToS3(bucketName, fileName, data) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(async () => {
			const body = JSON.stringify(data);
			const keyPrefix = getKeyPrefix(state.get(["user", "identityId"]));
			const s3 = new AWS.S3({ useDualStack: true });

			try {
				await s3.putObject({ Bucket: bucketName, Key: keyPrefix + "/" + fileName, Body: body, ContentType: "application/json" }).promise();

				console.log("PUT!: " + data);
				resolve(data);
			} catch (err) {
				reject("Error: " + err.message);
			}
		}).catch((err) => {
			console.error(err);
			showError2("Save failed", err);
			reject(err);
		});
	});
}

export function deleteJsonFileFromS3WithKeyPrefix(bucketName, keyPrefix, fileName) {
	return deleteObject(bucketName, `${keyPrefix}/${fileName}`).catch((err) => {
		console.error(err);
		showError2("Delete failed", err);
	});
}

export function deleteJsonFileFromS3(bucketName, fileName) {
	const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());
	const key = `${keyPrefix}/${fileName}`;

	return deleteObject(bucketName, key).catch((err) => {
		console.error(err);
		showError2("Delete failed", err);
	});
}

export function saveJsonFilesToS3WithKeyPrefix(bucketName, files, message = "") {
	refreshCredentials().then(() => {
		putBatchWithKeyPrefix(bucketName, files)
			.then(() => {
				if (message !== "") {
					showSuccess("Save successful", message);
					state.set(["appState", "editor", "showSpinner"], false);
				}
			}).catch(err => {
				throw err;
			});
	}).catch((err) => {
		console.error(err);
		showError2("Save failed", err);
	});
}

// This also getAllChecklists
export function saveJsonFilesToS3(bucketName, files, message = "", getChecklists = true) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());
			putBatch(bucketName, keyPrefix, files)
				.then(() => {
					if (getChecklists) {
						sendInventoryAndUserInfoMessage().then(() => {
							if (message !== "") {
								showSuccessToast(message);
								state.set(["appState", "editor", "showSpinner"], false);
							}
							resolve();
						}).catch(err => {
							console.error(err);
							reject(err);
							showError("Failed to save", err.message);
						});
					} else {

						if (message !== "") {
							showSuccessToast(message);
							state.set(["appState", "editor", "showSpinner"], false);
						}
						resolve();
					}
				})
				.catch((err) => {
					reject(err);
					showError2("Save failed", err);
					console.log(err.message);
				});

		}).catch((err) => {
			reject(err);
			console.error(err);
			showError2("Save failed", err);
		});
	});

}

const putBatch = function putBatch(bucketName, keyPrefix, files) {
	return Promise.all(files.map((file) => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		const body = JSON.stringify(file.fileContent);
		console.log("PUT INVENTORY2", files);
		return bucket.putObject({ Key: `${file.prefix ? file.prefix : keyPrefix}/${file.fileName}`, ContentType: "application/json", Body: body }).promise();
	}));
};

const putBatchWithKeyPrefix = function putBatchWithKeyPrefix(bucketName, files) {
	return Promise.all(files.map((file) => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		const body = JSON.stringify(file.fileContent);
		console.log("PUT INVENTORY3", files);
		return bucket.putObject({ Key: `${file.keyPrefix}/${file.fileName}`, ContentType: "application/json", Body: body }).promise();
	}));
};


export async function updateChecklistsAndPushToS3(checklist, newDocument = null, { message = "", ignoreContributors = false, lock = true }) {
	// Guarantee we have an identityId
	// Check metadata - if identityId is the same as logged in user then don't inject
	const activityInfo = getActivityById(checklist.id);

	if (activityInfo && activityInfo.hasOwnProperty("identityId")) {
		checklist.identityId = activityInfo.identityId;
	}

	if (activityInfo && activityInfo.hasOwnProperty("sharedWithType") && (activityInfo.sharedWithType === "member" || activityInfo.sharedWithType === "group") && checklist.identityId === getIdentityId()) {
		showError2("Save Activity", "Unable to save the activity because this activity was shared with you but is missing the proper identity id.");
		return;
	}

	const isThirdPartyAuthor = (checklist.hasOwnProperty("identityId") && checklist.identityId !== "" && checklist.identityId !== getIdentityId() && (isOrganizationAdministrator() || isOrganizationAuthor()));
	if (!checklist.hasOwnProperty("identityId") || checklist.identityId === "" || !checklist.identityId || checklist.identityId === getIdentityId() || isThirdPartyAuthor) {
		const documents = state.get(["activities"]);
		let inventoryEntry = {};

		const name = state.get(["user", "name"]);
		const username = state.get(["user", "username"]);
		const nickname = state.get(["user", "nickname"]);

		if (!ignoreContributors) {
			if (newDocument === null) {
				if (!checklist.hasOwnProperty("contributors")) {
					const contributors = [];
					contributors.push({ name, username, nickname });
					checklist.contributors = contributors;
				} else if (checklist.contributors.length === 0) {
					checklist.contributors.push({ name, username, nickname });
				} else if (checklist.contributors[checklist.contributors.length - 1].username !== username) {
					checklist.contributors.push({ name, username, nickname });
				}
			}
		}

		const newDocuments = [];

		for (const document of documents) { // eslint-disable-line no-unused-vars
			if (!document.hasOwnProperty("category") || document.category !== "learning") {
				if (!document.hasOwnProperty("newDoc") || !document.newDoc) {
					if (checklist.id === document.id) {
						document.name = checklist.name;
						document.description = checklist.description;
						document.cloned = checklist.cloned;
						document.publisher = checklist.publisher;
						document.genre = checklist.genre;
						document.tags = checklist.tags ? checklist.tags : [];
						document.contributors = checklist.contributors;
						document.heroImage = checklist.heroImage;
						document.checklistId = checklist.checklistId;
						document.driveRootPath = checklist.driveRootPath;
						document.category = checklist.category ? checklist.category : "";
						if (checklist.owner) {
							document.owner = checklist.owner;
						}
						// If trackData was set on inventory entry but not in the document yet, then adjust the document content to what was set in the inventory entry
						if (!checklist.hasOwnProperty("trackData") && document.hasOwnProperty("trackData")) {
							checklist.trackData = document.trackData;
						} else {
							document.trackData = checklist.trackData;
						}

						document.revision = checklist.hasOwnProperty("revision") ? checklist.revision : 1;
						document.revisionTs = checklist.hasOwnProperty("revisionTs") ? checklist.revisionTs : new Date().getTime();
						document.externalResources = checklist.hasOwnProperty("externalResources") ? checklist.externalResources : [];
						inventoryEntry = document;
					}

					// experiences.json		
					newDocuments.push(document);
				} else if (document.hasOwnProperty("newDoc") && document.newDoc && checklist.id === document.id) {
					document.newDoc = false;
					document.name = checklist.name;
					document.description = checklist.description;
					document.cloned = checklist.cloned;
					document.publisher = checklist.publisher;
					document.genre = checklist.genre;
					document.tags = checklist.tags ? checklist.tags : [];
					document.contributors = checklist.contributors;
					document.heroImage = checklist.heroImage;
					document.checklistId = checklist.checklistId;
					document.driveRootPath = checklist.driveRootPath;
					document.trackData = checklist.trackData;
					document.category = checklist.category ? checklist.category : "";
					document.revision = checklist.hasOwnProperty("revision") ? checklist.revision : 1;
					document.revisionTs = checklist.hasOwnProperty("revisionTs") ? checklist.revisionTs : new Date().getTime();
					document.externalResources = checklist.hasOwnProperty("externalResources") ? checklist.externalResources : [];
					document.owner = true;

					checklist.identityId = getIdentityId();

					// experiences.json
					newDocuments.unshift(document);
				}
			}

		}
		const keyPrefix = checklist.identityId ? checklist.identityId : getIdentityId();
		// const keyPrefix = isThirdPartyAuthor ? checklist.identityId : getIdentityId();

		if (!isValidActivitySavePathPrefix(keyPrefix)) {
			throw new Error(`Save activity JSON failed, invalid save path prefix: ${keyPrefix}`);
		}

		let experiencesInventory = createExperiencesInventoryFromActivities(newDocuments);

		const files = isThirdPartyAuthor ? [
			{
				fileName: `${checklist.id}.json`,
				fileContent: checklist,
				prefix: keyPrefix
			},
		] : [
			{
				fileName: env.artifacts.inventoryFileName,
				fileContent: experiencesInventory
			},
			{
				fileName: `${checklist.id}.json`,
				fileContent: checklist,
				prefix: keyPrefix
			},
		];

		if (newDocument !== null) {
			newDocuments.unshift(newDocument);
		}
		console.log("S");

		return new Promise((resolve, reject) => {
			setActivities(newDocuments).then(() => {
				updateSharedDocument(_.assign({}, inventoryEntry, checklist), isThirdPartyAuthor).then(() => {
					saveJsonFilesToS3(env.s3.contentBucket, files, message, isMobile()).then(async () => {
						// Relock it ...
						if (state.exists(["newActivityWorkflowGuid"])) {
							const newActivityWorkflowGuid = state.get(["newActivityWorkflowGuid"]);
							if (newActivityWorkflowGuid) {
								try {
									await associateActivityWithWorkflow(checklist.id, newActivityWorkflowGuid);
									await reloadActivities();
								} catch (err) {
									showError("Associate with Workflow", "Unable to associate activity with selected workflow, please try adding it manually.", () => {
										state.set(["appState", "editor", "showSpinner"], false);
									});
									reject(err);
								}
								state.set(["newActivityWorkflowGuid"], null);
							}
						}
						lock && putLock(checklist.id, keyPrefix);
						resolve();
					});
				}).catch(err => {
					showError("Save Failed", "Unable to update shared activity meta data. Reason:" + err);
					state.set(["appState", "editor", "showSpinner"], false);
					reject(err);
				});
			}).catch(err => {
				showError("Save Failed", "Failed to update inventory of activities, reason:" + err);
				state.set(["appState", "editor", "showSpinner"], false);
				reject(err);
			});
		});

	} else {
		throw new Error("Unable to save activity, the current user doesn't have the appropriate permissions to perform a save.");
	}
}



export function importChecklistFromCsv(checklist) {
	const newDocument = {
		id: checklist.id,
		name: checklist.name,
		description: checklist.description,
		cloned: (checklist.publisher === "self" ? true : true),
		genre: checklist.genre,
		tags: checklist.tags,
		contributors: checklist.hasOwnProperty("contributors") ? checklist.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
		publisher: checklist.publisher,
		shareCode: "",
		shareCodeReceived: "",
		version: "1.0",
		revision: 1,
		revisionTs: new Date().getTime(),
		visible: true,
		speedType: "KIAS",
	};

	updateChecklistsAndPushToS3(checklist, newDocument, { message: `The activity ${checklist.name} was successfully imported.` });
}

export function importChecklist(checklist) {
	const newDocument = {
		id: checklist.id,
		name: checklist.name,
		description: checklist.description,
		cloned: (checklist.publisher === "self" ? true : true),
		genre: checklist.genre,
		tags: checklist.tags,
		contributors: checklist.hasOwnProperty("contributors") ? checklist.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
		publisher: checklist.publisher,
		shareCode: "",
		shareCodeReceived: "",
		version: "1.0",
		revision: 1,
		revisionTs: new Date().getTime(),
		visible: true,
		speedType: "KIAS",
	};

	updateChecklistsAndPushToS3(checklist, newDocument, { message: `The activity ${checklist.name} was successfully imported.` });
}

export function setDocumentPrivacy(activity, privacy) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), activity);

			const activityInfo = getActivityById(activity.id);

			getActivity(activity.id, activityInfo.identityId).then((content) => {
				let checklist = content;

				if (checklist.hasOwnProperty("identityId")) {
					delete checklist.identityId;
				}
				if (checklist.hasOwnProperty("share")) {
					delete checklist.share;
				}

				// If privacy = public then copy
				// If privacy = private then delete
				checklist.privacy = privacy;

				// Need to update documents collection
				activity.privacy = privacy;
				const isThirdPartyAuthor = (checklist.hasOwnProperty("identityId") && checklist.identityId !== "" && checklist.identityId !== getIdentityId() && (isOrganizationAdministrator() || isOrganizationAuthor()));
				updateSharedDocument(checklist, isThirdPartyAuthor).then(() => {
					updateActivity(activity);

					let documentsToSend = _.cloneDeep(state.get(["activities"]));
					documentsToSend.shift();
					documentsToSend = createExperiencesInventoryItemFromActivity(filterLearningActivities(documentsToSend));

					if (privacy === "public") {
						const files = [
							{
								keyPrefix,
								fileName: env.artifacts.inventoryFileName,
								fileContent: documentsToSend,
							},
							{
								keyPrefix: PUBLIC_KEY_PREFIX,
								fileName: `${checklist.id}.json`,
								fileContent: checklist,
							},
						];

						saveJsonFilesToS3WithKeyPrefix(env.s3.contentBucket, files, "");
					} else if (privacy === "private") {
						// Not consistent...above we do this in a batch
						deleteJsonFileFromS3WithKeyPrefix(env.s3.contentBucket, PUBLIC_KEY_PREFIX, `${activity.id}.json`);
						saveJsonToS3WithKeyPrefix(documentsToSend, env.s3.contentBucket, keyPrefix, env.artifacts.inventoryFileName, "");
					}
					resolve();
				}).catch(err => {
					console.error(err);
					reject(err);
				});
			});

		}).catch((err) => {
			console.error(err);
			showError2("Set privacy failed", err);
		});
	});

}

export function setDocumentOrgPrivacy(activity, privacy) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), activity);

			const activityInfo = getActivityById(activity.id);

			getActivity(activity.id, activityInfo.identityId).then((content) => {

				let checklist = content;

				if (checklist.hasOwnProperty("identityId")) {
					delete checklist.identityId;
				}
				if (checklist.hasOwnProperty("share")) {
					delete checklist.share;
				}

				// If privacy = public then copy
				// If privacy = private then delete
				checklist.orgPrivacy = privacy;

				// Need to update documents collection
				activity.orgPrivacy = privacy;
				const isThirdPartyAuthor = (checklist.hasOwnProperty("identityId") && checklist.identityId !== "" && checklist.identityId !== getIdentityId() && (isOrganizationAdministrator() || isOrganizationAuthor()));
				updateSharedDocument(checklist, isThirdPartyAuthor).then(() => {
					updateActivity(activity);

					let documentsToSend = _.cloneDeep(state.get(["activities"]));
					documentsToSend.shift();
					documentsToSend = createExperiencesInventoryFromActivities(documentsToSend);

					if (privacy === "public") {
						const files = [
							{
								keyPrefix,
								fileName: env.artifacts.inventoryFileName,
								fileContent: documentsToSend,
							},
							{
								keyPrefix: `${state.get(["selectedOrg", "id"])}/${PUBLIC_KEY_PREFIX}`,
								fileName: `${checklist.id}.json`,
								fileContent: checklist,
							},
						];

						saveJsonFilesToS3WithKeyPrefix(env.s3.contentBucket, files, "");
					} else if (privacy === "private") {
						// Not consistent...above we do this in a batch
						deleteJsonFileFromS3WithKeyPrefix(env.s3.contentBucket, `${state.get(["selectedOrg", "id"])}/${PUBLIC_KEY_PREFIX}`, `${activity.id}.json`);
						saveJsonToS3WithKeyPrefix(documentsToSend, env.s3.contentBucket, keyPrefix, env.artifacts.inventoryFileName, "");
					}
					resolve();
				}).catch(err => {
					console.error(err);
					reject(err);
				});
			});

		}).catch((err) => {
			console.error(err);
			reject(err);
		});
	});

}

export function toggleSpeedType(document) {
	const documents = state.get(["activities"]);

	const newDocuments = [];

	for (const doc of documents) { // eslint-disable-line no-unused-vars
		if (!doc.hasOwnProperty("newDoc") || !doc.newDoc) {
			if (doc.id === document.id) {
				if (doc.speedType.toLowerCase() === "mph") {
					doc.speedType = "KIAS";
				} else {
					doc.speedType = "MPH";
				}
			}
			newDocuments.push(doc);
		}
	}



	// TODO: Can get out of sync without promise
	saveJsonToS3(createExperiencesInventoryFromActivities(newDocuments), env.s3.contentBucket, env.artifacts.inventoryFileName).then(() => {
		setActivities(newDocuments);
	}).catch(err => {
		throw err;
	});
}

export async function getPrintTemplates() {
	let headers = new Headers();

	headers.append("Authorization", "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm");

	const responseTemplates = await fetch("https://miralouaero.jsreportonline.net/odata/templates?$select=name", {
		method: "GET",
		headers: headers
	});
	const jsonTemplates = await responseTemplates.json();
	const templates = jsonTemplates.value;

	return templates;
}

export async function getResolvedTemplate(templateId) {
	let headers = new Headers();

	headers.append("Authorization", "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm");

	const responseTemplate = await fetch("https://miralouaero.jsreportonline.net/odata/templates(" + templateId + ")", {
		method: "GET",
		headers: headers
	});
	const jsonTemplate = await responseTemplate.json();
	const template = jsonTemplate.value[0].content;

	const templateObject = {
		"content": template,
		"engine": "none",
		"recipe": "html"
	};

	const myData = { "template": templateObject, "data": JSON.stringify({}) };

	jsreport.serverUrl = "https://miralouaero.jsreportonline.net";

	//add custom headers to ajax calls
	jsreport.headers["Authorization"] = "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm";

	//render through AJAX request and return promise with array buffer response
	try {
		const resBuffer = await jsreport.renderAsync(myData);

		utils.hideLoader(state, "loadingSpinner");

		return resBuffer.toString();
	} catch (err) {
		utils.hideLoader(state, "loadingSpinner");

		alert("Error: " + err.toString());
	}
}

export function printTemplateWithContent(name, data, content, orientation) { //eslint-disable-line
	return new Promise((resolve, reject) => {
		state.set(["appState", "documents", "showSpinner"], true);

		// recurseDriveRefsToUrls(data.children, data.id, "", "");

		if (isMobile()) {
			postMessage({
				method: "showSpinner"
			});
		}

		const template = {
			"content": content,
			"engine": "handlebars",
			"recipe": "chrome-pdf",
			"electron": {
				"landscape": orientation === "landscape" ? true : false
			}
		};

		let newTemplate = template;

		if (data.hasOwnProperty("authorProperties") && data.authorProperties.hasOwnProperty("jsreportTemplate")) {
			newTemplate = Object.assign({}, template, data.authorProperties.jsreportTemplate);

			if (newTemplate.recipe !== "electron-pdf") {
				delete newTemplate.electron;
			}
		}

		const myData = { "template": newTemplate, "data": JSON.stringify(data) };

		jsreport.serverUrl = env.jsreports.url;

		// add custom headers to ajax calls
		jsreport.headers.Authorization = env.jsreports.auth;
		// jsreport.headers['Content-Type'] = "application/json";

		// render through AJAX request and return promise with array buffer response
		jsreport.renderAsync(myData).then((res) => {
			console.log("Results from jsreports: ", res);

			state.set(["appState", "documents", "showSpinner"], false);

			if (isMobile()) {
				postMessage({ method: "base64Blob", base64: res.toDataURI() });
				postMessage({
					method: "hideSpinner"
				});
			} else {
				postMessage({ method: "showGoBack" });
				res.download(name + ".pdf");
			}
			resolve();
		}, (reason) => {
			state.set(["appState", "documents", "showSpinner"], false);

			alert(`Error: ${reason.status}\n${reason.statusText}`);
			if (isMobile()) {
				postMessage({
					method: "hideSpinner"
				});
				postMessage({
					method: "showError",
					errorTitle: "Print Failed",
					errorText: `An error occurred while attempting to print the report: ${reason.statusText}`
				});
			}
			reject(reason);
			// rejection
		});
	});
}

export function printTemplate2(name, data, templateId, config = {}) { //eslint-disable-line
	return new Promise((resolve, reject) => {
		state.set(["appState", "documents", "showSpinner"], true);

		if (data.hasOwnProperty("children")) {
			recurseDriveRefsToUrls(data.children, data.id, "", "");
		}

		if (isMobile()) {
			postMessage({
				method: "showSpinner"
			});
		}

		const myData = { template: { name: templateId }, data: data };

		// This could be API Gateway - this seems more secure as we would not even have public endpoint for reporting
		// Call lambda
		const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

		const params = {
			// Put in env var
			FunctionName: "service-jsreport-lambda-proxy-v2-prod",
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: JSON.stringify({
				renderRequest: myData
			})
		};

		lambda.invoke(params, (error, data) => {
			if (error) {
				state.set(["appState", "documents", "showSpinner"], false);
				alert(`Error: ${error.statusCode} (${error.code}) ${error.message}`);
				if (isMobile()) {
					postMessage({
						method: "hideSpinner"
					});
					postMessage({
						method: "showError",
						errorTitle: "Print Failed",
						errorText: `An error occurred while attempting to print the report: ${error.statusCode} (${error.code}) ${error.message}`
					});
				}
				reject(error);
			} else {
				const result = JSON.parse(data.Payload);

				if (result.hasOwnProperty("errorType")) {
					state.set(["appState", "documents", "showSpinner"], false);

					alert(`Error: ${result.errorMessage}`);
					if (isMobile()) {
						postMessage({
							method: "hideSpinner"
						});
						postMessage({
							method: "showError",
							errorTitle: "Print Failed",
							errorText: `An error occurred while attempting to print the report: ${result.errorMessage}`
						});
					}
					reject(error);
				} else {
					console.log("Results from jsreports: ", result.body);

					state.set(["appState", "documents", "showSpinner"], false);

					// Saw article recommending to use contentDisposition - no javascript and no data limits

					if (isMobile()) {
						postMessage({ method: "base64Blob", base64: "data:application/pdf;base64," + result.body });
						postMessage({
							method: "hideSpinner"
						});
					} else {
						postMessage({ method: "showGoBack" });

						// const blob = new Blob([result.body], { type: "application/pdf;charset=utf-8" });

						if (config.hasOwnProperty("docTypeExt")) {
							FileSaver.saveAs("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," + result.body, `${name}.${config.docTypeExt}`);
						} else {
							FileSaver.saveAs("data:application/pdf;base64," + result.body, `${name}.pdf`);
						}
					}
					resolve();
				}
			}
		});
	});
}

export function printTemplate(name, data, templateId, config = {}) { //eslint-disable-line
	return new Promise((resolve, reject) => {
		try {
			state.set(["appState", "documents", "showSpinner"], true);
			state.set(["appState", "status", "showSpinner"], true);

			//this is a hack, need to handle spinner outside this method since it is used in multiple places
			state.set(["appState", "editor", "showSpinner"], true);
			if (data.hasOwnProperty("children")) {
				recurseDriveRefsToUrls(data.children, data.id, "", "");
			}

			if (isMobile()) {
				postMessage({
					method: "showSpinner"
				});
			}

			const myData = { template: { name: templateId }, data: JSON.stringify(data) };

			jsreport.serverUrl = env.jsreports.url;

			// add custom headers to ajax calls
			jsreport.headers.Authorization = env.jsreports.auth;
			// jsreport.headers['Content-Type'] = "application/json";

			// render through AJAX request and return promise with array buffer response
			jsreport.renderAsync(myData).then((res) => {
				console.log("Results from jsreports: ", res);

				// open in new window
				//window.open(res.toDataURI());

				//window.location.href = "data:image/gif;base64,R0lGODlhAQABAAAAACw=";
				//window.open("https://www.ambifi.com", "_blank");
				// open download dialog

				//actions.postMessage({ method: "showGoBack" });

				//console.log(res.toDataURI());

				state.set(["appState", "documents", "showSpinner"], false);
				state.set(["appState", "status", "showSpinner"], false);
				state.set(["appState", "editor", "showSpinner"], false);

				if (isMobile()) {
					postMessage({ method: "base64Blob", base64: res.toDataURI() });
					postMessage({
						method: "hideSpinner"
					});
				} else {
					postMessage({ method: "showGoBack" });

					if (config && config.hasOwnProperty("docTypeExt")) {
						res.download(name + "." + config.docTypeExt);
					} else {
						res.download(name + ".pdf");
					}
				}
				resolve();
			}, (reason) => {
				state.set(["appState", "documents", "showSpinner"], false);
				state.set(["appState", "status", "showSpinner"], false);
				state.set(["appState", "editor", "showSpinner"], false);

				alert(`Error: ${reason.status}\n${reason.statusText}`);
				if (isMobile()) {
					postMessage({
						method: "hideSpinner"
					});
					postMessage({
						method: "showError",
						errorTitle: "Print Failed",
						errorText: `An error occurred while attempting to print the report: ${reason.statusText}`
					});
				}
				reject(reason);
				// rejection
			});

		} catch (err) {
			state.set(["appState", "documents", "showSpinner"], false);
			state.set(["appState", "status", "showSpinner"], false);
			state.set(["appState", "editor", "showSpinner"], false);

			alert(`Error: ${err.message}`);
		}
	});
}

export async function printFromMemory(document, columns = false, numColumns = 5, orientation = "portrait", custom = false) { //eslint-disable-line
	utils.showLoader(state, "loadingSpinner");

	let checklist = document;

	checklist = await publishActivityToSignedUrls(checklist);

	utils.recurseReplaceSpeeds(checklist.children);
	recurseDriveRefsToUrls(checklist.children, checklist.id, "", "");
	utils.recurseConvertChecklistToMarkdown(checklist.children, true);

	let templateId;

	if (columns) {
		if (isChecklistItems(checklist)) {
			templateId = "ambifi-items-columns";
		} else if (isChecklistSections(checklist)) {
			templateId = "ambifi-sections-columns";
		} else if (isChecklistLists(checklist)) {
			templateId = "ambifi-lists-columns";
		}
	} else {
		if (isChecklistItems(checklist)) {
			if (checklist.publisher.toLowerCase() === "checkmate") {
				templateId = "miracheck-items-checkmate";
			} else {
				templateId = "ambifi-items";
			}
		} else if (isChecklistSections(checklist)) {
			if (checklist.publisher.toLowerCase() === "checkmate") {
				templateId = "miracheck-sections-checkmate";
			} else {
				templateId = "ambifi-sections";
			}
		} else if (isChecklistLists(checklist)) {
			if (checklist.publisher.toLowerCase() === "checkmate") {
				templateId = "miracheck-lists-checkmate";
			} else {
				templateId = "ambifi-lists";
			}
		}
	}

	const template = checklist.printTemplate;

	if (custom && template && template.trim().length > 0) {
		// var templates = await getPrintTemplates();
		// var template = await getResolvedTemplate("58bfd021d4d441000194d118");
		removeAlternatives(checklist);
		await printTemplateWithContent(document.name, checklist, template, orientation);
	} else if (custom && (_.isUndefined(template) || (template && template.trim.length === 0))) {
		utils.hideLoader(state, "loadingSpinner");
		alert("You do not have a Print Template. You need to add a Print Template in the Checklist properties.");
	} else {
		await printTemplate(document.name, checklist, templateId);
	}
}

export function print(document, columns = false, numColumns = 5, orientation = "portrait", custom = false) { // eslint-disable-line
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, async (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				let checklist = JSON.parse(data.Body.toString());

				checklist = await publishActivityToSignedUrls(checklist);

				recurseReplaceSpeeds(checklist.children);
				recurseConvertChecklistToMarkdown(checklist.children, true);
				recurseDriveRefsToUrls(checklist.children, checklist.id, "", "");

				let templateId;

				if (columns) {
					if (isChecklistItems(checklist)) {
						templateId = "ambifi-items-columns";
					} else if (isChecklistSections(checklist)) {
						templateId = "ambifi-sections-columns";
					} else if (isChecklistLists(checklist)) {
						templateId = "ambifi-lists-columns";
					}
				} else {
					if (isChecklistItems(checklist)) {
						if (checklist.publisher.toLowerCase() === "checkmate") {
							templateId = "miracheck-items-checkmate";
						} else {
							templateId = "ambifi-items";
						}
					} else if (isChecklistSections(checklist)) {
						if (checklist.publisher.toLowerCase() === "checkmate") {
							templateId = "miracheck-sections-checkmate";
						} else {
							templateId = "ambifi-sections";
						}
					} else if (isChecklistLists(checklist)) {
						if (checklist.publisher.toLowerCase() === "checkmate") {
							templateId = "miracheck-lists-checkmate";
						} else {
							templateId = "ambifi-lists";
						}
					}
				}

				const template = checklist.printTemplate;

				if (custom && template && template.trim().length > 0) {
					// getResolvedTemplate("");
					removeAlternatives(checklist);
					printTemplateWithContent(document.name, checklist, template, orientation);
				} else if (custom && (_.isUndefined(template) || (template && template.trim.length === 0))) {
					utils.hideLoader(state, "loadingSpinner");
					alert("You do not have a Print Template. You need to add a Print Template in the Checklist properties.");
				} else {
					printTemplate(document.name, checklist, templateId);
				}
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Print failed", err);
	});
}

export function exportContentToJson(document, convertToCsv = false) {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				let checklist = JSON.parse(data.Body.toString());
				recurseReplaceSpeeds(checklist.children);

				exportContentDumpToJson(checklist, convertToCsv);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Export content as JSON failed", err);
	});

}

export function exportDocForReview(document) { // eslint-disable-line
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, async (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				let checklist = JSON.parse(data.Body.toString());
				checklist = await publishActivityToSignedUrls(checklist);

				recurseReplaceSpeeds(checklist.children);

				if (checklist.hasOwnProperty("children")) {
					recurseDriveRefsToUrls(checklist.children, checklist.id, "", "");
				}

				recurseReplaceHtmlEntities(checklist.children);

				let level;
				if (isChecklistItems(checklist)) {
					level = "content-extract-items";
				} else if (isChecklistSections(checklist)) {
					level = "content-extract-sections";
				} else if (isChecklistLists(checklist)) {
					level = "content-extract-lists";
				}

				printTemplate(document.name + " - " + document.id, checklist, level, { "docTypeExt": "docx" });
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Export doc for review failed", err);
	});
}

export function printShowMode(document) {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, async (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				let checklist = JSON.parse(data.Body.toString());

				checklist = await publishActivityToSignedUrls(checklist);

				recurseReplaceSpeeds(checklist.children);

				let templateId;

				recurseDriveRefsToUrls(checklist.children, checklist.id, "", "");

				recurseConvertChecklistToMarkdown(checklist.children, true);

				console.log("Org ID: " + getOrgId());

				if (getOrgId() === "641d5a7f-4a87-11e9-a392-061714f61e3e" && isChecklistItems(checklist)) {
					templateId = "chop-orientation";
				} else if ((getOrgId() === "57c412f1-83a5-11e8-a392-061714f61e3e" || getOrgId() === "421433d6-c0d4-11e9-b530-066917b38554")) {
					if (isChecklistItems(checklist)) {
						templateId = "ambifi-items-orientation";
					} else if (isChecklistSections(checklist)) {
						templateId = "ambifi-sections-orientation";
					} else if (isChecklistLists(checklist)) {
						templateId = "ambifi-lists-orientation";
					}
				} else {
					if (isChecklistItems(checklist)) {
						templateId = "ambifi-items-show-mode";
					} else if (isChecklistSections(checklist)) {
						templateId = "ambifi-sections-show-mode";
					} else if (isChecklistLists(checklist)) {
						templateId = "ambifi-lists-show-mode";
					}
				}


				printTemplate(document.name, checklist, templateId);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Print failed", err);
	});
}

function removeAlternatives(checklist) {
	let lists = checklist.children;
	removeAlternativesRecurse(lists, checklist, 0, 0);
}

function removeAlternativesRecurse(arr, listIndex, sectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			listIndex = i;
			let nameList = arr[i].name;
			nameList = nameList.replace(" | ", "|");
			let nameListParts = nameList.split("|");
			if (nameListParts.length > 0) {
				arr[i].name = nameListParts[0];
			}
		} else if (arr[i].type === "section") {
			sectionIndex = i;
			let nameSection = arr[i].name;
			nameSection = nameSection.replace(" | ", "|");
			let nameSectionParts = nameSection.split("|");
			if (nameSectionParts.length > 0) {
				arr[i].name = nameSectionParts[0];
			}
		} else if (arr[i].type.startsWith("item")) { // eslint-disable-line
		}

		if (arr[i].hasOwnProperty("children")) {
			removeAlternativesRecurse(arr[i].children, listIndex, sectionIndex);
		}
	}
}

export function getExtractOld(sql = "", fields = [], filePrefix = "extract-") {
	refreshCredentials().then(() => {
		let event = {
			orgId: getOrgId(),
			identityId: getIdentityId(),
			csvFilename: filePrefix + moment().format() + ".csv"
		};

		if (sql !== "") {
			event.sql = sql;
			event.fields = fields;
		}

		let lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: "2015-03-31" });
		let params = {
			FunctionName: env.lambda.extractHistoryCsvFunction,
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: JSON.stringify(event)
		};

		state.set(["appState", "documents", "showSpinner"], true);
		lambda.invoke(params, function (error, data) {
			if (error) {

				state.set(["appState", "documents", "showSpinner"], false);
				alert(error.message);
			} else {
				const result = data.Payload;

				const newResult = JSON.parse(result);
				console.log(newResult.outputLocation);
				console.log(JSON.stringify(result));
				// get s3 document
				const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "text/csv", ResponseCacheControl: "no-cache" } });
				bucket.getObject({ Key: newResult.outputFileLocation }, (err, data) => {
					state.set(["appState", "documents", "showSpinner"], false);
					if (err) {
						console.log(err, err.stack);
						alert(`${err}; ${err.stack}`);
					} else {
						const csv = data.Body;
						//console.log(csv);

						createBlobCsv2(csv, event.csvFilename);
					}
				});
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Extract failed", err);
	});
}

export function getExtract(sql = "", fields = [], filePrefix = "extract-") {
	refreshCredentials().then(() => {
		let event = {
			orgId: getOrgId(),
			identityId: getIdentityId(),
			email: getEmail(),
			csvFilename: filePrefix + moment().format() + ".csv"
		};

		if (sql !== "") {
			event.sql = sql;
			event.fields = fields;
		}

		let lambda = new AWS.Lambda({ region: "us-east-1", apiVersion: "2015-03-31" });
		let params = {
			FunctionName: env.lambda.extractHistoryCsvFunction,
			InvocationType: "Event",
			LogType: "None",
			Payload: JSON.stringify(event)
		};

		state.set(["appState", "documents", "showSpinner"], true);
		lambda.invoke(params, function (error, data) {
			if (error) {

				state.set(["appState", "documents", "showSpinner"], false);
				alert(error.message);
			} else {

				showInfo("Extract", "You have successfuly submitted the extract. You will get an email when the extract is complete.");

				// const result = data.Payload;

				// const newResult = JSON.parse(result);
				// console.log(newResult.outputLocation);
				// console.log(JSON.stringify(result));
				// // get s3 document
				// const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "text/csv", ResponseCacheControl: "no-cache" } });
				// bucket.getObject({ Key: newResult.outputFileLocation }, (err, data) => {
				// 	state.set(["appState", "documents", "showSpinner"], false);
				// 	if (err) {
				// 		console.log(err, err.stack);
				// 		alert(`${err}; ${err.stack}`);
				// 	} else {
				// 		const csv = data.Body;
				// 		//console.log(csv);

				// 		createBlobCsv2(csv, event.csvFilename);
				// 	}
				// });
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Extract failed", err);
	});
}
export function exportCsv(document) {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				const checklist = JSON.parse(data.Body.toString());
				const resArr = [["list", "section", "label1", "label2", "labelOnly", "labelOnlyBackgroundColor", "mandatory"]];
				const lastList = "";
				const lastSection = "";

				recurseToFlatArray(checklist.children, lastList, lastSection, resArr);

				const lineArray = [];
				resArr.forEach((infoArray) => {
					const line = infoArray.join(",");
					lineArray.push(line);
				});
				const csvContent = lineArray.join("\n");

				createBlobCsv2(csvContent, `${checklist.name}.csv`);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Export failed", err);
	});
}

export function exportCsvAdvanced(document) {
	refreshCredentials().then(() => {
		const keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get(), document);

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: `${keyPrefix}/${document.id}.json` }, (err, data) => {
			if (err) {
				console.log(err, err.stack);
				alert(`${err}; ${err.stack}`);
			} else {
				const checklist = JSON.parse(data.Body.toString());
				const resArr = [
					[
						"checklistId",
						"checklistName",
						"checklistAudio",
						"checklistPrint",
						"checklistDescription",
						"checklistDontSpeakAudio",
						"checklistTrackData",
						"checklistTrackLocation",
						"checklistGenre",
						"checklistTags",
						"listId",
						"listName",
						"listAudio",
						"listPrint",
						"listDefaultView",
						"listColor",
						"listIgnoreCompletion",
						"listDontPrint",
						"sectionId",
						"sectionName",
						"sectionAudio",
						"sectionPrint",
						"sectionColor",
						"sectionIconColor",
						"sectionBackgroundColor",
						"sectionBorderColor",
						"sectionIgnoreCompletion",
						"sectionDontPrint",
						"itemId",
						"itemType",
						"itemShowModeTextAlign",
						"itemLabel1",
						"itemLabel2",
						"itemComments",
						"itemCommentsInline",
						"itemMandatory",
						"itemIgnoreCompletion",
						"itemLabelOnly",
						"itemLabelOnlyBackgroundColor",
						"itemLabel1Color",
						"itemLabel1Audio",
						"itemLabel1Print",
						"itemLabel2Color",
						"itemLabel2Audio",
						"itemLabel2Print",

						"textInputPlaceholder",
						"textInputDefaultValue",
						"textInputMaxLength",
						"textInputKeyboardType",
						"textInputKeyboardAutoCapitalize",
						"textInputKeyboardAutoCorrect",
						"textInputKeyboardReturnKeyType",
						"textInputMaskType",
						"textInputCustomMask",
						"textInputCustomMask2",
						"textInputCurrencySymbol",
						"textInputCurrencySeparator",
						"textInputNumberOfLines",

						"pickerItemViewType",
						"itemsDatasource",
						"associatedContentTemplate",
						"pickerItemsDatasource",
						"pickerItemPlaceholder",
						"pickerItemDefaultValue",
						"pickerItems",
						"items",
						"pickerAdvanceOnSelect",
						"pickerLinkOnSelect",
						"pickerLinkActionType",

						"yesNoLinkOnSelect",
						"yesNoLinkActionType",
						"yesLinkActionType",
						"noLinkActionType",
						"naLinkActionType",
						"yesNoYesLinkId",
						"yesNoNoLinkId",
						"yesNoNaLinkId",
						"yesNoShowNa",
						"yesNoAdvanceOnSelect",

						"dateTimeType",
						"dateTimeInitialDate",
						"dateTimeMinuteInterval",
						"dateTimeAdvanceOnSelect",

						"imagePickerAddMediaButton",
						"imagePickerUploadTitle",
						"imagePickerCaptureMediaTitle",

						"sketchPadBackgroundColor",
						"sketchPadPenColor",
						"sketchPadPenWidth",

						"itemDontPrint",
						"itemStartTimer",
						"itemStopTimer",

						"itemLinkOnCheck",
						"itemLinkId",
						"itemLinkActionType"
					]
				];
				const lastList = "";
				const lastSection = "";
				recurseToFlatArrayAdvanced(checklist, checklist.children, lastList, lastSection, resArr);

				const lineArray = [];
				resArr.forEach((infoArray) => {
					const line = infoArray.join(",");
					lineArray.push(line);
				});
				const csvContent = lineArray.join("\n");
				createBlobCsv2(csvContent, `${checklist.name}.csv`);
			}
		});
	}).catch((err) => {
		console.error(err);
		showError2("Export failed", err);
	});
}

export function convertImageUrlToBase64(imageUrl) {
	refreshCredentials().then(() => {
		const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

		const params = {
			FunctionName: "convertImageUrlToBase64",
			InvocationType: "RequestResponse",
			LogType: "None",
			Payload: `{"imageUrl":"${imageUrl}"}`,
		};

		lambda.invoke(params, (error, data) => {
			if (error) {
				alert(error);
			} else {
				console.log(data.Payload);
			}
		});

	}).catch((err) => {
		console.error(err);
		showError2("Convert failed", err);
	});
}

export function sendEmail(toAddresses = [], subject = "", body = "", htmlBody, ccAddresses = [], senderEmail = "support@ambifi.com", replyToAddresses, charset = "UTF-8") {
	refreshCredentials().then(async () => {
		const emailParams = {
			Destination: { ToAddresses: toAddresses ? toAddresses : [], CcAddresses: ccAddresses ? ccAddresses : [] },
			Message: {
				Body: {
					Html: {
						Charset: charset ? charset : 'UTF-8',
						Data: htmlBody ? htmlBody : body
					},
					Text: {
						Charset: charset ? charset : 'UTF-8',
						Data: body
					}
				},
				Subject: {
					Charset: charset ? charset : 'UTF-8',
					Data: subject
				}
			},
			Source: senderEmail
		};

		if (replyToAddresses) {
			emailParams.ReplyToAddresses = replyToAddresses
		}

		await new AWS.SES({ apiVersion: "2010-12-01" }).sendEmail(emailParams).promise();

		// const lambda = new AWS.Lambda({ region: env.aws.defaultRegion, apiVersion: "2015-03-31" });

		// const params = {
		// 	FunctionName: env.lambda.sendEmailFunction,
		// 	InvocationType: "RequestResponse",
		// 	LogType: "None",
		// 	Payload: JSON.stringify({
		// 		method: "sendEmail",
		// 		toEmails: toEmails,
		// 		ccEmails: ccEmails,
		// 		bccEmails: bccEmails,
		// 		subject: subject,
		// 		body: body,
		// 		htmlBody: htmlBody ? htmlBody : body,
		// 		senderEmail: senderEmail,
		// 		replyToAddresses: replyToAddresses,
		//		charset: charset
		// 	}),
		// };

		// lambda.invoke(params, (error, data) => {
		// 	if (error) {
		// 		alert(error);
		// 	} else {
		// 		console.log(data.Payload);
		// 	}
		// });

	}).catch((err) => {
		console.error(err);
		showError2("Send email failed", err);
	});
}

function showErrorNoDetail(title, err) {
	if (err === null) {
		showError(title, "Unknown error.");
	} else if (err.code === "NetworkingError") {
		showError(title, "Network error. Please check connectivity and try again.");
	} else {
		showError(title, err.message);
	}
}


/**
 * NEW FUNCTIONS added as part of the larger refactor, need to focus more on cohesion to ensure these modules don't grow into confusing monoliths.
 */


function getUtcTimestamp() {
	// ISO 8601
	return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
}

export function getActivityInstance(key) {
	return new Promise(async (resolve, reject) => {
		try {
			const instance = await getObject(env.s3.contentBucket, key, false);
			resolve(instance);
		} catch (e) {
			reject(e);
		}
	});
}

export function saveActivityInstance(instance, prefix = getIdentityId()) {
	return new Promise(async (resolve, reject) => {
		const { instanceId, id } = instance;
		let lastTimestamp = instance.history && _.isArray(instance.history) && !_.isEmpty(instance.history) ? (instance.history[0].timestamp).replace(" ", "T") : getUtcTimestamp();
		let fileName = id + "/" + lastTimestamp + "_" + instanceId + ".json";

		try {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
			await bucket.putObject({ Bucket: env.s3.contentBucket, Key: prefix + "/" + fileName, Body: JSON.stringify(instance), ContentType: "application/json" }).promise();
			resolve(instance);
		} catch (e) {
			reject(e);
		}
	});
}

export async function getActivity(activityId, prefix = getIdentityId()) {
	let activity = await getObject(env.s3.contentBucket, `${prefix}/${activityId}.json`);
	// If you are a third party author then we need the identityId in the activity
	if (prefix !== getIdentityId()) {
		activity.identityId = prefix;
	}

	return activity;
}

export function getActivityIfExists(activityId, prefix = getIdentityId()) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			objectExists(env.s3.contentBucket, `${prefix}/${activityId}.json`).then(exists => {
				if (exists) {
					getObject(env.s3.contentBucket, `${prefix}/${activityId}.json`).then(content => {
						resolve(content);
					}).catch(err => {
						reject(err);
					});
				} else {
					resolve();
				}
			}).catch(err => {
				reject(err);
			});
		}).catch(err => {
			reject(err);
		});
	});
}

export function activityExists(activityId, prefix = getIdentityId()) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			objectExists(env.s3.contentBucket, `${prefix}/${activityId}.json`).then(exists => {
				resolve(exists);
			}).catch(err => {
				reject(err);
			});
		}).catch(err => {
			reject(err);
		});
	});
}

export function getObject(bucketName, key, skipParse = false) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
			bucket.getObject({ Key: key }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					// console.log(data, data.Body.toString());
					if (skipParse) {
						resolve(data.Body);
					} else {
						let content = _.trim(data.Body.toString());
						try {
							resolve(JSON.parse(content));
						} catch (e) {
							resolve(content);
						}

					}
				}
			});
		}).catch(err => {
			reject(err);
		});
	});
}

export function getInventory(prefix = getIdentityId()) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const Key = `${prefix}/${env.artifacts.inventoryFileName}`;
			const Bucket = env.s3.contentBucket;

			objectExists(Bucket, Key).then((res) => {
				if (res) {
					const bucket = new AWS.S3({ useDualStack: true, params: { Bucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
					bucket.getObject({ Key }, (err, data) => {
						if (err) {
							reject(err);
						} else {
							resolve(JSON.parse(data.Body.toString()));
						}
					});
				} else {
					// Doesn't exist yet!
					uploadObject([], Bucket, Key).then(() => {
						resolve([]);
					}).catch(err => {
						reject(err);
					});
				}
			}).catch(err => {
				reject(err);
			});
		}).catch(err => {
			reject(err);
		});
	});
}

// export function getTimerTemplates(prefix = getIdentityId()) {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const s3 = new AWS.S3({ useDualStack: true });
// 			const timerTemplatesData = await s3.getObject({ Bucket: env.s3.contentBucket, Key: prefix + "/" + TIMER_TEMPLATES_FILE_NAME }).promise();
// 			const newTimerTemplates = JSON.parse(timerTemplatesData.Body.toString());

// 			resolve(newTimerTemplates);
// 			// If exists then set otherwise set a template of timers
// 		} catch (err) {
// 			if (err.statusCode === 404) {
// 				await putJsonFileToS3(env.s3.contentBucket, "timerTemplates.json", timerTemplates);
// 				resolve(timerTemplates);
// 			} else {
// 				reject(err);
// 			}
// 		}
// 	});
// }


/**
 * This function will filter out all non essential properties from shared/external artifacts.
 * There are only a certain amount of properties that can remain in the users inventory.
 * @param {*} activities 
 */
function filterExternalActivityProperties(activities) {
	const result = [];

	activities.forEach(activity => {
		if (activity.hasOwnProperty("identityId") && !_.isEmpty(activity.identityId)) {
			result.push(_.pick(activity, INVENTORY_EXTERNAL_CONTENT_PERSISTENT_PROPS));
		} else {
			result.push(activity);
		}
	});

	return result;
}

export function putInventory(inventory, prefix = getIdentityId()) {

	// Make sure that when this is put that blank entries are removed
	const _inventory = _.filter(inventory, (o) => !o.newDoc);

	return uploadObject(
		filterExternalActivityProperties(
			// Create stripped down experiences inventory
			createExperiencesInventoryFromActivities(
				filterLearningActivities(_inventory)
			)
		),
		env.s3.contentBucket, `${prefix}/${env.artifacts.inventoryFileName}`);
}


export function copyObject(sourceBucket, sourceKey, destinationBucket, destinationKey, additionalParams = {}) {
	return new Promise((resolve, reject) => {
		let params = {
			CopySource: `/${sourceBucket}/${sourceKey}`,
			Key: destinationKey
		};

		_.each(additionalParams, (value, key) => {
			params[key] = value;
		})
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: destinationBucket } });
		bucket.copyObject(params, function (err, data) {
			if (err) {
				reject(err);
			} else {
				resolve(data);
			}
		});
	});
}

export function moveObject(sourceBucket, sourceKey, destinationBucket, destinationKey) {
	return new Promise((resolve, reject) => {
		copyObject(sourceBucket, sourceKey, destinationBucket, destinationKey).then(() => {
			deleteObject(sourceBucket, sourceKey).then(() => {
				resolve();
			}).catch(err => {
				reject(err);
			});
		}).catch(err => {
			reject(err);
		});
	});
}

export function uploadObject(content, bucketName, key, skipRefreshSession = false) {
	return new Promise(async (resolve, reject) => {
		try {
			if (!skipRefreshSession) {
				await refreshCredentials();
			}
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			const params = { Key: key, ContentType: "application/json", Body: !_.isString(content) ? JSON.stringify(content) : content };
			bucket.upload(params, (err) => {
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			});
		} catch (err) {
			reject(err);
		}
	});
}

export function deleteObject(bucketName, key) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			bucket.deleteObject({ Key: key }, err => {
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});

}
export function deleteObjects(bucketName, objects) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			bucket.deleteObjects({ Delete: { Objects: objects } }, err => {
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});

}
export function objectExists(bucketName, key) {

	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			bucket.headObject({ Key: key }, function (err) {
				if (err && err.code === "NotFound") {
					resolve(false);
				} else if (err) {
					reject(err);
				} else {
					resolve(true);
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});
}

export function objectExists2(bucketName, key) { // eslint-disable-line
	return new Promise((resolve) => {
		resolve(true);
	});
}


export function listAllObjects(bucketName, prefix) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			recursiveListObjects(bucketName, prefix, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});
}

function recursiveListObjects(bucketName, prefix = null, cb = () => { }, marker = null, files = []) {
	const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
	const params = {
		Bucket: bucketName
	};

	// are we paging from a specific point?
	if (marker) {
		params.Marker = marker;
	}

	if (prefix) {
		params.Prefix = prefix;
	}

	bucket.listObjects(params, function (err, data) {
		if (err) {
			return cb(err);
		}

		// concat the list of files into our collection
		files = files.concat(data.Contents);

		// are we paging?
		if (data.IsTruncated) {
			let length = data.Contents.length;
			let marker = data.Contents[length - 1].Key;
			// recursion!
			recursiveListObjects(bucketName, prefix, cb, marker, files);
		} else {
			cb(undefined, files);
		}

	});
}

export function batchGetActivities(activities) {
	return new Promise((resolve, reject) => {
		Promise.all(activities.map((activity) => {

			let keyPrefix = getIdentityId();
			if (activity.hasOwnProperty("identityId")) {
				keyPrefix = activity.identityId;
			}

			return getActivityIfExists(activity.id, keyPrefix);
		})).then(results => {
			resolve(_.filter(results, (o) => { return !_.isUndefined(o) && !_.isNull(o); }));
		}).catch(err => {
			reject(err);
		});
	});
}

export function getObjectTags(bucketName, key) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			bucket.getObjectTagging({ Key: key }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data.TagSet);
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});
}

export function getObjectTag(bucketName, key, tagName) {
	return new Promise((resolve, reject) => {
		const multipleTagNames = _.isArray(tagName);
		getObjectTags(bucketName, key).then(tags => {
			let result = null;
			tags.forEach(tag => {
				if (!multipleTagNames && tag.Key === tagName) {
					result = tag.Value;
				} else if (multipleTagNames && _.indexOf(tagName, tag.Key) !== -1) {
					if (!result) {
						result = {};
					}
					result[tag.Key] = tag.Value;
				}
			});
			resolve(result);
		}).catch(err => {
			reject(err);
		});
	});
}

export function getActivityTag(activityId, tagName, prefix = getIdentityId()) {
	return getObjectTag(env.s3.contentBucket, `${prefix}/${activityId}.json`, tagName);
}

export function putObjectTags(bucketName, key, tags) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			bucket.putObjectTagging({ Key: key, Tagging: { TagSet: tags } }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});
}

/**
 * Will put the provided content into the specified bucket and under the specified key. Converts content to string if not yet a string.
 * @param {*} bucketName 
 * @param {*} key 
 * @param {*} content 
 * @param {*} contentType default to "application/json"
 */
export function putObject(bucketName, key, content, contentType = "application/json") {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
			// await s3.putObject({ Bucket: bucketName, Key: keyPrefix + "/" + fileName, Body: body }).promise();

			const Body = !_.isString(content) ? JSON.stringify(content) : content;

			bucket.putObject({ Key: key, Body, ContentType: contentType }, (err, data) => {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}).catch((err) => {
			reject(err);
		});
	});
}

export function putActivityTag(activityId, tagName, tagValue, prefix = getIdentityId()) {
	return putActivityTags(activityId, { [tagName]: tagValue }, prefix);
}

/**
 * 
 * @param {*} activityId activity that shall receive the tags (will be added to existing tags)
 * @param {Object} tagsObj key/value map
 * @param {*} prefix 
 */
export function putActivityTags(activityId, tags, prefix = getIdentityId()) {
	return new Promise((resolve, reject) => {
		const BucketName = env.s3.contentBucket;
		const Key = `${prefix}/${activityId}.json`;
		getObjectTags(BucketName, Key).then(existingTags => {
			const existingTagsMap = existingTags && existingTags.length > 0 ? arrayToMap(existingTags, "Key") : {};
			_.keys(tags).forEach(key => {
				existingTagsMap[key] = tags[key];
			});
			console.log(existingTagsMap);
			const newTags = _.keys(existingTagsMap).map(Key => {
				return {
					Key,
					Value: existingTagsMap[Key]
				};
			});
			console.log(newTags);
			putObjectTags(BucketName, Key, newTags).then(res => {
				resolve(res);
			}).catch(err => {
				reject(err);
			});
		});
	});
}


export function loadActivityInstanceFromS3(bucketName, fileName) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
			bucket.getObject({ Key: `${fileName}` }, (err, data) => {
				if (err) {
					showError("Unable to get activity", err);
					reject(err);
				} else {
					// Set previewChecklist
					let jsonChecklist = JSON.parse(data.Body.toString());
					// Should convert to make sure has at least one list and one section and set proper type
					jsonChecklist = normalizeActivity(jsonChecklist);
					resolve(jsonChecklist);
				}
			});
		}).catch((err) => {
			console.error(err);
			showError2("Unable to load activity", err);
			reject(err);
		});
	});

}

/**
 * Checks for invalid S3 paths when saving activities. 
 * 
 * Paths cannont contain null or undefined.
 * 
 * @param {*} strPath 
 * @returns boolean indicating whether or not the path is valid
 */
export function isValidActivitySavePathPrefix(strPath) {
	if (!strPath) {
		return false;
	}

	let strPathLowerCase = strPath.toLowerCase();

	if (strPathLowerCase.includes("null") ||
		strPathLowerCase.includes("undefined") ||
		strPathLowerCase === "/") {
		return false;
	}

	return true;
}
import _ from "lodash";

import state from "../state/state";

const PROP_LABEL = "label";
const PROP_LABEL1_AUDIO = "label1Audio";
const PROP_LABEL2 = "label2";
const PROP_LABEL2_AUDIO = "label2Audio";
const PROP_COMMENTS = "comments";
const PROP_COMMENTS_AUDIO = "commentsAudio";
const PROP_COMMENTS_SSML = "commentsSsml";

/**
 * If you want to expand scanning for variables to other props then add them here, this will allow variables to be defined in the text.
 */
export const INLINE_VARIABLE_PROPS = [
	PROP_LABEL,
	PROP_LABEL1_AUDIO, // May want to inject variables and calculations into overrides
	PROP_LABEL2,
	PROP_LABEL2_AUDIO, // May want to inject variables and calculations into overrides
	PROP_COMMENTS,
	PROP_COMMENTS_AUDIO,
	PROP_COMMENTS_SSML,
]

export const INLINE_VARIABLE_PROPS_MAP = {};
_.forEach(INLINE_VARIABLE_PROPS, propName => {
	INLINE_VARIABLE_PROPS_MAP[propName] = true;
})

const PROP_MARKERS = "markers";
const ACTIVITY_META_DATA_BASE_PATH = ["editActivityMetaData"];
const EDITOR_ACTIVITY_MARKERS_PATH = [...ACTIVITY_META_DATA_BASE_PATH, PROP_MARKERS];

export const MARKER_TYPE_ERROR = "error";
export const MARKER_TYPE_WARNING = "warning";
export const MARKER_TYPE_INFO = "info";

export function createMarker(type = MARKER_TYPE_INFO, text = "", key = null, metaData = null) {
	return {
		type,
		text,
		key,
		metaData
	}
}

export function createErrorMarker(text = "", key = null, metaData = null) {
	return createMarker(MARKER_TYPE_ERROR, text, key, metaData);
}

export function createWarningMarker(text = "", key = null, metaData = null) {
	return createMarker(MARKER_TYPE_WARNING, text, key, metaData);
}

export function createInfoMarker(text = "", key = null, metaData = null) {
	return createMarker(text, key, metaData);
}

export function resetActivityEditMetaData() {
	state.set(...ACTIVITY_META_DATA_BASE_PATH, {
		inlineVariables: {}
	})
}

export function setActivityMetaData(prop, value) {
	let _prop = prop;
	if (!_.isArray(prop)) {
		_prop = [prop];
	}

	state.set([...ACTIVITY_META_DATA_BASE_PATH, ..._prop], value);
}

export function getActivityMetaData(prop) {
	let _prop = prop;
	if (!_.isArray(prop)) {
		_prop = [prop];
	}

	return state.get([...ACTIVITY_META_DATA_BASE_PATH, ..._prop]);
}

/**
 * Add 
 * @param {*} entityId this is the guid of the entity
 * @param {*} marker the marker containing information and meta data that can ultimately be shown in the editor. Add a "key" property to make this unique and prevent multiple markers for the same error
 * @param {*} property can define if this related to a certain property/properties or just "root" (the entity as a whole)
 */
export function addEntityMarker(entityId, marker, property = "root") {
	const entityPropertyMarkersPath = [...EDITOR_ACTIVITY_MARKERS_PATH, entityId, property];

	if (!state.exists(entityPropertyMarkersPath)) {
		state.set(entityPropertyMarkersPath, []);
	}
	const existingMarkers = state.get(entityPropertyMarkersPath);
	if (marker.key) {
		// This needs to be unique
		let exists = false;
		let idx = -1;
		for (let existingMarker of existingMarkers) {
			idx++;
			if (existingMarker.key === marker.key) {
				exists = true;
				break;
			}
		}

		if (exists) {
			if (marker.collisionBehavior !== "ignore") {

				state.select(entityPropertyMarkersPath).splice([idx, 1, marker]);
			}
		} else {
			state.push(entityPropertyMarkersPath, marker);
		}
	} else {
		state.push(entityPropertyMarkersPath, marker);
	}
}

/**
 * Will remove one or more markers for a given entity and property, determining what is removed is based on the provided filter function.
 * @param {*} entityId the guid of the entity
 * @param {*} property the property for which markers shall be removed
 * @param {*} filter a function that given a marker determines whether to keep it or loose it, a marker will be removed if the function returns false for it. (See lodash#filter)
 */
export function removeEntityMarkers(entityId, property = "root", filter) {
	const entityMarkersPath = [...EDITOR_ACTIVITY_MARKERS_PATH, entityId, property];
	if (!state.exists(entityMarkersPath)) {
		// Nothing to remove, nothing to do
		return;
	}

	if (!filter || !_.isFunction(filter)) {
		state.unset(entityMarkersPath);
	} else {
		const markers = _.filter(state.get(entityMarkersPath), filter);
		state.set(entityMarkersPath, markers);
	}
}

import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
// import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import { FormControl, FormGroup, InputGroup, SplitButton, MenuItem, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import MediaQuery from "react-responsive";
import Modal from "react-responsive-modal";

import { getSelectedItemLabel, getContent, uploadContent, showSpinner, hideSpinner, selectFolderAndFile } from "../../actions/drive";
import { showError } from "../../actions/alertActions";
import Drive from "./drive";
import { isClipboardEmpty, getClipboard, copy } from "../../utils/clipboard";
import { isMobile } from "../../utils/utils";
// import {generateUUID} from "../../utils/utils";

class InlineFilePicker extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			pickerShow: false,
			editorState: {
				content: "",
				validity: {
					valid: true,
					error: null
				}
			}
		};
		console.log("Construct inline file picker", props);
		autoBind(this);
	}

	handleCancel() {
		this.setState({
			pickerShow: false
		});
	}

	handleOk(selectedItem) {
		console.log("Handling ok...", selectedItem);
		const { onFileSelected } = this.props;
		this.setState({
			pickerShow: false
		});
		if (onFileSelected) {
			onFileSelected(selectedItem);
		}
	}

	toggleDrivePicker() {

		const { value } = this.props;

		if (!this.state.pickerShow && value) {
			this.props.dispatch(
				selectFolderAndFile,
				value
			);
		}

		this.setState({
			pickerShow: !this.state.pickerShow
		});
	}

	handleCopyResource() {
		copy(this.props.reference);
	}

	handlePasteResource() {
		if (this.props.handleReplaceValue && !isClipboardEmpty()) {
			this.props.handleReplaceValue(getClipboard());
		}
	}

	handleUpload() {
		const { accept, entityId } = this.props;
		let _accept = accept;
		if (!_accept) {
			_accept = "*/*";
		}
		const input = document.getElementById(`quick-upload-inline-${entityId}`);
		if (input) {
			input.accept = _accept;
			input.click();
		}
	}

	handleUploadAndResplace() {

	}

	handleShowSpinner() {
		if (this.props.showSpinner && _.isFunction(this.props.showSpinner)) {
			this.props.showSpinner();
		} else {
			showSpinner();
		}
	}
	handleHideSpinner() {
		if (this.props.hideSpinner && _.isFunction(this.props.hideSpinner)) {
			this.props.hideSpinner();
		} else {
			hideSpinner();
		}
	}

	handleEditDocument() {
		const { value: cursor } = this.props;

		if (!cursor) {
			showError("Invalid Selection", "Please make sure to select a file for editing.");
			return;
		}
		this.handleShowSpinner();
		setTimeout(() => {
			getContent(cursor.guid).then(editingContent => {
				this.setState({
					editing: true,
					editingContent,
					editingInfo: {
						newDoc: false,
						type: cursor.type
					},
					captureNewDocumentInfo: false
				});
				this.handleHideSpinner();
			}).catch(err => {
				showError("Edit Document", "An error occurred while attempting to open the document for editing: " + err);
				this.handleHideSpinner();
			});
		});

	}

	handleCancelEditDocument() {
		this.setState({
			editingContent: null,
			editing: false,
			captureNewDocumentInfo: false
		});
	}

	handleEditorContentChange(content) {
		console.log("ON CONTENT CHANGE", content);
		const editorState = _.clone(this.state.editorState);
		editorState.content = content;
		this.setState({ editorState });
	}

	handleEditorContentValidityChange(validity) {
		const editorState = _.clone(this.state.editorState);
		editorState.validity = validity;
		this.setState({ editorState });
	}

	handleSave() {
		this.handleShowSpinner();
		setTimeout(() => {
			const { editorState, editingInfo } = this.state;
			const { value: cursor, savePathWhenEmpty, showEditorWhenEmpty, acceptType } = this.props;
			const { content } = editorState;
			const { type } = editingInfo;

			if (!cursor && showEditorWhenEmpty) {
				// THIS NEEDS TO BE HARDENED FIRST

				uploadContent(savePathWhenEmpty, content, "application/json", {
					type: acceptType
				}).then(() => {
					if (this.props.onEditContentSave && _.isFunction(this.props.onEditContentSave)) {
						this.props.onEditContentSave(content);
					}
					this.handleHideSpinner();
				}).catch(err => {
					console.error(err);
					this.handleHideSpinner();
				});
			} else {
				// Edited doc!
				if (cursor.type !== "file") {
					showError("Save File", "Unable to save file, expected a file to be selected.");
					return;
				}

				const { contentType } = type;
				const { path } = cursor;
				uploadContent(path, content, contentType).then(() => {
					if (this.props.onEditContentSave && _.isFunction(this.props.onEditContentSave)) {
						this.props.onEditContentSave(content);
					}
					this.handleHideSpinner();
				}).catch(err => {
					console.error(err);
					this.handleHideSpinner();
				}); // No need to update metaData here, we already have the information we need (file type)

			}

		});
	}

	handleCancelPickFile() {
		this.setState({
			pickerShow: false
		});
	}

	render() {
		const { pickerShow, editing, editingContent } = this.state;
		const { value, hideConvenienceFunctions, handleQuickUpload, handleReplaceExisting, reference, acceptType, modal, handleShowPasteImageModal, handleShowAiImageGeneratorModal, handleShowMarkupImageModal } = this.props;
		let driveComponent = <Drive {...this.props} inline okCancel actionBarTop onOk={this.handleOk} onCancel={this.handleCancel} acceptType={acceptType} />;
		let component = driveComponent;
		component = modal ? (
			<Modal
				open={pickerShow}
				onClose={this.handleCancelPickFile} classNames={{ modal: "custom-modal templates" }} closeButton>
				<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
					<div style={{ flex: 0 }}>
						<h3>Pick File</h3>
						<hr />
					</div>
					<div className="file-picker-modal-body">
						{component}
					</div>
				</div>
				{/* <AmbifiLoader show={showSpinner} modal /> */}
			</Modal>
		) : component;
		console.log("GOT VALUE", value);
		let path = "";
		try {
			path = getSelectedItemLabel(value);
		} catch (err) {
			console.warn(err);
		}
		const { editors } = this.props;
		let editor = null;
		if (editors && value) {
			const { metaData, ext } = value;
			if (metaData) {
				const { type } = metaData;
				if (type && editors[type]) {
					editor = editors[type];
				}
			}
			const extesnionKey = `extension/${ext}`;
			if (!editor && ext && editors[extesnionKey]) {
				editor = editors[extesnionKey];
			}
		}


		let pasteImageMenuItem;
		let aiImageGeneratorMenuItem;
		let markupImageMenuItem;

		if (this.props.accept && this.props.accept.startsWith("image")) {
			pasteImageMenuItem = <MenuItem key="-3" onClick={() => {
				handleShowPasteImageModal();
			}}>
				<div style={{ padding: "5px" }}>
					<FontAwesomeIcon icon="paste" /> Paste Image
				</div>
			</MenuItem>;

			if (this.props.urlParams.hasOwnProperty("aiAssistant") && this.props.urlParams.aiAssistant === "true") {
				aiImageGeneratorMenuItem = <MenuItem key="-3x" onClick={() => {
					handleShowAiImageGeneratorModal();
				}}>
					<div style={{ padding: "5px" }}>
						<FontAwesomeIcon icon="magic" />AI Assistant - Generate Image
					</div>
				</MenuItem>;
			}

			if (this.props.markupImage || (!isMobile() && (this.props.urlParams.hasOwnProperty("markupImage") && this.props.urlParams.markupImage === "true"))) {
				markupImageMenuItem = <MenuItem key="-4" onClick={() => {
					handleShowMarkupImageModal();
				}}>
					<div style={{ padding: "5px" }}>
						<FontAwesomeIcon icon="pen" /> Media Markup
					</div>
				</MenuItem>;
			}
		}


		if (!pickerShow) {


			component =
				<div>

					<FormGroup>
						{!hideConvenienceFunctions && <MediaQuery query='(max-width: 767px)'>
							{!hideConvenienceFunctions && <div style={{ display: "flex", flexDirection: "column" }}>
								<Button bsStyle="link" style={{ flex: 1 }} key="1" onClick={() => {
									handleQuickUpload();
								}}>
									<div style={{ padding: "5px" }}>
										<FontAwesomeIcon icon="cloud-upload" /> Quick Upload
									</div>
								</Button>
								{path && !_.isEmpty(path) && <Button bsStyle="link" style={{ flex: 1 }} key="2" onClick={() => {
									if (value.path && value.path.indexOf("/") !== -1) {
										handleReplaceExisting(value.path);
									}
								}}>
									<div style={{ padding: "5px" }}>
										<FontAwesomeIcon icon="sync" /> Upload New Version
									</div>
								</Button>}
							</div>}
						</MediaQuery>}
						{/* <input type="file" accept="image/*" id={`replace-${_guid}`} onChange={this.handleUploadAndResplace} style={{ display: "none" }} /> */}
						{!hideConvenienceFunctions && <InputGroup>

							<InputGroup.Addon><FontAwesomeIcon icon={["far", "hdd"]} /></InputGroup.Addon>
							<FormControl type="text" disabled value={path} />
							{editor && <InputGroup.Button><Button key="drive-edit-file-button" onClick={this.handleEditDocument}><FontAwesomeIcon icon="pencil" /> Edit</Button></InputGroup.Button>}
							<MediaQuery query='(max-width: 767px)'>
								<InputGroup.Button>

									<Button onClick={this.toggleDrivePicker}><FontAwesomeIcon icon={["far", "folder-open"]} /> Browse</Button>
								</InputGroup.Button>
							</MediaQuery>
							<MediaQuery query='(min-width: 768px)'>
								<SplitButton
									componentClass={InputGroup.Button}
									id="input-dropdown-addon"
									title={<div onClick={this.toggleDrivePicker}><FontAwesomeIcon icon={["far", "folder-open"]} />&nbsp;Browse</div>}
									pullRight
								>
									{path && !_.isEmpty(path) && <MenuItem key="-2" onClick={() => {
										console.log("Replace existing", value);
										if (value.path && value.path.indexOf("/") !== -1) {
											this.handleCopyResource();
										}
									}}>
										<div style={{ padding: "5px" }}>
											<FontAwesomeIcon icon={["far", "copy"]} /> Copy Resource
										</div>
									</MenuItem>}
									<MenuItem key="-1" disabled={isClipboardEmpty() || getClipboard() === reference} onClick={() => {
										this.handlePasteResource();
									}}>
										<div style={{ padding: "5px" }}>
											<FontAwesomeIcon icon="paste" /> Paste Resource
										</div>
									</MenuItem>
									{pasteImageMenuItem}
									{aiImageGeneratorMenuItem}
									{markupImageMenuItem}
									{handleQuickUpload &&
										<>
											<MenuItem divider />
											<MenuItem key="1" onClick={() => {
												handleQuickUpload();
											}}>
												<div style={{ padding: "5px" }}>
													<FontAwesomeIcon icon="cloud-upload" /> Quick Upload
												</div>
											</MenuItem>
										</>
									}
									{path && !_.isEmpty(path) && <MenuItem key="2" onClick={() => {
										console.log("Replace existing", value);
										if (value.path && value.path.indexOf("/") !== -1) {
											handleReplaceExisting(value.path);
										}
									}}>
										<div style={{ padding: "5px" }}>
											<FontAwesomeIcon icon="sync" /> Upload New Version<br />
											<small style={{ whiteSpace: "normal", minWidth: "150px" }}><b>Warning:</b> Will replace the selected resource with new content.</small>
										</div>
									</MenuItem>}
								</SplitButton>
							</MediaQuery>
						</InputGroup>}
						{hideConvenienceFunctions && <InputGroup.Button>
							<Button onClick={this.toggleDrivePicker}><FontAwesomeIcon icon={["far", "folder-open"]} /> Browse</Button>
						</InputGroup.Button>}

					</FormGroup>
					{(editing) && <Modal
						open={editing}
						onClose={this.handleCancelEditDocument} classNames={{ modal: "custom-modal templates" }} closeButton>
						<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
							<div style={{ flex: 0 }}>
								<h3>Edit</h3>
								<hr />
							</div>
							<div className="map-modal-body-container" style={{ flex: 1, /*display: "flex", */overflowY: "auto" }}>
								{<editor.component ref={this.currentEditor} onChange={this.handleEditorContentChange} onValidityChange={this.handleEditorContentValidityChange} content={editingContent} />}
							</div>
							<div className="modal-footer-container space-between" style={{ flex: 0 }}>
								<Button onClick={this.handleCancelEditDocument}><FontAwesomeIcon icon="ban" /> Cancel</Button>
								<Button bsStyle="primary" onClick={this.handleSave}><FontAwesomeIcon icon="save" /> Save</Button>
							</div>
						</div>
						{/* <AmbifiLoader show={showSpinner} modal /> */}
					</Modal>}
				</div>;

		}


		return component;
	}
}

export default branch({
	clipbpard: ["generalClipboard"],
	editors: ["driveEditors", "editors"],
	urlParams: ["appState", "urlParams"],
	markupImage: ["appCapabilities", "markupImage"]
}, InlineFilePicker);
import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import { Button } from "react-bootstrap";

class MemberGroupSelect extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			initialValueGroups: this.props.groups ? this.props.groups : "",
			initialValueMembers: this.props.members ? this.props.members : "",
			valueGroups: this.props.groups ? this.props.groups : "",
			valueMembers: this.props.members ? this.props.members : ""
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		console.log("DOING SOMETHING WITH PROPS");

		console.log("PROPS CHANGE", this.props, nextProps);

		if (this.props.members.length !== nextProps.members.length) {
			this.setState({
				valueMembers: nextProps.members
			})
		}


		console.log(nextProps);
	}

	render() {
		const { defaultMembers, defaultGroups, onChange, externalValueManagement, members, groups } = this.props;
		let defaultMemberEntries = [];
		let defaultGroupEntries = [];
		if (defaultMembers) {
			defaultMemberEntries = defaultMemberEntries.concat(defaultMembers);
		}
		if (defaultGroups) {
			defaultGroupEntries = defaultGroupEntries.concat(defaultGroups);
		}

		let groupSelect;

		if (defaultGroupEntries.length > 0) {
			const valueGroups = externalValueManagement ? groups : this.state.valueGroups;
			groupSelect =
				<div>
					<div>
						<label>Groups</label>
					</div>
					<div>
						<ReactSelect
							closeOnSelect={true}
							disabled={defaultGroupEntries.length === 0}
							multi
							onChange={(value) => {
								this.setState({ valueGroups: value });
								if (onChange) {
									onChange({ groups: value });
								}
							}}
							options={defaultGroupEntries}
							placeholder="Select groups"
							removeSelected={true}
							value={valueGroups}
							simpleValue
						/>
					</div>
				</div>;
		}

		const valueMembers = externalValueManagement ? members : this.state.valueMembers;
		return (
			<div>

				{groupSelect}

				{!this.props.hideLabel && <div style={{ marginTop: groupSelect ? 25 : 0 }}>
					<label>Members</label>
				</div>}
				<div>
					<ReactSelect
						closeOnSelect={true}
						multi
						onChange={(value) => {
							this.setState({ valueMembers: value });
							if (onChange) {
								onChange({ members: value });
							}
						}}
						disabled={defaultMemberEntries.length === 0}
						options={defaultMemberEntries}
						placeholder="Select members"
						removeSelected={true}
						value={valueMembers}
						simpleValue
						menuPosition={"fixed"}
					/>
				</div>
				{!this.props.hideButton &&
					<div style={{ marginTop: 25 }}>
						<Button
							onClick={() => {
								this.props.onShare(this.state.initialValueGroups, this.state.initialValueMembers, this.state.valueGroups, this.state.valueMembers);
								this.props.onClose();
							}}>Apply</Button>
					</div>}
			</div>
		);
	}
}

MemberGroupSelect.propTypes = {
	defaultMembers: PropTypes.array,
	defaultGroups: PropTypes.array
};

export default MemberGroupSelect;
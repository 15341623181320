import _ from "lodash";
import moment from "moment";
import request from "superagent";

import env from "../constants/env";
import PlayerPaths from "../constants/paths/playerPaths";
import * as inventoryActions from "./inventoryActions";

import { fetchInventory, fetchDrive } from "./portalActions";
import * as navActions from "./navActions";
import * as algolia from "../search/algolia";
import { showError, showInfo, showYesNo } from "./alertActions";
import state from "../state/state";
import { generateUUID, getUtcTimestamp } from "../utils/utils";
import browserHistory from "../history";
import * as userActions from "./user";
import { refreshCredentials } from "../utils/securityUtil";
import { initializeDrive } from "./drive";
import { addActivityInstance, closeDetailedStatusModal } from "./actions";
import { markAssignmentSubmitted, getAssignmentScheduleForCurrentUser } from "./assignmentActions";
import { initializeWorkflows, initializeWorkflowParticipants } from "./workflowActions";
import { sendLearningEvent } from "./healthStream";
import naturalCompare from "string-natural-compare";

export function initializePlayer(inventory) {
	console.log("Algolia search");
	return algolia.searchUserActivities().then((content) => {
		console.log("Algolia search (2)");
		state.set(PlayerPaths.ACTIVITIES, inventory);
		let sessions = {};

		_.each(content.hits, (hit) => {

			if (_.isUndefined(sessions[hit.id])) {
				sessions[hit.id] = [];
			}
			sessions[hit.id].push(hit);
		});


		_.each(sessions, (activitySessions, id) => {
			sessions[id] = _.orderBy(activitySessions, ["updatedDateTimeDate"], ["desc"]);
		});
		console.log("Refresh Sessions", sessions);
		state.set(PlayerPaths.SESSIONS, sessions);
		state.set(PlayerPaths.ACTIVITIES_LOADED, true);
		state.set(PlayerPaths.RELOAD_ACTIVITIES, false);

	}).catch((err) => {
		console.error("Algolia search (2)", err);
		throw err;
	});
}

export function loadActivities(baseInventory = null) {
	return inventoryActions.assembleActivitiesForWeb(baseInventory).then((result) => {
		state.set(PlayerPaths.ACTIVITIES, result);

		return algolia.searchUserActivities().then((content) => {
			let sessions = {};

			_.each(content.hits, (hit) => {

				if (_.isUndefined(sessions[hit.id])) {
					sessions[hit.id] = [];
				}
				sessions[hit.id].push(hit);
			});


			_.each(sessions, (activitySessions, id) => {
				sessions[id] = _.orderBy(activitySessions, ["updatedDateTimeDate"], ["desc"]);
			});

			state.set(PlayerPaths.SESSIONS, sessions);
			state.set(PlayerPaths.ACTIVITIES_LOADED, true);
			state.set(PlayerPaths.RELOAD_ACTIVITIES, false);

		}).catch((err) => {
			throw err;
		});

	}).catch((err) => {
		console.error(err);
	});

}

export function showPlayerSpinner() {
	state.set(PlayerPaths.SHOW_SPINNER, true);
	state.set(["appState", "documents", "showSpinner"], true);
}

export function hidePlayerSpinner() {
	state.set(PlayerPaths.SHOW_SPINNER, false);
	state.set(["appState", "documents", "showSpinner"], false);
}

/**
 *  Reload activities due to add/remove
 */
export function reloadActivities(refreshAssignments = false, playlistId) {
	return new Promise((resolve, reject) => {
		showPlayerSpinner();

		setTimeout(() => {

			refreshCredentials().then(async () => {
				try {
					// const inventoryRes = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.inventory}`).set({ Authorization: userActions.getJwtToken() });
					// const { inventory } = inventoryRes.body;
					// const driveRes = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.drive}`).set({ Authorization: userActions.getJwtToken() });
					// const { drive } = driveRes.body;

					const jwt = userActions.getJwtToken();
					const inventoryRes = await fetchInventory(userActions.getJwtToken(), playlistId);
					const { inventory, workflows, workflowParticipants } = inventoryRes;
					const drive = await fetchDrive(jwt);
					//const inventoryRes = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.inventory}?includeDrive=true`).set({ Authorization: userActions.getJwtToken() });
					//const { inventory, drive, workflows, workflowParticipants } = inventoryRes.body;

					inventoryActions.initializeInventoryV2(inventory);
					initializePlayer(inventory);
					await initializeDrive(drive);
					await initializeWorkflows(workflows);
					await initializeWorkflowParticipants(workflowParticipants);
					if (refreshAssignments) {
						await getAssignmentScheduleForCurrentUser();
					}

					hidePlayerSpinner();
					resolve();
				} catch (err) {
					hidePlayerSpinner();
					console.error("An error occurred while attempting to fetch inventory", err);
					showError("Load Activities", "Unable to load activities, this might be due to network connectivity issues.");
					reject(err);
				}

			});
		}, 2000);

	});
	// return new Promise((resolve, reject) => {
	// 	inventoryActions.initializeInventory(true).then(async (inventory) => {
	// 		loadActivities(inventory).then(() => {
	// 			tree.set(PlayerPaths.RELOAD_ACTIVITIES, false);
	// 			hidePlayerSpinner();
	// 			resolve();
	// 		}).catch((err) => {
	// 			console.error("Load Activities", err, err.stack);
	// 			showError("Load Activities", "Unable to load activities, this might be due to network connectivity issues.");
	// 			hidePlayerSpinner();
	// 			reject(err);
	// 		});
	// 	});
	// });
}

export function reloadActivitiesFast(playlistId) {
	return new Promise((resolve, reject) => {
		showPlayerSpinner();

		refreshCredentials().then(async () => {
			try {
				const inventoryRes = await fetchInventory(userActions.getJwtToken(), playlistId);
				const { inventory } = inventoryRes;

				console.log("!@!@!@ INVENTORY", inventory);

				inventoryActions.initializeInventoryV2(inventory);
				initializePlayer(inventory);

				hidePlayerSpinner();
				resolve();
			} catch (err) {
				hidePlayerSpinner();
				console.error("An error occurred while attempting to fetch inventory", err);
				showError("Load Activities", "Unable to load activities, this might be due to network connectivity issues.");
				reject(err);
			}

		});
	});
	// return new Promise((resolve, reject) => {
	// 	inventoryActions.initializeInventory(true).then(async (inventory) => {
	// 		loadActivities(inventory).then(() => {
	// 			tree.set(PlayerPaths.RELOAD_ACTIVITIES, false);
	// 			hidePlayerSpinner();
	// 			resolve();
	// 		}).catch((err) => {
	// 			console.error("Load Activities", err, err.stack);
	// 			showError("Load Activities", "Unable to load activities, this might be due to network connectivity issues.");
	// 			hidePlayerSpinner();
	// 			reject(err);
	// 		});
	// 	});
	// });
}

function getFirstAssignmentForActivity(activityId) {
	let assignment = null;

	const assignmentsSchedule = state.get(["assignments", "schedule"]);

	if (_.isArray(assignmentsSchedule) && assignmentsSchedule.length > 0) {
		const findAssignments = assignmentsSchedule.filter((item) => {
			return activityId === item.activityId;
		})

		// Found at least one
		if (findAssignments.length > 0) {
			// Now filter out submitted ones
			const notSubmittedAssignments = findAssignments.filter((item) => {
				return (!item.submitted || item.submitted === "")
			});

			notSubmittedAssignments.sort((a, b) => naturalCompare(a.dueDate, b.dueDate));

			const submittedAssignments = findAssignments.filter((item) => {
				return (item.submitted && item.submitted !== "")
			});

			submittedAssignments.sort((a, b) => naturalCompare(a.dueDate, b.dueDate));
			submittedAssignments.reverse();

			// No more assigmnents left
			// if (notSubmittedAssignments.length === 0) {
			// 	navActions.navigateTo("home");

			// 	setTimeout(() => {
			// 		showInfo("Assignment", "The activity <b>" + submittedAssignments[0].activityName + "</b> was an assignment and was submitted on <b>" + new Date(submittedAssignments[0].submitted).toLocaleString() + "</b>. You have no more assignments to submit.");
			// 	}, 1000);
			// 	return;
			// }

			if (notSubmittedAssignments.length > 0) {
				// Set assignment to first one due that hasn't been completed yet 
				assignment = notSubmittedAssignments[0];
			}
		}
	}

	return assignment;
}

function getAssignmentForInstance(instanceId) {
	let assignment = null;

	const assignmentsSchedule = state.get(["assignments", "schedule"]);

	const findAssignment = assignmentsSchedule.find((item) => {
		return instanceId === item.instanceId;
	})

	if (findAssignment) {
		assignment = findAssignment;
	}

	return assignment;
}

export async function startNewActivity(tree, activityId, initialItemId, instanceId = null, assignment = null, sessionCheck = false) {//eslint-disable-line
	console.info("!@#!@# startNewActivity", activityId, instanceId);
	let url = null;

	let _instanceId = instanceId ? instanceId : generateUUID();

	if (assignment === null) {
		assignment = getFirstAssignmentForActivity(activityId);
	}

	// Let's see if this activity is in inventory
	// If not let's auto-add it
	let activities = tree.get(["activities"]);
	activities = activities.filter((item) => {
		return !item.newDoc;
	})
	const hasActivity = activities.find((item) => {
		return item.id === activityId;
	});

	if (!hasActivity) {
		try {
			const activityMetaData = await getActivityMetadata(activityId);
			activities.push(activityMetaData);
			tree.set(PlayerPaths.ACTIVITIES, activities);
			console.info("!@#!@# SET ACTIVITIES", activities);
		} catch (err) {
			navActions.navigateTo("home");

			setTimeout(() => {
				showError("Error Loading Activity", "The activity could not be found.");
			}, 1000);

			return;
		}
	}

	if (instanceId === null && sessionCheck && !assignment) {
		// See if have at least one session
		let sessions = tree.get(PlayerPaths.SESSIONS_BY_ACTIVITY_ID(activityId));
		let sessionsInstanceId = null;
		let hasSession = false;

		console.info("Sessions", sessions);

		if (!_.isUndefined(sessions) && sessions.length >= 1) {
			hasSession = true;
			//look up URL from sessions
			console.info("Sessions 0", sessions[0]);
			url = sessions[0].url;
			console.info("url", url);
			sessionsInstanceId = sessions[0].instanceId;
			console.info("instanceId", sessionsInstanceId);
		}

		if (hasSession) {
			console.info('HERE!');
			// Prompt to resume or start new
			showYesNo("You already have a session in progress for this activity. Do you want to resume?\n\nNOTE: A new session will be created if you answer No.", "Resume Activity", (yes) => {
				if (yes) {
					_instanceId = sessionsInstanceId;


					tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
					tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, _instanceId);
					const path = "/player/" + activityId + "/" + _instanceId;
					browserHistory.push(path);
				} else {
					// Still figure out how to handle assignment!!!

					tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
					tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, _instanceId);
					const path = "/player/" + activityId + "/" + _instanceId;
					browserHistory.push(path);
				}
			});
		} else {
			tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
			tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, _instanceId);
			if (assignment) {
				const { assignmentId } = assignment;
				console.log("Setting assignmentId", assignment, assignmentId);
				tree.set(PlayerPaths.SELECTED_ACTIVITY_ASSIGNMENT_ID, assignmentId);
			}
			const path = "/player/" + activityId + "/" + _instanceId;
			browserHistory.push(path);

		}
	} else {
		if (assignment) {
			const { assignmentId } = assignment;
			console.log("Setting assignmentId", assignment, assignmentId);
			tree.set(PlayerPaths.SELECTED_ACTIVITY_ASSIGNMENT_ID, assignmentId);
			_instanceId = assignment.instanceId;
		}

		tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
		tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, _instanceId);
		const path = "/player/" + activityId + "/" + _instanceId;
		browserHistory.push(path);
	}

}

export async function resumeActivity(tree, activityId, activityInstanceId, /*initialItemId, */url = null, assignment = null, sessionCheck = false) {
	console.info("!@#!@# resumeNewActivity", activityId, activityInstanceId, url);

	// Need to get assignment with that instanceId
	if (assignment === null) {
		assignment = getAssignmentForInstance(activityInstanceId);
	}

	// Let's see if this activity is in inventory
	// If not let's auto-add it
	let activities = tree.get(["activities"]);
	activities = activities.filter((item) => {
		return !item.newDoc;
	})
	const hasActivity = activities.find((item) => {
		return item.id === activityId;
	});

	if (!hasActivity) {
		const activityMetaData = await getActivityMetadata(activityId);
		activities.push(activityMetaData);
		tree.set(PlayerPaths.ACTIVITIES, activities);
	}

	// Need to set this right before doing redirect using browserHistory
	//tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
	//tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, activityInstanceId);

	if (assignment) {
		if (assignment.submitted && assignment.submitted !== "") {
			navActions.navigateTo("home");

			setTimeout(() => {
				showInfo("Assignment", "The activity <b>" + assignment.activityName + "</b> was an assignment and was submitted on <b>" + new Date(assignment.submitted).toLocaleString() + "</b>.");
			}, 1000);
			return;
		} else {
			// Set this at the last possible moment for resume so player is only rendered once
			tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
			tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, activityInstanceId);

			const { assignmentId } = assignment;
			tree.set(PlayerPaths.SELECTED_ACTIVITY_ASSIGNMENT_ID, assignmentId);

			console.info("!@#!@# resuming activity", activityId, activityInstanceId, url);
			const path = "/player/" + activityId + "/" + activityInstanceId;

			browserHistory.push(path);

			return;
		}
	}

	if (url === null) {
		//look up URL from sessions
		let sessions = tree.get(PlayerPaths.SESSIONS_BY_ACTIVITY_ID(activityId));
		console.info("!@#!@# SESSIONS", sessions);
		if (!_.isUndefined(sessions)) {
			let index = _.findIndex(sessions, { id: activityId, instanceId: activityInstanceId });
			if (index !== -1) {
				url = sessions[index].url;
			}
		}
	}

	if (url !== null) {
		// Set this at the last possible moment for resume so player is only rendered once
		tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);
		tree.set(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID, activityInstanceId);

		console.info("!@#!@# resuming activity", activityId, activityInstanceId, url);
		const path = "/player/" + activityId + "/" + activityInstanceId;

		browserHistory.push(path);
	} else {
		//no activity session found
		tree.set(PlayerPaths.SHOW_SPINNER, false);

		console.info(`!@#!@# Session not found when trying to resume activityId: ${activityId}, instanceId: ${activityInstanceId}, starting new activity`);
		startNewActivity(tree, activityId, null, null, null, sessionCheck);

		// navActions.navigateTo("home");
	}
}

export function showSessionsModal(tree, activityId) {

	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, activityId);

	sortSessions(tree, "updatedDateTimeDate");

	tree.set(PlayerPaths.SHOW_SESSIONS_MODAL, true);
}

export function closeSessionsModal(tree) {
	tree.set(PlayerPaths.SELECTED_ACTIVITY_ID, "");
	tree.set(PlayerPaths.SHOW_SESSIONS_MODAL, false);
}

export function getSelectedActivityId() {
	return state.get(PlayerPaths.SELECTED_ACTIVITY_ID);
}

export function sortSessions(tree, sortCriteria) {
	console.log("handle sort " + sortCriteria);

	let allActivitySessions = tree.get(PlayerPaths.SESSIONS);
	let selectedActivityId = tree.get(PlayerPaths.SELECTED_ACTIVITY_ID);

	if (!_.isUndefined(allActivitySessions[selectedActivityId])) {
		const now = moment();

		let sessions = allActivitySessions[selectedActivityId];
		let instanceDefinitions = sessions.map(function (instance, j) {
			const instanceDefiniton = _.cloneDeep(instance);
			instanceDefiniton.index = j;
			instanceDefiniton.today = false;
			const rawCreatedDate = instance.hasOwnProperty("createdDateTime") ? moment.utc(instance.createdDateTime * 1000).local() : null;
			let createdDateTime = null;
			if (rawCreatedDate) {
				createdDateTime = rawCreatedDate.local().format("MMMM Do YYYY, h: mm: ss a");
				instanceDefiniton.rawCreatedDate = rawCreatedDate;
				instanceDefiniton.createdDateTimeDate = rawCreatedDate.toDate();
			}
			instanceDefiniton.createdDateTimeFormatted = createdDateTime;
			const rawUpdatedDate = instance.hasOwnProperty("lastUpdatedDateTime") ? moment.utc(instance.lastUpdatedDateTime * 1000).local() : null;
			let updatedDateTime = null;
			if (rawUpdatedDate) {
				updatedDateTime = rawUpdatedDate.local().format("MMMM Do YYYY, h: mm: ss a");
				instanceDefiniton.today = now.isSame(rawUpdatedDate, "day");
				instanceDefiniton.updatedDateTimeDate = rawUpdatedDate.toDate();
			}
			instanceDefiniton.updatedDateTimeFormatted = updatedDateTime;
			return instanceDefiniton;
		});

		let sortedInstanceDefinitions = [];
		instanceDefinitions.forEach(instanceDefinition => {
			if (instanceDefinition.hasOwnProperty(sortCriteria) && instanceDefinition[sortCriteria] !== null) {
				sortedInstanceDefinitions.push(instanceDefinition);
			}
		});

		sortedInstanceDefinitions = _.reverse(_.sortBy(sortedInstanceDefinitions, [sortCriteria]));
		//console.log("SORTED", sortCriteria, sortedInstanceDefinitions);

		_.reverse(instanceDefinitions).forEach(instanceDefinition => {
			if (!instanceDefinition.hasOwnProperty(sortCriteria) || instanceDefinition[sortCriteria] === null) {
				sortedInstanceDefinitions.push(instanceDefinition);
			}
		});

		tree.set(PlayerPaths.SORTED_SESSIONS, sortedInstanceDefinitions);
	} else {
		tree.set(PlayerPaths.SORTED_SESSIONS, []);
	}
}

export function setSelectedHomeTab(tree, tab) {
	tree.set(PlayerPaths.SELECTED_HOME_TAB, tab);
}

export async function handleWebPlayerExit(tree, event) {
	// Test for sending learning event to HealthStream
	// try {
	// 	const resLearningEvent = await sendLearningEvent("alternatetri2", "", "12345", "Test Course", "", 100);
	// } catch (err) {
	// 	alert("There was a problem posting a learning event to HealthStream");
	// }


	try {
		if (event && event.submitInfo && event.submitInfo.submit) {
			const activityId = tree.get(PlayerPaths.SELECTED_ACTIVITY_ID);
			const assignmentId = tree.get(PlayerPaths.SELECTED_ACTIVITY_ASSIGNMENT_ID);
			const instanceId = tree.get(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID);
			const { submitted } = event.submitInfo;
			console.log("Submitting assignment", activityId, assignmentId, instanceId, submitted);
			addActivityInstance(activityId, {
				id: instanceId,
				lastUpdatedDate: getUtcTimestamp().replace(" ", "T"),
				sync: true,
				submitted
			}, true);
			markAssignmentSubmitted(assignmentId, instanceId, submitted);
		}

		// If editing in Status Detail then close dialog
		if (event.statusDetailSave) {
			closeDetailedStatusModal(tree);
		} else {
			// await reloadActivities();
			if (event.sessions) {
				tree.set(PlayerPaths.SESSIONS, event.sessions);
			}
			tree.set(PlayerPaths.SHOW_SESSIONS_MODAL, false);
			navActions.navigateTo("home");
		}

	} catch (e) {
		console.error("Failed to handle web player exit", e);
	} finally {
		clearSelectedActivityState();
	}
}

async function getActivityMetadata(activityId) {
	const activityMetaData = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.artifacts.getActivityMetadata(activityId)}`).set({ Authorization: userActions.getJwtToken() });
	return JSON.parse(activityMetaData.body.metaData);
}

export function clearSelectedActivityState() {
	state.unset(PlayerPaths.SELECTED_ACTIVITY_ID);
	state.unset(PlayerPaths.SELECTED_ACTIVITY_INSTANCE_ID);
	state.unset(PlayerPaths.SELECTED_ACTIVITY_ASSIGNMENT_ID);
}
import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";

import TileNode from "./tileNode";
import styles from "./tilePanel.module.css";

const ITEMS_CONTAINER_STYLE_CLASS = "items-container";
const ITEMS_CONTAINER_STYLE_CLASS_THIN = "items-container-thin";

const ICON_FONT_SIZE = "10vw";
const ICON_FONT_SIZE_THIN = "6vw";

class TilePanel extends PureComponent {

	constructor(props) {
		super(props);
		this.containerRef = React.createRef();
		this.resizeObserver = null;

		this.state = {
			itemsContainerStyleClass: ITEMS_CONTAINER_STYLE_CLASS,
			iconFontSize: ICON_FONT_SIZE
		}

		autoBind(this);
	}

	componentDidMount() {
		this.resizeObserver = new ResizeObserver((entries) => {
			if (entries.length > 0) {
				let entry = entries[0];
				let width = entry.contentRect.width;
				if (width < 400) {
					this.setState({
						itemsContainerStyleClass: ITEMS_CONTAINER_STYLE_CLASS_THIN,
						iconFontSize: ICON_FONT_SIZE_THIN
					});
				} else {
					this.setState({
						itemsContainerStyleClass: ITEMS_CONTAINER_STYLE_CLASS,
						iconFontSize: ICON_FONT_SIZE
					});
				}
			}
		});

		if (this.containerRef?.current) {
			this.resizeObserver.observe(this.containerRef.current);
		}
	}

	componentWillUnmount() {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	}

	render() {

		const { data, onSelect, onDblClick, accept } = this.props;

		const children = data ?? [];
		const n = children.length;

		let folders = [];
		let files = [];

		for (let i = 0; i < n; i++) {
			let item = children[i];

			if (item.type === "folder") {
				folders.push(<TileNode node={item} onSelect={onSelect} onDblClick={onDblClick} accept={accept} iconFontSize={this.state.iconFontSize} />);
			} else if (item.type === "file") {
				files.push(<TileNode node={item} onSelect={onSelect} onDblClick={onDblClick} accept={accept} iconFontSize={this.state.iconFontSize} />);
			}
		}

		let foldersComponent = (folders.length > 0) ?
			<div>
				<div className={styles["item-group-header"]}>Folders</div>
				<div className={styles[this.state.itemsContainerStyleClass]} ref={this.foldersContainerRef}>{folders}</div>
			</div>
			: null;

		let filesComponent = (files.length > 0) ?
			<div>
				<div className={styles["item-group-header"]}>Files</div>
				<div className={styles[this.state.itemsContainerStyleClass]}>{files}</div>
			</div>
			: null;

		let components =
			<div ref={this.containerRef}>
				{foldersComponent}
				{filesComponent}
				{(children.length === 0) ? <div className="message-container">{"No items to view"}</div> : null}
			</div>

		return components
	}
}

export default branch({}, TilePanel);
import request from "superagent";
import env from "../constants/env";
import { showError } from "../actions/alertActions";

export async function updateUser(jwt, userId, name, realId, profilePicture, role, identityId) {
	return new Promise(async (resolve, reject) => {
		try {
			await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.streamChat.updateUser}?userId=${userId}&name=${name}&realId=${realId}&profilePicture=${profilePicture}&role=${role}&identityId=${identityId}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			resolve();
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}

export async function updateChannelName(jwt, channelId, channelName) {
	return new Promise(async (resolve, reject) => {
		try {
			await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.streamChat.updateChannelName}?channelId=${channelId}&channelName=${channelName}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			resolve();
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}

export async function enableChannel(jwt, channelId) {
	return new Promise(async (resolve, reject) => {
		try {
			await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.streamChat.enableChannel}?channelId=${channelId}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			resolve();
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}

export async function disableChannel(jwt, channelId) {
	return new Promise(async (resolve, reject) => {
		try {
			const url = `${env.apiGateway.baseUrl}${env.apiGateway.endpoints.streamChat.disableChannel}?channelId=${channelId}`;
			debugger;
			await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.streamChat.disableChannel}?channelId=${channelId}`)
				.set("Authorization", jwt)
				.set({ "Accept": "application/json" });

			resolve();
		} catch (err) {
			console.error(err);
			showError("Error", err.message);
			reject(err);
		}
	});
}
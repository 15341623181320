

import _ from "lodash";

import { syncTimerTemplates } from "../actions/inventoryActions";
import env from "../constants/env";
import browserHistory from "../history";
import { getJsonFileFromS3, printTemplate, putJsonFileToS3, saveHistory, sendEmail } from "../persistence/s3";
import state from "../state/state";
import { refreshCredentials } from "../utils/securityUtil";
import {
	getLs,
	normalizeActivity,
	setFullSessionName,
	recurseReplaceSpeeds,
	recurseConvertChecklistToMarkdown,
	getQueryStringParam,
} from "../utils/utils";

import { showError, showSuccess } from "./alertActions";
import { getDriveObjectsAsMap } from "./drive";
import { assembleActivitiesForMobile } from "./inventoryActions";
import { updateItunesSubscription, logout } from "./portalActions";
import { getIdentityId, getUsername, isOrganizationUser } from "./user";
import { getOrgInfo } from "./orgsActions";
import { navigateTo } from "./navActions";

import { publishActivityToSignedUrls } from "./publish";
import { getAssignmentScheduleForCurrentUser } from "./assignmentActions";

export function initializePostMessageListeners() {
	// WKWebView
	window.receivedMessageFromRN = async (e) => {
		console.log("receivedMessage: " + JSON.stringify(e));

		let obj = e;
		if (Array.isArray(obj)) {
			console.log("Call from mobile device (receivedMessageFromRN)", obj);
			// Compile checklists to send back to mobile app 
			refreshCredentials().then(() => {
				// sendInventoryAndUserInfoMessage().then(() => {
				return saveHistoryInstances(obj, {}, true);
				// }).catch((err) => {
				// postMessage({ "error": "Get checklists failed: " + err.message });
				// });
			}).catch(err => {
				postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
			});


		} else if (obj.hasOwnProperty("itunesSubscription")) {
			let sub = e;
			let itunesSubscription = {
				plan: sub.itunesSubscription,
				expiration: sub.subscriptionExpiration,
				transactionId: sub.subscriptionTransactionId
			};
			refreshCredentials().then(() => {
				updateItunesSubscription(itunesSubscription);
			}).catch(err => {
				postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
			});
		} else if (obj.hasOwnProperty("deviceId")) {
			let device = e;
			state.set(["device"], device);
		} else if (obj.hasOwnProperty("timerTemplates")) {
			const clientTimerTemplates = obj.timerTemplates;
			const serverTimerTemplates = await getJsonFileFromS3(env.s3.contentBucket, "timerTemplates.json");
			const timerTemplates = syncTimerTemplates(clientTimerTemplates, serverTimerTemplates);

			// Save to s3
			await putJsonFileToS3(env.s3.contentBucket, "timerTemplates.json", timerTemplates);
			if (obj.refreshChecklists) {
				refreshCredentials().then(() => {
					sendInventoryAndUserInfoMessage().then(() => {
					}).catch((err) => {
						postMessage({ "error": "Get checklists failed: " + err.message });
					});
				}).catch(err => {
					postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
				});
			}
		} else if (obj.hasOwnProperty("type") && obj.type === "checklist") {
			refreshCredentials().then(() => {
				saveHistoryInstances([obj]);
			}).catch(err => {
				postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
			});
		} else if (obj.hasOwnProperty("method")) {
			switch (obj.method) {
				case "sendEmail": {
					await sendEmail(obj.toAddresses, obj.subject, obj.body, obj.htmlBody, obj.ccAddresses, obj.senderEmail, obj.replyToAddresses, obj.charset);
					break;
				}
				case "setPlaylistId": {
					refreshCredentials().then(() => {
						sendInventoryAndUserInfoMessage(null, obj.id).then(() => {
						}).catch((err) => {
							postMessage({ "error": "Get checklists failed: " + err.message });
						});
					}).catch(err => {
						postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
					});
					break;
				}
				case "navigateTo": {
					navigateTo(obj.id);
					break;
				}
				case "logout": {
					logout();
					break;
				}
				case "refreshAssignments": {
					getAssignmentScheduleForCurrentUser().then(assignments => {
						sendAssignmentsMessage(assignments);
					});
					break;
				}
				case "saveInCameraRoll": {
					if (obj.success) {
						showSuccess("Save Media Asset", "Successfully saved media asset in camera roll.");
					} else {
						showError("Save Media Asset", "Local save of media asset failed, reason:" + obj.err);
					}
					break;
				}
				case "printInstance": {
					console.log("!*!*!* printInstance");
					// Set previewChecklist
					let jsonChecklist = obj.data;

					let lastTimestamp = (jsonChecklist.history[0].timestamp).replace(" ", "T");
					let fileName = jsonChecklist.id + "/" + lastTimestamp + "_" + jsonChecklist.instanceId + ".json";

					jsonChecklist = await publishActivityToSignedUrls(jsonChecklist, fileName);

					// Should convert to make sure has at least one list and one section and set proper type
					jsonChecklist = normalizeActivity(jsonChecklist);
					jsonChecklist = setFullSessionName(jsonChecklist);

					recurseReplaceSpeeds(jsonChecklist.children);
					recurseConvertChecklistToMarkdown(jsonChecklist.children, false);

					printTemplate(obj.name, jsonChecklist, obj.templateId, obj.callbackJsonToken);
					break;
				}
				default: {
					console.log("Nothing to do");
				}
			}
		} else {

			let user = e;

			let savePassword = getLs(user.username + ";savePassword");

			if (savePassword === null) {
				if (user.hasOwnProperty("sharedDevice")) {
					savePassword = !user.sharedDevice;
					state.set(["user", "sharedDevice"], user.sharedDevice);
				} else {
					savePassword = true;
				}
			}

			state.set(["user", "username"], user.username);
			if (savePassword) {
				state.set(["user", "password"], user.password);
			}
			state.set(["user", "savePassword"], savePassword);

			let rememberMe = getLs(user.username + ";rememberMe");
			if (rememberMe === null) {
				if (user.hasOwnProperty("sharedDevice")) {
					rememberMe = !user.sharedDevice;
					state.set(["user", "sharedDevice"], user.sharedDevice);
				} else {
					rememberMe = true;
				}
			}

			state.set(["user", "rememberMe"], rememberMe);

			console.log("index.js: " + JSON.stringify(state.get(["user"])));

			const path = "/login";
			browserHistory.push(path);
		}
	};

	window.addEventListener("message", (e) => {
		console.log("!@#!@# WINDOW LISTENER");
		console.log("!@#!@# MESSAGE", e.data);
		try {
			if (e.data) {
				const data = JSON.parse(e.data);
				if (data.hasOwnProperty("pricing") && data.pricing.hasOwnProperty("invoiceItems")) {
					// Need to cache this data and then save it to the activity if click Go Back on popup
					state.set(["appState", "showPricing", "invoiceItemsTemp"], data.pricing.invoiceItems);
					state.set(["appState", "showPricing", "estimatesTemp"], data.pricing.estimates);
					state.set(["appState", "showPricing", "jobDetailsTemp"], data.pricing.jobDetails);
					state.set(["appState", "showPricing", "selectedValueTemp"], data.pricing.selectedValue);

					console.log("!@#!@# SHOW PRICING", state.get(["appState", "showPricing"]));
				}
			}
		} catch (err) {

		}
	})

	// UIWebView
	document.addEventListener("message", async (e) => {
		console.log("!@#!@# DOCUMENT LISTENER");
		let obj = JSON.parse(e.data);
		if (Array.isArray(obj)) {
			console.log("Call from mobile device (receivedMessageFromRN)", obj);
			// Compile checklists to send back to mobile app 
			refreshCredentials().then(() => {
				// sendInventoryAndUserInfoMessage().then(() => {
				return saveHistoryInstances(obj, {}, true);
				// }).catch((err) => {
				// postMessage({ "error": "Get checklists failed: " + err.message });
				// });
			}).catch(err => {
				postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
			});
		} else if (obj.hasOwnProperty("pricing")) {
			console.log("!@#!@# MESSAGE 2", JSON.stringify(obj));
		} else if (obj.hasOwnProperty("deviceId")) {
			let device = obj;
			state.set(["device"], device);
		} else if (obj.hasOwnProperty("timerTemplates")) {
			const clientTimerTemplates = obj.timerTemplates;
			const serverTimerTemplates = await getJsonFileFromS3(env.s3.contentBucket, "timerTemplates.json");
			const timerTemplates = syncTimerTemplates(clientTimerTemplates, serverTimerTemplates);

			// Save to s3
			await putJsonFileToS3(env.s3.contentBucket, "timerTemplates.json", timerTemplates);
			if (obj.refreshChecklists) {
				refreshCredentials().then(() => {
					sendInventoryAndUserInfoMessage().then(() => {
					}).catch((err) => {
						postMessage({ "error": "Get checklists failed: " + err.message });
					});
				}).catch(err => {
					postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
				});
			}
		} else if (obj.hasOwnProperty("type") && obj.type === "checklist") {
			refreshCredentials().then(() => {
				saveHistoryInstances([obj]);
			}).catch(err => {
				postMessage({ "error": "Operation failed, unable to refresh security credentials: " + err.message });
			});
		} else if (obj.hasOwnProperty("method")) {
			switch (obj.method) {
				case "navigateTo": {
					navigateTo(obj.id);
					break;
				}
				case "logout": {
					logout();
					break;
				}
				case "refreshAssignments": {
					getAssignmentScheduleForCurrentUser().then(assignments => {
						sendAssignmentsMessage(assignments);
					});
					break;
				}
				case "saveInCameraRoll": {
					if (obj.success) {
						showSuccess("Save Media Asset", "Successfully saved media asset in camera roll.");
					} else {
						showError("Save Media Asset", "Local save of media asset failed, reason:" + obj.err);
					}
					break;
				}
				case "printInstance": {
					// Set previewChecklist
					let jsonChecklist = obj.data;

					let lastTimestamp = (jsonChecklist.history[0].timestamp).replace(" ", "T");
					let fileName = jsonChecklist.id + "/" + lastTimestamp + "_" + jsonChecklist.instanceId + ".json";

					jsonChecklist = await publishActivityToSignedUrls(jsonChecklist, fileName);

					// Should convert to make sure has at least one list and one section and set proper type
					jsonChecklist = normalizeActivity(jsonChecklist);
					jsonChecklist = setFullSessionName(jsonChecklist);

					recurseReplaceSpeeds(jsonChecklist.children);
					recurseConvertChecklistToMarkdown(jsonChecklist.children, false);

					printTemplate(obj.name, jsonChecklist, obj.templateId, obj.callbackJsonToken);
					break;
				}
				default: {
					console.log("Nothing to do");
				}
			}
		} else {
			let user = obj;

			let savePassword = getLs(user.username + ";savePassword");

			if (savePassword === null) {
				if (user.hasOwnProperty("sharedDevice")) {
					savePassword = !user.sharedDevice;
					state.set(["user", "sharedDevice"], user.sharedDevice);
				} else {
					savePassword = true;
				}
			}

			state.set(["user", "username"], user.username);
			if (savePassword) {
				state.set(["user", "password"], user.password);
			}
			state.set(["user", "savePassword"], savePassword);

			let rememberMe = getLs(user.username + ";rememberMe");
			if (rememberMe === null) {
				if (user.hasOwnProperty("sharedDevice")) {
					rememberMe = !user.sharedDevice;
					state.set(["user", "sharedDevice"], user.sharedDevice);
				} else {
					rememberMe = true;
				}
			}

			state.set(["user", "rememberMe"], rememberMe);

			console.log("index.js: " + JSON.stringify(state.get(["user"])));

			const path = "/login";
			browserHistory.push(path);
		}
	});
}

export function sendOrganizationInfoMessage(orgInfos) {
	const info = _.pick(_.first(orgInfos), ["id", "name", "logoUrl", "preferences"]);
	let message = {
		type: "org-info",
		info
	};

	postMessage(message);
}

export function sendAssignmentsMessage(assignments) {
	// newChecklists is what pass to mobile app. Aggregate users, content and checklists
	let message = {
		type: "assignemnts-message",
		assignments
	};

	postMessage(message);
}

export function saveHistoryInstances(obj, overrides = {}, refreshChecklists = false) {
	// Array of instances to save to s3
	let instances = obj;
	let files = [];
	let errLog = [];

	//			awsS3.logToCloudWatch(instances);

	for (let i = 0; i < instances.length; i++) {
		//alert("Error " + i);
		try {
			let instance = instances[i];
			let lastTimestamp = (instance.history[0].timestamp).replace(" ", "T");
			let fileName = instance.id + "/" + lastTimestamp + "_" + instance.instanceId + ".json";

			if (overrides.displayName) {
				instance.displayName = overrides.displayName;
			} else {
				const member = state.get(["selectedOrg", "member"]);
				instance.displayName = member.displayName;
			}

			files.push({ fileName: fileName, fileContent: instance });
		} catch (e) {
			errLog.push(e);
		}
	}

	if (errLog.length > 0) {
		//alert(JSON.stringify(errLog,null,2));
	}

	return saveHistory(env.s3.contentBucket, files, overrides.keyPrefixOverride, refreshChecklists);
}

export function sendInventoryAndUserInfoMessage(inventory = null, playlistId) {
	return new Promise(async (resolve, reject) => {
		// newChecklists is what pass to mobile app. Aggregate users, content and checklists
		let message = {
			user: getUserInfo(),
			timerTemplates: [],
			checklists: [],
		};

		// Get timerTemplates
		try {
			const serverTimerTemplates = await getJsonFileFromS3(env.s3.contentBucket, "timerTemplates.json");
			message.timerTemplates = serverTimerTemplates;
		} catch (err) {
			console.log(err.message);
		}

		const apiVersion = getQueryStringParam("app-api-version");

		if (isOrganizationUser() && apiVersion >= 8) {
			console.log("About to get org info");
			const orgInfo = await getOrgInfo();
			console.log("Got org info", orgInfo);
			// if (orgInfo) {
			message.orgInfo = orgInfo;
			// } else {
			// 	message.orgInfo = "None";
			// }

			// Send any env props
			let mobileAppEnv = {
				algolia: env.algolia
			}
			message.env = mobileAppEnv;

			postMessage(message);
			resolve();
		} else {
			assembleActivitiesForMobile(inventory, playlistId).then(async (mobileInventory) => {
				// Now retrieve the content map to allow resolving of external resources on the fly
				// *** Going to fetch directly from mobile app
				if (apiVersion < 6) {
					message.externalResources = getDriveObjectsAsMap();
				}

				console.log("!#!#!# MOBILE INVENTORY", mobileInventory);

				// Add filter for invisible checklists
				const resultMobileInventory = mobileInventory.filter(activity => {
					if (activity.dependency) {
						return true;
					} else {
						if (!activity.hasOwnProperty("visible")) {
							return true;
						} else {
							return activity.visible;
						}
					}
				});

				console.log("!#!#!# RESULT MOBILE INVENTORY", resultMobileInventory);

				// const resultMobileInventory = mobileInventory.filter(activity => activity.visible !== false);

				message.checklists = resultMobileInventory;
				console.log("About to get org info");
				const orgInfo = await getOrgInfo();
				console.log("Got org info", orgInfo);
				// if (orgInfo) {
				message.orgInfo = orgInfo;
				// } else {
				// 	message.orgInfo = "None";
				// }

				// Send any env props
				let mobileAppEnv = {
					algolia: env.algolia
				}
				message.env = mobileAppEnv;

				postMessage(message);
				resolve();

			}).catch(err => {
				reject(err);
			});
		}
	});
}

export function sendTokenUpdatedMessage(token) {
	state.set(["user", "jwt"], token.jwt);
	postMessage({ method: "tokenUpdated", token });
}

export function sendLogoutMessage() {
	postMessage({ method: "logout" });
}

export function sendHideModalEditorMessage() {
	postMessage({ method: "hideModalEditor" });
}

export function sendDummyMessage() {
	postMessage({ method: "dummy" });
}

export function sendSaveInCameraRollMessage(content, fileName) {
	postMessage({
		method: "saveInCameraRoll",
		content,
		fileName
	});
}

export function sendRestoreSession(restore) {
	postMessage({ method: "restoreSession", restore });
}

export function sendLoginStartedMessage() {

}

export function sendLoginFinishedMessage() {

}

export function sendSyncStartMessage() {

}

export function sendSyncFinishedMessage() {

}

let embedded = false;

export function setEmbeddedAppState(_embedded) {
	embedded = _embedded;
}


export function postMessage(message, embed = embedded) {
	let success = false;


	if (window.ReactNativeWebView) {
		try {
			// console.log("About to post message", message, embed ? "*" : window.location.href);
			// const target = embed ? parent : window; //eslint-disable-line
			// target.ReactNativeWebView.postMessage(JSON.stringify(message), embed ? "*" : window.location.href);
			window.ReactNativeWebView.postMessage(JSON.stringify(message));
			success = true;
		} catch (e) {
			console.error(e);
		}
	} else if (window.postMessage) {
		try {
			console.log("About to post message", message, embed ? "*" : window.location.href);
			const target = embed ? parent : window; //eslint-disable-line
			target.postMessage(JSON.stringify(message), embed ? "*" : window.location.href);
			success = true;
		} catch (e) {
			console.error(e);
		}
	} else if (window.webView && window.webView.postMessage) {
		try {
			window.webView.postMessage(JSON.stringify(message));
			success = true;
		} catch (e) {
			console.error(e);
		}
	}

	return success;
}


function getUserInfo() {
	const user = state.get(["user"]);
	return {
		username: getUsername(),
		scr: state.get(["user", "password"]),
		savePassword: getLs(state.get(["user", "username"]) + ";savePassword"),
		rememberMe: getLs(state.get(["user", "username"]) + ";rememberMe"),
		identityId: getIdentityId(),
		subscriptionPlan: user.subscriptionPlan,
		subscriptionExpiration: user.hasOwnProperty("subscriptionExpiration") ? user.subscriptionExpiration : 0,
		member: state.get(["selectedOrg", "member"])
	};
}
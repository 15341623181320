import AWS from "aws-sdk";
import _ from "lodash";

import { refreshCredentials } from "./securityUtil";

export function getAllQuery(params) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			try {
				let results = [];
				const docClient = new AWS.DynamoDB.DocumentClient();
				docClient.query(params, async (err, data) => {
					if (err) {
						console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
						reject(err.message);
					} else {
						results = _.concat(results, data.Items);
						let loop = data.hasOwnProperty("LastEvaluatedKey");
						let ExclusiveStartKey = data.LastEvaluatedKey;
						console.log("GOT data", data.Items.length, ExclusiveStartKey);
						while (loop) {
							let newParams = params;
							newParams.ExclusiveStartKey = ExclusiveStartKey;
							await new Promise((resolve, reject) => {
								docClient.query(newParams, (err, data) => {
									if (err) {
										console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
										reject("Unable to query. Error:\n\n" + err.message);
									} else {
										results = _.concat(results, data.Items);
										loop = data.hasOwnProperty("LastEvaluatedKey");
										ExclusiveStartKey = data.LastEvaluatedKey;
										resolve();
									}
								});
							});
						}
					}
					resolve(results);
				});
			} catch (err) {
				reject(err);
			}

		});
	});
}
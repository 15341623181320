import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";

import ListNode from "./listNode";

class ListPanel extends PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		const { data, onSelect, onDblClick, accept, mode } = this.props;

		let children = data ?? [];

		let components = null;

		const items = [];
		const n = children.length;
		for (let i = 0; i < n; i++) {
			items.push(<ListNode node={children[i]} onSelect={onSelect} onDblClick={onDblClick} accept={accept} />);
		}
		components = (children.length > 0) ?
			<div>
				{items}
			</div> : <div className="message-container">{"No items to view"}</div>

		return components;
	}
}

export default branch({}, ListPanel);
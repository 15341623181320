import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";

import ListPanel from "./listPanel";
import TilePanel from "./tilePanel";

class ListView extends PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		const { data, onSelect, onDblClick, mode, accept } = this.props;

		let children = data.children ?? [];

		let components = null;

		switch (mode) {
			case "list": {
				components = <TilePanel data={children} onSelect={onSelect} onDblClick={onDblClick} accept={accept} />
				break;
			}
			default: {
				components = <ListPanel data={children} onSelect={onSelect} onDblClick={onDblClick} accept={accept} />
			}
		}

		return <div className={`ambifi-tree ${mode}`}>
			{components}
		</div>;
	}

}


export default branch({}, ListView);
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Glyphicon from '../app-icon';

export function SortableKenBurnsEffectPropertyItem(props) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: props.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	return (
		<div ref={setNodeRef} style={style} onClick={() => {
			props.onSelectItem(props.kenBurnsEffect);
		}}>
			<div style={{ display: "flex", userSelect: "none", width: "100%" }}>
				<div style={{ marginRight: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => {
					props.onToggleKenBurnsEffectVisible();
				}}>
					<Glyphicon style={{ fontSize: "12px", color: "#444444" }} glyph={!props.kenBurnsEffect.hasOwnProperty("canvasVisible") || props.kenBurnsEffect.canvasVisible ? "eye" : "eye-slash"} />
				</div>
				<div tabIndex={props.tabIndex} style={{ cursor: "pointer", flex: 1, backgroundColor: props.selected ? "lightgreen" : null }} onKeyDown={(event) => {
					event.preventDefault();
					let charCode = String.fromCharCode(event.which).toLowerCase();
					if ((event.ctrlKey || event.metaKey) && charCode === 's') {
					} else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
						props.onClone();
					} else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
					}
				}}>
					{props.kenBurnsEffect.id}
				</div>
				{/* <div style={{ marginRight: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => {
					props.onDeleteCamera();
				}}>
					<Glyphicon style={{ fontSize: "16px", color: "#444444" }} glyph="trash" />
				</div> */}
				<div {...attributes} {...listeners} style={{ flex: 0, cursor: "grab", userSelect: "none" }}>
					<Glyphicon style={{ fontSize: "16px", color: "#444444" }} glyph="sort" />
				</div>
			</div>
		</div>
	);
}
import React, { Component } from "react";
import { branch } from "baobab-react/higher-order";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reportError } from "../../persistence/s3";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		reportError(error, errorInfo);
		// You can also log error messages to an error reporting service here
	}

	render() {
		const { showSpinner } = this.props;
		if (this.state.errorInfo) {
			// Error path
			return (
				<div style={{ backgroundColor: "#ffcccb", padding: "10px", borderRadius: "10px" }}>
					<h3 style={{ margin: "5px" }}>Something went wrong <FontAwesomeIcon icon={["far", "frown"]} /></h3>
					<hr />
					<p>We apologize for the inconvenience, this issue will be reported automatically so we can resolve it as quick as possible.</p>
					{showSpinner && <div style={{ margin: "15px" }}><FontAwesomeIcon
						icon='spinner'
						spin /> Uploading error report...</div>}
					<details style={{ whiteSpace: "pre-wrap" }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}


export default branch({
	showSpinner: ["appState", "errorSpinner", "show"]
}, ErrorBoundary);
import _ from "lodash";
import { getActivityContent } from "./activityActions";
import { getActivityInventory } from "./inventoryActions";
import { extractUrlFromLinkText } from "../utils/activity";
import state from "../state/state";

export function getLinkInfo(link) {
	return extractUrlFromLinkText(link);
}

export function isValidLinkAction(action) {
	let valid = false;
	state.get(["linkActionTypes"]).forEach(linkActionType => {
		if (linkActionType.action === action) {
			valid = true;
		}
	});
	return valid;
}

export function getLinkActionType(action) {
	let result = null;
	state.get(["linkActionTypes"]).forEach(linkActionType => {
		if (linkActionType.action === action) {
			result = linkActionType;
		}
	});
	return result;
}

export function buildLink(linkAction, linkTarget) {
	return `${linkAction}:${linkTarget}`;
}
export function buildLinkFromLinkInfo(linkInfo) {
	const { actionType } = linkInfo;
	const linkTarget = getLinkTargetFromLinkInfo(linkInfo);

	return buildLink(actionType, linkTarget);
}

export function getLinkTargetFromLinkInfo(linkInfo) {
	console.log("getLinkTargetFromLinkInfo ", linkInfo);
	const { targetActivityId, targetId, queryString, externalUrl } = linkInfo;
	let linkTarget = "";

	if (externalUrl) {
		linkTarget += externalUrl;
	} else {
		if (targetActivityId) {
			linkTarget += targetActivityId;
		}

		if (targetId) {
			linkTarget += `#${targetId}`;
		}
	}


	if (queryString && !_.isEmpty(queryString)) {
		if (_.isString(queryString)) {
			linkTarget += `?${queryString}`;
		} else if (_.isPlainObject(queryString)) {
			const qsParts = [];
			_.keys(queryString).forEach(key => {
				qsParts.push(`${key}=${queryString[key]}`);
			});
			linkTarget += `?${qsParts.join("&")}`;
		}
	}

	return linkTarget;
}
/**
 * Will return all "checklistId" mapping to communicate which activities can be linked to
 */
export function getActivityLinks() {
	const result = {};

	getActivityInventory().forEach(activityInventoryEntry => {
		const { checklistId } = activityInventoryEntry;
		if (checklistId && checklistId !== "") {
			result[checklistId] = activityInventoryEntry;
		}

	});

	return result;
}

/**
 * Will return all internal link targets for a given activity
 * @param {*} activityId 
 */
export function getInternalLinks(activityId) {

	return new Promise(async (resolve, reject) => {
		try {
			let result = [];
			const activity = await getActivityContent(activityId);
			if (activity && _.isPlainObject(activity)) {
				console.log("Fetched activity and now extracting links", activity);
				result = getInternalLinksFromActivity(activity);
				console.log("Links extracted", result);
			}
			resolve(result);
		} catch (err) {
			reject(err);
		}
	});

}

export function getInternalLinksFromActivity(activity) {
	let result = {};
	if (activity && _.isPlainObject(activity)) {
		parseLinkTargets(activity, result);
	}
	return result;
}

function parseLinkTargets(node, idMap) {
	if (node.hasOwnProperty("type")) {
		const nodeType = node.type;
		if (nodeType === "list" || nodeType === "section" || nodeType.indexOf("item") === 0) {
			if (node.hasOwnProperty("id") && !_.isEmpty(node.id) && node.id) {
				idMap[node.id] = node;
			}

		}
	}

	if (node.hasOwnProperty("children") && _.isArray(node.children)) {
		node.children.forEach(child => {
			parseLinkTargets(child, idMap);
		});
	}
}
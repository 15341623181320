import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAmbulance as faAmbulanceRegular,
	faArrowCircleDown as faArrowCircleDownRegular,
	faArrowCircleUp as faArrowCircleUpRegular,
	faArrowDown as faArrowDownRegular,
	faArrowLeft as faArrowLeftRegular,
	faArrowRight as faArrowRightRegular,
	faArrowUp as faArrowUpRegular,
	faArrows as faArrowsRegular,
	faBallotCheck as faBallotCheckRegular,
	faBan as faBanRegular,
	faBarcode as faBarcodeRegular,
	faBeer as faBeerRegular,
	faBicycle as faBicycleRegular,
	faBook as faBookRegular,
	faBooks as faBooksRegular,
	faBuilding as faBuildingRegular,
	faCalendar as faCalendarRegular,
	faCalendarCheck as faCalendarCheckRegular,
	faCalendarMinus as faCalendarMinusRegular,
	faCalendarPlus as faCalendarPlusRegular,
	faCameraRetro as faCameraRetroRegular,
	faCar as faCarRegular,
	faCaretRight as faCaretRightRegular,
	faChartBar as faChartBarRegular,
	faCheck as faCheckRegular,
	faCheckCircle as faCheckCircleRegular,
	faCheckSquare as faCheckSquareRegular,
	faChevronDown as faChevronDownRegular,
	faChevronUp as faChevronUpRegular,
	faCircle as faCircleRegular,
	faClipboard as faClipboardRegular,
	faClock as faClockRegular,
	faClone as faCloneRegular,
	faCloud as faCloudRegular,
	faCloudUpload as faCloudUploadRegular,
	faCogs as faCogsRegular,
	faCopy as faCopyRegular,
	faCut as faCutRegular,
	faDotCircle as faDotCircleRegular,
	faDownload as faDownloadRegular,
	faEdit as faEditRegular,
	faEllipsisH as faEllipsisHRegular,
	faEllipsisV as faEllipsisVRegular,
	faEnvelope as faEnvelopeRegular,
	faEraser as faEraserRegular,
	faExclamationTriangle as faExclamationTriangleRegular,
	faExpand as faExpandRegular,
	faExpandWide as faExpandWideRegular,
	faExternalLink as faExternalLinkRegular,
	faExternalLinkSquare as faExternalLinkSquareRegular,
	faEye as faEyeRegular,
	faEyeSlash as faEyeSlashRegular,
	faFighterJet as faFighterJetRegular,
	faFile as faFileRegular,
	faFileAlt as faFileAltRegular,
	faFileArchive as faFileArchiveRegular,
	faFileAudio as faFileAudioRegular,
	faFileCode as faFileCodeRegular,
	faFileExcel as faFileExcelRegular,
	faFileExport as faFileExportRegular,
	faFileImage as faFileImageRegular,
	faFilePdf as faFilePdfRegular,
	faFileUpload as faFileUploadRegular,
	faFileUser as faFileUserRegular,
	faFileVideo as faFileVideoRegular,
	faFileWord as faFileWordRegular,
	faFilter as faFilterRegular,
	faFlask as faFlaskRegular,
	faFolder as faFolderRegular,
	faFolderOpen as faFolderOpenRegular,
	faFolderTree as faFolderTreeRegular,
	faFont as faFontRegular,
	faFrown as faFrownRegular,
	faFutbol as faFutbolRegular,
	faGamepad as faGamepadRegular,
	faGavel as faGavelRegular,
	faGlobe as faGlobeRegular,
	faGraduationCap as faGraduationCapRegular,
	faHdd as faHddRegular,
	faHeart as faHeartRegular,
	faHistory as faHistoryRegular,
	faHome as faHomeRegular,
	faHotel as faHotelRegular,
	faImage as faImageRegular,
	faInfoCircle as faInfoCircleRegular,
	faKey as faKeyRegular,
	faKeyboard as faKeyboardRegular,
	faList as faListRegular,
	faListAlt as faListAltRegular,
	faLocationArrow as faLocationArrowRegular,
	faLock as faLockRegular,
	faLockOpen as faLockOpenRegular,
	faMap as faMapRegular,
	faMapMarker as faMapMarkerRegular,
	faMinus as faMinusRegular,
	faMinusCircle as faMinusCircleRegular,
	faMinusSquare as faMinusSquareRegular,
	faMoneyBillAlt as faMoneyBillAltRegular,
	faMotorcycle as faMotorcycleRegular,
	faMusic as faMusicRegular,
	faObjectGroup as faObjectGroupRegular,
	faPaperPlane as faPaperPlaneRegular,
	faPaste as faPasteRegular,
	faPaw as faPawRegular,
	faPen as faPenRegular,
	faPencil as faPencilRegular,
	faPlane as faPlaneRegular,
	faPlay as faPlayRegular,
	faPlus as faPlusRegular,
	faPlusCircle as faPlusCircleRegular,
	faPlusSquare as faPlusSquareRegular,
	faPrint as faPrintRegular,
	faProjectDiagram as faProjectDiagramRegular,
	faPuzzlePiece as faPuzzlePieceRegular,
	faQuestion as faQuestionRegular,
	faQuestionCircle as faQuestionCircleRegular,
	faRedo as faRedoRegular,
	faRepeat as faRepeatRegular,
	faSave as faSaveRegular,
	faSearch as faSearchRegular,
	faShare as faShareRegular,
	faShareAlt as faShareAltRegular,
	faShareAltSquare as faShareAltSquareRegular,
	faShareSquare as faShareSquareRegular,
	faShip as faShipRegular,
	faShoppingBag as faShoppingBagRegular,
	faShoppingCart as faShoppingCartRegular,
	faSignOut as faSignOutRegular,
	faSitemap as faSitemapRegular,
	faSmile as faSmileRegular,
	faSort as faSortRegular,
	faSortAmountDown as faSortAmountDownRegular,
	faSpellCheck as faSpellCheckRegular,
	faSpinner as faSpinnerRegular,
	faSquare as faSquareRegular,
	faSync as faSyncRegular,
	faSyncAlt as faSyncAltRegular,
	faTable as faTableRegular,
	faTh as faThRegular,
	faThLarge as faThLargeRegular,
	faThumbsDown as faThumbsDownRegular,
	faThumbsUp as faThumbsUpRegular,
	faTicket as faTicketRegular,
	faTimes as faTimesRegular,
	faTimesCircle as faTimesCircleRegular,
	faTrash as faTrashRegular,
	faTree as faTreeRegular,
	faTv as faTvRegular,
	faUndo as faUndoRegular,
	faUpload as faUploadRegular,
	faUser as faUserRegular,
	faUserCircle as faUserCircleRegular,
	faUserPlus as faUserPlusRegular,
	faUsers as faUsersRegular,
	faUsersCog as faUsersCogRegular,
	faUtensilSpoon as faUtensilSpoonRegular,
	faUtensils as faUtensilsRegular,
	faVideo as faVideoRegular,
	faVolumeSlash as faVolumeSlashRegular,
	faVolumeUp as faVolumeUpRegular,
	faWifi as faWifiRegular,
	faWrench as faWrenchRegular,
	faFluxCapacitor as faFluxCapacitorRegular,
	faCube as faCubeRegular,
	faCamera as faCameraRegular,
	faMobileAlt as faMobileAltRegular,
	faCommentsAlt as faCommentsAltRegular,
	faPaperclip as faPaperclipRegular,
	faPhoneAlt as faPhoneAltRegular,
	faPhoneSlash as faPhoneSlashRegular,
	faRingsWedding as faRingsWeddingRegular,
	faInboxIn as faInboxInRegular,
	faInboxOut as faInboxOutRegular,
	faCubes as faCubesRegular,
	faConstruction as faConstructionRegular,
	faDrawSquare as faDrawSquareRegular,
	faGem as faGemRegular,
	faShippingFast as faShippingFastRegular,
	faChartLine as faChartLineRegular,
	faMagic as faMagicRegular,
	faStream as faStreamRegular,
	faMapMarkerExclamation as faMapMarkerExclamationRegular,
	faCommentAlt as faCommentAltRegular,
	faCommentAltSlash as faCommentAltSlashRegular,
	faTransporter2 as faTransporter2Regular,
	faPauseCircle as faPauseCircleRegular,
	faCommentAltDollar as faCommentAltDollarRegular,
	faMoneyCheckEdit as faMoneyCheckEditRegular,
	faMoneyCheckEditAlt as faMoneyCheckEditAltRegular,
	faDraftingCompass as faDraftingCompassRegular,
	faScanner as faScannerRegular,
	faLayerGroup as faLayerGroupRegular,
	faTreeAlt as faTreeAltRegular,
	faBurn as faBurnRegular,
	faClipboardListCheck as faClipboardListCheckRegular,
	faJedi as faJediRegular,
	faHandPaper as faHandPaperRegular,
	faConveyorBelt as faConveyorBeltRegular,
	faCrosshairs as faCrosshairsRegular,
	faHandSparkles as faHandSparklesRegular,
	faMicroscope as faMicroscopeRegular,
	faStarship as faStarshipRegular,
	faTruckPickup as faTruckPickupRegular,
	faInventory as faInventoryRegular,
	faVideoPlus as faVideoPlusRegular,
	faBug as faBugRegular,
	faDesktop as faDesktopRegular,
	faBooksMedical as faBooksMedicalRegular,
	faImages as faImagesRegular,
	faPhotoVideo as faPhotoVideoRegular,
	faClosedCaptioning as faClosedCaptioningRegular,
	faLaptopMedical as faLaptopMedicalRegular,
	faPersonCarry as faPersonCarryRegular,
	faSearchPlus as faSearchPlusRegular,
	faSearchMinus as faSearchMinusRegular,
	faArrowSquareDown as faArrowSquareDownRegular,
	faArrowSquareLeft as faArrowSquareLeftRegular,
	faArrowSquareRight as faArrowSquareRightRegular,
	faArrowSquareUp as faArrowSquareUpRegular,
	faAlignJustify as faAlignJustifyRegular,
	faAlignSlash as faAlignSlashRegular,
	faUndoAlt as faUndoAltRegular,
	faRedoAlt as faRedoAltRegular,
	faFingerprint as faFingerprintRegular,
	faHandPointUp as faHandPointUpRegular,
	faBoxCheck as faBoxCheckRegular,
	faCode as faCodeRegular,
	faStickyNote as faStickyNoteRegular,
	faLink as faLinkRegular,
	faSortAlphaDown as faSortAlphaDownRegular,
	faSortNumericDownAlt as faSortNumericDownAltRegular,
	faQrcode as faQrCodeRegular
} from "@fortawesome/pro-regular-svg-icons";

import {
	faAmbulance as faAmbulanceSolid,
	faArrowCircleDown as faArrowCircleDownSolid,
	faArrowCircleUp as faArrowCircleUpSolid,
	faArrowDown as faArrowDownSolid,
	faArrowLeft as faArrowLeftSolid,
	faArrowRight as faArrowRightSolid,
	faArrowUp as faArrowUpSolid,
	faArrows as faArrowsSolid,
	faBallotCheck as faBallotCheckSolid,
	faBan as faBanSolid,
	faBarcode as faBarcodeSolid,
	faBeer as faBeerSolid,
	faBicycle as faBicycleSolid,
	faBook as faBookSolid,
	faBooks as faBooksSolid,
	faBuilding as faBuildingSolid,
	faCalendar as faCalendarSolid,
	faCalendarCheck as faCalendarCheckSolid,
	faCalendarMinus as faCalendarMinusSolid,
	faCalendarPlus as faCalendarPlusSolid,
	faCameraRetro as faCameraRetroSolid,
	faCar as faCarSolid,
	faCaretRight as faCaretRightSolid,
	faChartBar as faChartBarSolid,
	faCheck as faCheckSolid,
	faCheckCircle as faCheckCircleSolid,
	faCheckSquare as faCheckSquareSolid,
	faChevronDown as faChevronDownSolid,
	faChevronUp as faChevronUpSolid,
	faCircle as faCircleSolid,
	faClipboard as faClipboardSolid,
	faClock as faClockSolid,
	faClone as faCloneSolid,
	faCloud as faCloudSolid,
	faCloudUpload as faCloudUploadSolid,
	faCogs as faCogsSolid,
	faCopy as faCopySolid,
	faCut as faCutSolid,
	faDotCircle as faDotCircleSolid,
	faDownload as faDownloadSolid,
	faEdit as faEditSolid,
	faEllipsisH as faEllipsisHSolid,
	faEllipsisV as faEllipsisVSolid,
	faEnvelope as faEnvelopeSolid,
	faEraser as faEraserSolid,
	faExpand as faExpandSolid,
	faExpandWide as faExpandWideSolid,
	faExclamationTriangle as faExclamationTriangleSolid,
	faExternalLink as faExternalLinkSolid,
	faExternalLinkSquare as faExternalLinkSquareSolid,
	faEye as faEyeSolid,
	faEyeSlash as faEyeSlashSolid,
	faFighterJet as faFighterJetSolid,
	faFile as faFileSolid,
	faFileAlt as faFileAltSolid,
	faFileArchive as faFileArchiveSolid,
	faFileAudio as faFileAudioSolid,
	faFileCode as faFileCodeSolid,
	faFileExcel as faFileExcelSolid,
	faFileExport as faFileExportSolid,
	faFileImage as faFileImageSolid,
	faFilePdf as faFilePdfSolid,
	faFileUpload as faFileUploadSolid,
	faFileUser as faFileUserSolid,
	faFileVideo as faFileVideoSolid,
	faFileWord as faFileWordSolid,
	faFilter as faFilterSolid,
	faFlask as faFlaskSolid,
	faFolder as faFolderSolid,
	faFolderOpen as faFolderOpenSolid,
	faFolderTree as faFolderTreeSolid,
	faFont as faFontSolid,
	faFrown as faFrownSolid,
	faFutbol as faFutbolSolid,
	faGamepad as faGamepadSolid,
	faGavel as faGavelSolid,
	faGlobe as faGlobeSolid,
	faGraduationCap as faGraduationCapSolid,
	faHdd as faHddSolid,
	faHeart as faHeartSolid,
	faHistory as faHistorySolid,
	faHome as faHomeSolid,
	faHotel as faHotelSolid,
	faImage as faImageSolid,
	faInfoCircle as faInfoCircleSolid,
	faKey as faKeySolid,
	faKeyboard as faKeyboardSolid,
	faList as faListSolid,
	faListAlt as faListAltSolid,
	faLocationArrow as faLocationArrowSolid,
	faLock as faLockSolid,
	faLockOpen as faLockOpenSolid,
	faMap as faMapSolid,
	faMapMarker as faMapMarkerSolid,
	faMinus as faMinusSolid,
	faMinusCircle as faMinusCircleSolid,
	faMinusSquare as faMinusSquareSolid,
	faMoneyBillAlt as faMoneyBillAltSolid,
	faMotorcycle as faMotorcycleSolid,
	faMusic as faMusicSolid,
	faObjectGroup as faObjectGroupSolid,
	faPaperPlane as faPaperPlaneSolid,
	faPaste as faPasteSolid,
	faPaw as faPawSolid,
	faPen as faPenSolid,
	faPencil as faPencilSolid,
	faPlane as faPlaneSolid,
	faPlay as faPlaySolid,
	faPlus as faPlusSolid,
	faPlusCircle as faPlusCircleSolid,
	faPlusSquare as faPlusSquareSolid,
	faPrint as faPrintSolid,
	faProjectDiagram as faProjectDiagramSolid,
	faPuzzlePiece as faPuzzlePieceSolid,
	faQuestion as faQuestionSolid,
	faQuestionCircle as faQuestionCircleSolid,
	faRedo as faRedoSolid,
	faRepeat as faRepeatSolid,
	faSave as faSaveSolid,
	faSearch as faSearchSolid,
	faShare as faShareSolid,
	faShareAlt as faShareAltSolid,
	faShareAltSquare as faShareAltSquareSolid,
	faShareSquare as faShareSquareSolid,
	faShip as faShipSolid,
	faShoppingBag as faShoppingBagSolid,
	faShoppingCart as faShoppingCartSolid,
	faSignOut as faSignOutSolid,
	faSitemap as faSitemapSolid,
	faSmile as faSmileSolid,
	faSort as faSortSolid,
	faSortAmountDown as faSortAmountDownSolid,
	faSpellCheck as faSpellCheckSolid,
	faSpinner as faSpinnerSolid,
	faSquare as faSquareSolid,
	faSync as faSyncSolid,
	faSyncAlt as faSyncAltSolid,
	faTable as faTableSolid,
	faTh as faThSolid,
	faThLarge as faThLargeSolid,
	faThumbsDown as faThumbsDownSolid,
	faThumbsUp as faThumbsUpSolid,
	faTicket as faTicketSolid,
	faTimes as faTimesSolid,
	faTimesCircle as faTimesCircleSolid,
	faTrash as faTrashSolid,
	faTree as faTreeSolid,
	faTv as faTvSolid,
	faUndo as faUndoSolid,
	faUpload as faUploadSolid,
	faUser as faUserSolid,
	faUserCircle as faUserCircleSolid,
	faUserPlus as faUserPlusSolid,
	faUsers as faUsersSolid,
	faUsersCog as faUsersCogSolid,
	faUtensilSpoon as faUtensilSpoonSolid,
	faUtensils as faUtensilsSolid,
	faVideo as faVideoSolid,
	faVolumeSlash as faVolumeSlashSolid,
	faVolumeUp as faVolumeUpSolid,
	faWifi as faWifiSolid,
	faWrench as faWrenchSolid,
	faFluxCapacitor as faFluxCapacitorSolid,
	faCube as faCubeSolid,
	faCamera as faCameraSolid,
	faMobileAlt as faMobileAltSolid,
	faCommentsAlt as faCommentsAltSolid,
	faPaperclip as faPaperclipSolid,
	faPhoneAlt as faPhoneAltSolid,
	faPhoneSlash as faPhoneSlashSolid,
	faRingsWedding as faRingsWeddingSolid,
	faInboxIn as faInboxInSolid,
	faInboxOut as faInboxOutSolid,
	faCubes as faCubesSolid,
	faConstruction as faConstructionSoild,
	faDrawSquare as faDrawSquareSolid,
	faGem as faGemSolid,
	faShippingFast as faShippingFastSolid,
	faChartLine as faChartLineSolid,
	faMagic as faMagicSolid,
	faStream as faStreamSolid,
	faMapMarkerExclamation as faMapMarkerExclamationSolid,
	faCommentAlt as faCommentAltSolid,
	faCommentAltSlash as faCommentAltSlashSolid,
	faTransporter2 as faTransporter2Solid,
	faPauseCircle as faPauseCircleSolid,
	faCommentAltDollar as faCommentAltDollarSolid,
	faMoneyCheckEdit as faMoneyCheckEditSolid,
	faMoneyCheckEditAlt as faMoneyCheckEditAltSolid,
	faDraftingCompass as faDraftingCompassSolid,
	faScanner as faScannerSolid,
	faLayerGroup as faLayerGroupSolid,
	faTreeAlt as faTreeAltSolid,
	faBurn as faBurnSolid,
	faClipboardListCheck as faClipboardListCheckSolid,
	faJedi as faJediSolid,
	faHandPaper as faHandPaperSolid,
	faConveyorBelt as faConveyorBeltSolid,
	faCrosshairs as faCrosshairsSolid,
	faHandSparkles as faHandSparklesSolid,
	faMicroscope as faMicroscopeSolid,
	faStarship as faStarshipSolid,
	faTruckPickup as faTruckPickupSolid,
	faInventory as faInventorySolid,
	faVideoPlus as faVideoPlusSolid,
	faBug as faBugSolid,
	faDesktop as faDesktopSolid,
	faBooksMedical as faBooksMedicalSolid,
	faImages as faImagesSolid,
	faPhotoVideo as faPhotoVideoSolid,
	faClosedCaptioning as faClosedCaptioningSolid,
	faLaptopMedical as faLaptopMedicalSolid,
	faPersonCarry as faPersonCarrySolid,
	faSearchPlus as faSearchPlusSolid,
	faSearchMinus as faSearchMinusSolid,
	faArrowSquareDown as faArrowSquareDownSolid,
	faArrowSquareLeft as faArrowSquareLeftSolid,
	faArrowSquareRight as faArrowSquareRightSolid,
	faArrowSquareUp as faArrowSquareUpSolid,
	faAlignJustify as faAlignJustifySolid,
	faAlignSlash as faAlignSlashSolid,
	faUndoAlt as faUndoAltSolid,
	faRedoAlt as faRedoAltSolid,
	faFingerprint as faFingerprintSolid,
	faHandPointUp as faHandPointUpSolid,
	faBoxCheck as faBoxCheckSolid,
	faCode as faCodeSolid,
	faStickyNote as faStickyNoteSolid,
	faLink as faLinkSolid,
	faSortAlphaDown as faSortAlphaDownSolid,
	faSortNumericDownAlt as faSortNumericDownAltSolid,
	faQrcode as faQrCodeSolid
} from "@fortawesome/pro-solid-svg-icons";


library.add(
	faAmbulanceRegular,
	faAmbulanceSolid,
	faArrowCircleDownRegular,
	faArrowCircleDownSolid,
	faArrowCircleUpRegular,
	faArrowCircleUpSolid,
	faArrowDownRegular,
	faArrowDownSolid,
	faArrowLeftRegular,
	faArrowLeftSolid,
	faArrowRightRegular,
	faArrowRightSolid,
	faArrowUpRegular,
	faArrowUpSolid,
	faArrowsRegular,
	faArrowsSolid,
	faBallotCheckRegular,
	faBallotCheckSolid,
	faBanRegular,
	faBanSolid,
	faBarcodeRegular,
	faBarcodeSolid,
	faBeerRegular,
	faBeerSolid,
	faBicycleRegular,
	faBicycleSolid,
	faBookRegular,
	faBookSolid,
	faBooksRegular,
	faBooksSolid,
	faBuildingRegular,
	faBuildingSolid,
	faCalendarRegular,
	faCalendarSolid,
	faCalendarCheckRegular,
	faCalendarCheckSolid,
	faCalendarMinusRegular,
	faCalendarMinusSolid,
	faCalendarPlusRegular,
	faCalendarPlusSolid,
	faCameraRetroRegular,
	faCameraRetroSolid,
	faCarRegular,
	faCarSolid,
	faCaretRightRegular,
	faCaretRightSolid,
	faChartBarRegular,
	faChartBarSolid,
	faCheckRegular,
	faCheckSolid,
	faCheckCircleRegular,
	faCheckCircleSolid,
	faCheckSquareRegular,
	faCheckSquareSolid,
	faChevronDownRegular,
	faChevronDownSolid,
	faChevronUpRegular,
	faChevronUpSolid,
	faCircleRegular,
	faCircleSolid,
	faClipboardRegular,
	faClipboardSolid,
	faClockRegular,
	faClockSolid,
	faCloneRegular,
	faCloneSolid,
	faCloudRegular,
	faCloudSolid,
	faCloudUploadRegular,
	faCloudUploadSolid,
	faCogsRegular,
	faCogsSolid,
	faCopyRegular,
	faCopySolid,
	faCutRegular,
	faCutSolid,
	faDotCircleRegular,
	faDotCircleSolid,
	faDownloadRegular,
	faDownloadSolid,
	faEditRegular,
	faEditSolid,
	faEllipsisHRegular,
	faEllipsisHSolid,
	faEllipsisVRegular,
	faEllipsisVSolid,
	faEnvelopeRegular,
	faEnvelopeSolid,
	faEraserRegular,
	faEraserSolid,
	faExclamationTriangleRegular,
	faExclamationTriangleSolid,
	faExpandRegular,
	faExpandSolid,
	faExpandWideRegular,
	faExpandWideSolid,
	faExternalLinkRegular,
	faExternalLinkSolid,
	faExternalLinkSquareRegular,
	faExternalLinkSquareSolid,
	faEyeRegular,
	faEyeSolid,
	faEyeSlashRegular,
	faEyeSlashSolid,
	faFighterJetRegular,
	faFighterJetSolid,
	faFileRegular,
	faFileSolid,
	faFileAltRegular,
	faFileAltSolid,
	faFileArchiveRegular,
	faFileArchiveSolid,
	faFileAudioRegular,
	faFileAudioSolid,
	faFileCodeRegular,
	faFileCodeSolid,
	faFileExcelRegular,
	faFileExcelSolid,
	faFileExportRegular,
	faFileExportSolid,
	faFileImageRegular,
	faFileImageSolid,
	faFilePdfRegular,
	faFilePdfSolid,
	faFileUploadRegular,
	faFileUploadSolid,
	faFileUserRegular,
	faFileUserSolid,
	faFileVideoRegular,
	faFileVideoSolid,
	faFileWordRegular,
	faFileWordSolid,
	faFilterRegular,
	faFilterSolid,
	faFlaskRegular,
	faFlaskSolid,
	faFolderRegular,
	faFolderSolid,
	faFolderOpenRegular,
	faFolderOpenSolid,
	faFolderTreeRegular,
	faFolderTreeSolid,
	faFontRegular,
	faFontSolid,
	faFrownRegular,
	faFrownSolid,
	faFutbolRegular,
	faFutbolSolid,
	faGamepadRegular,
	faGamepadSolid,
	faGavelRegular,
	faGavelSolid,
	faGlobeRegular,
	faGlobeSolid,
	faGraduationCapRegular,
	faGraduationCapSolid,
	faHddRegular,
	faHddSolid,
	faHeartRegular,
	faHeartSolid,
	faHistoryRegular,
	faHistorySolid,
	faHomeRegular,
	faHomeSolid,
	faHotelRegular,
	faHotelSolid,
	faImageRegular,
	faImageSolid,
	faInfoCircleRegular,
	faInfoCircleSolid,
	faKeyRegular,
	faKeySolid,
	faKeyboardRegular,
	faKeyboardSolid,
	faListRegular,
	faListSolid,
	faListAltRegular,
	faListAltSolid,
	faLocationArrowRegular,
	faLocationArrowSolid,
	faLockRegular,
	faLockSolid,
	faLockOpenRegular,
	faLockOpenSolid,
	faMapRegular,
	faMapSolid,
	faMapMarkerRegular,
	faMapMarkerSolid,
	faMinusRegular,
	faMinusSolid,
	faMinusCircleRegular,
	faMinusCircleSolid,
	faMinusSquareRegular,
	faMinusSquareSolid,
	faMoneyBillAltRegular,
	faMoneyBillAltSolid,
	faMotorcycleRegular,
	faMotorcycleSolid,
	faMusicRegular,
	faMusicSolid,
	faObjectGroupRegular,
	faObjectGroupSolid,
	faPaperPlaneRegular,
	faPaperPlaneSolid,
	faPasteRegular,
	faPasteSolid,
	faPawRegular,
	faPawSolid,
	faPenRegular,
	faPenSolid,
	faPencilRegular,
	faPencilSolid,
	faPlaneRegular,
	faPlaneSolid,
	faPlayRegular,
	faPlaySolid,
	faPlusRegular,
	faPlusSolid,
	faPlusCircleRegular,
	faPlusCircleSolid,
	faPlusSquareRegular,
	faPlusSquareSolid,
	faPrintRegular,
	faPrintSolid,
	faProjectDiagramRegular,
	faProjectDiagramSolid,
	faPuzzlePieceRegular,
	faPuzzlePieceSolid,
	faQuestionRegular,
	faQuestionSolid,
	faQuestionCircleRegular,
	faQuestionCircleSolid,
	faRedoRegular,
	faRedoSolid,
	faRepeatRegular,
	faRepeatSolid,
	faSaveRegular,
	faSaveSolid,
	faSearchRegular,
	faSearchSolid,
	faShareRegular,
	faShareSolid,
	faShareAltRegular,
	faShareAltSolid,
	faShareAltSquareRegular,
	faShareAltSquareSolid,
	faShareSquareRegular,
	faShareSquareSolid,
	faShipRegular,
	faShipSolid,
	faShoppingBagRegular,
	faShoppingBagSolid,
	faShoppingCartRegular,
	faShoppingCartSolid,
	faSignOutRegular,
	faSignOutSolid,
	faSitemapRegular,
	faSitemapSolid,
	faSmileRegular,
	faSmileSolid,
	faSortRegular,
	faSortSolid,
	faSortAmountDownRegular,
	faSortAmountDownSolid,
	faSpellCheckRegular,
	faSpellCheckSolid,
	faSpinnerRegular,
	faSpinnerSolid,
	faSquareRegular,
	faSquareSolid,
	faSyncRegular,
	faSyncSolid,
	faSyncAltRegular,
	faSyncAltSolid,
	faTableRegular,
	faTableSolid,
	faThRegular,
	faThSolid,
	faThLargeRegular,
	faThLargeSolid,
	faThumbsDownRegular,
	faThumbsDownSolid,
	faThumbsUpRegular,
	faThumbsUpSolid,
	faTicketRegular,
	faTicketSolid,
	faTimesRegular,
	faTimesSolid,
	faTimesCircleRegular,
	faTimesCircleSolid,
	faTrashRegular,
	faTrashSolid,
	faTreeRegular,
	faTreeSolid,
	faTvRegular,
	faTvSolid,
	faUndoRegular,
	faUndoSolid,
	faUploadRegular,
	faUploadSolid,
	faUserRegular,
	faUserSolid,
	faUserCircleRegular,
	faUserCircleSolid,
	faUserPlusRegular,
	faUserPlusSolid,
	faUsersRegular,
	faUsersSolid,
	faUsersCogRegular,
	faUsersCogSolid,
	faUtensilSpoonRegular,
	faUtensilSpoonSolid,
	faUtensilsRegular,
	faUtensilsSolid,
	faVideoRegular,
	faVideoSolid,
	faVolumeSlashRegular,
	faVolumeSlashSolid,
	faVolumeUpRegular,
	faVolumeUpSolid,
	faWifiRegular,
	faWifiSolid,
	faWrenchRegular,
	faWrenchSolid,
	faFluxCapacitorRegular,
	faFluxCapacitorSolid,
	faCubeRegular,
	faCubeSolid,
	faCameraRegular,
	faCameraSolid,
	faMobileAltRegular,
	faMobileAltSolid,
	faCommentsAltRegular,
	faCommentsAltSolid,
	faPaperclipRegular,
	faPaperclipSolid,
	faPhoneAltRegular,
	faPhoneAltSolid,
	faPhoneSlashRegular,
	faPhoneSlashSolid,
	faRingsWeddingRegular,
	faRingsWeddingSolid,
	faInboxInRegular,
	faInboxInSolid,
	faInboxOutRegular,
	faInboxOutSolid,
	faCubesRegular,
	faCubesSolid,
	faConstructionRegular,
	faConstructionSoild,
	faDrawSquareRegular,
	faDrawSquareSolid,
	faGemRegular,
	faGemSolid,
	faShippingFastRegular,
	faShippingFastSolid,
	faChartLineRegular,
	faChartLineSolid,
	faMagicRegular,
	faMagicSolid,
	faStreamRegular,
	faStreamSolid,
	faMapMarkerExclamationRegular,
	faMapMarkerExclamationSolid,
	faCommentAltRegular,
	faCommentAltSolid,
	faCommentAltSlashRegular,
	faCommentAltSlashSolid,
	faTransporter2Regular,
	faTransporter2Solid,
	faTransporter2Regular,
	faPauseCircleRegular,
	faPauseCircleSolid,
	faCommentAltDollarRegular,
	faCommentAltDollarSolid,
	faMoneyCheckEditRegular,
	faMoneyCheckEditSolid,
	faMoneyCheckEditAltRegular,
	faMoneyCheckEditAltSolid,
	faDraftingCompassRegular,
	faDraftingCompassSolid,
	faScannerRegular,
	faScannerSolid,
	faLayerGroupRegular,
	faLayerGroupSolid,
	faTreeAltRegular,
	faTreeAltSolid,
	faBurnRegular,
	faBurnSolid,
	faClipboardListCheckRegular,
	faClipboardListCheckSolid,
	faHandPaperRegular,
	faHandPaperSolid,
	faCrosshairsRegular,
	faCrosshairsSolid,
	faHandSparklesRegular,
	faHandSparklesSolid,
	faMicroscopeRegular,
	faMicroscopeSolid,
	faStarshipRegular,
	faStarshipSolid,
	faTruckPickupRegular,
	faTruckPickupSolid,
	faJediRegular,
	faJediSolid,
	faConveyorBeltRegular,
	faConveyorBeltSolid,
	faInventoryRegular,
	faInventorySolid,
	faVideoPlusRegular,
	faVideoPlusSolid,
	faBugRegular,
	faBugSolid,
	faDesktopRegular,
	faDesktopSolid,
	faBooksMedicalRegular,
	faBooksMedicalSolid,
	faImagesRegular,
	faImagesSolid,
	faPhotoVideoRegular,
	faPhotoVideoSolid,
	faClosedCaptioningRegular,
	faClosedCaptioningSolid,
	faLaptopMedicalRegular,
	faLaptopMedicalSolid,
	faPersonCarryRegular,
	faPersonCarrySolid,
	faSearchPlusRegular,
	faSearchPlusSolid,
	faSearchMinusRegular,
	faSearchMinusSolid,
	faArrowSquareDownRegular,
	faArrowSquareDownSolid,
	faArrowSquareLeftRegular,
	faArrowSquareLeftSolid,
	faArrowSquareRightRegular,
	faArrowSquareRightSolid,
	faArrowSquareUpRegular,
	faArrowSquareUpSolid,
	faAlignJustifyRegular,
	faAlignJustifySolid,
	faAlignSlashRegular,
	faAlignSlashSolid,
	faUndoAltRegular,
	faUndoAltSolid,
	faRedoAltRegular,
	faRedoAltSolid,
	faFingerprintRegular,
	faFingerprintSolid,
	faHandPointUpRegular,
	faHandPointUpSolid,
	faBoxCheckRegular,
	faBoxCheckSolid,
	faCodeRegular,
	faCodeSolid,
	faStickyNoteRegular,
	faStickyNoteSolid,
	faLinkRegular,
	faLinkSolid,
	faSortAlphaDownRegular,
	faSortAlphaDownSolid,
	faSortNumericDownAltRegular,
	faSortNumericDownAltSolid,
	faQrCodeRegular,
	faQrCodeSolid
);

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email) {
	return emailRegex.test(email);
}

export function isValidExtendedEmail(email) {
	if (isValidEmail(email)) {
		return true;
	} else {
		const extendedEmailRegex = /(.*?)<(.*?)>/gm;

		const array = [...email.matchAll(extendedEmailRegex)];

		// If hava a pattern like: Jeff Bonasso <jeff.bonasso@gmail.com> or "jeff.bonasso@ambifi.com" <jeff.bonasso@ambifi.com> and there is a valid email address in the brackets
		if (array && array.length > 0 && array[0].length === 3) {
			if (isValidEmail(array[0][2])) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}

export function getExtendedEmail(email) {
	if (isValidEmail(email)) {
		return {
			name: "",
			email: email
		};
	} else {
		const extendedEmailRegex = /(.*?)<(.*?)>/gm;

		const array = [...email.matchAll(extendedEmailRegex)];

		// If hava a pattern like: Jeff Bonasso <jeff.bonasso@gmail.com> or "jeff.bonasso@ambifi.com" <jeff.bonasso@ambifi.com> and there is a valid email address in the brackets
		if (array && array.length > 0 && array[0].length === 3) {
			if (isValidEmail(array[0][2])) {
				let name = array[0][1].trim();
				if (/"(.*?)"/gm.test(name) || /'(.*?)'/gm.test(name)) {
					return {
						name: "",
						email: array[0][2].trim()
					};
				} else {
					return {
						name: array[0][1].trim(),
						email: array[0][2].trim()
					};
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}
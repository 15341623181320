

import { getOrgId } from "../actions/orgsActions";
import { listAllObjects, listAllObjectsAndDirectories } from "../utils/s3.js";
import { getAllQuery } from "../utils/org";
import { stripOrgIdFromDriveRootDirs, convertPathToGuid } from "../utils/utils";
import AWS from "aws-sdk";
import _ from "lodash";
import env from "../constants/env";

import state from "../state/state";

const s3 = new AWS.S3();

export function build(mode = "tree", includeReferenceMap = false) {
	console.log("!@!@!@ Drive Tree Builder:build");
	return new Promise(async (resolve, reject) => {
		try {
			const drive = await fetchDriveTree(mode, false, includeReferenceMap);
			console.log("Drive Tree Builder finished");
			resolve({
				drive
			});
		} catch (e) {
			console.error("Drive Tree Builder failed with the following exception", e);
			reject(e);
		}
	});
}

export async function fetchDriveTree(mode = "tree", skipS3Scan = false, includeReferenceMap = false) {
	return new Promise(async (resolve, reject) => {
		try {
			console.log("!@!@!@ Drive Tree Builder:listDrive");

			getObjectsForOrgId().then(async (objects) => {
				const orgId = getOrgId();

				const objectPathToObject = {};
				const objectGuidToObject = {};

				objects.forEach(object => {
					objectPathToObject[object.path] = object;
					if (mode === "mapofguids" || includeReferenceMap) {
						objectGuidToObject[object.guid] = object;
					}
				});

				if (skipS3Scan) {
					let result = null;
					switch (_.toLower(mode)) {
						case "tree": {
							reject({
								success: false,
								message: "Tree mode is not available when skipping s3 scan, use either mapOfGuids or mapOfPaths mode instead"
							});
							break;
						}
						case "mapofguids": {
							result = objectGuidToObject;
							break;
						}
						case "mapofpaths": {
							result = objectPathToObject;
							break;
						}
						default: {
							reject({
								success: false,
								message: "Unknown mode, choose one of tree, mapOfGuids or mapOfPaths."
							});
						}
					}
					if (result) {
						if (includeReferenceMap) {
							resolve({
								[mode]: result,
								objectGuidToObject,
								objectPathToObject
							});
						} else {
							resolve(result);
						}
					}
				} else {

					let modeLowercase = _.toLower(mode);
					let result = {};

					// handle tree differently since it needs directories
					if (modeLowercase === "tree") {
						result = await getDriveContentAsList(objectPathToObject, orgId);
					} else {
						let results = await listAllObjects(env.s3.assetsBucket, [`public/${orgId}`, `private/${orgId}`]/*, "/"*/);
						switch (modeLowercase) {
							case "mapofguids": {
								result = getDriveContentAsMapOfGuids(objectPathToObject, results, orgId);
								break;
							}
							case "mapofpaths": {
								result = getDriveContentAsMapOfPaths(objectPathToObject, results, orgId);
								break;
							}
							default: {
								result = getDriveContentAsHierarchy(objectPathToObject, results, orgId);
							}
						}
					}

					state.set(["appState", "loadingMessage"], "Loading drive finalizing...");

					// console.log("Generated drive content", mode, result);
					if (includeReferenceMap) {
						resolve({
							[mode]: result,
							objectGuidToObject,
							objectPathToObject
						});
					} else {
						resolve(result);
					}
				}
			}).catch(err => {
				reject("Unable to verify organization membership for caller");
			});
		} catch (err) {
			reject("Unable to get objects for organization.\n\n" + err);
		}
	});
}

export async function fetchDriveListForSelectedFolder() {
	return await getDriveContentAsList(state.get(["drive", "pathToResource"]), getOrgId());
}

export async function fetchAllFileObjectsForFolder(folder) {

	let results = await listAllObjects(env.s3.assetsBucket, folder);

	let orgId = getOrgId();

	let fileObjectsList = [];

	let objectPathToObject = state.get(["drive", "pathToResource"]);

	_.forEach(results, s3Item => {
		let fileObject = buildFileObjectFromS3ContentItem(s3Item, orgId, objectPathToObject);
		if (fileObject) {
			fileObjectsList.push(fileObject);
		}
	});

	return fileObjectsList;
}


function getDriveContentAsHierarchy(objectPathToObject, s3Content, orgId) {
	console.log("!@!@!@ Drive Tree Builder:getDriveContentAsHierarchy");
	let result = {};
	try {
		let mapHierarchy = {};
		_.forEach(s3Content, result => {
			const filePath = result.Key.replace(`public/${orgId}/`, "public/").replace(`private/${orgId}/`, "private/");
			const filePathPieces = filePath.split("/");
			const internalPath = _.dropRight(filePathPieces).join(".");
			if (!_.endsWith(result.Key, "/")) {
				// console.log("Got drive file:", result);
				// Get the internal path that doesn't include orgId and /drive/
				if (!_.get(mapHierarchy, internalPath)) {
					_.set(mapHierarchy, internalPath, {
						children: []
					});
				} else if (!_.get(mapHierarchy, `${internalPath}.children`)) {
					_.set(mapHierarchy, `${internalPath}.children`, []);
				}

				_.get(mapHierarchy, internalPath).children.push(_.assign({ isFile: true, fileName: _.last(filePathPieces) }, objectPathToObject[result.Key])); //{ fileName: _.last(filePathPieces), obj: objectPathToObject[result.Key] }
			} else if (filePath.length > 0) {
				if (!_.get(mapHierarchy, internalPath)) {
					_.set(mapHierarchy, internalPath, {});
				}
			}
		});
		result = transformToTreeHierarchy(mapHierarchy, objectPathToObject);
		if (!result || _.isEmpty(result) || !result.children || _.isEmpty(result.children)) {
			result = {
				type: "folder",
				name: "Drive",
				toggled: true,
				children: [{
					type: "folder",
					name: "public",
					path: ["public"],
					_path: ["public"],
					guid: "public",
					children: []
				}, {
					type: "folder",
					name: "private",
					path: ["private"],
					_path: ["private"],
					guid: "private",
					children: []
				}]
			};
		}

		// Detect if private or public might be missing
		let hasPrivate = false;
		let hasPublic = false;
		_.forEach(result.children, child => {
			if (child.name === "public") {
				hasPublic = true;
			} else if (child.name === "private") {
				hasPrivate = true;
			}
		});

		if (!hasPublic) {
			result.children.unshift({
				type: "folder",
				name: "public",
				path: ["public"],
				_path: ["public"],
				guid: "public",
				children: []
			});
		}

		if (!hasPrivate) {
			result.children.push({
				type: "folder",
				name: "private",
				path: ["private"],
				_path: ["private"],
				guid: "private",
				children: []
			});
		}

		result.root = true;
		result.guid = "root";
		result.allowMoveCopyRename = false;
		result.allowDelete = false;
		result.allowCreate = false;
		result.allowCollapse = false;
		result.children[0].allowDelete = false;
		result.children[0].toggled = true;
		result.children[0].allowCollapse = false;
		result.children[0].allowMoveCopyRename = false;
		result.children[1].allowDelete = false;
		result.children[1].toggled = true;
		result.children[1].allowCollapse = false;
		result.children[1].allowMoveCopyRename = false;

	} catch (err) {
		console.error(err);
	}

	return result;
}

async function getDriveContentAsList(objectPathToObject, orgId) {
	console.log("!@!@!@ Drive Tree Builder:getDriveContentAsList");

	let result = {
		children: []
	};

	let selectedFolder = state.get(["drive", "selectedFolder"]);

	try {
		// Folder is selected 
		if (!_.isEmpty(selectedFolder)) {

			let s3ContentAndDirs = await listAllObjectsAndDirectories(env.s3.assetsBucket, selectedFolder);

			let selectedFolderPieces = selectedFolder.split("/");
			result.type = "folder";
			result.guid = convertPathToGuid(selectedFolder);
			result.name = selectedFolderPieces[selectedFolderPieces.length - 2];
			result.fullPath = selectedFolder;

			let selectedFolderNoOrgId = stripOrgIdFromDriveRootDirs(selectedFolder, orgId);
			let selectedFolderNoOrgIdPieces = selectedFolderNoOrgId.split("/");
			if (selectedFolderNoOrgId.endsWith("/")) selectedFolderNoOrgIdPieces.pop();

			result.path = selectedFolderNoOrgIdPieces;
			result._path = selectedFolderNoOrgIdPieces;

			// Do any directories first
			_.forEach(s3ContentAndDirs.CommonPrefixes, s3CommonPrefix => {

				let prefix = s3CommonPrefix.Prefix;
				let prefixPieces = prefix.split("/");
				let name = prefixPieces[prefixPieces.length - 2];

				let prefixNoOrgId = stripOrgIdFromDriveRootDirs(prefix, orgId);

				let prefixNoOrgIdPieces = prefixNoOrgId.split("/");
				if (prefixNoOrgId.endsWith("/")) prefixNoOrgIdPieces.pop();

				let folderObject = {
					type: "folder",
					name: name,
					fullPath: prefix,
					guid: convertPathToGuid(prefix),
					path: prefixNoOrgIdPieces,
					_path: prefixNoOrgIdPieces
				}

				result.children.push(folderObject);
			});

			// Do files second
			_.forEach(s3ContentAndDirs.Contents, s3Item => {
				let fileObject = buildFileObjectFromS3ContentItem(s3Item, orgId, objectPathToObject);
				if (fileObject) {
					result.children.push(fileObject);
				}
			});
		} else {
			// No folder selected, default to root public and private
			result = {
				type: "folder",
				name: "Drive",
				children: [{
					type: "folder",
					name: "public",
					path: ["public"],
					fullPath: `public/${orgId}/`,
					_path: ["public"],
					guid: "public",
					children: [],
					allowDelete: false,
					allowMoveCopyRename: false,
					allowCreate: false
				}, {
					type: "folder",
					name: "private",
					path: ["private"],
					fullPath: `private/${orgId}/`,
					_path: ["private"],
					guid: "private",
					children: [],
					allowDelete: false,
					allowMoveCopyRename: false,
					allowCreate: false
				}]
			};

			result.root = true;
			result.guid = "root";
			result.allowMoveCopyRename = false;
			result.allowDelete = false;
			result.allowCreate = false;
		}



		console.log("GET DRIVE CONTENT LIST RESULT", result);

		console.log("!@!@!@ Drive Tree Builder:getDriveContentAsList Results");
	} catch (err) {
		console.error(err);
	}

	return result;
}

function getDriveContentAsMapOfGuids(objectPathToObject, s3Content, orgId) {
	console.log("!@!@!@ Drive Tree Builder:getDriveContentAsMapOfGuids");
	let _result = {};
	_.forEach(s3Content, result => {
		if (!_.endsWith(result.Key, "/") && objectPathToObject[result.Key]) {
			const filePath = result.Key.replace(`public/${orgId}/`, "public/").replace(`private/${orgId}/`, "private/");
			const filePathPieces = filePath.split("/");
			const dynamoObjectInfo = objectPathToObject[result.Key];
			_result[dynamoObjectInfo.guid] = _.assign({ fileName: _.last(filePathPieces) }, objectPathToObject[result.Key]);
		}
	});
	return _result;
}

function getDriveContentAsMapOfPaths(objectPathToObject, s3Content, orgId) {
	console.log("!@!@!@ Drive Tree Builder:getDriveContentAsMapOfPaths");
	let _result = {};
	_.forEach(s3Content, result => {
		if (!_.endsWith(result.Key, "/") && objectPathToObject[result.Key]) {
			const filePath = result.Key.replace(`public/${orgId}/`, "public/").replace(`private/${orgId}/`, "private/");
			const filePathPieces = filePath.split("/");
			const dynamoObjectInfo = objectPathToObject[result.Key];
			_result[dynamoObjectInfo.path] = _.assign({ fileName: _.last(filePathPieces) }, objectPathToObject[result.Key]);
		}
	});
	return _result;
}

function getObjectsForOrgId() {
	console.log("!@!@!@ getObjectsForOrgId");
	return new Promise(async (resolve, reject) => {
		try {
			const orgId = getOrgId();

			const params = {
				TableName: env.dynamoDb.driveTable,
				KeyConditionExpression: "#orgId = :orgId",
				ExpressionAttributeNames: {
					"#orgId": "orgId"
				},
				ExpressionAttributeValues: {
					":orgId": orgId
				},
				IndexName: "orgId-index"
			};

			try {
				const data = await getAllQuery(params);
				resolve(_.map(data, item => {
					if (!item.hasOwnProperty("scope")) {
						item.scope = item.orgId;
					}

					return item;
				}));
			} catch (err) {
				console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
				reject(err.message);
			}
		} catch (err) {
			reject("Unable to verify organization membership for caller\n\n" + err.message);
		}
	});
}

function transformToTreeHierarchy(mapHierarchy, objectPathToObject, path = []) {
	// console.log("!@!@!@ Drive Tree Builder:transformToTreeHierarchy");
	if (_.isPlainObject(mapHierarchy) && mapHierarchy.isFile) {
		// Hit a file name
		const _path = Object.assign([], path);
		_path.push(mapHierarchy.fileName);
		return Object.assign({
			type: "file",
			ext: _.last(mapHierarchy.fileName.split(".")),
			name: mapHierarchy.fileName,
			path: _path,
			_path
		}, _.omit(mapHierarchy, ["isFile"]));
	} else if (_.isPlainObject(mapHierarchy)) {
		let children = [];
		_.keys(mapHierarchy).forEach(key => {
			const _path = Object.assign([], path);
			if (key !== "children") {
				_path.push(key);
			}
			const child = transformToTreeHierarchy(mapHierarchy[key], objectPathToObject, _path);
			if (_.isArray(child)) {
				children = _.concat(children, child);
			} else {
				child.name = key;
				child.path = _path;
				child._path = _path;
				if (child.type === "folder") {
					child.toggled = false;
					child.guid = _path.join("#");
				}
				children.push(child);
			}
		});

		return {
			type: "folder",
			name: "Drive",
			toggled: true,
			children
		};
	} else if (_.isArray(mapHierarchy)) {
		return mapHierarchy.map(entry => {
			return transformToTreeHierarchy(entry, objectPathToObject, path);
		});
	}
}

function buildFileObjectFromS3ContentItem(s3Item, orgId, objectPathToObject) {

	const filePath = stripOrgIdFromDriveRootDirs(s3Item.Key, orgId);
	const filePathPieces = filePath.split("/");

	let fileObject;
	if (!_.endsWith(s3Item.Key, "/")) {
		// Hit a file name

		fileObject = {
			type: "file",
			ext: _.last(s3Item.Key.split(".")),
			name: _.last(filePathPieces),
			path: s3Item.Key,
			_path: filePathPieces
		};

		fileObject = _.assign(fileObject, { isFile: true, fileName: _.last(filePathPieces) }, objectPathToObject[s3Item.Key]);
	}
	return fileObject;
}

import algoliasearch from "algoliasearch";
import _ from "lodash";

import AWS from "aws-sdk";

import env from "../constants/env";
import state from "../state/state";

import { getOrgId } from "../actions/orgsActions";

import { getHistoryIndexName, getSearchIndexName } from "../actions/orgsActions";

import * as awsS3 from "../persistence/s3";
import { refreshCredentials } from "../utils/securityUtil";
import { showInfo, showError2 } from "../actions/alertActions";
import { hideStatusSpinner, showStatusSpinner } from "../actions/actions";

let client = algoliasearch(env.algolia.applicationId, env.algolia.apiKey);
let client2 = algoliasearch(env.algolia.applicationId, env.algolia.apiKey2);

export function getClient() {
	return client;
}

export function getClient2() {
	return client2;
}

function transformSearchStateToFacetFilter(searchState) {

	const arrFacetFilters = [];

	// Company
	let arrCompanies = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("company") && searchState.menu.company !== "") {
		arrCompanies.push("company:" + searchState.menu.company);
		arrFacetFilters.push(arrCompanies);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("displayName") && searchState.refinementList.displayName !== "") {
		arrCompanies = transformRefinementList(searchState, "company");
		if (arrCompanies.length > 0) {
			arrFacetFilters.push(arrCompanies);
		}
	}

	// Display Name
	let arrDisplayNames = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("displayName") && searchState.menu.displayName !== "") {
		arrDisplayNames.push("displayName:" + searchState.menu.displayName);
		arrFacetFilters.push(arrDisplayNames);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("displayName") && searchState.refinementList.displayName !== "") {
		arrDisplayNames = transformRefinementList(searchState, "displayName");
		if (arrDisplayNames.length > 0) {
			arrFacetFilters.push(arrDisplayNames);
		}
	}

	// Activity Name
	let arrNames = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("name") && searchState.menu.name !== "") {
		arrNames.push("name:" + searchState.menu.name);
		arrFacetFilters.push(arrNames);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("name") && searchState.refinementList.name !== "") {
		arrNames = transformRefinementList(searchState, "name");
		if (arrNames.length > 0) {
			arrFacetFilters.push(arrNames);
		}
	}

	// Refinement List
	const arrActivityResult = transformRefinementList(searchState, "activityResult");
	if (arrActivityResult.length > 0) {
		arrFacetFilters.push(arrActivityResult);
	}

	// Refinement List
	const arrWorkflowStates = transformRefinementList(searchState, "workflowStatusString");
	if (arrWorkflowStates.length > 0) {
		arrFacetFilters.push(arrWorkflowStates);
	}

	// Refinement List
	const arrWorkflowSubStates = transformRefinementList(searchState, "workflowSubStatusString");
	if (arrWorkflowSubStates.length > 0) {
		arrFacetFilters.push(arrWorkflowSubStates);
	}

	// Refinement List
	const arrCompletionStates = transformRefinementList(searchState, "completionStateString");
	if (arrCompletionStates.length > 0) {
		arrFacetFilters.push(arrCompletionStates);
	}

	// Genre
	const arrGenres = transformRefinementList(searchState, "genre");
	if (arrGenres.length > 0) {
		arrFacetFilters.push(arrGenres);
	}

	// Tags
	const arrTags = transformRefinementList(searchState, "tags");
	if (arrTags.length > 0) {
		arrFacetFilters.push(arrTags);
	}

	// Facility
	let arrFacilities = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("facility") && searchState.menu.facility !== "") {
		arrFacilities.push("facility:" + searchState.menu.facility);
		arrFacetFilters.push(arrFacilities);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("facility") && searchState.refinementList.facility !== "") {
		arrFacilities = transformRefinementList(searchState, "facility");
		if (arrFacilities.length > 0) {
			arrFacetFilters.push(arrFacilities);
		}
	}

	// Division
	let arrDivisions = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("division") && searchState.menu.division !== "") {
		arrDivisions.push("division:" + searchState.menu.division);
		arrFacetFilters.push(arrDivisions);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("division") && searchState.refinementList.division !== "") {
		arrDivisions = transformRefinementList(searchState, "division");
		if (arrDivisions.length > 0) {
			arrFacetFilters.push(arrDivisions);
		}
	}

	// Department
	let arrDepartments = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("department") && searchState.menu.department !== "") {
		arrDepartments.push("department:" + searchState.menu.department);
		arrFacetFilters.push(arrDepartments);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("department") && searchState.refinementList.department !== "") {
		arrDepartments = transformRefinementList(searchState, "department");
		if (arrDepartments.length > 0) {
			arrFacetFilters.push(arrDepartments);
		}
	}

	// Job Title
	let arrJobTitles = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("jobtitle") && searchState.menu.jobtitle !== "") {
		arrJobTitles.push("jobtitle:" + searchState.menu.jobtitle);
		arrFacetFilters.push(arrJobTitles);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("jobtitle") && searchState.refinementList.jobtitle !== "") {
		arrJobTitles = transformRefinementList(searchState, "jobtitle");
		if (arrJobTitles.length > 0) {
			arrFacetFilters.push(arrJobTitles);
		}
	}

	// Delivery Platform
	let arrDeliveryPlatforms = [];

	if (searchState.hasOwnProperty("menu") && searchState.menu.hasOwnProperty("deliveryPlatform") && searchState.menu.deliveryPlatform !== "") {
		arrDeliveryPlatforms.push("deliveryPlatform:" + searchState.menu.deliveryPlatform);
		arrFacetFilters.push(arrDeliveryPlatforms);
	} else if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty("deliveryPlatform") && searchState.refinementList.deliveryPlatform !== "") {
		arrDeliveryPlatforms = transformRefinementList(searchState, "deliveryPlatform");
		if (arrDeliveryPlatforms.length > 0) {
			arrFacetFilters.push(arrDeliveryPlatforms);
		}
	}

	return arrFacetFilters;
}

function transformSearchStateToNumericFilter(searchState) {
	const arrNumericFilters = [];

	const arrDueDateTimes = [];
	const arrCreatedDateTimes = [];
	const arrLastUpdatedDateTimes = [];

	// Due Date
	if (searchState.hasOwnProperty("multiRange") && searchState.multiRange.hasOwnProperty("dueDate") && searchState.multiRange.dueDate !== "") {
		arrDueDateTimes.push("dueDate:" + (searchState.multiRange.dueDate.split(":")[0] ? searchState.multiRange.dueDate.split(":")[0] : "0") + " TO " + searchState.multiRange.dueDate.split(":")[1]);
		arrNumericFilters.push(arrDueDateTimes);
	}

	// Started Timestamp
	if (searchState.hasOwnProperty("multiRange") && searchState.multiRange.hasOwnProperty("createdDateTime") && searchState.multiRange.createdDateTime !== "") {
		arrCreatedDateTimes.push("createdDateTime:" + searchState.multiRange.createdDateTime.split(":")[0] + " TO " + (searchState.multiRange.createdDateTime.split(":")[1] ? searchState.multiRange.createdDateTime.split(":")[1] : (Date.now() / 1000).toFixed(0)));
		arrNumericFilters.push(arrCreatedDateTimes);
	}

	// Last Updated Timestamp
	if (searchState.hasOwnProperty("multiRange") && searchState.multiRange.hasOwnProperty("lastUpdatedDateTime") && searchState.multiRange.lastUpdatedDateTime !== "") {
		arrLastUpdatedDateTimes.push("lastUpdatedDateTime:" + searchState.multiRange.lastUpdatedDateTime.split(":")[0] + " TO " + (searchState.multiRange.lastUpdatedDateTime.split(":")[1] ? searchState.multiRange.lastUpdatedDateTime.split(":")[1] : (Date.now() / 1000).toFixed(0)));
		arrNumericFilters.push(arrLastUpdatedDateTimes);
	}

	return arrNumericFilters;
}

function transformRefinementList(searchState, refinement) {
	const arrRefinements = [];

	if (searchState.hasOwnProperty("refinementList") && searchState.refinementList.hasOwnProperty(refinement)) {
		for (let i = 0; i < searchState.refinementList[refinement].length; i++) {
			const item = searchState.refinementList[refinement][i];
			arrRefinements.push(refinement + ":" + item);

		}
	}

	return arrRefinements;
}

export function touchHistoryFilesFromSearch(searchState) {
	const s3 = new AWS.S3({ useDualStack: true });

	refreshCredentials().then(async ({ cognitoUser }) => {
		console.log(searchState);
		let index = client2.initIndex(getHistoryIndexName());

		const arrFacetFilters = transformSearchStateToFacetFilter(searchState);
		const arrNumericFilters = transformSearchStateToNumericFilter(searchState);

		let options = {
			hitsPerPage: 1000
		};

		if (arrFacetFilters.length > 0) {
			options.facetFilters = arrFacetFilters
		}
		if (arrNumericFilters.length > 0) {
			options.numericFilters = arrNumericFilters
		}

		let results = await index.search(searchState.hasOwnProperty("query") ? searchState.query : "", options);
		//let results = await index.search('', options);

		console.log(results);

		let hits = results.hits;

		let count = 0;

		showStatusSpinner(state);

		for (let i = 0; i < hits.length; i++) {
			const hit = hits[i];

			await s3.copyObject({ MetadataDirective: "REPLACE", CopySource: `${env.s3.contentBucket}/` + hit.url, Bucket: env.s3.contentBucket, Key: hit.url }).promise();
			count++;
		}

		hideStatusSpinner(state);

		if (count === 1) {
			showInfo("Touch Files", `One file has been touched.`)
		} else if (count > 1) {
			showInfo("Touch Files", `${count} files have been touched.`)
		}
	}).catch((err) => {
		hideStatusSpinner(state);

		console.error(err);
		showError2("touchHistoryFilesFromSearch", err);
	});
}

export async function extractAll(where, orderBy) {
	let selectStmt = "identityId, activityId, instanceId, itemGuid, sessionName, checklistName, checklistCompletionState, checklistCompletionStateString, genre, url, tags, itemId, listIndex, sectionIndex, itemIndex, SUBSTRING(label1, 1, 255) as label1, SUBSTRING(label2, 1, 255) as label2, type, action, timestamp, value, duration ";


	const sql = "" +
		"SELECT " +
		"`ambifi-core`.`history`.`identityId` AS `identityId`, " +
		"`ambifi`.`OrganizationMember`.`displayName` AS `displayName`, " +
		"`ambifi`.`OrganizationMember`.`inviteEmail` AS `email`, " +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_1` AS `id34`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_2` AS `coid`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_4` AS `facility`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_5` AS `division`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_6` AS `department`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_8` AS `jobtitle`, " : "") +
		"`ambifi-core`.`history`.`activityId` AS `activityId`, " +
		"`ambifi-core`.`history`.`instanceId` AS `instanceId`, " +
		"`ambifi-core`.`history`.`sessionName` AS `sessionName`, " +
		"`ambifi-core`.`history`.`checklistName` AS `checklistName`, " +
		"`ambifi-core`.`history`.`checklistDescription` AS `checklistDescription`, " +
		"`ambifi-core`.`history`.`checklistCompletionState` AS `checklistCompletionState`, " +
		"`ambifi-core`.`history`.`checklistCompletionStateString` AS `checklistCompletionStateString`, " +
		"`ambifi-core`.`history`.`activityResult` AS `activityResult`, " +
		"`ambifi-core`.`history`.`genre` AS `genre`, " +
		"`ambifi-core`.`history`.`url` AS `url`, " +
		"`ambifi-core`.`history`.`tags` AS `tags`, " +
		"`ambifi-core`.`history`.`itemGuid` AS `itemGuid`, " +
		"`ambifi-core`.`history`.`itemId` AS `itemId`, " +
		"`ambifi-core`.`history`.`listIndex` AS `listIndex`, " +
		"`ambifi-core`.`history`.`sectionIndex` AS `sectionIndex`, " +
		"`ambifi-core`.`history`.`itemIndex` AS `itemIndex`, " +
		"`ambifi-core`.`history`.`label1` AS `label1`, " +
		"`ambifi-core`.`history`.`label2` AS `label2`, " +
		"`ambifi-core`.`history`.`type` AS `type`, " +
		"`ambifi-core`.`history`.`action` AS `action`, " +
		"`ambifi-core`.`history`.`value` AS `value`, " +
		"`ambifi-core`.`history`.`timestamp` AS `timestamp`, " +
		"`ambifi-core`.`history`.`duration` AS `duration` " +
		"FROM " +
		"(`ambifi-core`.`history` JOIN `ambifi`.`OrganizationMember` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMember`.`userSubId` LEFT OUTER JOIN `ambifi`.`OrganizationMemberInfo` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMemberInfo`.`userSubId`) " +
		"WHERE " +
		"(`ambifi-core`.`history`.`orgId` = '" + getOrgId() + "') AND (`ambifi-core`.`history`.`deleted` = 0) AND action <> 'setSketchPad' AND action <> 'setImagePicker' " + ((where && where !== "") ? " AND " + where + " " : "") +
		"ORDER BY " +
		((orderBy && orderBy !== "") ? orderBy : "identityId, activityId, instanceId, timestamp");

	console.log(sql);

	let fields;
	if (getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") {
		fields = ['identityId', 'displayName', 'email', 'id34', 'coid', 'facility', 'division', 'department', 'jobtitle', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'url', 'tags', 'itemGuid', 'itemId', 'listIndex', 'sectionIndex', 'itemIndex', 'label1', 'label2', 'type', 'action', 'value', 'timestamp', 'duration'];
	} else {
		fields = ['identityId', 'displayName', 'email', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'url', 'tags', 'itemGuid', 'itemId', 'listIndex', 'sectionIndex', 'itemIndex', 'label1', 'label2', 'type', 'action', 'value', 'timestamp', 'duration'];
	}

	awsS3.getExtract(sql, fields, "history-extract-");

	/*
	
	
	
		const browser = index.browseAll();
		let hits = [];
	
		browser.on("result", function onResult(content) {
			hits = hits.concat(content.hits);
		});
	
		browser.on("end", function onEnd() {
			console.log("Finished!");
			console.log("We got %d hits", hits.length);
	
			// Need to get each S3 doc in batch to get history
		});
	
		browser.on("error", function onError(err) {
			throw err;
		});
	*/
}

export async function extractAllSessions(where, orderBy) {
	// To make completely dynmic, could query OrganizationMemberInfo table first for Org to get the types that need returned

	const sql = "" +
		"SELECT " +
		"`ambifi-core`.`history`.`identityId` AS `identityId`, " +
		"`ambifi`.`OrganizationMember`.`displayName` AS `displayName`, " +
		"`ambifi`.`OrganizationMember`.`inviteEmail` AS `email`, " +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_1` AS `id34`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_2` AS `coid`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_4` AS `facility`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_5` AS `division`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_6` AS `department`, " : "") +
		((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_8` AS `jobtitle`, " : "") +
		"`ambifi-core`.`history`.`activityId` AS `activityId`, " +
		"`ambifi-core`.`history`.`instanceId` AS `instanceId`, " +
		"`ambifi-core`.`history`.`sessionName` AS `sessionName`, " +
		"`ambifi-core`.`history`.`checklistName` AS `checklistName`, " +
		"`ambifi-core`.`history`.`checklistDescription` AS `checklistDescription`, " +
		"`ambifi-core`.`history`.`checklistCompletionState` AS `checklistCompletionState`, " +
		"`ambifi-core`.`history`.`checklistCompletionStateString` AS `checklistCompletionStateString`, " +
		"`ambifi-core`.`history`.`activityResult` AS `activityResult`, " +
		"`ambifi-core`.`history`.`genre` AS `genre`, " +
		"`ambifi-core`.`history`.`tags` AS `tags`, " +
		"`ambifi-core`.`history`.`url` AS `url`, " +
		"`ambifi-core`.`history`.`timestamp` AS `timestamp`, " +
		"SUM(duration) as 'totalDuration', " +
		"AVG(duration) as 'avgPerItem' " +
		"FROM " +
		"(`ambifi-core`.`history` JOIN `ambifi`.`OrganizationMember` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMember`.`userSubId` LEFT OUTER JOIN `ambifi`.`OrganizationMemberInfo` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMemberInfo`.`userSubId`) " +
		"WHERE " +
		"((`ambifi-core`.`history`.`orgId` = '" + getOrgId() + "') " +
		" AND (`ambifi-core`.`history`.`deleted` = 0) " + ((where && where !== "") ? " AND " + where + " " : "") +
		") " +
		"GROUP BY `ambifi-core`.`history`.`instanceId` " +
		"ORDER BY " +
		((orderBy && orderBy !== "") ? orderBy : "`ambifi-core`.`history`.`timestamp` ASC")


	console.log(sql);

	let fields;

	if (getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") {
		fields = ['identityId', 'displayName', 'email', 'id34', 'coid', 'facility', 'division', 'department', 'jobtitle', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'tags', 'url', 'timestamp', 'totalDuration', 'avgPerItem'];
	} else {
		fields = ['identityId', 'displayName', 'email', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'tags', 'url', 'timestamp', 'totalDuration', 'avgPerItem'];
	}

	awsS3.getExtract(sql, fields, "session-extract-");
}

export async function countUniqueUsers(searchState, showHealthStream) {
	try {
		let index = client2.initIndex(getHistoryIndexName());

		const arrFacetFilters = transformSearchStateToFacetFilter(searchState);
		const arrNumericFilters = transformSearchStateToNumericFilter(searchState);

		console.log("SEARCH STATE", searchState, arrFacetFilters, arrNumericFilters);

		let options = {
		};

		if (!showHealthStream) {
			options.filters = "NOT deliveryPlatform:HealthStream"
		}

		if (arrFacetFilters.length > 0) {
			options.facetFilters = arrFacetFilters
		}
		if (arrNumericFilters.length > 0) {
			options.numericFilters = arrNumericFilters
		}

		showStatusSpinner(state);

		let hits = [];
		await index.browseObjects({
			query: "",
			attributesToRetrieve: "displayName",
			// filters: `createdDateTime>1690804800 AND createdDateTime<1693569600`,
			...options,
			batch: batch => {
				hits = hits.concat(batch);
			}
		});

		// console.log("HITS", hits.length, hits[0]);

		const totalUsers = [];

		for (let i = 0; i < hits.length; i++) {
			const hit = hits[i];

			if (!totalUsers.includes(hit.displayName)) {
				totalUsers.push(hit.displayName);
			}
		}

		hideStatusSpinner(state);

		showInfo("Unique Users", "" + totalUsers.length);

		console.log("UNIQUE USERS", totalUsers.length);

		return totalUsers.length;
	} catch (err) {
		console.error("ERROR", err);
	}
}

export async function getInvoicesByIdentityId(identityId, startDate, endDate) {
	try {
		let index = client2.initIndex(getHistoryIndexName());

		const arrFacetFilters = ["invoiceItems.identityId:" + identityId];
		// const arrNumericFilters = transformSearchStateToNumericFilter(searchState);

		// console.log("SEARCH STATE", searchState, arrFacetFilters, arrNumericFilters);

		let options = {
		};

		if (arrFacetFilters.length > 0) {
			options.facetFilters = arrFacetFilters
		}
		// if (arrNumericFilters.length > 0) {
		// 	options.numericFilters = arrNumericFilters
		// }

		showStatusSpinner(state);

		let hits = [];
		await index.browseObjects({
			query: "",
			// attributesToRetrieve: "displayName",
			// filters: `createdDateTime>1690804800 AND createdDateTime<1693569600`,
			...options,
			batch: batch => {
				hits = hits.concat(batch);
			}
		});

		// console.log("HITS", hits.length, hits[0]);

		const invoiceItems = [];
		for (let i = 0; i < hits.length; i++) {
			invoiceItems.push(hits[i].invoiceItems);
		}

		hideStatusSpinner(state);

		return invoiceItems;
	} catch (err) {
		console.error("ERROR", err);
	}
}

export async function extractAllFiltered(searchState) {
	refreshCredentials().then(async ({ cognitoUser }) => {
		console.log(searchState);

		let index = client2.initIndex(getHistoryIndexName());

		const arrFacetFilters = transformSearchStateToFacetFilter(searchState);
		const arrNumericFilters = transformSearchStateToNumericFilter(searchState);

		let options = {
			hitsPerPage: 1000
		};

		if (arrFacetFilters.length > 0) {
			options.facetFilters = arrFacetFilters
		}
		if (arrNumericFilters.length > 0) {
			options.numericFilters = arrNumericFilters
		}

		let results = await index.search(searchState.hasOwnProperty("query") ? searchState.query : "", options);
		//let results = await index.search('', options);

		console.log(results);

		let hits = results.hits;

		let instancesFilter = "";

		let instances = "";
		for (let i = 0; i < hits.length; i++) {
			const hit = hits[i];

			if (i === 0) {
				instances = "'" + hit.instanceId + "'";
			} else {
				instances = instances + ", '" + hit.instanceId + "'";
			}
		}

		if (instances !== "") {
			instancesFilter = " AND instanceId IN (" + instances + ")";
		}

		const sql = "" +
			"SELECT " +
			"`ambifi-core`.`history`.`identityId` AS `identityId`, " +
			"`ambifi`.`OrganizationMember`.`displayName` AS `displayName`, " +
			"`ambifi`.`OrganizationMember`.`inviteEmail` AS `email`, " +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_1` AS `id34`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_2` AS `coid`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_4` AS `facility`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_5` AS `division`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_6` AS `department`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_8` AS `jobtitle`, " : "") +
			"`ambifi-core`.`history`.`activityId` AS `activityId`, " +
			"`ambifi-core`.`history`.`instanceId` AS `instanceId`, " +
			"`ambifi-core`.`history`.`sessionName` AS `sessionName`, " +
			"`ambifi-core`.`history`.`checklistName` AS `checklistName`, " +
			"`ambifi-core`.`history`.`checklistDescription` AS `checklistDescription`, " +
			"`ambifi-core`.`history`.`checklistCompletionState` AS `checklistCompletionState`, " +
			"`ambifi-core`.`history`.`checklistCompletionStateString` AS `checklistCompletionStateString`, " +
			"`ambifi-core`.`history`.`activityResult` AS `activityResult`, " +
			"`ambifi-core`.`history`.`genre` AS `genre`, " +
			"`ambifi-core`.`history`.`url` AS `url`, " +
			"`ambifi-core`.`history`.`tags` AS `tags`, " +
			"`ambifi-core`.`history`.`itemGuid` AS `itemGuid`, " +
			"`ambifi-core`.`history`.`itemId` AS `itemId`, " +
			"`ambifi-core`.`history`.`listIndex` AS `listIndex`, " +
			"`ambifi-core`.`history`.`sectionIndex` AS `sectionIndex`, " +
			"`ambifi-core`.`history`.`itemIndex` AS `itemIndex`, " +
			"`ambifi-core`.`history`.`label1` AS `label1`, " +
			"`ambifi-core`.`history`.`label2` AS `label2`, " +
			"`ambifi-core`.`history`.`type` AS `type`, " +
			"`ambifi-core`.`history`.`action` AS `action`, " +
			"`ambifi-core`.`history`.`value` AS `value`, " +
			"`ambifi-core`.`history`.`timestamp` AS `timestamp`, " +
			"`ambifi-core`.`history`.`duration` AS `duration` " +
			"FROM " +
			"(`ambifi-core`.`history` JOIN `ambifi`.`OrganizationMember` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMember`.`userSubId` LEFT OUTER JOIN `ambifi`.`OrganizationMemberInfo` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMemberInfo`.`userSubId`) " +
			"WHERE " +
			"(`ambifi-core`.`history`.`orgId` = '" + getOrgId() + "')" + instancesFilter + " AND action <> 'setSketchPad' AND action <> 'setImagePicker' " +
			"ORDER BY " +
			"identityId, activityId, instanceId, timestamp";

		console.log(sql);

		let fields;
		if (getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") {
			fields = ['identityId', 'displayName', 'email', 'id34', 'coid', 'facility', 'division', 'department', 'jobtitle', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'url', 'tags', 'itemGuid', 'itemId', 'listIndex', 'sectionIndex', 'itemIndex', 'label1', 'label2', 'type', 'action', 'value', 'timestamp', 'duration'];
		} else {
			fields = ['identityId', 'displayName', 'email', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'url', 'tags', 'itemGuid', 'itemId', 'listIndex', 'sectionIndex', 'itemIndex', 'label1', 'label2', 'type', 'action', 'value', 'timestamp', 'duration'];
		}

		if (results.nbHits > 1000) {
			window.confirm("When doing an extract from search results, there is a limit of 1000 sessions that can be returned. The current search results has " + results.nbHits + " records. You can apply more filters to reduce the result size.\n\nDo you want to continue generating the extract with the first 1000 sessions?", () => {
				awsS3.getExtract(sql, fields, "history-extract-search-results-");
			});
		} else {
			window.confirm("This will generate an extract with " + results.nbHits + " sessions.\n\nDo you want to continue generating the extract?", () => {
				awsS3.getExtract(sql, fields, "history-extract-search-results-");
			});
		}
	}).catch((err) => {
		console.error(err);
		showError2("extractAllFiltered", err);
	});


	/*
	
	
	
		const browser = index.browseAll();
		let hits = [];
	
		browser.on("result", function onResult(content) {
			hits = hits.concat(content.hits);
		});
	
		browser.on("end", function onEnd() {
			console.log("Finished!");
			console.log("We got %d hits", hits.length);
	
			// Need to get each S3 doc in batch to get history
		});
	
		browser.on("error", function onError(err) {
			throw err;
		});
	*/
}


export async function extractAllSessionsFiltered(searchState) {
	refreshCredentials().then(async ({ cognitoUser }) => {
		// To make completely dynmic, could query OrganizationMemberInfo table first for Org to get the types that need returned
		console.log(searchState);

		let index = client2.initIndex(getHistoryIndexName());

		const arrFacetFilters = transformSearchStateToFacetFilter(searchState);
		const arrNumericFilters = transformSearchStateToNumericFilter(searchState);

		let options = {
			hitsPerPage: 1000
		};

		if (arrFacetFilters.length > 0) {
			options.facetFilters = arrFacetFilters
		}
		if (arrNumericFilters.length > 0) {
			options.numericFilters = arrNumericFilters
		}

		let results = await index.search(searchState.hasOwnProperty("query") ? searchState.query : "", options);
		//let results = await index.search('', options);

		console.log(results);

		let hits = results.hits;

		let instancesFilter = "";

		let instances = "";
		for (let i = 0; i < hits.length; i++) {
			const hit = hits[i];

			if (i === 0) {
				instances = "'" + hit.instanceId + "'";
			} else {
				instances = instances + ", '" + hit.instanceId + "'";
			}
		}

		if (instances !== "") {
			instancesFilter = " AND instanceId IN (" + instances + ")";
		}

		const sql = "" +
			"SELECT " +
			"`ambifi-core`.`history`.`identityId` AS `identityId`, " +
			"`ambifi`.`OrganizationMember`.`displayName` AS `displayName`, " +
			"`ambifi`.`OrganizationMember`.`inviteEmail` AS `email`, " +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_1` AS `id34`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_2` AS `coid`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_4` AS `facility`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_5` AS `division`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_6` AS `department`, " : "") +
			((getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") ? "`ambifi`.`OrganizationMemberInfo`.`memberInfoValue_8` AS `jobtitle`, " : "") +
			"`ambifi-core`.`history`.`activityId` AS `activityId`, " +
			"`ambifi-core`.`history`.`instanceId` AS `instanceId`, " +
			"`ambifi-core`.`history`.`sessionName` AS `sessionName`, " +
			"`ambifi-core`.`history`.`checklistName` AS `checklistName`, " +
			"`ambifi-core`.`history`.`checklistDescription` AS `checklistDescription`, " +
			"`ambifi-core`.`history`.`checklistCompletionState` AS `checklistCompletionState`, " +
			"`ambifi-core`.`history`.`checklistCompletionStateString` AS `checklistCompletionStateString`, " +
			"`ambifi-core`.`history`.`activityResult` AS `activityResult`, " +
			"`ambifi-core`.`history`.`genre` AS `genre`, " +
			"`ambifi-core`.`history`.`tags` AS `tags`, " +
			"`ambifi-core`.`history`.`url` AS `url`, " +
			"`ambifi-core`.`history`.`timestamp` AS `timestamp`, " +
			"SUM(duration) as 'totalDuration', " +
			"AVG(duration) as 'avgPerItem' " +
			"FROM " +
			"(`ambifi-core`.`history` JOIN `ambifi`.`OrganizationMember` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMember`.`userSubId` LEFT OUTER JOIN `ambifi`.`OrganizationMemberInfo` ON `ambifi-core`.`history`.`identityId`=`ambifi`.`OrganizationMemberInfo`.`userSubId`) " +
			"WHERE " +
			"(`ambifi-core`.`history`.`orgId` = '" + getOrgId() + "')" + instancesFilter +
			"GROUP BY `ambifi-core`.`history`.`instanceId` " +
			"ORDER BY " +
			"`ambifi-core`.`history`.`timestamp` ASC"


		console.log(sql);

		let fields;

		if (getOrgId() === "75a99644-d769-11ec-8330-069463faa5ff") {
			fields = ['identityId', 'displayName', 'email', 'id34', 'coid', 'facility', 'division', 'department', 'jobtitle', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'tags', 'url', 'timestamp', 'totalDuration', 'avgPerItem'];
		} else {
			fields = ['identityId', 'displayName', 'email', 'activityId', 'instanceId', 'sessionName', 'checklistName', 'checklistDescription', 'checklistCompletionState', 'checklistCompletionStateString', 'activityResult', 'genre', 'tags', 'url', 'timestamp', 'totalDuration', 'avgPerItem'];
		}

		if (results.nbHits > 1000) {
			window.confirm("When doing an extract from search results, there is a limit of 1000 sessions that can be returned. The current search results has " + results.nbHits + " records. You can apply more filters to reduce the result size.\n\nDo you want to continue generating the extract with the first 1000 sessions?", () => {
				awsS3.getExtract(sql, fields, "session-extract-search-results-");
			});
		} else {
			window.confirm("This will generate an extract with " + results.nbHits + " sessions.\n\nDo you want to continue generating the extract?", () => {
				awsS3.getExtract(sql, fields, "session-extract-search-results-");
			});
		}
	}).catch((err) => {
		console.error(err);
		showError2("extractAllSessionsFiltered", err);
	});
}



// Do I want to directly set state here, or control all through actions?
export function searchFeatured(text) {
	let index = client.initIndex(env.algolia.index);
	index.search(text, { hitsPerPage: 10 }, function (err, content) {
		state.set(["search", "featured"], content.hits);
	});
}

export function searchFeatured2() {
	let index = client.initIndex(env.algolia.index);

	let hitsFeatured = [];
	let hitsFeaturedSims = [];
	let hitsFeaturedSnippets = [];

	index.search("self", { hitsPerPage: 100, restrictSearchableAttributes: "publisher" }, function (err, content) {
		if (err) {
			console.error("Connection to Algolia failed", err);
			alert("Error connecting to Algolia.");
			state.set(["search", "featured"], []);
			state.set(["search", "featuredSnippets"], []);
			state.set(["search", "featuredSims"], []);
			return;
		}

		if (content === null) {
			state.set(["search", "featured"], []);
			state.set(["search", "featuredSnippets"], []);
			state.set(["search", "featuredSims"], []);

			return;
		}
		let hits = content.hits;

		hits.sort(compare);

		for (let i = 0; i < hits.length; i++) {
			let foundSim = false;
			let foundSnippet = false;

			let hit = hits[i];

			if (hit.tags.length > 0) {
				for (let j = 0; j < hit.tags.length; j++) {
					let tag = hit.tags[j];
					if (tag.toLowerCase() === "simulation") {
						foundSim = true;
					} else if (tag.toLowerCase() === "snippet") {
						foundSnippet = true;
					}
				}
			}

			if (foundSnippet) {
				hitsFeaturedSnippets.push(hit);
			} else if (foundSim) {
				hitsFeaturedSims.push(hit);
			} else {
				hitsFeatured.push(hit);
			}
		}
		state.set(["search", "featured"], hitsFeatured);
		state.set(["search", "featuredSnippets"], hitsFeaturedSnippets);
		state.set(["search", "featuredSims"], hitsFeaturedSims);
	});
}

function compare(a, b) {
	if (a.name.trim() < b.name.trim()) { return -1; }
	if (a.name.trim() > b.name.trim()) { return 1; }
	return 0;
}

export function searchFeatured3() {
	let index = client.initIndex(env.algolia.index);
	index.search("self", {
		"getRankingInfo": 1,
		"facets": "*",
		"attributesToRetrieve": "*",
		"highlightPreTag": "<em>",
		"highlightPostTag": "</em>",
		"hitsPerPage": 10,
		"restrictSearchableAttributes": "publisher",
		"facetFilters": [],
		"maxValuesPerFacet": 100
	}, function (err, content) {
		state.set(["search", "featured"], content.hits);
	});
}


export function searchCheckMate() {
	let index = client.initIndex(env.algolia.index);
	index.search("CheckMate", { hitsPerPage: 100 }, function (err, content) {
		if (err) {
			console.error("Connection to Algolia failed", err);
			alert("Error connecting to Algolia.");
			state.set(["search", "checkmate"], []);
			return;
		}
		if (content === null) {
			state.set(["search", "checkmate"], []);
			return;
		}

		let hits = content.hits;
		hits.sort(compare);
		state.set(["search", "checkmate"], hits);
	});
}

export function searchTrending(text) {
	let index = client.initIndex(env.algolia.index);
	index.search(text, { hitsPerPage: 10 }, function (err, content) {
		state.set(["search", "trending"], content.hits);
	});
}

export function searchRecommended(text) {
	let index = client.initIndex(env.algolia.index);
	index.search(text, { hitsPerPage: 10 }, function (err, content) {
		state.set(["search", "recommended"], content.hits);
	});
}

function initializeHistoryIndex(index) {
	return new Promise(async (resolve, reject) => {
		console.log("initializeHistoryIndex (1)");
		try {
			await index.getSettings();
			console.log("initializeHistoryIndex (3)");
			resolve();
		} catch (err) {
			console.log("initializeHistoryIndex (2)");
			try {
				await index.setSettings(env.algolia.historyIndexSettings);
				console.log("initializeHistoryIndex (4)");
				resolve();
			} catch (err) {
				console.error("initializeHistoryIndex (4)", err);
				reject(err);
			}
		}
	});
}

function initializeOrgIndex(index) {
	return new Promise(async (resolve, reject) => {
		console.log("initializeOrgIndex (1)");
		try {
			await index.getSettings();
			console.log("initializeOrgIndex (3)");
			resolve();
		} catch (err) {
			console.log("initializeOrgIndex (2)");
			try {
				await index.setSettings(env.algolia.orgIndexSettings);
				console.log("initializeOrgIndex (4)");
				resolve();
			} catch (err) {
				console.error("initializeOrgIndex (4)", err);
				reject(err);
			}
		}
	});
}

// function initializeSearchIndex(index) {
// 	return new Promise((resolve, reject) => {
// 		index.getSettings((err) => {
// 			if (err) {
// 				index.setSettings(env.algolia.historyIndexSettings, (err) => {
// 					if (err) {
// 						reject(err);
// 					} else {
// 						resolve();
// 					}
// 				});
// 			} else {
// 				resolve();
// 			}
// 		});
// 	});
// }

export function searchOrgActivities() {

	return new Promise(async (resolve, reject) => {
		try {
			const index = client2.initIndex(getSearchIndexName());

			let hits = [];
			hits = await index.search("");
			resolve(hits.nbHits);

			// Wasn't working
			// index.browse({
			// 	query: "",
			// 	attributesToRetrieve: "*"
			// },
			// 	function searchDone(err, content) {
			// 		debugger;
			// 		if (err) {
			// 			reject(err);
			// 		} else {
			// 			resolve(content);
			// 		}
			// 	});
		} catch (e) {
			console.error(e);
			reject(e);
		}

	});
}

export function getSearchIndex() {
	const index = client2.initIndex(getSearchIndexName());

	return index;
}

export function getHistoryIndex() {
	const index = client2.initIndex(getHistoryIndexName());

	return index;
}

export async function initOrgIndex() {
	const index = client2.initIndex(getSearchIndexName());

	await initializeOrgIndex(index);
}

export async function initHistoryIndex() {
	const index = client2.initIndex(getHistoryIndexName());

	// JJB - Shouldn't need anymore
	// await initializeHistoryIndex(index);
}

// Need to add more generic sticker actions as well such as addSticker, removeSticker, removeAllStickers
// For now just allow hardwired rush job

export async function toggleRushJob(hit) {
	let index = client2.initIndex(getHistoryIndexName());

	if (hit.hasOwnProperty("stickers") && hit.stickers.length > 0) {
		// const updateObject = {
		// 	objectID: hit.objectID,
		// 	stickers: [],
		// 	rankingScore: 0
		// };

		// Need to recreate object to remove properties

		delete hit.stickers;
		delete hit.rankingScore;
		delete hit["__position"];

		await index.saveObject(hit);
	} else {
		const updateObject = {
			objectID: hit.objectID,
			stickers: [{
				label: "RUSH",
				color: "red",
				icon: "shipping-fast"
			}],
			rankingScore: 100
		};

		await index.partialUpdateObject(updateObject);
	}
}

export async function updateDueDate(objectID, dueDate, dueDateIsoString) {
	let index = client2.initIndex(getHistoryIndexName());

	const updateObject = {
		objectID,
		dueDate,
		dueDateIsoString
	};

	await index.partialUpdateObject(updateObject);
}

export function searchUserActivities() {

	console.log("searchUserActivities");
	return new Promise(async (resolve, reject) => {
		try {
			console.log("searchUserActivities (2)");
			const index = client2.initIndex(getHistoryIndexName());

			console.log("searchUserActivities (3)");
			// JJB - Shouldn't need anymore
			// await initializeHistoryIndex(index);


			console.log("searchUserActivities (4)");
			let user = state.select(["user"]).get();

			//use browse to get all records
			if (!_.isUndefined(user)) {

				console.log("searchUserActivities (5)");
				try {
					let hits = [];
					await index.browseObjects({
						query: "",
						attributesToRetrieve: "*",
						filters: `displayName: "${user.name}"`,
						batch: batch => {
							hits = hits.concat(batch);
						}
					});
					console.log("searchUserActivities (6)", hits);
					resolve({ hits });
				} catch (err) {
					if (err.statusCode === 404) {
						// Back off and try again
						setTimeout(async () => {
							console.log("searchUserActivities (7)");
							try {
								let hits = [];
								await index.browseObjects({
									query: "",
									attributesToRetrieve: "*",
									filters: `displayName: "${user.name}"`,
									batch: batch => {
										hits = hits.concat(batch);
									}
								});
								console.log("searchUserActivities (6)", hits);
								resolve({ hits });
							} catch (err) {
								reject(err);
							}

						}, 2000);
					} else {
						console.error("searchUserActivities (x1)", err);
						reject(err);
					}
					console.error("searchUserActivities (5)", err);
				}
			} else {
				resolve({});
			}
		} catch (e) {
			console.error("searchUserActivities (x1)", e);
			console.error(e);
			reject(e);
		}

	});
}
import React from "react";
import { createPortal } from "react-dom";
import { branch } from "baobab-react/higher-order";
import Simplert from "react-simplert";
import { dismissAlert } from "../actions/alertActions";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Utils from "../utils/utils";

// const ReactComponentPrototype = Component.prototype;
// const ReactPureComponentPrototype = PureComponent.prototype;

class Alerts extends React.Component {

	constructor(props) {
		super(props);
		this.refreshCount = 0;
	}

	handleDismiss() {
		dismissAlert();
	}

	// Biggest hack ever...needs to be better way
	componentDidUpdate() {
		if (this.refreshCount % 2 === 0) {
			this.forceUpdate();
		}
		this.refreshCount++;
	}

	render() {
		let alerts = [];
		const self = this;
		this.props.alerts.forEach((alert, idx) => {

			let opts = {
				showSimplert: true,
				customClass: "ambifi-alert",
				customCloseBtnText: "OK",
				onClose: self.handleDismiss.bind(self),
				disableOverlayClick: true,
				type: alert.type === "danger" ? "error" : alert.type
			};

			if (alert.title && alert.title !== "") {
				opts.title = alert.title;
			}

			if (alert.onConfirm) {
				opts.onConfirm = self.handleDismiss.bind(self);
				opts.useConfirmBtn = true;
				opts.onClose = function () { self.handleDismiss(); alert.onConfirm(); };
				opts.customConfirmBtnText = "Cancel";
				opts.customConfirmBtnClass = "simplert-button-transparent";
				opts.customCloseBtnText = "OK";
				opts.customCloseBtnClass = "simplert-button-orange";
				opts.type = "warning";
			}

			if (alert.onYesNo) {
				opts.onConfirm = function () { self.handleDismiss(); alert.onYesNo(false); };
				opts.useConfirmBtn = true;
				opts.onClose = function () { self.handleDismiss(); alert.onYesNo(true); };
				opts.customConfirmBtnText = "No";
				opts.customConfirmBtnClass = "simplert-button-transparent";
				opts.customCloseBtnText = "Yes";
				opts.customCloseBtnClass = "simplert-button-orange";
				opts.type = "warning";
			}

			if (alert.callback) {
				opts.onClose = function () { self.handleDismiss(); alert.callback(); };
			}



			if (alert.hasOwnProperty("message")) {
				let message = null;
				if (_.isString(alert.message)) {
					message = Utils.truncateWithEllipses(alert.message, 1000);
				} else if (_.isObject(alert.message) && alert.message.hasOwnProperty("message")) {
					message = Utils.truncateWithEllipses(alert.message.message, 1000);
				} else if (!alert.message) {
					message = alert.title;
				} else if (React.isValidElement(alert.message.prototype)) {
					message = alert.message;
				}

				if (message) {
					alerts.push(<Simplert key={`alert-${idx}`}
						{...opts} message={message} />);
				} else {
					alerts.push(<Simplert key={`alert-${idx}`}
						{...opts} message={""} />);
				}

			} else {
				console.error("Not a string", alert);
			}
		});

		return createPortal(<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
			{alerts}
		</>, document.body);
	}

}

export default branch(() => {
	return {
		alerts: ["appState", "alerts"]
	};
}, Alerts);
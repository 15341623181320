import React from "react";
import { branch } from "baobab-react/higher-order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flexbox from "flexbox-react";

import MultiSelectToolbar from "./app-multiselect-toolbar";

import { selectAllActivities, resetSelectedActivities } from "../../actions/activityActions";

class MultiSelectActivitiesToolbar extends React.PureComponent {
	render() {
		let multiSelectToolbar =
			<MultiSelectToolbar
				selectedItems={this.props.selectedActivities}
				onClickClose={() => {
					this.props.dispatch(
						resetSelectedActivities
					);
				}}
				onClickSelectAll={() => {
					this.props.dispatch(
						selectAllActivities
					);
				}}>
				<Flexbox flexDirection="row" alignItems="center">
					<div style={{ cursor: "pointer" }} onClick={() => {
					}}>
						<FontAwesomeIcon icon="share-alt-square" />
					</div>
				</Flexbox>
			</MultiSelectToolbar>;

		if (this.props.selectedActivities.length === 0) {
			multiSelectToolbar = null;
		}

		return multiSelectToolbar;
	}
}

export default branch({
	selectedActivities: ["appState", "selectedActivities"],
}, MultiSelectActivitiesToolbar);
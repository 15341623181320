import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import _ from "lodash";
import { FormGroup, FormControl, Panel, ControlLabel } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "react-autobind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//, getActivityLinks, getInternalLinks

import { getLinkInfo, getLinkActionType, getLinkTargetFromLinkInfo, getActivityLinks, getInternalLinks, getInternalLinksFromActivity } from "../../actions/linkingActions";
import LinkActionType from "../itemComponents/LinkActionType";
import { transformFromChecklistEntities } from "../../utils/transformUtils";
import state from "../../state/state";
import ErrorBoundary from "../errors/errorBoundary";
class LinkBuilder extends PureComponent {

	constructor(props) {
		super(props);

		const { linkAction, linkTarget, linkUrl } = this.props;
		// It is important that either the linkUrl is provided which will look as follows:
		// goto:#internalId - as an example this would be a goto to an internalId
		// - OR -
		// the linkAction and linkTarget are provided separate, this is because some places in the activity
		// data model have them separate, for example an item will capture linkActionType and linkId

		const linkInfo = getLinkInfo(linkUrl ? linkUrl : linkTarget ? `${linkAction}:${linkTarget}` : linkAction);
		console.log("CONSTRUCTOR", linkInfo);
		this.state = {
			edit: false,
			internalLink: true,
			linkInfo,
			showLoadInternalIdsSpinner: false,
			linkTargetActivitys: {},
			linkTargetIds: {}
		};
		autoBind(this);
	}

	handleChangeLinkAction(e) {

		const linkAction = e.target.value;
		const linkInfo = _.cloneDeep(this.state.linkInfo);
		linkInfo.actionType = linkAction;



		if (this.props.onChange && _.isFunction(this.props.onChange)) {
			let linkTarget = getLinkTargetFromLinkInfo(linkInfo);
			if (linkInfo.actionType) {
				const linkActionType = getLinkActionType(linkInfo.actionType);
				if (!linkActionType.nav) {
					linkTarget = null;
					linkInfo.targetId = null;
					linkInfo.targetActivityId = null;
					linkInfo.queryString = {};
				}
			}

			this.props.onChange({
				linkAction,
				linkTarget
			});
		}

		this.setState({
			linkInfo
		});
	}

	handleChangeLinkTarget(linkInfo) {
		if (this.props.onChange && _.isFunction(this.props.onChange)) {

			const linkTarget = getLinkTargetFromLinkInfo(linkInfo);
			const linkAction = linkInfo.actionType;

			this.props.onChange({
				linkAction,
				linkTarget
			});
		}
	}

	handleChangeExternalUrl(e) {
		const externalUrl = e.target.value;

		// This might auto select the first item for the currently edited activity

		let linkInfo = _.cloneDeep(this.state.linkInfo);
		linkInfo.targetId = null;
		linkInfo.targetActivityId = externalUrl;

		linkInfo.targetId = null;
		linkInfo.targetActivityId = null;
		linkInfo.externalUrl = externalUrl;
		linkInfo.queryString = {};
		if (!linkInfo.actionType || linkInfo.actionType === "") {
			linkInfo.actionType = "external"; // Fallback to this if it is empty
		}
		this.setState({ linkInfo });
		console.log("Updated external link target", externalUrl, linkInfo);
		this.handleChangeLinkTarget(linkInfo);
	}

	handleChangeTargetActivityId(e) {
		const targetActivityId = e.target.value;

		// This might auto select the first item for the currently edited activity
		this.handleRefreshLinkTargets(targetActivityId, {});
		let linkInfo = _.cloneDeep(this.state.linkInfo);
		linkInfo.targetId = null;
		linkInfo.targetActivityId = targetActivityId;

			linkInfo.targetId = null;
		linkInfo.queryString = {};
		if (!linkInfo.actionType || linkInfo.actionType === "") {
			linkInfo.actionType = "goto"; // Fallback to this if it is empty
		}
		this.setState({ linkInfo });
		console.log("Selected activity", targetActivityId, linkInfo);
		this.handleChangeLinkTarget(linkInfo);
	}

	handleChangeTargetId(e) {
		const targetId = e.target.value;

		let linkInfo = _.cloneDeep(this.state.linkInfo);
		linkInfo.targetId = targetId;
		console.log("Selected entity target", targetId, linkInfo);
		if (!linkInfo.actionType || linkInfo.actionType === "") {
			linkInfo.actionType = "goto"; // Fallback to this if it is empty
		}
		this.setState({
			linkInfo
		});
		this.handleChangeLinkTarget(linkInfo);
	}

	handleRefreshLinkTargets(activityChecklistId, _linkTargetActivitys={}) {
		const { linkTargetActivitys } = this.state;
		const { editActivity } = this.props;
		let activity = linkTargetActivitys[activityChecklistId];

		if (!activity) {
			activity = _linkTargetActivitys[activityChecklistId];
		}

		if (activity) {
			console.log("Getting internal links for activity", activity);
			this.setState({
				showLoadInternalIdsSpinner: true
			});
			setTimeout(() => {
				getInternalLinks(activity.id).then((linkTargetIds) => {
					this.setState({
						linkTargetIds,
						showLoadInternalIdsSpinner: false
					});
				});
			});

		} else if (editActivity) {
			console.log("Getting internal links for edit activity", editActivity);

			const activity = transformFromChecklistEntities(state.get(["tree", "root"]));

			const linkTargetIds = getInternalLinksFromActivity(activity);

				this.setState({
					linkTargetIds
				});
		} else {
			console.log("");
			this.setState({
				linkTargetIds: {}
			});
		}
	}

	render() {
		const { editActivity } = this.props;
		const { linkTargetActivitys, linkTargetIds, linkInfo, showLoadInternalIdsSpinner } = this.state;
		// It is important that either the linkUrl is provided which will look as follows:
		// goto:#internalId - as an example this would be a goto to an internalId
		// - OR -
		// the linkAction and linkTarget are provided separate, this is because some places in the activity
		// data model have them separate, for example an item will capture linkActionType and linkI

		let linkActionType = null;
		if (linkInfo.actionType) {
			linkActionType = getLinkActionType(linkInfo.actionType);
		} else if (linkInfo.url) {
			const _linkActionType = getLinkActionType(linkInfo.url);
			if (_linkActionType) {
				linkActionType = _linkActionType;
			}
		}
		console.log("GET LINK INFO", linkInfo, linkActionType);

		let description = null;
		if (linkActionType !== null && linkActionType.description) {
			description = <Panel bsStyle="info">
				<Panel.Heading>
					<Panel.Title><FontAwesomeIcon icon="info-circle" /> Action Description</Panel.Title>
				</Panel.Heading>
				<Panel.Body>{linkActionType.description}</Panel.Body>
			</Panel>;
		}

		const components = [
			<>
				<p><FontAwesomeIcon icon="arrow-right" /> What kind of link action would you like to perform? Select one to learn more.</p>
				<div style={{ paddingLeft: "10px" }}>
					<LinkActionType includeNone value={linkInfo.actionType ? linkInfo.actionType : linkActionType && linkActionType.action ? linkActionType.action:null } onChange={this.handleChangeLinkAction} />

					{description}
				</div>
			</>
		];

		const linkTargetActivityOptions = [];
		let linkTargetIdOptions = [];

		if (editActivity) {
			// We're in an activity, this should allow the convenience UI of just selecting "internal" link targets
			linkTargetActivityOptions.push(<option key="link-activity-option-null" value="">Somewhere in this Activity</option>);
		}
		let targetActivityId = null;
		let unavailableTargetActivity = false;

		if (linkActionType !== null && linkActionType.nav) {
			if (linkTargetActivitys && _.isPlainObject(linkTargetActivitys) && !_.isEmpty(linkTargetActivitys)) {
				const availableIds = {};
				_.keys(linkTargetActivitys).forEach((checklistId) => {
					const activityInfo = linkTargetActivitys[checklistId];
					availableIds[checklistId] = true;
					linkTargetActivityOptions.push(<option key={`link-activity-option-${checklistId}`} value={checklistId}>{activityInfo.name}</option>);
				});

				targetActivityId = linkInfo && linkInfo.targetActivityId ? linkInfo.targetActivityId : null;
				if (linkActionType.internalOnly) {
					components.push(<>
						<p><FontAwesomeIcon icon="info-circle" /> This link action only allows specifying link targets within this current activity</p>
					</>);
				} else {
					unavailableTargetActivity = (targetActivityId && !availableIds[targetActivityId]);
					if (unavailableTargetActivity) {
						linkTargetActivityOptions.unshift(<option key="link-activity-option-broken" value={targetActivityId} disabled>Choose a new activity link target...</option>);
					}
					components.push(<>
						{unavailableTargetActivity && <div style={{color:"red"}}>
							<FontAwesomeIcon icon="exclamation-triangle" /> This link was originally intended to an activity with link ID <b>{targetActivityId}</b>, however it could not be found in your account.<br/>Your available options are:
							<ul>
								<li>Add the ID {targetActivityId} to an activity in your inventory by navigating to Authoring and editing the intended activity.</li>
								<li>Select a new target below.</li>
							</ul>
						</div>}
						<p><FontAwesomeIcon icon="arrow-right" /> Would like to link to somewhere in <b>this</b> activity or to <b>another</b> activity?</p>
						<div style={{ paddingLeft: "10px" }}>
							<FormGroup controlId="formControlsSelect">
								<FormControl onChange={this.handleChangeTargetActivityId} componentClass="select" placeholder="Select an activity" value={targetActivityId}>
									{linkTargetActivityOptions}
								</FormControl>
							</FormGroup>
						</div>
					</>);
				}
			}
			const availableLinkTargetIds = {};
			if (linkTargetIds && _.isPlainObject(linkTargetIds) && !_.isEmpty(linkTargetIds)) {
				if (targetActivityId) {
					linkTargetIdOptions.push(<option key={"link-target-id-option-null"} value="">None - beginning of activity</option>);
				} else {
					linkTargetIdOptions.push(<option key={"link-target-id-option-null"} value="">You must select a target...</option>);
				}
				_.keys(linkTargetIds).forEach((entityId, idx) => {
					const entityInfo = linkTargetIds[entityId];
					const label = entityInfo.label1 ? entityInfo.label1 : entityInfo.label ? entityInfo.label : entityInfo.name;
					availableLinkTargetIds[entityId] = true;
					linkTargetIdOptions.push(<option key={`link-target-id-option-${entityId}-${idx}`} value={entityId}>({entityInfo.type}) {label}</option>);
				});
			}
			console.log("Render link target options", linkTargetIds, linkTargetIdOptions);


			if (!unavailableTargetActivity) {
			if (showLoadInternalIdsSpinner) {
				components.push(<div style={{ paddingLeft: "10px" }}>
					<FontAwesomeIcon icon="spinner" spin/> Loading internal link targets...
				</div>);
			} else {

				if (linkTargetIdOptions.length > 0) {
					const titleText = targetActivityId ? (
						<p>You can now <b>optionally</b> select a list, section or item the user should be linked to within the selected activity. If you don't make a selection the activity will be started from the beginning.</p>
					) : (
						<p>Please select a list, section or item as a link target.</p>
					);
					const placeholder = "Select a target list, section or item.";
					const value = linkInfo && linkInfo.targetId ? linkInfo.targetId : null;
					const unavailableLinkTarget = (value && value !== "" && !availableLinkTargetIds[value]);
					if (unavailableLinkTarget) {
						if (!targetActivityId) {
							linkTargetIdOptions = _.tail(linkTargetIdOptions);
						}

						linkTargetIdOptions.unshift(<option key="link-target-id-option-broken" style={{color:"red"}} value={targetActivityId}>Choose a new internal link target...</option>);
					}
					components.push(<div key="id-target-select" style={{ paddingLeft: "10px" }}>
						{unavailableLinkTarget && <div style={{ color: "red" }}>
							<FontAwesomeIcon icon="exclamation-triangle" /> This link was originally intended to a list/section or item with ID <b>{value}</b>, however it could not be found in the selected activity.<br />Your available options are:
							<ul>
								<li>Add the ID {value} to the intended target list/section or item in the selected activity.</li>
								<li>Select a new list/section or item target below.</li>
							</ul>
						</div>}
							{titleText}
							<FormGroup controlId="formControlsSelect">
								<FormControl onChange={this.handleChangeTargetId} componentClass="select" placeholder={placeholder} value={value}>
									{linkTargetIdOptions}
								</FormControl>
							</FormGroup>
						</div>);
				} else {
					if (!targetActivityId) {
						components.push(<div key="id-target-error" style={{ paddingLeft: "10px" }}><Panel bsStyle="warning">
							<Panel.Heading>
								<Panel.Title><FontAwesomeIcon icon="exclamation-triangle" /> No Internal Link Targets</Panel.Title>
							</Panel.Heading>
							<Panel.Body>This activity doesn't have any link targets, make sure to add <b>IDs</b> to lists, sections or items to allow linking. Click on a list, section or item and expand the "Advanced" panel, then type an ID into the corresponding textbox.</Panel.Body>
						</Panel></div>);
					} else {
						components.push(<div key="id-target-warning" style={{ paddingLeft: "10px" }}><Panel bsStyle="info">
							<Panel.Heading>
								<Panel.Title><FontAwesomeIcon icon="info-circle" /> No Internal Link Targets</Panel.Title>
							</Panel.Heading>
							<Panel.Body>The selected activity contains no linkable lists, sections or items. Not to worry, the current link will be linking to the beginning of the activity.</Panel.Body>
						</Panel></div>);
					}
				}

			}
			}
		} else if (linkActionType && linkActionType.action === "external") {
			// Need to allow the user to type a URL!
			const url = linkInfo && linkInfo.externalUrl ? linkInfo.externalUrl : "";
			components.push(<FormGroup
				controlId="externalUrl"
			>
				<ControlLabel>External URL:</ControlLabel>
				<FormControl
					type="text"
					value={url}
					placeholder="https://some.external.url"
					onChange={this.handleChangeExternalUrl}
				/>
				<FormControl.Feedback />
			</FormGroup>);
		}
		/*
		
		Tried to get that in quickly but figured out that there is a lot more to this, for one we now need a more complex link data structure
		which is not supported in the players and then we also need to deal with all the resource references now embedded in that new link concept

		else if (linkActionType.action === "showModalDynamicItem") {
			let label1 =
				<BasicPanel titleText="Label 1">
					<div>
						<RichTextEditorWithToggle placeholder="(Label 1)" autoFocus={true} key={label1Key} advanced={label1EditorAdvanced} value={entity.label}
							onChange={(obj) => {
								this.props.dispatch(actions.handleChangeProperty, "label", obj.text);
							}}
							onClickToggle={() => {
								this.props.dispatch(actions.handleChangeProperty, "label1EditorAdvanced", !label1EditorAdvanced);
							}}
						/>
						<div style={{ marginTop: "5px" }}>
							<Checkbox checked={entity.suppressAudioLabel1 === null || entity.suppressAudioLabel1 ? "checked" : ""}
								onChange={(e) => {
									this.props.dispatch(actions.handleChangeProperty, "suppressAudioLabel1", e.target.checked);
								}}>
								Don't Speak Label 1
						</Checkbox>
						</div>
					</div>

				</BasicPanel>;
			let label2 =
				<BasicPanel titleText="Label 2">
					<div>
						<RichTextEditorWithToggle placeholder="(Label 2)" key={label2Key} advanced={label2EditorAdvanced} value={entity.label2}
							onChange={(obj) => {
								this.props.dispatch(actions.handleChangeProperty, "label2", obj.text);
							}}
							onClickToggle={() => {
								this.props.dispatch(actions.handleChangeProperty, "label2EditorAdvanced", !label2EditorAdvanced);
							}}
						/>
						<div style={{ marginTop: "5px" }}>
							<Checkbox checked={entity.suppressAudioLabel2 === null || entity.suppressAudioLabel2 ? "checked" : ""}
								onChange={(e) => {
									this.props.dispatch(actions.handleChangeProperty, "suppressAudioLabel2", e.target.checked);
								}}
							>
								Don't Speak Label 2
						</Checkbox>
						</div>
					</div>

				</BasicPanel>;
			components.push(label1);
			components.push(label2);
			components.push(<MediaPanel
				filterEmpty
				onChange={handleChangeMedia}
				{..._.pick(entity, getMediaPanelRequiredProperties())}
			/>);
		}*/
		return <ErrorBoundary><div>
			{components}
		</div></ErrorBoundary>;
	}

	componentDidMount() {
		const { linkInfo } = this.state;

		const linkTargetActivitys = getActivityLinks();
		console.log("Set link target activities", linkInfo, linkTargetActivitys);
		let activityId = null;
		if (linkInfo && linkInfo.targetActivityId) {
			activityId = linkInfo.targetActivityId;
		}
		this.setState({
			linkTargetActivitys
		});
		this.handleRefreshLinkTargets(activityId, linkTargetActivitys);
	}

}

export default branch({
	editActivity: ["tree", "root", "entity"]
}, LinkBuilder);
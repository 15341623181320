import React from "react";
import { branch } from "baobab-react/higher-order";
import { Nav, Navbar, NavItem, DropdownButton, MenuItem, Form, FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import Flexbox from "flexbox-react";
import MediaQuery from "react-responsive";
import NavItem2 from "./app-nav-item.js";
import Avatar from "../userProfile/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import _ from "lodash";
import Modal from "react-responsive-modal";
import autoBind from "react-autobind";
// import { AmplifySignOut } from "@aws-amplify/ui-react";

import env from "../../constants/env";
import { showConfirm, showError } from "../../actions/alertActions";
import { breakLock } from "../../actions/activityActions";
// import * as actions from "../../actions/actions";
import * as actionsPortal from "../../actions/portalActions";
// import * as utils from "../../utils/utils";
// import browserHistory from "../../history";
import { navigateTo } from "../../actions/navActions";
import { isOrganizationAdministrator, isOrganizationAuthor, getIdentityId, getJwtToken, getInjectedSecurityContext, isOrganizationUser } from "../../actions/user";
import { getOrgId } from "../../actions/orgsActions";
import { isDev, isMobile, handleOpenInNewTab } from "../../utils/utils";

import MultiSelectActivitiesToolbar from "../../components/navPanel/app-multiselect-activities-toolbar";
import MultiSelectMembersToolbar from "../../components/navPanel/app-multiselect-members-toolbar";
import MultiSelectStatusToolbar from "../../components/navPanel/app-multiselect-status-toolbar";
import MultiSelectSearchToolbar from "../../components/navPanel/app-multiselect-search-toolbar";

import { postMessage } from "../../actions/mobileAppCommunicationActions";

import state from "../../state/state";

import MediaPreviewModal from "../../components/shared/mediaPreview";
import { impersonateUser } from "../../actions/adminActions.js";

class NavLinks extends React.PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			showDevInfo: false,
			devInfoForms: {
				impersonateUser: {
				}
			}
		};
		autoBind(this);
	}

	handleLogoutClick() {
		if (window.location.href.indexOf("/editor/") !== -1) {
			showConfirm("Are you sure you want to leave this page? All unsaved changes will be lost.", "Leave Editor?", () => {
				breakLock(this.props.selectedChecklistId).then(() => {
					actionsPortal.logout(this.props.user.username);
				}).catch(err => {
					showError("Break Lock", "Failed to break editing lock, this means that authors won't be able to edit this activity, administrators will be able to manually break the lock. Reason:" + err, () => {
						actionsPortal.logout(this.props.user.username);
					});
				});
				actionsPortal.logout(this.props.user.username);
			});
		} else {
			actionsPortal.logout(this.props.user.username);
		}
	}

	handleProfileClick() {
		navigateTo("profile");
	}

	handleShowDevInfoClick() {
		this.setState({
			showDevInfo: true
		});
	}

	handleCloseDevInfo() {
		this.setState({
			showDevInfo: false
		});
	}

	handleDevInfoFormOnChange(formId, e) {
		this.setState({
			devInfoForms: {
				[formId]: {
					[e.target.name]: e.target.value
				}
			}
		});
	}

	async handleImpersonateUser() {
		const { impersonateCognitoUserSubId } = this.state.devInfoForms.impersonateUser;
		await impersonateUser(getOrgId(), impersonateCognitoUserSubId);
	}

	render() {
		// let self = this;
		const { appCapabilities } = this.props;

		let reorder;
		// if (this.props.showReorder) {
		// 	reorder = <NavItem style={{ marginTop: "8px" }} onClick={this.props.onReorder}><FontAwesomeIcon icon="sort" /> Organize</NavItem>;
		// }

		let barComponents = [], barComponents2 = [];

		let homeNavItem2;

		if (!isMobile()) {
			homeNavItem2 = <NavItem2 key="nav2-home" id="home" label="Home" icon="home" onClick={() => navigateTo("home")} selected={this.props.navPanel.selected === "home" ? true : false} />;
			barComponents2.push(homeNavItem2);
		}


		if (isOrganizationAdministrator() || isOrganizationAuthor()) {
			barComponents2.push(<NavItem2 key="nav2-my-checklists" id="myChecklists" label="Authoring" icon="edit" onClick={() => navigateTo("myChecklists")} selected={this.props.navPanel.selected === "myChecklists" ? true : false} filtersAll={this.props.filtersAll} />);
		}

		barComponents2.push(<NavItem2 key="nav2-search" id="search" label="Search" icon="search" onClick={() => navigateTo("search")} selected={this.props.navPanel.selected === "search" ? true : false} />);


		if (state.get(["user", "chatEnabled"])) {
			barComponents2.push(<NavItem2 key="nav2-chat" id="chat" label="Chat" bubbleCount={this.props.chatUnreadCount} icon="comments-alt" onClick={() => navigateTo("chat")} selected={this.props.navPanel.selected === "chat" ? true : false} />);
		}

		const isAdmin = isOrganizationAdministrator();
		const isAuthor = isOrganizationAuthor();
		const isUser = isOrganizationUser();

		if (isAdmin) {
			if (appCapabilities.assignments) {
				barComponents2.push(<NavItem2 key="nav2-assignments" id="assignments" label="Assignments" icon={["far", "calendar-check"]} onClick={() => navigateTo("assignments")} selected={this.props.navPanel.selected === "assignments" ? true : false} />);
			}

			barComponents2.push(<NavItem2 key="nav2-search-history" id="searchHistory" label="Status" icon="chart-bar" onClick={() => navigateTo("searchHistory")} selected={this.props.navPanel.selected === "searchHistory" ? true : false} />);
			barComponents2.push(<NavItem2 key="nav2-dashboard" id="dashboard" label="Dashboard" icon="chart-line" onClick={() => navigateTo("dashboard")} selected={this.props.navPanel.selected === "dashboard" ? true : false} />);
			barComponents2.push(<NavItem2 key="nav2-timers" id="timers" label="Timers" icon="clock" onClick={() => navigateTo("timers")} selected={this.props.navPanel.selected === "timers" ? true : false} />);
			if (appCapabilities.publications) {
				barComponents2.push(<NavItem2 key="nav2-publications" id="publications" label="Publications" icon="books" onClick={() => navigateTo("publications")} selected={this.props.navPanel.selected === "publications" ? true : false} />);
			}
			barComponents2.push(<NavItem2 key="nav2-import" id="import" label="Import" icon="upload" onClick={() => navigateTo("import")} selected={this.props.navPanel.selected === "import" ? true : false} />);

			barComponents2.push(<NavItem2 key="nav2-drive" id="drive" label="Drive" icon={["far", "hdd"]} onClick={() => navigateTo("drive")} selected={this.props.navPanel.selected === "drive" ? true : false} />);
			barComponents2.push(<NavItem2 key="nav2-organization" id="organization" label="Organization" icon="building" onClick={() => navigateTo("organization")} selected={this.props.navPanel.selected === "organization" ? true : false} />);

			// if (isDev()) {
			// 	barComponents2.push([<NavItem2 key="nav2-report-builder" id="reporting" label="Reporting" icon="file-pdf" onClick={() => navigateTo("reporting")} selected={this.props.navPanel.selected === "reporting" ? true : false} />,
			// 	<NavItem2 key="nav2-content-builder" id="cms" label="Content Mgmt" icon="file-alt" onClick={() => navigateTo("cms")} selected={this.props.navPanel.selected === "cms" ? true : false} />]);
			// }
		} else if (isAuthor) {
			if (appCapabilities.assignments) {
				barComponents2.push(<NavItem2 key="nav2-assignments" id="assignments" label="Assignments" icon="calendar-check" onClick={() => navigateTo("assignments")} selected={this.props.navPanel.selected === "assignments" ? true : false} />);
			}
			barComponents2.push(<NavItem2 key="nav2-search-history" id="searchHistory" label="Status" icon="chart-bar" onClick={() => navigateTo("searchHistory")} selected={this.props.navPanel.selected === "searchHistory" ? true : false} />);

			barComponents2.push(<NavItem2 key="nav2-import" id="import" label="Import" icon="download" onClick={() => navigateTo("import")} selected={this.props.navPanel.selected === "import" ? true : false} />);
			barComponents2.push(<NavItem2 key="nav2-drive" id="drive" label="Drive" icon={["far", "hdd"]} onClick={() => navigateTo("drive")} selected={this.props.navPanel.selected === "drive" ? true : false} />);
		} else if (isUser) {
			barComponents2.push(<NavItem2 key="nav2-search-history" id="searchHistory" label="Status" icon="chart-bar" onClick={() => navigateTo("searchHistory")} selected={this.props.navPanel.selected === "searchHistory" ? true : false} />);
		}

		if (isAdmin || isAuthor) {
			barComponents2.push(<NavItem2 key="nav2-support" id="support" label="Getting Started" icon="info-circle" onClick={(e) => {
				if (isMobile()) {
					e.preventDefault();
					postMessage({
						external_url_open: "https://docs.ambifi.com"
					});
				} else {
					handleOpenInNewTab("https://docs.ambifi.com");
				}
			}} />);
			barComponents2.push(<NavItem2 key="nav2-developers" id="developers" label="Developers" icon="info-circle" onClick={(e) => {
				if (isMobile()) {
					e.preventDefault();
					postMessage({
						external_url_open: "https://ambifi.atlassian.net/wiki/spaces/DOC/overview"
					});
				} else {
					handleOpenInNewTab("https://ambifi.atlassian.net/wiki/spaces/DOC/overview");
				}
			}} />);
		}

		if (this.props.editor) {

			let homeNavItem;

			const admin = isOrganizationAdministrator();
			const author = isOrganizationAuthor();

			if (!isMobile()) {
				homeNavItem = <NavItem key="nav-home" style={{ marginTop: "8px" }} id="home" label="Home" icon="home" onClick={() => navigateTo("home")} selected={this.props.navPanel.selected === "home" ? true : false}><FontAwesomeIcon icon="home" /></NavItem >;
				barComponents.push(homeNavItem);
			}

			if (admin || author) {
				barComponents.push(<NavItem key="nav-my-checklists" style={{ marginTop: "8px" }} id="myChecklists" label="Authoring" icon="edit" onClick={() => navigateTo("myChecklists")} selected={this.props.navPanel.selected === "myChecklists" ? true : false} filtersAll={this.props.filtersAll}><FontAwesomeIcon icon="edit" /></NavItem>);
			}

			barComponents.push(<NavItem key="nav-search" style={{ marginTop: "8px" }} id="search" label="Search" title="Search" icon="search" onClick={() => navigateTo("search")} selected={this.props.navPanel.selected === "search" ? true : false}><FontAwesomeIcon icon="search" /></NavItem >);

			if (state.get(["user", "chatEnabled"])) {
				barComponents.push(<NavItem key="nav-chat" style={{ marginTop: "8px" }} id="chat" label="Chat" title="Chat" bubbleCount={this.props.chatUnreadCount} icon="comments-alt" onClick={() => navigateTo("chat")} selected={this.props.navPanel.selected === "chat" ? true : false}><FontAwesomeIcon icon="comments-alt" /></NavItem >);
			}

			if (admin) {
				if (appCapabilities.assignments) {
					barComponents.push(<NavItem key="nav-assignments" style={{ marginTop: "8px" }} id="assignments" label="Assignments" title="Assignments" icon={["far", "calendar-check"]} onClick={() => navigateTo("assignments")} selected={this.props.navPanel.selected === "assignments" ? true : false}><FontAwesomeIcon icon={["far", "calendar-check"]} /></NavItem>);
				}

				barComponents.push(<NavItem key="nav-search-history" style={{ marginTop: "8px" }} id="searchHistory" label="Status" title="Status" icon="chart-bar" onClick={() => navigateTo("searchHistory")} selected={this.props.navPanel.selected === "searchHistory" ? true : false}><FontAwesomeIcon icon="chart-bar" /></NavItem >);
				barComponents.push(<NavItem key="nav-dashboard" style={{ marginTop: "8px" }} id="dashboard" label="Dashboard" title="Dashboard" icon="chart-line" onClick={() => navigateTo("dashboard")} selected={this.props.navPanel.selected === "dashboard" ? true : false}><FontAwesomeIcon icon="chart-line" /></NavItem >);
				barComponents.push(<NavItem key="nav-timers" style={{ marginTop: "8px" }} id="timers" label="Timers" title="Timers" icon={["far", "clock"]} onClick={() => navigateTo("timers")} selected={this.props.navPanel.selected === "timers" ? true : false}><FontAwesomeIcon icon={["far", "clock"]} /></NavItem >);
				if (appCapabilities.publications) {
					barComponents.push(<NavItem key="nav-publications" style={{ marginTop: "8px" }} id="publications" label="Publications" title="Publication" icon="books" onClick={() => navigateTo("publications")} selected={this.props.navPanel.selected === "publications" ? true : false}><FontAwesomeIcon icon="book" /></NavItem>);
				}
				barComponents.push(<NavItem key="nav-import" style={{ marginTop: "8px" }} id="import" label="Import" title="Import" icon="upload" onClick={() => navigateTo("import")} selected={this.props.navPanel.selected === "history" ? true : false}><FontAwesomeIcon icon="download" /></NavItem>);
				barComponents.push(<NavItem key="nav-drive" style={{ marginTop: "8px" }} id="drive" label="Drive" title="Drive" icon={["far", "hdd"]} onClick={() => navigateTo("drive")} selected={this.props.navPanel.selected === "drive" ? true : false}><FontAwesomeIcon icon={["far", "hdd"]} /></NavItem>);
				barComponents.push(<NavItem key="nav-organization" style={{ marginTop: "8px" }} id="organization" label="Organization" title="Organization" icon={["far", "building"]} onClick={() => navigateTo("organization")} selected={this.props.navPanel.selected === "organization" ? true : false}><FontAwesomeIcon icon={["far", "building"]} /></NavItem>);

				// if (isDev()) {
				// 	barComponents.push([<NavItem key="nav-report-builder" style={{ marginTop: "8px" }} id="reporting" label="Reporting" title="Reporting" icon={["far", "file-pdf"]} onClick={() => navigateTo("reporting")} selected={this.props.navPanel.selected === "reporting" ? true : false}><FontAwesomeIcon icon={["far", "file-pdf"]} /></NavItem>,
				// 	<NavItem key="nav-content-builder" style={{ marginTop: "8px" }} id="cms" label="Content Mgmt" title="Content Mgmt" icon={["far", "file-alt"]} onClick={() => navigateTo("cms")} selected={this.props.navPanel.selected === "cms" ? true : false}><FontAwesomeIcon icon={["far", "file-alt"]} /></NavItem>]);
				// }
			} else if (author) {
				if (appCapabilities.assignments) {
					barComponents.push(<NavItem key="nav-assignments" style={{ marginTop: "8px" }} id="assignments" label="Assignments" title="Assignments" icon={["far", "calendar-check"]} onClick={() => navigateTo("assignments")} selected={this.props.navPanel.selected === "assignments" ? true : false}><FontAwesomeIcon icon="calendar-check" /></NavItem>);
				}

				barComponents.push(<NavItem key="nav-import" style={{ marginTop: "8px" }} id="import" label="Import" title="Import" icon="download" onClick={() => navigateTo("import")} selected={this.props.navPanel.selected === "history" ? true : false}><FontAwesomeIcon icon="download" /></NavItem>);
				barComponents.push(<NavItem key="nav-drive" style={{ marginTop: "8px" }} id="drive" label="Drive" title="Drive" icon={["far", "hdd"]} onClick={() => navigateTo("drive")} selected={this.props.navPanel.selected === "drive" ? true : false}><FontAwesomeIcon icon={["far", "hdd"]} /></NavItem>);
			}

			if (admin || author) {
				// <NavItem id="support" key="nav:support" label="Support" icon="info-circle" onClick={() => handleOpenInNewTab("https://docs.ambifi.com")} />
				barComponents.push(<NavItem key="nav-support" style={{ marginTop: "8px" }} id="support" label="Getting Started" title="Getting Started" icon="info-circle" onClick={(e) => {
					if (isMobile()) {
						e.preventDefault();
						postMessage({
							external_url_open: "https://docs.ambifi.com"
						});
					} else {
						handleOpenInNewTab("https://docs.ambifi.com");
					}
				}}><FontAwesomeIcon icon="info-circle" /></NavItem>);
				barComponents.push(<NavItem key="nav-developers" style={{ marginTop: "8px" }} id="developers" label="Developers" title="Developers" icon="info-circle" onClick={(e) => {
					if (isMobile()) {
						e.preventDefault();
						postMessage({
							external_url_open: "https://ambifi.atlassian.net/wiki/spaces/DOC/overview"
						});
					} else {
						handleOpenInNewTab("https://ambifi.atlassian.net/wiki/spaces/DOC/overview");
					}
				}}><FontAwesomeIcon icon="info-circle" /></NavItem>);
			}
		}


		// let title = <span><i className="fa fa-user"></i> {this.props.user.username}</span>; 
		//name={this.props.user.displayName}
		console.log("DISPLAY NAME", this.props.user.displayName);
		const avatarName = this.props.selectedOrg && this.props.selectedOrg.member ? this.props.selectedOrg.member.displayName : this.props.user.username;


		// if (utils.isMobile()) {
		const profileButton =
			<DropdownButton style={{ border: "none", backgroundColor: "inherit" }} title={<Avatar size={50} name={avatarName} round />} key="dropdown-user" id="dropdown-user">
				{/* <MenuItem eventKey="1" onClick={this.handleProfileClick.bind(this)}><FontAwesomeIcon icon="user-circle" /> Profile</MenuItem> */}
				<MenuItem eventKey="2" onClick={this.handleProfileClick.bind(this)}><FontAwesomeIcon icon={["far", "user-circle"]} /> Profile</MenuItem>
				{((this.props.urlParams.hasOwnProperty("showDevInfo") && this.props.urlParams.showDevInfo === "true") || isDev() || isAdmin) && <MenuItem eventKey="3" onClick={this.handleShowDevInfoClick.bind(this)}><FontAwesomeIcon icon="info-circle" /> Show Dev Info</MenuItem>}
				<MenuItem eventKey="2" onClick={this.handleLogoutClick.bind(this)}><FontAwesomeIcon icon="sign-out" /> Logout</MenuItem>
			</DropdownButton>;
		const profileButtonMobile =
			<DropdownButton style={{ border: "none", backgroundColor: "inherit" }} pullRight title={<Avatar size={50} name={avatarName} round />} key="dropdown-user" id="dropdown-user">
				{/* <MenuItem eventKey="1" onClick={this.handleProfileClick.bind(this)}><FontAwesomeIcon icon="user-circle" /> Profile</MenuItem> */}
				<MenuItem eventKey="2" onClick={this.handleProfileClick.bind(this)}><FontAwesomeIcon icon={["far", "user-circle"]} /> Profile</MenuItem>
				{((this.props.urlParams.hasOwnProperty("showDevInfo") && this.props.urlParams.showDevInfo === "true") || isDev() || isAdmin) && <MenuItem eventKey="3" onClick={this.handleShowDevInfoClick.bind(this)}><FontAwesomeIcon icon="info-circle" /> Show Dev Info</MenuItem>}
				<MenuItem eventKey="2" onClick={this.handleLogoutClick.bind(this)}><FontAwesomeIcon icon="sign-out" /> Logout</MenuItem>
			</DropdownButton>;

		// } else {
		// 	profileButton =
		// 		<DropdownButton title={title} key="dropdown-user" id="dropdown-user">
		// 			<MenuItem eventKey="1" onClick={this.handleLogoutClick.bind(this)}><FontAwesomeIcon icon="user-circle" /> Profile</MenuItem>
		// 			<MenuItem eventKey="2" onClick={this.handleLogoutClick.bind(this)}><FontAwesomeIcon icon="sign-out" /> Logout</MenuItem>
		// 			<MenuItem divider />
		// 			<MenuItem eventKey="3" onClick={() => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/subscribe"); }}>Subscription</MenuItem>
		// 			<MenuItem divider />
		// 			<MenuItem eventKey="4" onClick={() => { self.props.dispatch(actions.setLastLocation, window.location); browserHistory.push("/updateCustomerCard"); }}>Update Card Info</MenuItem>
		// 		</DropdownButton>;
		// }

		const defaultLogoUrl = "/assets/ambifi-logo-only-64x64.png";

		let img = <img alt="AmbiFi Logo" style={styles.logoImage} src={defaultLogoUrl} />;
		let orgTitle = "AmbiFi Cloud";

		if (this.props.selectedOrg) {
			let imgUrl = this.props.selectedOrg.logoUrl && this.props.selectedOrg.logoUrl !== "none" ? this.props.selectedOrg.logoUrl : defaultLogoUrl;
			// let imgUrl = "/assets/ambifi-logo-only-64x64.png";
			// let imgUrl = "/assets/test/testLogo.png";
			img = <img alt={`${this.props.selectedOrg.name} Logo`} style={styles.logoImage} src={imgUrl} />;
			orgTitle = this.props.selectedOrg.logoUrl ? "" : this.props.selectedOrg.name;
		}
		let logoComponent = <Flexbox alignItems="center">{img}<h2 className="orgLogo" style={{ margin: "0 0 0 10px", color: "#000000", cursor: "pointer" }} onClick={() => { navigateTo("myChecklists"); }}>{orgTitle}</h2></Flexbox>;

		// barComponents.push(<AmplifySignOut />);
		// barComponents2.push(<AmplifySignOut />);

		const identityId = getIdentityId();
		return (
			<div className="pageNav">
				<Navbar fluid fixedTop inverse className={this.props.forceShowNav ? "forceShow" : ""}>
					<Navbar.Header style={{ height: "100%" }}>
						<Navbar.Brand style={{ justifyContent: "center", alignItems: "center", display: "flex", height: "62px" }}>
							<MediaQuery query='(min-width: 768px)'>
								{logoComponent}
							</MediaQuery>
							<MediaQuery query='(max-width: 767px)'>
								{img}
							</MediaQuery>
						</Navbar.Brand>
						<Navbar.Toggle />
						<MediaQuery query='(max-width: 767px)'>
							<NavItem className="profile-dropdown-item" style={{ float: "right" }}>
								{profileButtonMobile}
							</NavItem>
						</MediaQuery>

					</Navbar.Header>
					<Navbar.Collapse>
						<MediaQuery query='(max-width: 767px)'>
							<Nav pullRight style={{ display: "flex", flexDirection: "column", cursor: "pointer", float: "rigth" }}>
								{/* <NavItem2 id="home" label="Home" icon="home" onClick={() => navigateTo("home")} selected={this.props.navPanel.selected === "home" ? true : false} /> */}
								{barComponents2}
								{reorder}
								{/* <NavItem href="https://forum.miralouaero.com" target="_blank"><Button><FontAwesomeIcon fixedWidth={true} style={{ fontWeight: "bold", color: "green", fontSize: "16px" }} icon="question-circle" /></Button></NavItem> */}
							</Nav>
						</MediaQuery>
						<MediaQuery query='(min-width: 768px)'>
							<Nav pullRight>
								{barComponents}
								{reorder}
								<NavItem className="profile-dropdown-item">
									{profileButton}
								</NavItem>
								{/* <NavItem href="https://forum.miralouaero.com" target="_blank"><Button><FontAwesomeIcon fixedWidth={true} style={{ fontWeight: "bold", color: "green", fontSize: "16px" }} icon="question-circle" /></Button></NavItem> */}
							</Nav>
						</MediaQuery>
					</Navbar.Collapse>

					<MultiSelectActivitiesToolbar />
					<MultiSelectMembersToolbar />
					<MultiSelectStatusToolbar />
					<MultiSelectSearchToolbar />
				</Navbar>
				<Modal open={this.state.showDevInfo} onClose={this.handleCloseDevInfo} classNames={{ modal: "custom-modal dev-info-modal" }}>
					<h3 style={{ textAlign: "center" }}>Dev Info</h3>
					<div className="dev-info-modal-body">
						<h4>User Info</h4>
						<div>Quick Links:<br />
							<ul>
								<li><a rel="noopener noreferrer" href={`https://s3.console.aws.amazon.com/s3/buckets/${env.s3.contentBucket}/${identityId}/?region=us-east-1&tab=overview`} target="_blank">User S3 Bucket</a></li>
							</ul>
						</div>
						<div>User Sub Id (Cognito):<br />
							<textarea style={{ width: "100%", height: "100px" }}>{identityId}</textarea></div>
						<div>Organization Id:<br />
							<textarea style={{ width: "100%", height: "100px" }}>{getOrgId()}</textarea></div>
						<div>JWT:<br /><textarea style={{ width: "100%", height: "400px" }}>{this.props.user.loggedIn ? getJwtToken() : "Not Logged In"}</textarea></div>
						<div>Injected Security Context:<br /><textarea style={{ width: "100%", height: "400px" }}>{this.props.user.loggedIn ? getInjectedSecurityContext() : "Not Logged In"}</textarea></div>
						<br />
						<h4>Impersonate User</h4>
						<div className="dev-info-warning">Warning: When impersonating a user, you will lose this session and log in as that user</div>
						<Form>
							<FormGroup>
								<ControlLabel>Cognito User Sub ID</ControlLabel>{" "}
								<FormControl
									type="text"
									value={this.state.impersonateCognitoUserSubId}
									name="impersonateCognitoUserSubId"
									onChange={(e) => this.handleDevInfoFormOnChange("impersonateUser", e)}
								/>
							</FormGroup>
							<Button onClick={this.handleImpersonateUser}>Impersonate</Button>
						</Form>
					</div>
				</Modal>
				<MediaPreviewModal />
			</div>
		);
	}
}

const styles = {
	logoImage: { maxHeight: "50px", maxWidth: "200px", marginTop: "auto", marginBottom: "auto", marginLeft: "10px" }
};

export default branch({
	appCapabilities: ["appCapabilities"],
	navPanel: ["appState", "navPanel"],
	user: ["user"],
	forceShowNav: ["appState", "editor", "forceShowNav"],
	selectedOrg: ["selectedOrg"],
	selectedActivites: ["appState", "selectedActivities"],
	selectedMembers: ["appState", "selectedMembers"],
	selectedStatusItems: ["appState", "selectedStatusItems"],
	selectedSearchItems: ["appState", "selectedSearchItems"],
	urlParams: ["appState", "urlParams"],
	chatUnreadCount: ["appState", "streamChat", "unreadMessagesCount"]
}, NavLinks);
import mime from "mime";

const PROP_BACKGROUND_IMAGE = "backgroundImage";
const PROP_IMAGE = "image";
const PROP_VIDEO = "video";
const PROP_AUDIO = "audio";
const PROP_LABEL_AUDIO_FILE = "labelAudioFile";
const PROP_LABEL1_AUDIO_FILE = "label1AudioFile";
const PROP_LABEL2_AUDIO_FILE = "label2AudioFile";
const PROP_COMMENTS_AUDIO_FILE = "commentsAudioFile";
const PROP_PDF = "pdf";
const PROP_THREED = "threeD";
const PROP_ENTITY_ID = "entityId";

const REQUIRED_MEDIA_PANEL_PROPS = [
	PROP_BACKGROUND_IMAGE,
	PROP_IMAGE,
	PROP_VIDEO,
	PROP_AUDIO,
	PROP_LABEL_AUDIO_FILE,
	PROP_LABEL1_AUDIO_FILE,
	PROP_LABEL2_AUDIO_FILE,
	PROP_COMMENTS_AUDIO_FILE,
	PROP_PDF,
	PROP_THREED,
	PROP_ENTITY_ID
];

const AVAILABLE_MEDIA_ATTACHMENT_TYPES = [
	{
		accept: "image/*",
		label: "Background Image",
		shortLabel: "Background",
		propName: PROP_BACKGROUND_IMAGE,
		icon: ["far", "image"],
		addIcon: "camera-retro"
	},
	{
		accept: "image/*",
		label: "Image",
		propName: PROP_IMAGE,
		icon: ["far", "file-image"],
		addIcon: "camera"
	},
	{
		accept: "video/*",
		label: "Video",
		propName: PROP_VIDEO,
		icon: "file-video",
		addIcon: "video"
	},
	{
		accept: "model/gltf+json",
		label: "3D",
		propName: PROP_THREED,
		icon: ["far", "cube"],
		// toolbarMore: true,
		noCapture: true
	},
	{
		accept: "audio/*",
		label: "Audio",
		propName: PROP_AUDIO,
		icon: ["far", "file-audio"],
		// toolbarMore: true,
		noCapture: true
	},
	{
		accept: "application/pdf",
		label: "PDF",
		propName: PROP_PDF,
		icon: ["far", "file-pdf"],
		// toolbarMore: true,
		noCapture: true
	},
];

export function getAvailableMediaAttachmentTypes() {
	return AVAILABLE_MEDIA_ATTACHMENT_TYPES;
}

export function getMediaPanelRequiredProperties() {
	return REQUIRED_MEDIA_PANEL_PROPS;
}

export function getMediaType(ext) {
	const type = mime.getType(ext);
	if (type) {
		if (type.startsWith("image/")) {
			return "image";
		} else if (type.startsWith("audio/")) {
			return "audio";
		} else if (type.startsWith("video/")) {
			return "video";
		} else if (type === "application/pdf") {
			return "pdf";
		} else if (type === "model/gltf+json") {
			return "gltf";
		}
	} else {
		return null;
	}
}
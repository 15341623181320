
import React from "react";
import { branch } from "baobab-react/higher-order";
import { createPortal } from "react-dom";
import Loader from "react-loader-advanced";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import DeplayedHideButton from "./delayedHideButton";

const _Loader = ({ message, showLoader, ...props }) => {
	let handleOnHide = null;
	if (props.onRequestHide && _.isFunction(props.onRequestHide)) {
		handleOnHide = () => {
			props.onRequestHide();
		};
	}

	if (props.progressPercent || props.progressMessage) {
		message = <div style={{ position: "relative" }}>
			<FontAwesomeIcon
				icon='spinner'
				size='4x'
				spin
				color='#ffffff44' />
			<div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
				<div style={{ color: "skyblue", fontWeight: "bold", fontSize: "16px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
					<div>
						{props.progressMessage && props.progressMessage}
					</div>
					<div>
						{props.progressPercent ? (props.progressMessage ? "(" + props.progressPercent + "%)" : props.progressPercent + "%") : null}
					</div>
				</div>
			</div>
		</div>
	}

	const loader = <>
		<FontAwesomeIcon
			icon='spinner'
			size='4x'
			spin
		/>
		{props.show && (!props.hasOwnProperty("omitHideButton") || !props.omitHideButton) && <DeplayedHideButton delay={props.hasOwnProperty("hideButtonDelay") && _.isNumber(props.hideButtonDelay) ? props.hideButtonDelay : 10} onClick={handleOnHide} />}
	</>;

	const _message = (message) ? showLoader ? <>
		<div style={{ position: "relative" }}>
			<FontAwesomeIcon
				icon='spinner'
				size='4x'
				spin
				color='#ffffff44' />
			<div style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
				<div style={{ color: "skyblue", fontWeight: "bold", fontSize: "16px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
					<div>
						{message && message}
					</div>
				</div>
			</div>
		</div>
	</> : message : loader;

	const _props = _.assign({}, {
		style: { margin: "0px", padding: "0px" },
		className: "col-xs-12",
		backgroundStyle: { overflow: "auto", height: "calc(100% - 20px)", borderRadius: "4px" },
		message: _message
	}, props);


	if (_props.panel && props.show) {
		delete _props.style;
		_props.className = "panelLoader";
	} else if (_props.modal && props.show) {
		delete _props.style;
		_props.className = "modalLoader";
	} else if (_props.fullPage && props.show) {
		delete _props.style;
		_props.className = "fullPageLoader";
	}


	let comp = (props.children) ? (
		<Loader {..._props}>
			{props.show && (!props.hasOwnProperty("omitHideButton") || !props.omitHideButton) && <DeplayedHideButton delay={2} onClick={handleOnHide} />}
			{props.children}
		</Loader>
	) : (
		<Loader {..._props}>
			<span />
		</Loader>
	);

	return _props.fullPage ? createPortal(comp, document.body) : comp;
};

export default branch({
	progressPercent: ["drive", "showSpinner", "progressPercent"],
	progressMessage: ["drive", "showSpinner", "progressMessage"]
}, _Loader);
import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import autoBind from "react-autobind";
import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setFilterTextProperty } from "../../actions/inventoryActions";

class FilterTextbox extends PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleFilterActivities(e) {
		setFilterTextProperty(e.target.value);
	}

	render() {
		return <FormGroup style={{ margin: 0 }}>
			<InputGroup>
				<InputGroup.Addon><FontAwesomeIcon icon="filter" /></InputGroup.Addon>
				<FormControl placeholder="Type to filter" defaultValue={this.props.filterText ? this.props.filterText : ""} type="text" onChange={this.handleFilterActivities} />
			</InputGroup>
		</FormGroup>;
	}
}


FilterTextbox.contextTypes = {
	tree: PropTypes.baobab
};
export default branch({

	filterText: ["appState", "filters", "all", "text"]
}, FilterTextbox);
import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isConnected } from "../../utils/connectivityUtil";

class StatusIndicator extends PureComponent {

	render() {
		// console.log("Connection", isConnected());
		const connected = isConnected();

		let connectedText;
		if (this.props.showConnectedText) {
			connectedText = <span>&nbsp;{connected ? "Connected" : "No Connection"}</span>;
		}

		return (
			<div>
				<b><FontAwesomeIcon style={{ color: connected ? "lightgreen" : "red" }} icon="wifi" /></b>{connectedText}
			</div>
		);
	}
}

StatusIndicator.defaultProps = {
	showConnectedText: true
};

export default branch({
	connection: ["connection"]
}, StatusIndicator);
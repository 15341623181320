export default {
	black: "black",
	white: "white",
	red: "red",
	green: "green",
	blue: "blue",
	checkedBlack: "#000000",
	checkedGreen: "#008800",
	checkedBlue: "#215EBD",
	appBar: {
		sideMenuIcon: {
			name: "bars",
			color: "white",
			size: 30
		},
		container: {
			backgroundColor: "#121212"
		},
		activityName: {
			color: "white"
		},
		timer: {
			wrapper: {
			},
			containerDigits: {

			},
			timerDigits: {
				fontSize: 48,
				color: "white",
				fontWeight: "bold",
				fontFamily: "Courier"
			},
			containerLabels: {
				backgroundColor: "#444444"
			},
			label1Text: {
				color: "white",
				fontSize: 14
			},
			label2Text: {
				color: "white",
				fontSize: 20
			},
			progressBarContainer: {
				backgroundColor: "#444444"
			},
			progressBar: {

			},
			progressBarLessThan50PercentDone: {
				backgroundColor: "lightgreen"
			},
			progressBar50To75PercentDone: {
				backgroundColor: "orange"
			},
			progressBarOver75PercentDone: {
				backgroundColor: "red"
			}
		}
	},
	breadcrumb: {
		container: {
			width: "100%",
			height: 40,
			backgroundColor: "#444444",
			borderBottomWidth: 1,
			borderBottomColor: "#888888"
		},
		dialog: {
			container: {
				backgroundColor: "rgba(68,68,68,.8)"
			},
			closeButton: {
				width: 34,
				height: 34,
				borderRadius: 17,
				backgroundColor: "#888888",
				borderWidth: 1,
				borderColor: "white"
			},
			closeButtonIconStyles: {
				color: "white"
			},
			closeButtonIcon: "times",
			navStackRow: {
				height: 50,
				width: "100%",
				borderBottomWidth: 1,
				borderBottomColor: "lightgray"
			},
			navStackDismissRow: {
				height: 50,
				width: "100%"
			},
			itemRow: {
				width: "100%",
				height: 35,
				borderBottomWidth: 1,
				borderBottomColor: "lightgray"
			},
			itemRowSelected: {
				backgroundColor: "rgba(255,255,255,.4)",
				borderLeftWidth: 3,
				borderLeftColor: "white"
			},
			itemRowNotVisible: {
				opacity: 0.8
			},
			sectionRow: {
				width: "100%",
				height: 35,
				borderBottomWidth: 1,
				borderBottomColor: "lightgray"
			},
			sectionRowSelected: {
				backgroundColor: "rgba(255,255,255,.3)"
			},
			sectionRowNotVisible: {
				opacity: 0.8
			},
			listRow: {
				width: "100%",
				height: 35,
				borderBottomWidth: 1,
				borderBottomColor: "lightgray"
			},
			listRowSelected: {
				backgroundColor: "rgba(255,255,255,.2)"
			},
			listRowNotVisible: {
				opacity: 0.8
			},
			treeFunctionBarWrapper: {
				width: "100%",
				backgroundColor: "rgba(0,0,0,.1)",
				borderTopColor: "white",
				borderBottomColor: "white",
				borderTopWidth: 1,
				borderBottomWidth: 2
			},
			searchBox: {
				backgroundColor: "#1F1F1F",
				color: "white",
				height: 30,
				borderRadius: 5
			},
			functionButtons: {
				height: 30
			},
			functionButton: {
				width: 30,
				height: 30,
				backgroundColor: "#1F1F1F",
				borderRadius: 5
			},
			functionButtonText: {
				color: "white",
				fontSize: 16
			},
			structureModeRow: {
				width: "100%",
				height: 100,
				borderBottomColor: "lightgray"
			},
			structureModeRowSelected: {
				backgroundColor: "rgba(255,255,255,.3)"
			},
			structureModeRowFunctions: {
				backgroundColor: "#1F1F1F",
				borderBottomWidth: 0,
				borderTopWidth: 2
			},
			structureModeRowButton: {
				width: 120,
				height: 35,
				backgroundColor: "red",
				borderRadius: 5
			},
			structureModeRowButtonLabel: {
				color: "white"
			},
			expandCollapseButton: {
				backgroundColor: "rgb(74,74,74)",
				borderRadius: 3,
				borderColor: "#cccccc",
				borderWidth: 1,
				height: 26
			}
		},
		chevron: {
			width: 12,
			height: "100%"
		},
		chevronIcon: "chevron-right",
		chevronIconStyles: {
			color: "#999999"
		},
		historyButton: {
			container: {
				width: 68,
				height: 34,
				backgroundColor: "#888888",
				borderRadius: 5
			},
			buttons: {
				goBack: {
					width: 34
				},
				goBackIcon: "arrow-left",
				goBackIconStyles: {
					fontWeight: "bold",
					color: "white"
				},
				toggleHistory: {
					width: 34
				},
				toggleHistoryIcon: "sort-down",
				toggleHistoryIconStyles: {
					fontWeight: "bold",
					color: "white"
				},
			},
			buttonSeparator: {
				height: "80%",
				width: 1,
				backgroundColor: "lightgray"
			}
		},
		itemStyles: {
			height: "100%"
		},
		itemSelectedStyles: {
			backgroundColor: "#888888"
		},
		itemTextStyles: {
			color: "white"
		},
		textOverFlowGradient: {
			startColor: "rgba(68,68,68,0)",
			endColor: "rgba(68,68,68,.8)"
		}
	},
	attachments: {
		icon: {
			color: "black"
		},
		selectedIcon: {
			color: "white"
		}
	},
	contentPanel: {
		bgColor: "#ffffff",
		homeBgColor: "#ffffff",
	},
	placeholder: {
		color: "#eeeeee",
		bgColor: "#ffffff"
	},
	placeholderStartProcedure: {
		iconName: "arrow-circle-up",
		color: "#eeeeee",
		fontSize: 64
	},
	placeholderEndProcedure: {
		iconName: "thumbs-up",
		color: "#eeeeee",
		fontSize: 64
	},
	section: {
		backgroundColor: "#888888",
		color: "white",
		borderRadius: 5
	},
	list: {
		backgroundColor: "#444444",
		color: "white",
		borderRadius: 5
	},
	item: {
		color: "black",
		disabledColor: "lightgray",
		selectedColor: "green",
		selectedDisabledColor: "#f8f8f8",
		selectedDisabledColorFocusedMode: "lightgray",
		base: {
			borderRadius: 15,
			color: "black"
		},
		labelOnly: {
			backgroundColor: "#FFF8C6"
		},
		checked: {
			color: "green"
		},
		comments: {
			color: "#F9A200"
		},
		notes: {
			color: "#0000F9"
		},
		sketchPadImage: {
			color: "darkgreen"
		},
		media: {
			color: "purple"
		},
		yesNo: {
			yesButton: {
				selectedBorderColor: "darkgreen",
				selectedColor: "darkgreen",
				color: "lightgreen",
			},
			noButton: {
				selectedBorderColor: "darkred",
				selectedColor: "darkred",
				color: "pink"
			},
			naButton: {
				selectedBorderColor: "darkblue",
				selectedColor: "darkblue",
				color: "lightblue"
			}
		},
		textInput: {
			borderRadius: 5,
			borderStyle: "solid",
			borderColor: "#dddddd",
			backgroundColor: "white",
			color: "black",
			width: "100%"
		},
		textInputMultiline: {
			height: 100,
			padding: 5,
			borderRadius: 5,
			borderStyle: "solid",
			borderColor: "#dddddd",
			backgroundColor: "white",
			color: "black",
			width: "100%"
		},
		linkColor: "blue",
		barcodeScanner: {
			panel: {
				backgroundColor: "transparent",
				padding: 5
			},
			textInput: {
				padding: 5,
				borderRadius: 5,
				borderStyle: "solid",
				borderColor: "#dddddd",
				backgroundColor: "white",
				color: "black"
			},
			buttons: {
				scan: {
					backgroundColor: "skyblue",
					height: 30,
					width: 30,
					borderRadius: 5
				},
				scanText: {
					color: "black",
					fontSize: 14
				},
				clear: {
					backgroundColor: "red",
					height: 30,
					width: 30,
					borderRadius: 5
				},
				clearText: {
					color: "white",
					fontSize: 14,
					fontWeight: "bold"
				}
			}
		},
		sketchPad: {
			backgroundColor: "white",
			color: "gray",
			borderStyle: "dashed",
			borderColor: "gray",
			borderWidth: 3
		},
		picker: {
			basic: {
				backgroundColor: "white",
				width: "100%"
			},
			buttons: {
				backgroundColor: "transparent",
				color: "blue",
				selectedBorderColor: "blue"
			},
		},
		choice: {
			checkbox: {
				backgroundColor: "white",
				borderColor: "black",
				checkedIconName: "check",
				borderRadius: 5
			},
			radio: {
				backgroundColor: "white",
				borderColor: "black",
				checkedIconName: "circle"
			},
			text: {
				color: "black"
			}
		},
		check: {
			backgroundColor: "white",
			borderColor: "black",
			checkedIconName: "check",
			borderRadius: 5
		},
		datePicker: {
			backgroundColor: "white",
			color: "black"
		},
		mediaPicker: {
			button: {
				color: "blue",
				fontWeight: "bold"
			},
			imagePlaceholder: {
				width: 100,
				height: 100,
				borderStyle: "dashed",
				borderWidth: 1,
				borderColor: "gray"
			}
		},
		addressSearch: {
			panel: {
				backgroundColor: "white",
				width: "100%"
			},
			selectAddressLink: {
				color: "blue",
				fontWeight: "bold"
			},
			navigateLink: {
				color: "blue"
			},
			text: {
				color: "black",
				fontWeight: "bold"
			}
		},
		buttonOk: {
			underlayColor: "lightgray",
			height: 40,
			marginTop: 10,
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: "lightgray",
			borderRadius: 5,
			backgroundColor: "white",
			fontWeight: "bold",
			color: "royalblue"
		},
		ranking: {
			dialogs: {
				editFullscreen: {
					wrapper: {
						backgroundColor: "white"
					},
					header: {
						height: 70,
						borderBottomWidth: 1,
						borderBottomColor: "lightgray"
					},
					footer: {
						borderTopWidth: 1,
						borderTopColor: "lightgray"
					}
				},
				insertItem: {
					wrapper: {
						backgroundColor: "white"
					},
					header: {
						height: 70,
						borderBottomWidth: 1,
						borderBottomColor: "lightgray"
					},
					footer: {
						borderTopWidth: 1,
						borderTopColor: "lightgray"
					}
				}
			},
			buttons: {
				edit: {
					width: 100,
					backgroundColor: "skyblue",
					height: 40,
					borderRadius: 5,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				editText: {
					color: "white",
					fontSize: 16,
					fontWeight: "bold"
				},
				editFullscreen: {
					width: 100,
					backgroundColor: "skyblue",
					height: 40,
					borderRadius: 5,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				editFullscreenText: {
					color: "white",
					fontSize: 16,
					fontWeight: "bold"
				},
				editFullscreenDone: {
					backgroundColor: "skyblue",
					height: 40,
					borderRadius: 5,
					width: 100,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				editFullscreenDoneText: {
					color: "white",
					fontWeight: "bold",
					fontSize: 16
				},
				insertDone: {
					backgroundColor: "skyblue",
					height: 40,
					borderRadius: 5,
					width: 100,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				insertDoneText: {
					color: "white",
					fontWeight: "bold",
					fontSize: 16
				},
				insertCancel: {
					backgroundColor: "red",
					height: 40,
					borderRadius: 5,
					width: 100,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				insertCancelText: {
					color: "white",
					fontWeight: "bold",
					fontSize: 16
				},
				doneEditing: {
					width: 100,
					backgroundColor: "skyblue",
					height: 40,
					borderRadius: 5,
					borderWidth: 1,
					borderColor: "lightgray",
					borderStyle: "solid"
				},
				doneEditingText: {
					color: "white",
					fontSize: 16,
					fontWeight: "bold"
				}
			},
			placeholder: {
				panel: {
					width: "100%",
					borderWidth: 1,
					borderStyle: "dashed",
					borderRadius: 10,
					height: 50,
					backgroundColor: "white"
				},
				text: {
					fontWeight: "bold",
					color: "black",
					fontSize: 18
				}
			},
			item: {
				panel: {
					display: "flex",
					minHeight: 50,
					borderRadius: 10,
					backgroundColor: "rgb(211, 211, 211)"
				},
				text: {
					color: "black",
					fontSize: 14
				},
				panelDragging: {
					minHeight: 50,
					borderRadius: 10,
					backgroundColor: "rgba(211, 211, 211, .8)",
					shadowColor: "grey"
				},
				panelDraggingText: {
					color: "black",
					fontSize: 14
				},
				panelSelectableForInsert: {
					minHeight: 50,
					borderRadius: 10,
					backgroundColor: "rgb(211, 211, 211)"
				},
				panelSelectableForInsertText: {
					color: "black",
					fontSize: 14
				},
				panelSelectableForInsertSelected: {
					display: "flex",
					minHeight: 50,
					borderRadius: 10,
					backgroundColor: "skyblue",
					color: "white"
				},
				panelSelectableForInsertSelectedText: {
					color: "white",
					fontSize: 14
				}
			}
		},
		weather: {
			textNoWeatherData: {
				color: "black"
			},
			title: {
				fontWeight: "bold",
				color: "black"
			},
			summary: {
				color: "black"
			},
			dataPoint: {
				label: {
					color: "black"
				},
				value: {
					fontWeight: "bold",
					color: "black"
				}
			}
		}
	},
	itemSimple: {
		labelOnly: {
			backgroundColor: "#FFF8C6"
		},
		selectedColor: "green",
		selectedDisabledColor: "#ffffff"
	},
	home: {
		footer: {
			color: "#666666"
		}
	},
	modal: {
		content: {
			bgColor: "#ffffff"
		}
	},
	comments: {
		sketchPad: {
			container: {
				bgColor: "lightgray"
			},
			canvas: {
				bgColor: "white",
				penColor: "blue"
			}
		}
	},
	nav: {
		bgColor: "#ffffff",
		profile: {
			bgColor: "#B2DBFF",
			color: "black",
			borderBottomColor: "royalblue",
			buttonTxtColor: "white"
		},
		item: {
			color: "black",
			disabledColor: "gray",
			selectedColor: "blue"
		},
		section: {
			color: "#121212",
			iconColor: "#121212"
		},
		picker: {
			title: {
				color: "#4681b9"
			},
			item: {
				color: "black",
				iconColor: "#121212"
			}
		},
		learnMore: {
			color: "blue"
		},
		hr: {
			color: "#eeeeee",
			bgColor: "#cccccc"
		}
	},
	checkbar: {
		check: {
			color: "darkgreen",
			bgColor: "#eeffee"
		},
		skip: {
			color: "darkblue",
			bgColor: "#eeeeff"
		},
		locateItem: {
			color: "#000000",
			bgColor: "#eeeeee"
		},
		listen: {
			color: "green",
			disabledColor: "#168e8c",
			bgColor: "#e5f9f9"
		},
		emergency: {
			color: "#cc0000",
			bgColor: "#ffcccc"
		},
		switchShowMode: {
			bgColor: "rgb(40,40,40)",
			color: "lightgray"
		}
	}
};
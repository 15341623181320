import React, { Component } from "react";
import { refreshCredentials  } from "../../utils/securityUtil";
import { postMessage, setEmbeddedAppState } from "../../actions/mobileAppCommunicationActions";
class SecurityEmbed extends Component {

	render() {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", flexDirection:"column", backgroundColor:"white" }}>
				<img alt="AmbiFi Logo" src="/assets/ambifi-logo.png" style={{maxHeight:"50px"}}/>	
				<h3>Security Context</h3>
			</div>
		);
	}
	componentDidMount() {
		setEmbeddedAppState(true);
		refreshCredentials().then(() => {
			postMessage({
				method: "security-context-initialized"
			},true);
		}).catch(err => {
			postMessage({
				method: "security-context-exception",
				err
			},true);
		});
	}
}

export default SecurityEmbed;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NavItem extends React.Component {
	render() {
		return (
			<div style={{ display: "flex", alignItems: "center", borderLeftStyle: "solid", borderWidth: "5px", borderColor: this.props.selected ? "royalblue" : "transparent", padding: "10px" }} onClick={this.props.onClick}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ position: "relative" }}>
						<FontAwesomeIcon fixedWidth={true} style={{ fontSize: "24px" }} icon={this.props.icon} />
						<div style={{ position: "absolute", display: (this.props.bubbleCount && this.props.bubbleCount > 0) ? "flex" : "none", alignItems: "center", justifyContent: "center", width: "24px", height: "24px", top: "-12px", right: "-8px", backgroundColor: "red", color: "white", borderRadius: "16px", padding: "2px", fontSize: "12px" }}>
							{this.props.bubbleCount}
						</div>
					</div>
					<div style={{ marginLeft: "5px" }}>{this.props.label}</div>
				</div>
			</div>
		);
	}
}

export default NavItem;

import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import { Button, Checkbox } from "react-bootstrap";

// import mockData from "../../data/json/utteranceFindReplace.json";
import mockData from "../../data/json/ttsPhonetics.json";
import devicePlatforms from "../../data/json/devicePlatforms.json";
import deviceManufacturers from "../../data/json/deviceManufacturers.json";
import deviceModels from "../../data/json/deviceModels.json";
import deviceVoices from "../../data/json/deviceVoices.json";


import Modal from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AmbifiPanel, AmbifiInlinePanel } from "../shared";

import _ from "lodash";

class ModalFilters extends React.PureComponent {
	constructor(props) {
		super(props);

		this.platforms = {};
		for (let devicePlatform of devicePlatforms) {
			this.platforms[devicePlatform.value] = devicePlatform.label;
		}
		this.manufacturers = {};
		for (let deviceManufacturer of deviceManufacturers) {
			this.manufacturers[deviceManufacturer.value] = deviceManufacturer.label;
		}
		this.models = {};
		for (let deviceModel of deviceModels) {
			this.models[deviceModel.value] = deviceModel.label;
		}
		this.voices = {};
		for (let deviceVoice of deviceVoices) {
			this.voices[deviceVoice.value] = deviceVoice.label;
		}

		console.log(mockData);

		this.mockData = mockData;

		if (props.model) {
			// Make a copy as starting point for modal
			this.state = _.cloneDeep(props.model);
		} else {
			this.state = [
				{
					replaceContent: "",
					withContent: "",
					filters: []
				}
			];
		}
	}

	addFilterItem() {
		let newFilters;

		if (this.state.hasOwnProperty("filters")) {
			newFilters = this.state.filters.slice(0);
		} else {
			newFilters = [];
		}

		newFilters.push({
			withContent: "",
			platforms: [],
			manufacturers: [],
			models: [],
			voices: []
		});


		this.setState({ filters: newFilters });
	}

	deleteFilterItem(index) {
		let newFilters = this.state.filters.slice(0);

		newFilters.splice(index, 1);

		this.setState({ filters: newFilters });
	}

	render() {
		let filterItemsComponent;

		if (this.state.hasOwnProperty("filters")) {
			filterItemsComponent = this.state.filters.map((filter, index) => {
				let hasPlatforms = false;
				let hasManufacturers = false;
				let hasModels = false;
				let hasVoices = false;

				let platformsComponent = [];
				const platforms = this.platforms;

				for (let platformValue in platforms) {
					let platformChecked = false;
					if (filter.platforms.indexOf(platformValue) > -1) {
						platformChecked = true;
						hasPlatforms = true;
					}

					platformsComponent.push(
						<div>
							<Checkbox checked={platformChecked} value={platformValue} onChange={(e) => {
								const newFilters = _.cloneDeep(this.state.filters);
								const newPlatforms = newFilters[index].platforms;
								const newPlatformIndex = newPlatforms.indexOf(e.target.value);

								if (newPlatformIndex > -1) {
									newPlatforms.splice(newPlatformIndex, 1);
								} else {
									newPlatforms.push(e.target.value);
								}

								newFilters[index].platforms = newPlatforms;

								this.setState({ filters: newFilters });
							}}>{platforms[platformValue]}</Checkbox>
						</div>
					);
				}

				let manufacturersComponent = [];
				const manufacturers = this.manufacturers;

				for (let manufacturerValue in manufacturers) {
					let manufacturerChecked = false;
					if (filter.manufacturers.indexOf(manufacturerValue) > -1) {
						manufacturerChecked = true;
						hasManufacturers = true;
					}

					manufacturersComponent.push(
						<div>
							<Checkbox checked={manufacturerChecked} value={manufacturerValue} onChange={(e) => {
								const newFilters = _.cloneDeep(this.state.filters);
								const newManufacturers = newFilters[index].manufacturers;
								const newManufacturerIndex = newManufacturers.indexOf(e.target.value);

								if (newManufacturerIndex > -1) {
									newManufacturers.splice(newManufacturerIndex, 1);
								} else {
									newManufacturers.push(e.target.value);
								}

								newFilters[index].manufacturers = newManufacturers;

								this.setState({ filters: newFilters });
							}}>{manufacturers[manufacturerValue]}</Checkbox>
						</div>
					);
				}

				let modelsComponent = [];
				const models = this.models;

				for (let modelValue in models) {
					let modelChecked = false;
					if (filter.models.indexOf(modelValue) > -1) {
						modelChecked = true;
						hasModels = true;
					}

					modelsComponent.push(
						<div>
							<Checkbox checked={modelChecked} value={modelValue} onChange={(e) => {
								const newFilters = _.cloneDeep(this.state.filters);
								const newModels = newFilters[index].models;
								const newModelIndex = newModels.indexOf(e.target.value);

								if (newModelIndex > -1) {
									newModels.splice(newModelIndex, 1);
								} else {
									newModels.push(e.target.value);
								}

								newFilters[index].models = newModels;

								this.setState({ filters: newFilters });
							}}>{models[modelValue]}</Checkbox>
						</div>
					);
				}

				let voicesComponent = [];
				const voices = this.voices;

				for (let voiceValue in voices) {
					let voiceChecked = false;
					if (filter.voices.indexOf(voiceValue) > -1) {
						voiceChecked = true;
						hasVoices = true;
					}

					voicesComponent.push(
						<div>
							<Checkbox checked={voiceChecked} value={voiceValue} onChange={(e) => {
								const newFilters = _.cloneDeep(this.state.filters);
								const newVoices = newFilters[index].voices;
								const newVoiceIndex = newVoices.indexOf(e.target.value);

								if (newVoiceIndex > -1) {
									newVoices.splice(newVoiceIndex, 1);
								} else {
									newVoices.push(e.target.value);
								}

								newFilters[index].voices = newVoices;

								this.setState({ filters: newFilters });
							}}>{voices[voiceValue]}</Checkbox>
						</div>
					);
				}

				return (
					<div>
						<AmbifiInlinePanel title={"Filter " + (index + 1)} icon="filter">
							<div>
								<label>With</label>
							</div>
							<div>
								<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Text to replace with)" value={filter.withContent} onChange={(e) => {
									const newFilters = this.state.filters.slice(0);

									newFilters[index].withContent = e.target.value;

									this.setState({ filters: newFilters });
								}} />
							</div>

							<AmbifiPanel titleText="Platforms" collapsible hideFooter expanded={hasPlatforms}>
								{platformsComponent}
							</AmbifiPanel>
							<AmbifiPanel titleText="Manufacturers" collapsible hideFooter expanded={hasManufacturers}>
								{manufacturersComponent}
							</AmbifiPanel>
							<AmbifiPanel titleText="Models" collapsible hideFooter expanded={hasModels}>
								{modelsComponent}
							</AmbifiPanel>
							<AmbifiPanel titleText="Voices" collapsible hideFooter expanded={hasVoices}>
								{voicesComponent}
							</AmbifiPanel>
							<Button onClick={(e) => {
								e.preventDefault();
								window.confirm("Are you sure you want to delete Filter " + (index + 1) + "?", () => {
									this.deleteFilterItem(index);
								});
							}}><FontAwesomeIcon fixedWidth={true} style={{ color: "black", fontSize: "16px" }} icon="trash" /> Delete</Button>
						</AmbifiInlinePanel>


						{/* <div>
							<label>Platforms</label>
						</div>
						{platformsComponent}
						<div>
							<label>Manufacturers</label>
						</div>
						{manufacturersComponent}
						<div>
							<label>Models</label>
						</div>
						{modelsComponent}
						<div>
							<label>Voices</label>
						</div>
						{voicesComponent} */}
					</div>
				);
			});
		}

		return (
			<Modal open={this.props.open} onClose={this.props.onClose} little>
				<div style={{ display: "flex", flexDirection: "column", minWidth: 240, maxHeight: (window.innerHeight * .8) + "px" }}>
					<h3 style={{ textAlign: "center" }}>Filters</h3>
					<Button onClick={() => {
						this.addFilterItem();
					}}>
						Add Filter
					</Button>
					<div style={{ flex: 1, height: "100%", overflow: "auto" }}>

						{!this.state.hasOwnProperty("filters") &&
							<div style={{ marginTop: "15px" }}>
								There are currently no filters. A filter allows you to have a specific <b>Replace With</b> phrase per platform, manufacturer, model or voice. Click Add Filter to add your first one.
						</div>
						}
						{this.state.hasOwnProperty("filters") && this.state.filters.length === 0 &&
							<div style={{ marginTop: "15px" }}>
								There are currently no filters. A filter allows you to have a specific <b>Replace With</b> phrase per platform, manufacturer, model or voice. Click Add Filter to add your first one.
						</div>
						}
						{filterItemsComponent}
					</div>
					<div style={{ marginTop: "15px", flex: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Button onClick={this.props.onClose}>
							Cancel
						</Button>
						<Button onClick={() => {
							this.props.onSave(this.props.modelIndex, this.state);
						}}>
							OK
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}

ModalFilters.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
}, ModalFilters);
import _ from "lodash";

import * as utils from "../utils/utils.js";
import jsonTemplateMinimal from "../data/template-minimal.js";
import state from "../state/state";
import { getUrlForValue } from "../actions/drive.js";

let variables = [];
let variablesMap = {};

/*
Transform from checklist json to entities in memory
*/
export function transformToChecklistEntities(activity, identityId = null) {
	variables = [];
	variablesMap = {};
	let checklistEntities = {};
	let dstChildren = [];


	let driveRootPath = activity.hasOwnProperty("driveRootPath") ? activity.driveRootPath : "";
	if (_.isEmpty(driveRootPath) && !_.isEmpty(activity.name)) {
		driveRootPath = utils.convertStringToDriveRootPath(activity.name);
	}
	let root = {
		revision: activity.hasOwnProperty("revision") ? activity.revision : 1,
		revisionTs: new Date().getTime(),
		id: activity.hasOwnProperty("id") ? activity.id : utils.generateUUID(),
		baseIds: activity.hasOwnProperty("baseIds") ? activity.baseIds : [],
		cloned: activity.hasOwnProperty("cloned") ? activity.cloned : false,
		publisher: activity.hasOwnProperty("publisher") ? activity.publisher : "self",
		entity: {
			entityId: "root",
			guid: activity.hasOwnProperty("guid") ? activity.guid : utils.generateUUID(),
			checklistId: activity.hasOwnProperty("checklistId") ? activity.checklistId : "",
			type: "checklist",
			lang: activity.hasOwnProperty("lang") ? activity.lang : "",
			label: activity.hasOwnProperty("name") ? activity.name : "New Activity",
			labelAlexaPlain: activity.hasOwnProperty("nameAlexaPlain") ? activity.nameAlexaPlain : "",
			labelAlexaRich: activity.hasOwnProperty("nameAlexaRich") ? activity.nameAlexaRich : "",
			labelAudio: activity.hasOwnProperty("nameAudio") ? activity.nameAudio : "",
			labelSsml: activity.hasOwnProperty("nameSsml") ? activity.nameSsml : "",
			labelPrint: activity.hasOwnProperty("namePrint") ? activity.namePrint : "",
			labelAudioFile: activity.hasOwnProperty("nameAudioFile") ? activity.nameAudioFile : "",
			description: activity.hasOwnProperty("description") ? activity.description : "",
			authorProperties: activity.hasOwnProperty("authorProperties") ? JSON.stringify(activity.authorProperties, null, 2) : "{}",
			theme: activity.hasOwnProperty("theme") ? JSON.stringify(activity.theme, null, 2) : "{}",
			noGlobalTheme: activity.hasOwnProperty("noGlobalTheme") ? activity.noGlobalTheme : false,
			themes: activity.hasOwnProperty("themes") ? activity.themes : [],
			styles: activity.hasOwnProperty("styles") ? JSON.stringify(activity.styles, null, 2) : "{}",
			classes: activity.hasOwnProperty("classes") ? JSON.stringify(activity.classes, null, 2) : "{}",
			stylesheet: activity.hasOwnProperty("stylesheet") ? JSON.stringify(activity.stylesheet, null, 2) : "{}",
			noGlobalStylesheet: activity.hasOwnProperty("noGlobalStylesheet") ? activity.noGlobalStylesheet : false,
			stylesheets: activity.hasOwnProperty("stylesheets") ? activity.stylesheets : [],
			themeColors: activity.hasOwnProperty("themeColors") ? activity.themeColors : ["0f2043", "79cedc", "d5a458"],
			fonts: activity.hasOwnProperty("fonts") ? activity.fonts : "",
			workflowStatesDs: activity.hasOwnProperty("workflowStatesDs") ? activity.workflowStatesDs : activity.hasOwnProperty("workflowStates") ? activity.workflowStates : [],
			// workflowStates: activity.hasOwnProperty("workflowStates") ? activity.workflowStates : [],
			backgroundImage: activity.hasOwnProperty("backgroundImage") ? activity.backgroundImage : "",
			video: activity.hasOwnProperty("video") ? activity.video : "",
			image: activity.hasOwnProperty("image") ? activity.image : "",
			audio: activity.hasOwnProperty("audio") ? activity.audio : "",
			backgroundAudio: activity.hasOwnProperty("backgroundAudio") ? activity.backgroundAudio : "",
			backgroundAudioVolume: activity.hasOwnProperty("backgroundAudioVolume") ? activity.backgroundAudioVolume : 100,
			pdf: activity.hasOwnProperty("pdf") ? activity.pdf : "",
			heroImage: activity.hasOwnProperty("heroImage") ? activity.heroImage : "",
			logoImage: activity.hasOwnProperty("logoImage") ? activity.logoImage : "",
			logoImageSmall: activity.hasOwnProperty("logoImageSmall") ? activity.logoImageSmall : "",
			logoImageAlign: activity.hasOwnProperty("logoImageAlign") ? activity.logoImageAlign : "",
			logoImageSmallAlign: activity.hasOwnProperty("logoImageSmallAlign") ? activity.logoImageSmallAlign : "",
			backgroundImageDrive: activity.hasOwnProperty("backgroundImageDrive") ? activity.backgroundImageDrive : undefined,
			videoDrive: activity.hasOwnProperty("videoDrive") ? activity.videoDrive : undefined,
			imageDrive: activity.hasOwnProperty("imageDrive") ? activity.imageDrive : undefined,
			audioDrive: activity.hasOwnProperty("audioDrive") ? activity.audioDrive : undefined,
			backgroundAudioDrive: activity.hasOwnProperty("backgroundAudioDrive") ? activity.backgroundAudioDrive : "",
			backgroundAudioDuckingDuck: activity.hasOwnProperty("backgroundAudioDuckingDuck") ? activity.backgroundAudioDuckingDuck : false,
			backgroundAudioDuckingDuration: activity.hasOwnProperty("backgroundAudioDuckingDuration") ? activity.backgroundAudioDuckingDuration : "",
			backgroundAudioDuckingVolume: activity.hasOwnProperty("backgroundAudioDuckingVolume") ? activity.backgroundAudioDuckingVolume : "",
			pdfDrive: activity.hasOwnProperty("pdfDrive") ? activity.pdfDrive : undefined,
			heroImageDrive: activity.hasOwnProperty("heroImageDrive") ? activity.heroImageDrive : undefined,
			logoImageDrive: activity.hasOwnProperty("logoImageDrive") ? activity.logoImageDrive : "",
			logoImageSmallDrive: activity.hasOwnProperty("logoImageSmallDrive") ? activity.logoImageSmallDrive : "",
			driveRootPath,
			genre: activity.hasOwnProperty("genre") ? activity.genre : "misc",
			tags: activity.hasOwnProperty("tags") ? activity.tags : [],
			contributors: activity.hasOwnProperty("contributors") ? activity.contributors : [],
			startInShowMode: activity.hasOwnProperty("startInShowMode") ? activity.startInShowMode : false,
			relatedItems: activity.hasOwnProperty("relatedItems") ? activity.relatedItems : [],
			showModeTextAlign: activity.hasOwnProperty("showModeTextAlign") ? activity.showModeTextAlign : "",
			useCustomShowModeStyling: activity.hasOwnProperty("useCustomShowModeStyling") ? activity.useCustomShowModeStyling : false,
			allowSwitchToShowMode: activity.hasOwnProperty("allowSwitchToShowMode") ? activity.allowSwitchToShowMode : false,
			suppressAudio: activity.hasOwnProperty("suppressAudio") ? activity.suppressAudio : false,
			suppressAudioTitle: activity.hasOwnProperty("suppressAudioTitle") ? activity.suppressAudioTitle : false,
			hideTitleInAppBar: activity.hasOwnProperty("hideTitleInAppBar") ? activity.hideTitleInAppBar : false,
			suppressAudioEntityType: activity.hasOwnProperty("suppressAudioEntityType") ? activity.suppressAudioEntityType : false,
			suppressAudioCompletion: activity.hasOwnProperty("suppressAudioCompletion") ? activity.suppressAudioCompletion : false,
			procedureViewType: activity.hasOwnProperty("procedureViewType") ? activity.procedureViewType : "default",
			trackData: activity.hasOwnProperty("trackData") ? activity.trackData : true,
			trackLocation: activity.hasOwnProperty("trackLocation") ? activity.trackLocation : false,
			disableMediaOptimization: activity.hasOwnProperty("disableMediaOptimization") ? activity.disableMediaOptimization : false,
			dataSources: activity.hasOwnProperty("dataSources") ? activity.dataSources : [],
			externalResources: activity.hasOwnProperty("externalResources") ? activity.externalResources : [],
			suppressPrint: activity.hasOwnProperty("suppressPrint") ? activity.suppressPrint : false,
			itemButtonOkLabel: activity.hasOwnProperty("itemButtonOkLabel") ? activity.itemButtonOkLabel : "OK ✓",
			itemButtonOkVisibility: activity.hasOwnProperty("itemButtonOkVisibility") ? activity.itemButtonOkVisibility : "visible",
			itemCommentsLabel: activity.hasOwnProperty("itemCommentsLabel") ? activity.itemCommentsLabel : "More...",
			itemRelatedItemsLabel: activity.hasOwnProperty("itemRelatedItemsLabel") ? activity.itemRelatedItemsLabel : "Related",
			submitLabel: activity.hasOwnProperty("submitLabel") ? activity.submitLabel : "Save + Exit",
			lockOnSubmit: activity.hasOwnProperty("lockOnSubmit") ? activity.lockOnSubmit : false,
			submitLabelWhenComplete: activity.hasOwnProperty("submitLabelWhenComplete") ? activity.submitLabel : "Save + Exit",
			lockOnSubmitWhenComplete: activity.hasOwnProperty("lockOnSubmitWhenComplete") ? activity.lockOnSubmit : false,
			functions: activity.hasOwnProperty("functions") ? activity.functions : "",
			contentModel: activity.hasOwnProperty("contentModel") ? activity.contentModel : "",
			defaultColumnWidth: activity.hasOwnProperty("defaultColumnWidth") ? activity.defaultColumnWidth : "",
			focusedMode: activity.hasOwnProperty("focusedMode") ? activity.focusedMode : "",
			moveForward: activity.hasOwnProperty("moveForward") ? activity.moveForward : "",
			ignoreCompletion: activity.hasOwnProperty("ignoreCompletion") ? activity.ignoreCompletion : false,
			hideStatus: activity.hasOwnProperty("hideStatus") ? activity.hideStatus : false,
			printCustomProperties: activity.hasOwnProperty("printCustomProperties") ? JSON.stringify(activity.printCustomProperties, null, 2) : "{}",
			printTemplate: activity.hasOwnProperty("printTemplate") ? activity.printTemplate : "",
			// The entire video will have a tracks array
			// Each item will be an object in the tracks array with an array of clips
			// The clips will be ordered title, html, luma, video, image, audio
			// exportVideoProps: activity.hasOwnProperty("exportVideoProps") ? activity.exportVideoProps : {
			// 	background: null, // #000000
			// 	soundtrack: {
			// 		src: null,
			// 		effect: null, // fadeIn, fadeOut, fadeInFadeOut
			// 		volume: null // 0 to 1
			// 	},
			// 	fonts: [],
			// 	cache: null // boolean
			// },
			commentsVisibility: activity.hasOwnProperty("commentsVisibility") ? activity.commentsVisibility : "",
			listViewMode: activity.hasOwnProperty("listViewMode") ? activity.listViewMode : "",
			checkbarVisibility: activity.hasOwnProperty("checkbarVisibility") ? activity.checkbarVisibility : "",
			defaultView: activity.hasOwnProperty("defaultView") ? activity.defaultView : "",
			itemsOnSingleLine: activity.hasOwnProperty("itemsOnSingleLine") ? activity.itemsOnSingleLine : false,

			aiAssistant: activity.hasOwnProperty("aiAssistant") ? activity.aiAssistant : false,
			aiAssistantId: activity.hasOwnProperty("aiAssistantId") ? activity.aiAssistantId : "",
			aiAssistantFiles: activity.hasOwnProperty("aiAssistantFiles") ? activity.aiAssistantFiles : [],
			aiAssistantChecklistFiles: activity.hasOwnProperty("aiAssistantChecklistFiles") ? activity.aiAssistantChecklistFiles : []

			// printEngine: checklist.hasOwnProperty("printEngine") ? checklist.printEngine : "jsrender",
			// printRecipe: checklist.hasOwnProperty("printRecipe") ? checklist.printRecipe : "electron-pdf",		
			// printRecipeOptions: checklist.hasOwnProperty("printRecipeOptions") ? checklist.printRecipeOptions : "{}",		
			// printHelpers: checklist.hasOwnProperty("printHelpers") ? checklist.printHelpers : "",			
			// printScripts: checklist.hasOwnProperty("printScripts") ? checklist.printScripts : ""		
		},
		expanded: true,
		selected: false,
		children: dstChildren
	};

	if (identityId) {
		root.entity.identityId = identityId;
	}
	checklistEntities.root = root;
	checklistEntities.variables = variables;
	checklistEntities.variablesMap = variablesMap;
	checklistEntities.children = dstChildren;
	recurseChecklist(activity.children, dstChildren);

	return checklistEntities;
}

function recurseChecklist(arr, dstChildren) {
	for (let i = 0; i < arr.length; i++) {
		let newChildren = [];
		const entityId = utils.generateUUID();
		if (arr[i].type === "list") {

			const node = {
				id: utils.generateUUID(),
				expanded: false,
				selected: false,
				entity: {
					entityId,
					guid: arr[i].hasOwnProperty("guid") ? arr[i].guid : utils.generateUUID(),
					id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
					groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
					filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
					type: "list",
					label: arr[i].name,
					excludeFromNavigation: arr[i].hasOwnProperty("excludeFromNavigation") ? arr[i].excludeFromNavigation : false,
					relatedItemRefs: arr[i].hasOwnProperty("relatedItemRefs") ? arr[i].relatedItemRefs : "",
					labelAlexaPlain: arr[i].hasOwnProperty("nameAlexaPlain") ? arr[i].nameAlexaPlain : "",
					labelAlexaRich: arr[i].hasOwnProperty("nameAlexaRich") ? arr[i].nameAlexaRich : "",
					labelAudio: arr[i].hasOwnProperty("nameAudio") ? arr[i].nameAudio : "",
					labelSsml: arr[i].hasOwnProperty("nameSsml") ? arr[i].nameSsml : "",
					labelPrint: arr[i].hasOwnProperty("namePrint") ? arr[i].namePrint : "",
					labelAudioFile: arr[i].hasOwnProperty("nameAudioFile") ? arr[i].nameAudioFile : "",
					defaultView: arr[i].defaultView,
					startInBirdseyeView: arr[i].hasOwnProperty("startInBirdseyeView") ? arr[i].startInBirdseyeView : false,
					styles: arr[i].hasOwnProperty("styles") ? JSON.stringify(arr[i].styles, null, 2) : "{}",
					classes: arr[i].hasOwnProperty("classes") ? JSON.stringify(arr[i].classes, null, 2) : "{}",
					color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
					backgroundColor: arr[i].hasOwnProperty("backgroundColor") ? arr[i].backgroundColor : "",
					borderColor: arr[i].hasOwnProperty("borderColor") ? arr[i].borderColor : "",
					iconColor: arr[i].hasOwnProperty("iconColor") ? arr[i].iconColor : "",
					backgroundImage: arr[i].hasOwnProperty("backgroundImage") ? arr[i].backgroundImage : "",
					video: arr[i].hasOwnProperty("video") ? arr[i].video : "",
					image: arr[i].hasOwnProperty("image") ? arr[i].image : "",
					audio: arr[i].hasOwnProperty("audio") ? arr[i].audio : "",
					pdf: arr[i].hasOwnProperty("pdf") ? arr[i].pdf : "",
					backgroundImageDrive: arr[i].hasOwnProperty("backgroundImageDrive") ? arr[i].backgroundImageDrive : undefined,
					videoDrive: arr[i].hasOwnProperty("videoDrive") ? arr[i].videoDrive : undefined,
					imageDrive: arr[i].hasOwnProperty("imageDrive") ? arr[i].imageDrive : undefined,
					audioDrive: arr[i].hasOwnProperty("audioDrive") ? arr[i].audioDrive : undefined,
					pdfDrive: arr[i].hasOwnProperty("pdfDrive") ? arr[i].pdfDrive : undefined,
					backgroundAudioDuckingDuck: arr[i].hasOwnProperty("backgroundAudioDuckingDuck") ? arr[i].backgroundAudioDuckingDuck : "inherit",
					backgroundAudioDuckingDuration: arr[i].hasOwnProperty("backgroundAudioDuckingDuration") ? arr[i].backgroundAudioDuckingDuration : "inherit",
					backgroundAudioDuckingVolume: arr[i].hasOwnProperty("backgroundAudioDuckingVolume") ? arr[i].backgroundAudioDuckingVolume : "inherit",
					authorProperties: arr[i].hasOwnProperty("authorProperties") ? JSON.stringify(arr[i].authorProperties, null, 2) : "{}",
					itemButtonOkLabel: arr[i].hasOwnProperty("itemButtonOkLabel") ? arr[i].itemButtonOkLabel : "",
					itemButtonOkVisibility: arr[i].hasOwnProperty("itemButtonOkVisibility") ? arr[i].itemButtonOkVisibility : "inherit",
					itemCommentsLabel: arr[i].hasOwnProperty("itemCommentsLabel") ? arr[i].itemCommentsLabel : "",
					itemRelatedItemsLabel: arr[i].hasOwnProperty("itemRelatedItemsLabel") ? arr[i].itemRelatedItemsLabel : "",
					suppressAudio: arr[i].hasOwnProperty("suppressAudio") ? arr[i].suppressAudio : false,
					suppressAudioTitle: arr[i].hasOwnProperty("suppressAudioTitle") ? arr[i].suppressAudioTitle : false,
					suppressAudioEntityType: arr[i].hasOwnProperty("suppressAudioEntityType") ? arr[i].suppressAudioEntityType : false,
					suppressAudioCompletion: arr[i].hasOwnProperty("suppressAudioCompletion") ? arr[i].suppressAudioCompletion : false,
					suppressPrint: arr[i].hasOwnProperty("suppressPrint") ? arr[i].suppressPrint : false,
					procedureViewType: arr[i].hasOwnProperty("procedureViewType") ? arr[i].procedureViewType : "inherit",
					ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion") ? arr[i].ignoreCompletion : false,
					hideStatus: arr[i].hasOwnProperty("hideStatus") ? arr[i].hideStatus : false,
					enabled: arr[i].hasOwnProperty("enabled") ? arr[i].enabled : true,
					visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
					conditionalVisible: arr[i].hasOwnProperty("conditionalVisible") ? arr[i].conditionalVisible : "",
					iconType: arr[i].hasOwnProperty("iconType") ? arr[i].iconType : "none",
					iconImage: arr[i].hasOwnProperty("iconImage") ? arr[i].iconImage : "",
					iconFa: arr[i].hasOwnProperty("iconFa") ? arr[i].iconFa : "",
					defaultColumnWidth: arr[i].hasOwnProperty("defaultColumnWidth") ? arr[i].defaultColumnWidth : "",
					focusedMode: arr[i].hasOwnProperty("focusedMode") ? arr[i].focusedMode : "",
					moveForward: arr[i].hasOwnProperty("moveForward") ? arr[i].moveForward : "",
					commentsVisibility: arr[i].hasOwnProperty("commentsVisibility") ? arr[i].commentsVisibility : "",
					printProperties: arr[i].hasOwnProperty("printProperties") ? arr[i].printProperties : {
						keepTogether: false,
						breakBefore: true,
						breakBeforeHeight: 0,
						breakAfter: false,
						breakAfterHeight: 0
					},
					printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}",
					repeatable: arr[i].hasOwnProperty("repeatable") ? arr[i].repeatable : false,
					repeatableRetainValues: arr[i].hasOwnProperty("repeatableRetainValues") ? arr[i].repeatableRetainValues : false,
					repeatableIndex: arr[i].hasOwnProperty("repeatableIndex") ? arr[i].repeatableIndex : 1,
					repeatableInitialCount: arr[i].hasOwnProperty("repeatableInitialCount") ? arr[i].repeatableInitialCount : 1,
					repeatableMaxCount: arr[i].hasOwnProperty("repeatableMaxCount") ? arr[i].repeatableMaxCount : 0
				},
				children: newChildren
			};

			dstChildren.push(node);
		} else if (arr[i].type === "section") {
			const node = {
				id: utils.generateUUID(),
				expanded: false,
				selected: false,
				entity: {
					entityId,
					guid: arr[i].hasOwnProperty("guid") ? arr[i].guid : utils.generateUUID(),
					id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
					groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
					filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
					type: "section",
					label: arr[i].name,
					excludeFromNavigation: arr[i].hasOwnProperty("excludeFromNavigation") ? arr[i].excludeFromNavigation : false,
					relatedItemRefs: arr[i].hasOwnProperty("relatedItemRefs") ? arr[i].relatedItemRefs : "",
					labelAlexaPlain: arr[i].hasOwnProperty("nameAlexaPlain") ? arr[i].nameAlexaPlain : "",
					labelAlexaRich: arr[i].hasOwnProperty("nameAlexaRich") ? arr[i].nameAlexaRich : "",
					labelAudio: arr[i].hasOwnProperty("nameAudio") ? arr[i].nameAudio : "",
					labelSsml: arr[i].hasOwnProperty("nameSsml") ? arr[i].nameSsml : "",
					labelPrint: arr[i].hasOwnProperty("namePrint") ? arr[i].namePrint : "",
					labelAudioFile: arr[i].hasOwnProperty("nameAudioFile") ? arr[i].nameAudioFile : "",
					styles: arr[i].hasOwnProperty("styles") ? JSON.stringify(arr[i].styles, null, 2) : "{}",
					classes: arr[i].hasOwnProperty("classes") ? JSON.stringify(arr[i].classes, null, 2) : "{}",
					color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
					backgroundColor: arr[i].hasOwnProperty("backgroundColor") ? arr[i].backgroundColor : "",
					borderColor: arr[i].hasOwnProperty("borderColor") ? arr[i].borderColor : "",
					iconColor: arr[i].hasOwnProperty("iconColor") ? arr[i].iconColor : "",
					backgroundImage: arr[i].hasOwnProperty("backgroundImage") ? arr[i].backgroundImage : "",
					video: arr[i].hasOwnProperty("video") ? arr[i].video : "",
					image: arr[i].hasOwnProperty("image") ? arr[i].image : "",
					audio: arr[i].hasOwnProperty("audio") ? arr[i].audio : "",
					pdf: arr[i].hasOwnProperty("pdf") ? arr[i].pdf : "",
					backgroundImageDrive: arr[i].hasOwnProperty("backgroundImageDrive") ? arr[i].backgroundImageDrive : undefined,
					videoDrive: arr[i].hasOwnProperty("videoDrive") ? arr[i].videoDrive : undefined,
					imageDrive: arr[i].hasOwnProperty("imageDrive") ? arr[i].imageDrive : undefined,
					audioDrive: arr[i].hasOwnProperty("audioDrive") ? arr[i].audioDrive : undefined,
					pdfDrive: arr[i].hasOwnProperty("pdfDrive") ? arr[i].pdfDrive : undefined,
					backgroundAudioDuckingDuck: arr[i].hasOwnProperty("backgroundAudioDuckingDuck") ? arr[i].backgroundAudioDuckingDuck : "inherit",
					backgroundAudioDuckingDuration: arr[i].hasOwnProperty("backgroundAudioDuckingDuration") ? arr[i].backgroundAudioDuckingDuration : "inherit",
					backgroundAudioDuckingVolume: arr[i].hasOwnProperty("backgroundAudioDuckingVolume") ? arr[i].backgroundAudioDuckingVolume : "inherit",
					authorProperties: arr[i].hasOwnProperty("authorProperties") ? JSON.stringify(arr[i].authorProperties, null, 2) : "{}",
					itemButtonOkLabel: arr[i].hasOwnProperty("itemButtonOkLabel") ? arr[i].itemButtonOkLabel : "",
					itemButtonOkVisibility: arr[i].hasOwnProperty("itemButtonOkVisibility") ? arr[i].itemButtonOkVisibility : "inherit",
					itemCommentsLabel: arr[i].hasOwnProperty("itemCommentsLabel") ? arr[i].itemCommentsLabel : "",
					itemRelatedItemsLabel: arr[i].hasOwnProperty("itemRelatedItemsLabel") ? arr[i].itemRelatedItemsLabel : "",
					suppressAudio: arr[i].hasOwnProperty("suppressAudio") ? arr[i].suppressAudio : false,
					suppressAudioTitle: arr[i].hasOwnProperty("suppressAudioTitle") ? arr[i].suppressAudioTitle : false,
					suppressAudioEntityType: arr[i].hasOwnProperty("suppressAudioEntityType") ? arr[i].suppressAudioEntityType : false,
					suppressAudioCompletion: arr[i].hasOwnProperty("suppressAudioCompletion") ? arr[i].suppressAudioCompletion : false,
					procedureViewType: arr[i].hasOwnProperty("procedureViewType") ? arr[i].procedureViewType : "inherit",
					suppressPrint: arr[i].hasOwnProperty("suppressPrint") ? arr[i].suppressPrint : false,
					ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion") ? arr[i].ignoreCompletion : false,
					hideStatus: arr[i].hasOwnProperty("hideStatus") ? arr[i].hideStatus : false,
					enabled: arr[i].hasOwnProperty("enabled") ? arr[i].enabled : true,
					visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
					conditionalVisible: arr[i].hasOwnProperty("conditionalVisible") ? arr[i].conditionalVisible : "",
					defaultColumnWidth: arr[i].hasOwnProperty("defaultColumnWidth") ? arr[i].defaultColumnWidth : "",
					focusedMode: arr[i].hasOwnProperty("focusedMode") ? arr[i].focusedMode : "",
					moveForward: arr[i].hasOwnProperty("moveForward") ? arr[i].moveForward : "",
					commentsVisibility: arr[i].hasOwnProperty("commentsVisibility") ? arr[i].commentsVisibility : "",
					printProperties: arr[i].hasOwnProperty("printProperties") ? arr[i].printProperties : {
						keepTogether: false,
						breakBefore: false,
						breakBeforeHeight: 0,
						breakAfter: false,
						breakAfterHeight: 0
					},
					printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}",
					repeatable: arr[i].hasOwnProperty("repeatable") ? arr[i].repeatable : false,
					repeatableRetainValues: arr[i].hasOwnProperty("repeatableRetainValues") ? arr[i].repeatableRetainValues : false,
					repeatableIndex: arr[i].hasOwnProperty("repeatableIndex") ? arr[i].repeatableIndex : 1,
					repeatableInitialCount: arr[i].hasOwnProperty("repeatableInitialCount") ? arr[i].repeatableInitialCount : 1,
					repeatableMaxCount: arr[i].hasOwnProperty("repeatableMaxCount") ? arr[i].repeatableMaxCount : 0
				},
				children: newChildren
			};

			dstChildren.push(node);
		} else if (arr[i].type.startsWith("item")) {
			let node = {
				id: utils.generateUUID(),
				expanded: false,
				selected: false,
				entity: {
					entityId,
					guid: arr[i].hasOwnProperty("guid") ? arr[i].guid : utils.generateUUID(),
					id: arr[i].hasOwnProperty("id") ? arr[i].id : "",
					groupNames: arr[i].hasOwnProperty("groupNames") ? arr[i].groupNames : [],
					filterTags: arr[i].hasOwnProperty("filterTags") ? arr[i].filterTags : [],
					type: arr[i].type,
					backgroundColor: arr[i].hasOwnProperty("backgroundColor") ? arr[i].backgroundColor : "",
					showModeTextAlign: arr[i].hasOwnProperty("showModeTextAlign") ? arr[i].showModeTextAlign : "",
					useCustomShowModeStyling: arr[i].hasOwnProperty("useCustomShowModeStyling") ? arr[i].useCustomShowModeStyling : false,
					navTitle: arr[i].hasOwnProperty("navTitle") ? arr[i].navTitle : "",
					excludeFromNavigation: arr[i].hasOwnProperty("excludeFromNavigation") ? arr[i].excludeFromNavigation : "",
					relatedItemRefs: arr[i].hasOwnProperty("relatedItemRefs") ? arr[i].relatedItemRefs : "",
					label: arr[i].hasOwnProperty("label1") ? arr[i].label1 : "",
					label1EditorAdvanced: arr[i].hasOwnProperty("label1EditorAdvanced") ? arr[i].label1EditorAdvanced : "",
					label1AlexaPlain: arr[i].hasOwnProperty("label1AlexaPlain") ? arr[i].label1AlexaPlain : "",
					label1AlexaRich: arr[i].hasOwnProperty("label1AlexaRich") ? arr[i].label1AlexaRich : "",
					label1Audio: arr[i].hasOwnProperty("label1Audio") ? arr[i].label1Audio : "",
					label1Ssml: arr[i].hasOwnProperty("label1Ssml") ? arr[i].label1Ssml : "",
					label1Print: arr[i].hasOwnProperty("label1Print") ? arr[i].label1Print : "",
					label1AudioFile: arr[i].hasOwnProperty("label1AudioFile") ? arr[i].label1AudioFile : "",
					styles: arr[i].hasOwnProperty("styles") ? JSON.stringify(arr[i].styles, null, 2) : "{}",
					classes: arr[i].hasOwnProperty("classes") ? JSON.stringify(arr[i].classes, null, 2) : "{}",
					color: arr[i].hasOwnProperty("color") ? arr[i].color : "",
					label2: arr[i].hasOwnProperty("label2") ? arr[i].label2 : "",
					label2EditorAdvanced: arr[i].hasOwnProperty("label2EditorAdvanced") ? arr[i].label2EditorAdvanced : "",
					label2AlexaPlain: arr[i].hasOwnProperty("label2AlexaPlain") ? arr[i].label2AlexaPlain : "",
					label2AlexaRich: arr[i].hasOwnProperty("label2AlexaRich") ? arr[i].label2AlexaRich : "",
					label2Audio: arr[i].hasOwnProperty("label2Audio") ? arr[i].label2Audio : "",
					label2Ssml: arr[i].hasOwnProperty("label2Ssml") ? arr[i].label2Ssml : "",
					label2Print: arr[i].hasOwnProperty("label2Print") ? arr[i].label2Print : "",
					label2AudioFile: arr[i].hasOwnProperty("label2AudioFile") ? arr[i].label2AudioFile : "",
					label2Style: arr[i].hasOwnProperty("label2Style") ? JSON.stringify(arr[i].label2Style, null, 2) : "{}",
					label2Class: arr[i].hasOwnProperty("label2Class") ? arr[i].labbel2Class : "",
					label2Color: arr[i].hasOwnProperty("label2Color") ? arr[i].label2Color : "",
					mandatory: arr[i].hasOwnProperty("mandatory") ? arr[i].mandatory : false,
					comments: arr[i].hasOwnProperty("comments") ? arr[i].comments : "",
					commentsUrl: arr[i].hasOwnProperty("commentsUrl") ? arr[i].commentsUrl : "",
					commentsInline: arr[i].hasOwnProperty("commentsInline") ? arr[i].commentsInline : false,
					commentsAuthor: "",
					commentsAudio: arr[i].hasOwnProperty("commentsAudio") ? arr[i].commentsAudio : "",
					commentsSsml: arr[i].hasOwnProperty("commentsSsml") ? arr[i].commentsSsml : "",
					commentsPrint: arr[i].hasOwnProperty("commentsPrint") ? arr[i].commentsPrint : "",
					commentsAudioFile: arr[i].hasOwnProperty("commentsAudioFile") ? arr[i].commentsAudioFile : "",
					commentsTemplate: arr[i].hasOwnProperty("commentsTemplate") ? arr[i].commentsTemplate : "",
					commentsDataPath: arr[i].hasOwnProperty("commentsDataPath") ? arr[i].commentsDataPath : "",
					commentsVisibility: arr[i].hasOwnProperty("commentsVisibility") ? arr[i].commentsVisibility : "",
					linkOnCheck: arr[i].hasOwnProperty("linkOnCheck") ? arr[i].linkOnCheck : false,
					linkId: arr[i].hasOwnProperty("linkId") ? arr[i].linkId : "",
					linkActionType: arr[i].hasOwnProperty("linkActionType") ? arr[i].linkActionType : "goto",
					valueTrigger: arr[i].hasOwnProperty("valueTrigger") ? arr[i].valueTrigger : "",
					backgroundImage: arr[i].hasOwnProperty("backgroundImage") ? arr[i].backgroundImage : "",
					video: arr[i].hasOwnProperty("video") ? arr[i].video : "",
					videoMute: arr[i].hasOwnProperty("videoMute") ? arr[i].videoMute : true,
					videoLoop: arr[i].hasOwnProperty("videoLoop") ? arr[i].videoLoop : true,
					videoAspectRatio: arr[i].hasOwnProperty("videoAspectRatio") ? arr[i].videoAspectRatio : "",
					videoAspectRatioCustom: arr[i].hasOwnProperty("videoAspectRatioCustom") ? arr[i].videoAspectRatioCustom : "",
					videoMaximumHeight: arr[i].hasOwnProperty("videoMaximumHeight") ? arr[i].videoMaximumHeight : "default",
					videoShowInBackground: arr[i].hasOwnProperty("videoShowInBackground") ? arr[i].videoShowInBackground : false,
					videoResizeMode: arr[i].hasOwnProperty("videoResizeMode") ? arr[i].videoResizeMode : "contain",
					videoOverlayColor: arr[i].hasOwnProperty("videoOverlayColor") ? arr[i].videoOverlayColor : "transparent",
					videoOverlayColorCustom: arr[i].hasOwnProperty("videoOverlayColorCustom") ? arr[i].videoOverlayColorCustom : "rgba(255,255,255,0.7)",
					videoTextTracks: arr[i].hasOwnProperty("videoTextTracks") ? arr[i].videoTextTracks : [],
					image: arr[i].hasOwnProperty("image") ? arr[i].image : "",
					imageAspectRatio: arr[i].hasOwnProperty("imageAspectRatio") ? arr[i].imageAspectRatio : "",
					imageMaximumHeight: arr[i].hasOwnProperty("imageMaximumHeight") ? arr[i].imageMaximumHeight : "default",
					imageShowInBackground: arr[i].hasOwnProperty("imageShowInBackground") ? arr[i].imageShowInBackground : false,
					imageResizeMode: arr[i].hasOwnProperty("imageResizeMode") ? arr[i].imageResizeMode : "contain",
					imageOverlayColor: arr[i].hasOwnProperty("imageOverlayColor") ? arr[i].imageOverlayColor : "transparent",
					imageOverlayColorCustom: arr[i].hasOwnProperty("imageOverlayColorCustom") ? arr[i].imageOverlayColorCustom : "rgba(255,255,255,0.7)",
					imageKenBurnsEffectEnabled: arr[i].hasOwnProperty("imageKenBurnsEffectEnabled") ? arr[i].imageKenBurnsEffectEnabled : false,
					imageKenBurnsEffectDuration: arr[i].hasOwnProperty("imageKenBurnsEffectDuration") ? arr[i].imageKenBurnsEffectDuration : "default",
					imageKenBurnsEffectStartScaleX: arr[i].hasOwnProperty("imageKenBurnsEffectStartScaleX") ? arr[i].imageKenBurnsEffectStartScaleX : "default",
					imageKenBurnsEffectStartScaleY: arr[i].hasOwnProperty("imageKenBurnsEffectStartScaleY") ? arr[i].imageKenBurnsEffectStartScaleX : "default",
					imageKenBurnsEffectEndScaleX: arr[i].hasOwnProperty("imageKenBurnsEffectEndScaleX") ? arr[i].imageKenBurnsEffectEndScaleX : "default",
					imageKenBurnsEffectEndScaleY: arr[i].hasOwnProperty("imageKenBurnsEffectEndScaleY") ? arr[i].imageKenBurnsEffectEndScaleY : "default",
					audio: arr[i].hasOwnProperty("audio") ? arr[i].audio : "",
					pdf: arr[i].hasOwnProperty("pdf") ? arr[i].pdf : "",
					threeD: arr[i].hasOwnProperty("threeD") ? arr[i].threeD : "",
					backgroundImageDrive: arr[i].hasOwnProperty("backgroundImageDrive") ? arr[i].backgroundImageDrive : undefined,
					videoDrive: arr[i].hasOwnProperty("videoDrive") ? arr[i].videoDrive : undefined,
					imageDrive: arr[i].hasOwnProperty("imageDrive") ? arr[i].imageDrive : undefined,
					audioDrive: arr[i].hasOwnProperty("audioDrive") ? arr[i].audioDrive : undefined,
					pdfDrive: arr[i].hasOwnProperty("pdfDrive") ? arr[i].pdfDrive : undefined,
					threeDDrive: arr[i].hasOwnProperty("threeDDrive") ? arr[i].threeDDrive : undefined,
					backgroundAudioDuckingDuck: arr[i].hasOwnProperty("backgroundAudioDuckingDuck") ? arr[i].backgroundAudioDuckingDuck : "inherit",
					backgroundAudioDuckingDuration: arr[i].hasOwnProperty("backgroundAudioDuckingDuration") ? arr[i].backgroundAudioDuckingDuration : "inherit",
					backgroundAudioDuckingVolume: arr[i].hasOwnProperty("backgroundAudioDuckingVolume") ? arr[i].backgroundAudioDuckingVolume : "inherit",
					authorProperties: arr[i].hasOwnProperty("authorProperties") ? JSON.stringify(arr[i].authorProperties, null, 2) : "{}",
					itemHeight: arr[i].hasOwnProperty("itemHeight") ? arr[i].itemHeight : "default",
					itemButtonOkLabel: arr[i].hasOwnProperty("itemButtonOkLabel") ? arr[i].itemButtonOkLabel : "",
					itemButtonOkVisibility: arr[i].hasOwnProperty("itemButtonOkVisibility") ? arr[i].itemButtonOkVisibility : "inherit",
					itemCommentsLabel: arr[i].hasOwnProperty("itemCommentsLabel") ? arr[i].itemCommentsLabel : "",
					itemRelatedItemsLabel: arr[i].hasOwnProperty("itemRelatedItemsLabel") ? arr[i].itemRelatedItemsLabel : "",
					suppressAudio: arr[i].hasOwnProperty("suppressAudio") ? arr[i].suppressAudio : false,
					suppressAudioLabel1: arr[i].hasOwnProperty("suppressAudioLabel1") ? arr[i].suppressAudioLabel1 : false,
					suppressAudioLabel2: arr[i].hasOwnProperty("suppressAudioLabel2") ? arr[i].suppressAudioLabel2 : false,
					suppressAudioValue: arr[i].hasOwnProperty("suppressAudioValue") ? arr[i].suppressAudioValue : false,
					procedureViewType: arr[i].hasOwnProperty("procedureViewType") ? arr[i].procedureViewType : "inherit",
					suppressPrint: arr[i].hasOwnProperty("suppressPrint") ? arr[i].suppressPrint : false,
					ignoreCompletion: arr[i].hasOwnProperty("ignoreCompletion") ? arr[i].ignoreCompletion : false,
					startTracking: arr[i].startTracking === true,
					stopTracking: arr[i].hasOwnProperty("stopTracking") ? arr[i].stopTracking : false,
					saveOnCheck: arr[i].saveOnCheck === true,
					saveAndExitOnCheck: arr[i].saveAndExitOnCheck === true,
					generateReportOnCheck: arr[i].generateReportOnCheck === true,
					generateReportOnCheckTemplate: arr[i].hasOwnProperty("generateReportOnCheckTemplate") ? arr[i].generateReportOnCheckTemplate : "",
					startNamedTimers: arr[i].hasOwnProperty("startNamedTimers") ? arr[i].startNamedTimers : [],
					stopNamedTimers: arr[i].hasOwnProperty("stopNamedTimers") ? arr[i].stopNamedTimers : [],
					useAsSessionName: arr[i].hasOwnProperty("useAsSessionName") ? arr[i].useAsSessionName : false,
					isSearchable: arr[i].hasOwnProperty("isSearchable") ? arr[i].isSearchable : false,
					enabled: arr[i].hasOwnProperty("enabled") ? arr[i].enabled : true,
					visible: arr[i].hasOwnProperty("visible") ? arr[i].visible : true,
					valueSticky: arr[i].hasOwnProperty("valueSticky") ? arr[i].valueSticky : false,
					conditionalVisible: arr[i].hasOwnProperty("conditionalVisible") ? arr[i].conditionalVisible : "",
					calculatedDefaultValue: arr[i].hasOwnProperty("calculatedDefaultValue") ? arr[i].calculatedDefaultValue : "",
					itemsDatasource: arr[i].hasOwnProperty("itemsDatasource") ? arr[i].itemsDatasource : "",
					associatedContentTemplate: arr[i].hasOwnProperty("associatedContentTemplate") ? arr[i].associatedContentTemplate : "",
					"_validation_itemsDatasource": arr[i].hasOwnProperty("_validation_itemsDatasource") ? arr[i]["_validation_itemsDatasource"] : "",
					pickerItemsDatasource: arr[i].hasOwnProperty("pickerItemsDatasource") ? arr[i].pickerItemsDatasource : "",
					"_validation_pickerItemsDatasource": arr[i].hasOwnProperty("_validation_pickerItemsDatasource") ? arr[i]["_validation_pickerItemsDatasource"] : "",
					printProperties: arr[i].hasOwnProperty("printProperties") ? arr[i].printProperties : {
						keepTogether: true,
						breakBefore: false,
						breakBeforeHeight: 0,
						breakAfter: false,
						breakAfterHeight: 0
					},
					printCustomProperties: arr[i].hasOwnProperty("printCustomProperties") ? JSON.stringify(arr[i].printCustomProperties, null, 2) : "{}",
					// exportVideoClips: arr[i].hasOwnProperty("exportVideoClips") ? arr[i].exportVideoClips : {
					// 	"titleClip": {
					// 		"asset": {
					// 			"type": "title",
					// 			"text": null,
					// 			"style": null, // minimal, blockbuster, vogue, sketchy, skinny, chunk, chunkLight, marker, future, subtitle
					// 			"color": null, // #ffffff - transparent prepend with alpha #88ffffff
					// 			"size": null, // xx-small, x-small, small, medium, large, x-large, xx-large
					// 			"background": null, // #000000 - transparent prepend with alpha #88000000
					// 			"position": null, // top, topRight, right, bottomRight, bottom, bottomLeft, left, topLeft, center
					// 			"offset": {
					// 				"x": null, // -1 to 1 - 0.5 will move right half the screen width
					// 				"y": null // -1 to 1 - 0.5 will move up half the screen height
					// 			}
					// 		},
					// 		"transition": {
					// 			"in": null, // fade, reveal, wipeLeft, wipeRight, slideLeft, slideRight, slideUp, slideDown, carouselLeft, carouselRight, carouselUp, carouselDown, shuffleTopRight, shuffleRightTop, shuffleRightBottom, shuffleBottomRight, shuffleBottomLeft, shuffleLeftBottom, shuffleLeftTop, shuffleTopLeft, zoom - you can also append Fast or Slow to the end of these
					// 			"out": null // fade, reveal, wipeLeft, wipeRight, slideLeft, slideRight, slideUp, slideDown, carouselLeft, carouselRight, carouselUp, carouselDown, shuffleTopRight, shuffleRightTop, shuffleRightBottom, shuffleBottomRight, shuffleBottomLeft, shuffleLeftBottom, shuffleLeftTop, shuffleTopLeft, zoom - you can also append Fast or Slow to the end of these
					// 		},
					// 		"effect": null, // zoomIn, zoomOut, slideLeft, slideRight, slideUp, slideDown
					// 		"fit": null, // cover, contain, crop, none
					// 		"scale": null, // 0 to 1
					// 		"filter": null, // boost, contrast, darken, greyscale, lighten, muted, inver
					// 		"opacity": null, // 0 to 1
					// 		"start": null, // in seconds
					// 		"length": null, // in seconds
					// 		"position": null, // top, topRight, right, bottomRight, bottom, bottomLeft, left, topLeft, center
					// 		"offset": {
					// 			"x": null, // - 1 to 1 - 0.5 will move right half the viewport width
					// 			"y": null // - 1 to 1 - 0.5 will move up half the viewport height
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null // -360 to 360 - positive clockwise - negative counter-clockwise
					// 			},
					// 			"skew": {
					// 				"x": null, // 0 to 3 - along x-axis
					// 				"y": null // 0 to 3 - along y-axis
					// 			},
					// 			"flip": {
					// 				"horizontal": null, // boolean
					// 				"vertical": null // boolean
					// 			}
					// 		}
					// 	},
					// 	"htmlClip": {
					// 		"asset": {
					// 			"type": "html",
					// 			"html": null,
					// 			"css": null,
					// 			"width": null, // number in pixels of bounding box
					// 			"height": null, // number in pixels of bounding box
					// 			"background": null, // #000000 - transparent prepend with alpha #88000000
					// 			"position": null // top, topRight, right, bottomRight, bottom, bottomLeft, left, topLeft, center
					// 		},
					// 		"transition": {
					// 			"in": null,
					// 			"out": null
					// 		},
					// 		"effect": null,
					// 		"fit": null,
					// 		"scale": null,
					// 		"filter": null,
					// 		"opacity": null,
					// 		"start": null,
					// 		"length": null,
					// 		"offset": {
					// 			"x": null,
					// 			"y": null
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null
					// 			},
					// 			"skew": {
					// 				"x": null,
					// 				"y": null
					// 			},
					// 			"flip": {
					// 				"horizontal": null,
					// 				"vertical": null
					// 			}
					// 		}
					// 	},

					// 	"lumaClip": {
					// 		"asset": {
					// 			"type": "luma",
					// 				"src": null,
					// 				"trim": null // in seconds - videos start from trim point
					// 		},
					// 		"transition": {
					// 			"in": null,
					// 				"out": null
					// 		},
					// 		"effect": null,
					// 			"fit": null,
					// 				"scale": null,
					// 					"filter": null,
					// 						"opacity": null,
					// 							"start": null,
					// 								"length": null,
					// 									"offset": {
					// 			"x": null,
					// 				"y": null
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null
					// 			},
					// 			"skew": {
					// 				"x": null,
					// 					"y": null
					// 			},
					// 			"flip": {
					// 				"horizontal": null,
					// 					"vertical": null
					// 			}
					// 		}
					// 	},
					// 	"videoClip": {
					// 		"asset": {
					// 			"type": "video",
					// 			"src": null,
					// 			"trim": null, // in seconds - videos start from trim point
					// 			"volume": null, // 0 to 1
					// 			"crop": {
					// 				"top": null, // 0 to 1
					// 				"bottom": null, // 0 to 1
					// 				"left": null, // 0 to 1
					// 				"right": null // 0 to 1
					// 			}
					// 		},
					// 		"transition": {
					// 			"in": null,
					// 			"out": null
					// 		},
					// 		"effect": null,
					// 		"fit": null,
					// 		"scale": null,
					// 		"filter": null,
					// 		"opacity": null,
					// 		"start": null,
					// 		"length": null,
					// 		"offset": {
					// 			"x": null,
					// 			"y": null
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null
					// 			},
					// 			"skew": {
					// 				"x": null,
					// 				"y": null
					// 			},
					// 			"flip": {
					// 				"horizontal": null,
					// 				"vertical": null
					// 			}
					// 		}
					// 	},
					// 	"imageClip": {
					// 		"asset": {
					// 			"type": "image",
					// 			"src": null,
					// 			"crop": {
					// 				"top": null, // 0 to 1
					// 				"bottom": null, // 0 to 1
					// 				"left": null, // 0 to 1
					// 				"right": null // 0 to 1
					// 			}
					// 		},
					// 		"transition": {
					// 			"in": null,
					// 			"out": null
					// 		},
					// 		"effect": null,
					// 		"fit": null,
					// 		"scale": null,
					// 		"filter": null,
					// 		"opacity": null,
					// 		"start": null,
					// 		"length": null,
					// 		"offset": {
					// 			"x": null,
					// 			"y": null
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null
					// 			},
					// 			"skew": {
					// 				"x": null,
					// 				"y": null
					// 			},
					// 			"flip": {
					// 				"horizontal": null,
					// 				"vertical": null
					// 			}
					// 		}
					// 	},
					// 	"audioClip": {
					// 		"asset": {
					// 			"type": "audio",
					// 			"src": null,
					// 			"trim": null, // in seconds - videos start from trim point
					// 			"volume": null, // 0 to 1
					// 			"effect": null // fadeIn, fadeOut, fadeInFadeOut
					// 		},
					// 		"transition": {
					// 			"in": null,
					// 			"out": null
					// 		},
					// 		"effect": null,
					// 		"fit": null,
					// 		"scale": null,
					// 		"filter": null,
					// 		"opacity": null,
					// 		"start": null,
					// 		"length": null,
					// 		"offset": {
					// 			"x": null,
					// 			"y": null
					// 		},
					// 		"transform": {
					// 			"rotate": {
					// 				"angle": null
					// 			},
					// 			"skew": {
					// 				"x": null,
					// 				"y": null
					// 			},
					// 			"flip": {
					// 				"horizontal": null,
					// 				"vertical": null
					// 			}
					// 		}
					// 	}
					// },
					repeatable: arr[i].hasOwnProperty("repeatable") ? arr[i].repeatable : false,
					repeatableRetainValues: arr[i].hasOwnProperty("repeatableRetainValues") ? arr[i].repeatableRetainValues : false,
					repeatableIndex: arr[i].hasOwnProperty("repeatableIndex") ? arr[i].repeatableIndex : 1,
					repeatableInitialCount: arr[i].hasOwnProperty("repeatableInitialCount") ? arr[i].repeatableInitialCount : 1,
					repeatableMaxCount: arr[i].hasOwnProperty("repeatableMaxCount") ? arr[i].repeatableMaxCount : 0
				}

			};

			if (arr[i].hasOwnProperty("id") && arr[i].id !== "") {
				variables.push({
					id: arr[i].id,
					entityId
				});
				variablesMap[entityId] = true;
			}

			let deltaProps = {};
			switch (arr[i].type) {
				case "item":
					deltaProps = {
						entity: {
							labelOnly: arr[i].hasOwnProperty("labelOnly") ? arr[i].labelOnly : false,
							labelOnlyBackgroundColor: arr[i].hasOwnProperty("labelOnlyBackgroundColor") ? arr[i].labelOnlyBackgroundColor : "",
							startTimer: arr[i].hasOwnProperty("startTimer") ? arr[i].startTimer : false,
							stopTimer: arr[i].hasOwnProperty("stopTimer") ? arr[i].stopTimer : false
						}
					};
					break;
				case "itemTextInput":
				case "itemTextInputMultiline":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : true,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							textInputPlaceholder: arr[i].hasOwnProperty("textInputPlaceholder") ? arr[i].textInputPlaceholder : "",
							textInputDefaultValue: arr[i].hasOwnProperty("textInputDefaultValue") ? arr[i].textInputDefaultValue : "",
							textInputMaxLength: arr[i].hasOwnProperty("textInputMaxLength") ? arr[i].textInputMaxLength : 1000,
							textInputKeyboardType: arr[i].hasOwnProperty("textInputKeyboardType") ? arr[i].textInputKeyboardType : "default",
							textInputKeyboardAutoCapitalize: arr[i].hasOwnProperty("textInputKeyboardAutoCapitalize") ? arr[i].textInputKeyboardAutoCapitalize : "sentences",
							textInputKeyboardAutoCorrect: arr[i].hasOwnProperty("textInputKeyboardAutoCorrect") ? arr[i].textInputKeyboardAutoCorrect : true,
							textInputKeyboardReturnKeyType: arr[i].hasOwnProperty("textInputKeyboardReturnKeyType") ? arr[i].textInputKeyboardReturnKeyType : "default",
							textInputMaskType: arr[i].hasOwnProperty("textInputMaskType") ? arr[i].textInputMaskType : "",
							textInputCustomMask: arr[i].hasOwnProperty("textInputCustomMask") ? arr[i].textInputCustomMask : "",
							textInputCustomMask2: arr[i].hasOwnProperty("textInputCustomMask2") ? arr[i].textInputCustomMask2 : "",
							textInputCurrencySymbol: arr[i].hasOwnProperty("textInputCurrencySymbol") ? arr[i].textInputCurrencySymbol : "$",
							textInputCurrencySeparator: arr[i].hasOwnProperty("textInputCurrencySeparator") ? arr[i].textInputCurrrencySeparator : ",",
							textInputNumberOfLines: arr[i].hasOwnProperty("textInputNumberOfLines") ? arr[i].textInputNumberOfLines : 4
						}
					};
					break;
				case "itemPicker":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : true,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							pickerItemViewType: arr[i].hasOwnProperty("pickerItemViewType") ? arr[i].pickerItemViewType : "picker",
							pickerItemsDatasource: arr[i].hasOwnProperty("pickerItemsDatasource") ? arr[i].pickerItemsDatasource : "",
							pickerItemPlaceholder: arr[i].hasOwnProperty("pickerItemPlaceholder") ? arr[i].pickerItemPlaceholder : "",
							pickerItemDefaultValue: arr[i].pickerItemDefaultValue,
							pickerItems: arr[i].pickerItems,
							pickerAdvanceOnSelect: arr[i].hasOwnProperty("pickerAdvanceOnSelect") ? arr[i].pickerAdvanceOnSelect : false,
							pickerLinkOnSelect: arr[i].hasOwnProperty("pickerLinkOnSelect") ? arr[i].pickerLinkOnSelect : false,
							pickerLinkActionType: arr[i].hasOwnProperty("pickerLinkActionType") ? arr[i].pickerLinkActionType : "goto"
						}
					};
					break;
				case "itemRanking":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : false,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							config: arr[i].hasOwnProperty("config") ? arr[i].config : {},
							itemsDatasource: arr[i].hasOwnProperty("itemsDatasource") ? arr[i].itemsDatasource : "",
							items: arr[i].items,
						}
					};
					break;
				case "itemChoiceSingle":
				case "itemChoiceMulti":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : true,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							choiceItems: arr[i].choiceItems
						}
					};
					break;
				case "itemYesNo":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : true,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							yesNoLinkOnSelect: arr[i].hasOwnProperty("yesNoLinkOnSelect") ? arr[i].yesNoLinkOnSelect : false,
							yesNoLinkActionType: arr[i].hasOwnProperty("yesNoLinkActionType") ? arr[i].yesNoLinkActionType : "goto",
							yesLinkActionType: arr[i].hasOwnProperty("yesLinkActionType") ? arr[i].yesLinkActionType : "inherit",
							noLinkActionType: arr[i].hasOwnProperty("noLinkActionType") ? arr[i].noLinkActionType : "inherit",
							naLinkActionType: arr[i].hasOwnProperty("naLinkActionType") ? arr[i].naLinkActionType : "inherit",
							yesNoYesLinkId: arr[i].hasOwnProperty("yesNoYesLinkId") ? arr[i].yesNoYesLinkId : "",
							yesNoNoLinkId: arr[i].hasOwnProperty("yesNoNoLinkId") ? arr[i].yesNoNoLinkId : "",
							yesNoNaLinkId: arr[i].hasOwnProperty("yesNoNaLinkId") ? arr[i].yesNoNaLinkId : "",
							yesNoShowNa: arr[i].hasOwnProperty("yesNoShowNa") ? arr[i].yesNoShowNa : false,
							yesNoAdvanceOnSelect: arr[i].hasOwnProperty("yesNoAdvanceOnSelect") ? arr[i].yesNoAdvanceOnSelect : true,
						}
					};
					break;
				case "itemDate":
				case "itemTime":
				case "itemDateTime":
					deltaProps = {
						entity: {
							dateTimeType: arr[i].hasOwnProperty("dateTimeType") ? arr[i].dateTimeType : "local",
							dateTimeInitialDate: arr[i].hasOwnProperty("dateTimeInitialDate") ? arr[i].dateTimeInitialDate : "today",
							dateTimeMinuteInterval: arr[i].hasOwnProperty("dateTimeMinuteInterval") ? arr[i].dateTimeMinuteInterval : 1,
							dateTimeAdvanceOnSelect: arr[i].hasOwnProperty("dateTimeAdvanceOnSelect") ? arr[i].dateTimeAdvanceOnSelect : true,
						}
					};
					break;
				case "itemWeather":
					deltaProps = {
						entity: {
							weatherInitialLocation: arr[i].hasOwnProperty("weatherInitialLocation") ? arr[i].weatherInitialLocation : "current",
						}
					};
					break;
				case "itemBarcodeScanner":
					deltaProps = {
						entity: {
							required: arr[i].hasOwnProperty("required") ? arr[i].required : false,
							requiredMessage: arr[i].hasOwnProperty("requiredMessage") ? arr[i].requiredMessage : "A value is required.",
							barcodeTypes: arr[i].hasOwnProperty("barcodeTypes") ? arr[i].barcodeTypes : [],
						}
					};
					break;
				case "itemImagePicker":
					deltaProps = {
						entity: {
							imagePickerMediaType: arr[i].hasOwnProperty("imagePickerMediaType") ? arr[i].imagePickerMediaType : "photo",
							imagePickerAddMediaButton: arr[i].hasOwnProperty("imagePickerAddMediaButton") ? arr[i].imagePickerAddMediaButton : "Add Photo",
							imagePickerUploadTitle: arr[i].hasOwnProperty("imagePickerUploadTitle") ? arr[i].imagePickerUploadTitle : "Upload",
							imagePickerCaptureMediaTitle: arr[i].hasOwnProperty("imagePickerCaptureMediaTitle") ? arr[i].imagePickerCaptureMediaTitle : "Take Photo..."
						}
					};
					break;
				case "itemSketchPad":
					deltaProps = {
						entity: {
							sketchPadBackgroundColor: arr[i].hasOwnProperty("sketchPadBackgroundColor") ? arr[i].sketchPadBackgroundColor : "",
							sketchPadPenColor: arr[i].hasOwnProperty("sketchPadPenColor") ? arr[i].sketchPadPenColor : "",
							sketchPadPenWidth: arr[i].hasOwnProperty("sketchPadPenWidth") ? arr[i].sketchPadPenWidth : 5,
							sketchPadHeight: arr[i].hasOwnProperty("sketchPadHeight") ? arr[i].sketchPadHeight : 190
						}
					};
					break;
				default:
			}

			// Merge props
			node = _.merge({}, node, deltaProps);

			dstChildren.push(node);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseChecklist(arr[i].children, newChildren);
		}
	}
}

/*
Transform from entities in memory to checklist json
*/
export function transformFromChecklistEntities(checklistEntities, resolveUrls = false, resolveMarkdown = false, increaseRevision = false) {
	let dstChildren = [];
	let checklist = _.cloneDeep(jsonTemplateMinimal);
	if (!checklistEntities.hasOwnProperty("revision")) {
		checklist.revision = 0;
	} else {
		checklist.revision = checklistEntities.revision !== null && _.isNumber(checklistEntities.revision) ? checklistEntities.revision : 0;
	}

	if (increaseRevision) {
		checklist.revision = checklist.revision + 1;
		checklistEntities.revision = checklist.revision;
		checklist.revisionTs = new Date().getTime();
		checklistEntities.revisionTs = checklist.revisionTs;
	}

	let guid = utils.generateUUID();
	if (checklistEntities.entity.hasOwnProperty("guid") && checklistEntities.entity.guid && checklistEntities.entity.guid !== null) {
		guid = checklistEntities.entity.guid;
	}

	const inventoryEntry = state.get(["activitiesMap", checklistEntities.id]);

	checklist.id = checklistEntities.id;
	checklist.guid = guid;
	checklist.baseIds = checklistEntities.baseIds;
	checklist.checklistId = checklistEntities.entity.checklistId;
	checklist.lang = checklistEntities.entity.lang;
	checklist.name = checklistEntities.entity.label;
	checklist.nameAlexaPlain = checklistEntities.entity.labelAlexaPlain;
	checklist.nameAlexaRich = checklistEntities.entity.labelAlexaRich;
	checklist.nameAudio = checklistEntities.entity.labelAudio;
	checklist.nameSsml = checklistEntities.entity.labelSsml;
	checklist.namePrint = checklistEntities.entity.labelPrint;
	checklist.nameAudioFile = checklistEntities.entity.labelAudioFile;
	checklist.description = checklistEntities.entity.description;
	checklist.genre = checklistEntities.entity.genre;
	checklist.tags = checklistEntities.entity.tags;
	checklist.contributors = checklistEntities.entity.contributors;
	checklist.children = dstChildren;
	checklist.cloned = checklistEntities.cloned;
	checklist.publisher = checklistEntities.publisher;

	checklist.authorProperties = safeParse(checklistEntities.entity.authorProperties, {});


	checklist.theme = safeParse(checklistEntities.entity.theme, {});
	checklist.noGlobalTheme = checklistEntities.entity.noGlobalTheme;
	checklist.themes = checklistEntities.entity.themes;


	checklist.styles = safeParse(checklistEntities.entity.styles, {});


	checklist.classes = safeParse(checklistEntities.entity.classes, {});

	checklist.stylesheet = safeParse(checklistEntities.entity.stylesheet, {});
	checklist.noGlobalStylesheet = checklistEntities.entity.noGlobalStylesheet;
	checklist.stylesheets = checklistEntities.entity.stylesheets;

	checklist.themeColors = checklistEntities.entity.themeColors;

	checklist.fonts = checklistEntities.entity.fonts;

	// Mirating to datasource
	checklist.workflowStatesDs = checklistEntities.entity.workflowStatesDs;
	// checklist.workflowStates = checklistEntities.entity.workflowStates;

	checklist.backgroundImage = checklistEntities.entity.backgroundImage;
	checklist.video = checklistEntities.entity.video;
	checklist.image = checklistEntities.entity.image;
	checklist.audio = checklistEntities.entity.audio;
	checklist.backgroundAudio = checklistEntities.entity.backgroundAudio;
	checklist.backgroundAudioVolume = checklistEntities.entity.backgroundAudioVolume;
	checklist.pdf = checklistEntities.entity.pdf;
	checklist.heroImage = checklistEntities.entity.heroImage;
	checklist.heroImageUrl = getUrlForValue(checklist.heroImage, checklist.id);
	checklist.logoImage = checklistEntities.entity.logoImage;
	checklist.logoImageSmall = checklistEntities.entity.logoImageSmall;
	checklist.logoImageAlign = checklistEntities.entity.logoImageAlign;
	checklist.logoImageSmallAlign = checklistEntities.entity.logoImageSmallAlign;
	checklist.backgroundImageDrive = checklistEntities.entity.backgroundImageDrive;
	checklist.videoDrive = checklistEntities.entity.videoDrive;
	checklist.imageDrive = checklistEntities.entity.imageDrive;
	checklist.audioDrive = checklistEntities.entity.audioDrive;
	checklist.backgroundAudioDrive = checklistEntities.entity.backgroundAudioDrive;
	checklist.pdfDrive = checklistEntities.entity.pdfDrive;
	checklist.heroImageDrive = checklistEntities.entity.heroImageDrive;
	checklist.logoImageDrive = checklistEntities.entity.logoImageDrive;
	checklist.logoImageSmallDrive = checklistEntities.entity.logoImageSmallDrive;
	checklist.backgroundAudioDuckingDuck = checklistEntities.entity.backgroundAudioDuckingDuck;
	checklist.backgroundAudioDuckingDuration = checklistEntities.entity.backgroundAudioDuckingDuration;
	checklist.backgroundAudioDuckingVolume = checklistEntities.entity.backgroundAudioDuckingVolume;
	checklist.showModeTextAlign = checklistEntities.entity.showModeTextAlign;
	checklist.useCustomShowModeStyling = checklistEntities.entity.useCustomShowModeStyling;
	checklist.driveRootPath = checklistEntities.entity.driveRootPath;
	checklist.startInShowMode = checklistEntities.entity.startInShowMode;
	checklist.relatedItems = checklistEntities.entity.relatedItems;
	checklist.allowSwitchToShowMode = checklistEntities.entity.allowSwitchToShowMode;
	checklist.suppressAudio = checklistEntities.entity.suppressAudio;
	checklist.suppressAudioTitle = checklistEntities.entity.suppressAudioTitle;
	checklist.hideTitleInAppBar = checklistEntities.entity.hideTitleInAppBar;
	checklist.suppressAudioEntityType = checklistEntities.entity.suppressAudioEntityType;
	checklist.procedureViewType = checklistEntities.entity.procedureViewType;
	checklist.suppressAudioCompletion = checklistEntities.entity.suppressAudioCompletion;
	checklist.trackData = inventoryEntry && inventoryEntry.hasOwnProperty("trackData") ? inventoryEntry.trackData : checklistEntities.entity.trackData;
	checklist.trackLocation = checklistEntities.entity.trackLocation;
	checklist.disableMediaOptimization = checklistEntities.entity.disableMediaOptimization;
	checklist.dataSources = checklistEntities.entity.dataSources;
	checklist.externalResources = checklistEntities.entity.externalResources;
	checklist.suppressPrint = checklistEntities.entity.suppressPrint;
	if (checklistEntities.entity.hasOwnProperty("identityId")) {
		checklist.identityId = checklistEntities.entity.identityId;
	}
	checklist.itemButtonOkLabel = checklistEntities.entity.itemButtonOkLabel;
	checklist.itemButtonOkVisibility = checklistEntities.entity.itemButtonOkVisibility;
	checklist.itemCommentsLabel = checklistEntities.entity.itemCommentsLabel;
	checklist.itemRelatedItemsLabel = checklistEntities.entity.itemRelatedItemsLabel;
	checklist.submitLabel = checklistEntities.entity.submitLabel;
	checklist.lockOnSubmit = checklistEntities.entity.lockOnSubmit;
	checklist.submitLabelWhenComplete = checklistEntities.entity.submitLabelWhenComplete;
	checklist.lockOnSubmitWhenComplete = checklistEntities.entity.lockOnSubmitWhenComplete;
	checklist.functions = checklistEntities.entity.functions;
	checklist.contentModel = checklistEntities.entity.contentModel;
	checklist.defaultColumnWidth = checklistEntities.entity.defaultColumnWidth;
	checklist.focusedMode = checklistEntities.entity.focusedMode;
	checklist.moveForward = checklistEntities.entity.moveForward;
	checklist.commentsVisibility = checklistEntities.entity.commentsVisibility;
	checklist.listViewMode = checklistEntities.entity.listViewMode;

	checklist.ignoreCompletion = checklistEntities.entity.ignoreCompletion;
	checklist.hideStatus = checklistEntities.entity.hideStatus;
	checklist.printCustomProperties = safeParse(checklistEntities.entity.printCustomProperties, {});
	checklist.printTemplate = checklistEntities.entity.printTemplate;

	checklist.checkbarVisibility = checklistEntities.entity.checkbarVisibility;
	checklist.defaultView = checklistEntities.entity.defaultView;
	checklist.itemsOnSingleLine = checklistEntities.entity.itemsOnSingleLine;

	checklist.aiAssistant = checklistEntities.entity.aiAssistant;
	checklist.aiAssistantId = checklistEntities.entity.aiAssistantId;
	checklist.aiAssistantFiles = checklistEntities.entity.aiAssistantFiles;
	checklist.aiAssistantChecklistFiles = checklistEntities.entity.aiAssistantChecklistFiles;

	// TODO: Bug where "tree" has children twice
	recurseChecklistEntities(checklistEntities.children, dstChildren);

	if (resolveUrls) {
		utils.recurseDriveRefsToUrls(checklist.children, checklist.id, "", "");
	}

	if (resolveMarkdown) {
		utils.recurseConvertChecklistToMarkdown(checklist.children, true);
	}

	return checklist;
}

function recurseChecklistEntities(arr, dstChildren) {
	for (let i = 0; i < arr.length; i++) {
		let newChildren = [];
		if (arr[i].entity.type === "list") {

			let guid = utils.generateUUID();
			if (arr[i].entity.hasOwnProperty("guid") && arr[i].entity.guid && arr[i].entity.guid !== null) {
				guid = arr[i].entity.guid;
			}

			const node = {
				"id": arr[i].entity.id,
				"guid": guid,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": "list",
				"completionState": "n",
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"hideStatus": arr[i].entity.hideStatus,
				"name": arr[i].entity.label,
				"excludeFromNavigation": arr[i].entity.excludeFromNavigation,
				"relatedItemRefs": arr[i].entity.relatedItemRefs,
				"nameAlexaPlain": arr[i].entity.labelAlexaPlain,
				"nameAlexaRich": arr[i].entity.labelAlexaRich,
				"nameAudio": arr[i].entity.labelAudio,
				"nameSsml": arr[i].entity.labelSsml,
				"namePrint": arr[i].entity.labelPrint,
				"nameAudioFile": arr[i].entity.labelAudioFile,
				"defaultView": arr[i].entity.defaultView,
				"startInBirdseyeView": arr[i].entity.startInBirdseyeView,
				"styles": safeParse(arr[i].entity.styles, {}),
				"classes": safeParse(arr[i].entity.classes, {}),
				"color": arr[i].entity.color,
				"iconColor": arr[i].entity.iconColor,
				"backgroundColor": arr[i].entity.backgroundColor,
				"borderColor": arr[i].entity.borderColor,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"image": arr[i].entity.image,
				"audio": arr[i].entity.audio,
				"pdf": arr[i].entity.pdf,
				"backgroundImageDrive": arr[i].entity.backgroundImageDrive,
				"videoDrive": arr[i].entity.videoDrive,
				"imageDrive": arr[i].entity.imageDrive,
				"audioDrive": arr[i].entity.audioDrive,
				"pdfDrive": arr[i].entity.pdfDrive,
				"backgroundAudioDuckingDuck": arr[i].entity.backgroundAudioDuckingDuck,
				"backgroundAudioDuckingDuration": arr[i].entity.backgroundAudioDuckingDuration,
				"backgroundAudioDuckingVolume": arr[i].entity.backgroundAudioDuckingVolume,
				"authorProperties": safeParse(arr[i].entity.authorProperties, {}),
				"itemButtonOkLabel": arr[i].entity.itemButtonOkLabel,
				"itemButtonOkVisibility": arr[i].entity.itemButtonOkVisibility,
				"itemCommentsLabel": arr[i].entity.itemCommentsLabel,
				"itemRelatedItemsLabel": arr[i].entity.itemRelatedItemsLabel,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioTitle": arr[i].entity.suppressAudioTitle,
				"suppressAudioEntityType": arr[i].entity.suppressAudioEntityType,
				"procedureViewType": arr[i].entity.procedureViewType,
				"suppressAudioCompletion": arr[i].entity.suppressAudioCompletion,
				"suppressPrint": arr[i].entity.suppressPrint,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {}),
				"horzScrollPos": 0,
				"vertScrollPos": 0,
				"children": newChildren,
				"enabled": arr[i].entity.enabled,
				"visible": arr[i].entity.visible,
				"conditionalVisible": arr[i].entity.conditionalVisible,
				"iconType": arr[i].entity.iconType,
				"iconImage": arr[i].entity.iconImage,
				"iconFa": arr[i].entity.iconFa,
				"defaultColumnWidth": arr[i].entity.defaultColumnWidth,
				"focusedMode": arr[i].entity.focusedMode,
				"moveForward": arr[i].entity.moveForward,
				"commentsVisibility": arr[i].entity.commentsVisibility,
				"repeatable": arr[i].entity.repeatable,
				"repeatableRetainValues": arr[i].entity.repeatableRetainValues,
				"repeatableIndex": Number(arr[i].entity.repeatableIndex),
				"repeatableInitialCount": Number(arr[i].entity.repeatableInitialCount),
				"repeatableMaxCount": Number(arr[i].entity.repeatableMaxCount)
			};

			dstChildren.push(node);
		} else if (arr[i].entity.type === "section") {
			let guid = utils.generateUUID();
			if (arr[i].entity.hasOwnProperty("guid") && arr[i].entity.guid && arr[i].entity.guid !== null) {
				guid = arr[i].entity.guid;
			}

			const node = {
				"id": arr[i].entity.id,
				"guid": guid,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": "section",
				"completionState": "n",
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"hideStatus": arr[i].entity.hideStatus,
				"name": arr[i].entity.label,
				"excludeFromNavigation": arr[i].entity.excludeFromNavigation,
				"relatedItemRefs": arr[i].entity.relatedItemRefs,
				"nameAlexaPlain": arr[i].entity.labelAlexaPlain,
				"nameAlexaRich": arr[i].entity.labelAlexaRich,
				"nameAudio": arr[i].entity.labelAudio,
				"nameSsml": arr[i].entity.labelSsml,
				"namePrint": arr[i].entity.labelPrint,
				"nameAudioFile": arr[i].entity.labelAudioFile,
				"styles": safeParse(arr[i].entity.styles, {}),
				"classes": safeParse(arr[i].entity.classes, {}),
				"color": arr[i].entity.color,
				"backgroundColor": arr[i].entity.backgroundColor,
				"borderColor": arr[i].entity.borderColor,
				"iconColor": arr[i].entity.iconColor,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"image": arr[i].entity.image,
				"audio": arr[i].entity.audio,
				"pdf": arr[i].entity.pdf,
				"backgroundImageDrive": arr[i].entity.backgroundImageDrive,
				"videoDrive": arr[i].entity.videoDrive,
				"imageDrive": arr[i].entity.imageDrive,
				"audioDrive": arr[i].entity.audioDrive,
				"pdfDrive": arr[i].entity.pdfDrive,
				"backgroundAudioDuckingDuck": arr[i].entity.backgroundAudioDuckingDuck,
				"backgroundAudioDuckingDuration": arr[i].entity.backgroundAudioDuckingDuration,
				"backgroundAudioDuckingVolume": arr[i].backgroundAudioDuckingVolume,
				"authorProperties": safeParse(arr[i].entity.authorProperties, {}),
				"itemButtonOkLabel": arr[i].entity.itemButtonOkLabel,
				"itemButtonOkVisibility": arr[i].entity.itemButtonOkVisibility,
				"itemCommentsLabel": arr[i].entity.itemCommentsLabel,
				"itemRelatedItemsLabel": arr[i].entity.itemRelatedItemsLabel,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioTitle": arr[i].entity.suppressAudioTitle,
				"suppressAudioEntityType": arr[i].entity.suppressAudioEntityType,
				"suppressAudioCompletion": arr[i].entity.suppressAudioCompletion,
				"procedureViewType": arr[i].entity.procedureViewType,
				"suppressPrint": arr[i].entity.suppressPrint,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {}),
				"horzScrollPos": 0,
				"vertScrollPos": 0,
				"children": newChildren,
				"enabled": arr[i].entity.enabled,
				"visible": arr[i].entity.visible,
				"conditionalVisible": arr[i].entity.conditionalVisible,
				"defaultColumnWidth": arr[i].entity.defaultColumnWidth,
				"focusedMode": arr[i].entity.focusedMode,
				"moveForward": arr[i].entity.moveForward,
				"commentsVisibility": arr[i].entity.commentsVisibility,
				"repeatable": arr[i].entity.repeatable,
				"repeatableRetainValues": arr[i].entity.repeatableRetainValues,
				"repeatableIndex": arr[i].entity.repeatableIndex,
				"repeatableInitialCount": arr[i].entity.repeatableInitialCount,
				"repeatableMaxCount": arr[i].entity.repeatableMaxCount
			};

			dstChildren.push(node);
		} else if (arr[i].entity.type.startsWith("item")) {
			let guid = utils.generateUUID();
			if (arr[i].entity.hasOwnProperty("guid") && arr[i].entity.guid && arr[i].entity.guid !== null) {
				guid = arr[i].entity.guid;
			}
			let node = {
				"id": arr[i].entity.id,
				"guid": guid,
				"groupNames": arr[i].entity.groupNames,
				"filterTags": arr[i].entity.filterTags,
				"type": arr[i].entity.type,
				"checked": false,
				"ignoreCompletion": arr[i].entity.ignoreCompletion,
				"backgroundColor": arr[i].entity.backgroundColor,
				"showModeTextAlign": arr[i].entity.showModeTextAlign,
				"useCustomShowModeStyling": arr[i].entity.useCustomShowModeStyling,
				"navTitle": arr[i].entity.navTitle,
				"excludeFromNavigation": arr[i].entity.excludeFromNavigation,
				"relatedItemRefs": arr[i].entity.relatedItemRefs,
				"label1": arr[i].entity.label,
				"label1EditorAdvanced": arr[i].entity.label1EditorAdvanced,
				"label1AlexaPlain": arr[i].entity.label1AlexaPlain,
				"label1AlexaRich": arr[i].entity.label1AlexaRich,
				"label1Audio": arr[i].entity.label1Audio,
				"label1Ssml": arr[i].entity.label1Ssml,
				"label1Print": arr[i].entity.label1Print,
				"label1AudioFile": arr[i].entity.label1AudioFile,
				"styles": safeParse(arr[i].entity.styles, {}),
				"classes": safeParse(arr[i].entity.classes, {}),
				"color": arr[i].entity.color,
				"label2": arr[i].entity.label2,
				"label2EditorAdvanced": arr[i].entity.label2EditorAdvanced,
				"label2AlexaPlain": arr[i].entity.label2AlexaPlain,
				"label2AlexaRich": arr[i].entity.label2AlexaRich,
				"label2Audio": arr[i].entity.label2Audio,
				"label2Ssml": arr[i].entity.label2Ssml,
				"label2Print": arr[i].entity.label2Print,
				"label2AudioFile": arr[i].entity.label2AudioFile,
				"label2Color": arr[i].entity.label2Color,
				"mandatory": arr[i].entity.mandatory,
				"comments": arr[i].entity.comments,
				"commentsUrl": arr[i].entity.commentsUrl,
				"commentsInline": arr[i].entity.commentsInline,
				"commentsAuthor": arr[i].entity.commentsAuthor,
				"commentsAudio": arr[i].entity.commentsAudio,
				"commentsSsml": arr[i].entity.commentsSsml,
				"commentsPrint": arr[i].entity.commentsPrint,
				"commentsAudioFile": arr[i].entity.commentsAudioFile,
				"commentsTemplate": arr[i].entity.commentsTemplate,
				"commentsDataPath": arr[i].entity.commentsDataPath,
				"commentsVisibility": arr[i].entity.commentsVisibility,
				"linkOnCheck": arr[i].entity.linkOnCheck,
				"linkId": arr[i].entity.linkId,
				"linkActionType": arr[i].entity.linkActionType,
				"valueTrigger": arr[i].entity.valueTrigger,
				"backgroundImage": arr[i].entity.backgroundImage,
				"video": arr[i].entity.video,
				"videoMute": arr[i].entity.videoMute,
				"videoLoop": arr[i].entity.videoLoop,
				"videoAspectRatio": arr[i].entity.videoAspectRatio,
				"videoAspectRatioCustom": arr[i].entity.videoAspectRatioCustom,
				"videoMaximumHeight": arr[i].entity.videoMaximumHeight,
				"videoShowInBackground": arr[i].entity.videoShowInBackground,
				"videoResizeMode": arr[i].entity.videoResizeMode,
				"videoOverlayColor": arr[i].entity.videoOverlayColor,
				"videoOverlayColorCustom": arr[i].entity.videoOverlayColorCustom,
				"videoTextTracks": arr[i].entity.videoTextTracks,
				"image": arr[i].entity.image,
				"imageAspectRatio": arr[i].entity.imageAspectRatio,
				"imageMaximumHeight": arr[i].entity.imageMaximumHeight,
				"imageShowInBackground": arr[i].entity.imageShowInBackground,
				"imageResizeMode": arr[i].entity.imageResizeMode,
				"imageOverlayColor": arr[i].entity.imageOverlayColor,
				"imageOverlayColorCustom": arr[i].entity.imageOverlayColorCustom,
				"imageKenBurnsEffectEnabled": arr[i].entity.imageKenBurnsEffectEnabled,
				"imageKenBurnsEffectDuration": arr[i].entity.imageKenBurnsEffectDuration,
				"imageKenBurnsEffectStartScaleX": arr[i].entity.imageKenBurnsEffectStartScaleX,
				"imageKenBurnsEffectStartScaleY": arr[i].entity.imageKenBurnsEffectStartScaleY,
				"imageKenBurnsEffectEndScaleX": arr[i].entity.imageKenBurnsEffectEndScaleX,
				"imageKenBurnsEffectEndScaleY": arr[i].entity.imageKenBurnsEffectEndScaleY,
				"audio": arr[i].entity.audio,
				"pdf": arr[i].entity.pdf,
				"threeD": arr[i].entity.threeD,
				"backgroundImageDrive": arr[i].entity.backgroundImageDrive,
				"videoDrive": arr[i].entity.videoDrive,
				"imageDrive": arr[i].entity.imageDrive,
				"audioDrive": arr[i].entity.audioDrive,
				"pdfDrive": arr[i].entity.pdfDrive,
				"threeDDrive": arr[i].entity.threeDDrive,
				"backgroundAudioDuckingDuck": arr[i].entity.backgroundAudioDuckingDuck,
				"backgroundAudioDuckingDuration": arr[i].entity.backgroundAudioDuckingDuration,
				"backgroundAudioDuckingVolume": arr[i].entity.backgroundAudioDuckingVolume,
				"authorProperties": safeParse(arr[i].entity.authorProperties, {}),
				"itemHeight": arr[i].entity.itemHeight,
				"itemButtonOkLabel": arr[i].entity.itemButtonOkLabel,
				"itemButtonOkVisibility": arr[i].entity.itemButtonOkVisibility,
				"itemCommentsLabel": arr[i].entity.itemCommentsLabel,
				"itemRelatedItemsLabel": arr[i].entity.itemRelatedItemsLabel,
				"suppressAudio": arr[i].entity.suppressAudio,
				"suppressAudioLabel1": arr[i].entity.suppressAudioLabel1,
				"suppressAudioLabel2": arr[i].entity.suppressAudioLabel2,
				"suppressAudioValue": arr[i].entity.suppressAudioValue,
				"procedureViewType": arr[i].entity.procedureViewType,
				"suppressPrint": arr[i].entity.suppressPrint,
				"startTracking": arr[i].entity.startTracking,
				"stopTracking": arr[i].entity.stopTracking,
				"saveOnCheck": arr[i].entity.saveOnCheck,
				"saveAndExitOnCheck": arr[i].entity.saveAndExitOnCheck,
				"generateReportOnCheck": arr[i].entity.generateReportOnCheck,
				"generateReportOnCheckTemplate": arr[i].entity.generateReportOnCheckTemplate,
				"startNamedTimers": arr[i].entity.startNamedTimers,
				"stopNamedTimers": arr[i].entity.stopNamedTimers,
				"useAsSessionName": arr[i].entity.useAsSessionName,
				"isSearchable": arr[i].entity.isSearchable,
				"enabled": arr[i].entity.enabled,
				"visible": arr[i].entity.visible,
				"valueSticky": arr[i].entity.valueSticky,
				"conditionalVisible": arr[i].entity.conditionalVisible,
				"calculatedDefaultValue": arr[i].entity.calculatedDefaultValue,
				"printProperties": arr[i].entity.printProperties,
				"printCustomProperties": safeParse(arr[i].entity.printCustomProperties, {}),
				"repeatable": arr[i].entity.repeatable,
				"repeatableRetainValues": arr[i].entity.repeatableRetainValues,
				"repeatableIndex": arr[i].entity.repeatableIndex,
				"repeatableInitialCount": arr[i].entity.repeatableInitialCount,
				"repeatableMaxCount": arr[i].entity.repeatableMaxCount
			};

			let deltaProps = {};
			switch (arr[i].entity.type) {
				case "item":
					deltaProps = {
						labelOnly: arr[i].entity.labelOnly,
						labelOnlyBackgroundColor: arr[i].entity.labelOnlyBackgroundColor,
						startTimer: arr[i].entity.startTimer,
						stopTimer: arr[i].entity.stopTimer
					};
					break;
				case "itemTextInput":
				case "itemTextInputMultiline":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						textInputPlaceholder: arr[i].entity.textInputPlaceholder,
						textInputDefaultValue: arr[i].entity.textInputDefaultValue,
						textInputMaxLength: arr[i].entity.textInputMaxLength,
						textInputKeyboardType: arr[i].entity.textInputKeyboardType,
						textInputKeyboardAutoCapitalize: arr[i].entity.textInputKeyboardAutoCapitalize,
						textInputKeyboardAutoCorrect: arr[i].entity.textInputKeyboardAutoCorrect,
						textInputKeyboardReturnKeyType: arr[i].entity.textInputKeyboardReturnKeyType,
						textInputMaskType: arr[i].entity.textInputMaskType,
						textInputCustomMask: arr[i].entity.textInputCustomMask,
						textInputCustomMask2: arr[i].entity.textInputCustomMask2,
						textInputCurrencySymbol: arr[i].entity.textInputCurrencySymbol,
						textInputCurrencySeparator: arr[i].entity.textInputCurrencySeparator,
						textInputNumberOfLines: arr[i].entity.textInputNumberOfLines
					};
					break;
				case "itemYesNo":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						yesNoLinkOnSelect: arr[i].entity.yesNoLinkOnSelect,
						yesNoLinkActionType: arr[i].entity.yesNoLinkActionType,
						yesLinkActionType: arr[i].entity.yesLinkActionType,
						noLinkActionType: arr[i].entity.noLinkActionType,
						naLinkActionType: arr[i].entity.naLinkActionType,
						yesNoYesLinkId: arr[i].entity.yesNoYesLinkId,
						yesNoNoLinkId: arr[i].entity.yesNoNoLinkId,
						yesNoNaLinkId: arr[i].entity.yesNoNaLinkId,
						yesNoShowNa: arr[i].entity.yesNoShowNa,
						yesNoAdvanceOnSelect: arr[i].entity.yesNoAdvanceOnSelect,
					};
					break;
				case "itemPicker":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						pickerItemViewType: arr[i].entity.pickerItemViewType,
						pickerItemsDatasource: _.omit(arr[i].entity.pickerItemsDatasource, ["content"]),
						pickerItemPlaceholder: arr[i].entity.pickerItemPlaceholder,
						pickerItemDefaultValue: arr[i].entity.pickerItemDefaultValue,
						pickerItems: arr[i].entity.pickerItems,
						pickerAdvanceOnSelect: arr[i].entity.pickerAdvanceOnSelect,
						pickerLinkOnSelect: arr[i].entity.pickerLinkOnSelect,
						pickerLinkActionType: arr[i].entity.pickerLinkActionType
					};
					break;
				case "itemRanking":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						config: arr[i].entity.config,
						itemsDatasource: _.omit(arr[i].entity.itemsDatasource, ["content"]),
						associatedContentTemplate: arr[i].entity.associatedContentTemplate,
						items: arr[i].entity.items,
					};
					break;
				case "itemChoiceSingle":
				case "itemChoiceMulti":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						choiceItems: arr[i].entity.choiceItems,
					};
					break;
				case "itemDate":
				case "itemTime":
				case "itemDateTime":
					deltaProps = {
						dateTimeType: arr[i].entity.dateTimeType,
						dateTimeInitialDate: arr[i].entity.dateTimeInitialDate,
						dateTimeMinuteInterval: arr[i].entity.dateTimeMinuteInterval,
						dateTimeAdvanceOnSelect: arr[i].entity.dateTimeAdvanceOnSelect,
					};
					break;
				case "itemWeather":
					deltaProps = {
						weatherInitialLocation: arr[i].entity.weatherInitialLocation
					};
					break;
				case "itemBarcodeScanner":
					deltaProps = {
						required: arr[i].entity.required,
						requiredMessage: arr[i].entity.requiredMessage,
						barcodeTypes: arr[i].entity.barcodeTypes
					};
					break;
				case "itemImagePicker":
					deltaProps = {
						imagePickerMediaType: arr[i].entity.imagePickerMediaType,
						imagePickerAddMediaButton: arr[i].entity.imagePickerAddMediaButton,
						imagePickerUploadTitle: arr[i].entity.imagePickerUploadTitle,
						imagePickerCaptureMediaTitle: arr[i].entity.imagePickerCaptureMediaTitle
					};
					break;
				case "itemSketchPad":
					deltaProps = {
						sketchPadBackgroundColor: arr[i].entity.sketchPadBackgroundColor,
						sketchPadPenColor: arr[i].entity.sketchPadPenColor,
						sketchPadPenWidth: Number(arr[i].entity.sketchPadPenWidth),
						sketchPadHeight: Number(arr[i].entity.sketchPadHeight)
					};
					break;
				default:

			}


			// Merge props
			node = _.assign({}, node, deltaProps);


			dstChildren.push(node);
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseChecklistEntities(arr[i].children, newChildren);
		}
	}
}


/*
Add view specific props to checklist used in player
*/
export function transformToChecklistPlayer(checklist) {

	let checklistClone = _.cloneDeep(checklist);

	recurseChecklistPlayer(checklist, 0, checklistClone.children);
	return checklistClone;
}

function recurseChecklistPlayer(checklist, parentIndex, arr) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type === "list") {
			//set defaults for props that are new
			if (!arr[i].hasOwnProperty("backgroundColor")) { arr[i].backgroundColor = ""; }
			if (!arr[i].hasOwnProperty("borderColor")) { arr[i].borderColor = ""; }
			if (!arr[i].hasOwnProperty("iconColor")) { arr[i].iconColor = ""; }

			let selectedListIndex = _.get(checklist, "selected.listIndex");
			if (selectedListIndex === i) {
				arr[i].expanded = true;
			} else {
				arr[i].expanded = false;
			}
		} else if (arr[i].type === "section") {

			let selectedListIndex = _.get(checklist, "selected.listIndex");
			let selectedSectionIndex = _.get(checklist, "selected.sectionIndex");

			if (selectedListIndex === parentIndex && selectedSectionIndex === i) {
				arr[i].expanded = true;
			} else {
				arr[i].expanded = false;
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseChecklistPlayer(checklist, i, arr[i].children);
		}
	}
}

function safeParse(obj, defaultValue = null) {
	let result = defaultValue;
	if (result) {
		try {
			result = JSON.parse(obj);
		} catch (e) { } // eslint-disable-line
	}
	return result;
}

/*
Strip view specific player props
*/
export function transformFromChecklistPlayer(checklist) {
	let checklistClone = _.cloneDeep(checklist);
	recurseFromChecklistPlayer(checklistClone.children);
	return checklistClone;
}

function recurseFromChecklistPlayer(arr) {
	for (let i = 0; i < arr.length; i++) {

		if (arr[i].type === "list") {
			delete arr[i].expanded;
		} else if (arr[i].type === "section") {
			delete arr[i].expanded;
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseFromChecklistPlayer(arr[i].children);
		}
	}
}





import { v4 as uuidv4 } from 'uuid';

import _ from "lodash";

export function findEntityIndicesById(arr, entityId, entity, listIndex, sectionIndex, itemIndex) {
	if (_.isUndefined(listIndex)) {
		listIndex = -1;
	}
	if (_.isUndefined(sectionIndex)) {
		sectionIndex = -1;
	}
	if (_.isUndefined(itemIndex)) {
		itemIndex = -1;
	}

	for (var i = 0; i < arr.length; i++) {
		if (arr[i].entity.type === "list") {
			listIndex = i;
		} else if (arr[i].entity.type === "section") {
			sectionIndex = i;
		} else if (arr[i].entity.type.startsWith("item")) {
			itemIndex = i;
		}

		if (entityId === arr[i].entity.entityId) {
			entity.found = true;
			entity.result = { type: itemIndex > -1 ? "item" : sectionIndex > -1 ? "section" : "list", listIndex: listIndex, sectionIndex: sectionIndex, itemIndex: itemIndex };
		}

		if (arr[i].hasOwnProperty("children")) {
			findEntityIndicesById(arr[i].children, entityId, entity, listIndex, sectionIndex, itemIndex);
		}
	}
}

export function findEntityChildrenById(arr, entityId, entity) {
	for (var i = 0; i < arr.length; i++) {
		if (entityId === arr[i].entity.entityId) {
			entity.found = true;
			entity.result = arr[i].children;
		}
		if (arr[i].hasOwnProperty("children")) {
			findEntityChildrenById(arr[i].children, entityId, entity);
		}
	}
}

export function addCategoryNode(entity, expanded) {
	return {
		"id": uuidv4(),
		"entity": entity,
		"expanded": expanded,
		"children": []
	};
}

export function addItemNode(entity) {
	return {
		"id": uuidv4(),
		"entity": entity
	};
}

export function addCategoryEntity(entityId, label, visible) {
	return {
		"guid": uuidv4(),
		"entityId": entityId,
		"id": "",
		"type": "category",
		"lang": "",
		"label": label,
		"excludeFromNavigation": false,
		"relatedItemRefs": "",
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"labelAudioFile": "",
		"ignoreCompletion": false,
		"hideStatus": false,
		"visible": visible,
		"trackData": false,
		"trackLocation": false,
		"procedureViewType": "default",
		"itemButtonOkLabel": "OK ✓",
		"itemButtonOkVisibility": "visible",
		"itemCommentsLabel": "More...",
		"itemRelatedItemsLabel": "Related",
		"theme": "{}",
		"stylesheet": "{}",
		"styles": "{}",
		"classes": "{}",
		"printCustomProperties": "{}",
		"authorProperties": "{}"
	};
}

export function addListEntity(entityId, label) {
	return {
		"guid": uuidv4(),
		"entityId": entityId,
		"id": "",
		"type": "list",
		"label": label,
		"excludeFromNavigation": false,
		"relatedItemRefs": "",
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"labelAudioFile": "",
		"defaultView": "inherit",
		"startInBirdseyeView": false,
		"color": "",
		"backgroundColor": "",
		"borderColor": "",
		"iconColor": "",
		"styles": "{}",
		"classes": "{}",
		"suppressPrint": false,
		"suppressAudio": false,
		"suppressAudioTitle": false,
		"suppressAudioEntityType": false,
		"procedureViewType": "inherit",
		"suppressAudioCompletion": false,
		"visible": true,
		"enabled": true,
		"ignoreCompletion": false,
		"itemButtonOkLabel": "",
		"itemButtonOkVisibility": "inherit",
		"itemCommentsLabel": "",
		"itemRelatedItemsLabel": "",
		"hideStatus": false,
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether: true,
			breakBefore: false,
			breakBeforeHeight: 0,
			breakAfter: false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}",
		"repeatable": false,
		"repeatableRetainValues": false,
		"repeatableIndex": 1,
		"repeatableInitialCount": 1,
		"repeatableMaxCount": 0,
		"authorProperties": "{}"
	};
}

export function addSectionEntity(entityId, label) {
	return {
		"guid": uuidv4(),
		"entityId": entityId,
		"id": "",
		"type": "section",
		"label": label,
		"excludeFromNavigation": false,
		"relatedItemRefs": "",
		"labelAlexaPlain": "",
		"labelAlexaRich": "",
		"labelAudio": "",
		"labelSsml": "",
		"labelPrint": "",
		"labelAudioFile": "",
		"color": "",
		"backgroundColor": "",
		"borderColor": "",
		"iconColor": "",
		"styles": "{}",
		"classes": "{}",
		"suppressPrint": false,
		"suppressAudio": false,
		"suppressAudioTitle": false,
		"suppressAudioEntityType": false,
		"suppressAudioCompletion": false,
		"procedureViewType": "inherit",
		"visible": true,
		"enabled": true,
		"ignoreCompletion": false,
		"itemButtonOkLabel": "",
		"itemButtonOkVisibility": "inherit",
		"itemCommentsLabel": "",
		"itemRelatedItemsLabel": "",
		"hideStatus": false,
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether: true,
			breakBefore: false,
			breakBeforeHeight: 0,
			breakAfter: false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}",
		"repeatable": false,
		"repeatableRetainValues": false,
		"repeatableIndex": 1,
		"repeatableInitialCount": 1,
		"repeatableMaxCount": 0,
		"authorProperties": "{}"
	};
}

export function addItemEntity(entityId, label, label2, labelOnly, mandatory, comments, type) {
	// Need to support types
	let item = {
		"guid": uuidv4(),
		"entityId": entityId,
		"id": "",
		"type": type ? type : "item",
		"navTitle": "",
		"excludeFromNavigation": false,
		"relatedItemRefs": "",
		"label": label,
		"label1EditorAdvanced": false,
		"label1AlexaPlain": "",
		"label1AlexaRich": "",
		"label1Audio": "",
		"label1Ssml": "",
		"label1Print": "",
		"label1AudioFile": "",
		"backgroundColor": "",
		"color": "",
		"styles": "{}",
		"classes": "{}",
		"label2": label2,
		"label2EditorAdvanced": false,
		"label2AlexaPlain": "",
		"label2AlexaRich": "",
		"label2Audio": "",
		"label2Ssml": "",
		"label2Print": "",
		"label2AudioFile": "",
		"label2Color": "",
		"labelOnly": labelOnly,
		"labelOnlyBackgroundColor": "",
		"linkOnCheck": false,
		"linkId": "",
		"linkActionType": "goto",
		"visible": true,
		"enabled": true,
		"required": false,
		"mandatory": mandatory,
		"valueSticky": false,
		"comments": comments,
		"commentsUrl": "",
		"commentsInline": false,
		"commentsAuthor": "",
		"commentsAudio": "",
		"commentsSsml": "",
		"commentsPrint": "",
		"commentsAudioFile": "",
		"commentsTemplate": "",
		"commentsDataPath": "",
		"backgroundImage": "",
		"video": "",
		"videoLoop": true,
		"videoMute": true,
		"videoAspectRatio": "",
		"videoAspectRatioCustom": "",
		"videoMaximumHeight": "default",
		"videoShowInBackground": false,
		"videoResizeMode": "contain",
		"videoOverlayColor": "transparent",
		"videoOverlayColorCustom": "rgba(255,255,255,0.7)",
		"videoTextTracks": [],
		"image": "",
		"imageAspectRatio": "",
		"imageMaximumHeight": "default",
		"imageShowInBackground": false,
		"imageResizeMode": "contain",
		"imageOverlayColor": "transparent",
		"imageOverlayColorCustom": "rgba(255,255,255,0.7)",
		"imageKenBurnsEffectEnabled": false,
		"imageKenBurnsEffectDuration": "default",
		"imageKenBurnsEffectStartScaleX": "default",
		"imageKenBurnsEffectStartScaleY": "default",
		"imageKenBurnsEffectEndScaleX": "default",
		"imageKenBurnsEffectEndScaleY": "default",
		"audio": "",
		"pdf": "",
		"threeD": "",
		"suppressPrint": false,
		"suppressAudio": false,
		"suppressAudioLabel1": false,
		"suppressAudioLabel2": false,
		"suppressAudioValue": false,
		"procedureViewType": "inherit",
		"startTimer": false,
		"stopTimer": false,
		"startTracking": false,
		"stopTracking": false,
		"saveOnCheck": false,
		"saveAndExitOnCheck": false,
		"generateReportOnCheck": false,
		"generateReportOnCheckTemplate": "",
		"ignoreCompletion": false,
		"itemHeight": "default",
		"itemButtonOkLabel": "",
		"itemButtonOkVisibility": "inherit",
		"itemCommentsLabel": "",
		"itemRelatedItemsLabel": "",
		"groupNames": [],
		"filterTags": [],
		"printProperties": {
			keepTogether: true,
			breakBefore: false,
			breakBeforeHeight: 0,
			breakAfter: false,
			breakAfterHeight: 0
		},
		"printCustomProperties": "{}",
		"repeatable": false,
		"repeatableRetainValues": false,
		"repeatableIndex": 1,
		"repeatableInitialCount": 1,
		"repeatableMaxCount": 0,
		"authorProperties": "{}"
	};

	if (item.type.toLowerCase() === "itemyesno") {
		item["required"] = true;
		item["requiredMessage"] = "A value is required.";
		item["yesNoAdvanceOnSelect"] = true;
	} else if (item.type.toLowerCase() === "itempicker") {
		item["required"] = true;
		item["requiredMessage"] = "A value is required.";
	} else if (item.type.toLowerCase().startsWith("itemchoice")) {
		item["required"] = true;
		item["requiredMessage"] = "A value is required.";
	} else if (item.type.toLowerCase().startsWith("itemtextinput")) {
		item["required"] = true;
		item["requiredMessage"] = "A value is required.";
	} else if (item.type.toLowerCase().startsWith("itemranking")) {
		item["required"] = false;
		item["requiredMessage"] = "A value is required.";
	} else if (item.type.toLowerCase().startsWith("itembarcodeccanner")) {
		item["required"] = false;
		item["requiredMessage"] = "A value is required.";
	} else if (item.type.toLowerCase().startsWith("itemimagepicker")) {
		// No op
	} else if (item.type.toLowerCase().startsWith("itemsketchpad")) {
		// No op
	} else if (item.type.toLowerCase().startsWith("itemdate") || item.type.toLowerCase().startsWith("itemtime")) {
		// No op
	} else if (item.type.toLowerCase() === "itemweather") {
		// No op
	}

	return item;
}

export function getLabel1Color(item) {
	let label1Color = "";
	if (item.hasOwnProperty("color") && item.color !== "") {
		label1Color = item.color;
	}

	return label1Color;
}

export function getLabel2Color(item) {
	let label2Color = "";
	if (item.hasOwnProperty("label2Color") && item.label2Color !== "") {
		label2Color = item.label2Color;
	}

	return label2Color;
}

export function findEntityByGuid(authoringActivity, guid) {
	return recurseGetEntityByGuid(authoringActivity.children, guid)
}

function recurseGetEntityByGuid(arr, guid) {
	if (arr) {
		for (let i = 0; i < arr.length; i++) {
			let child = arr[i];

			//console.log(`FIND ENTITY BY GUID: ${guid} / ${child.entity.type} ${child.entity.guid}`);
			if (child.entity.guid === guid) {
				return child;
			}

			if (child.children) {
				let found = recurseGetEntityByGuid(child.children, guid);
				if (found) {
					return found;
				}
			}
		}
	}
}
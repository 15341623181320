import React, { PureComponent } from "react";
import Flexbox from "flexbox-react";
import MediaQuery from "react-responsive";
import _ from "lodash";
import ErrorBoundary from "../errors/errorBoundary";

import Alerts from "../../components/app-alerts.js";
import NavLinks from "../navPanel/app-nav-links";
import NavPanel from "../navPanel/app-nav-panel";
import { SaveModal } from "../drive/";
import ChecklistPreviewModal from "../app-checklist-preview-modal";

export default class Page extends PureComponent {
	render() {

		let contentAreaStyles = { overflowY: "auto", width: "100%", backgroundColor: "white" };
		if (this.props.contentAreaStyles) {
			contentAreaStyles = _.assign({}, contentAreaStyles, this.props.contentAreaStyles);
		}

		const classNames = ["pageWrapper"];
		if (this.props.className) {
			classNames.push(this.props.className);
		}

		const { title, subTitle, toolbar, flexLayout } = this.props;
		let titleComponent = null;
		if (title) {
			titleComponent = <div className="title">
				<h3 style={{ marginTop: "10px", marginBottom: 0 }}>{title}</h3>
				{subTitle && <h5 style={{ margin: 0, marginTop: "10px", color: "darkgray" }}>{subTitle}</h5>}
			</div>;
		}
		const pageHeader = titleComponent || toolbar ? <div className={`page-header${flexLayout ? " flex" : ""}`}>
			{titleComponent}
			{toolbar && <div>
				{toolbar}
			</div>}
		</div> : null;
		return (

			<Flexbox className={classNames.join(" ")} flexDirection="column">
				<Alerts />
				<SaveModal />
				{/* <NavLinks /> */}
				<NavLinks showReorder={this.props.showReorder} onReorder={this.props.onReorder} />
				{/* <MediaQuery query='(max-width: 599px)'>
					<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
						<Flexbox style={contentAreaStyles} padding="10px" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="flex-start">
							{this.props.children}
						</Flexbox>
					</Flexbox>
				</MediaQuery>
				<MediaQuery query='(min-width: 600px)'> */}
				<Flexbox flex="1" flexDirection="row" width="100vw" style={{ minHeight: 0 }}>
					<MediaQuery query='(min-width: 768px)'>
						<NavPanel onFilter={this.props.onFilter} onFilterEnd={this.props.onFilterEnd} />
					</MediaQuery>
					<Flexbox style={contentAreaStyles} padding={this.props.padding ? this.props.padding : "10px"} flexDirection="column" flex="1" justifyContent="flex-start" className="page-scroll-view">
						<ErrorBoundary>
							{pageHeader}
							{this.props.children}
						</ErrorBoundary>
					</Flexbox>
				</Flexbox>
				<ChecklistPreviewModal />
				{/* </MediaQuery> */}
			</Flexbox>
		);
	}
}


import React, { PureComponent } from "react";
import { findDOMNode, createPortal } from "react-dom";
import ReactSelect from "react-select";
import autoBind from "react-autobind";
import "react-select/dist/react-select.css"; // I'm using css-modules, but you can use whatever you like
import defaultMenuRenderer from "react-select/lib/utils/defaultMenuRenderer"; // this renders the actual menu - we can reuse the same component

class SelectMenu extends PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	componentDidMount() {
		window.addEventListener("scroll", this._handleScroll);  // just forceUpdate on scroll
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this._handleScroll);
	}

	getDocumentBody() {
		return document.body;
	}

	_handleScroll() {
		this.forceUpdate(); // just need to re-render on scroll so that the menu is not stuck in mid-air while the select input scrolls off-screen
	}

	render() {
		const rect = this.props.selectDOM.getBoundingClientRect();
		const computedStyle = window.getComputedStyle(this.props.selectDOM); // inherit as many styles as you want from the parent select
		const fontSize = computedStyle.getPropertyValue("font-size");
		const lineHeight = computedStyle.getPropertyValue("line-height");
		const position = {
			top: rect.bottom - 1,
			left: rect.left,
			width: rect.width,
		};

		return (
			createPortal(
				<div className={"Select-menu-outer"} style={{ ...position, fontSize, lineHeight, zIndex: 1001 }}>
					<div className="Select-menu">{defaultMenuRenderer(this.props.selectProps)}</div>
				</div>, document.body));
	}
}

export default class Select extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	componentDidMount() {
		this._selectDOM = findDOMNode(this._select).children[0];
	}

	componentDidUpdate() {
		this._selectDOM = findDOMNode(this._select).children[0];
	}

	_getRef(c) { this._select = c; }

	_renderMenu(selectProps) {
		if (!this._selectDOM) {
			return defaultMenuRenderer(selectProps);
		}

		return (
			<SelectMenu
				selectProps={selectProps}
				selectDOM={this._selectDOM}  // pass the select's dom node (for getting styles & bounding-box calcs
			/>
		);
	}


	render() {
		const {
			wrapperClassName,
			...otherProps
		} = this.props;

		return (
			<div
				ref={this._getRef}  // I am using some custom styles, so using a wrapping div. You may use <ReactSelect ...> directly here.
				className={wrapperClassName}
			>
				<ReactSelect
					{...otherProps}
					menuRenderer={this._renderMenu}
				/>
			</div>
		);
	}
}
import Baobab from "baobab";
import env from "../constants/env";

import contentEN_US from "../content/en-US/content.js";

import { getLs, getFirstLevelItemType, isDev } from "../utils/utils";

const { appCapabilities } = env;

const state = new Baobab(
	{
		removeAllLogs: false,
		lang: "en-US",
		version: {
			copyright: "© 2017-2024 AmbiFi, Inc. - All Rights Reserved",
			prefix: "Version",
			major: "8",
			minor: "155",
			revision: "0",
			build: "3731",
			suffix: ""
		},
		appCapabilities,
		device: null,
		devices: [],
		activities: [],
		checklistHistory: null,
		tree: {},
		editActivityMetaData: {
			inlineVariables: {}
		},
		selectedNodePath: [],
		typeahead: [],
		clipboardPasteText: "",
		clipboard: {},
		clipboardExternalResources: [],
		clipboardRelatedItems: [],
		clipboardMode: "",
		history: [],
		historyIndex: -1,
		linkActionTypes: [
			{
				label: "Goto",
				action: "goto",
				description: "Will navigate to the target, this action does not provide a back button.",
				nav: true
			},
			{
				label: "Goto (New Session)",
				action: "newSessionAndGoto",
				description: "Will navigate to the target and start a new session, meaning the activity will start from the beginning and no data will be filled in, this action does not provide a back button.",
				nav: true
			},
			{
				label: "Goto (Reset Items In Range)",
				action: "resetRangeAndGoto",
				description: "Will navigate to the target and reset all items between (and including) the current item and the target, this action does not provide a back button.",
				nav: true
			},
			{
				label: "Goto and Return",
				action: "gotoAndReturn",
				description: "Will navigate to the target, this action provides the user with a back button and allows them to navigate back to where they came from.",
				nav: true
			},
			{
				label: "Goto and Return (New Session)",
				action: "newSessionAndGotoAndReturn",
				description: "Will navigate to the target and start a new session, meaning the activity will start from the beginning and no data will be filled in, this action provides the user with a back button and allows them to navigate back to where they came from.",
				nav: true
			},
			{
				label: "Goto and Open Birdseye View",
				action: "gotoAndOpenBirdseyeView",
				description: "Will navigate to the target and open the Birdseye View. The target ID should be set on a List.",
				nav: true
			},
			{
				label: "Show Item in Dialog",
				description: "This will allow to user to remain at the current location but show a dialog with the content of the specified item, this dialog can then be dismissed by the user at which point they pick up where they left off.",
				action: "showModalItem",
				nav: true,
				internalOnly: true
			},
			// {
			// 	label: "Show Informational Content in Dialog",
			// 	description: "This will you to add labels and media to show to a user without having to create a separate item.",
			// 	action: "showModalDynamicItem"
			// },
			{
				label: "Go Back",
				description: "Will perform a navigate back action if it is available (e.g.: after a go to and return).",
				action: "goBack"
			},
			{
				label: "Save + Exit",
				description: "Saves the current Activity instance and navigates back to the home screen.",
				action: "saveActivityAndExit"
			},
			{
				label: "Reset Current Activity",
				description: "Will erase all data in the current activity and take the user to the beginning of the activity.",
				action: "resetCurrentActivity"
			},
			{
				label: "Reset Current List",
				description: "Will erase all data in the current list and take the user to the beginning of the list.",
				action: "resetCurrentList"
			},
			{
				label: "Reset Current Section",
				description: "Will erase all data in the current section and take the user to the beginning of the section.",
				action: "resetCurrentSection"
			},
			{
				label: "Repeat Current List",
				description: "Used in conjuction with the repeatble property, creates a new List based on the current List.",
				action: "repeatCurrentList"
			},
			{
				label: "Repeat Current Section",
				description: "Used in conjunction with the repeatable property, creates a new section based on the current Section.",
				action: "repeatCurrentSection"
			},
			{
				label: "Repeat Current Item",
				description: "Used in conjunction with the repeatable property, creates a new item based on the current Item.",
				action: "repeatCurrentItem"
			},
			{
				label: "Open Comments",
				description: "Will open author comments.",
				action: "openComments"
			},
			{
				label: "Open Notes",
				description: "Will open the notes to allow a user to capture additional information.",
				action: "openNotes"
			},
			{
				label: "Open Media",
				description: "Will open the media attachment dialog that allows users to add images and videos as supplemental notes.",
				action: "openMedia"
			},
			{
				label: "Open Sketchpad",
				description: "Will open the sketchpad dialog that allows users to add drawn sketches as supplemental notes.",
				action: "openSketchpad"
			},
			{
				label: "External",
				description: "An external URL that will be opened in the browser.",
				action: "external"
			}
		],
		itemTypes: [
			{
				name: "Check Item",
				type: "item",
				icon: "check"
			},
			{
				name: "Check Item (Label Only)",
				type: "itemLabelOnly",
				icon: "spell-check"
			},
			{
				name: "Text Input",
				type: "itemTextInput",
				icon: "font"
			},
			{
				name: "Text Input (Multiline)",
				type: "itemTextInputMultiline",
				icon: "font"
			},
			{
				name: "Yes / No",
				type: "itemYesNo",
				icon: "thumbs-up"
			},
			{
				name: "Picker",
				type: "itemPicker",
				icon: "list"
			},
			{
				name: "Single Choice",
				type: "itemChoiceSingle",
				icon: ["far", "dot-circle"]
			},
			{
				name: "Multi Choice",
				type: "itemChoiceMulti",
				icon: ["far", "check-square"]
			},
			{
				name: "Ranking",
				type: "itemRanking",
				icon: "sort"
			},
			{
				name: "Date Picker",
				type: "itemDate",
				icon: "calendar"
			},
			{
				name: "Time Picker",
				type: "itemTime",
				icon: "calendar"
			},
			{
				name: "Date Time Picker",
				type: "itemDateTime",
				icon: "calendar"
			},
			{
				name: "Weather",
				type: "itemWeather",
				icon: "cloud"
			},
			{
				name: "Address Search",
				type: "itemAddress",
				icon: "location-arrow"
			},
			{
				name: "Location Search",
				type: "itemLocation",
				icon: "location-arrow"
			},
			{
				name: "Media Upload",
				type: "itemImagePicker",
				icon: ["far", "file-image"]
			},
			{
				name: "Barcode Scanner",
				type: "itemBarcodeScanner",
				icon: "barcode"
			},
			{
				name: "Sketch Pad",
				type: "itemSketchPad",
				icon: "edit"
			}
		],
		genres: [
			{
				value: "auto",
				name: "Automobile",
				icon: "car",
				iconPng: "https://i.imgur.com/qyUEp4f.png"
			},
			{
				value: "aviation",
				name: "Aviation",
				icon: "plane",
				iconPng: "https://i.imgur.com/WHjyNob.png"
			},
			{
				value: "drone",
				name: "Drone",
				icon: "fighter-jet",
				iconPng: "https://i.imgur.com/WzXw8Po.png"
			},
			{
				value: "bike",
				name: "Bike",
				icon: "bicycle",
				iconPng: "https://i.imgur.com/0AMfTYN.png"
			},
			{
				value: "boat",
				name: "Boat",
				icon: "ship",
				iconPng: "https://i.imgur.com/KtcTbZj.png"
			},
			{
				value: "fun",
				name: "Fun",
				icon: "puzzle-piece",
				iconPng: "https://i.imgur.com/RIp6GG1.png"
			},
			{
				value: "food",
				name: "Food",
				icon: "utensil-spoon",
				iconPng: "https://i.imgur.com/FQd4TWZ.png"
			},
			{
				value: "games",
				name: "Games",
				icon: "gamepad",
				iconPng: "https://i.imgur.com/quGKE9k.png"
			},
			{
				value: "groceries",
				name: "Groceries",
				icon: "shopping-cart",
				iconPng: "https://i.imgur.com/nN6A66S.png"
			},
			{
				value: "health",
				name: "Health",
				icon: "ambulance",
				iconPng: "https://i.imgur.com/mMIK26a.png"
			},
			{
				value: "holidays",
				name: "Holidays",
				icon: "tree",
				iconPng: "https://i.imgur.com/3lp6LCd.png"
			},
			{
				value: "home",
				name: "Home",
				icon: "home",
				iconPng: "https://i.imgur.com/HPiip9M.png"
			},
			{
				value: "kids",
				name: "Kids",
				icon: "smile",
				iconPng: "https://i.imgur.com/VPstdR1.png"
			},
			{
				value: "legal",
				name: "Legal",
				icon: "gavel",
				iconPng: "https://i.imgur.com/DHyXMgi.png"
			},
			{
				value: "learning",
				name: "Learning",
				icon: "book",
				iconPng: "https://i.imgur.com/itjRDLW.png"
			},
			{
				value: "maintenance",
				name: "Maintenance",
				icon: "wrench",
				iconPng: "https://i.imgur.com/FxgGsnk.png"
			},
			{
				value: "money",
				name: "Money",
				icon: "money-bill-alt",
				iconPng: "https://i.imgur.com/Tyz2Fpu.png"
			},
			{
				value: "movies",
				name: "Movies",
				icon: "ticket",
				iconPng: "https://i.imgur.com/7Kk82Wj.png"
			},
			{
				value: "music",
				name: "Music",
				icon: "music",
				iconPng: "https://i.imgur.com/KiDWWwM.png"
			},
			{
				value: "motorcycle",
				name: "Motorcycle",
				icon: "motorcycle",
				iconPng: "https://i.imgur.com/1bfmjEY.png"
			},
			{
				value: "people",
				name: "People",
				icon: "users",
				iconPng: "https://i.imgur.com/VU2dTse.png"
			},
			{
				value: "pets",
				name: "Pets",
				icon: "paw",
				iconPng: "https://i.imgur.com/niAuSzv.png"
			},
			{
				value: "photo",
				name: "Photography",
				icon: "camera-retro",
				iconPng: "https://i.imgur.com/a4VTwWx.png"
			},
			{
				value: "restaurants",
				name: "Restaurants",
				icon: "utensils",
				iconPng: "https://i.imgur.com/KWJl1lu.png"
			},
			{
				value: "school",
				name: "School",
				icon: "graduation-cap",
				iconPng: "https://i.imgur.com/FE7UPUo.png"
			},
			{
				value: "science",
				name: "Science",
				icon: "flask",
				iconPng: "https://i.imgur.com/i4nnHbD.png"
			},
			{
				value: "shopping",
				name: "Shopping",
				icon: "shopping-bag",
				iconPng: "https://i.imgur.com/McFzyKa.png"
			},
			{
				value: "social",
				name: "Social",
				icon: "beer",
				iconPng: "https://i.imgur.com/ZO5Js7h.png"
			},
			{
				value: "sports",
				name: "Sports",
				icon: "futbol",
				iconPng: "https://i.imgur.com/IQAAL43.png"
			},
			{
				value: "tv",
				name: "Television",
				icon: "tv",
				iconPng: "https://i.imgur.com/RVEgTL6.png"
			},
			{
				value: "travel",
				name: "Travel",
				icon: "hotel",
				iconPng: "https://i.imgur.com/zaxDHNy.png"
			},
			{
				value: "video",
				name: "Video",
				icon: "video",
				iconPng: "https://i.imgur.com/m3mRYo9.png"
			},
			{
				value: "work",
				name: "Work",
				icon: "chart-bar",
				iconPng: "https://i.imgur.com/vBP2oLl.png"
			},
			{
				value: "misc",
				name: "Miscellaneous",
				icon: "question",
				iconPng: "https://i.imgur.com/lVSsmXj.png"
			}
		],
		tagSuggestions: [
		],
		groupNames: [
		],
		filterTags: [
		],
		user: {
			sub: "",
			name: "",
			username: "",
			email: "",
			password: "",
			code: "",
			identityId: "",
			loggedIn: false,
			historyUsers: [],
			entryPoint: "",
			org: null,
			savePassword: true,
			rememberMe: true,
			sharedDevice: false
		},
		timerTemplates: [],
		templates: {
			customTimer: {
				"id": "",
				"name": "",
				"type": "countdown",
				"mode": "normal",
				"viewType": "",
				"label1": "",
				"label2": "",
				"hours": 0,
				"minutes": 0,
				"seconds": 0,
				"hideDismiss": false,
				"suppressAudio": false,
				"action": {
					"type": "message",
					"message": "",
					"action": "",
					"linkItemId": "",
					"checklistId": ""
				},
				"triggers": [
				]
			}
		},
		appState: {
			priceListMap: null,
			customerMap: null,
			aiAssistantResponse: "",
			forceEditorRefresh: 0,
			hideHomeAlert: false,
			preview: {
				previewScale: "100",
				previewWidth: "100%",
				previewHeight: "100%",
				resolution: "responsive",
				resolutionDisabled: false
			},
			errorSpinner: {
				show: false
			},
			templateModal: {
				show: false
			},
			reasignModal: {
				show: false
			},
			playlistsModal: {
				show: false
			},
			startupModal: {
				showModal: true
			},
			timerModal: {
				show: false,
				timerTemplate: {}
			},
			mediaPreviewModal: {
				show: false,
				url: "",
				propName: ""
			},
			checklistReceived: false,
			checklistReceivedMessage: "Activity successfully received!",
			lastIdentityId: "",
			lastChecklistId: "",
			showDialogPriceTiers: false,
			registrationStep: 1,
			loading: false,
			loadingMessage: "",
			nodes: {

			},
			alerts: [],
			confirm: {
				message: "",
				title: "",
				onConfirm: null
			},
			documents: {
				initialSpinnerShow: false,
				showSpinner: false,
				spinnerMessage: ""
			},
			editor: {
				multiSelectMode: false,
				showSpinner: false,
				spinnerMessage: ""
			},
			navPanel: {
				selected: "home"
			},
			previewChecklist: {
				checklist: {},
				showModal: false,
				showInline: false,
				live: false,
				allVisible: true,
				drive: {}
			},
			showWorkflow: {
				checklist: {},
				showModal: false,
				url: ""
			},
			showWorkflowForMultiple: {
				checklist: {},
				showModal: false
			},
			showPricing: {
				invoiceItems: [],
				showModal: false,
				url: ""
			},
			showNotes: {
				checklist: {},
				showModal: false,
				sessionName: ""
			},
			showDetailedStatus: {
				checklist: {},
				checklistUserId: "",
				showModal: false,
				edit: false,
				modal: {
					showSpinner: false
				}
			},
			showDetailedHistory: {
				checklist: {},
				showModal: false
			},
			showCreateChatChannel: {
				activeChannelId: null,
				instance: {},
				showModal: false,
				isEditing: false
			},
			streamChat: {
				unreadMessagesCount: 0,
				unreadChannelsCount: 0
			},
			dolby: {

			},
			videoCall: {
				visible: false,
				autoJoin: true,
				conferenceId: "",
				conferenceAlias: "",
				invitedUsers: []
			},
			lastLocation: null,
			filters: {
				showVisible: true,
				showDependencies: true,
				all: {
					genres: [],
					tags: []
				},
				settings: {
					expandGenres: false,
					expandTags: false,
					sortGenresByCount: false,
					sortTagsByCount: false
				}
			},
			spinners: {
				orgs: {
					showMembersPanelSpinner: false
				}
			},
			assignments: {
				showLoader: false,
				showCreateModal: false,
				showEditModal: false
			},
			groupManagement: {
				showCreateEditModal: false,
				showCreateUpdateSpinner: false
			},
			scheduleManagement: {
				showCreateEditModal: false,
				showCreateUpdateSpinner: false
			},
			reporting: {
				showPdfPreviewLoader: false
			},
			cms: {
				models: [],
				flattenedModel: {}
			},
			selectedActivities: [],
			selectedMembers: [],
			selectedSearchItems: [],
			selectedStatusItems: [],
			searchItems: [],
			statusItems: [],
			statusViewType: "card-view",
			relatedItems: {
				clipboard: ""
			},
			urlParams: {},
			enterprise: false,
			subDomain: "",
			tabChat: "customer"
		},
		orgs: [],
		selectedOrg: {
			groups: [
				{
					id: "57a08a11-5196-459b-a800-80aa38b5eb58",
					name: "Managers",
					logoUrl: "",
					members: [
						{
							sub: "cf7a3d28-f120-49a8-b4f6-e73375919cfa",
							username: "alexa.poc",
							displayName: "AmbiFi Home",
							profileImageUrl: ""
						},
						{
							sub: "b9269679-7b25-4ce1-983b-16085e28c9d2",
							username: "maintenance.poc",
							displayName: "Maintenance Home",
							profileImageUrl: ""
						}
					]
				}
			],
			members: [
				{
					sub: "cf7a3d28-f120-49a8-b4f6-e73375919cfa",
					username: "alexa.poc",
					displayName: "AmbiFi Home",
					profileImageUrl: ""
				},
				{
					sub: "b9269679-7b25-4ce1-983b-16085e28c9d2",
					username: "maintenance.poc",
					displayName: "Maintenance Home",
					profileImageUrl: ""
				},
				{
					sub: "5f872edf-7783-47ab-9201-836a166ef3a3",
					username: "testuser1",
					displayName: "Test User1",
					profileImageUrl: ""
				}
			]
		},
		signup: {
			email: "",
			name: "",
			displayName: null,
			orgName: "",
			orgDomain: "",
			step: 1,
			showSpinner: false
		},
		search: {
			featured: [],
			checkmate: [],
			trending: [],
			recommended: []
		},
		import: {
			showSpinner: false
		},
		inviteMember: {
			showSpinner: false,
			inviteCode: null,
			role: 0 // Default to User
		},
		groupManagement: {},
		scheduleManagement: {},
		recent: [],
		editOrg: {},
		connection: {
			internetConnection: null,
			networkType: null
		},
		player: {
			checklist: {},
			checklistIdentityId: "",
			activities: [],
			activitiesLoaded: false,
			sessions: {},
			sessionsMap: {},
			sortedSesions: [],
			showSpinner: false,
			selectedActivityId: "",
			selectedActivityInstanceId: "",
			showSessionsModal: false,
			globalVars: {},
			navStack: [],
			reloadActivities: false,
			selectedHomeTab: "myActivities",
			showComments: false,
			showCommentsIdx: 0,
			statusDetailSessions: {}
		},
		drive: {
			settings: {
				mode: getLs("driveUi") ? getLs("driveUi") : "list"
			},
			children: null,
			modals: {
				saveModal: {
					show: false,
					content: null,
					path: null,
					fileName: null
				}
			},
			uploads: {},
			hierarchyMetadata: {},
			selectedFolder: ""
		},
		assignments: {
			showSpinner: false,
			list: [],
			selected: {},
			cronPresets: [
				{
					label: "Monday thru Friday @ 1pm",
					value: "0 0 13 ? * 1-5 *"
				},
				{
					label: "Every Monday in Februrary @ 1pm",
					value: "0 0 13 ? FEB MON *"
				},
				{
					label: "Every first Monday of the month @ 1pm",
					value: "0 0 13 ? 1/1 MON#1 *"
				}
			],
			schedule: []
		},
		generalClipboard: null,
		queryString: {},
		functions: "",
		publish: {
			status: {},
			newPublishConfig: {

			},
			showConfigModal: false,
			showSpinner: false,
			showPageSpinner: false,
			publishCompleteModal: {
				show: false,
				url: "",
				showDownloadButton: false,
				showClipboardButton: false
			}
		},
		playlists: [],
		playlistId: "",
		exportToZip: {
			status: {},
			config: {

			},
			showConfigModal: false,
			showSpinner: false,
			showPageSpinner: false
		},
		itemPreferences: {
			itemHeightOptions: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageMaxHeightOptions: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageKenBurnsEffectDuration: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageKenBurnsEffectStartScaleX: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageKenBurnsEffectStartScaleY: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageKenBurnsEffectEndScaleX: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			imageKenBurnsEffectEndScaleY: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}],
			videoMaxHeightOptions: [{
				label: "Default",
				value: "default"
			},
			{
				label: "Custom",
				value: "custom"
			}]
		},
		activityPreferences: {
			activityDefaultColumnWidthOptions: [
				{
					label: "User Preference",
					value: "userPreference"
				},
				{
					label: "Custom",
					value: "custom"
				}
			],
			activityChildDefaultColumnWidthOptions: [
				{
					label: "Inherit",
					value: "inherit"
				},
				{
					label: "User Preference",
					value: "userPreference"
				},
				{
					label: "Custom",
					value: "custom"
				}
			],
			activityChildBackgroundAudioDuckingOptions: [
				{
					label: "Inherit",
					value: "inherit"
				},
				{
					label: "Duck",
					value: true
				},
				{
					label: "Don't duck",
					value: false
				}
			],
			activityChildBackgroundAudioDurationVolumeOptions: [
				{
					label: "Inherit",
					value: "inherit"
				},
				{
					label: "Custom",
					value: "custom"
				}
			]
		},
		printTemplates: [],
		audio: {
			modals: {
				generateTts: {
					show: false
				},
				generateTtsForProperty: {
					show: false,
					propName: "",
					previewUrl: "",
					previewText: ""
				}
			},
			generateTtsConfig: {
				voiceId: "",
				sampleRate: null,
				engine: ""
			}
		},
		filters: {},
		content: contentEN_US,
		contentAll: {
			"en-US": contentEN_US
		},
		editor: {
			previewVisible: true,
			separateOutlineAndProps: true,
			layouts: [
				{
					name: "Outline / Properties / Preview",
					value: "outline-props-preview",
					disabled: false
				},
				{
					name: "Combined Outline + Properties / Preview",
					value: "outline-and-preview",
					disabled: false
				},
				{
					name: "Outline / Properties / NO Preview",
					value: "outline-props-no-preview",
					disabled: false
				},
				{
					name: "Combined Outline + Properties / NO Preview",
					value: "outline-no-preview",
					disabled: false
				}
			]
		}
	},
	{
		immutable: false
	}
);

// Resolve these initially, could use monkey as well
state.set(["appState", "editor", "previewVisible"], state.get(["editor", "previewVisible"]));
state.set(["appState", "editor", "separateOutlineAndProps"], state.get(["editor", "separateOutlineAndProps"]));

// JJB: Did see weird issue that if two sets in a row one can overwrite the other, saw this on driveRootPath
state.on('update', function (e) {
	// console.log("*!*!*! WHAT 1?");
	if (!state.get(["appState", "previewChecklist", "live"])) {
		return;
	}
	console.log("*!*!*! WHAT 2?");

	var eventData = e.data;

	const firstLevelItemType = getFirstLevelItemType(state.get(["tree", "root"]));

	const stateUpdates = [];

	let hasUpdates = false;
	eventData.transaction.forEach(transaction => {
		if (transaction.path.length === 2 && transaction.path[0] === "appState" && transaction.path[1] === "stateUpdates") {
			return;
		}

		console.log("*!*!*! WHAT 3?");
		console.log('*!*!*! Transaction details:', transaction);


		if (transaction.hasOwnProperty("path") && transaction.path.length >= 0 && transaction.path[0] === "drive") {
			// console.log('*!*!*! Transaction details:', transaction);

			console.log('*!*!*! stateUpdate DRIVE ' + transaction.path + "; " + transaction.value + "; " + firstLevelItemType);

			if (transaction.path.length > 1) {
				hasUpdates = true;
				stateUpdates.push({
					path: transaction.path,
					value: transaction.value,
					transactionType: transaction.type,
					type: "drive"
				});
			}
		} else if (transaction.hasOwnProperty("path") && transaction.path.length >= 2 && transaction.path[0] === "tree" && transaction.path[1] === "root" && !transaction.path.includes("driveRootPath")) {
			// console.log('*!*!*! Transaction details:', transaction);

			console.log('*!*!*! stateUpdate ' + transaction.path + "; " + transaction.value + "; " + firstLevelItemType);

			if (transaction.path.length > 1) {
				hasUpdates = true;
				stateUpdates.push({
					path: transaction.path,
					value: transaction.value,
					transactionType: transaction.type,
					type: "entities",
					firstLevelItemType: firstLevelItemType
				});
			}
		}
	});

	if (hasUpdates) {
		state.set(["appState", "stateUpdates"], stateUpdates);
		state.commit();
	}
});

export default state;
import React, { PureComponent } from "react";
import PropTypes from "baobab-react/prop-types";
import { branch } from "baobab-react/higher-order";
import Modal from "react-responsive-modal";
import autoBind from "react-autobind";
import { Form, FormControl, FormGroup, ControlLabel, Col, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Drive from "./drive";
import { AmbifiLoader } from "../shared/";
//uploadFile, showSaveModal, 
import { hideSaveModal } from "../../actions/drive";

class SaveModal extends PureComponent {


	constructor(props) {
		super(props);
		this.state = {
			saveButtonActive: false,
			savePath: null,
			saveFileName: null
		};
		autoBind(this);
	}

	handleFolderSelect(e) {
		console.log("Folder selected", e);
		this.setState({
			savePath: e.path
		});
	}

	handleFileSelect(e) {
		console.log("File selected", e);
	}

	handleChangeName(e) {
		const saveFileName = e.target.value;

		this.setState({
			saveFileName
		});
	}

	handleCloseModal() {
		hideSaveModal();
	}

	handleSave() {
		// uploadFile()
	}

	render() {
		//content, contentType, path, fileName
		const { show, showSpinner, extension } = this.props.saveModal;

		return (
			<Modal
				open={show}
				onClose={this.handleCloseModal} classNames={{ modal: "custom-modal" }} closeButton>
				<Col sm={12}>
					<h3>Save</h3>
				</Col>
				<Form style={{ marginTop: "10px" }}>
					<FormGroup controlId="artifact-name">
						<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left" }}>
							Location
						</Col>
						<Col sm={12}>
							<Drive inline hideActionBar modal folderOnly onFolderSelected={this.handleFolderSelect} onFileSelected={this.handleFileSelect} />
						</Col>
						<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left" }}>
							File Name
						</Col>
						<Col sm={12}>
							<InputGroup>
								<FormControl onChange={this.handleChangeName} type="text" />
								{extension ?
									<InputGroup.Addon>
										{"." + extension}
									</InputGroup.Addon> : ""}
							</InputGroup>
						</Col>
					</FormGroup>
				</Form>
				<Col sm={6} style={{ marginTop: "15px" }}>
					<Button bsStyle="danger" onClick={() => this.handleSave()}><FontAwesomeIcon icon="ban" /> Cancel </Button>
				</Col>
				<Col sm={6} style={{ marginTop: "15px", textAlign: "right" }}>
					<Button onClick={() => this.handleSave()}><FontAwesomeIcon icon={["far", "save"]} /> Save </Button>
				</Col>
				<AmbifiLoader show={showSpinner} modal />
			</Modal>
		);
	}
}

SaveModal.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	saveModal: ["drive", "modals", "saveModal"]
}, SaveModal);
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

import state from "../state/state";
import { handleChangeProperty, getProperty } from "./actions";
import { RESOURCE_TYPE_DRIVE, getContent, isDriveReference, isResourceEntry, getResourceReference, getDriveObject, RESOURCE_REFERENCE } from "./drive";
import { addExternalResource, getExternalResources, getExternalResource, removeExternalResource } from "./activityActions";

const DATASOURCE_REF_TYPE = "datasource-ref";
export const DATASOURCE_TYPE = "datasource";
export const TYPE_AMBIFI_DATASOURCE = "ambifi/datasource";

export function addDatasource(name, info, description = null) {
	// Used to be in activity > dataSources but now normalized 
	addExternalResource(info, {
		name,
		description,
		subType: DATASOURCE_TYPE
	});
}

export function getDataSources() {
	console.log("Attempting to get data sources", getExternalResources());
	return _.filter(getExternalResources(), (obj) => {
		return isDatasource(obj);
	});
}

/**
 * 
 * @param {*} ids find references for certain ids
 */
export function findDatasourceReferences(ids = null) {
	let targetIds = ids;
	if (ids && !_.isArray(ids)) {
		targetIds = [ids];
	}
	const refs = [];
	_findDatasourceReferences(getProperty(state, "children"), refs, targetIds);
	console.log("Data source references found:", refs);
	return refs;
}

export function createReferenceToDatasource(ds) {
	return {
		id: uuidv4(),
		type: RESOURCE_REFERENCE,
		dsType: ds.type,
		refId: ds.id
	};
}

function _findDatasourceReferences(obj, refs = [], ids = null) {
	if (_.isPlainObject(obj)) {
		if (isDatasourceRef(obj) && (!ids || (ids && ids.contains(obj.id)))) {
			// Push the ref to the results if its id is either part of the requested ids -OR- no specific ids have been requested (will return all)
			refs.push(obj);
		} else {
			_.keys(obj).forEach(key => {
				_findDatasourceReferences(obj[key], ids, refs);
			});
		}
	} else if (_.isArray(obj)) {
		obj.forEach(entry => {
			_findDatasourceReferences(entry, ids, refs);
		});
	}
}

export function isDatasourceRef(obj) {
	return isDriveReference(obj) && obj.metaData.subType === DATASOURCE_REF_TYPE;
}

export function isDatasource(obj) {
	return isResourceEntry(obj) && obj.metaData && (obj.metaData.subType === DATASOURCE_TYPE || obj.type === TYPE_AMBIFI_DATASOURCE);
}

export function deleteDatasource(id) {
	removeExternalResource(id);
}


export function toggleCommonKey(collectionKey, commonKey, remove, forceSingleValue = false, targetProp = "pickerItemsDatasource") {
	const pickerItemsDatasource = getProperty(state, targetProp);
	if (forceSingleValue) {
		pickerItemsDatasource[collectionKey] = _.isEmpty(commonKey) ? [] : [commonKey];
		handleChangeProperty(state, targetProp, _.cloneDeep(pickerItemsDatasource));
	} else {
		if (remove) {
			_.remove(pickerItemsDatasource[collectionKey], (n) => {
				return n === commonKey;
			});
			handleChangeProperty(state, targetProp, _.cloneDeep(pickerItemsDatasource));
		} else if (pickerItemsDatasource[collectionKey].indexOf(commonKey) === -1) {
			pickerItemsDatasource[collectionKey].push(commonKey);
			handleChangeProperty(state, targetProp, _.cloneDeep(pickerItemsDatasource));
		}
	}

}

export function addDatasourceRef(id, targetProp = "pickerItemsDatasource") {
	return new Promise((resolve, reject) => {
		console.log("Adding data source reference for ", id);
		const selectedDatasource = getResourceReference(id, null, true);

		if (selectedDatasource) {
			console.log("Add Ref for ds", selectedDatasource);
			const externalResource = getExternalResource(id);
			if (!externalResource) {
				reject(new Error(`Unable to find external resource with id ${id}`));
			} else {
				getContent(externalResource.guid).then(jsonDatasource => {
					console.log("Got datasource", jsonDatasource);
					handleChangeProperty(state, targetProp, _.assign({}, createReferenceToDatasource(externalResource), {
						commonKeys: extractCommonKeys(jsonDatasource),
						allKeys: extractAllKeys(jsonDatasource),
						labelKeys: [],
						valueKeys: [],
						styleKeys: [],
						associatedContentKeys: [],
						content: jsonDatasource
					}));
				}).catch(err => {
					reject(err);
				});
			}

		}
	});
}

let dsCache = {};
export function clearCache() {
	dsCache = {};
}

export function resolveDatasources(activity) {
	return new Promise((resolve, reject) => {

		if (activity && activity.dataSources && !_.isEmpty(activity.dataSources)) {
			const promises = [];
			activity.dataSources.forEach(ds => {
				if (ds.info && ds.info.type === RESOURCE_TYPE_DRIVE) {
					const relativePath = ds.info.path;
					promises.push(new Promise((res, rej) => {
						if (dsCache[relativePath]) {
							res(dsCache[relativePath]);
						} else {
							getContent(ds.id).then(content => {
								dsCache[relativePath] = content;
								const _ds = _.cloneDeep(ds);
								_ds.content = content;
								res(_ds);
							}).catch(err => {
								rej(err);
							});
						}
					}));
				}
			});

			Promise.all(promises).then(res => {
				activity.dataSources = res;
				resolve(activity);
			}).catch(err => {
				reject(err);
			});
		} else {
			resolve(activity);
		}
	});

}

export function getDatasourceContent(dsType) {
	return new Promise((resolve, reject) => {
		switch (dsType) {
			case RESOURCE_TYPE_DRIVE: {

				break;
			}
			default: {
				reject(new Error("Unknown datasource type " + dsType));
			}
		}
	});
}

export function attachDatasource(jsonDatasource) {

	const validationResult = validateJsonDatasource(jsonDatasource);

	if (validationResult.valid) {
		const pickerItemsDatasource = {
			type: "internal",
			contentType: "application/json",
			commonKeys: extractCommonKeys(jsonDatasource),
			allKeys: extractAllKeys(jsonDatasource),
			labelKeys: [],
			valueKeys: [],
			content: jsonDatasource
		};
		console.log(pickerItemsDatasource);
		handleChangeProperty(state, "_validation_pickerItemsDatasource", null);
		handleChangeProperty(state, "pickerItemsDatasource", pickerItemsDatasource);
	} else {
		handleChangeProperty(state, "pickerItemsDatasource", null);
		handleChangeProperty(state, "_validation_pickerItemsDatasource", validationResult);
	}
}


export function attachRankingDatasource(jsonDatasource) {

	const validationResult = validateJsonDatasource(jsonDatasource);

	if (validationResult.valid) {
		const itemsDatasource = {
			type: "internal",
			contentType: "application/json",
			commonKeys: extractCommonKeys(jsonDatasource),
			allKeys: extractAllKeys(jsonDatasource),
			labelKeys: [],
			valueKeys: [],
			styleKeys: [],
			associatedContentKeys: [],
			content: jsonDatasource
		};
		console.log(itemsDatasource);
		handleChangeProperty(state, "_validation_itemsDatasource", null);
		handleChangeProperty(state, "itemsDatasource", itemsDatasource);
	} else {
		handleChangeProperty(state, "itemsDatasource", null);
		handleChangeProperty(state, "_validation_itemsDatasource", validationResult);
	}
}

function extractAllKeys(jsonDatasource) {
	let keyMap = {};

	if (_.isArray(jsonDatasource)) {
		_.forEach(jsonDatasource, entry => {
			if (_.isPlainObject(entry)) {
				_.keys(entry).forEach(key => {
					keyMap[key] = true;
				});
			}
		});
	} else if (_.isPlainObject(jsonDatasource)) {
		_.keys(jsonDatasource).forEach(key => {
			const entry = jsonDatasource[key];
			if (_.isPlainObject(entry)) {
				keyMap[key] = true;
			}
		});
	}

	return _.keys(keyMap);
}

function extractCommonKeys(jsonDatasource) {
	let commonKeys = [];
	let keyMap = {};
	let availableEntries = 0;

	if (_.isArray(jsonDatasource)) {

		_.forEach(jsonDatasource, entry => {
			if (_.isPlainObject(entry)) {
				availableEntries++;
				_.keys(entry).forEach(key => {
					if (!keyMap.hasOwnProperty(key)) {
						keyMap[key] = 0;
					}
					keyMap[key]++;
				});
			}
		});
	} else if (_.isPlainObject(jsonDatasource)) {
		_.keys(jsonDatasource).forEach(key => {
			const entry = jsonDatasource[key];
			if (_.isPlainObject(entry)) {
				availableEntries++;
				if (!keyMap.hasOwnProperty(key)) {
					keyMap[key] = 0;
				}
				keyMap[key]++;
			}
		});
	}

	_.keys(keyMap).forEach(key => {
		if (keyMap[key] === availableEntries) {
			// Means it appears in all of them
			commonKeys.push(key);
		}
	});

	return commonKeys;
}

function validateJsonDatasource(jsonDatasource) {
	const result = {
		valid: true,
		issues: []
	};
	let nonObjectsFound = 0;
	if (_.isArray(jsonDatasource)) {

		_.forEach(jsonDatasource, entry => {
			if (!_.isPlainObject(entry)) {
				nonObjectsFound++;
			}
		});
	} else if (_.isPlainObject(jsonDatasource)) {
		_.keys(jsonDatasource).forEach(key => {
			const entry = jsonDatasource[key];
			if (!_.isPlainObject(entry)) {
				nonObjectsFound++;
			}
		});
	} else {
		result.valid = false;
		result.issues.push("Expecting JSON structure to be an Object or Array");
	}

	if (nonObjectsFound > 0) {
		result.valid = false;
		result.issues.push("Expecting a collection or properties of type Object, found " + nonObjectsFound + " non objects in the provided structure.");
	}



	return result;
}

export function getDatasourceIndexForRef(refId) {
	let result = null;
	const currentActivity = state.get(["currentChecklist"]);
	if (currentActivity && _.isArray(currentActivity.externalResources)) {
		currentActivity.externalResources.forEach((externalResource, idx) => {
			if (isDatasource(externalResource)) {
				if (externalResource.id === refId) {
					result = idx;
				}
			}
		});
	}
	return result;
}

export function getDatasourceStateTreePathForRefId(refId) {
	let result = null;
	if (refId && !_.isEmpty(refId)) {
		const idx = getDatasourceIndexForRef(refId);
		if (_.isNumber(idx)) {
			result = ["currentChecklist", "externalResources", idx];
		}
	}
	return result;
}

export function retrieveDatasourceForRef(dsRef) {
	console.log("Retrieving data source for ref", dsRef);
	const ds = getDatasourceForRef(dsRef.refId);
	const path = getDatasourceStateTreePathForRefId(dsRef.refId);
	console.log("Got path for data source", path, ds);
	if (path) {
		state.set(_.concat(path, ["loading"]), true);
		getResourceContentForDatasource(null, ds, true).then(content => {
			console.log("GOT DATASOURCE", content);
			state.set(_.concat(path, ["loading"]), false);
			state.set(_.concat(path, ["content"]), content);
		}).catch(err => {
			state.set(_.concat(path, ["loadingError"]), "Failed to load datasource. " + err);
			state.set(_.concat(path, ["loading"]), false);
		});
	} else {
		console.error("Unable to find path in state tree for data source reference", dsRef);
	}

}


export function getDatasourceForRef(refId, activity = null) {
	let result = null;
	const currentActivity = activity ? activity : state.get(["currentChecklist"]);
	console.log("Current activity", currentActivity);
	if (currentActivity && _.isArray(currentActivity.externalResources)) {
		currentActivity.externalResources.forEach(externalResource => {
			if (isDatasource(externalResource)) {
				if (externalResource.id === refId) {
					result = externalResource;
					result.info = getDriveObject(externalResource.guid);
				}
			}
		});
	}

	return result;
}

export function getResourceContentForDatasource(activity, ds, useCurrentActivity = false) {
	return new Promise((resolve, reject) => {
		if (!activity && useCurrentActivity) {
			activity = state.get(["currentChecklist"]);
		}
		const relativePath = ds.info ? ds.info.path : ds.path;
		// console.log("Cache path", relativePath, ds);
		getContent(ds.guid).then(content => {
			if (dsCache[relativePath]) {
				resolve(dsCache[relativePath]);
			} else {
				dsCache[relativePath] = content;
				resolve(content);
			}
		}).catch(err => {
			reject(err);
		});
	});
}
import React, { PureComponent } from "react";
import { Grid, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class InlinePanel extends PureComponent {
	render() {
		const { children, title, icon, leftBorder = true, topBorder, style } = this.props;


		let header = <div />;
		if (title && icon) {
			header = <h4><FontAwesomeIcon icon={icon} />&nbsp;&nbsp;{title}</h4>;
		} else if (title) {
			header = <h4>{title}</h4>;
		}

		let classNames = "inline-panel";
		if (leftBorder) {
			classNames += " leftBorder";
		}
		if (topBorder) {
			classNames += " topBorder";
		}

		const additionalProps = {};

		if (style) {
			additionalProps.style = style;
		}

		return (
			<div className={classNames} {...additionalProps}>
				{header}
				<Grid fluid>
					<Col>
						{/* <MembersList members={getMembers()} simple selectable onSelect={this.handleMemberSelect}/> */}

						{children}
					</Col>
				</Grid>
			</div>
		);
	}
}

export default InlinePanel;

import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import { Button } from "react-bootstrap";

import List from "react-list-select";

// import mockData from "../../data/json/utteranceFindReplace.json";
import mockData from "../../data/json/ttsPhonetics.json";
import devicePlatforms from "../../data/json/devicePlatforms.json";
import deviceManufacturers from "../../data/json/deviceManufacturers.json";
import deviceModels from "../../data/json/deviceModels.json";
import deviceVoices from "../../data/json/deviceVoices.json";

import ModalFilters from "./modalFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import _ from "lodash";

class PhoneticsManager extends React.PureComponent {

	constructor(props) {
		super(props);

		this.platforms = {};
		for (let devicePlatform of devicePlatforms) {
			this.platforms[devicePlatform.value] = devicePlatform.label;
		}
		this.manufacturers = {};
		for (let deviceManufacturer of deviceManufacturers) {
			this.manufacturers[deviceManufacturer.value] = deviceManufacturer.label;
		}
		this.models = {};
		for (let deviceModel of deviceModels) {
			this.models[deviceModel.value] = deviceModel.label;
		}
		this.voices = {};
		for (let deviceVoice of deviceVoices) {
			this.voices[deviceVoice.value] = deviceVoice.label;
		}

		const { content, onChange } = props;
		let editContent = content;

		if (!content || _.isEmpty(content)) {
			editContent = mockData; // Change this to DEFAULT data whene there is none yet (new tts-phonetics)
		}

		if (_.isString(editContent)) {
			editContent = JSON.parse(editContent);
		}

		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}

		const replaceContentItems = editContent.replaceContentItems;

		this.state = {
			editContent,
			showFiltersModal: false,
			filtersModalModelIndex: -1,
			filtersModalModel: null,
			selectedIndex: null,
			replaceContent: "",
			withContent: "",
			itemValues: replaceContentItems
		};
	}


	comp(item, index) {
		let filtersComponent = [];
		let hasFilters = false;

		if (item.hasOwnProperty("filters")) {
			for (let filter of item.filters) {
				let withComponent, platformsComponent, manufacturersComponent, modelsComponent, voicesComponent;

				console.log("FILTER: " + JSON.stringify(filter));
				withComponent = <div>{filter.withContent}</div>;

				if (filter.platforms.length > 0) {
					hasFilters = true;
					let platformItems = filter.platforms.map((platform) => {
						return this.platforms[platform];
					});
					const platformItemsString = platformItems.join(", ");

					platformsComponent =
						<div>
							<i>Platforms - ({platformItemsString})</i>
						</div>;
				}

				if (filter.manufacturers.length > 0) {
					hasFilters = true;
					const manufacturerItems = filter.manufacturers.map((manufacturer) => {
						return this.manufacturers[manufacturer];
					});

					const manufacturerItemsString = manufacturerItems.join(", ");

					manufacturersComponent =
						<div>
							<i>Manufacturers - ({manufacturerItemsString})</i>
						</div>;
				}

				if (filter.models.length > 0) {
					hasFilters = true;
					const modelItems = filter.models.map((model) => {
						return this.models[model];
					});

					const modelItemsString = modelItems.join(", ");

					modelsComponent =
						<div>
							<i>Models - ({modelItemsString})</i>
						</div>;
				}

				if (filter.voices.length > 0) {
					hasFilters = true;
					const voiceItems = filter.voices.map((voice) => {
						return this.voices[voice];
					});

					const voiceItemsString = voiceItems.join(", ");

					voicesComponent =
						<div>
							<i>Voices - ({voiceItemsString})</i>
						</div>;
				}

				filtersComponent.push(
					<div style={{ flexDirection: "column" }}>
						{withComponent}
						<div style={{ marginLeft: "5px" }}>
							{platformsComponent}
							{manufacturersComponent}
							{modelsComponent}
							{voicesComponent}
						</div>
					</div>
				);
			}
		}

		return (
			<div style={{ borderBottomStyle: "solid", borderBottomColor: "#cccccc", borderBottomWidth: "1px", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
				<div style={{ width: "50%", padding: "5px" }}>{item.replaceContent}</div>
				<div style={{ width: "50%", padding: "5px" }}>
					<div style={{ display: "flex" }}>
						{!hasFilters &&
							<div style={{ flex: 1 }}>{item.withContent}</div>
						}
						{hasFilters &&
							<div style={{ flex: 1 }}>
								<div style={{ marginTop: "5px" }}><b>Default</b></div>
								<div>{item.withContent}</div>
								<div>
									<div style={{ marginTop: "5px" }}><b>Filters</b></div>
									{filtersComponent}
								</div>
							</div>
						}
						<div style={{ flex: 0 }}>
							<Button onClick={() => { this.handleChangeState({ showFiltersModal: true, filtersModalModelIndex: index, filtersModalModel: this.state.itemValues[index] }); }}><FontAwesomeIcon fixedWidth={true} style={{ color: "black", fontSize: "16px" }} icon="ellipsis-v" /></Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	sort() {
		let newItemValues = this.state.itemValues.slice(0);

		newItemValues.sort(function (a, b) {
			return a.replaceContent.toLowerCase().localeCompare(b.replaceContent.toLowerCase());
		});

		this.handleChangeState({ itemValues: newItemValues, replaceContent: "", withContent: "", selectedIndex: null });
	}

	findItem(arr, value) {
		let foundIndex = -1;
		let foundObj = null;

		for (let i = 0; i < arr.length; i++) {
			const obj = arr[i];

			if (obj.replaceContent === value) {
				foundIndex = i;
				foundObj = obj;
			}
		}

		if (foundIndex > -1) {
			return {
				index: foundIndex,
				obj: foundObj
			};
		} else {
			return null;
		}
	}

	handleChangeState(newState) {
		this.setState(newState);
		const { onChange } = this.props;
		if (newState.hasOwnProperty("itemValues") && onChange && _.isFunction(onChange)) {

			const { editContent } = this.state;
			editContent.replaceContentItems = newState.itemValues;

			editContent.ttsPhoneticsCompiled = this.compileItemValues(newState.itemValues);

			onChange(editContent);
		}
	}

	compileItemValues(itemValues) {
		console.log("Item Values: " + JSON.stringify(itemValues));

		let retObj = {
			system: {},
			platforms: {},
			manufacturers: {},
			models: {},
			voices: {}
		};

		for (let itemValue of itemValues) {
			retObj.system[itemValue.replaceContent] = itemValue.withContent;

			if (itemValue.hasOwnProperty("filters")) {
				for (let filter of itemValue.filters) {
					for (let platform of filter.platforms) {
						if (!retObj.platforms.hasOwnProperty(platform)) {
							retObj.platforms[platform] = {};
						}

						retObj.platforms[platform][itemValue.replaceContent] = filter.withContent;
					}
					for (let manufacturer of filter.manufacturers) {
						if (!retObj.manufacturers.hasOwnProperty(manufacturer)) {
							retObj.manufacturers[manufacturer] = {};
						}

						retObj.manufacturers[manufacturer][itemValue.replaceContent] = filter.withContent;
					}
					for (let model of filter.models) {
						if (!retObj.models.hasOwnProperty(model)) {
							retObj.models[model] = {};
						}

						retObj.models[model][itemValue.replaceContent] = filter.withContent;
					}
					for (let voice of filter.voices) {
						if (!retObj.voices.hasOwnProperty(voice)) {
							retObj.voices[voice] = {};
						}

						retObj.voices[voice][itemValue.replaceContent] = filter.withContent;
					}
				}
			}
		}

		return retObj;
	}

	render() {
		let self = this;

		const items = this.state.itemValues.map((item, index) => this.comp(item, index));

		console.log(JSON.stringify(this.state, null, 3));

		return (
			<div className="tts-phonetics-manager">
				<div>
					<label>Replace</label>
				</div>
				<div>
					<input ref="txtReplace" style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Text to replace)" value={this.state.replaceContent} onChange={(e) => {
						const value = e.target.value;

						const item = this.findItem(this.state.itemValues, value);
						if (item !== null) {
							// const newItemValues = this.state.itemValues.slice(0);

							// newItemValues[obj.index] = {
							// 	replaceContent: obj.replaceContent,
							// 	withContent: obj.withContent
							// };

							// console.log(obj.replaceContent + " " + obj.withContent + " " + obj.index);

							this.handleChangeState({ replaceContent: item.obj.replaceContent, withContent: item.obj.withContent, selectedIndex: item.index });
						} else {
							this.handleChangeState({ replaceContent: value, selectedIndex: null });
						}
					}} />
				</div>
				<div>
					<label>With</label>
				</div>
				<div>
					<input style={{ marginBottom: "15px" }} className="form-control" type="text" placeholder="(Text to replace with)" value={this.state.withContent} onChange={(e) => {
						this.handleChangeState({ withContent: e.target.value });
					}} />
				</div>
				<div class="replacements-list" ref={ref => { this.listRef = ref; }}>
					<List
						selected={[this.state.selectedIndex]}
						items={items}
						onChange={(selected) => {
							this.handleChangeState({
								selectedIndex: selected,
								replaceContent: this.state.itemValues[selected].replaceContent,
								withContent: this.state.itemValues[selected].withContent
							});
							console.log(selected);
						}}
					/>
				</div>
				< div style={{ display: "flex" }}>
					{this.state.selectedIndex !== null &&
						<Button style={{ width: "100px" }} bsStyle="primary" onClick={() => {
							const newItemValues = this.state.itemValues.slice(0);

							newItemValues[this.state.selectedIndex] = {
								replaceContent: this.state.replaceContent,
								withContent: this.state.withContent
							};
							this.handleChangeState({ itemValues: newItemValues });
						}}>
							Replace
						</Button>
					}
					{this.state.selectedIndex === null &&
						<Button style={{ width: "100px" }} bsStyle="success" disabled={this.state.replaceContent === ""} onClick={() => {
							const newItemValues = this.state.itemValues.slice(0);

							newItemValues.push({
								replaceContent: this.state.replaceContent,
								withContent: this.state.withContent
							});

							this.handleChangeState({
								selectedIndex: this.state.itemValues.length,
								itemValues: newItemValues
							});
							setTimeout(function () {
								self.listRef.scrollTop = self.listRef.scrollHeight;
							}, 0);

						}}>
							Add
						</Button>
					}

					<div style={{ width: "10px" }} />

					<Button disabled={this.state.selectedIndex === null} onClick={() => {
						const newItemValues = this.state.itemValues.slice(0);

						newItemValues.splice(this.state.selectedIndex, 1);

						this.handleChangeState({ selectedIndex: null, itemValues: newItemValues });
					}}>
						Delete
					</Button>

					<div style={{ width: "25px" }} />

					<Button onClick={() => {
						this.sort();
					}}>
						Sort
					</Button>
				</div>
				{this.state.showFiltersModal &&
					<ModalFilters modelIndex={this.state.filtersModalModelIndex} model={_.cloneDeep(this.state.filtersModalModel)} open={this.state.showFiltersModal} onClose={() => {
						this.handleChangeState({ showFiltersModal: false });
					}} onSave={(index, model) => {
						const newItemValues = this.state.itemValues.slice(0);
						newItemValues[index] = model;

						this.handleChangeState({
							showFiltersModal: false, filtersModalModelIndex: -1, filtersModalModel: null, itemValues: newItemValues
						});
					}} />
				}
			</div>
		);
	}
}

PhoneticsManager.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
}, PhoneticsManager);
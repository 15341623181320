import React, { Suspense, lazy, PureComponent } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { branch, root } from "baobab-react/higher-order";
import ReactGA from "react-ga";
import autoBind from "react-autobind";
// import Amplify from "aws-amplify";

import SecurityEmbed from "./components/securityEmbed/index";
import { isBrowserSupported, isMobile } from "./utils/utils";
import { AmbifiLoader } from "./components/shared";
import browserHistory from "./history";
import state from "./state/state";
// import awsconfig from './constants/aws-exports';
// Amplify.configure(awsconfig);

// Stream Chat and Dolby initialization
import VoxeetSdk from "@voxeet/voxeet-web-sdk";
import VideoCallContainer from "./components/videoCallContainer";
import { StreamChat } from 'stream-chat';
import Alerts from "./components/app-alerts.js";

// Global css required by 3rd party components
import "react-datetime/css/react-datetime.css";
import { sendHideModalEditorMessage, sendRestoreSession } from "./actions/mobileAppCommunicationActions.js";

const chatClient = StreamChat.getInstance("bymefp455ef2");
// chatClient.setBaseURL('https://chat.stream-io-api.com');

ReactGA.initialize("UA-100193329-2");

function logPageView() {
	this.setState({ askBeforeNav: window.location.href.indexOf("/editor/") });
	ReactGA.set({ page: window.location.pathname + window.location.search });
	ReactGA.pageview(window.location.pathname + window.location.search);
}


const RegistrationFlow = lazy(() => import("./components/portal/app-registration-flow"));
const Search = lazy(() => import("./components/app-search"));
const Chat = lazy(() => import("./components/chat"));
const DrivePage = lazy(() => import("./components/drive/page"));
const SearchHistory = lazy(() => import("./components/app-search-history"));
const Dashboard = lazy(() => import("./components/dashboard"));
const Documents = lazy(() => import("./components/app-documents"));
const ReceiveChecklist = lazy(() => import("./components/app-receive-checklist"));
const History = lazy(() => import("./components/app-history"));
const TimerTemplates = lazy(() => import("./components/timer/app-timer-templates"));
const Import = lazy(() => import("./components/app-import"));
const Org = lazy(() => import("./components/organizations"));
const Main = lazy(() => import("./components/app-main"));
const UserProfile = lazy(() => import("./components/userProfile"));
const Reporting = lazy(() => import("./components/reporting"));
const ContentManagement = lazy(() => import("./components/contentManagement"));
const Assignments = lazy(() => import("./components/assignments"));
const PlayerHome = lazy(() => import("./components/player/home"));
const Player = lazy(() => import("./components/player/player"));
const Publications = lazy(() => import("./components/publications"));
// User management screens
const PortalHome = lazy(() => import("./components/portal/app-home"));
const Verify = lazy(() => import("./components/portal/app-verify-user"));
const Devices = lazy(() => import("./components/portal/app-devices"));
const DeleteUser = lazy(() => import("./components/portal/app-delete-user"));
const Login = lazy(() => import("./components/portal/app-login"));
const LoggedIn = lazy(() => import("./components/portal/app-logged-in"));
const ForgotPassword = lazy(() => import("./components/portal/app-forgot-password"));
const Subscribe = lazy(() => import("./components/portal/app-subscribe"));
const PurchaseCustom = lazy(() => import("./components/portal/app-purchase-custom"));
const UpdateCustomerCard = lazy(() => import("./components/portal/app-update-customer-card"));
const MagicLinkHandler = lazy(() => import("./components/portal/app-magic-link"));

let RootedPortal = RegistrationFlow;

if (isMobile() && !window.location.href.includes("code=")) {
	RootedPortal = Login;
}
const RootedSearch = Search;
const RootedChat = Chat;
const RootedSearchHistory = SearchHistory;
const RootedDashboard = Dashboard;
const RootedPortalHome = PortalHome;
const RootedVerify = Verify;
const RootedDevices = Devices;
const RootedDeleteUser = DeleteUser;
const RootedLogin = Login;
const RootedLoggedIn = LoggedIn;
const RootedForgotPassword = ForgotPassword;
const RootedSubscribe = Subscribe;
const RootedPurchaseCustom = PurchaseCustom;
const RootedUpdateCustomerCard = UpdateCustomerCard;
const RootedMain = Main;
const RootedDocuments = Documents;
const RootedReceiveChecklist = ReceiveChecklist;
const RootedHistory = History;
const RootedTimerTemplates = TimerTemplates;
const RootedImport = Import;
const RootedOrg = Org;
const RootedUserProfile = UserProfile;
const RootedReporting = Reporting;
const RootedContentManagement = ContentManagement;
const RootedDrive = DrivePage;
const RootedPlayerHome = PlayerHome;
const RootedPlayer = Player;
const RootedAssignments = Assignments;
const RootedPublications = Publications;

class AmbiFiMainApp extends PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	async componentWillUnmount() {
		try {
			await VoxeetSdk.session.close();
			await chatClient.disconnectUser();
		} catch (err) {
			console.log("Error", err);
		}
	}

	render() {
		var isSupported = isBrowserSupported();

		if (!isSupported) {
			return (
				<div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "25px" }}>
					<div style={{ color: "white", maxWidth: "800px" }}>
						<div>
							<h1>Sorry, IE 11 is not supported :(</h1>
							<p>
								IE 11 was released in 2013 and Microsoft has announced its end of life. AmbiFi runs on all modern browsers from Chrome to Firefox, to Microsoft Edge, to Apple Safari.
							</p>
						</div>
					</div>
				</div>

			)
		}


		// if (isMobile()) {
		// 	if (this.props.appInitialized || this.props.needsSessionRestore) {

		// 	} else {
		// 		sendHideModalEditorMessage();
		// 	}
		// }

		console.log("RENDER APP");
		return this.props.appInitialized || this.props.needsSessionRestore ? (
			<Router history={browserHistory} onUpdate={logPageView}>
				<Suspense fallback={<AmbifiLoader fullPage show={true} />}>
					<Switch>
						<Route key="route-root" exact path="/" component={RootedPortal} />
						<Route key="route-index" path="/index.html" component={RootedPortal} />
						<Route key="route-home" path="/home" component={RootedPlayerHome} />
						<Route key="route-search" path="/search" component={RootedSearch} />
						<Route key="route-chat" path="/chat" component={RootedChat} />
						<Route key="route-portal-home" path="/portalHome" component={RootedPortalHome} />
						<Route key="route-verify" path="/verify" component={RootedVerify} />
						<Route key="route-devices" path="/devices" component={RootedDevices} />
						<Route key="route-delete-user" path="/deleteUser" component={RootedDeleteUser} />
						<Route key="route-checklists" path="/checklists" component={RootedDocuments} />
						<Route key="route-receive-checklist" path="/receiveChecklist/:shareId" component={RootedReceiveChecklist} />
						<Route key="route-profile" path="/profile" component={RootedUserProfile} />
						<Route key="route-login" path="/login/create-magic-link" component={MagicLinkHandler} />
						<Route key="route-login" path="/login/authenticate-magic-link" component={() => <MagicLinkHandler authenticate />} />
						<Route key="route-login" path="/login" component={RootedLogin} />
						<Route key="route-auth" path="/auth" component={RootedLogin} />
						<Route key="route-logged-in" path="/loggedIn" component={RootedLoggedIn} />
						<Route key="route-forgot-password" path="/forgotPassword" component={RootedForgotPassword} />
						<Route key="route-subscribe" path="/subscribe" component={RootedSubscribe} />
						<Route key="route-purchase-custom" path="/purchaseCustom" component={RootedPurchaseCustom} />
						<Route key="route-update-customer-card" path="/updateCustomerCard" component={RootedUpdateCustomerCard} />
						<Route key="route-search-history" path="/searchHistory" component={RootedSearchHistory} />
						<Route key="route-dashboard" path="/dashboard" component={RootedDashboard} />
						<Route key="route-history" path="/history" component={RootedHistory} />
						<Route key="route-timers" path="/timers" component={RootedTimerTemplates} />
						<Route key="route-import" path="/import" component={RootedImport} />
						<Route key="route-editor" path="/editor/:checklistId" component={RootedMain} />
						<Route key="route-organization" path="/organization" component={RootedOrg} />
						<Route key="route-reporting" path="/reporting" component={RootedReporting} />
						<Route key="route-cms" path="/cms" component={RootedContentManagement} />
						<Route key="route-drive" path="/drive" component={RootedDrive} />
						<Route key="route-player-activity-instance" path="/activity/:activityId/instance/:instanceId" component={RootedPlayer} />
						<Route key="route-activity" path="/activity/:activityId" component={RootedPlayer} />
						<Route key="route-player" path="/player/:activityId/:instanceId" component={RootedPlayer} />
						<Route key="route-player-activity" path="/player/:activityId" component={RootedPlayer} />
						<Route key="route-assignments" path="/assignments" component={RootedAssignments} />
						<Route key="route-publications" path="/publications" component={RootedPublications} />
						<Route key="route-security-context" path="/security-context" component={SecurityEmbed} />
					</Switch>
					{this.props.videoCall.visible &&
						<Route key="route-video-call" path="/" children={<VideoCallContainer />} />
					}
				</Suspense>
			</Router>
		) : <>
			<Alerts />
			<AmbifiLoader message={(this.props.loadingMessage && this.props.loadingMessage !== "") ? this.props.loadingMessage : "Initializing app..."} fullPage show={true} showLoader={true} />
		</>;
	}

}

export default root(state, branch({
	appInitialized: ["appState", "initialized"],
	needsSessionRestore: ["appState", "needsSessionRestore"],
	videoCall: ["appState", "videoCall"],
	loadingMessage: ["appState", "loadingMessage"]
}, AmbiFiMainApp));
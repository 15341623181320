import React, { useRef, useState, useEffect } from 'react';

import { validateUrl, getImageSize } from "../../utils/utils";

const ImageWithMarkup = (props) => {
	const svgRef = useRef();

	const [imageSize, setImageSize] = useState(null);

	const { url, imageHotspots } = props;

	useEffect(() => {
		async function asyncGetImageSize() {
			const imageSize = await getImageSize(url);

			setImageSize({
				width: imageSize.width,
				height: imageSize.height
			});
		}

		asyncGetImageSize();

		// Anything in here is fired on component unmount.
		return () => {
		}
	}, [url]);

	const renderComponent = () => {
		// If no url or an image and don't have size yet return
		if (imageSize === null || url == null) {
			return null;
		}

		let hotspots = null;
		if (imageHotspots && imageHotspots.length > 0) {
			hotspots = imageHotspots.map((hotspot, idx) => {
				return (
					<>
						{(!hotspot.hasOwnProperty("shape") || hotspot.shape.toLowerCase() === "rectangle") &&
							<rect
								style={{ transformOrigin: `${hotspot.coords.x + (hotspot.coords.width * 0.5)}px ${hotspot.coords.y + (hotspot.coords.height * 0.5)}px` }}
								transform={`rotate(${hotspot.rotation ? hotspot.rotation : 0})`}
								rx={hotspot.style.borderStyle && hotspot.style.borderRadius ? hotspot.style.borderRadius : 0}
								x={hotspot.coords.x}
								y={hotspot.coords.y}
								width={hotspot.coords.width}
								height={hotspot.coords.height}
								strokeWidth={hotspot.style.hasOwnProperty("borderWidth") ? hotspot.style.borderWidth : 5}
								stroke={hotspot.style.borderColor ? hotspot.style.borderColor : "darkorange"}
								fill={hotspot.style.borderFillColor ? hotspot.style.borderFillColor : "transparent"}
								pointerEvents="none"
							/>
						}
						{(hotspot.hasOwnProperty("shape") && hotspot.shape.toLowerCase() === "arrow") &&
							<polygon
								style={{ transformOrigin: `${hotspot.coords.x + (hotspot.coords.width * 0.5)}px ${hotspot.coords.y + (hotspot.coords.height * 0.5)}px` }}
								transform={`rotate(${hotspot.rotation ? hotspot.rotation : 0})`}
								points={`${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.33)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height * 0.33)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y},${hotspot.coords.x + (hotspot.coords.width)} ${hotspot.coords.y + (hotspot.coords.height * 0.5)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height)},${hotspot.coords.x + (hotspot.coords.width * 0.5)} ${hotspot.coords.y + (hotspot.coords.height * 0.67)},${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.67)},${hotspot.coords.x} ${hotspot.coords.y + (hotspot.coords.height * 0.33)}`}
								strokeWidth={hotspot.style.hasOwnProperty("borderWidth") ? hotspot.style.borderWidth : 5}
								stroke={hotspot.style.borderColor ? hotspot.style.borderColor : "darkorange"}
								fill={hotspot.style.borderFillColor ? hotspot.style.borderFillColor : "transparent"}
								pointerEvents="none"
							/>
						}
					</>
				)
			});
		}

		const result = url && validateUrl(url) ? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
			{/* <img alt="Preview" className="player-image" style={{ flex: 1, maxHeight: "50vh" }} src={url} /> */}
			<svg ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${imageSize.width} ${imageSize.height}`} width="100%">
				<image href={url} />
				{hotspots}
			</svg>
		</div> : null;

		return result;
	}

	return renderComponent();
}

export default ImageWithMarkup;
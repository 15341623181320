import AWS from "aws-sdk";
import _ from "lodash";

import state from "../state/state";

export function deleteObject(bucketName, key) {
	return new Promise((resolve, reject) => {
		console.log("Attempting to delete", bucketName, key);
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		bucket.deleteObject({ Key: key }, err => {
			if (err) {
				reject(err);
			} else {
				resolve();
			}
		});
	});

}
export function deleteObjects(bucketName, objects) {
	return new Promise((resolve, reject) => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		bucket.deleteObjects({ Delete: { Objects: objects } }, err => {
			if (err) {
				reject(err);
			} else {
				resolve();
			}
		});
	});

}

export function uploadObject(bucketName, path, content, contentType) {
	return new Promise((resolve, reject) => {
		// Instantiate aws sdk service objects now that the credentials have been updated.
		const _bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });

		const params = { Key: path };
		params.ContentType = contentType;
		params.Body = content;

		_bucket.upload(params, (err) => {
			if (err) {
				reject(err);
			} else {
				resolve();
			}
		});
	});
}


export function copyObject(sourceBucket, sourceKey, destinationBucket, destinationKey) {
	return new Promise((resolve, reject) => {
		let params = {
			CopySource: `/${sourceBucket}/${sourceKey}`,
			Key: destinationKey,
			ServerSideEncryption: "AES256"
		};
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: destinationBucket } });
		bucket.copyObject(params, function (err, data) {
			if (err) {
				reject(err);
			} else {
				resolve(data);
			}
		});
	});
}

export function moveObject(sourceBucket, sourceKey, destinationBucket, destinationKey) {
	return new Promise((resolve, reject) => {
		copyObject(sourceBucket, sourceKey, destinationBucket, destinationKey).then(() => {
			deleteObject(sourceBucket, sourceKey).then(() => {
				resolve();
			}).catch(err => {
				reject(err);
			});
		}).catch(err => {
			reject(err);
		});
	});
}


export function objectExists(bucketName, key) {
	return new Promise((resolve, reject) => {

		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
		bucket.headObject({ Key: key }, function (err) {
			if (err && err.code === "NotFound") {
				resolve(false);
			} else if (err) {
				reject(err);
			} else {
				resolve(true);
			}
		});

	});
}


export function getObject(bucketName, key, skipParse = false) {
	return new Promise((resolve, reject) => {
		const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
		bucket.getObject({ Key: key }, (err, data) => {
			if (err) {
				reject(err);
			} else {
				// console.log(data, data.Body.toString());
				if (skipParse) {
					resolve(data.Body);
				} else {
					try {
						resolve(JSON.parse(_.trim(data.Body.toString())));
					} catch (e) {
						console.error("Failed to parse object", e);
						reject(e);
					}
				}
			}
		});

	});
}

export function listAllObjects(bucketName, prefix, delimiter = null) {
	return new Promise((resolve, reject) => {
		let prefixes = prefix;
		if (!_.isArray(prefix)) {
			prefixes = [prefix];
		}
		const promises = [];
		prefixes.forEach(prefix => {
			promises.push(new Promise((res, rej) => {
				recursiveListObjects(bucketName, prefix, (err, data) => {
					if (err) {
						rej(err);
					} else {
						res(data);
					}
				}, null, [], delimiter);
			}));
		});

		Promise.all(promises).then(results => {
			console.log("Got results", results);
			let result = [];
			results.forEach(res => {
				result = _.concat(result, res);
			});
			resolve(result);
		}).catch(err => {
			reject(err);
		});
	});
}

function recursiveListObjects(bucketName, prefix = null, cb = () => { }, marker = null, files = [], delimiter = null) {
	const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
	const params = {
		Bucket: bucketName
	};

	// are we paging from a specific point?
	if (marker) {
		params.Marker = marker;
	}

	if (prefix) {
		params.Prefix = prefix;
	}

	if (delimiter) {
		params.Delimiter = delimiter;
	}

	// state.set(["appState", "loadingMessage"], `Loading drive files - ${files.length}`);

	// if (state.get(["appState", "navPanel", "selected"]) === "drive") {
	// 	state.set(["drive", "showSpinner", "progressMessage"], `Loading drive files - ${files.length}`);
	// }

	bucket.listObjects(params, (err, data) => {
		if (err) {
			return cb(err);
		}

		// concat the list of files into our collection
		files = files.concat(data.Contents);

		// are we paging?
		if (data.IsTruncated) {
			let length = data.Contents.length;
			let marker = data.Contents[length - 1].Key;
			// recursion!
			recursiveListObjects(bucketName, prefix, cb, marker, files, delimiter);
		} else {
			cb(undefined, files);
		}

	});
}

/**
 * Gets a list of objects and directories in an S3 bucket under a specified prefix
 * 
 * @param {*} bucketName The S3 bucket name
 * @param {*} prefix The prefix, or "directory" used to list objects from, can be an array
 * @param {*} delimiter The delimiter to use to specify folders
 * @returns An object containing a Content array of objects and an array of 
 */
export async function listAllObjectsAndDirectories(bucketName, prefix, delimiter = "/") {
	return new Promise((resolve, reject) => {
		let prefixes = prefix;
		if (!_.isArray(prefix)) {
			prefixes = [prefix];
		}
		const promises = [];
		prefixes.forEach(prefix => {
			promises.push(new Promise((res, rej) => {
				recursiveListObjectsAndDirectories(bucketName, prefix, (err, data) => {
					if (err) {
						rej(err);
					} else {
						res(data);
					}
				}, null, undefined, delimiter);
			}));
		});

		Promise.all(promises).then(results => {
			console.log("Got results", results);
			let result = {
				Contents: [],
				CommonPrefixes: []
			}
			results.forEach(res => {
				result.Contents = result.Contents.concat(res.Contents);
				result.CommonPrefixes = result.CommonPrefixes.concat(res.CommonPrefixes);
			});
			resolve(result);
		}).catch(err => {
			reject(err);
		});
	});
}

async function recursiveListObjectsAndDirectories(bucketName, prefix = null, cb = () => { }, marker = null, results = { Contents: [], CommonPrefixes: [] }, delimiter = null) {
	const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: bucketName } });
	const params = {
		Bucket: bucketName
	};

	// are we paging from a specific point?
	if (marker) {
		params.Marker = marker;
	}

	if (prefix) {
		params.Prefix = prefix;
	}

	if (delimiter) {
		params.Delimiter = delimiter;
	}

	// state.set(["appState", "loadingMessage"], `Loading drive files - ${results.Contents.length}`);

	// if (state.get(["appState", "navPanel", "selected"]) === "drive") {
	// 	state.set(["drive", "showSpinner", "progressMessage"], `Loading drive files - ${results.Contents.length}`);
	// }

	bucket.listObjects(params, (err, data) => {
		if (err) {
			return cb(err);
		}

		// concat the list of files into our collection
		results.Contents = results.Contents.concat(data.Contents);
		results.CommonPrefixes = results.CommonPrefixes.concat(data.CommonPrefixes);

		// are we paging?
		if (data.IsTruncated) {
			let length = data.Contents.length;
			let marker = data.Contents[length - 1].Key;
			// recursion!
			recursiveListObjectsAndDirectories(bucketName, prefix, cb, marker, results, delimiter);
		} else {
			cb(undefined, results);
		}
	});
}


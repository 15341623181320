const jsonlint = require("jsonlint-mod");
window.jsonlint = jsonlint;

let constants = {

	codeMirrorOptionsJson: {
		theme: "default",
		height: "auto",
		viewportMargin: Infinity,
		mode: {
			name: "javascript",
			json: true,
			statementIndent: 2
		},
		lineNumbers: true,
		lineWrapping: false,
		foldGutter: true,
		gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
		indentWithTabs: false,
		tabSize: 2,
		styleActiveLine: true,
		lint: true
	},
	codeMirrorOptionsJs: {
		theme: "default",
		height: "auto",
		viewportMargin: Infinity,
		mode: {
			name: "javascript",
			statementIndent: 2
		},
		lineNumbers: true,
		lineWrapping: false,
		foldGutter: true,
		gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
		indentWithTabs: false,
		tabSize: 2,
		styleActiveLine: true,
		lint: true
	},
	textTracks: {
		langCodes: [
			{
				value: "ar",
				name: "Arabic"
			},
			{
				value: "ca",
				name: "Catalan"
			},
			{
				value: "zh-Hans",
				name: "Chinese (Simplified)"
			},
			{
				value: "zh-Hant",
				name: "Chinese (Traditional)"
			},
			{
				value: "cs",
				name: "Czech"
			},
			{
				value: "da",
				name: "Danish"
			},
			{
				value: "nl",
				name: "Dutch"
			},
			{
				value: "en",
				name: "English"
			},
			{
				value: "fi",
				name: "Finnish"
			},
			{
				value: "fr",
				name: "French"
			},
			{
				value: "de",
				name: "German"
			},
			{
				value: "el",
				name: "Greek"
			},
			{
				value: "he",
				name: "Hebrew"
			},
			{
				value: "hi",
				name: "Hindi"
			},
			{
				value: "hu",
				name: "Hungarian"
			},
			{
				value: "is",
				name: "Icelandic"
			},
			{
				value: "id",
				name: "Indonesian"
			},
			{
				value: "it",
				name: "Italian"
			},
			{
				value: "jp",
				name: "Japanese"
			},
			{
				value: "ko",
				name: "Korean"
			},
			{
				value: "ms",
				name: "Malay"
			},
			{
				value: "no",
				name: "Norwegian"
			},
			{
				value: "pl",
				name: "Polish"
			},
			{
				value: "pt",
				name: "Portuguese"
			},
			{
				value: "pa",
				name: "Punjabi"
			},
			{
				value: "ro",
				name: "Romanian"
			},
			{
				value: "sr",
				name: "Serbian"
			},
			{
				value: "es",
				name: "Spanish"
			},
			{
				value: "sv",
				name: "Swedish"
			},
			{
				value: "ta",
				name: "Tamil"
			},
			{
				value: "th",
				name: "Thai"
			},
			{
				value: "tr",
				name: "Turkish"
			},
			{
				value: "uk",
				name: "Ukranian"
			},
			{
				value: "vi",
				name: "Viatvaluese"
			}
		],
		kinds: [
			{
				value: "subtitles",
				name: "Subtitles"
			},
			{
				value: "captions",
				name: "Captions"
			},
			{
				value: "descriptions",
				name: "Descriptions"
			},
			{
				value: "chapters",
				name: "Chapters"
			},
			{
				value: "metadata",
				name: "Metadata"
			}
		]
	},
	audio: {
		tts: {
			pollyVoices: [
				{
					"Gender": "Female",
					"Name": "Lotte",
					"LanguageName": "Dutch",
					"Id": "Lotte",
					"LanguageCode": "nl-NL"
				},
				{
					"Gender": "Male",
					"Name": "Maxim",
					"LanguageName": "Russian",
					"Id": "Maxim",
					"LanguageCode": "ru-RU"
				},
				{
					"Gender": "Female",
					"Name": "Salli",
					"LanguageName": "US English",
					"Id": "Salli",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Male",
					"Name": "Geraint",
					"LanguageName": "Welsh English",
					"Id": "Geraint",
					"LanguageCode": "en-GB-WLS"
				},
				{
					"Gender": "Male",
					"Name": "Miguel",
					"LanguageName": "US Spanish",
					"Id": "Miguel",
					"LanguageCode": "es-US"
				},
				{
					"Gender": "Female",
					"Name": "Marlene",
					"LanguageName": "German",
					"Id": "Marlene",
					"LanguageCode": "de-DE"
				},
				{
					"Gender": "Male",
					"Name": "Giorgio",
					"LanguageName": "Italian",
					"Id": "Giorgio",
					"LanguageCode": "it-IT"
				},
				{
					"Gender": "Female",
					"Name": "Inês",
					"LanguageName": "Portuguese",
					"Id": "Ines",
					"LanguageCode": "pt-PT"
				},
				{
					"Gender": "Female",
					"Name": "Zeina",
					"LanguageName": "Arabic",
					"Id": "Zeina",
					"LanguageCode": "arb"
				},
				{
					"Gender": "Female",
					"Name": "Zhiyu",
					"LanguageName": "Chinese Mandarin",
					"Id": "Zhiyu",
					"LanguageCode": "cmn-CN"
				},
				{
					"Gender": "Female",
					"Name": "Gwyneth",
					"LanguageName": "Welsh",
					"Id": "Gwyneth",
					"LanguageCode": "cy-GB"
				},
				{
					"Gender": "Male",
					"Name": "Karl",
					"LanguageName": "Icelandic",
					"Id": "Karl",
					"LanguageCode": "is-IS"
				},
				{
					"Gender": "Female",
					"Name": "Joanna",
					"LanguageName": "US English",
					"Id": "Joanna",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Female",
					"Name": "Lucia",
					"LanguageName": "Castilian Spanish",
					"Id": "Lucia",
					"LanguageCode": "es-ES"
				},
				{
					"Gender": "Male",
					"Name": "Cristiano",
					"LanguageName": "Portuguese",
					"Id": "Cristiano",
					"LanguageCode": "pt-PT"
				},
				{
					"Gender": "Female",
					"Name": "Astrid",
					"LanguageName": "Swedish",
					"Id": "Astrid",
					"LanguageCode": "sv-SE"
				},
				{
					"Gender": "Female",
					"Name": "Vicki",
					"LanguageName": "German",
					"Id": "Vicki",
					"LanguageCode": "de-DE"
				},
				{
					"Gender": "Female",
					"Name": "Mia",
					"LanguageName": "Mexican Spanish",
					"Id": "Mia",
					"LanguageCode": "es-MX"
				},
				{
					"Gender": "Female",
					"Name": "Bianca",
					"LanguageName": "Italian",
					"Id": "Bianca",
					"LanguageCode": "it-IT"
				},
				{
					"Gender": "Female",
					"Name": "Vitória",
					"LanguageName": "Brazilian Portuguese",
					"Id": "Vitoria",
					"LanguageCode": "pt-BR"
				},
				{
					"Gender": "Female",
					"Name": "Raveena",
					"LanguageName": "Indian English",
					"Id": "Raveena",
					"LanguageCode": "en-IN"
				},
				{
					"Gender": "Female",
					"Name": "Chantal",
					"LanguageName": "Canadian French",
					"Id": "Chantal",
					"LanguageCode": "fr-CA"
				},
				{
					"Gender": "Female",
					"Name": "Amy",
					"LanguageName": "British English",
					"Id": "Amy",
					"LanguageCode": "en-GB"
				},
				{
					"Gender": "Male",
					"Name": "Brian",
					"LanguageName": "British English",
					"Id": "Brian",
					"LanguageCode": "en-GB"
				},
				{
					"Gender": "Male",
					"Name": "Russell",
					"LanguageName": "Australian English",
					"Id": "Russell",
					"LanguageCode": "en-AU"
				},
				{
					"Gender": "Female",
					"Name": "Aditi",
					"LanguageName": "Indian English",
					"Id": "Aditi",
					"LanguageCode": "en-IN"
				},
				{
					"Gender": "Male",
					"Name": "Matthew",
					"LanguageName": "US English",
					"Id": "Matthew",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Female",
					"Name": "Dóra",
					"LanguageName": "Icelandic",
					"Id": "Dora",
					"LanguageCode": "is-IS"
				},
				{
					"Gender": "Male",
					"Name": "Enrique",
					"LanguageName": "Castilian Spanish",
					"Id": "Enrique",
					"LanguageCode": "es-ES"
				},
				{
					"Gender": "Male",
					"Name": "Hans",
					"LanguageName": "German",
					"Id": "Hans",
					"LanguageCode": "de-DE"
				},
				{
					"Gender": "Female",
					"Name": "Carmen",
					"LanguageName": "Romanian",
					"Id": "Carmen",
					"LanguageCode": "ro-RO"
				},
				{
					"Gender": "Female",
					"Name": "Ivy",
					"LanguageName": "US English",
					"Id": "Ivy",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Female",
					"Name": "Ewa",
					"LanguageName": "Polish",
					"Id": "Ewa",
					"LanguageCode": "pl-PL"
				},
				{
					"Gender": "Female",
					"Name": "Maja",
					"LanguageName": "Polish",
					"Id": "Maja",
					"LanguageCode": "pl-PL"
				},
				{
					"Gender": "Female",
					"Name": "Nicole",
					"LanguageName": "Australian English",
					"Id": "Nicole",
					"LanguageCode": "en-AU"
				},
				{
					"Gender": "Female",
					"Name": "Camila",
					"LanguageName": "Brazilian Portuguese",
					"Id": "Camila",
					"LanguageCode": "pt-BR"
				},
				{
					"Gender": "Female",
					"Name": "Filiz",
					"LanguageName": "Turkish",
					"Id": "Filiz",
					"LanguageCode": "tr-TR"
				},
				{
					"Gender": "Male",
					"Name": "Jacek",
					"LanguageName": "Polish",
					"Id": "Jacek",
					"LanguageCode": "pl-PL"
				},
				{
					"Gender": "Male",
					"Name": "Justin",
					"LanguageName": "US English",
					"Id": "Justin",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Female",
					"Name": "Céline",
					"LanguageName": "French",
					"Id": "Celine",
					"LanguageCode": "fr-FR"
				},
				{
					"Gender": "Female",
					"Name": "Kendra",
					"LanguageName": "US English",
					"Id": "Kendra",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Male",
					"Name": "Ricardo",
					"LanguageName": "Brazilian Portuguese",
					"Id": "Ricardo",
					"LanguageCode": "pt-BR"
				},
				{
					"Gender": "Male",
					"Name": "Mads",
					"LanguageName": "Danish",
					"Id": "Mads",
					"LanguageCode": "da-DK"
				},
				{
					"Gender": "Male",
					"Name": "Mathieu",
					"LanguageName": "French",
					"Id": "Mathieu",
					"LanguageCode": "fr-FR"
				},
				{
					"Gender": "Female",
					"Name": "Léa",
					"LanguageName": "French",
					"Id": "Lea",
					"LanguageCode": "fr-FR"
				},
				{
					"Gender": "Female",
					"Name": "Naja",
					"LanguageName": "Danish",
					"Id": "Naja",
					"LanguageCode": "da-DK"
				},
				{
					"Gender": "Female",
					"Name": "Penélope",
					"LanguageName": "US Spanish",
					"Id": "Penelope",
					"LanguageCode": "es-US"
				},
				{
					"Gender": "Female",
					"Name": "Tatyana",
					"LanguageName": "Russian",
					"Id": "Tatyana",
					"LanguageCode": "ru-RU"
				},
				{
					"Gender": "Male",
					"Name": "Ruben",
					"LanguageName": "Dutch",
					"Id": "Ruben",
					"LanguageCode": "nl-NL"
				},
				{
					"Gender": "Female",
					"Name": "Mizuki",
					"LanguageName": "Japanese",
					"Id": "Mizuki",
					"LanguageCode": "ja-JP"
				},
				{
					"Gender": "Male",
					"Name": "Takumi",
					"LanguageName": "Japanese",
					"Id": "Takumi",
					"LanguageCode": "ja-JP"
				},
				{
					"Gender": "Female",
					"Name": "Conchita",
					"LanguageName": "Castilian Spanish",
					"Id": "Conchita",
					"LanguageCode": "es-ES"
				},
				{
					"Gender": "Female",
					"Name": "Carla",
					"LanguageName": "Italian",
					"Id": "Carla",
					"LanguageCode": "it-IT"
				},
				{
					"Gender": "Female",
					"Name": "Kimberly",
					"LanguageName": "US English",
					"Id": "Kimberly",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Male",
					"Name": "Jan",
					"LanguageName": "Polish",
					"Id": "Jan",
					"LanguageCode": "pl-PL"
				},
				{
					"Gender": "Female",
					"Name": "Liv",
					"LanguageName": "Norwegian",
					"Id": "Liv",
					"LanguageCode": "nb-NO"
				},
				{
					"Gender": "Male",
					"Name": "Joey",
					"LanguageName": "US English",
					"Id": "Joey",
					"LanguageCode": "en-US"
				},
				{
					"Gender": "Female",
					"Name": "Lupe",
					"LanguageName": "US Spanish",
					"Id": "Lupe",
					"LanguageCode": "es-US"
				},
				{
					"Gender": "Female",
					"Name": "Seoyeon",
					"LanguageName": "Korean",
					"Id": "Seoyeon",
					"LanguageCode": "ko-KR"
				},
				{
					"Gender": "Female",
					"Name": "Emma",
					"LanguageName": "British English",
					"Id": "Emma",
					"LanguageCode": "en-GB"
				}
			],
			generationParams: {
				SAMPLE_RATE: "ttsAudioGenerationSampleRate",
				VOICE_ID: "ttsAudioGenerationVoiceId",
				ENGINE: "ttsAudioGenerationEngine"
			}
		}
	},
	react: {
		components: {
			reactModal: {
				zIndex: 1002
			}
		}
	},
	experiencesInventory: {
		props: ["id", "visible"]
	},
	activity: {
		metadata: {
			props: [
				"id",
				"name",
				"description",
				"genre",
				"publisher",
				"externalResources",
				"tags",
				"revision",
				"revisionTs",
				"guid",
				"baseIds",
				"checklistId",
				"lang",
				"contributors",
				"cloned",
				"authorProperties",
				"heroImage",
				"heroImageUrl",
				"trackData",
				"trackLocation",
				"dependencies",
				"privacy",
				"orgPrivacy",
				"visible",
				"suppressAudio",
				"hasShares"
			]
		}
	}
};

export const codeMirrorOptionsJson = constants.codeMirrorOptionsJson;
export const codeMirrorOptionsJs = constants.codeMirrorOptionsJs;

export default constants;
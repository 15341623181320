import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flexbox from "flexbox-react";

class MultiSelectToolbar extends React.PureComponent {
	render() {
		let multiSelectToolbar =
			<div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
				<div style={{ margin: 0, backgroundColor: "royalblue", height: 65, paddingLeft: 10, paddingRight: 10, paddingTop: 15, paddingBottom: 15, color: "white", fontWeight: "bold", fontSize: 24 }}>
					<Flexbox flexDirection="row" alignItems="center" justifyContent="space-between">
						<Flexbox flexDirection="row" alignItems="center">
							<div style={{ cursor: "pointer" }} onClick={this.props.onClickClose}>
								<FontAwesomeIcon icon="times" />
							</div>
							<div style={{ fontSize: 20, marginLeft: 20 }}>
								{this.props.selectedItems.length} selected
							</div>
							<div style={{ cursor: "pointer", textDecoration: "underline", fontSize: 12, marginLeft: 20, fontWeight: "normal" }} onClick={this.props.onClickSelectAll}>
								Select All
							</div>
						</Flexbox>
						{this.props.children}
					</Flexbox>
				</div>
			</div>;

		if (this.props.selectedItems.length === 0) {
			multiSelectToolbar = null;
		}

		return multiSelectToolbar;
	}
}

export default MultiSelectToolbar;
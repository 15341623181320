import React from "react";
import ReactModal from "react-modal";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import { closeMediaPreviewModal } from "../../actions/actions";
import AppConstants from "../../constants/appConstants";

import { getMediaType } from "../../utils/mediaUtils";
import { getFileTypeIcon, getExtensionFromUrl } from "../../utils/utils";

class MediaPreview extends React.PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	renderPreview() {

		let preview;
		let { url, propName } = this.props.mediaPreviewModal;

		let type;
		let ext = getExtensionFromUrl(url);

		if (url) {
			if (propName) {
				console.log("PROP NAME", propName);
				type = getMediaTypeByPropName(propName);
				if (!type) {
					//no type found, default to image
					type = "image";
				}
			} else {
				type = getMediaType(ext);
			}

			switch (type) {
				case "video": {
					preview = url ? <ReactPlayer url={url} maxWidth="100%" maxHeight="100%" playing={false} playsInline={true} muted={false} controls /> : null;
					break;
				}
				case "audio": {
					preview = url ? <ReactAudioPlayer src={url} controls /> : null;
					break;
				}
				case "pdf": {
					if (url) {
						preview =
							<object className="pdf" data={url} type="application/pdf" internalinstanceid="9" title="" style={{ width: "100%", height: "100%" }}>
								<p>Your browser isn't supporting embedded pdf files. You can download the file
									<a href={url} target="_blank">here</a>.</p>
							</object>;
					}
					break;
				}
				case "threeD": {
					preview = url ? <div>3D preview will go here</div> : null;
					break;
				}
				case "image": {
					preview = url ? <img alt="Preview" src={url} style={{ objectFit: "contain", maxWidth: "100%", maxHeight: "100%" }} /> : null;
					break;
				}
				default: {
					preview = <FontAwesomeIcon style={{ fontSize: "10vw" }} icon={getFileTypeIcon(ext)} />;
				}
			}
		}
		return preview;
	}


	render() {
		let closeButton =
			<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", position: "absolute", right: "10px", top: "12px", width: "32px" }}>
				<div className="custom-player-modal-close-button">
					<FontAwesomeIcon icon="times" onClick={() => this.props.dispatch(closeMediaPreviewModal)} />
				</div>
			</div>

		const component = <ReactModal style={{ overlay: { zIndex: AppConstants.react.components.reactModal.zIndex, backgroundColor: "#666666" }, content: { borderRadius: 0 } }} className="custom-player-modal" isOpen={this.props.mediaPreviewModal.show} little>
			{/* <h3 style={{ textAlign: "center" }}>{modalTitle}</h3> */}
			<div style={{ display: "flex", height: "100vh", overflow: "auto", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#666666", padding: "50px" }}>
				{this.renderPreview()}
			</div>
			{closeButton}
		</ReactModal>;

		return component;
	}
}

export default branch({
	mediaPreviewModal: ["appState", "mediaPreviewModal"]
}, MediaPreview);

function getMediaTypeByPropName(propName) {

	// Default to image if not found
	let mediaType = "image";
	switch (propName) {
		case "video": {
			mediaType = "video";
			break;
		}
		case "audio":
		case "backgroundAudio":
		case "labelAudioFile":
		case "label1AudioFile":
		case "label2AudioFile":
		case "commentsAudioFile":
			{
				mediaType = "audio";
				break;
			}
		case "pdf": {
			mediaType = "pdf";
			break;
		}
		case "threeD": {
			mediaType = "threeD"
			break;
		}
		default: {
			break;
		}
	}

	return mediaType;
}
import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import DriveContext from "../../driveContext";

import { generateUUID, getFileTypeIcon, validateUrl } from "../../../../utils/utils";
import { getUrlForExternalResource } from "../../../../actions/drive";
import { getMediaType } from "../../../../utils/mediaUtils";
import { showMediaPreviewModal } from "../../../../actions/actions";

import styles from "./tileNode.module.css";

class TileNode extends PureComponent {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleClick(event) {
		if (event.detail === 2) {
			this.handleDblClick();
		} else {
			this.handleSelect();
		}
	}

	handleSelect() {
		if (this.props.onSelect) {
			this.props.onSelect(this.props.node);
		}
	}

	handleDblClick() {
		if (this.props.onDblClick) {
			this.props.onDblClick(this.props.node);
		}
	}

	renderHeader() {

		const { node } = this.props;

		let icon = (node.type === "folder") ? "folder" : getFileTypeIcon(node.ext);
		let iconComponent = <FontAwesomeIcon icon={icon} />

		return (
			<div className={styles["header-container"] + " name"}>
				<div className={styles["icon-container"]}>{iconComponent}</div>
				<div className={styles["header"]} title={node.name}>{node.name}</div>
			</div>
		);
	}

	renderFilePreview() {
		const { node, meta, iconFontSize } = this.props;
		const { guid, id, name, type } = node;
		const { active } = meta ? meta : node;
		const key = `node-${guid ? guid : id ? id : generateUUID()}`;

		// console.log("Render node", name, toggled, active);
		let result = null;
		if (type === "file") {

			const url = getUrlForExternalResource(node);
			const type = getMediaType(node.ext);
			let preview = null;
			switch (type) {
				case "video": {
					preview = url ? <ReactPlayer url={url} style={{ width: "100%", maxWidth: "400px" }} width="100%" height="100%" playing={false} playsInline={true} muted={false} loop controls /> : null;
					break;
				}
				case "audio":
				case "labelAudioFile":
				case "label1AudioFile":
				case "label2AudioFile":
				case "commentsAudioFile":
					{
						preview = url ? <ReactAudioPlayer style={{ width: "100%", maxWidth: "400px" }} src={url} controls /> : null;
						break;
					}
				case "image": {
					preview = url && validateUrl(url) ? <img alt="Preview" style={{ width: "100%", maxWidth: "400px" }} src={url} /> : null;
					break;
				}
				default: {
					preview = <FontAwesomeIcon style={{ fontSize: iconFontSize ? iconFontSize : "10vw" }} icon={getFileTypeIcon(node.ext, node)} />;
				}
			}
			result = <div key={key} className={styles["file-preview-container"]}>
				<div className="thumb">
					{preview}
				</div>
			</div>;
		}
		return result;
	}

	render() {
		const { node, meta, accept } = this.props;
		const { guid, id, name, type } = node;
		const { active } = meta ? meta : node;

		const key = `tile-node-${guid ? guid : id ? id : generateUUID()}`;

		// console.log("Render node", name, toggled, active);

		let fnsSelect = {};
		let acceptable = (type === "folder" || !accept || (accept && node && node.metaData && node.metaData.type === accept));
		if (acceptable) {
			fnsSelect = {
				onClick: this.handleClick
			};
		}

		let previewButton;
		let mediaType = getMediaType(node.ext);
		if (mediaType === "video" ||
			mediaType === "audio" ||
			mediaType === "pdf" ||
			mediaType === "threeD" ||
			mediaType === "image") {
			let url = getUrlForExternalResource(node);

			previewButton = <div className="open-large" onClick={() => { this.props.dispatch(showMediaPreviewModal, url); }} title="View Media"><FontAwesomeIcon icon="expand-wide" /></div>;
		}

		return <div key={key} className={acceptable ? active ? "tile-node selected" : "tile-node" : "tile-node disabled"} {...fnsSelect} id={`drive-node-${this.context?.driveComponentId}-${node.guid}`} ß>
			{this.renderHeader()}
			{node.type === "file" && this.renderFilePreview()}
			<div class="footer">{previewButton}</div>
		</div>;
	}
}

TileNode.contextType = DriveContext;

export default branch((props) => {
	// console.log("Mapping", props.node.guid, props.node);
	if (props.node.guid) {
		return {
			meta: ["drive", "hierarchyMetadata", props.node.guid]
		};
	} else {
		return {};
	}
}, TileNode);
import React, { PureComponent } from "react";
import { branch } from "baobab-react/higher-order";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, InputGroup, FormControl, SplitButton, MenuItem, DropdownButton, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import { copy, isClipboardEmpty, getClipboard } from "../../utils/clipboard";
import autoBind from "react-autobind";
import _ from "lodash";

import { isDriveReference, getUrlForResource, getResourceReference, uploadAndOptimizeMediaFile/*, getContent*/ } from "../../actions/drive";
import { showSuccess, showConfirm } from "../../actions/alertActions";
import InlineFilePicker from "../drive/inlineFilePicker";
import { injectExternalResourceReference, getDriveObjectForReference, getDriveRootPath, isMediaOptimizationDisabled } from "../../actions/activityActions";
import { handleChangeProperty, getSelectedNodePath, handleShowGenerateTtsAudioForPropertyModal, showMediaPreviewModal } from "../../actions/actions";
import { urlToSecureParameterObject } from "../../utils/securityUtil";
import { isMobile, /*, fixFileNameForS3*/ } from "../../utils/utils";
import { uploadFile, getDriveObjectByPath } from "../../actions/drive";
import { showError } from "../../actions/alertActions";
import { AmbifiLoader } from ".";
import { generateUUID, handleOpenInNewTab, generatePastedImageFileName } from "../../utils/utils";
import PasteImageModal from "./pasteImageModal"
import AiImageGeneratorModal from "./aiImageGeneratorModal";
import MarkupImageModal from "../markupImage/MarkupImageModal";
import state from "../../state/state";

import ImageWithMarkup from "./imageWithMarkup";

class MediaPropertyInjector extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			previewUrl: null,
			mobile: isMobile(),
			uploading: false,
			showPasteImageModal: false,
			showAiImageGeneratorModal: false,
			showMarkupImageModal: false
		};
		this.fileUploadField = React.createRef();
		autoBind(this);
	}

	handleSwitchMode(mode) {
		if (this.props.onValueChange) {
			if (mode === "drive") {
				this.props.onValueChange(getResourceReference(null));
			} else {
				this.props.onValueChange("https://");
			}
		}
	}

	handleDeleteMediaProperty() {
		showConfirm("Are you sure you want to delete this media item?", "Delete Media", () => {
			this.props.onValueChange("");
		});
	}

	handleSelectFile(info) {
		console.log(info);
		const { propName } = this.props;
		injectExternalResourceReference(propName, info, getSelectedNodePath(), this.props.onChange);
	}

	handleChangeUrl(e) {
		this.handleChangeProperty(this.props.propName, e.target.value);
	}

	handlePaste() {
		if (!isClipboardEmpty()) {
			this.handleReplaceValue(getClipboard());
		}
	}

	onDocumentLoad(pdfInfo) {
		console.log("PDF loaded", pdfInfo);
		this.setState({
			pdfInfo
		});
	}
	handleQuickUpload(autoSelect = true) {
		const { accept } = this.props;
		let _accept = accept;
		if (!_accept) {
			_accept = "*/*";
		}
		const input = this.fileUploadField.current;
		if (input) {
			input.accept = _accept;
			input.click();
			this.setState({
				quickUploadAutoSelect: autoSelect
			});
		}
	}

	handleChangeProperty(propName, propValue) {
		if (this.props.onChange && _.isFunction(this.props.onChange)) {
			this.props.onChange(propValue);
		} else {
			this.props.dispatch(
				handleChangeProperty,
				propName,
				propValue
			);
		}
	}

	handleReplaceValue(newVal) {
		this.handleChangeProperty(this.props.propName, newVal);
	}

	handleReplaceExisting(path) {
		console.log("replace existing", path);
		const { accept } = this.props;
		let _accept = accept;
		if (!_accept) {
			_accept = "*/*";
		} else {
			_accept = _accept.replace("/*", "/" + _.last(path.split(".")));
		}
		const input = this.fileUploadField.current;
		if (input) {
			input.accept = _accept;
			input.click();
		}
		this.setState({
			replaceExistingTarget: path
		});
	}

	handleQuickUploadSubmit(event, overrides = {}) {
		const file = event.target.files[0];
		const nodePath = getSelectedNodePath();
		console.log("Got files", file, this.state.config);
		let fileName = (overrides.fileName) ? overrides.fileName : file.name;
		if (file && fileName) {
			const { propName } = this.props;
			this.setState({
				uploading: true
			});
			const driveRootPath = getDriveRootPath(true);
			console.log(driveRootPath);
			const isReplace = !!this.state.replaceExistingTarget;
			let targetPath = isReplace ? this.state.replaceExistingTarget : `private/activities/${driveRootPath}`;
			const isQuickMobileUpload = !isReplace && isMobile();
			if (isQuickMobileUpload) {
				let newExt;
				if (propName === "video") {
					newExt = "mp4";
				} else if (propName === "audio" || propName === "backgroundAudio") {
					newExt = "mp3";
				} else {
					newExt = `${_.last(fileName.split("."))}`;
				}

				targetPath = `${targetPath}/${generateUUID()}.${newExt}`;
			}

			const func = (isMediaOptimizationDisabled() || overrides.notOptimized) ? uploadFile : uploadAndOptimizeMediaFile;
			func(targetPath, file, propName, isReplace || isQuickMobileUpload, overrides).then((uploadResponse) => {
				if (this.state.quickUploadAutoSelect) {
					// MAY NEED TO REVERT COMMENT BELOW, TEST
					injectExternalResourceReference(propName, getDriveObjectByPath(/*isReplace || isQuickMobileUpload ? targetPath : */uploadResponse.path), nodePath);
				}
				this.setState({
					uploading: false,
					replaceExistingTarget: null
				});
				this.fileUploadField.current.value = "";

			}).catch(err => {
				showError("Upload File", "An error occurred while attempting to upload the file. Error: " + err);
			});
		} else {
			showError("Upload File", "Need to select a folder and specify a file to upload");
		}
	}

	handleShowGenerateTtsAudioForPropertyModal(propName) {
		this.props.dispatch(handleShowGenerateTtsAudioForPropertyModal, propName);
	}

	pasteFunction(event) {
		var self = this;
		// consider the first item (can be easily extended for multiple items)
		var item = event.pasteEvent.clipboardData.items[0];

		if (this.state.showPasteImageModal) {

			this.setState({
				showPasteImageModal: false
			})

			if (item && item.type.indexOf("image") === 0) {
				var blob = item.getAsFile();

				/*
				var reader = new FileReader();
				reader.onload = function (event) {
					console.log(event.target.result);				
				};
				reader.readAsDataURL(blob);
				*/

				let overrides = { notOptimized: true };

				if (event.imageName) {
					overrides.fileName = event.imageName;
				} else {
					overrides.fileName = generatePastedImageFileName();
				}

				self.handleQuickUploadSubmit({
					target: {
						files: [blob]
					}
				}, overrides);

			} else if (event.pasteEvent.clipboardData.files.length > 0) {

				let pastedFile = event.pasteEvent.clipboardData.files[0];

				let overrides = { notOptimized: true };

				if (event.imageName) {
					overrides.fileName = event.imageName;
				} else {
					overrides.fileName = generatePastedImageFileName(); //clipboard always returns png
				}

				if (pastedFile.type.indexOf("image") === 0) {
					self.handleQuickUploadSubmit({
						target: {
							files: [pastedFile]
						}
					}, overrides)
				}


			} else {
				console.log("Skipping paste for item", item);
				showError("Error", "Paste image failed, no image data found.");
			}
		}
	}

	handleShowPasteImageModal() {
		this.setState({
			showPasteImageModal: true,
			quickUploadAutoSelect: true //need to do this so inline file picker path is always updated
		})
	}

	handleShowAiImageGeneratorModal() {
		this.setState({
			showAiImageGeneratorModal: true
		})
	}

	handleClosePasteImageModal() {
		this.setState({
			showPasteImageModal: false
		})
	}


	handleCloseAiImageGeneratorModal() {
		this.setState({
			showAiImageGeneratorModal: false
		})
	}

	handleShowMarkupImageModal() {
		this.setState({
			showMarkupImageModal: true
		})
	}

	handleCloseMarkupImageModal() {
		this.setState({
			showMarkupImageModal: false
		})
	}

	renderMediaPanel(type, url, driveReference = true) {
		let result = <div />;
		const { loading } = this.state;
		const loadingDiv = <div style={{ margin: "15px" }}><FontAwesomeIcon
			icon='spinner'
			spin /> Uploading media asset...</div>;


		let generateTtsAudioMenuItem;
		if (this.props.generateTtsAudio) {
			generateTtsAudioMenuItem = <MenuItem onClick={() => { this.handleShowGenerateTtsAudioForPropertyModal(type); }}><div><FontAwesomeIcon icon={["far", "volume-up"]} />&nbsp;Generate TTS Audio</div></MenuItem>;
		}


		let _createUrlWrapper = driveReference && !!url;
		if (loading) {
			result = loadingDiv;
		} else {
			switch (type) {
				case "video": {
					result = url ? <ReactPlayer url={url} className="player-video" width="100%" playing={false} playsInline={true} muted={false} loop controls /> : null;
					break;
				}
				case "audio":
				case "backgroundAudio":
				case "labelAudioFile":
				case "label1AudioFile":
				case "label2AudioFile":
				case "commentsAudioFile":
					{
						result = url ? <ReactAudioPlayer style={{ width: "100%", maxWidth: "400px" }} src={url} controls /> : null;
						break;
					}
				case "pdf": {
					if (url) {
						const _url = urlToSecureParameterObject(url);
						console.log("PDF fetch url", _url);
						// result = <iframe style={{width:"100%", height:"60.4vw"}} src={url} />;
						result = <div className="embed-responsive embed-responsive-210by297">
							<object className="embed-responsive-item" data={url} type="application/pdf" internalinstanceid="9" title="">
								<p>Your browser isn't supporting embedded pdf files. You can download the file
									<a href={url}> here</a>.</p>
							</object>
						</div>;
						// result = <Document
						// 	style={{width:"100%"}}	
						// 	file={_url}
						// 	onLoadSuccess={this.onDocumentLoad}
						// >
						// 	<Page pageNumber={1} scale={0.5} />
						// </Document>;
					}
					break;
				}
				case "threeD": {
					result = null;

					// result = url && validateUrl(url) ? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					// 	3D Preview will go here
					// </div> : null;
					break;
				}
				case "videoTextTrack": {
					result = null;

					// result = url && validateUrl(url) ? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					// 	Video Text Track Preview will go here
					// </div> : null;

					break;
				}
				default: {
					// result = url && validateUrl(url) ? <img alt="Preview" style={{ maxWidth: "800px" }} src={url} /> : null;

					let authorProperties = {};

					let cursor = state.select(state.select("selectedNodePath").get());
					let entity = cursor.get().entity;

					if (entity.authorProperties) {
						try {
							authorProperties = JSON.parse(entity.authorProperties);
						} catch (err) {
							authorProperties = {};
						}
					}

					// If have imageHotspots in authorProperties
					let imageHotspots = authorProperties.imageHotspots ? authorProperties.imageHotspots : null;

					result = <ImageWithMarkup url={url} imageHotspots={imageHotspots} />
				}
			}
		}
		if (_createUrlWrapper && result !== loadingDiv) {
			result = (
				<div>



					<FormGroup>
						<InputGroup>
							<FormControl onChange={(e) => { this.handleChangeUrl(e); }} type="text" value={url} ref={el => this[`ref-${type}`] = el} />
							<SplitButton
								componentClass={InputGroup.Button}
								id="input-dropdown-addon"
								title={<div onClick={() => { copy(url); showSuccess("Copied", "Media address has been copied to clipboard"); }}><FontAwesomeIcon icon="clipboard" />&nbsp;Copy</div>}
								pullRight
							>
								<MenuItem disabled={isClipboardEmpty()} key="1" onClick={() => { this.handlePaste(type); }}><FontAwesomeIcon icon="paste" />&nbsp;Paste Resource</MenuItem>
								<MenuItem divider />
								{generateTtsAudioMenuItem}
								{(this.props.markupImage || (!this.state.mobile && (this.props.urlParams.hasOwnProperty("markupImage") && this.props.urlParams.markupImage === "true"))) &&
									<>
										<MenuItem key="markupImageModal" onClick={() => {
											this.handleShowMarkupImageModal();
										}}>
											<FontAwesomeIcon icon="pen" />&nbsp;Media Markup
										</MenuItem>
										<MenuItem divider />
									</>
								}
								<MenuItem key="1" onClick={() => { /*handleOpenInNewTab(url);*/ this.props.dispatch(showMediaPreviewModal, url, type); }}><FontAwesomeIcon icon="expand-wide" />&nbsp;View&nbsp;Media&nbsp;</MenuItem>
							</SplitButton>
						</InputGroup>
					</FormGroup>
					{result}
					{/* <MediaQuery query='(max-width: 599px)'>
						<div style={{ marginTop: "5px" }}><a href="#clip" onClick={() => { copy(url); showSuccess("Copied", "Media address has been copied to clipboard"); }}><FontAwesomeIcon name="clipboard" />&nbsp;Copy&nbsp;Media&nbsp;Address</a></div><div style={{ marginTop: "5px" }}><a href={url} target="_blank"><FontAwesomeIcon icon="external-link"/>&nbsp;View&nbsp;Media</a></div>
					</MediaQuery>
					<MediaQuery query='(min-width: 600px)'>
						<div style={{ marginTop: "5px" }}><a href="#clip" onClick={() => { copy(url); showSuccess("Copied", "Media address has been copied to clipboard"); }}><FontAwesomeIcon name="clipboard" />&nbsp;Copy&nbsp;Media&nbsp;Address</a> | <a href={url} target="_blank"><FontAwesomeIcon icon="external-link" />&nbsp;View&nbsp;Media</a></div>
					</MediaQuery>	 */}
				</div>
			);
		} else if (driveReference && result !== loadingDiv) {
			result = (<div>
				<FormGroup>
					<InputGroup>
						<FormControl onChange={(e) => { this.handleChangeUrl(e); }} type="text" value={url} ref={el => this[`ref-${type}`] = el} />
						<SplitButton
							componentClass={InputGroup.Button}
							id="input-dropdown-addon"
							title={<div onClick={() => { copy(url); showSuccess("Copied", "Media address has been copied to clipboard"); }}><FontAwesomeIcon icon="clipboard" />&nbsp;Copy</div>}
							pullRight
						>
							<MenuItem disabled={isClipboardEmpty()} key="1" onClick={() => { this.handlePaste(type); }}><FontAwesomeIcon icon="paste" />&nbsp;Paste</MenuItem>
							{generateTtsAudioMenuItem}
							<MenuItem divider />
							<MenuItem key="2" onClick={() => { /*handleOpenInNewTab(url);*/ this.props.dispatch(showMediaPreviewModal, url, type); }}><FontAwesomeIcon icon="expand-wide" />&nbsp;View&nbsp;Media&nbsp;</MenuItem>
						</SplitButton>
					</InputGroup>
				</FormGroup>
			</div>);
		} else if (result !== loadingDiv) {
			result = (
				<div style={{ display: "flex", flexDirection: "column" }}>
					{result}
					<div style={{ display: "flex" }}>
						{(type === "image" && (this.props.markupImage || (!this.state.mobile && (this.props.urlParams.hasOwnProperty("markupImage") && this.props.urlParams.markupImage === "true")))) &&
							<Button style={{ marginTop: "5px", marginRight: "10px" }} onClick={() => { this.handleShowMarkupImageModal(); }}><FontAwesomeIcon icon="pen" />&nbsp;Media&nbsp;Markup</Button>
						}
						{!this.state.mobile && <Button style={{ marginTop: "5px", maxWidth: "200px" }} onClick={() => { /*handleOpenInNewTab(url)*/ this.props.dispatch(showMediaPreviewModal, url, type); }}><FontAwesomeIcon icon="expand-wide" />&nbsp;View&nbsp;Media&nbsp;</Button>}
					</div>
				</div>);
		}
		return result;
	}

	render() {

		const { value, propName, accept, label, entityId } = this.props;

		let authorProperties = {};

		let cursor = state.select(state.select("selectedNodePath").get());
		let entity = cursor.get().entity;

		if (entity.authorProperties) {
			try {
				authorProperties = JSON.parse(entity.authorProperties);
			} catch (err) {
				authorProperties = {};
			}
		}

		// If have imageHotspots in authorProperties
		let imageHotspots = authorProperties.imageHotspots ? authorProperties.imageHotspots : null;
		let imageKenBurnsEffect = authorProperties.imageKenBurnsEffect ? authorProperties.imageKenBurnsEffect : null;

		const driveReference = isDriveReference(value);
		let _accept = accept;
		if (!_accept) {
			_accept = "*/*";
		}
		let previewUrl = null;
		let externalResource = null;
		if (driveReference) {
			try {
				const resourceEntry = getDriveObjectForReference(value);
				externalResource = getDriveObjectForReference(value);
				previewUrl = getUrlForResource(resourceEntry, externalResource);
				// if (previewUrl.includes(".vtt")) {
				// 	getContent(resourceEntry.guid).then(content => {
				// 		console.log("!@!@!@ CONTENT", content);
				// 	}).catch(err => {
				// 		showError("Get Content", "An error occurred while attempting to get content: " + err);
				// 	});
				// }
			} catch (err) {
				console.warn(err);
			}
		} else {
			previewUrl = value;
			externalResource = value;
		}

		console.log("Render a preview", previewUrl);
		const preview = this.renderMediaPanel(propName, previewUrl, !driveReference, imageHotspots);

		console.log("EXTERNAL RESOURCE", externalResource);

		const component = (driveReference) ? <InlineFilePicker handleReplaceValue={this.handleReplaceValue} handleQuickUpload={this.handleQuickUpload} handleReplaceExisting={this.handleReplaceExisting} actionBarTop edit onFileSelected={this.handleSelectFile} value={externalResource} reference={value} accept={accept} entityId={entityId} handleShowPasteImageModal={this.handleShowPasteImageModal} handleShowAiImageGeneratorModal={this.handleShowAiImageGeneratorModal} handleShowMarkupImageModal={this.handleShowMarkupImageModal} /> : null;

		let generateTtsAudioMenuItem;
		console.log("MEDIA INJECTOR", this.props);
		if (this.props.generateTtsAudio) {
			generateTtsAudioMenuItem = <>
				<MenuItem eventKey="3" disabled={!driveReference} onClick={() => { this.handleShowGenerateTtsAudioForPropertyModal(propName); }}><div><FontAwesomeIcon icon="volume-up" /> Generate TTS Audio</div><div><small>Generate audio from associated text.</small></div></MenuItem>
				<MenuItem divider />
			</>;
		}

		let pasteImageModal;

		if (this.state.showPasteImageModal) {
			pasteImageModal = <PasteImageModal open={this.state.showPasteImageModal} onPaste={this.pasteFunction} onClose={this.handleClosePasteImageModal} nodePath={getSelectedNodePath()} />
		}

		let aiImageGeneratorModal;

		if (this.state.showAiImageGeneratorModal) {
			aiImageGeneratorModal = <AiImageGeneratorModal open={this.state.showAiImageGeneratorModal} onClose={this.handleCloseAiImageGeneratorModal} />
		}

		let markupImageModal;
		if (this.state.showMarkupImageModal) {
			markupImageModal = <MarkupImageModal open={this.state.showMarkupImageModal} onClose={this.handleCloseMarkupImageModal} url={previewUrl} imageHotspots={imageHotspots} imageKenBurnsEffect={imageKenBurnsEffect} />
		}

		return (
			<AmbifiLoader show={this.state.uploading}>
				<div>
					<div>
						<label>{label}</label>
						{/* <Connected>
								<br/><span style={{ color: "red", fontWeight: "bold" }}><FontAwesomeIcon icon="exclamation-triangle " /> No Connection, upload may fail, make sure to  </span>
							</Connected> */}
					</div>
					<div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", borderBottom: "1px dashed gray", paddingBottom: "10px" }}>
						<input ref={this.fileUploadField} type="file" id={`quick-upload-inline-${entityId}`} onChange={this.handleQuickUploadSubmit} style={{ display: "none" }} />
						<DropdownButton
							id={`${entityId}-reference-type-selector`}
							title={
								driveReference ? (
									<span><FontAwesomeIcon icon={["far", "hdd"]} /> From AmbiFi Drive</span>
								) : (
									<span><FontAwesomeIcon icon="external-link" /> From External (Self Hosted)</span>
								)
							}
						>
							<MenuItem eventKey="1" disabled={driveReference} onClick={() => { this.handleSwitchMode("drive"); }}><div><FontAwesomeIcon icon={["far", "hdd"]} /> Drive</div><div><small>Upload and reference files from your AmbiFi Drive.</small></div></MenuItem>
							<MenuItem eventKey="2" disabled={!driveReference} onClick={() => { this.handleSwitchMode("url"); }}><div><FontAwesomeIcon icon="external-link" /> External (Self Hosted)</div><div><small>Provide a URL to external files.</small></div></MenuItem>
							{generateTtsAudioMenuItem}
						</DropdownButton>
						<Button bsStyle="danger" onClick={this.handleDeleteMediaProperty}><FontAwesomeIcon icon="times" /></Button>
					</div>
					<div style={{ display: "flex", flexDirection: "column" }}>

						{component}
						{preview}
						<hr />
					</div>
					{pasteImageModal}
					{aiImageGeneratorModal}
					{markupImageModal}
				</div>
			</AmbifiLoader>
		);
	}

}


export default branch((props) => {
	return {
		guidToResource: ["drive", "guidToResource"],
		uploadStatus: ["uploads", props.entityId],
		urlParams: ["appState", "urlParams"],
		markupImage: ["appCapabilities", "markupImage"]
	};
}, MediaPropertyInjector);
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import _ from "lodash";
import React from "react";
import { Button, Checkbox } from "react-bootstrap";
import autoBind from "react-autobind";

import { setFilterGenreProperty, setFilterTagProperty, setShowDependencies, setShowVisible, setFilterSetting } from "../../actions/inventoryActions";
import * as utils from "../../utils/utils.js";


class NavItemMyChecklists extends React.Component {

	constructor(props) {
		super(props);
		autoBind(this);
	}

	handleExpandGenres() {
		setFilterSetting("expandGenres", true);
	}

	handleCollapseGenres() {
		setFilterSetting("expandGenres", false);
	}

	handleExpandTags() {
		setFilterSetting("expandTags", true);
	}

	handleCollapseTags() {
		setFilterSetting("expandTags", false);
	}

	handleSortGenresByCount() {
		setFilterSetting("sortGenresByCount", true);
	}

	handleSortGenresByName() {
		setFilterSetting("sortGenresByCount", false);
	}

	handleSortTagsByCount() {
		setFilterSetting("sortTagsByCount", true);
	}

	handleSortTagsByName() {
		setFilterSetting("sortTagsByCount", false);
	}

	handleClearFilters() {
		this.props.filtersAll.genres.forEach((genre, i) => {
			setFilterGenreProperty(this.context.tree, genre.genre, false, this.props.onFilter, this.props.onFilterEnd);
		});
		this.props.filtersAll.tags.forEach((tag, i) => {
			setFilterTagProperty(this.context.tree, tag.tag, false, this.props.onFilter, this.props.onFilterEnd);
		});
	}

	render() {
		let self = this;

		let clearFilters =
			<Button bsStyle="link" style={{ margin: 0, padding: "5px", marginLeft: "-5px", marginBottom: "10px" }} onClick={this.handleClearFilters} key={"clear-filters"}>Clear Filters</Button>;

		let genresTitle;


		let expandTags = false;
		let expandGenres = false;
		const { filterSettings } = this.props;
		if (filterSettings) {
			if (filterSettings.expandTags) {
				expandTags = filterSettings.expandTags;
			}
			if (filterSettings.expandGenres) {
				expandGenres = filterSettings.expandGenres;
			}
		}

		let genres = [];

		if (filterSettings.sortGenresByCount) {
			_.reverse(_.sortBy(this.props.filtersAll.genres, ["count", "genre"])).forEach((genre, i) => {
				if ((!expandGenres && i <= 9) || expandGenres) {
					let genreIcon = utils.getGenreIconForType(self.context.tree, genre.genre);

					if (genre.count > 0) {
						genres.push(<div key={"genre" + i}>
							<Checkbox checked={genre.checked} onChange={(e) => { setFilterGenreProperty(self.context.tree, genre.genre, e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
								<FontAwesomeIcon fixedWidth={true} style={{ fontSize: "16px" }} icon={genreIcon} /> {genre.genre} ({genre.count})
							</Checkbox>
						</div>);
					}
				}
			});
		} else {
			_.sortBy(this.props.filtersAll.genres, ["genre"]).forEach((genre, i) => {
				if ((!expandGenres && i <= 9) || expandGenres) {
					let genreIcon = utils.getGenreIconForType(self.context.tree, genre.genre);
					if (genre.count > 0) {
						genres.push(<div key={"genre" + i}>
							<Checkbox checked={genre.checked} onChange={(e) => { setFilterGenreProperty(self.context.tree, genre.genre, e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
								<FontAwesomeIcon fixedWidth={true} style={{ fontSize: "16px" }} icon={genreIcon} /> {genre.genre} ({genre.count})
							</Checkbox>
						</div>);
					}
				}
			});
		}
		if (this.props.filtersAll.genres.length > 10) {
			if (!expandGenres) {
				genres.push(<Button bsStyle="link" onClick={this.handleExpandGenres} key={"genre-expand"}>Show More...</Button>);
			} else {
				genres.push(<Button bsStyle="link" onClick={this.handleCollapseGenres} key={"genre-collapse"}>Show Less...</Button>);
			}
		}


		if (genres.length !== 0) {
			genresTitle =
				<div>
					<b>Genres</b>
					{this.props.filterSettings.sortGenresByCount &&
						<Button bsStyle="link" bsSize="xsmall" style={{ fontSize: "10px" }} onClick={this.handleSortGenresByName} key={"genre-sort-by-name"}>Sort By Name</Button>
					}
					{!this.props.filterSettings.sortGenresByCount &&
						<Button bsStyle="link" bsSize="xsmall" style={{ fontSize: "10px" }} onClick={this.handleSortGenresByCount} key={"genre-sort-by-count"}>Sort By Count</Button>
					}
					<br />
				</div>;
		}

		let tagsTitle;
		// _.sortBy(users, ['user', 'age']);


		let tags = [];
		if (filterSettings.sortTagsByCount) {
			_.reverse(_.sortBy(this.props.filtersAll.tags, ["count", "tag"])).forEach((tag, i) => {
				if ((!expandTags && i <= 9) || expandTags) {
					if (tag.count > 0) {
						tags.push(<div key={"tag" + i}>
							<Checkbox checked={tag.checked} onChange={(e) => { setFilterTagProperty(self.context.tree, tag.tag, e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
								{tag.tag} ({tag.count})
							</Checkbox>
						</div>);
					}
				}
			});
		} else {
			_.sortBy(this.props.filtersAll.tags, ["tag"]).forEach((tag, i) => {
				if ((!expandTags && i <= 9) || expandTags) {
					if (tag.count > 0) {
						tags.push(<div key={"tag" + i}>
							<Checkbox checked={tag.checked} onChange={(e) => { setFilterTagProperty(self.context.tree, tag.tag, e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
								{tag.tag} ({tag.count})
							</Checkbox>
						</div>);
					}
				}
			});
		}


		if (this.props.filtersAll.tags.length > 10) {
			if (!expandTags) {
				tags.push(<Button bsStyle="link" onClick={this.handleExpandTags} key={"tags-expand"}>Show More...</Button>);
			} else {
				tags.push(<Button bsStyle="link" onClick={this.handleCollapseTags} key={"tags-collapse"}>Show Less...</Button>);
			}
		}
		if (tags.length !== 0) {
			tagsTitle =
				<div>
					<b>Tags</b>
					{this.props.filterSettings.sortTagsByCount &&
						<Button bsStyle="link" bsSize="xsmall" style={{ fontSize: "10px" }} onClick={this.handleSortTagsByName} key={"tags-sort-by-name"}>Sort By Name</Button>
					}
					{!this.props.filterSettings.sortTagsByCount &&
						<Button bsStyle="link" bsSize="xsmall" style={{ fontSize: "10px" }} onClick={this.handleSortTagsByCount} key={"tags-sort-by-count"}>Sort By Count</Button>
					}
					<br />
				</div>;
		}

		let showVisible =
			<Checkbox checked={!this.props.showVisible} onChange={(e) => { setShowVisible(self.context.tree, !e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
				Show Visible Only
			</Checkbox>;
		let showDependencies =
			<Checkbox checked={this.props.showDependencies} onChange={(e) => { setShowDependencies(self.context.tree, e.target.checked, self.props.onFilter, self.props.onFilterEnd); }}>
				Show Dependencies
			</Checkbox>;

		let genresPanel;
		if (this.props.capabilitiesMyChecklists.filterGenresVisible) {
			genresPanel =
				<div>
					{genresTitle}
					{genres}
				</div>;

		}

		let filters;
		if (this.props.selected && (this.props.filtersAll.genres.length > 0 || this.props.filtersAll.tags.length > 0)) {
			filters = (this.props.visibilityOnly) ? null : (
				<div style={{ marginLeft: "10px", marginTop: "10px" }}>
					{showVisible}
					{showDependencies}
					<hr style={{ borderColor: "#dddddd" }} />
					{clearFilters}
					{genresPanel}
					<div>
						{tagsTitle}
						{tags}
					</div>
				</div>);
		}

		return (
			<div style={{ display: "flex", alignItems: "center", borderLeftStyle: "solid", borderWidth: "5px", borderColor: this.props.selected ? "royalblue" : "transparent", padding: "10px" }} onClick={this.props.onClick}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<FontAwesomeIcon fixedWidth={true} style={{ fontSize: "24px" }} icon={this.props.icon} />&nbsp;&nbsp;{this.props.label}
					</div>
					{filters}
				</div>
			</div>
		);
	}
}

NavItemMyChecklists.contextTypes = {
	tree: PropTypes.baobab
};


export default branch({
	showVisible: ["appState", "filters", "showVisible"],
	filterSettings: ["appState", "filters", "settings"],
	showDependencies: ["appState", "filters", "showDependencies"],
	capabilitiesMyChecklists: ["appCapabilities", "views", "myChecklists"]
}, NavItemMyChecklists);
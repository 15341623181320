import state from "../state/state";
import globals from "../constants/env";
import _ from "lodash";
import { toast } from "react-toastify";

import { postMessage } from "../actions/mobileAppCommunicationActions";
import { isMobile } from "../utils/utils";

export function showAlert(message, cb) {
	if (message instanceof Error) {
		console.error("receieved an error, expecting an Object", message);
	}
	showInfo("Info", message, cb);
}

export function dismissAlert() {
	state.select(["appState", "alerts"]).set([]);
}

export function showInfo(title, message, cb) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			id: Date.now() + Math.random(),
			type: "info",
			title,
			message,
			callback: cb
		});

		if (isMobile()) {
			postMessage({
				method: "showAlert",
				title: title,
				message: message
			});
		}
	} else {
		alert(message);
		if (cb) {
			cb();
		}
	}
}

export function showError(title, message, cb) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			id: Date.now() + Math.random(),
			type: "danger",
			title,
			message,
			callback: cb
		});

		if (isMobile()) {
			postMessage({
				method: "showAlert",
				title: title,
				message: message
			});
		}
	} else {
		alert(message);
		if (cb) {
			cb();
		}
	}
}

export function showWarning(title, message, cb) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			id: Date.now() + Math.random(),
			type: "warning",
			title,
			message,
			callback: cb
		});

		if (isMobile()) {
			postMessage({
				method: "showAlert",
				title: title,
				message: message
			});
		}
	} else {
		alert(message);
		if (cb) {
			cb();
		}
	}
}

export function showSuccess(title, message, cb) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			id: Date.now() + Math.random(),
			type: "success",
			title,
			message,
			callback: cb
		});
	} else {
		alert(message);
		if (cb) {
			cb();
		}
	}
}

export function showConfirm(message, title, onConfirm) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			message,
			title,
			onConfirm
		});
	} else {
		if (window.confirm(message)) {
			onConfirm();
		}
	}
}

export function showYesNo(message, title, onYesNo) {
	if (globals.config.useFancyAlerts) {
		state.select(["appState", "alerts"]).push({
			message,
			title,
			onYesNo
		});
	} else {
		if (window.confirm(message)) {
			onYesNo();
		}
	}
}

export function showError2(title, err) {
	if (err === null) {
		showError(title, "Unknown error.");
	} else if (err.code === "NetworkingError") {
		showError(title, "Network error. Please check connectivity and try again.");
	} else {
		showError(title, `${err.message}\n\nDetail: ${err.stack}`);
	}
}

export function showSuccessToast(message, options = null) {

	let _options = {
		position: "top-right",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	};
	if (options) {
		_options = _.assign({}, _options, options);
	}

	toast.success(message, _options);
}

export function showErrorToast(message, options = null) {

	let _options = {
		position: "top-right",
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	};
	if (options) {
		_options = _.assign({}, _options, options);
	}

	toast.error(message, _options);
}

export function showWarningToast(message, options = null) {

	let _options = {
		position: "top-right",
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	};
	if (options) {
		_options = _.assign({}, _options, options);
	}

	toast.warn(message, _options);
}


export function showInfoToast(message, options = null) {

	let _options = {
		position: "top-right",
		autoClose: false,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	};
	if (options) {
		_options = _.assign({}, _options, options);
	}

	toast.warn(message, _options);
}

export function showSaveActivityError(e) {
	showError("Save Activity", "There was an issue saving the activity. Sorry for the inconvenience, but you will need to refresh the app before you will be able to save. If you did a lot of work since your last save, you will have the opportunity to restore any lost changes." + ((e.message) ? `<br/><br/>Error details: ${e.message}` : ""));
}

import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";
import thunkMidleware from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";

import {
	ConferenceRoom,
	VoxeetProvider,
	reducer as voxeetReducer,
} from "@voxeet/react-components";
// import VoxeetSdk from "@voxeet/voxeet-web-sdk";


import { getJwtToken } from "../../actions/user";
import { getJwtTokenDolby } from "../../utils/securityUtil";

// Import Style
import "@voxeet/react-components/dist/voxeet-react-components.css"; // Customize, refer to https://github.com/voxeet/voxeet-assets-react-components

const reducers = combineReducers({
	voxeet: voxeetReducer,
});

const configureStore = () => createStore(reducers, applyMiddleware(thunkMidleware));

class VideoCall extends React.PureComponent {
	async doRefreshToken() {
		return await getJwtTokenDolby(getJwtToken());
	};

	render() {
		if (!this.props.videoSdkInitialized) {
			return (
				<div>
					Video Call is not initialized. Do you have connectivity? If so, try refreshing the app.
				</div>
			);
		}

		return (
			<VoxeetProvider store={configureStore()}>
				<ConferenceRoom
					// sdk={VoxeetSdk}
					autoJoin={this.props.videoCall.autoJoin}
					isAdmin={this.props.videoCall.invitedUsers && this.props.videoCall.invitedUsers.length > 0 ? true : false}
					userInfo={{ name: this.props.user.name, externalId: this.props.user.username }}
					isWidget={false}
					rtcpmode={"max"}
					liveRecordingEnabled
					closeSessionAtHangUp={false}
					videoCodec={"H264"}
					chromeExtensionId={"efdjhmbmjlhomjhnnmpeeillhpnldoje"}
					displayModes={["tiles", "speaker"]}
					// oauthToken={this.props.videoCallOauthToken}
					// refreshTokenCallback={this.doRefreshToken}
					conferenceAlias={this.props.conferenceAlias ? this.props.conferenceAlias : null}
					isModal={false}
					// invitedUsers={
					// 	[{
					// 		name: "Test1",
					// 		externalId: "ambifi.test1"
					// 	}]
					// }
					handleOnLeave={this.props.handleOnLeave}
					handleOnConnect={this.props.handleOnConnect}
				/>
			</VoxeetProvider>
		);
	}
}

VideoCall.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ["user"],
	urlParams: ["appState", "urlParams"],
	orgId: ["selectedOrg", "id"],
	orgName: ["selectedOrg", "name"],
	chatConnected: ["appState", "chatConnected"],
	videoCall: ["appState", "videoCall"],
	videoCallConnected: ["appState", "videoCallConnected"],
	videoSdkInitialized: ["appState", "videoSdkInitialized"],
	videoCallOauthToken: ["appState", "videoCallOauthToken"]
}, VideoCall);

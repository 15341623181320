import React, { PureComponent } from "react";
import _ from "lodash";
import { Controlled as CodeMirror } from "react-codemirror2";

import { codeMirrorOptionsJson } from "../../../constants/appConstants";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line
require("codemirror/mode/javascript/javascript"); // eslint-disable-line
require("codemirror/mode/htmlmixed/htmlmixed"); // eslint-disable-line
require("codemirror/addon/fold/foldcode"); // eslint-disable-line
require("codemirror/addon/fold/foldgutter"); // eslint-disable-line
require('codemirror/addon/fold/brace-fold.js'); // eslint-disable-line
require("codemirror/addon/fold/foldgutter.css"); // eslint-disable-line
require('codemirror/addon/lint/lint');// eslint-disable-line
require('codemirror/addon/lint/json-lint');// eslint-disable-line
require("codemirror/addon/lint/lint.css");

export default class JsonEditor extends PureComponent {

	constructor(props) {
		super(props);
		const { content, onChange } = props;
		let editContent = content;
		if (!content || _.isEmpty(content)) {
			editContent = `
			{
				"prop":"value"
			}
			`;

		}

		if (!_.isString(editContent)) {
			editContent = JSON.stringify(editContent, null, 4);
		}

		this.lastEdit = editContent;

		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}

		this.state = {
			editContent
		};
	}

	handleChangeEditContent(editContent) {
		this.setState({
			editContent
		});
		const { onChange } = this.props;
		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}
	}

	handleChangeValidity(valid, error) {
		const validity = {
			valid,
			error
		};
		this.setState({
			validity
		});

		const { onValidityChange } = this.props;
		if (onValidityChange && _.isFunction(onValidityChange)) {
			onValidityChange(validity);
		}
	}

	render() {

		const { editContent } = this.state;


		return <CodeMirror
			value={editContent}
			options={codeMirrorOptionsJson}
			onBeforeChange={(editor, data, value) => {
				this.lastEdit = value;
				this.handleChangeEditContent(value);
			}}
			className="stretch"
			// editorDidMount={editor => { this.instance = editor; }}
			onBlur={() => {
				try {
					if (!_.isEmpty(this.lastEdit)) {
						JSON.parse(this.lastEdit);
						this.handleChangeValidity(true);
					}
				} catch (e) {
					this.handleChangeValidity(false, e);
					alert(e.message + "\n\nThe edited content is not valid JSON, please make sure to fix all issues before attempting to save.");
				}
			}}
		/>;

	}

}
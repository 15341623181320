import state from "../state/state";
import browserHistory from "../history";
import { showConfirm } from "./alertActions";

export function navigateTo(target, confirmNavigate = false) {
	if (confirmNavigate) {
		showConfirm("Are you sure you want to leave this page? All unsaved changes will be lost.", "Leave Page?", () => {
			doNavigate(target);
		});
	} else {
		doNavigate(target);
	}
}

function doNavigate(target) {
	let previousTarget = state.get(["appState", "navPanel", "selected"]);

	let path;
	if (target === "home") {
		// path = "/home";
		path = "/home"; // For now
	} else if (target === "search") {
		path = "/search";
	} else if (target === "chat") {
		path = "/chat";
	} else if (target === "searchHistory") {
		path = "/searchHistory";
	} else if (target === "dashboard") {
		path = "/dashboard";
	} else if (target === "myChecklists") {
		path = "/checklists";
	} else if (target === "history") {
		path = "/history";
	} else if (target === "timers") {
		path = "/timers";
	} else if (target === "import") {
		path = "/import";
	} else if (target === "organization") {
		path = "/organization";
	} else if (target === "profile") {
		path = "/profile";
	} else if (target === "login") {
		path = "/login";
	} else if (target === "reporting") {
		path = "/reporting";
	} else if (target === "cms") {
		path = "/cms";
	} else if (target === "drive") {
		path = "/drive";
	} else if (target === "player") {
		path = "/player";
	} else if (target === "assignments") {
		path = "/assignments";
	} else if (target === "publications") {
		path = "/publications";
	} else {
		path = target;
	}


	//do save if we were in the player	
	if (previousTarget === "player" && target !== "player") {
		state.set(["appState", "navPanel", "selected"], target);
		browserHistory.push(path);
	} else {
		state.set(["appState", "navPanel", "selected"], target);
		browserHistory.push(path);
	}
}
import React, { PureComponent } from "react";
import _ from "lodash";
import { Controlled as CodeMirror } from "react-codemirror2";

require("codemirror/lib/codemirror.css"); // eslint-disable-line
require("codemirror/theme/material.css"); // eslint-disable-line

export default class TextEditor extends PureComponent {

	constructor(props) {
		super(props);
		const { content, onChange } = props;
		let editContent = content;
		if (!content || _.isEmpty(content)) {
			editContent = ``;

		}

		if (!_.isString(editContent)) {
			editContent = JSON.stringify(editContent, null, 4);
		}

		this.lastEdit = editContent;

		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}

		this.state = {
			editContent
		};
	}

	handleChangeEditContent(editContent) {
		this.setState({
			editContent
		});
		const { onChange } = this.props;
		if (onChange && _.isFunction(onChange)) {
			onChange(editContent);
		}
	}

	handleChangeValidity(valid, error) {
		const validity = {
			valid,
			error
		};
		this.setState({
			validity
		});

		const { onValidityChange } = this.props;
		if (onValidityChange && _.isFunction(onValidityChange)) {
			onValidityChange(validity);
		}
	}

	render() {
		const { editContent } = this.state;

		const codeMirrorOptions = {
			mode: "plain/text",
			theme: "default",
			height: "auto",
			viewportMargin: Infinity,
			lineNumbers: true,
			lineWrapping: false,
			indentWithTabs: false,
			tabSize: 2,
			styleActiveLine: true
		};

		return <CodeMirror
			value={editContent}
			options={codeMirrorOptions}
			onBeforeChange={(editor, data, value) => {
				this.lastEdit = value;
				this.handleChangeEditContent(value);
			}}
			className="stretch"
			// editorDidMount={editor => { this.instance = editor; }}
			editorDidMount={editor => { this.editor = editor; setTimeout(() => { editor.refresh(); }, 200); }}
		/>;

	}

}
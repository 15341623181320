
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branch } from "baobab-react/higher-order";
import Flexbox from "flexbox-react";
import _ from "lodash";
import React from "react";
import { Badge, Button, OverlayTrigger, Popover, MenuItem, DropdownButton } from "react-bootstrap";
// import ClampLines from "react-clamp-lines";
import Toggle from "react-bootstrap-toggle";
import { getUrlForValue } from "../../actions/drive";
import copy from "copy-to-clipboard";
import { showSuccess } from "../../actions/alertActions";


const ActivityCard = (props) => {

	// console.log("RENDER CARD", props);
	const {
		title,
		titleOverlay,
		selected,
		name,
		menuComponent,
		condensedMenuComponent,
		heroImageUrl,
		heroImage,
		genres,
		addCard,
		visible,
		privacy,
		orgPrivacy,
		iconShare,
		genre,
		description,
		onClick,
		onEdit,
		hideVisibility,
		showContributors,
		contributors,
		onPreview,
		shared,
		sharedWithType,
		authoringGranted,
		bodyComponents,
		showStatus,
		headerClassName,
		bodyClassName,
		activityId,
		subTitle,
		headerComponent,
		selectable,
		onSelect,
		trackData,
		dependency,
		requiredBy,
		workflowInfo,
		workflows,
		stages,
		owner,
		onTransitionActivityToStage,
		onPublish,
		onEditShares,
		publicationInfo,
		activityIdentityId
	} = props;

	let icon = "question";

	if (genres) {
		for (let i = 0; i < genres.length; i++) {
			let _genre = genres[i];
			if (genre === _genre.value) {
				icon = _genre.icon;
			}
		}
	}

	const publicPopover = (
		<Popover id="public-activity-popover" title="Public Activity">
			This activity can be discovered by searching the Public space.
		</Popover>
	);
	const visiblePopover = (
		<Popover id="visible-popover" title="Visible">
			Means that the activity will be shown in the list of available activities on the home screen.
		</Popover>
	);
	const invisiblePopover = (
		<Popover id="invisible-popover" title="Invisible">
			Means that the activity will <b>not</b> be shown in the list of available activities on the home screen.
		</Popover>
	);
	const orgPublicPopover = (
		<Popover id="public-in-org-popover" title="Public In Organization">
			This activity can be discovered by searching the Organization space.
		</Popover>
	);

	const sharedPopover = (
		<Popover id="shared-popover" title="Shared">
			This activity has been shared with one or more members of your organization.
		</Popover>
	);
	// const previewPopover = (
	// 	<Popover id="preview-popover" title="Preview">
	// 		Will show a preview of the selected activity, proviudes a quick glimpse.
	// 	</Popover>
	// );
	let sharedIdentityId = "";
	try {
		sharedIdentityId = activityIdentityId ? " (" + props.membersMap[activityIdentityId].displayName + ")." : ".";

	} catch (err) {
		console.error("ERROR", err.message);
	}

	const externalMessage = shared && sharedWithType === "self" ? "This activity was added by you via search." : "This activity has been shared with you by another member of the organization" + sharedIdentityId;
	const externalPopover = (
		<Popover id="external-popover" title="External">
			{externalMessage}
		</Popover>
	);


	let dependencyPopover = null;
	if (dependency && !_.isEmpty(requiredBy)) {
		dependencyPopover = (
			<Popover id="dependency-popover" title="Required By">
				This activity is a dependency and is required by:
				<ul>
					{
						requiredBy.map((req, idx) => {
							return <li key={`reqby-${activityId}-${idx}`}>{req.name}</li>;
						})
					}
				</ul>
			</Popover>
		);
	}

	let heroImageStyle = {};

	if (heroImageUrl || heroImage) {
		const url = getUrlForValue(heroImageUrl ? heroImageUrl : heroImage, activityId);
		// console.log("Got URL for card hero Image", url, activityId);
		heroImageStyle.backgroundImage = `url("${url}")`;
	} else if (!addCard) {
		heroImageStyle.backgroundImage = "url('/assets/defaultCard.jpg')";
	}


	let contributorsTooltip = "";
	if (showContributors && contributors) {
		let creator = "Creator:\n";
		let _contributors = "\n\nContributors:\n";
		if (contributors.length === 0) {
			contributorsTooltip = "There are currently no contributors.";
		} else {
			contributorsTooltip = contributorsTooltip + creator;
			contributorsTooltip = contributorsTooltip + (contributors[0] && contributors[0].nickname && contributors[0].nickname.length > 0 ? contributors[0].nickname : contributors[0].name);

			if (contributors.length > 1) {
				contributorsTooltip = contributorsTooltip + _contributors;
				for (let i = 1; i < contributors.length; i++) {
					let contributor = contributors[i].name;
					if (i < contributors.length - 1) {
						contributorsTooltip = contributorsTooltip + contributor + "\n";
					} else {
						contributorsTooltip = contributorsTooltip + contributor;
					}
				}
			}
		}
	}
	const popoverContributors = (
		<Popover id="popover-positioned-bottom" title="Contributors">
			{contributorsTooltip}
		</Popover>
	);

	const popoverWorkflowLock = (
		<Popover id="popover-positioned-bottom" title="Workflow Lock">
			You participate in the activities workflow but not in the current workflow stage, that means you still get to see the activity but actions may be limited.
		</Popover>
	);

	let ownerMessage = "You participate in the activities workflow but not in the current workflow stage, however as an owner you can perform all actions unimpeded.";
	if (workflowInfo && !workflowInfo.participatesInWorkflow) {
		ownerMessage = "You don't participate in the activities workflow, however as an owner you can perform all actions unimpeded.";
	}

	const popoverWorkflowLockOwner = (
		<Popover id="popover-positioned-bottom" title="Workflow Lock (Owner)">
			{ownerMessage}
		</Popover>
	);

	const trackDataComponent =
		<Toggle
			onClick={props.onTrackDataToggle}
			on={<div>Track Data ON</div>}
			off={<div>Track Data OFF</div>}
			size="xs"
			onstyle="success"
			offstyle="danger"
			active={trackData}
		/>;


	const titleClassName = (props.titleClassName) ? props.titleClassName : menuComponent && !condensedMenuComponent ? "title twoButtonsMenu" : onClick || onPreview ? "title twoButtons" : "title";
	let workflowStatus = null;
	if (workflowInfo && (workflowInfo.participatesInWorkflow || owner)) {
		const { workflowStagePermissions, workflowGuid, currentWorkflowStageGuid } = workflowInfo;
		const workflow = workflows[workflowGuid];
		const stageName = stages[currentWorkflowStageGuid] && stages[currentWorkflowStageGuid].name ? stages[currentWorkflowStageGuid].name : "?";
		const canTransition = owner || (workflowStagePermissions && workflowStagePermissions.transition);

		let stageComponent = stageName;
		if (canTransition && workflow.transitions && _.isArray(workflow.transitions)) {
			console.log("CAN TRANSITION", workflow);
			const transitions = [];
			workflow.transitions.forEach(transition => {
				if (transition.fromStageGuid === currentWorkflowStageGuid) {
					const { toStageGuid } = transition;
					const targetStage = stages[toStageGuid];
					const targetStageName = targetStage.name;
					if (targetStage) {
						transitions.push(<MenuItem eventKey={`transition-${currentWorkflowStageGuid}-${toStageGuid}`} onClick={() => onTransitionActivityToStage(activityId, toStageGuid, stageName, targetStageName)}>Send to {targetStageName}</MenuItem>);
					}
				}
			});
			stageComponent = <DropdownButton
				className="workflow-transition-menu"
				title={stageName}
				bsSize={"small"}
				id="workflow-transition-stage"
				dropup
				pullRight
			>
				{transitions}
			</DropdownButton>;
		}

		const workflowName = workflow && workflow.name ? workflow.name : "?";

		let workflowComponent = workflowName;
		if (onEditShares && _.isFunction(onEditShares)) {
			workflowComponent = <Button bsSize={"small"} bsStyle="link" onClick={onEditShares}><FontAwesomeIcon icon="pencil" /> {workflowName}</Button>;
		}

		workflowStatus = <div className="status" style={{ borderRadius: 0, display: "flex", flexDirection: "row", padding: 0, marginBottom: "3px", borderBottom: "1px solid gray", alignItems: "center", paddingBottom: "2px" }}>
			<FontAwesomeIcon icon="project-diagram" title={`This activity is part of the '${workflowName}' workflow.`} />
			{workflowInfo.workflowStageLock && !owner && <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverWorkflowLock}><Button className="status-button"><FontAwesomeIcon icon="lock" /></Button></OverlayTrigger>}
			{workflowInfo.workflowStageLock && owner && <OverlayTrigger trigger="click" rootClose placement="top" overlay={popoverWorkflowLockOwner}><Button className="status-button"><FontAwesomeIcon icon="lock-open" /></Button></OverlayTrigger>}{workflowComponent} &nbsp;<FontAwesomeIcon icon="caret-right" />&nbsp; {stageComponent}
		</div >;
	}

	const status = (showStatus) ? <div className="status">
		<div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

			{workflowStatus}
			<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					{showContributors ? <OverlayTrigger trigger="click"
						rootClose placement="top" overlay={popoverContributors}>
						<Button className="status-button"><FontAwesomeIcon icon="user" /></Button>
					</OverlayTrigger> : null}
					{hideVisibility ? null : visible ? (<OverlayTrigger
						trigger="click"
						rootClose
						placement="top"
						overlay={visiblePopover}
					><Button className="status-button"><FontAwesomeIcon icon={["far", "eye"]} /></Button></OverlayTrigger>) : (<OverlayTrigger
						trigger="click"
						rootClose
						placement="top"
						overlay={invisiblePopover}
					><Button className="status-button"><FontAwesomeIcon style={{ color: "red" }} icon="eye-slash" /></Button></OverlayTrigger>)}
					{privacy === "public" ? (
						<OverlayTrigger
							trigger="click"
							rootClose
							placement="top"
							overlay={publicPopover}
						>
							<Button className="status-button"><FontAwesomeIcon icon="globe" /></Button>
						</OverlayTrigger>
					) : null}
					{shared && !dependency && <OverlayTrigger
						trigger="click"
						rootClose
						placement="top"

						overlay={externalPopover}
					>
						<Button className="status-button"><FontAwesomeIcon icon={sharedWithType && sharedWithType === "self" ? "external-link-square" : "external-link"} /></Button>
					</OverlayTrigger>}
					{orgPrivacy === "public" ? <OverlayTrigger
						trigger="click"
						rootClose
						placement="top"
						overlay={orgPublicPopover}
					><Button className="status-button"><FontAwesomeIcon icon="building" /></Button></OverlayTrigger> : null}
					{iconShare ? <OverlayTrigger
						trigger="click"
						rootClose
						placement="top"
						overlay={sharedPopover}
					><Button className="status-button"><FontAwesomeIcon icon="share" /></Button></OverlayTrigger> : null}
					{dependency && <OverlayTrigger
						trigger="click"
						rootClose
						placement="top"

						overlay={dependencyPopover}
					>
						<Button className="status-button"><FontAwesomeIcon icon={"link"} /> <Badge>{requiredBy.length}</Badge></Button>
					</OverlayTrigger>}
					{(workflowInfo && !workflowInfo.participatesInWorkflow && _.isString(workflowInfo.publicationVersion)) && <span className="status-button publication"><FontAwesomeIcon icon={["far", "books"]} /><span>{workflowInfo.publicationVersion}</span></span>}
				</div>
				{(!dependency && ((!shared || authoringGranted))) && trackDataComponent}
			</div>
		</div>
	</div > : null;


	const displayTitle = title ? title : name ? name : "( No Title )";
	let titleComponent = displayTitle;
	// if (titleClassName === "title-player") {
	// 	titleComponent = <ClampLines
	// 		text={displayTitle}
	// 		lines="2"
	// 		ellipsis="..."
	// 		buttons={false} />;
	// }

	let publicationInfoComponent = null;
	if (publicationInfo && _.isPlainObject(publicationInfo)) {
		const { publicationVersion, publicationRevision, revision, revisionTs } = publicationInfo;
		const revisionTsFormatted = revisionTs ? " | " + new Date(revisionTs).toLocaleDateString() + " " + new Date(revisionTs).toLocaleTimeString() : "";
		publicationInfoComponent = <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", padding: "5px", paddingTop: 0, fontSize: "11px", color: "white" }}>{!publicationVersion && <>Revision: {revision}{revisionTsFormatted}<span style={{ marginLeft: "10px", fontSize: "8px" }}>{activityId}</span></>}{publicationVersion && <>Latest Publication: {publicationVersion} (rev: {publicationRevision})  {activityId}</>}</div>;
	}

	const _headerComponent = headerComponent ? headerComponent : <div className={headerClassName}>
		<div style={{ width: "100%" }} className={`card-title ${titleClassName}`}>
			{!titleOverlay &&
				<>
					<div style={{ flexDirection: "column", width: "calc(100% - 50px)" }}>
						{titleComponent}
						{/* {menuComponent &&
							<div style={{ color: "#666666", fontSize: "8px" }}>{activityId}</div>
						} */}
					</div>
					<div style={{ cursor: "pointer", position: "absolute", top: "0px", right: "5px" }} onClick={() => {
						const host = window.location.hostname.toLowerCase().replace("https://", "").split("/")[0];
						const port = window.location.port;
						const newTitle = (title && typeof title === 'string' || title instanceof String) ? title : name ? name : "( No Title )"
						console.log("!@#!@# HOST: ", host);
						copy(newTitle + "\n" + "https://" + host + ((port !== "") ? ":" + port : "") + "/activity/" + activityId);
						showSuccess("Activity Link", "The Activity Link...<br/><br/><b>" + newTitle + "<br/>" + "https://" + host + ((port !== "") ? ":" + port : "") + "/activity/" + activityId + "</b><br/><br/>" + "...has been copied to the clipboard.");
					}}>
						<FontAwesomeIcon style={{ color: "white" }} icon={["far", "share"]} />
					</div>
					{subTitle ? <div className="sub-title">{subTitle}</div> : ""}
				</>
			}
			<div className="overflow-fade" />
		</div>
		<div className="actionMenu">

			{onEdit && (!shared || authoringGranted) && <Button onClick={onEdit}><FontAwesomeIcon icon="pencil" /></Button>}
			{onPublish && _.isFunction(onPublish) && <Button onClick={onPublish}><FontAwesomeIcon icon={["far", "books"]} /></Button>}
			{onPreview && _.isFunction(onPreview) && <Button onClick={onPreview}><FontAwesomeIcon icon={["far", "eye"]} /></Button>}
			{/* {onPreview ? <OverlayTrigger trigger="click"
				rootClose placement="bottom" overlay={previewPopover}>
				<Button onClick={onPreview}><FontAwesomeIcon icon="eye" /></Button></OverlayTrigger> : null} */}
			{titleOverlay &&
				<Button onClick={() => {
					const host = window.location.hostname.toLowerCase().replace("https://", "").split("/")[0];
					const port = window.location.port;
					const newTitle = (title && typeof title === 'string' || title instanceof String) ? title : name ? name : "( No Title )"
					console.log("!@#!@# HOST: ", host);
					copy(newTitle + "\n" + "https://" + host + ((port !== "") ? ":" + port : "") + "/activity/" + activityId);
					showSuccess("Activity Link", "The Activity Link...<br/><br/><b>" + newTitle + "<br/>" + "https://" + host + ((port !== "") ? ":" + port : "") + "/activity/" + activityId + "</b><br/><br/>" + "...has been copied to the clipboard.");
				}}><FontAwesomeIcon icon={["far", "share"]} /></Button>
			}

			{!dependency && menuComponent}

		</div>
	</div>;

	return addCard ? (<div onClick={onClick} className="ambifi-card">
		<div className="addArea">
			<span><FontAwesomeIcon icon="plus-circle" /> Add Activity</span>
		</div>
	</div>) : (<div className="ambifi-card" onClick={onClick} style={heroImageStyle}>
		{_headerComponent}
		<div className="heroImage">
			<div className="description smooth-font-white">
				{titleOverlay && <div style={{ color: "white" }}>{titleOverlay}</div>}
				<div style={{ fontSize: "0.8em", fontWeight: "normal" }}>
					{description}
				</div>
			</div>
			{(!heroImageUrl && !heroImage) ? <FontAwesomeIcon style={{ color: "rgba(0,0,0,.5)" }} icon={icon} /> : null}
			{onSelect && <div onClick={onSelect} className={selectable ? "check-overlay selected" : "check-overlay"} style={{ zIndex: 500, opacity: 1, position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
				<Flexbox style={{ height: "100%" }} flexDirection="row" alignItems="center" justifyContent="center">
					<div style={{ display: selected ? "initial" : "none" }}><FontAwesomeIcon style={{ paddingBottom: 20, fontSize: 100, color: "orange" }} icon={["far", "check-circle"]} /></div>
					<div style={{ display: !selected ? "initial" : "none" }}><FontAwesomeIcon style={{ paddingBottom: 20, fontSize: 100, color: "orange" }} icon={["far", "circle"]} /></div>
				</Flexbox>
			</div>}

		</div>
		<div className={bodyClassName}>
			{bodyComponents}
			{publicationInfoComponent}
		</div>
		{status}

	</div>);
};

ActivityCard.defaultProps = {
	showStatus: true,
	headerClassName: "header",
	bodyClassName: "body",
	titleClassName: "title"
};

export default branch({
	workflows: ["workflowGuidToWorkflow"],
	stages: ["workflowStages"],
	membersMap: ["selectedOrg", "membersMap"]
}, ActivityCard);
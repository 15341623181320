import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	useMemo
} from "react";
// import ReactDOM from "react-dom";
// import styled from "styled-components";
import { WaveSurfer, WaveForm, Region, /*Marker*/ } from "wavesurfer-react";
import "./index.css";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min";
import MarkersPlugin from "wavesurfer.js/src/plugin/markers";
import { copyToClipboard } from "../../../utils/utils";

import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";

import AWS from "aws-sdk";

import { refreshCredentials } from "../../../utils/securityUtil";

import env from "../../../constants/env";

// const Buttons = styled.div`
//   display: inline-block;
// `;

// const Button = styled.button``;

/**
 * @param min
 * @param max
 * @returns {*}
 */
// function generateNum(min, max) {
// 	return Math.random() * (max - min + 1) + min;
// }

/**
 * @param distance
 * @param min
 * @param max
 * @returns {([*, *]|[*, *])|*[]}
 */
// function generateTwoNumsWithDistance(distance, min, max) {
// 	const num1 = generateNum(min, max);
// 	const num2 = generateNum(min, max);
// 	// if num2 - num1 < 10
// 	if (num2 - num1 >= 10) {
// 		return [num1, num2];
// 	}
// 	return generateTwoNumsWithDistance(distance, min, max);
// }

/**
 * Use formatTimeCallback to style the notch labels as you wish, such
 * as with more detail as the number of pixels per second increases.
 *
 * Here we format as M:SS.frac, with M suppressed for times < 1 minute,
 * and frac having 0, 1, or 2 digits as the zoom increases.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override timeInterval, primaryLabelInterval and/or
 * secondaryLabelInterval so they all work together.
 *
 * @param: seconds
 * @param: pxPerSec
 */
function formatTimeCallback(seconds, pxPerSec) {
	seconds = Number(seconds);
	var minutes = Math.floor(seconds / 60);
	seconds = seconds % 60;

	// fill up seconds with zeroes
	var secondsStr = Math.round(seconds).toString();
	if (pxPerSec >= 25 * 10) {
		secondsStr = (seconds * 1000).toFixed(0);
	} else if (pxPerSec >= 25 * 1) {
		secondsStr = (seconds * 1000).toFixed(0);
	}

	if (minutes > 0) {
		if (seconds < 10) {
			secondsStr = '0' + secondsStr;
		}
		return `${minutes}:${secondsStr}`;
	}
	return secondsStr;
}

/**
 * Use timeInterval to set the period between notches, in seconds,
 * adding notches as the number of pixels per second increases.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override formatTimeCallback, primaryLabelInterval
 * and/or secondaryLabelInterval so they all work together.
 *
 * @param: pxPerSec
 */
function timeInterval(pxPerSec) {
	var retval = 1;
	if (pxPerSec >= 25 * 100) {
		retval = 0.01;
	} else if (pxPerSec >= 25 * 40) {
		retval = 0.025;
	} else if (pxPerSec >= 25 * 10) {
		retval = 0.1;
	} else if (pxPerSec >= 25 * 4) {
		retval = 0.25;
	} else if (pxPerSec >= 25) {
		retval = 1;
	} else if (pxPerSec * 5 >= 25) {
		retval = 5;
	} else if (pxPerSec * 15 >= 25) {
		retval = 15;
	} else {
		retval = Math.ceil(0.5 / pxPerSec) * 60;
	}
	return retval;
}

/**
 * Return the cadence of notches that get labels in the primary color.
 * EG, return 2 if every 2nd notch should be labeled,
 * return 10 if every 10th notch should be labeled, etc.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override formatTimeCallback, primaryLabelInterval
 * and/or secondaryLabelInterval so they all work together.
 *
 * @param pxPerSec
 */
function primaryLabelInterval(pxPerSec) {
	var retval = 1;
	if (pxPerSec >= 25 * 100) {
		retval = 10;
	} else if (pxPerSec >= 25 * 40) {
		retval = 4;
	} else if (pxPerSec >= 25 * 10) {
		retval = 10;
	} else if (pxPerSec >= 25 * 4) {
		retval = 4;
	} else if (pxPerSec >= 25) {
		retval = 1;
	} else if (pxPerSec * 5 >= 25) {
		retval = 5;
	} else if (pxPerSec * 15 >= 25) {
		retval = 15;
	} else {
		retval = Math.ceil(0.5 / pxPerSec) * 60;
	}
	return retval;
}

/**
 * Return the cadence of notches to get labels in the secondary color.
 * EG, return 2 if every 2nd notch should be labeled,
 * return 10 if every 10th notch should be labeled, etc.
 *
 * Secondary labels are drawn after primary labels, so if
 * you want to have labels every 10 seconds and another color labels
 * every 60 seconds, the 60 second labels should be the secondaries.
 *
 * Note that if you override the default function, you'll almost
 * certainly want to override formatTimeCallback, primaryLabelInterval
 * and/or secondaryLabelInterval so they all work together.
 *
 * @param pxPerSec
 */
function secondaryLabelInterval(pxPerSec) {
	// draw one every 10s as an example
	return Math.floor(10 / timeInterval(pxPerSec));
}

function formatCursorTimeCallback(seconds) {
	seconds = Number(seconds);

	return "" + (seconds * 1000).toFixed(0) + " ms";
}


function AudioWaveform(props) {
	const [timelineVis/*, setTimelineVis*/] = useState(true);
	const [currentTime, setCurrentTime] = useState(0);
	const [playPauseLabel, setPlayPauseLabel] = useState("Play");

	// const [markers, setMarkers] = useState([
	// 	{
	// 		time: 5.5,
	// 		label: "V1",
	// 		color: "#ff990a",
	// 		draggable: true
	// 	},
	// 	{
	// 		time: 10,
	// 		label: "V2",
	// 		color: "#00ffcc",
	// 		position: "top"
	// 	}
	// ]);

	const plugins = useMemo(() => {
		return [
			{
				plugin: RegionsPlugin,
				options: { dragSelection: true }
			},
			timelineVis && {
				plugin: TimelinePlugin,
				options: {
					container: "#timeline",
					formatTimeCallback: formatTimeCallback,
					timeInterval: timeInterval,
					primaryLabelInterval: primaryLabelInterval,
					secondaryLabelInterval: secondaryLabelInterval,
					primaryColor: 'blue',
					secondaryColor: 'red',
					primaryFontColor: 'blue',
					secondaryFontColor: 'red'
				}
			},
			{
				plugin: MarkersPlugin,
				options: {
					markers: [{ draggable: true }]
				}
			},
			{
				plugin: CursorPlugin,
				options: {
					formatTimeCallback: formatCursorTimeCallback,
					showTime: true,
					opacity: 1,
					customShowTimeStyle: {
						'background-color': '#000',
						color: '#fff',
						padding: '2px',
						'font-size': '12px',
						'font-weight': "bold"
					}
				}
			}
		].filter(Boolean);
	}, [timelineVis]);

	// const toggleTimeline = useCallback(() => {
	// 	setTimelineVis(!timelineVis);
	// }, [timelineVis]);

	const [regions, setRegions] = useState([
		// {
		// 	id: "region-1",
		// 	start: 0.5,
		// 	end: 10,
		// 	color: "rgba(0, 0, 0, .5)",
		// 	data: {
		// 		systemRegionId: 31
		// 	}
		// },
		// {
		// 	id: "region-2",
		// 	start: 5,
		// 	end: 25,
		// 	color: "rgba(225, 195, 100, .5)",
		// 	data: {
		// 		systemRegionId: 32
		// 	}
		// },
		// {
		// 	id: "region-3",
		// 	start: 15,
		// 	end: 35,
		// 	color: "rgba(25, 95, 195, .5)",
		// 	data: {
		// 		systemRegionId: 33
		// 	}
		// }
	]);

	// use regions ref to pass it inside useCallback
	// so it will use always the most fresh version of regions list
	const regionsRef = useRef(regions);
	const wavesurferRef = useRef();

	useEffect(() => {
		regionsRef.current = regions;

		// Anything in here is fired on component unmount.
		return () => {
			if (wavesurferRef && wavesurferRef.current && props.src && props.src !== "") {
				wavesurferRef.current.stop();
			}
		}

	}, [regions, props.src]);


	const removeLastRegion = useCallback(() => {
		let nextRegions = [...regions];

		nextRegions.pop();

		setRegions(nextRegions);

		props.onRegionSelected(null);
	}, [regions, props]);

	const regionCreatedHandler = useCallback(
		(region) => {
			console.log("region-created --> region:", region);

			if (region.data.systemRegionId) return;

			props.onRegionSelected(null);

			setRegions([
				// ...regionsRef.current,
				{ ...region, data: { ...region.data, systemRegionId: -1 } }
			]);
		},
		[/*regionsRef*/props]
	);

	const handleWSMount = useCallback(
		(waveSurfer) => {
			let fileName = props.src;
			fileName = fileName.replace("https://public-assets.ambifi.com/", "public/");
			fileName = fileName.replace("https://private-assets.ambifi.com/", "private/");
			fileName = fileName.split("?")[0];

			// Get signed url of props.src
			refreshCredentials().then(() => {
				var s3 = new AWS.S3();
				var presignedGETURL = s3.getSignedUrl('getObject', {
					Bucket: env.s3.assetsBucket,
					Key: fileName, //filename
					Expires: 100 //time to expire in seconds
				});

				if (waveSurfer.markers) {
					waveSurfer.clearMarkers();
				}

				wavesurferRef.current = waveSurfer;

				if (wavesurferRef.current) {
					// For now trying to use url with params of v at end				
					// wavesurferRef.current.load(document.getElementById(props.audioElementId));
					wavesurferRef.current.load(presignedGETURL);
					// wavesurferRef.current.load(props.src.split("?")[0]);

					wavesurferRef.current.on("region-created", regionCreatedHandler);

					wavesurferRef.current.on("ready", () => {
						console.log("WaveSurfer is ready");
					});

					wavesurferRef.current.on("region-removed", (region) => {
						// props.onRegionSelected(null);
						console.log("region-removed --> ", region);
					});

					wavesurferRef.current.on("loading", (data) => {
						console.log("loading --> ", data);
					});

					wavesurferRef.current.on("finish", (data) => {
						setPlayPauseLabel("Play")
						console.log("finish --> ", data);
					});

					wavesurferRef.current.on("audioprocess", (data) => {
						setCurrentTime((data * 1000).toFixed(0));
						console.log("audioprocess --> ", data);
					});

					wavesurferRef.current.on("interaction", (data) => {
						console.log("CURRENT TIME:", wavesurferRef.current.getCurrentTime());
					});

					wavesurferRef.current.on("seek", (data) => {
						setCurrentTime((wavesurferRef.current.getCurrentTime() * 1000).toFixed(0));
						console.log("CURRENT TIME AFTER SEEK:", wavesurferRef.current.getCurrentTime());

						props.onCursorSelected(wavesurferRef.current.getCurrentTime());

						// showInfo("Playhead Time (ms)", "" + (wavesurferRef.current.getCurrentTime() * 1000).toFixed(0));
					});

					if (window) {
						window.surferidze = wavesurferRef.current;
					}
				}
			}).catch(err => {
			});

		},
		// [regionCreatedHandler, props.audioElementId]
		[regionCreatedHandler, /*props.src, */props]
	);

	// const handleWSMount2 = useCallback(
	// 	(waveSurfer) => {
	// 		if (waveSurfer.markers) {
	// 			waveSurfer.clearMarkers();
	// 		}

	// 		wavesurferRef.current = waveSurfer;

	// 		if (wavesurferRef.current) {
	// 			// For now trying to use url with params of v at end				
	// 			// wavesurferRef.current.load(document.getElementById(props.audioElementId));
	// 			wavesurferRef.current.load(props.src);
	// 			// wavesurferRef.current.load(props.src.split("?")[0]);

	// 			wavesurferRef.current.on("region-created", regionCreatedHandler);

	// 			wavesurferRef.current.on("ready", () => {
	// 				console.log("WaveSurfer is ready");
	// 			});

	// 			wavesurferRef.current.on("region-removed", (region) => {
	// 				props.onRegionSelected(null);
	// 				console.log("region-removed --> ", region);
	// 			});

	// 			wavesurferRef.current.on("loading", (data) => {
	// 				console.log("loading --> ", data);
	// 			});

	// 			wavesurferRef.current.on("finish", (data) => {
	// 				setPlayPauseLabel("Play")
	// 				console.log("finish --> ", data);
	// 			});

	// 			wavesurferRef.current.on("audioprocess", (data) => {
	// 				setCurrentTime((data * 1000).toFixed(0));
	// 				console.log("audioprocess --> ", data);
	// 			});

	// 			wavesurferRef.current.on("interaction", (data) => {
	// 				console.log("CURRENT TIME:", wavesurferRef.current.getCurrentTime());
	// 			});

	// 			wavesurferRef.current.on("seek", (data) => {
	// 				setCurrentTime((wavesurferRef.current.getCurrentTime() * 1000).toFixed(0));
	// 				console.log("CURRENT TIME AFTER SEEK:", wavesurferRef.current.getCurrentTime());

	// 				props.onCursorSelected(wavesurferRef.current.getCurrentTime());

	// 				// showInfo("Playhead Time (ms)", "" + (wavesurferRef.current.getCurrentTime() * 1000).toFixed(0));
	// 			});

	// 			if (window) {
	// 				window.surferidze = wavesurferRef.current;
	// 			}
	// 		}
	// 	},
	// 	// [regionCreatedHandler, props.audioElementId]
	// 	[regionCreatedHandler, /*props.src, */props]

	// );

	// const generateRegion = useCallback(() => {
	// 	if (!wavesurferRef.current) return;
	// 	const minTimestampInSeconds = 0;
	// 	const maxTimestampInSeconds = wavesurferRef.current.getDuration();
	// 	const distance = generateNum(0, 10);
	// 	const [min, max] = generateTwoNumsWithDistance(
	// 		distance,
	// 		minTimestampInSeconds,
	// 		maxTimestampInSeconds
	// 	);

	// 	const r = generateNum(0, 255);
	// 	const g = generateNum(0, 255);
	// 	const b = generateNum(0, 255);

	// 	setRegions([
	// 		...regions,
	// 		{
	// 			id: `custom-${generateNum(0, 9999)}`,
	// 			start: min,
	// 			end: max,
	// 			color: `rgba(${r}, ${g}, ${b}, 0.5)`
	// 		}
	// 	]);
	// }, [regions, wavesurferRef]);
	// const generateMarker = useCallback(() => {
	// 	if (!wavesurferRef.current) return;
	// 	const minTimestampInSeconds = 0;
	// 	const maxTimestampInSeconds = wavesurferRef.current.getDuration();
	// 	const distance = generateNum(0, 10);
	// 	const [min] = generateTwoNumsWithDistance(
	// 		distance,
	// 		minTimestampInSeconds,
	// 		maxTimestampInSeconds
	// 	);

	// 	const r = generateNum(0, 255);
	// 	const g = generateNum(0, 255);
	// 	const b = generateNum(0, 255);

	// 	setMarkers([
	// 		...markers,
	// 		{
	// 			label: `custom-${generateNum(0, 9999)}`,
	// 			time: min,
	// 			color: `rgba(${r}, ${g}, ${b}, 0.5)`
	// 		}
	// 	]);
	// }, [markers, wavesurferRef]);


	// const removeLastMarker = useCallback(() => {
	// 	let nextMarkers = [...markers];

	// 	nextMarkers.pop();

	// 	setMarkers(nextMarkers);
	// }, [markers]);

	// const shuffleLastMarker = useCallback(() => {
	// 	setMarkers((prev) => {
	// 		const next = [...prev];
	// 		let lastIndex = next.length - 1;

	// 		const minTimestampInSeconds = 0;
	// 		const maxTimestampInSeconds = wavesurferRef.current.getDuration();
	// 		const distance = generateNum(0, 10);
	// 		const [min] = generateTwoNumsWithDistance(
	// 			distance,
	// 			minTimestampInSeconds,
	// 			maxTimestampInSeconds
	// 		);

	// 		next[lastIndex] = {
	// 			...next[lastIndex],
	// 			time: min
	// 		};

	// 		return next;
	// 	});
	// }, [markers]);

	const play = useCallback(() => {
		if (wavesurferRef.current.isPlaying()) {
			setPlayPauseLabel("Play");
		} else {
			setPlayPauseLabel("Pause");
		}
		wavesurferRef.current.playPause();
	}, []);

	const handleRegionUpdate = useCallback((region, smth) => {
		console.log("region-update-end --> region:", region);
		console.log(smth);

		props.onRegionSelected(region);

		if (wavesurferRef.current.isPlaying()) {
			setPlayPauseLabel("Pause");
		} else {
			setPlayPauseLabel("Play");
		}
	}, [props]);

	return (
		<div className="App">
			{(props.src && props.src !== "") &&
				// {(props.audioElementId) &&
				<>
					<WaveSurfer plugins={plugins} onMount={handleWSMount}>
						<WaveForm id="waveform" /*hideCursor cursorColor="transparent" */ xhr={{
							cache: "default",
							mode: "cors",
							method: "GET",
							credentials: "include",
							headers: [
								{ key: "cache-control", value: "no-cache" },
								{ key: "pragma", value: "no-cache" }
							]
						}}>
							{/* <WaveForm id="waveform" hideCursor cursorColor="transparent" backend="MediaElement"> */}
							{regions.map((regionProps) => (
								<Region
									onUpdateEnd={handleRegionUpdate}
									key={regionProps.id}
									{...regionProps}
								/>
							))}
							{/* {markers.map((marker, index) => {
						return (
							<Marker
								key={index}
								{...marker}
								onClick={(...args) => {
									console.log("onClick", ...args);
								}}
								onDrag={(...args) => {
									console.log("onDrag", ...args);
								}}
								onDrop={(...args) => {
									console.log("onDrop", ...args);
								}}
							/>
						);
					})} */}
						</WaveForm>
						<div id="timeline" />
					</WaveSurfer>
					<ButtonToolbar style={{ marginTop: "15px" }}>
						<ButtonGroup>

							{/* <Button onClick={generateRegion}>Generate region</Button>
				<Button onClick={generateMarker}>Generte Marker</Button> */}
							<Button bsStyle="primary" style={{ marginRight: "5px" }} onClick={play}>{playPauseLabel}</Button>
							<Button bsStyle="primary" style={{ marginRight: "5px" }} onClick={() => { wavesurferRef.current.seekTo(0) }}>Seek to Start</Button>
							<Button bsStyle="primary" style={{ marginRight: "5px" }} onClick={() => { copyToClipboard("" + currentTime) }}>Copy {currentTime} ms</Button>
							<Button bsStyle="primary" disabled={regions.length === 0} onClick={removeLastRegion}>Remove Region</Button>
							{/*<Button onClick={removeLastMarker}>Remove last marker</Button>
				<Button onClick={shuffleLastMarker}>Shuffle last marker</Button>
				<Button onClick={toggleTimeline}>Toggle timeline</Button> */}
						</ButtonGroup>
					</ButtonToolbar>
					{/* <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
				<div style={{ marginLeft: "5px", fontSize: "12px" }}>{currentTime} ms</div>
			</div> */}
				</>
			}
		</div>
	);
}

export default AudioWaveform;

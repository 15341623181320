import React from "react";
import ReactAvatar from "react-avatar";
import ColorHash from "color-hash";

import { getDisplayName, getProfilePicture } from "../../actions/user";

const Avatar = ({ name, size, selectable, onSelect, image }) => {
	const colorHash = new ColorHash({ lightness: [0.4, 0.4, 0.4] });
	const backgroundColor = colorHash.hex(name);

	let props = {
		size,
		name,
		color: backgroundColor,
		fgColor: "#ffffff",
		round: true,
		title: name
	};

	if (selectable) {
		props.onClick = onSelect;
		props.style = {
			cursor: "pointer"
		};
	}

	let profilePicture;

	if (image && image !== "" && image !== "none") {
		profilePicture = image;
	} else if (getDisplayName() === name && getProfilePicture()) {
		profilePicture = getProfilePicture();
	}

	return (
		<>
			{profilePicture &&
				<img title={name} style={{ borderRadius: size + "px", width: size + "px", height: size + "px", objectFit: "cover" }} src={profilePicture} alt="Profile" />
				// <ReactAvatar {...props} src={profilePicture} />
			}
			{!profilePicture &&
				<ReactAvatar {...props} />
			}
		</>
	);
};

export default Avatar;
import React from "react";
import Modal from "react-responsive-modal";
import autoBind from "react-autobind";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImagesGenerate } from "../../ai/aiAssistant";

export default class AiImageGeneratorModal extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		autoBind(this);
		this.state = {
			spinning: false,
			imagePrompt: "",
			url: ""
		};
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		let component = <Modal classNames={{ modal: "custom-modal" }} open={this.props.open} onClose={this.props.onClose}>
			<h3 style={{ textAlign: "center" }}>AI Assistant - Image Generator</h3>
			<br />
			<div>
				<div style={{ display: "flex", marginBottom: "15px", flexDirection: "column", color: "gray" }}>
					<label>
						Image Prompt
					</label>
					<div>
						<textarea className="form-control" type="text" name="imagePrompt" placeholder="Describe the image you want generated" value={this.state.imagePrompt} onChange={this.handleChange} />
						<Button style={{ marginTop: 10 }} bsStyle="default" onClick={async () => {
							this.setState({
								spinning: true,
								url: ""
							});
							const url = await getImagesGenerate(this.state.imagePrompt);
							this.setState({
								spinning: false,
								url: url
							});
						}}><FontAwesomeIcon icon="magic" /> Generate Image</Button>
					</div>
				</div>
				{this.state.spinning &&
					<div style={{ margin: "15px" }}><FontAwesomeIcon
						icon='spinner'
						spin /> Working hard for you...</div>}
				{this.state.url !== "" &&
					<div>
						<img style={{ height: "400px", objectFit: "contain" }} src={this.state.url} alt={this.state.imagePrompt} />
					</div>
				}
				<div style={{ display: "flex", justifyContent: "center" }}>
					<div style={{ display: "flex", marginTop: 25 }}>
						<Button bsStyle="primary" onClick={this.props.onClose}>Close</Button>
					</div>
				</div>
			</div>
		</Modal>

		return component;
	}
}


import React, { PureComponent } from "react";
import { Button, Panel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmoothCollapse from "react-smooth-collapse";
import _ from "lodash";
import Glyphicon from "../app-icon";
import ErrorBoundary from "../errors/errorBoundary";

export class AmbifiBasicPanel extends PureComponent {
	constructor(props) {
		super(props);

		let parentExpanded = true;
		if (this.props.hasOwnProperty("collapsed") && this.props.collapsed) {
			parentExpanded = false;
		}

		this.state = {
			expanded: parentExpanded
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.collapsed !== this.props.collapsed) {
			this.setState({
				expanded: !nextProps.collapsed
			});
		}
	}

	render() {
		const { titleText, titleIcon, headerContent, children, collapsible = false, className = null, subTitle = null, anchor = null, id = null } = this.props;
		let headerComponent;
		let _headerContent = headerContent;
		if (!_headerContent) {
			let textComponent = (titleIcon) ? <span><FontAwesomeIcon icon={titleIcon} />&nbsp;&nbsp;{titleText}</span> : titleText;
			_headerContent = (
				<div className="row" style={styles.header}>
					<div className="col-xs-12 vcenter">
						{textComponent}

					</div>
					{subTitle && !_.isEmpty(subTitle) && <div className="col-xs-12 vcenter"><small>{subTitle}</small></div>}
				</div>
			);
		}

		if (collapsible) {

			const addtlHeaderProps = {};

			if (id) {
				addtlHeaderProps.id = id;
			}

			let chevronDown = <span><Glyphicon glyph="chevron-down" /></span>;
			let chevronUp = <span><Glyphicon glyph="chevron-up" /></span>;
			_headerContent = (
				<div className="row-eq-height disable-select" style={styles.collapsible} onClick={() => this.setState({ expanded: !this.state.expanded })} {...addtlHeaderProps}>
					<div className="col-xs-8 vcenter">
						{headerContent ? _headerContent : (titleIcon) ? <span><FontAwesomeIcon icon={titleIcon} />&nbsp;&nbsp;{titleText}</span> : titleText}
						{subTitle && !_.isEmpty(subTitle) && <div><small>{subTitle}</small></div>}
					</div>
					<div className="col-xs-4 vcenter" style={styles.collapsibleButtonContainer}>
						<Button /*onClick={() => this.setState({ expanded: !this.state.expanded })}*/ bsSize="small">{this.state.expanded ? chevronUp : chevronDown}</Button>
					</div>
				</div>
			);
		}


		headerComponent = (
			<Panel.Heading className="header">
				{_headerContent}
			</Panel.Heading>
		);


		let body = <Panel.Body className="body" style={styles.body}>
			{children}
		</Panel.Body>;
		if (collapsible) {
			body = <SmoothCollapse allowOverflowWhenOpen={true} className="body-collapse" expanded={this.state.expanded}>{body}</SmoothCollapse>;
		}

		let additionalProps = {
			className: "ambifi-panel basic "
		};
		if (className) {
			additionalProps.className += className;
		}

		if (anchor) {
			additionalProps.id = anchor;
		}

		return (
			<Panel  {...additionalProps}>
				<ErrorBoundary>
					{headerComponent}
					{body}
				</ErrorBoundary>
			</Panel>
		);
	}
}

const styles = {
	body: { paddingLeft: "0.5em", paddingRight: 0 },
	collapsible: { fontWeight: "bold", verticalAlign: "middle", cursor: "pointer" },
	collapsibleButtonContainer: { justifyContent: "flex-end" },
	header: { fontWeight: "bold" }
};

export default AmbifiBasicPanel;
import React, { Component } from "react";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import { saveAs } from "file-saver";
import _, { last } from "lodash";
import { Button, FormControl, FormGroup, Col, ControlLabel, Panel, ButtonGroup, MenuItem, DropdownButton, HelpBlock, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import { listDrive, moveFile, getResourceReference, createFolder, uploadFile, deleteFile, deleteFolder, moveFolder, copyFile, copyFolder, trimHierarchyToPrefix, filterHierarchy, setNodeActive, setNodeToggle, handleSwitchHierarchyMode, /*uploadAndOptimizeMediaFile, */getUrlForResource, getContent, uploadContent, showSpinner, hideSpinner, selectFolderAndListDrive, clearSelectionAndActiveTreeCursor, listDriveForSelectedFolder, setActiveTreeCursorForDriveGuid, getFullObjectPath } from "../../actions/drive";
import { showError, showConfirm } from "../../actions/alertActions";
import { getDriveRootPath } from "../../actions/activityActions";
import { AmbifiLoader } from "../shared/";
import ListView from "./views/listView";
import { getUrlForExternalResource } from "../../actions/drive";
import { getMediaType } from "../..//utils/mediaUtils";
import { getFileTypeIcon, validateUrl, handleOpenInNewTab, stripOrgIdFromDriveRootDirs, generateUUID } from "../../utils/utils";
import DriveContext from "./driveContext";
import {
	Breadcrumb
} from "react-bootstrap";
// import { isMediaOptimizationDisabled } from "../../actions/activityActions";
import styles from "./drive.module.css";
import { showMediaPreviewModal } from "../../actions/actions";

const RESERVED_FOLDER_NAMES = [
	"children",
	"parent",
	"path",
	"this"
];

function FieldGroup({ id, label, help, ...props }) {
	return (
		<FormGroup controlId={id}>
			<ControlLabel>{label}</ControlLabel>
			<FormControl {...props} />
			{help && <HelpBlock>{help}</HelpBlock>}
		</FormGroup>
	);
}

class Drive extends Component {

	constructor(props) {
		super(props);
		this.currentEditor = React.createRef();
		this.driveComponentId = generateUUID();
		this.state = {
			cursor: null,
			data: null,
			showCreateFolderModal: false,
			showUploadFileModal: false,
			rename: {},
			editorState: {
				content: "",
				validity: {
					valid: true,
					error: null
				}
			},
			filter: props.filter ? props.filter : "",
			driveInitialized: false
		};
		autoBind(this);
	}

	onToggle(node, toggled) {
		console.log("ON TOGGLE", node);
		const { cursor } = this.state;
		const { okCancel } = this.props;

		if (node.children) {
			const _toggled = (!node.hasOwnProperty("allowCollapse") || node.allowCollapse) ? toggled : true;
			// node.toggled = _toggled;
			setNodeToggle(node.guid, _toggled);
		}
		console.log("On toggle", cursor, node);
		if (cursor && cursor !== node) {
			console.log("Set cursor inactive", cursor);
			setNodeActive(cursor.guid, false);
		}
		setNodeActive(node.guid, true);

		// Capture last selected to make sure we communicate properly when a path is deleted that will upset the reference
		if (!okCancel) {
			// Don't send these events unless a file has been selected
			if (node.type === "file" && this.props.onFileSelected) {
				this.props.onFileSelected(node);
			} else if (node.type === "folder" && this.props.onFolderSelected) {
				this.props.onFolderSelected(node);
			} else if (this.props.onItemSelected) {
				this.props.onItemSelected(getResourceReference(node));
			}
		}

		this.setState({ cursor: node });
	}

	onSelect(node) {
		console.log("ON SELECT", node);
		const { cursor } = this.state;

		const { okCancel } = this.props;

		// Need to clear previous selection to prevent more than one node
		// being sleected
		clearSelectionAndActiveTreeCursor();

		if (cursor && cursor !== node) {
			setNodeActive(cursor.guid, false, null);
			// setPathActive(cursor._path, false);
		}
		setNodeActive(node.guid, true, node);
		// setPathActive(node._path, true);

		if (!okCancel) {
			// Don't send these events unless a file has been selected
			if (node.type === "file" && this.props.onFileSelected) {
				this.props.onFileSelected(node);
			} else if (node.type === "folder" && this.props.onFolderSelected) {
				this.props.onFolderSelected(node);
			} else if (this.props.onItemSelected) {
				this.props.onItemSelected(getResourceReference(node));
			}
		}

		this.setState({ cursor: node });


	}

	async onDblClick(node) {
		if (node.type === "folder") {
			this.handleSelectFolderAndListDrive(node.fullPath);
		}
	}

	onFilterChange(e) {
		const filter = e.target.value.trim();
		this.setState({
			filter: filter
		});
		filterHierarchy(filter);
	}

	handleStartMove() {
		const { cursor } = this.state;
		const { hierarchy } = this.props;
		this.setState({
			moving: true,
			moveInfo: {
				origin: _.cloneDeep(cursor),
				originParentFolderPath: hierarchy.fullPath
			}
		});
	}

	handleStartCopy() {
		const { cursor } = this.state;
		const { hierarchy } = this.props;
		this.setState({
			copying: true,
			copyInfo: {
				origin: _.cloneDeep(cursor),
				originParentFolderPath: hierarchy.fullPath
			}
		});
	}

	handleCancelMove() {
		this.setState({
			moving: false,
			moveInfo: null
		});
	}

	handleCancelCopy() {
		this.setState({
			copying: false,
			copyInfo: null
		});
	}

	handleMove() {
		const { cursor, moveInfo } = this.state;
		const { hierarchy } = this.props;
		if (hierarchy.type === "folder") {
			if (moveInfo.origin.type === "file") {
				const destinationPath = _.concat(hierarchy.path, moveInfo.origin.fileName).join("/");
				moveFile(moveInfo.origin.guid, destinationPath).then(() => {
					this.handleCancelMove();
				}).catch((err) => {
					showError("Move File", `Failed to move file ${moveInfo.origin.guid} to ${destinationPath}, reason: ${err}`);
				});
			} else {
				const destinationPath = _.concat(hierarchy.path, moveInfo.origin.name).join("/");
				moveFolder(moveInfo.origin, destinationPath).then(() => {
					this.handleCancelMove();
				}).catch((err) => {
					showError("Move Folder", `Failed to move folder ${moveInfo.origin.guid} to ${destinationPath}, reason: ${err}`);
				});
			}

		} else {
			showError("Move File", "Must be within a folder as a destination");
		}
	}

	handleCopy() {
		const { copyInfo } = this.state;
		const { hierarchy } = this.props;
		if (hierarchy.type === "folder") {
			if (copyInfo.origin.type === "file") {
				const destinationPath = _.concat(hierarchy.path, copyInfo.origin.fileName).join("/");
				copyFile(copyInfo.origin.guid, destinationPath).then(() => {
					this.handleCancelCopy();
				}).catch((err) => {
					showError("Copy File", `Failed to copy file ${copyInfo.origin.guid} to ${destinationPath}, reason: ${err}`);
				});
			} else {
				const destinationPath = _.concat(hierarchy.path, copyInfo.origin.name).join("/");
				copyFolder(copyInfo.origin, destinationPath).then(() => {
					this.handleCancelCopy();
				}).catch((err) => {
					showError("Copy Folder", `Failed to copy folder ${copyInfo.origin.guid} to ${destinationPath}, reason: ${err}`);
				});
			}
		} else {
			showError("Copy File", "Must be within a folder as a destination");
		}
	}

	handleShowCreateFolderModal() {
		this.setState({
			showCreateFolderModal: true
		});
	}

	handleHideCreateFolderModal() {
		this.setState({
			showCreateFolderModal: false
		});
	}

	handleCancelAddFolder() {
		this.handleHideCreateFolderModal();
		this.setState({
			createFolder: null
		});
	}

	handleChangeCreateFolderName(e) {
		let name = e.target.value;
		let validationError = null;
		let validationState = "success";
		if (name && _.indexOf(RESERVED_FOLDER_NAMES, _.toLower(name)) !== -1) {
			validationState = "error";
			validationError = `This is a reserved name, unable to name folder '${name}'`;
			name = null;
		}
		this.setState({
			createFolder: _.assign({}, this.state.createFolder, {
				name,
				validationState,
				validationError
			})
		});
	}

	handleAddFolder() {
		const { createFolder: createFolderInfo } = this.state;
		const { hierarchy } = this.props;

		// Get folder we're currently in
		if (hierarchy.type === "folder") {
			createFolder(`${hierarchy && hierarchy.path ? hierarchy.path.join("/") + "/" : ""}${createFolderInfo.name}/`).then(() => {
				this.handleCancelAddFolder();
			}).catch(err => {
				showError("Create Folder", "An error occurred while attempting to create the folder. Error: " + err);
			});
		} else {
			showError("Create Folder", "Must select a folder to create a new folder in.");
		}
	}

	handleSelectUploadFile(event) {
		if (event.target && event.target.files && event.target.files.length === 0) {
			return;
		}

		const errors = [];

		const { hierarchy } = this.props;

		// Get folder we're currently in
		if (hierarchy.type === "folder") {
			event.target.files.forEach(async (file) => {
				if (file.name && file) {
					// const func = isMediaOptimizationDisabled() ? await uploadFile : await uploadAndOptimizeMediaFile;
					const func = await uploadFile;
					func(hierarchy.path.join("/"), file).then(() => {
						document.getElementById("drive-upload").value = "";
						this.handleCancelUploadFile();
					}).catch(err => {
						errors.push("Upload File, an error occurred while attempting to upload the file. Error: " + err);
					});
				} else {
					errors.push("Upload File, there was an issue with the file.");
				}
			});

			if (errors.length > 0) {
				showError("Upload File", errors.join("\n"));
			}
		} else {
			document.getElementById("drive-upload").value = "";
			showError("Upload File", "Need to select a folder and specify a file to upload");
		}
	}

	handleShowUploadFile() {
		this.setState({
			showUploadFileModal: true
		});
		document.getElementById("drive-upload").click();
	}

	handleHideUploadFile() {
		this.setState({
			showUploadFileModal: false
		});
	}

	handleCancelUploadFile() {
		this.handleHideUploadFile();
		this.setState({
			uploadFile: null
		});
	}

	handleCancel() {
		const { onCancel } = this.props;
		if (onCancel) {
			onCancel();
		}
	}

	handleOk() {
		const { onOk } = this.props;
		const { cursor } = this.state;
		if (onOk) {
			onOk(cursor);
		}
	}

	handleRefresh() {
		this.setState({
			refreshDisabled: true
		});
		listDrive().then(() => {
			this.setState({
				refreshDisabled: false
			});
		}).catch(err => {
			showError("Refresh Drive", "An error occurred while attempting to refersh drive content:" + err, () => {
				this.setState({
					refreshDisabled: false
				});
			});
		});
	}

	handleSwitchMode(targetMode) {
		handleSwitchHierarchyMode(targetMode);
	}

	handleDelete() {
		const { cursor } = this.state;
		showConfirm("Are you sure you want to delete the selected " + cursor.type + "?", "Delete", () => {
			let targetDeletePath = cursor.path;
			targetDeletePath += cursor.type === "folder" ? "/" : "";
			console.log("Deleting " + cursor.type, targetDeletePath, cursor);
			const backupCursor = Object.assign({}, cursor);
			if (backupCursor.type === "folder") {

				this.setState({ cursor: null });
				deleteFolder(cursor).then(() => {
					if (backupCursor.type === "file" && this.props.onFileSelected) {
						this.props.onFileSelected(null);
					} else if (backupCursor.type === "folder" && this.props.onFolderSelected) {
						this.props.onFolderSelected(null);
					} else if (this.props.onItemSelected) {
						this.props.onItemSelected(null);
					}
				}).catch(err => {
					showError("Delete", "Failed to delete " + cursor.type + ", reason:" + err);
				});
			} else {
				console.log("Delete file", backupCursor);

				this.setState({ cursor: null });
				deleteFile(backupCursor.guid).then(() => {
					if (backupCursor.type === "file" && this.props.onFileSelected) {
						this.props.onFileSelected(null);
					} else if (backupCursor.type === "folder" && this.props.onFolderSelected) {
						this.props.onFolderSelected(null);
					} else if (this.props.onItemSelected) {
						this.props.onItemSelected(null);
					}
				}).catch(err => {
					console.error(err);
					showError("Delete", "Failed to delete " + cursor.type + ", reason:" + err);
				});
			}

		});
	}

	handleChangeNameRename(e) {
		this.setState({
			rename: _.assign({}, this.state.rename, {
				fileName: e.target.value
			})
		});
	}

	handleStartRename() {
		const { cursor } = this.state;
		console.log("Rename", _.assign({}, cursor, {

			fileNameNoExt: cursor.type === "folder" ? cursor.name : _.dropRight(cursor.name.split(".")).join("."),
			ext: cursor.type === "folder" ? null : _.last(cursor.name.split("."))
		}));
		this.setState({
			renaming: true,
			rename: _.assign({}, cursor, {
				fileName: cursor.name,
				fileNameNoExt: cursor.type === "folder" ? cursor.name : _.dropRight(cursor.name.split(".")).join("."),
				ext: cursor.type === "folder" ? null : _.last(cursor.name.split("."))
			})
		});
	}

	handleRename() {
		const { rename } = this.state;
		const { fileName } = rename;
		let destinationPath = null;
		if (rename.type === "folder") {
			destinationPath = _.dropRight(rename._path);
			destinationPath.push(fileName);
			console.log(destinationPath, rename._path);
			moveFolder(rename, destinationPath.join("/")).then((cursor) => {

				this.setState({ cursor });
				this.renderPreview();
				this.handleCancelRename();
			}).catch((err) => {
				showError("Rename Folder", `Failed to rename folder ${rename.name} to ${fileName}, reason: ${err}`);
			});
		} else if (rename.type === "file") {
			destinationPath = _.dropRight(rename._path);
			destinationPath.push(fileName);
			moveFile(rename.guid, destinationPath.join("/")).then((cursor) => {
				this.setState({ cursor });
				this.renderPreview(cursor);
				this.handleCancelRename();
			}).catch((err) => {
				showError("Rename File", `Failed to rename file ${rename.name} to ${fileName}, reason: ${err}`);
			});
		} else {
			throw new Error("Must be a file or folder that is getting renamed");
		}

	}

	handleDownloadFile() {
		const { cursor } = this.state;
		if (cursor) {
			const url = getUrlForResource(cursor);
			console.log("Download ", cursor, url);
			saveAs(url, cursor.name);
		}
	}

	handleCancelRename() {
		this.setState({
			renaming: false,
			rename: {}
		});
	}

	renderPreview(node = this.state.cursor) {
		console.log("Render preview", node);
		let preview = null;
		let addPreviewLarge = false;
		let url = null;
		let type = null;
		if (node && node.type === "file") {
			addPreviewLarge = true;
			url = getUrlForExternalResource(node);
			type = getMediaType(node.ext);

			switch (type) {
				case "video": {
					preview = url ? <ReactPlayer url={url} width="100%" height="100%" playing={false} playsInline={true} muted={false} loop controls /> : null;
					break;
				}
				case "audio": {
					preview = url ? <ReactAudioPlayer src={url} controls /> : null;
					break;
				}
				case "pdf": {
					if (url) {
						// result = <iframe style={{width:"100%", height:"60.4vw"}} src={url} />;
						preview =
							<object className="pdf" data={url} type="application/pdf" internalinstanceid="9" title="">
								<p>Your browser isn't supporting embedded pdf files. You can download the file
									<a href={url} target="_blank">here</a>.</p>
							</object>;
						// preview = <FontAwesomeIcon icon="file-pdf" />;
						// result = <Document
						// 	style={{width:"100%"}}	
						// 	file={_url}
						// 	onLoadSuccess={this.onDocumentLoad}
						// >
						// 	<Page pageNumber={1} scale={0.5} />
						// </Document>;
					}
					break;
				}
				case "threeD": {
					preview = url && validateUrl(url) ? <div>3D preview will go here</div> : null;
					break;
				}
				case "image": {
					preview = url && validateUrl(url) ? <img alt="Preview" src={url} /> : null;
					break;
				}
				default: {
					addPreviewLarge = false;
					preview = <FontAwesomeIcon style={{ fontSize: "10vw" }} icon={getFileTypeIcon(node.ext, node)} />;
				}
			}
		}

		let result = preview;
		if (addPreviewLarge) {
			result = [
				preview,
				<div className="open-large" onClick={() => { /*handleOpenInNewTab(url);*/ this.props.dispatch(showMediaPreviewModal, url); }}><FontAwesomeIcon icon="expand-wide" /></div>
			];
		}

		return result;
	}

	handleEditDocument() {
		const { cursor } = this.state;
		if (!cursor) {
			showError("Invalid Selection", "Please make sure to select a file for editing.");
			return;
		}
		showSpinner();
		setTimeout(() => {
			getContent(cursor.guid).then(editingContent => {
				this.setState({
					editing: true,
					editingContent,
					editingInfo: {
						newDoc: false,
						type: cursor.type
					},
					captureNewDocumentInfo: false
				});
				hideSpinner();
			}).catch(err => {
				showError("Edit Document", "An error occurred while attempting to open the document for editing: " + err);
				hideSpinner();
			});
		});

	}

	handleCancelEditDocument() {
		this.setState({
			editingContent: null,
			editing: false,
			captureNewDocumentInfo: false
		});
	}

	// handleSaveEdit(closeDialog = false) {

	// }

	handleStartCreateNewDocument() {
		this.setState({
			editing: true,
			editingInfo: {
				newDoc: true,
				name: null,
				type: null
			},
			captureNewDocumentInfo: true
		});
	}

	handleSetStartCreateNewDocumentName(e) {
		const _state = _.cloneDeep(this.state);
		_.set(_state, "editingInfo.name", e.target.value);
		this.setState(_state);
	}

	handleSetStartCreateNewDocumentType(type) {
		const _state = _.cloneDeep(this.state);
		_.set(_state, "editingInfo.type", type);
		this.setState(_state);
	}

	handleCancelAddNewDocument() {
		this.setState({
			editContent: null,
			editingInfo: null,
			captureNewDocumentInfo: false
		});
	}

	handleEditNewDocument() {
		this.setState({
			captureNewDocumentInfo: false
		});
	}

	handleEditorContentChange(content) {
		console.log("ON CONTENT CHANGE", content);
		const editorState = _.clone(this.state.editorState);
		editorState.content = content;
		this.setState({ editorState });
	}

	handleEditorContentValidityChange(validity) {
		const editorState = _.clone(this.state.editorState);
		editorState.validity = validity;
		this.setState({ editorState });
	}

	handleSave() {
		const { cursor, editorState, editingInfo } = this.state;
		const { hierarchy } = this.props;
		const { content } = editorState;
		const { name, type, newDoc } = editingInfo;

		if (newDoc) {

			if (hierarchy.type !== "folder") {
				showError("Create File", "Unable to create file, expected a drive folder to be selected.");
				return;
			}

			//Editing for now is mostly for JSON files, so hard coding this for now
			//if we need to be smarter about extensions then add a switch statement for 
			if (!type || !type.extension) {
				showError("Create File", "The selected document type doesn't contain information about the file extension, unable to create document.");
				return;
			}

			if (!content || _.isEmpty(content)) {
				showError("Create File", "There is not content, make sure to author some content prior to attempting to save.");
				return;
			}

			const { extension, contentType } = type;

			// Create path from current drive folder location
			const path = `${hierarchy.path.join("/")}/${name.replace(`.${extension}`, "")}.${extension}`;
			uploadContent(path, content, contentType, {
				type: type.type
			}).then(() => {
				this.handleCancelEditDocument();
			});
		} else {
			// Edited doc!
			if (cursor.type !== "file") {
				showError("Save File", "Unable to save file, expected a file to be selected.");
				return;
			}

			const { contentType } = type;
			const { path } = cursor;
			uploadContent(path, content, contentType).then(() => {
				this.handleCancelEditDocument();
			}); // No need to update metaData here, we already have the information we need (file type)
		}
	}

	handleSelectFolderAndListDrive(path) {
		clearSelectionAndActiveTreeCursor();
		selectFolderAndListDrive(path);
		this.setState({ cursor: null });
		this.setState({
			filter: ""
		});
	}

	handleSelectActivityDriveRootFolder() {
		let path = getDriveRootPath(true);
		if (path) {
			let fullPath = getFullObjectPath("private/activities/" + path + "/");

			if (fullPath !== this.props.selectedFolder) {
				selectFolderAndListDrive(fullPath);
			}
		}
	}

	renderBreadcrumbs() {

		let { selectedFolder } = this.props;
		let breadcrumbItems = [];

		if (selectedFolder) {
			let folderSlugs = selectedFolder.split("/");
			let currentPath = "";
			let breadcrumbData = [];

			// Need to do two passes, due to concatenation onClick will only use last folder path 
			// if done in a single loop
			folderSlugs.map((folderSlug, i) => {
				let name;
				let folderPath;

				// skip over public / private root folders to concatenate orgId
				if (i > 0 && folderSlug) {
					if (i === 1) {
						folderPath = folderSlugs[0] + "/" + folderSlugs[1] + "/";
						name = folderSlugs[0];
					} else {
						folderPath = folderSlugs[i] + "/";
						name = folderSlugs[i];
					}

					let active = false;
					if (i === folderSlugs.length - 2) {
						active = true;
					}

					currentPath += folderPath;
					breadcrumbData.push({
						name,
						folderPath: currentPath,
						active
					})
				}
			});

			breadcrumbData.map((data) => {
				breadcrumbItems.push(
					<Breadcrumb.Item active={data.active} onClick={data.active ? null : () => this.handleSelectFolderAndListDrive(data.folderPath)}>{data.name}</Breadcrumb.Item>
				)
			})
		}

		let breadcrumbs =
			<div className={styles["breadcrumb-container"]}>
				<Breadcrumb>
					<Breadcrumb.Item active={!selectedFolder ? true : false} onClick={() => this.handleSelectFolderAndListDrive("")}>Drive</Breadcrumb.Item>
					{breadcrumbItems}
				</Breadcrumb>
			</div>

		return breadcrumbs;
	}

	render() {
		const { cursor, moving, copying, editing, editingInfo, editingContent, copyInfo, moveInfo } = this.state;
		const { validity } = this.state.editorState;
		const { inline, edit, showSpinner, hideSearchInput, hideActionBar, okCancel, actionBarTop, prefix, hierarchy, settings, handleQuickUpload, acceptType, value } = this.props;
		console.log("render drive, value=", value, hierarchy);
		if (!hierarchy) {
			return (
				<div style={{ width: "100%" }}>
					<div>Loading files...please be patient.</div>
					<AmbifiLoader show={showSpinner} fullPage={!inline} panel={inline} />
				</div>
			)

		}

		const buttons = [];
		let deleteButton = null;
		let actionBar = null;
		let okCancelBar = null;


		const { editors } = this.props;
		let editor = null;
		if (editors && cursor) {
			const { metaData, ext } = cursor;
			if (metaData) {
				const { type } = metaData;
				if (type && editors[type]) {
					editor = editors[type];
				}
			}
			const extesnionKey = `extension/${ext}`;
			if (!editor && ext && editors[extesnionKey]) {
				editor = editors[extesnionKey];
			}
		}

		console.log("Render cursor", cursor, editor);

		let editType, editName;
		if (editingInfo) {
			const { name, type } = editingInfo;
			editType = type;
			editName = name;
			if (type && type.component) {
				editor = type;
			}
		}

		const createMenuComponent = (title, icon, key, onClick, extraProps = {}) => {
			if (inline) {
				return <MenuItem key={key} onClick={onClick} {...extraProps}><FontAwesomeIcon icon={icon} /> {title}</MenuItem>;
			} else {
				return <Button key={key} onClick={onClick} {...extraProps}><FontAwesomeIcon icon={icon} /> {title}</Button>;
			}
		};

		const handleQuickUploadButton = createMenuComponent("Upload File", "upload", "drive-upload-file-button", () => { handleQuickUpload(false); });
		if (/*cursor && */ edit && !hideActionBar) {
			if (moving || copying) {

				let selectedFolderSameOrigin = false;
				if (moving) {
					if (moveInfo.originParentFolderPath === hierarchy.fullPath) selectedFolderSameOrigin = true;
				} else {
					if (copyInfo.originParentFolderPath === hierarchy.fullPath) selectedFolderSameOrigin = true;
				}

				buttons.push(createMenuComponent(moving ? "Move Here" : "Paste Here", moving ? "check" : "paste", "drive-move-file-drop", moving ? this.handleMove : this.handleCopy, { disabled: hierarchy.type !== "folder" || (hierarchy.hasOwnProperty("allowCreate") && !hierarchy.allowCreate) || selectedFolderSameOrigin, bsStyle: "success" }));
				buttons.push(createMenuComponent("Cancel", "ban", "drive-cancel-move-file", moving ? this.handleCancelMove : this.handleCancelCopy));
			} else {

				/*
				 * Show buttons based on the folder you are in
				 */

				// Allowing folder creation is based current folder you are in
				const folderAllowCreate = hierarchy.type === "folder" && (!hierarchy.hasOwnProperty("allowCreate") || hierarchy.allowCreate);

				if (folderAllowCreate) {
					buttons.push(createMenuComponent("Create Folder", "folder", "drive-create-folder", this.handleShowCreateFolderModal));

					if (!_.isEmpty(editors)) {
						buttons.push(createMenuComponent("New", "plus-circle", "drive-new-file-button", this.handleStartCreateNewDocument));
					}

					buttons.push(createMenuComponent("Upload File", "upload", "drive-upload-file-button", this.handleShowUploadFile));
				}


				/*
				 * Show buttons based file / folder selected by cursor
				 */
				if (cursor) {
					if (cursor.type === "file") {
						buttons.push(createMenuComponent("Download", "download", "drive-download-file-button", this.handleDownloadFile, { bsStyle: "primary" }));
						// buttons.push(<buttonComponent bsStyle="primary" key="drive-download-file-button" onClick={this.handleDownloadFile}><FontAwesomeIcon icon="download" /> Download</buttonComponent>);
					}

					/*
						This button is driven by folder you are in
					if (cursor.type === "folder" && settings.mode === "tree") {
						if (folderAllowCreate) {
							buttons.push(createMenuComponent("Upload File", "upload", "drive-upload-file-button", this.handleShowUploadFile));
							// buttons.push(<buttonComponent key="drive-upload-file-button" onClick={this.handleShowUploadFile}><FontAwesomeIcon icon="upload" /> Upload File</buttonComponent>);
						}
					} else if (handleQuickUpload) {
						buttons.push(handleQuickUploadButton);
					}
					*/

					if (editor && cursor.type === "file") {
						buttons.push(createMenuComponent("Edit", "file-edit", "drive-edit-file-button", this.handleEditDocument));
						// buttons.push(<buttonComponent key="drive-edit-file-button" onClick={this.handleEditDocument}><FontAwesomeIcon icon="pencil" /> Edit</buttonComponent>);
					}

					/* 
						This button is driven by folder you are in

					} else if (folderAllowCreate && !_.isEmpty(editors)) {
						buttons.push(createMenuComponent("New", "plus-circle", "drive-new-file-button", this.handleStartCreateNewDocument));
						// buttons.push(<buttonComponent key="drive-new-file-button" onClick={this.handleStartCreateNewDocument}><FontAwesomeIcon icon="plus-circle" /> New</buttonComponent>);
					}
					*/

					if (!cursor.hasOwnProperty("allowMoveCopyRename") || cursor.allowMoveCopyRename) {
						const renameButton = createMenuComponent("Rename", "pencil", "drive-rename-button", this.handleStartRename);
						buttons.push(
							createMenuComponent("Copy", ["far", "copy"], "drive-copy-button", this.handleStartCopy),
							createMenuComponent("Move", "arrows", "drive-move-button", this.handleStartMove)
						);
						buttons.push(renameButton);
					}

					/*
					if (cursor.type === "folder") {
						if (!cursor.hasOwnProperty("allowCreate") || cursor.allowCreate) {
							buttons.push(createMenuComponent("Create Folder", "folder", "drive-create-folder", this.handleShowCreateFolderModal));
							// buttons.push(<buttonComponent key="drive-create-folder" onClick={this.handleShowCreateFolderModal}><FontAwesomeIcon icon="folder" /> Create Folder</buttonComponent>);
						}
					}
					*/

					if (!cursor.hasOwnProperty("allowDelete") || cursor.allowDelete) {
						deleteButton = createMenuComponent("Delete", "times", "drive-delete-button", this.handleDelete, { bsStyle: "danger" });
						// deleteButton = <buttonComponent bsStyle="danger" key="drive-delete-button" onClick={this.handleDelete}><FontAwesomeIcon icon="times" /> Delete</buttonComponent>;
					}
				}
			}
			const actionBarStyles = {
				display: "flex", justifyContent: "space-between", padding: "10px"
			};
			actionBarStyles[actionBarTop ? "borderBottom" : "borderTop"] = "1px solid rgba(0,0,0,.3)";


		} else if (settings.mode === "list" && handleQuickUpload) {
			buttons.push(handleQuickUploadButton);
		}

		// Delete should always be after quick upload
		if (deleteButton) {
			buttons.push(deleteButton);
		}

		// Add button to go to activity drive root if editing activity
		let viewActivityDriveRootButton;
		if (inline && this.props.editActivity) {
			viewActivityDriveRootButton = createMenuComponent("View Activity Root", "hdd", "drive-view-root-button", this.handleSelectActivityDriveRootFolder);
			buttons.push(viewActivityDriveRootButton);
		}

		actionBar = buttons && buttons.length > 0 ? !inline ? <ButtonGroup key="drive-copy-move-operations-group" style={{ marginBottom: "10px" }}>{buttons}</ButtonGroup> : <DropdownButton componentClass={InputGroup.Button} noCaret bsStyle="primary" pullRight title={<FontAwesomeIcon icon="ellipsis-v" />}>{buttons}</DropdownButton> : null;
		if (okCancel) {
			okCancelBar = (
				<div className="button-bar">
					<Button bsStyle="danger" onClick={this.handleCancel}><FontAwesomeIcon icon="ban" /> Cancel</Button>
					{(cursor && cursor.type === "file") && <Button bsStyle="success" disabled={!cursor} onClick={this.handleOk}><FontAwesomeIcon icon="check" /> OK</Button>}
				</div>
			);
		}

		console.log("RENDER DRIVE", cursor);

		return (
			<div className={`ambifi-drive ${inline ? "inline" : "full"}`}>
				<input multiple key={"drive-file-upload"} id="drive-upload" type="file" onChange={this.handleSelectUploadFile} style={{ display: "none" }} />
				{(!inline && actionBarTop) && actionBar}
				<div class="drive-header">
					{!hideSearchInput && <div className="input-group search-input" style={{ flex: 1 }}>
						<span className="input-group-addon">
							<FontAwesomeIcon icon="search" />
						</span>
						<input className="form-control"
							onChange={this.onFilterChange.bind(this)}
							placeholder="Search Drive"
							type="text"
							value={this.state.filter}
						/>
						<InputGroup.Button>
							<Button onClick={this.handleRefresh} disabled={this.state.refreshDisabled}><FontAwesomeIcon icon="sync" /></Button>
						</InputGroup.Button>
						<InputGroup.Button>
							<Button onClick={() => { this.handleSwitchMode(settings.mode === "tree" ? "list" : "tree"); }} disabled={this.state.refreshDisabled}><FontAwesomeIcon icon={settings.mode === "tree" ? "th-large" : "folder-tree"} /></Button>
						</InputGroup.Button>
						{inline && actionBar}
					</div>}
					{/* <Button onClick={this.handleRefresh} disabled={this.state.refreshDisabled}><FontAwesomeIcon icon="sync" /></Button>
					<Button onClick={() => { this.handleSwitchMode(settings.mode === "tree" ? "list" : "tree"); }} disabled={this.state.refreshDisabled}><FontAwesomeIcon icon={settings.mode === "tree" ? "th-large" : "folder-tree"} /></Button> */}

				</div>
				<div className="drive-breadcrumbs">
					{this.renderBreadcrumbs()}
				</div>
				<div className="drive-tree-wrapper">
					<div className="drive-tree" style={{ height: "100%" }} ref={(ref) => this.scrollParentRef = ref}>
						<DriveContext.Provider value={{ driveComponentId: this.driveComponentId }}>
							<ListView
								data={hierarchy}
								onSelect={this.onSelect}
								onDblClick={this.onDblClick}
								mode={settings.mode}
								accept={acceptType}
								scrollParentRef={this.scrollParentRef}
								driveComponentId={this.driveComponentId}
							/>
						</DriveContext.Provider>
					</div>
					{
						settings.mode === "tree" && cursor && cursor.type === "file" && <div className="sticky-preview">
							{this.renderPreview(cursor)}
						</div>
					}
				</div>
				<Modal
					open={this.state.showCreateFolderModal}
					onClose={this.handleCancelAddFolder} classNames={{ modal: "custom-modal" }} closeButton>
					<div>
						<h4>Create Folder</h4>
						<hr />
						<div className="map-modal-body-container">
							<FormGroup controlId="folder-name" validationState={this.state.createFolder && this.state.createFolder.validationState ? this.state.createFolder.validationState : null}>
								<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left" }}>
									Folder Name
								</Col>
								<Col sm={12}>
									<FormControl onChange={this.handleChangeCreateFolderName} type="text" />
									{this.state.createFolder && this.state.createFolder.validationState !== "success" &&
										<Panel bsStyle="danger">
											<Panel.Heading>{this.state.createFolder.validationError}</Panel.Heading>
										</Panel>
									}
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer-container">
							<Button onClick={this.handleCancelAddFolder} style={{ "margin-right": "10px" }}>Cancel</Button>
							<Button disabled={!this.state.createFolder || _.isEmpty(this.state.createFolder.name)} bsStyle="primary" onClick={this.handleAddFolder}>OK</Button>
						</div>
					</div>
					{/* <AmbifiLoader show={showSpinner} modal /> */}
				</Modal>
				<Modal
					open={!!this.state.renaming}
					onClose={this.handleCancelRename} classNames={{ modal: "custom-modal" }} closeButton>
					<div>
						<h4>Rename</h4>
						<hr />
						<div className="map-modal-body-container">

							<FormGroup controlId="folder-name" validationState={this.state.createFolder && this.state.createFolder.validationState ? this.state.createFolder.validationState : null}>
								<Col componentClass={ControlLabel} sm={12} style={{ textAlign: "left" }}>
									<i>{this.state.rename.name}</i> to:
								</Col>
								<Col sm={12}>
									<div style={{ display: "flex", alignItems: "center" }}>
										<FormControl autoFocus onFocus={(e) => {
											const value = e.target.value;
											if (!value) {
												return;
											}
											const indexPeriod = value.lastIndexOf(".");
											if (indexPeriod > -1) {
												e.target.setSelectionRange(0, indexPeriod);
												// const range = document.createRange();
												// range.selectNodeContents(e.target);
												// range.setStart(e.target, 0);
												// range.setEnd(e.target, indexPeriod);
												// const sel = window.getSelection();
												// sel.removeAllRanges();
												// sel.addRange(range);
											} else {
												e.target.select();
											}
										}} style={{ flex: 1, marginRight: "10px" }} value={this.state.rename.fileName} onChange={this.handleChangeNameRename} type="text" /><div></div>
									</div>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer-container">
							<Button onClick={this.handleCancelRename} style={{ "margin-right": "10px" }}>Cancel</Button>
							<Button disabled={!this.state.rename || _.isEmpty(this.state.rename.fileName)} bsStyle="primary" onClick={this.handleRename}>Rename</Button>
						</div>
					</div>
					{/* <AmbifiLoader show={showSpinner} modal /> */}
				</Modal>
				{(editing) && <Modal
					open={editing}
					onClose={this.handleCancelEditDocument} classNames={{ modal: "custom-modal templates" }} closeButton>
					{this.state.captureNewDocumentInfo && <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
						<div style={{ flex: 0 }}>
							<h3>New Document</h3>
							<hr />
						</div>

						<div className="map-modal-body-container" style={{ flex: 1, overflowY: "auto" }}>
							<FieldGroup
								id="formControlsDocumentName"
								type="text"
								label="Document Name"
								placeholder={editName}
								onChange={this.handleSetStartCreateNewDocumentName}
							/>
							<ControlLabel>Select Document Type</ControlLabel>
							<DropdownButton
								style={{ marginLeft: "10px" }}
								title={editType ? (
									<><FontAwesomeIcon icon={editType.icon} /> {editType.title}</>
								) : "Select a document type..."
								}>
								{
									editors && _.map(_.keys(editors), editorKey => {
										const editor = editors[editorKey];
										console.log("Mapping", editor);
										return <MenuItem onClick={() => { this.handleSetStartCreateNewDocumentType(editor); }} href="#/action-1"><FontAwesomeIcon icon={editor.icon} /> {editor.title}</MenuItem>;
									})
								}
								{/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
									<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
									<Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
							</DropdownButton>

						</div>
						<div className="modal-footer-container space-between" style={{ flex: 0 }}>
							<Button onClick={this.handleCancelEditDocument} style={{ "marginRight": "10px" }}><FontAwesomeIcon icon="ban" /> Cancel</Button>
							<Button bsStyle="primary" onClick={this.handleEditNewDocument}><FontAwesomeIcon icon="check" /> Ok</Button>
						</div>
					</div>}

					{!this.state.captureNewDocumentInfo && <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
						<div style={{ flex: 0 }}>
							<h3>Edit</h3>
							<hr />
						</div>
						<div className="map-modal-body-container" style={{ flex: 1, /*display: "flex", */overflowY: "auto" }}>
							{<editor.component ref={this.currentEditor} onChange={this.handleEditorContentChange} onValidityChange={this.handleEditorContentValidityChange} content={editingContent} />}
						</div>
						<div className="modal-footer-container space-between" style={{ flex: 0 }}>
							<Button onClick={this.handleCancelEditDocument}><FontAwesomeIcon icon="ban" /> Cancel</Button>
							<Button bsStyle="primary" onClick={this.handleSave} disabled={validity && !validity.valid}><FontAwesomeIcon icon="save" /> Save</Button>
						</div>
					</div>}
					{/* <AmbifiLoader show={showSpinner} modal /> */}
				</Modal>}
				<div className="drive-footer">
					{(!inline && !actionBarTop) && actionBar}
					{okCancelBar}
				</div>
				<AmbifiLoader show={showSpinner} fullPage={!inline} panel={inline} />
			</div>
		);
	}

	async UNSAFE_componentWillMount() {
		showSpinner();
	}

	async componentDidMount() {

		const { lastActiveGuid, hierarchyActiveTreeCursor } = this.props;

		if (!this.props.hierarchy) {
			await listDrive();
		} else if (this.props.hierarchy && this.props.hierarchy.fullPath !== this.props.selectedFolder) {
			// Refresh the selected folder, this occurs when the selected folder is changed 
			// outside of the drive component, such as when editing an activity changes the
			// selected folder to activity's drive root path.
			//
			// Since the drive component may or may not have been mounted, we use this check
			// to do the appropriate refresh on mount. This way, if we need to change the selected
			// folder outside of the drive component, we just set the ["drive", "selectedFolder"] property
			// and let the drive component call the proper refresh function.
			await listDriveForSelectedFolder();
		}

		// Set the tree cursor if we have a last active guid but no cursor, this will happen 
		// when setting the active node outside of the drive component such as clicking on the
		// browse button on an inline file picker
		if (lastActiveGuid && !hierarchyActiveTreeCursor) {
			setActiveTreeCursorForDriveGuid(lastActiveGuid);
		}

		hideSpinner();

		// Calling setState inside componentDidMount will call render, but does 
		// not update the DOM. That's why setTimeout is used to set the state
		// that says the drive is initialized so selected node can be scrolled into view.
		setTimeout(() => {
			this.setState({
				driveInitialized: true
			})
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.activeCursor && this.state.cursor === null) {
			console.log("SETTING CURSOR", nextProps.activeCursor);
			this.setState({
				cursor: nextProps.activeCursor
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { lastActiveGuid } = this.props;

		if (this.state.driveInitialized === true && prevState.driveInitialized === false) {
			this.scrollSelectedItemIntoView();
		}

		if (this.props.settings?.mode !== prevProps.settings?.mode) {
			this.scrollSelectedItemIntoView();
		}
	}

	scrollSelectedItemIntoView() {
		const { lastActiveGuid } = this.props;
		if (lastActiveGuid) {
			const el = window.document.getElementById(`drive-node-${this.driveComponentId}-${lastActiveGuid}`);
			if (el) {
				el.scrollIntoView({
					block: "center"
				});
			}
		}
	}
}

export default branch({
	hierarchy: ["drive", "hierarchy"],
	settings: ["drive", "settings"],
	showSpinner: ["drive", "showSpinner"],
	activeCursor: ["drive", "hierarchyActiveCursor"],
	editors: ["driveEditors", "editors"],
	filter: ["drive", "filter"],
	selectedFolder: ["drive", "selectedFolder"],
	lastActiveGuid: ["drive", "lastActiveGuid"],
	hierarchyActiveTreeCursor: ["drive", "hierarchyActiveCursor"],
	editActivity: ["tree", "root", "entity"]
}, Drive);
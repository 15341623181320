
import React from "react";
import { branch } from "baobab-react/higher-order";
import PropTypes from "baobab-react/prop-types";

import VideoCall from "../videoCall";

import VoxeetSdk from "@voxeet/voxeet-web-sdk";

class VideoCallContainer extends React.PureComponent {
	render() {
		return (
			<div style={{ position: "absolute", zIndex: 10000000 }}>
				<VideoCall
					conferenceAlias={this.props.videoCall.conferenceAlias}
					handleOnConnect={async (e) => {
						try {
							if (this.props.videoCall.invitedUsers.length > 0) {
								// console.log("!@!@!@ CONFERENCE", VoxeetSdk.conference.current);
								await VoxeetSdk.notification.invite(VoxeetSdk.conference.current, this.props.videoCall.invitedUsers);
							}
						} catch (err) {
							console.log("Error on Dobly Connect", err);
						}
					}}
					handleOnLeave={async () => {
						this.context.tree.set(["appState", "videoCall", "visible"], false);
					}}
				/>
			</div>
		);
	}
}

VideoCallContainer.contextTypes = {
	tree: PropTypes.baobab
};

export default branch({
	user: ["user"],
	urlParams: ["appState", "urlParams"],
	orgId: ["selectedOrg", "id"],
	orgName: ["selectedOrg", "name"],
	videoCall: ["appState", "videoCall"]
}, VideoCallContainer);


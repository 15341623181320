import React, { useState, useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
	const { onClick, show = false, styles = {}, delay = 10 } = props;
	const [countdown, setCountdown] = useState(delay);
	const [showControl, setShowControl] = useState(show);
	if (onClick && _.isFunction(onClick)) {
		useEffect(() => {
			if (countdown === 0) {
				setShowControl(true);
			} else {
				setTimeout(() => {
					setCountdown(countdown - 1);
				}, 1000);
			}
		}, [countdown]);
	}

	let _style = _.assign({ cursor: "pointer", width: "70px", height: "70px", position: "absolute", right: "10px", top: "10px", borderRadius: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "50px", color: "white", backgroundColor: "rgba(0,0,0,.5)" }, styles);
	let additionalProps = {};
	if (onClick && _.isFunction(onClick)) {
		additionalProps.onClick = onClick;
	}
	return showControl ? (
		<div style={_style} {...additionalProps}>
			<FontAwesomeIcon icon="times" />
		</div>
	) : null;


};
import _ from "lodash";
import state from "../state/state";
import PlayerPaths from "../constants/paths/playerPaths";

const PROP_CHILDREN = "children";
const PROP_GUID = "guid";
const PROP_ID = "id";

let pathCache = {};

export function getCurrentChecklistKeyPath(indices) {
	let result = PlayerPaths.CHECKLIST.slice();
	indices.forEach(entry => {
		result.push(PROP_CHILDREN, entry);
	});
	return result;
}

export function getObjectByIndices(listIndex, sectionIndex, itemIndex) {
	let item = state.get(getCurrentChecklistKeyPath([listIndex, sectionIndex, itemIndex]));
	if (item.hasOwnProperty(PROP_GUID)) {
		const itemOverrides = state.get(PlayerPaths.CHECKLIST_OVERRIDE(item.guid));
		if (itemOverrides) {
			item = _.assign({}, item, itemOverrides);
		}
	}
	return item;
}

export function getItemByIndices(listIndex, sectionIndex, itemIndex) {
	let item = state.get(getCurrentChecklistKeyPath([listIndex, sectionIndex, itemIndex]));
	if (item.hasOwnProperty(PROP_GUID)) {
		const itemOverrides = state.get(PlayerPaths.CHECKLIST_OVERRIDE(item.guid));
		if (itemOverrides) {
			item = _.assign({}, item, itemOverrides);
		}
	}
	return item;
}

function findObjectById(objectId) {
	return findObjectByPropValue(objectId, PROP_ID);
}

function findObjectByGuid(objectGuid) {
	return objectGuid ? findObjectByPropValue(objectGuid, PROP_GUID) : {};
}

function findObjectByPropValue(propValue, prop, basePath = [], skipCacheLookup = false) {
	if (!skipCacheLookup && pathCache[`${prop}:${propValue}`]) {
		console.log("CACHE HIT", prop, propValue);
		return state.get(pathCache[`${prop}:${propValue}`]);
	} else {
		const path = getCurrentChecklistKeyPath(basePath);

		let obj = state.get(path);
		console.log("Looking at path", prop, propValue, basePath, path, obj[prop]);
		if (obj) {
			if (obj[prop] === propValue) {
				console.log("Found it", propValue);
				pathCache[`${prop}:${propValue}`] = path;
				return obj;
			} else if (obj.hasOwnProperty(PROP_CHILDREN)) {

				let result = null;
				obj[PROP_CHILDREN].forEach((child, idx) => {
					if (!result) {
						const newPath = Object.assign([], basePath);
						newPath.push(idx);
						result = findObjectByPropValue(propValue, prop, newPath, true);
					}
				});
				return result;
			}
		}
	}
}

export function clearPathCaches() {
	pathCache = {};
}

export function getObjectById(objectId, providedNode = null) {
	let item = providedNode ? providedNode : findObjectById(objectId);
	if (item && item.hasOwnProperty(PROP_GUID)) {
		item = _.assign({}, item, getOverridesForGuid(item.guid));
	}
	return item;
}

export function getObjectByGuid(objectGuid, providedNode = null) {
	let item = providedNode ? providedNode : findObjectByGuid(objectGuid);
	if (item && item.hasOwnProperty(PROP_GUID)) {
		item = _.assign({}, item, getOverridesForGuid(item.guid));
	}
	return item;
}

export function setOverridePropertyForObject(obj, prop, value) {
	if (!obj || !obj.hasOwnProperty(PROP_GUID)) {
		throw new Error("Expected an object with a guid property.");
	}
	setOverrideProperty(obj.guid, prop, value);
}

export function setOverrideProperty(guid, prop, value) {
	if (!state.exists(PlayerPaths.CHECKLIST_OVERRIDE(guid))) {
		state.set(PlayerPaths.CHECKLIST_OVERRIDE(guid), {
			[prop]: value
		});
	} else {
		state.set(PlayerPaths.CHECKLIST_OVERRIDE_PROPERTY(guid, prop), value);
	}
}

export function getOverridesForGuid(guid) {
	let overrides = state.get(PlayerPaths.CHECKLIST_OVERRIDE(guid));
	return overrides ? overrides : {};
}
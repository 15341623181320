import AWS from "aws-sdk";
import _ from "lodash";
import request from "superagent";
import omitEmpty from "omit-empty";

import { refreshCredentials } from "../utils/securityUtil";
import { getJwtToken, getGroupMemberships, getIdentityId } from "../actions/user";
import { showError, showInfo, showSuccess } from "../actions/alertActions";
import * as dynamoDb from "../persistence/dynamoDb";
import { getKeyPrefix } from "../utils/utils";
import { saveJsonToS3 } from "../persistence/s3";
import { getMemberBySubForSelectedOrg, getOrgId, getGroupById } from "./orgsActions";
import { normalizeActivity, filterLearningActivities } from "../utils/utils";
import { clonePublicActivity, activityExists, activityIdExists, updateActivity } from "./inventoryActions";
import { reloadActivities } from "./playerActions";
import { getUrlForValue } from "./drive";
import { isActivityHasShares, scanForExternalDependencies } from "../utils/activity";

import { sendInventoryAndUserInfoMessage, postMessage } from "./mobileAppCommunicationActions";

import { isMobile } from "../utils/utils";

import env from "../constants/env";
import state from "../state/state";

import { createExperiencesInventoryFromActivities } from "../utils/activity";
import AppConstants from "../constants/appConstants";

const PUBLIC_KEY_PREFIX = "public";
export function addReferenceToActivity(hit, scope) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			let sharedArtifactOwnerId = PUBLIC_KEY_PREFIX;
			if (scope === "org") {
				sharedArtifactOwnerId = `${getOrgId()}/${PUBLIC_KEY_PREFIX}`;
			}

			const bucket = new AWS.S3({ useDualStack: true, params: { Bucket: env.s3.contentBucket, ResponseContentType: "application/json", ResponseCacheControl: "no-cache" } });
			bucket.getObject({ Key: `${sharedArtifactOwnerId}/${hit.id}.json` }, (err, data) => {
				if (err) {
					showError("Reference Activity", "Failed to add reference to activity, reason: " + err);
				} else {
					const activity = JSON.parse(data.Body.toString());

					let newActivity = {};
					newActivity.identityId = sharedArtifactOwnerId;
					newActivity.id = activity.id;
					newActivity.guid = activity.guid;
					if (activity.checklistId && !_.isEmpty(activity.checklistId)) {
						newActivity.checklistId = activity.checklistId;
					}

					newActivity.name = activity.name;
					newActivity.description = activity.description;
					newActivity.genre = activity.genre;
					newActivity.tags = activity.tags;
					newActivity.firstLevel = activity.firstLevel;
					newActivity.publisher = activity.publisher;
					newActivity.shareCode = activity.shareCode ? activity.shareCode : "";
					newActivity.shareCodeReceived = activity.shareCodeReceived ? activity.shareCodeReceived : "";
					newActivity.version = activity.version ? activity.version : "1.0";
					newActivity.revision = activity.revision ? activity.revision : 1;
					newActivity.heroImage = activity.heroImage ? activity.heroImage : "";
					newActivity.heroImageUrl = activity.heroImageUrl ? activity.heroImageUrl : "";
					newActivity.logoImage = activity.logoImage ? activity.logoImage : "";
					newActivity.logoImageSmall = activity.logoImageSmall ? activity.logoImageSmall : "";
					newActivity.driveRootPath = activity.driveRootPath ? activity.driveRootPath : "";
					newActivity.cloned = activity.cloned;
					newActivity.visible = true;
					newActivity.speedType = activity.speedType;
					newActivity.contributors = activity.contributors;
					newActivity.sharedArtifactOwnerId = sharedArtifactOwnerId;
					newActivity.sharedWithType = "self";
					newActivity.externalResources = activity.externalResources;

					const addStatement = buildActivityShareDataStructure(getIdentityId(), newActivity);
					const documents = state.select(["activities"]);
					addStatement.sharedArtifactOwnerId = sharedArtifactOwnerId;
					addStatement.sharedWithType = "self";

					console.log(addStatement);
					if (!activityExists(activity.name)) {
						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.share}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify(addStatement)).then(res => {

								if (isMobile()) {
									showSuccess("Add Activity", "The Activity has been added to My Activities.\n\nClick Go Back to return from Search and click the Sync button in the bottom toolbar to refresh.", () => {
										// showSuccess("Add Activity", "The Activity has been added to My Activities.\n\nClick Go Back to return from Search.\n\nYour My Activities should refresh with the new activity. If you don't see it after some time click the Sync button in the bottom toolbar.", () => {
										// sendInventoryAndUserInfoMessage().then(() => {
										// }).catch((err) => {
										// 	postMessage({ "error": "Get checklists failed: " + err.message });
										// });

										documents.push(newActivity);
										reloadActivities(state);

										resolve(res.body);
									});

								} else {
									showSuccess("Add Activity", "The Activity has been added to My Activities.", () => {
										documents.push(newActivity);
										reloadActivities(state);

										resolve(res.body);
									});
								}
							}).catch(err => {
								console.error(err);
								showError("Add Activity", "Unable to add the Activity to your activities, reason:" + err.message, () => {
									reject(err);
								});
							});
					} else if (!activityIdExists(activity.id)) {
						window.confirm("An activity of the same name already exists in your My Activities. Do you still want to add it?", () => {
							request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.share}`)
								.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
								.send(JSON.stringify(addStatement)).then(res => {
									if (isMobile()) {
										showSuccess("Add Activity", "The Activity has been added to My Activities.\n\nClick Go Back to return from Search and click the Sync button in the bottom toolbar to refresh.", () => {
											// sendInventoryAndUserInfoMessage().then(() => {
											// }).catch((err) => {
											// 	postMessage({ "error": "Get checklists failed: " + err.message });
											// });

											documents.push(newActivity);
											reloadActivities(state);

											resolve(res.body);
										});

									} else {
										showSuccess("Add Activity", "The Activity has been added to My Activities.", () => {
											documents.push(newActivity);
											reloadActivities(state);

											resolve(res.body);
										});

									}
								}).catch(err => {
									console.error(err);
									showError("Add Activity", "Unable to add the Activity to your activities, reason:" + err.message, () => {
										reject(err);
									});
								});
						});
					} else {
						showError("Add Activity", "Unable to add the Activity. It is already in your inventory.", () => {
							resolve();
						});
					}

				}
			});

		}).catch((err) => {
			console.error(err);
			showError("Activity not added", err);
		});
		// const addStatement = buildActivityShareDataStructure(getIdentityId(), checklistMetadata);

		// console.log(addStatement);
	});
	// return new Promise((resolve, reject) => {
	// 	refreshCredentials().then(() => {
	// 		request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.share}`)
	// 			.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
	// 			.send(JSON.stringify(toAdd)).then(res => {
	// 				resolve(res.body);
	// 			}).catch(err => {
	// 				console.error(err);
	// 				reject(err);
	// 			});
	// 	});
	// });

}

export function removeReferenceToActivity(activityId) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.removeShare}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify({
					sharedArtifactId: activityId,
					sharedWithId: getIdentityId()
				})).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				});
		});
	});
}

function getArrayOfIds(input) {
	let result = [];

	if (_.isArray(input)) {
		result = input;
	} else if (_.isString(input)) {
		result = _.without(input.split(","), "");
	}

	return result;
}

function showSpinner() {
	state.set(["appState", "documents", "showSpinner"], true);
}

function hideSpinner() {
	state.set(["appState", "documents", "showSpinner"], false);
}

export function shareActivity2(initialGroups, initialMembers, groups, members, groupAuthoringGrants, memberAuthoringGrants, checklistMetadata) {
	return new Promise(async (resolve, reject) => {
		console.log(initialGroups, initialMembers, groups, members, groupAuthoringGrants, memberAuthoringGrants, checklistMetadata);
		const arrInitialGroups = getArrayOfIds(initialGroups);
		let arrGroups = [];
		if (_.isArray(groups)) {
			arrGroups = _.map(groups, group => {
				return group.value;
			});
		} else {
			arrGroups = getArrayOfIds(groups);
		}

		const authoringGrants = [];
		groupAuthoringGrants.forEach(groupAuthoringGrant => {
			authoringGrants.push(groupAuthoringGrant.value);
		});
		memberAuthoringGrants.forEach(memberAuthoringGrant => {
			authoringGrants.push(memberAuthoringGrant.value);
		});

		const arrInitialMembers = getArrayOfIds(initialMembers);
		let arrMembers = [];
		if (_.isArray(members)) {
			arrMembers = _.map(members, member => {
				return member.value;
			});
		} else {
			arrMembers = getArrayOfIds(members);
		}

		const membersToAdd = _.difference(arrMembers, arrInitialMembers);
		const membersToRemove = _.difference(arrInitialMembers, arrMembers);
		const groupsToAdd = _.difference(arrGroups, arrInitialGroups);
		const groupsToRemove = _.difference(arrInitialGroups, arrGroups);

		const toAdd = [];
		const toRemove = [];

		membersToAdd.forEach(memberToAdd => {
			toAdd.push(buildActivityShareDataStructure(memberToAdd, checklistMetadata));
		});
		groupsToAdd.forEach(groupToAdd => {
			toAdd.push(buildActivityShareDataStructure(groupToAdd, checklistMetadata, "group"));
		});
		membersToRemove.forEach(memberToRemove => {
			toRemove.push(buildActivityRemoveShareDataStructure(memberToRemove, checklistMetadata.id, checklistMetadata.identityId));
		});
		groupsToRemove.forEach(groupToRemove => {
			toRemove.push(buildActivityRemoveShareDataStructure(groupToRemove, checklistMetadata.id, "group", "group"));
		});

		const needsAdd = !_.isEmpty(toAdd);
		const needsRemove = !_.isEmpty(toRemove);
		const updatePromises = [];
		if (needsAdd) {
			updatePromises.push(new Promise((resolve, reject) => {
				refreshCredentials().then(() => {
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.share}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(toAdd)).then(res => {
							resolve(res.body);
						}).catch(err => {
							reject(err);
						});
				});
			}));

			// Send emails
			// For new test on CCHS Crew
			if (getOrgId() === "eb1f8267-139a-11ee-b77b-0621251bbe8f" || getOrgId() === "3c2c0579-2bdc-11e9-a392-061714f61e3e") {
				window.confirm("Do you want to send notifications to any new users?", async () => {
					try {
						showSpinner();

						setTimeout(async () => {
							await sendAddEmail(toAdd, checklistMetadata.id, checklistMetadata.name, checklistMetadata.description, arrInitialGroups, arrInitialMembers);
						}, 200);

						hideSpinner();
					} catch (err) {
						hideSpinner();
						alert("Error sending notification: ", err.message);
					}
				});
			}
		}

		if (needsRemove) {
			updatePromises.push(new Promise((resolve, reject) => {
				refreshCredentials().then(() => {
					request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.removeShare}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(toRemove)).then(res => {
							resolve(res.body);
						}).catch(err => {
							reject(err);
						});
				});
			}));

			// Send emails
			// For new test on CCHS Crew
			if (getOrgId() === "eb1f8267-139a-11ee-b77b-0621251bbe8f" || getOrgId() === "3c2c0579-2bdc-11e9-a392-061714f61e3e") {
				window.confirm("Do you want to send notifications to users being removed?", async () => {
					try {
						showSpinner();

						setTimeout(async () => {
							await sendRemoveEmail(toRemove, checklistMetadata.name, arrInitialGroups, arrInitialMembers);
						}, 200);

						hideSpinner();
					} catch (err) {
						hideSpinner();
						alert("Error sending notification: ", err.message);
					}
				});
			}
		}

		Promise.all(updatePromises).then(() => {
			request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.updateShareAuthoringGrants(checklistMetadata.id)}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(authoringGrants)).then(() => {
					resolve();
				}).catch(err => {
					console.error(err);
					reject({
						title: "Activity Share",
						message: "Failed to update authoring grants, reason:" + err.message
					});
				});
		}).catch(err => {
			console.error(err);
			reject({
				title: "Activity Share",
				message: "Failed to update activity shares, reason:" + err.message
			});
		});
	});
}

function delay(milliseconds) {
	return new Promise(resolve => {
		setTimeout(resolve, milliseconds);
	});
}

var wait = (ms) => {
	const start = Date.now();
	let now = start;
	while (now - start < ms) {
		now = Date.now();
	}
}

async function sendAddEmail(toAdd, activityId, activityTitle, activityDescription, arrInitialGroups, arrInitialMembers) {
	// Look up email from userSubId
	// Build URL for activity

	let ownerDisplayName = "";
	let ownerEmail = "";

	const initialMembers = [];

	const emails = [];

	const members = state.get(["selectedOrg", "membersMap"]);

	const groups = state.get(["selectedOrg", "groupsMap"]);

	const subDomain = state.get(["appState", "subDomain"]);

	arrInitialGroups.forEach((item) => {
		const group = groups[item];

		group.members.forEach((item) => {
			if (item.inviteEmail && item.inviteEmail !== "" && !initialMembers.includes(item.inviteEmail)) {
				initialMembers.push(item.inviteEmail);
			}
		});
	});

	arrInitialMembers.forEach((item) => {
		const member = members[item];

		if (member.inviteEmail && member.inviteEmail !== "" && !initialMembers.includes(member.inviteEmail)) {
			initialMembers.push(item.inviteEmail);
		}
	});

	toAdd.forEach(async (item) => {
		ownerDisplayName = item.sharedArtifactOwnerId !== "group" ? members[item.sharedArtifactOwnerId].displayName : "AmbiFi Group";
		ownerEmail = item.sharedArtifactOwnerId !== "group" ? members[item.sharedArtifactOwnerId].inviteEmail : "AmbiFi Group";

		const emailsSent = [];
		const emailsError = [];

		if (item.sharedWithType === "member") {
			const email = members[item.sharedWithId].inviteEmail;

			if (email && email !== "" && !emails.includes(email) && email !== ownerEmail && !initialMembers.includes(email)) {
				try {
					wait(200);
					await sendShareEmail(email, activityId, activityTitle, activityDescription, ownerDisplayName, subDomain);
					console.info("!!!!!! EMAIL SENT: ", email);
					emailsSent.push(email);
				} catch (err) {
					console.info("!!!!!! EMAIL ERROR: ", email, err.message);
					emailsError.push(email);
				}
			}
		} else if (item.sharedWithType === "group") {
			const group = groups[item.sharedWithId];


			group.members.forEach(async (item) => {
				const email = item.inviteEmail;
				if (email && email !== "" && !emails.includes(email) && email !== ownerEmail && !initialMembers.includes(email)) {
					try {
						wait(200);
						await sendShareEmail(email, activityId, activityTitle, activityDescription, ownerDisplayName, subDomain);
						console.info("!!!!!! EMAIL SENT: ", email);
						emailsSent.push(email);
					} catch (err) {
						console.info("!!!!!! EMAIL ERROR: ", email, err.message);
						emailsError.push(email);
					}
				}
			});
		}

		if (emailsError.length > 0) {
			showError("Send Email Error", emailsError.join(", "));
		}
	});
}

async function sendShareEmail(email, activityId, activityTitle, activityDescription, ownerDisplayName, subDomain) {
	const newActivityDescription = (activityDescription && activityDescription !== "") ? activityDescription : null;

	const emailParams = {
		Destination: { ToAddresses: [email] },
		Message: {
			Body: {
				Html: {
					Charset: 'UTF-8',
					Data: `<html><body>
							<img src="https://docs.ambifi.com/img/ambifi-logo-200w.png" style="width:150px;"/><hr/>					
                           <p>The following activity was shared with you by <b>${ownerDisplayName}</b> and will be on your <b>My Activities</b> page:</p>
						   <div style='font-weight: bold; font-size: 16pt'>${activityTitle}</div>
						   ${newActivityDescription ? "<div style='font-weight: bold; font-size: 12pt'>" + newActivityDescription + "</div>" : ""}
							<br/>	
						   <hr/>
							<p>You can run the activity by clicking on this link:</p>
							<p>https://${subDomain}.ambifi.com/activity/${activityId}</p>
							<p style='fontSize: 9pt; color: gray'>NOTE: If tapping the link on a mobile device and it doesn't automatically open the mobile app, you can open the app and this activity should appear on your My Activities page.</p>
							<hr/>
							<p>If you don't yet have the AmbiFi mobile app, you can download it from the App Store or Google Play store.</p><p><div style='display: flex; flex-direction: row'><div><a href="https://itunes.apple.com/us/app/ambifi/id1375015365?mt=8"><img style='width: 125px;' alt='Download on the App Store' src='https://docs.ambifi.com/img/appstore-badge-v2.png'/></a><a href='https://play.google.com/store/apps/details?id=com.ambifimobile'><img style='width: 125px;' alt='Get it on Google Play' src='https://docs.ambifi.com/img/google-play-badge-v2.png'/></a></div></p><p>
							</body></html>`
				},
				Text: {
					Charset: 'UTF-8',
					Data: `The following activity was shared with you by ${ownerDisplayName} and will be on your My Activities page: ${activityTitle}`
				}
			},
			Subject: {
				Charset: 'UTF-8',
				Data: `${activityTitle} has been shared with you`
			}
		},
		Source: 'support@ambifi.com'
	};

	await new AWS.SES({ apiVersion: "2010-12-01" }).sendEmail(emailParams).promise();
}

async function sendRemoveEmail(toRemove, activityTitle, arrInitialGroups, arrInitialMembers) {
	// Look up email from userSubId
	// Build URL for activity

	let ownerDisplayName = "";
	let ownerEmail = "";

	const initialMembers = [];

	const emails = [];

	const members = state.get(["selectedOrg", "membersMap"]);

	const groups = state.get(["selectedOrg", "groupsMap"]);

	arrInitialGroups.forEach((item) => {
		const group = groups[item];

		group.members.forEach((item) => {
			if (item.inviteEmail && item.inviteEmail !== "" && !initialMembers.includes(item.inviteEmail)) {
				initialMembers.push(item.inviteEmail);
			}
		});
	});

	arrInitialMembers.forEach((item) => {
		const member = members[item];

		if (member.inviteEmail && member.inviteEmail !== "" && !initialMembers.includes(member.inviteEmail)) {
			initialMembers.push(item.inviteEmail);
		}
	});

	toRemove.forEach(async (item) => {
		ownerDisplayName = item.sharedArtifactOwnerId !== "group" ? members[item.sharedArtifactOwnerId].displayName : "AmbiFi Group";
		ownerEmail = item.sharedArtifactOwnerId !== "group" ? members[item.sharedArtifactOwnerId].inviteEmail : "AmbiFi Group";

		const emailsSent = [];
		const emailsError = [];

		if (item.sharedWithType === "member") {
			const email = members[item.sharedWithId].inviteEmail;

			if (email && email !== "" && !emails.includes(email) && email !== ownerEmail) {
				try {
					wait(200);
					await sendUnshareEmail(email, activityTitle, ownerDisplayName);
					console.info("!!!!!! EMAIL SENT: ", email);
					emailsSent.push(email);
				} catch (err) {
					console.info("!!!!!! EMAIL ERROR: ", email, err.message);
					emailsError.push(email);
				}
			}
		} else if (item.sharedWithType === "group") {
			const group = groups[item.sharedWithId];


			group.members.forEach(async (item) => {
				const email = item.inviteEmail;
				if (email && email !== "" && !emails.includes(email) && email !== ownerEmail) {
					try {
						wait(200);
						await sendUnshareEmail(email, activityTitle, ownerDisplayName);
						emailsSent.push(email);
						console.info("!!!!!! EMAIL SENT: ", email);
					} catch (err) {
						console.info("!!!!!! EMAIL ERROR: ", email, err.message);
						emailsError.push(email);
					}
				}
			});
		}

		if (emailsError.length > 0) {
			showError("Send Email Error", emailsError.join(", "));
		}
	});
}


async function sendUnshareEmail(email, activityTitle, ownerDisplayName) {
	const emailParams = {
		Destination: { ToAddresses: [email] },
		Message: {
			Body: {
				Html: {
					Charset: 'UTF-8',
					Data: `<html><body>
							<img src="https://docs.ambifi.com/img/ambifi-logo-200w.png" style="width:150px;"/><hr/>					
                           <p>The following activity was unshared with you by <b>${ownerDisplayName}</b> and will be removed from your <b>My Activities</b> page:</p>
							<div style='font-weight: bold; font-size: 16pt'>${activityTitle}</div>							</body></html>`
				},
				Text: {
					Charset: 'UTF-8',
					Data: `The following activity was unshared with you by ${ownerDisplayName} and will be removed from your My Activities page: ${activityTitle}`
				}
			},
			Subject: {
				Charset: 'UTF-8',
				Data: `${activityTitle} has been shared with you`
			}
		},
		Source: 'support@ambifi.com'
	};

	await new AWS.SES({ apiVersion: "2010-12-01" }).sendEmail(emailParams).promise();
}

export function shareActivity(initialGroups, initialMembers, groups, members, groupAuthoringGrants, memberAuthoringGrants, checklistMetadata) {
	return new Promise((resolve, reject) => {
		showSpinner();
		setTimeout(() => {
			console.log(initialGroups, initialMembers, groups, members, groupAuthoringGrants, memberAuthoringGrants, checklistMetadata);
			const arrInitialGroups = getArrayOfIds(initialGroups);
			let arrGroups = [];
			if (_.isArray(groups)) {
				arrGroups = _.map(groups, group => {
					return group.value;
				});
			} else {
				arrGroups = getArrayOfIds(groups);
			}

			const authoringGrants = [];
			groupAuthoringGrants.forEach(groupAuthoringGrant => {
				authoringGrants.push(groupAuthoringGrant.value);
			});
			memberAuthoringGrants.forEach(memberAuthoringGrant => {
				authoringGrants.push(memberAuthoringGrant.value);
			});

			const arrInitialMembers = getArrayOfIds(initialMembers);
			let arrMembers = [];
			if (_.isArray(members)) {
				arrMembers = _.map(members, member => {
					return member.value;
				});
			} else {
				arrMembers = getArrayOfIds(members);
			}


			const membersToAdd = _.difference(arrMembers, arrInitialMembers);
			const membersToRemove = _.difference(arrInitialMembers, arrMembers);
			const groupsToAdd = _.difference(arrGroups, arrInitialGroups);
			const groupsToRemove = _.difference(arrInitialGroups, arrGroups);

			const toAdd = [];
			const toRemove = [];

			membersToAdd.forEach(memberToAdd => {
				toAdd.push(buildActivityShareDataStructure(memberToAdd, checklistMetadata));
			});
			groupsToAdd.forEach(groupToAdd => {
				toAdd.push(buildActivityShareDataStructure(groupToAdd, checklistMetadata, "group"));
			});
			membersToRemove.forEach(memberToRemove => {
				toRemove.push(buildActivityRemoveShareDataStructure(memberToRemove, checklistMetadata.id));
			});
			groupsToRemove.forEach(groupToRemove => {
				toRemove.push(buildActivityRemoveShareDataStructure(groupToRemove, checklistMetadata.id, "group"));
			});

			const needsAdd = !_.isEmpty(toAdd);
			const needsRemove = !_.isEmpty(toRemove);
			const updatePromises = [];

			if (needsAdd) {
				updatePromises.push(new Promise((resolve, reject) => {
					refreshCredentials().then(() => {
						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.share}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify(toAdd)).then(res => {
								hideSpinner();
								resolve(res.body);
							}).catch(err => {
								hideSpinner();
								console.error(err);
								reject(err);
							});
					});
				}));

			}

			if (needsRemove) {
				updatePromises.push(new Promise((resolve, reject) => {
					refreshCredentials().then(() => {
						request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.removeShare}`)
							.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
							.send(JSON.stringify(toRemove)).then(res => {
								hideSpinner();
								resolve(res.body);
							}).catch(err => {
								hideSpinner();
								console.error(err);
								reject(err);
							});
					});
				}));
			}

			Promise.all(updatePromises).then(() => {
				request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.updateShareAuthoringGrants(checklistMetadata.id)}`)
					.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
					.send(JSON.stringify(authoringGrants)).then(() => {
						showSuccess("Activity Share", "Activity shares have been updated successfully", () => {
							hideSpinner();
							resolve();
						});
					}).catch(err => {
						hideSpinner();
						console.error(err);
						showError("Activity Share", "Failed to update authoring grants, reason:" + err.message, () => { reject(err); });
					});
			}).catch(err => {
				hideSpinner();
				showError("Activity Share", "Failed to update activity shares, reason:" + err.message, () => { reject(err); });
			});
		}, 100);
	});
}

export async function shareActivityWithCode(identityId, activity) {
	let shareCode = prompt("Please enter a share code (e.g. N51212)", activity.shareCode);

	if (shareCode !== null) {
		try {
			let data = await dynamoDb.getPrivateSharePromise(shareCode);
			if (!_.isEmpty(data) && (activity.shareCode !== shareCode)) {
				alert("The Share Code " + shareCode + " has already been used. Please choose another Share Code.");
			} else {
				activity.shareCode = shareCode;

				await dynamoDb.putPrivateSharePromise({ shareCode: shareCode, identityId: identityId, checklistId: activity.id, publisher: activity.publisher });

				updateActivity(activity);
				let newDocuments = _.cloneDeep(state.get(["activities"]));
				newDocuments.shift();
				newDocuments = filterLearningActivities(newDocuments);

				saveJsonToS3(createExperiencesInventoryFromActivities(newDocuments), env.s3.contentBucket, env.artifacts.inventoryFileName);

				showSuccess("Share Code", "You have successfully created a Share Code " + shareCode + ". Communicate this Share Code to other users and they can use it to replace one of their checklists with the contents of this checklist.");
				//showSuccess("Share Code", "You have successfully created a Share Code " + shareCode + ". Communicate this Share Code to other users and they can use it to replace one of their checklists with the contents of this checklist. They would utilize the Replace Share Code action to receive your content. NOTE: If the content being shared is licensed CheckMate content, then they will need to replace a checklist that has a CheckMate license. They can purchase a new one if they don't already have one.");
			}
		} catch (e) {
			console.log("This is an issue....", e);
			alert(e);
		}
	}
}

export function getSharesForUser() {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			const groupMemberships = getGroupMemberships();
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.assembleInventory}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.send(JSON.stringify(groupMemberships)).then(res => {
					console.log("Received ", res.body);
					resolve(res.body);
				}).catch(err => {
					console.error(err);
					reject(err);
				});
		});
	});
}

function buildActivityShareDataStructure(sharedWithId, metaData, sharedWithType = "member") {

	const sharedArtifactId = metaData.id;
	const sharedArtifactOwnerId = metaData.identityId;
	const sharedArtifactType = "activity";
	const obj = omitEmpty({
		sharedArtifactId,
		sharedArtifactOwnerId,
		sharedArtifactType,
		sharedWithId,
		sharedWithType,
		//metaData
	});

	return obj;
}

export function updateSharedDocument(document, thirdPartyAuthor = false) {
	return new Promise((resolve, reject) => {
		try {
			if (state.exists(["activitiesMap", document.id])) {
				const documentDefinition = state.get(["activitiesMap", document.id]);
				// Need to resolve the heroImage here ...

				let url = null;
				if (document.heroImage) {
					url = getUrlForValue(document.heroImage, document.id);
				}

				if (url) {
					document.heroImageUrl = url;
				}

				// Means there are active shares here

				// Get hasShares by looking at document.share object created by UI
				if (document.share) {
					document.hasShares = isActivityHasShares(document.share);
				}

				const metaData = _.pick(document, AppConstants.activity.metadata.props);

				// Don't include this in metadata
				//if (documentDefinition.metaData) {
				//	metaData.metaData = documentDefinition.metaData;
				//}

				if (document.children) {
					const dependencies = scanForExternalDependencies(document, true);
					metaData.dependencies = dependencies && _.isArray(dependencies) ? dependencies : [];
					console.log("Dependencies detected:", dependencies);
				}

				// Don't include this in metadata
				//metaData.thirdPartyAuthor = thirdPartyAuthor;
				//if (!thirdPartyAuthor && !metaData.identityId) {
				//	metaData.identityId = getIdentityId();
				//}

				console.log("UPDATE DOC META DATA", metaData);
				refreshCredentials().then(() => {
					request.put(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.updateShareMetadata(document.id)}`)
						.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
						.send(JSON.stringify(metaData)).then(res => {
							resolve(res.body);
						}).catch(err => {
							console.error(err);
							reject(err);
						});
				}).catch(err => {
					reject(err);
				});

			} else {
				resolve(); // New document
			}

		} catch (err) {
			if (err.message.startsWith("Maximum call stack")) {
				showError("Save Activity", "There was an issue saving the activity. Sorry for the inconvenience, but you will need to refresh the app before you will be able to save. If you did a lot of work since your last save, you will have the opportunity to restore any lost changes.");
				resolve();
			}
		}
	});

}

function buildActivityRemoveShareDataStructure(sharedWithId, sharedArtifactId, sharedArtifactOwnerId, sharedWithType = "member") {
	return {
		sharedArtifactOwnerId,
		sharedArtifactId,
		sharedWithId,
		sharedWithType
	};
}

/**
 * For the interim this can be plugged in whereever we are persisting the inventory, this will remove all entries that pertain to
 * shared activities.
 * @param {*} collection 
 */
export function filterShares(documents) {
	const result = [];
	documents.forEach(document => {
		if (!document.hasOwnProperty("identityId")) {
			result.push(document);
		}
	});
	return result;
}

export function filterDupes(documents) {
	const found = {};
	const result = [];
	console.log("Filter dupes on these documents:", documents);
	documents.forEach(doc => {
		if (!found[doc.id]) {
			result.push(doc);
			found[doc.id] = true;
		}
	});

	return result;
}

export function injectShares(activities, injectContent = false) {
	console.debug("Injecting shares...");

	return new Promise((resolve, reject) => {
		state.set(["appState", "documents", "initialSpinnerShown"], true);
		getSharesForUser().then(shares => {
			// console.debug("Got shares for user", shares);
			if (injectContent) {
				const s3 = new AWS.S3({ useDualStack: true });
				const rehydradedSharesPromises = [];
				shares.forEach(share => {
					rehydradedSharesPromises.push(new Promise((resolve, reject) => {
						s3.getObject({ Bucket: env.s3.contentBucket, Key: share.identityId + "/" + share.id + ".json" }, (err, data) => {
							console.log("Retrieving checklist", share.identityId, share.id);
							if (err) {
								reject(err);
							} else {
								let activity = normalizeActivity(_.assign({}, JSON.parse(data.Body.toString()), share));
								resolve(activity);
							}
						});
					}));
				});

				Promise.all(rehydradedSharesPromises).then(results => {
					const finalCollection = filterDupes(filterShares(activities).concat(results));
					console.debug("Final collection w/ shares included", finalCollection);
					resolve(finalCollection);
				}).catch((err) => {
					state.set(["appState", "documents", "showSpinner"], false);
					reject(err);
				});

			} else {
				const finalCollection = filterDupes(filterShares(activities).concat(shares));
				console.debug("Final collection w/ shares included", finalCollection);
				state.set(["appState", "documents", "showSpinner"], false);
				resolve(finalCollection);
			}

		}).catch(err => {
			state.set(["appState", "documents", "showSpinner"], false);
			reject(err);
		});
	});

}

export function receiveSharedChecklist(identityId, shareId) {
	//export function shareActivity(initialGroups, initialMembers, groups, members, checklistMetadata) {
	refreshCredentials().then(() => {

		const s3 = new AWS.S3({ useDualStack: true });
		const keyPrefix = getKeyPrefix(identityId);

		s3.getObject({ Bucket: env.s3.contentBucket, Key: `${keyPrefix}/${env.artifacts.inventoryFileName}` }, (err, data) => {

			if (!err) {
				let checklists = JSON.parse(data.Body.toString());

				const srcIdentityId = shareId.split(";")[0];
				const srcChecklistId = shareId.split(";")[1];

				let srcKeyPrefix = srcIdentityId;
				s3.getObject({ Bucket: env.s3.contentBucket, Key: srcKeyPrefix + "/" + srcChecklistId + ".json" }, function (err, data) {
					if (!err) {
						let checklist = JSON.parse(data.Body.toString());

						if (checklist.publisher === "checkmate") {
							state.set(["appState", "checklistReceived"], true);
							if (srcIdentityId === "public") {

								state.set(["appState", "checklistReceivedMessage"], "You can't receive a CheckMate checklist. You need to purchase one from MiraCheck Cloud.");
							} else {
								state.set(["appState", "checklistReceivedMessage"], "You can't receive a CheckMate checklist. Use the Create Share Code action to share a CheckMate checklist.");
							}
							return;
						}

						//console.log("WHAT!: " + JSON.stringify(checklist));

						if (srcIdentityId === "public") {
							clonePublicActivity({ id: srcChecklistId }, true);
						} else {
							let newChecklist = {};
							newChecklist.identityId = srcIdentityId;
							newChecklist.id = checklist.id;
							newChecklist.name = checklist.name;
							newChecklist.description = checklist.description;
							newChecklist.genre = checklist.genre;
							newChecklist.tags = checklist.tags;
							newChecklist.publisher = checklist.publisher;
							newChecklist.shareCode = checklist.shareCode ? checklist.shareCode : "";
							newChecklist.shareCodeReceived = checklist.shareCodeReceived ? checklist.shareCodeReceived : "";
							newChecklist.version = checklist.version ? checklist.version : "1.0";
							newChecklist.revision = checklist.revision ? checklist.revision : 1;
							newChecklist.cloned = checklist.cloned;
							newChecklist.visible = true;
							newChecklist.speedType = checklist.speedType;
							newChecklist.contributors = checklist.contributors;
							newChecklist.category = checklist.category ? checklist.category : "";

							// Only add if it doesn't already exist
							if (!_.find(checklists, newChecklist)) {
								//console.log(newChecklist);
								checklists.push(newChecklist);
								checklists = filterLearningActivities(checklists);
								// Write out new checklists.json to S3
								saveJsonToS3(createExperiencesInventoryFromActivities(checklists), env.s3.contentBucket, env.artifacts.inventoryFileName, "", false);
							}

							state.set(["appState", "checklistReceived"], true);
						}

					} else {
						handleChecklistRetceiveError(err);
					}
				});
			} else {
				handleChecklistRetceiveError(err);
			}
		});
	}).catch((err) => {
		console.error(err);
		handleChecklistRetceiveError(err);
	});
}

function handleChecklistRetceiveError(err) {
	state.set(["appState", "checklistReceivedMessage"], "There was an error receiving the checklist. " + err.message);
	state.set(["appState", "checklistReceived"], true);
}

/**
 * Gets an array of share data for the supplied artifact ID
 * 
 * @param {*} sharedArtifactId The shared artifact ID, usually an activity
 * @returns An array of shares for this artifact
 */
export async function getArtifactShares(sharedArtifactId) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.getArtifactShares(sharedArtifactId)}`)
				.set({ Authorization: getJwtToken(), "Content-Type": "application/json" })
				.then(res => {
					resolve(res.body);
				}).catch(err => {
					console.error(err);
					reject(err);
				});
		});
	});
}

/**
 * Returns a model that matches how shares are stored as part of an activity document.
 * This is used by the share modal to add / remove shares.
 * 
 * @param {*} sharedArtifactId The shared artifact ID, usually an activity
 * @returns Model used to add / remove shares
 */
export async function getArtifactSharesDocumentModel(sharedArtifactId) {
	let share = {};

	let response = await getArtifactShares(sharedArtifactId);

	let groups = [];
	let groupAuthoringGrants = [];

	let members = [];
	let memberAuthoringGrants = [];

	_.each(response, (responseItem) => {

		const { sharedWithType, sharedWithId, authoringGranted } = responseItem;

		switch (sharedWithType) {
			case "group":
				groups.push(sharedWithId);

				if (authoringGranted) {
					const group = getGroupById(sharedWithId);
					if (group) {
						groupAuthoringGrants.push({
							value: sharedWithId,
							label: group.displayName
						})
					} else {
						alert("The group with ID " + sharedWithId + " no longer exists.");
					}
				}
				break;

			case "member":
				members.push(sharedWithId);

				if (authoringGranted) {
					const member = getMemberBySubForSelectedOrg(sharedWithId);

					if (member) {
						memberAuthoringGrants.push({
							value: sharedWithId,
							label: member.displayName
						})
					} else {
						alert("The meber with ID " + sharedWithId + " no longer exists.");
					}
				}
				break;

			default:
				break;
		}
	});

	share.groups = groups.join(",");
	share.groupAuthoringGrants = groupAuthoringGrants;
	share.members = members.join(",");
	share.memberAuthoringGrants = memberAuthoringGrants;

	return share;
}


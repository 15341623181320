import Baobab from "baobab";
import _ from "lodash";
import request from "superagent";

import state from "../state/state";
import { hasElement, getKeyPrefix, generateUUID, isMobile, /*getQueryStringParam, */convertStringToDriveRootPath } from "../utils/utils";
import { deleteObject, objectExists, getActivity, putInventory, getInventory, /*batchGetActivities, */updateChecklistsAndPushToS3, PUBLIC_KEY_PREFIX } from "../persistence/s3";
import { SUBSCRIPTION_PLAN_BASIC, getSubscriptionPlan, getIdentityId, getJwtToken } from "./user";
import { refreshCredentials } from "../utils/securityUtil";
import { showError2, showSuccess, showSuccessToast } from "./alertActions";
import { getOrgId } from "./orgsActions";
import { removeReferenceToActivity, updateSharedDocument } from "./shareActivityActions";
import { /*normalizeActivity, */isChecklistLists, isChecklistSections, isChecklistItems } from "../utils/utils";
import { sendInventoryAndUserInfoMessage } from "./mobileAppCommunicationActions";
// import { resolveDatasources, clearCache } from "./datasource";
// import { initializeDrive } from "./drive";
import PlayerPaths from "../constants/paths/playerPaths";
import { showDocumentsSpinner, hideDocumentsSpinner } from "./actions";
import { fetchInventory/*, fetchDrive*/ } from "./portalActions";

import env from "../constants/env";

import moment from "moment";

const googleTranslate = require("google-translate")("AIzaSyDBl4u6OBo3OTwj_nMCFsWFxavmqXF4T9M");

/**
 * IMPORTANT!
 * These properties will override share meta data, the first property that is added here is
 * "visible" since we want to honor visible from the users inventory vs. what is in the share meta data.
 * An example of what should NEVER be in there is "name" or "description" since the user shouldn't be able to change
 * that as it was set by the author.
 */
const INVENTORY_OVERRIDE_PROPS = ["visible"];
// const INVENTORY_OVERRIDE_PROPS_MOBILE_SYNC = ["visible", "category"];

/**
 * These are props that will be persisted in thr
 */
export const INVENTORY_EXTERNAL_CONTENT_PERSISTENT_PROPS = _.concat(["id", "identityId"], INVENTORY_OVERRIDE_PROPS);

export function syncTimerTemplates(clientTimerTemplates, serverTimerTemplates) {
	let timerTemplates = [];

	for (let i = 0; i < clientTimerTemplates.length; i++) {
		const timerTemplate = clientTimerTemplates[i];

		const foundTemplate = _.find(serverTimerTemplates, function (o) { return o.guid === timerTemplate.guid; });

		// Not found, so we want to add it	
		if (!foundTemplate) {
			timerTemplates.push(timerTemplate);
			// Found, so we want to pick the correct one
		} else {
			const timerTemplateTimestamp = moment(timerTemplate.timestamp);
			const foundTemplateTimestamp = moment(foundTemplate.timestamp);
			if (timerTemplateTimestamp < foundTemplateTimestamp) {
				timerTemplates.push(foundTemplate);
			} else {
				timerTemplates.push(timerTemplate);
			}
		}
	}

	for (let i = 0; i < serverTimerTemplates.length; i++) {
		const timerTemplate = serverTimerTemplates[i];

		const foundTemplate = _.find(clientTimerTemplates, function (o) { return o.guid === timerTemplate.guid; });

		// Not found, so we want to add it	
		if (!foundTemplate) {
			timerTemplates.push(timerTemplate);
		}
	}

	return timerTemplates;
}

function hasFilter() {
	let genres = state.get(["appState", "filters", "all", "genres"]);
	let tags = state.get(["appState", "filters", "all", "tags"]);
	let text = state.get(["appState", "filters", "all", "text"]);

	for (let i = 0; i < genres.length; i++) {
		let genre = genres[i];
		if (genre.checked) {
			return true;
		}
	}

	for (let i = 0; i < tags.length; i++) {
		let tag = tags[i];
		if (tag.checked) {
			return true;
		}
	}
	if (text && _.isString(text) && text.length >= 3) {
		return true;
	}
}

export function containsHiddenActivities() {
	let documents = state.get(["activities"]);
	for (let i = 0; i < documents.length; i++) {
		let document = documents[i];
		if (!document.visible) {
			return true;
		}
	}

	return false;
}

export function clearFilters(documents) {

	for (let i = 0; i < documents.length; i++) {
		let document = documents[i];
		if (document.hasOwnProperty("filterVisible")) {
			delete document.filterVisible;
		}
	}
}

export function setShowVisible(tree, visible, onFilterStart, onFilterEnd) {
	//console.debug("In setShowVisible");
	if (onFilterStart) {
		onFilterStart();
	}
	setTimeout(() => {
		tree.set(["appState", "filters", "showVisible"], visible);
		setActivities(tree.get(["activities"]));
		if (onFilterEnd) {
			onFilterEnd();
		}
	});
}

export function setShowDependencies(tree, visible, onFilterStart, onFilterEnd) {
	//console.debug("In setShowVisible");
	if (onFilterStart) {
		onFilterStart();
	}
	setTimeout(() => {
		tree.set(["appState", "filters", "showDependencies"], visible);
		setActivities(tree.get(["activities"]));
		if (onFilterEnd) {
			onFilterEnd();
		}
	});
}
export function setFilterGenreProperty(tree, property, value, onFilterStart, onFilterEnd) {

	if (onFilterStart) {
		onFilterStart();
	}

	let genres = tree.get(["appState", "filters", "all", "genres"]);

	let index = -1;
	for (let i = 0; i < genres.length; i++) {
		let genre = genres[i];
		if (genre.genre === property) {
			index = i;
		}
	}

	if (index >= 0) {
		tree.set(["appState", "filters", "all", "genres", index, "checked"], value);
	}

	refreshInventory();
	if (onFilterEnd) {
		onFilterEnd();
	}
}

export function setFilterTagProperty(tree, property, value, onFilterStart, onFilterEnd) {

	if (onFilterStart) {
		onFilterStart();
	}

	let tags = tree.get(["appState", "filters", "all", "tags"]);

	let index = -1;
	for (let i = 0; i < tags.length; i++) {
		let tag = tags[i];
		if (tag.tag === property) {
			index = i;
		}
	}

	if (index >= 0) {
		tree.set(["appState", "filters", "all", "tags", index, "checked"], value);
	}

	refreshInventory();

	if (onFilterEnd) {
		onFilterEnd();
	}
}

export function setFilterTextProperty(filterText, onFilterStart, onFilterEnd) {
	if (onFilterStart) {
		onFilterStart();
	}
	console.log("SETTING FILTER TEXT PROPERTY", filterText);
	state.set(["appState", "filters", "all", "text"], filterText);
	refreshInventory();

	if (onFilterEnd) {
		onFilterEnd();
	}
}

/**
 * Alias for refresh()
 */
export function refreshInventory() {
	refresh();
}

let prevNoFilter = true;
export function refresh() {
	const documents = state.get(["activities"]);
	clearFilters(documents);
	const filterState = {};
	if (!hasFilter(state)) {
		if (!prevNoFilter) {
			prevNoFilter = true;
			state.set(["filters"], filterState); // No filters
			updateActivities(documents);
			if (!state.exists("activitiesMap")) {
				const monkey = Baobab.monkey;
				state.set(["activitiesMap"],
					monkey({
						cursors: {
							activities: ["activities"]
						},
						get: (data) => {
							const result = {};
							if (data) {
								data.activities.forEach(doc => {
									result[doc.id] = doc;
								});
							}
							return result;
						}
					}));
			}
		}
		return;
	}
	prevNoFilter = false;
	let genres = state.get(["appState", "filters", "all", "genres"]);
	let tags = state.get(["appState", "filters", "all", "tags"]);
	let text = state.get(["appState", "filters", "all", "text"]);
	let _text = null;
	if (text && _.isString(text) && text.length >= 3) {
		_text = text.toLowerCase();
	}

	// Filter out only ones selected

	for (let i = 0; i < documents.length; i++) {
		let document = documents[i];

		for (let j = 0; j < genres.length; j++) {
			let genre = genres[j];
			if (document.genre === genre.genre && genre.checked) {
				document.filterVisible = true;
			} else {
				if (document.hasOwnProperty("filterVisible") && document.filterVisible) {
					document.filterVisible = true;
				} else {
					if (!document.newDoc) {
						document.filterVisible = false;
					}
				}
			}
		}
		if (document.tags) {
			for (let j = 0; j < document.tags.length; j++) {
				let documentTag = document.tags[j];

				for (let k = 0; k < tags.length; k++) {
					let tag = tags[k];
					if (documentTag === tag.tag && tag.checked) {
						document.filterVisible = true;
					} else {
						if (document.hasOwnProperty("filterVisible") && document.filterVisible) {
							document.filterVisible = true;
						} else {
							if (!document.newDoc) {
								document.filterVisible = false;
							}
						}
					}
				}
			}
		}
		if (_text && document.name) {
			if (document.name.toLowerCase().indexOf(_text) !== -1) {
				document.filterVisible = true;
			} else {
				if (!document.newDoc) {
					document.filterVisible = false;
				}
			}
		}
		filterState[document.id] = !!document.filterVisible;
	}

	state.set(["filters"], filterState);
	updateActivities(documents);
	if (!state.exists("activitiesMap")) {
		const monkey = Baobab.monkey;
		state.set(["activitiesMap"],
			monkey({
				cursors: {
					activities: ["activities"]
				},
				get: (data) => {
					const result = {};
					if (data) {
						data.activities.forEach(doc => {
							result[doc.id] = doc;
						});
					}
					return result;
				}
			}));
	}
}

export function setFilterSetting(prop, value) {
	state.set(["appState", "filters", "settings", prop], value);
}

export function getActivityById(activityId) {
	return state.get(["activitiesMap", activityId]);
}

export function deleteActivityById(activityId, physicallyRemoveFromS3 = true) {
	return new Promise((resolve, reject) => {
		const activities = state.get(["activities"]);

		const newActivities = [];
		// let template;
		for (const _activity of activities) { //eslint-disable-line no-unused-vars
			if (!_activity.hasOwnProperty("newDoc") || !_activity.newDoc) {
				if (_activity.id !== activityId) {
					newActivities.push(_activity);
				}
			}
		}

		const key = `${getIdentityId()}/${activityId}.json`;
		// TODO: Can get out of sync without promise
		if (!physicallyRemoveFromS3) {
			setAndPersistActivities(newActivities, true).then(inventory => {
				if (isMobile()) {
					sendInventoryAndUserInfoMessage(inventory).then(() => {
						resolve(inventory);
					}).catch(err => {
						reject(err);
					});
				} else {
					resolve(inventory);
				}
			}).catch(err => {
				reject(err);
			});
		} else {
			objectExists(env.s3.contentBucket, key).then(res => {
				setAndPersistActivities(newActivities, true).then(inventory => {
					if (res) {
						deleteObject(env.s3.contentBucket, key).then(() => {
							if (isMobile()) {
								sendInventoryAndUserInfoMessage(inventory).then(() => {
									resolve(inventory);
								}).catch(err => {
									reject(err);
								});
							} else {
								resolve(inventory);
							}
						});
					} else {
						reject("Object not found");
					}
				}).catch(err => {
					reject(err);
				});
			}).catch(err => {
				console.error(err);
				reject({
					code: "NOT_FOUND",
					err
				});
			});
		}
	});

}

export function deleteActivity(activity) {
	if (activity.hasOwnProperty("identityId") && !_.isEmpty(activity.identityId)) {

		return new Promise((resolve, reject) => {
			// In this case we are dealing with an external reference, these can be added in various ways:
			// 1) Via sharing, where an org admin might share an activity with you
			// 2) Via sharing, where an org admin might share an activity with a group you are a member of
			// 3) Search (global or organization) and adding an activity by reference
			// Because of (2) it is difficult to just remove an activity from the inventory... if you're a member of a group
			// you would have to exclude it somehow (since you can't remove yourself from a group). This just seemed a little
			// too much for the time being therefore for cases 1 & 2 we will just set visible=false (interim solution)
			if (activity.sharedWithType === "self") {
				// You shared this with yourself
				// Remove the reference from the Dynamo Table
				removeReferenceToActivity(activity.id).then(() => {
					// Now remove it from the inventory
					deleteActivityById(activity.id, false).then(() => {
						request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.artifacts.activity(activity.id)}`)
							.set({ Authorization: getJwtToken() })
							.then(res => {
								console.log("Delete activity", res);
								state.set(PlayerPaths.RELOAD_ACTIVITIES, true);
								resolve();
							}).catch(err => {
								console.error(err);
								reject(err);
							});

					}).catch(err => {
						reject(err);
					});
				}).catch(err => {
					reject(err);
				});
			} else {
				// This means the sharedWithType is either "member" or "group", meaning someone shared this with you as an org member or
				// with a group you are a member of.
				reject({
					message: "Unable to delete this activity as it has been shared with you by a member of the organization. Please use the 'Toggle Visibility' capability instead to hide this activity."
				});
			}

		});

	} else {
		return deleteActivityById(activity.id).then(() => {
			request.delete(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.artifacts.activity(activity.id)}`)
				.set({ Authorization: getJwtToken() })
				.then(res => {
					console.log("Delete activity (2)", res);
					state.set(PlayerPaths.RELOAD_ACTIVITIES, true);
				}).catch(err => {
					console.error(err);
				});
		});
	}
}

export function getActivityInventory() {
	return state.get(["activities"]);
}

function updateActivities(activities) {
	//console.log("ACTIVITIES UPDATE", activities);
	state.set(["activities"], ensureLeadingBlankActivity(activities));

	injectMonkeysActivitySelected();
}

function injectMonkeysActivitySelected() {
	const documents = state.get(["activities"]);

	// Filter out only ones selected
	for (let i = 0; i < documents.length; i++) {
		let document = documents[i];

		// Create a monkey to get selected state for multi-selection
		state.set(["activities", i, "selected"],
			Baobab.monkey({
				cursors: {
					selectedActivities: ["appState", "selectedActivities"]
				},
				get: (data) => {
					if (data) {
						const index = data.selectedActivities.indexOf(document.id);

						if (index > -1) {
							return true;
						} else {
							return false;
						}
					}
					return false;
				}
			}));

	}
}

export function updateActivity(activity) {
	const documents = state.get(["activities"]);

	const newDocuments = [];
	for (const doc of documents) { // eslint-disable-line no-unused-vars
		if (doc.id === activity.id) {
			newDocuments.push(activity);
		} else {
			newDocuments.push(doc);
		}
	}

	setActivities(newDocuments);
}

function recurseCreateContentArray(arr, content, contentMetadata, lastListIndex, lastSectionIndex) {
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].type.startsWith("list")) {
			lastListIndex = i;

			if (arr[i].name) {
				content.push(arr[i].name);
				contentMetadata.push({
					type: "list",
					listIndex: i,
					propName: "name"
				});
			}

			if (arr[i].nameAudio) {
				content.push(arr[i].nameAudio);
				contentMetadata.push({
					type: "list",
					listIndex: i,
					propName: "nameAudio"
				});
			}
		} else if (arr[i].type.startsWith("section")) {
			lastSectionIndex = i;

			if (arr[i].name) {
				content.push(arr[i].name);
				contentMetadata.push({
					type: "section",
					listIndex: lastListIndex,
					sectionIndex: i,
					propName: "name"
				});
			}

			if (arr[i].nameAudio) {
				content.push(arr[i].nameAudio);
				contentMetadata.push({
					type: "section",
					listIndex: lastListIndex,
					sectionIndex: i,
					propName: "nameAudio"
				});
			}
		} else if (arr[i].type.startsWith("item")) {
			if (arr[i].label1) {
				content.push(arr[i].label1);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "label1"
				});
			}

			if (arr[i].label2) {
				content.push(arr[i].label2);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "label2"
				});
			}

			if (arr[i].comments) {
				content.push(arr[i].comments);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "comments"
				});
			}

			if (arr[i].label1Audio) {
				content.push(arr[i].label1Audio);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "label1Audio"
				});
			}

			if (arr[i].label2Audio) {
				content.push(arr[i].label2Audio);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "label2Audio"
				});
			}

			if (arr[i].commentsAudio) {
				content.push(arr[i].commentsAudio);
				contentMetadata.push({
					type: "item",
					listIndex: lastListIndex,
					sectionIndex: lastSectionIndex,
					itemIndex: i,
					propName: "commentsAudio"
				});
			}

			if (arr[i].type === "itemPicker") {
				for (let j = 0; j < arr[i].pickerItems.length; j++) {
					const pickerItem = arr[i].pickerItems[j];
					content.push(pickerItem.label);
					contentMetadata.push({
						type: "itemPickerPickerItem",
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						pickerItemIndex: j
					});
				}
			} else if (arr[i].type === "itemChoiceSingle" || arr[i].type === "itemChoiceMulti") {
				for (let j = 0; j < arr[i].choiceItems.length; j++) {
					const choiceItem = arr[i].choiceItems[j];
					content.push(choiceItem.label);
					contentMetadata.push({
						type: "itemChoiceChoiceItem",
						listIndex: lastListIndex,
						sectionIndex: lastSectionIndex,
						itemIndex: i,
						choiceItemIndex: j
					});
				}
			}
		}

		if (arr[i].hasOwnProperty("children")) {
			recurseCreateContentArray(arr[i].children, content, contentMetadata, lastListIndex, lastSectionIndex);
		}
	}
}

export function cloneActivity(activity, lang) {
	if (hasMaxActivities()) {
		return;
	}
	showDocumentsSpinner();
	setTimeout(() => {
		refreshCredentials().then(() => {
			let keyPrefix;
			if (activity.hasOwnProperty("identityId")) {
				keyPrefix = activity.identityId;
			} else {
				keyPrefix = getKeyPrefix(state.select(["user", "identityId"]).get());
			}

			getActivity(activity.id, keyPrefix).then(async _activity => {
				if (_activity.hasOwnProperty("identityId")) {
					delete _activity.identityId;
				}
				if (_activity.hasOwnProperty("share")) {
					delete _activity.share;
				}
				const baseId = _activity.id;
				_activity.id = generateUUID();
				let baseIds;
				if (_activity.hasOwnProperty("baseIds")) {
					_activity.baseIds.push(baseId);
					baseIds = _activity.baseIds;
				} else {
					baseIds = [];
					baseIds.push(baseId);
					_activity.baseIds = baseIds;
				}

				_activity.cloned = true;
				_activity.revision = _activity.hasOwnProperty("revision") ? (_activity.revision + 1) : 1;
				_activity.revisionTs = new Date().getTime();
				_activity.tags = _activity.tags ? _activity.tags : [];
				// If a lang is passed in then translate text
				if (!_.isUndefined(lang) && lang !== null) {
					let clonedActivity = _cloneActivity(_activity);
					// clone
					// clonedActivity = normalizeActivity(clonedActivity);
					// clonedActivity.firstLevel = "lists";
					if (isChecklistLists(clonedActivity)) {
						clonedActivity.firstLevel = "lists";
					} else if (isChecklistSections(clonedActivity)) {
						clonedActivity.firstLevel = "sections";
					} else if (isChecklistItems(clonedActivity)) {
						clonedActivity.firstLevel = "items";
					}

					// Loop through activity and add any content to be translated to array
					let content = [];
					let contentMetadata = [];

					content.push(clonedActivity.name);
					contentMetadata.push({
						type: "activity",
						propName: "name"
					});

					if (clonedActivity.description) {
						content.push(clonedActivity.description);
						contentMetadata.push({
							type: "activity",
							propName: "description"
						});
					}

					if (clonedActivity.nameAudio) {
						content.push(clonedActivity.nameAudio);
						contentMetadata.push({
							type: "activity",
							propName: "nameAudio"
						});
					}

					recurseCreateContentArray(clonedActivity.children, content, contentMetadata, 0, 0);

					// Translate array
					googleTranslate.translate(content, lang, async function (err, translations) {
						if (err) {
							// dummy
						} else {
							console.log(translations);

							clonedActivity.lang = lang;

							for (let i = 0; i < contentMetadata.length; i++) {
								const cmi = contentMetadata[i];

								if (cmi.type === "activity") {
									clonedActivity[cmi.propName] = translations[i].translatedText;
								} else if (cmi.type === "list") {
									clonedActivity.children[cmi.listIndex][cmi.propName] = translations[i].translatedText;
								} else if (cmi.type === "section") {
									if (clonedActivity.firstLevel === "lists") {
										clonedActivity.children[cmi.listIndex].children[cmi.sectionIndex][cmi.propName] = translations[i].translatedText;
									} else if (clonedActivity.firstLevel === "sections") {
										clonedActivity.children[cmi.sectionIndex][cmi.propName] = translations[i].translatedText;
									}
								} else if (cmi.type === "item") {
									if (clonedActivity.firstLevel === "lists") {
										let item = clonedActivity.children[cmi.listIndex].children[cmi.sectionIndex].children[cmi.itemIndex];
										item[cmi.propName] = translations[i].translatedText;
									} else if (clonedActivity.firstLevel === "sections") {
										let item = clonedActivity.children[cmi.sectionIndex].children[cmi.itemIndex];
										item[cmi.propName] = translations[i].translatedText;
									} else {
										let item = clonedActivity.children[cmi.itemIndex];
										item[cmi.propName] = translations[i].translatedText;
									}
								} else if (cmi.type === "itemPickerPickerItem") {
									if (clonedActivity.firstLevel === "lists") {
										let item = clonedActivity.children[cmi.listIndex].children[cmi.sectionIndex].children[cmi.itemIndex];
										item.pickerItems[cmi.pickerItemIndex].label = translations[i].translatedText;
									} else if (clonedActivity.firstLevel === "sections") {
										let item = clonedActivity.children[cmi.sectionIndex].children[cmi.itemIndex];
										item.pickerItems[cmi.pickerItemIndex].label = translations[i].translatedText;
									} else {
										let item = clonedActivity.children[cmi.itemIndex];
										item.pickerItems[cmi.pickerItemIndex].label = translations[i].translatedText;
									}
								} else if (cmi.type === "itemChoiceChoiceItem") {
									if (clonedActivity.firstLevel === "lists") {
										let item = clonedActivity.children[cmi.listIndex].children[cmi.sectionIndex].children[cmi.itemIndex];
										item.choiceItems[cmi.choiceItemIndex].label = translations[i].translatedText;
									} else if (clonedActivity.firstLevel === "sections") {
										let item = clonedActivity.children[cmi.sectionIndex].children[cmi.itemIndex];
										item.choiceItems[cmi.choiceItemIndex].label = translations[i].translatedText;
									} else {
										let item = clonedActivity.children[cmi.itemIndex];
										item.choiceItems[cmi.choiceItemIndex].label = translations[i].translatedText;
									}
								}
							}

							const newActivity = {
								id: _activity.id,
								name: clonedActivity.name,
								description: clonedActivity.description,
								externalResources: _activity.externalResources,
								cloned: true,
								genre: _activity.genre,
								tags: _activity.tags,
								contributors: _activity.hasOwnProperty("contributors") ? _activity.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
								publisher: _activity.publisher,
								shareCode: "",
								shareCodeReceived: "",
								version: "1.0",
								revision: 1,
								revisionTs: new Date().getTime(),
								visible: true,
								speedType: "KIAS",
								heroImage: _activity.heroImage ? _activity.heroImage : null,
								logoImage: _activity.logoImage ? _activity.logoImage : null,
								logoImageSmall: _activity.logoImageSmall ? _activity.logoImageSmall : null,
								owner: true,
								trackData: _activity.trackData
							};

							await updateChecklistsAndPushToS3(clonedActivity, newActivity, { lock: false });
							state.set(PlayerPaths.RELOAD_ACTIVITIES, true);
							hideDocumentsSpinner();
							showSuccessToast(`Successfully created clone of activity ${_activity.name}`);
						}
					});
				} else {
					_activity.name = `Clone of ${_activity.name}`;

					_activity.driveRootPath = convertStringToDriveRootPath(_activity.name);

					const clonedActivity = _cloneActivity(_activity);

					const newActivity = {
						id: _activity.id,
						name: _activity.name,
						description: _activity.description,
						externalResources: _activity.externalResources,
						cloned: true,
						genre: _activity.genre,
						tags: _activity.tags,
						contributors: _activity.hasOwnProperty("contributors") ? _activity.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
						publisher: _activity.publisher,
						shareCode: "",
						shareCodeReceived: "",
						version: "1.0",
						revision: 1,
						revisionTs: new Date().getTime(),
						visible: true,
						speedType: "KIAS",
						heroImage: _activity.heroImage ? _activity.heroImage : null,
						logoImage: _activity.logoImage ? _activity.logoImage : null,
						logoImageSmall: _activity.logoImageSmall ? _activity.logoImageSmall : null,
						owner: true,
						trackData: _activity.trackData
					};

					await updateChecklistsAndPushToS3(clonedActivity, newActivity, { lock: false });
					state.set(PlayerPaths.RELOAD_ACTIVITIES, true);
					hideDocumentsSpinner();
					showSuccessToast(`Successfully created clone of activity ${_activity.name}`);
				}

			}).catch(err => {
				hideDocumentsSpinner();
				console.error(err);
				showError2("Clone failed", err);
			});

		}).catch((err) => {
			console.error(err);
			showError2("Clone failed", err);
		});
	});
}

function _cloneActivity(activity) {
	const clonedActivity = _.omit(_.cloneDeep(activity), ["identityId", "share"]);
	clonedActivity.tags = activity.tags ? activity.tags : [];
	clonedActivity.shareCode = "";
	clonedActivity.shareCodeReceived = "";
	clonedActivity.privacy = "private";
	clonedActivity.visible = true;
	clonedActivity.orgPrivacy = null;
	clonedActivity.privacy = null;
	clonedActivity.owner = true;

	return clonedActivity;
}

export function clonePublicActivity(hit, fromShare = false, scope) {
	if (hasMaxActivities()) {
		return;
	}
	refreshCredentials().then(() => {
		const prefix = scope === "org" ? `${getOrgId()}/${PUBLIC_KEY_PREFIX}` : PUBLIC_KEY_PREFIX;
		getActivity(hit.id, prefix).then(async _activity => {

			const _clonedActivity = _cloneActivity(_activity);

			if (fromShare) {
				await addActivity(_clonedActivity, true);

				setTimeout(function () {
					state.set(["appState", "checklistReceived"], true);
				}, 3000);
			} else {
				// Check if already exists in checklists
				if (!activityExists(_clonedActivity.name)) {
					await addActivity(_clonedActivity, true);
				} else {
					window.confirm("An activity of the same name already exists in your My Activities. Do you still want to add it?", async () => {
						await addActivity(_clonedActivity, true);
					});
				}
			}
		}).catch(err => {
			alert(err, err.stack);
		});

	}).catch((err) => {
		console.error(err);
		showError2("Clone Document", err);
	});
}

export function getPublicActivityClone(hit) {
	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			let prefix = PUBLIC_KEY_PREFIX;
			if (!hit.url.includes(`s3.amazonaws.com/${env.s3.contentBucket}/public`)) {
				prefix = getOrgId() + "/public";
			}

			getActivity(hit.id, prefix).then(_activity => {

				const _clonedActivity = _cloneActivity(_activity);

				resolve(_clonedActivity);
			}).catch(err => {
				console.error(err);
				reject(err);
			});
		}).catch((err) => {
			console.error(err);
			reject(err);
		});
	});
}

export function activityExists(name) {
	let exists = false;
	const activities = state.get(["activities"]);
	for (let i = 0; i < activities.length; i++) {
		if (name === activities[i].name) {
			//console.log(activities[i].name);
			exists = true;
		}
	}

	return exists;
}

export function activityIdExists(activityId) {
	let exists = false;
	const activities = state.get(["activities"]);
	for (let i = 0; i < activities.length; i++) {
		if (activityId === activities[i].id) {
			//console.log(activities[i].id);
			exists = true;
		}
	}

	return exists;
}

export function addActivity(activity, cloned = false) {
	return new Promise(async (resolve, reject) => {
		const baseId = activity.id;
		activity.id = generateUUID();
		if (activity.hasOwnProperty("baseIds")) {
			activity.baseIds.push(baseId);
		} else {
			const baseIds = [];
			baseIds.push(baseId);
			activity.baseIds = baseIds;
		}
		// Need to think through all scenarios...for now allow people to delete anything
		activity.cloned = cloned;

		const newActivity = {
			id: activity.id,
			name: activity.name,
			description: activity.description,
			cloned: cloned,
			genre: activity.genre,
			tags: activity.tags,
			contributors: activity.hasOwnProperty("contributors") ? activity.contributors : [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
			publisher: activity.publisher,
			shareCode: "",
			shareCodeReceived: "",
			version: "1.0",
			revision: 1,
			revisionTs: new Date().getTime(),
			visible: true,
			speedType: "KIAS",
			heroImage: activity.heroImage ? activity.heroImage : null,
			logoImage: activity.logoImage ? activity.logoImage : null,
			logoImageSmall: activity.logoImageSmall ? activity.logoImageSmall : null
		};

		await updateChecklistsAndPushToS3(activity, newActivity, { lock: false });

		state.set(PlayerPaths.RELOAD_ACTIVITIES, true);

		showSuccess("Added", "This activity has been successfuly added to My Activities.");
		resolve();
	});
}

export function setAndPersistActivities(activities) {
	return new Promise((resolve, reject) => {
		setActivities(activities).then(() => {
			persistInventory(activities).then(() => {
				resolve(activities);
			}).catch(err => {
				reject(err);
			});
		});
	});
}

export function persistInventory(inventory = null) {
	return new Promise((resolve, reject) => {
		if (!inventory) {
			// Fallback to the current state tree
			inventory = state.get(["activities"]);

		}
		const _inventory = _.clone(inventory);

		if (!_.isArray(_inventory)) {
			reject(new Error("Expected inventory to be an array, unable to persist."));
		}

		putInventory(_inventory).then(() => {
			resolve();
		}).catch(err => {
			reject(err);
		});
	});

}

export function setActivities(activities) {
	return new Promise((resolve, reject) => {
		try {
			//console.log("setDocuments!");
			let oldGenres = state.get(["appState", "filters", "all", "genres"]);
			let oldTags = state.get(["appState", "filters", "all", "tags"]);

			// Build data for genres and tags for filtering
			let allGenres = [];
			let allTags = [];
			activities.forEach(activity => {

				let showVisible = state.get(["appState", "filters", "showVisible"]);
				let showDependencies = state.get(["appState", "filters", "showDependencies"]);

				if (activity.category === "learning") {
					return;
				}

				let inc = 1;
				if ((!showVisible && !activity.visible) || (!showDependencies && activity.dependency)) {
					inc = 0;
				}

				let genre = activity.genre;
				if (genre !== "") {
					let genreItem = hasElement(allGenres, "genre", genre);
					if (genreItem !== null) {
						// Find index
						for (let j = 0; j < allGenres.length; j++) {
							if (allGenres[j].genre === genre) {
								let oldElement = hasElement(oldGenres, "genre", genre);
								let newGenreItem = {
									genre: genre,
									count: genreItem.count + inc,
									checked: oldElement === null ? false : oldElement.checked
								};

								allGenres.splice(j, 1, newGenreItem);
							}
						}
					} else {
						const oldElement = hasElement(oldGenres, "genre", genre);

						allGenres.push({ genre: genre, count: inc, checked: oldElement === null ? false : oldElement.checked });
					}
					if (activity.tags) {
						for (let j = 0; j < activity.tags.length; j++) {
							let tag = activity.tags[j];

							let tagItem = hasElement(allTags, "tag", tag);
							if (tagItem !== null) {
								// Find index
								for (let k = 0; k < allTags.length; k++) {
									if (allTags[k].tag === tag) {
										let oldTag = hasElement(oldTags, "tag", tag);
										let newTagItem = {
											tag: tag,
											count: tagItem.count + inc,
											checked: oldTag === null ? false : oldTag.checked
										};
										allTags.splice(k, 1, newTagItem);
									}
								}
							} else {
								const oldTag = hasElement(oldTags, "tag", tag);

								allTags.push({ tag: tag, count: inc, checked: oldTag === null ? false : oldTag.checked });
							}
						}
					}
				}
			});

			state.set(["appState", "filters", "all", "genres"], allGenres);
			state.set(["appState", "filters", "all", "tags"], allTags);

			updateActivities(activities);
			if (!state.exists("activitiesMap")) {
				const monkey = Baobab.monkey;
				state.set(["activitiesMap"],
					monkey({
						cursors: {
							documents: ["activities"]
						},
						get: (data) => {
							const result = {};
							if (data) {
								data.documents.forEach(doc => {
									result[doc.id] = doc;
								});
							}
							return result;
						}
					}));
			}
			resolve(state.get(["activities"]));
		} catch (e) {
			const message = "An error occurred while trying to update activities in state";
			console.error(message, e);
			reject(new Error(message, e));
		}
	});

}

function ensureLeadingBlankActivity(activities) {
	const result = _.filter(activities, (o) => !o.newDoc);
	result.unshift(getBlankInventoryActivityEntry());
	return result;
}

export function replaceActivity(activity, identityId, activityId) {


	refreshCredentials().then(() => {
		getActivity(activityId, identityId).then(_activity => {
			_activity.id = activity.id;

			if (_activity.hasOwnProperty("identityId")) {
				delete _activity.identityId;
			}
			if (_activity.hasOwnProperty("share")) {
				delete _activity.share;
			}

			const newActivity = _.cloneDeep(activity);
			newActivity.name = _activity.name;
			newActivity.description = _activity.description;
			newActivity.externalResources = _activity.externalResources;
			newActivity.cloned = _activity.cloned;
			newActivity.publisher = _activity.publisher;
			newActivity.genre = _activity.genre;
			newActivity.tags = _activity.tags;
			newActivity.speedType = _activity.speedType;
			newActivity.contributors = _activity.contributors;
			newActivity.version = _activity.version;
			newActivity.revision = _activity.revision;
			newActivity.revisionTs = _activity.revisionTs;
			newActivity.heroImage = _activity.heroImage ? _activity.heroImage : null;
			newActivity.logoImage = _activity.logoImage ? _activity.logoImage : null;
			newActivity.logoImageSmall = _activity.logoImageSmall ? _activity.logoImageSmall : null;

			updateActivity(newActivity);

			updateChecklistsAndPushToS3(_activity, null, { ignoreContributors: true, lock: false });
		}).catch(err => {
			alert(err, err.stack);
		});

	}).catch((err) => {
		console.error(err);
		showError2("Replace failed", err);
	});
}

export function hasMaxActivities() {
	const documents = state.get(["activities"]);

	const subscriptionPlan = getSubscriptionPlan();

	if (subscriptionPlan === SUBSCRIPTION_PLAN_BASIC && documents.length === 6) {
		alert("You can only have a maximum of five checklists in the Basic Plan. You will need to upgrade to the Standard or Pro Plan if you would like to manage more checklists.");
		return true;
	}

	return false;
}


export function initialize(performIntegrityCheck = false) {
	return fetchActivityInventory(performIntegrityCheck, true);
}

export function initializeInventoryV2(userInventory) {
	return new Promise(async (resolve, reject) => {
		try {
			const finalInventory = ensureLeadingBlankActivity(userInventory);
			await setActivities(finalInventory);
			resolve(finalInventory);
		} catch (err) {
			reject(err);
		}
	});
}

export function initializeInventory(performIntegrityCheck = false) {
	return fetchActivityInventory(performIntegrityCheck, true);
}

/**
 * Will fetch the inventory.json and shares and combine them together into a proper inventory.
 * 
 * ensureLeadingBlankActivity(mergeInventory(finalInventory, shares, myInventoryMap)) : mergeInventory(finalInventory, shares, myInventoryMap);
 */
function fetchActivityInventory(performIntegrityCheck = false, addBlankFirstEntry = false, playlistId) {
	return new Promise((resolve, reject) => {

		refreshCredentials().then(async () => {
			try {
				//const inventoryRes = await request.get(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.user.inventory}?includeDrive=true`).set({ Authorization: getJwtToken() });
				//const { inventory, drive } = inventoryRes.body;

				const jwt = getJwtToken();

				//split up retrieval of drive and inventory due to size
				const { inventory } = await fetchInventory(jwt, playlistId);

				// Need to split drive even more for now
				const finalInventory = addBlankFirstEntry ? ensureLeadingBlankActivity(inventory) : inventory;
				initializeInventoryV2(finalInventory);
				// const drive = await fetchDrive(jwt);
				// initializeDrive(drive);
				resolve(finalInventory);
			} catch (err) {
				console.error("An error occurred while attempting to fetch inventory", err, performIntegrityCheck);
				reject(err);
			}

		});

	});
}

/**
 * Will assemble an inventory that can be sent over to the mobile side
 * @param {*} baseInventory 
 */
export function assembleActivitiesForMobile(baseInventory = null, playlistId) {
	return new Promise(async (resolve, reject) => {

		let inventory = baseInventory;
		if (!inventory) {
			try {
				inventory = await fetchActivityInventory(true, false, playlistId);
			} catch (e) {
				reject(e);
			}
		}

		const finalInventory = inventory;
		// Ok now that we have the inventory lets get all the content and inject it!
		// if (getQueryStringParam("app-api-version") > 1) {

		// ONLY USE NEW WAY OF GETTING INVENTORY

		const activities = [];
		filterNewActivityPlaceholder(finalInventory).forEach(activity => {
			if (!activity.hasOwnProperty("revision")) {
				activity.revision = 1;
			}
			activities.push(activity);
		});
		resolve(activities);
		// } else {
		// 	console.log("!#!#!# OH NO");
		// 	batchGetActivities(filterNewActivityPlaceholder(finalInventory)).then(results => {
		// 		const activities = [];
		// 		results.forEach(result => {
		// 			// if (!result.hasOwnProperty("visible") || result.visible) {
		// 			/**
		// 					 * There are a couple of things happening here, first we retrieve the actual content from S3, second we overlay
		// 					 * configuration the user might have made in the inventory, right now the only prop that gets overlayed here is "visible".
		// 					 */
		// 			let activity = normalizeActivity(result);
		// 			if (!activity.hasOwnProperty("revision")) {
		// 				activity.revision = 1;
		// 			}
		// 			activities.push(activity);
		// 			// }	
		// 		});
		// 		clearCache();
		// 		const promises = activities.map(activity => {
		// 			return resolveDatasources(activity);
		// 		});
		// 		Promise.all(promises).then((res) => {
		// 			resolve(res);
		// 		}).catch(err => {
		// 			reject(err);
		// 		});
		// 	}).catch(err => {
		// 		reject(err);
		// 	});
		// }
	});
}



function markInventoryActivities(inventory, propValues = []) {

	inventory.forEach(activity => {
		propValues.forEach(propValue => {
			activity[propValue.property] = propValue.value;
		});
	});

}

/**
 * This will return the AmbiFi Learning experiences.json
 */
function fetchLearningInventory() {
	return getInventory(env.s3.ambiFiLearningKey);
}

function filterNewActivityPlaceholder(activities) {
	return _.filter(activities, (o) => { return !o.newDoc; });
}

export function getBlankInventoryActivityEntry() {
	return {
		id: generateUUID(),
		newDoc: true,
		name: "",
		description: "",
		genre: "",
		tags: [],
		contributors: [{ name: state.get(["user", "name"]), username: state.get(["user", "username"]), nickname: state.get("user", "nickname") }],
		publisher: "self",
		shareCode: "",
		shareCodeReceived: "",
		version: "1.0",
		revision: 1,
		cloned: false,
		visible: true,
		speedType: "KIAS",
	};
}

export function setActivityProperty(activityId, property, value) {
	const activities = state.get(["activities"]);

	const newActivities = [];

	for (const activity of activities) { // eslint-disable-line no-unused-vars
		if (!activity.hasOwnProperty("newDoc") || !activity.newDoc) {
			if (activity.id === activityId) {
				activity[property] = value;
			}
			newActivities.push(activity);
		}
	}

	return setAndPersistActivities(newActivities, true);
}

export function toggleActivityProperty(activityId, property) {
	const activities = state.get(["activities"]);

	const newActivities = [];

	for (const activity of activities) { // eslint-disable-line no-unused-vars
		if (!activity.hasOwnProperty("newDoc") || !activity.newDoc) {
			if (activity.id === activityId) {
				activity[property] = !activity[property];
			}
			newActivities.push(activity);
		}
	}

	return setAndPersistActivities(newActivities, true);
}

export function toggleActivityVisible(document) {
	return new Promise((resolve, reject) => {
		toggleActivityProperty(document.id, "visible").then((inventory) => {
			state.set(PlayerPaths.RELOAD_ACTIVITIES, true);
			if (isMobile()) {
				sendInventoryAndUserInfoMessage(inventory).then(() => {
					resolve(inventory);
				}).catch(err => {
					reject(err);
				});
			} else {
				resolve(inventory);
			}
		});
	});
}

export function updateActivityShareInfo(activity, shareMetadata) {
	return new Promise(async (resolve, reject) => {
		const result = await setActivityProperty(activity.id, "share", shareMetadata);
		const _activity = getActivityById(activity.id);
		updateSharedDocument(_activity, false).then(() => {
			resolve(result);
		}).catch(err => {
			console.error(err);
			reject(err);
		});
	});
}

export function fetchActivityAndLearningInventory(baseInventory = null) {
	return new Promise(async (resolve, reject) => {

		let inventory = baseInventory;
		if (!inventory) {
			try {
				inventory = await fetchActivityInventory(true); // eslint-disable-line
			} catch (e) {
				reject(e);
			}
		}
		fetchLearningInventory().then(learningInventory => {
			// Poor mans approach to categorization
			markInventoryActivities(learningInventory, [
				{
					property: "category",
					value: "learning"
				},
				{
					property: "identityId",
					value: env.s3.ambiFiLearningKey
				}
			]);
			resolve({ inventory, learningInventory });
		});
	});
}

/**
 * Will assemble an inventory for the web app home page, this only requires experience.json
 * for the user and from the learning org
 * @param {*} baseInventory 
 */
export function assembleActivitiesForWeb(baseInventory = null) {

	return new Promise((resolve, reject) => {
		fetchActivityAndLearningInventory(baseInventory).then((result) => {
			const finalInventory = _.concat(result.learningInventory, result.inventory);
			resolve(finalInventory);
		}).catch((err) => {
			reject(err);
		});
	});
}
import request from "superagent";
import env from "../constants/env";
import state from "../state/state";
import { refreshCredentials } from "../utils/securityUtil";
import { getJwtToken } from "./user";
import { showError } from "./alertActions";
import { getAppBaseUrl } from "../utils/utils";
import { logoutUser } from "./portalActions";


export async function impersonateUser(orgId, cognitoUserSubId) {
	try {
		const response = await generateMagicLink(orgId, cognitoUserSubId);
		console.log("Magic link for impersonate user " + response.magicLink);

		//Log out current user before redirect
		await logoutUser(false);
		window.location.href = response.magicLink;
	} catch (e) {
		showError("Error", "Impersonate user failed: " + e.message);
	}
}

export async function generateMagicLink(orgId, cognitoUserSubId) {

	const payload = {
		orgId,
		cognitoUserSubId,
		baseUrl: getAppBaseUrl()
	};

	return new Promise((resolve, reject) => {
		refreshCredentials().then(() => {
			request.post(`${env.apiGateway.baseUrl}${env.apiGateway.endpoints.admin.ml.generateMagicLink}`)
				.set("Authorization", getJwtToken())
				.send(payload)
				.then((res) => {
					resolve(res.body);
				}).catch(err => {
					reject(err);
				});
		});
	});
}
import _ from "lodash";

const globals = {
	stage: "prod",
	hostUrl: "https://app.ambifi.com",
	pingUrl: "https://api.ambifi.com/ping",
	assetsUrl: "https://assets.ambifi.com",
	zoidComponentsUrl: "https://app.ambifi.com/zoidComponents",
	learningOrgId: "57c412f1-83a5-11e8-a392-061714f61e3e",
	appCapabilities: {
		views: {
			myChecklists: {
				filterVisible: true,
				filterGenresVisible: true,
				makePublic: false
			},
			showDetailedStatus: {
				modal: {
					editVisible: true
				}
			},
			nav: {
				homeVisible: true
			},
			player: {
				procedure: {
					autoSelectOnScroll: false,
					focusInputOnSelect: true
				}
			}
		},
		editor: {
			showModeTextAlign: true
		},
		showMode: true,
		mediaUploadPhotosOnly: false,
		barcode: true,
		multiSelect: true,
		publications: true,
		assignments: true,
		translations: false,
		markupImage: true,
		generateTtsAudio: true,
		generateVideo: true
	},
	stripe: {
		key: "pk_live_sa2GpJNU1qtWv5tw6NMcovb9"
	},
	jsreports: {
		url: "https://miralouaero.jsreportonline.net",
		auth: "Basic amVmZi5ib25hc3NvQG1pcmFsb3VhZXJvLmNvbTpTaXhGb3VyNzJHb2xm"
	},
	cognito: {
		userPoolId: "us-east-1_ROCoQpdol",
		clientId: "1rcviqadbkk963benln73ucs6f",
		identityPoolId: "us-east-1:49d5adf8-61d3-4ddb-927b-c522b6458116",
		loginContext: "cognito-idp.us-east-1.amazonaws.com/us-east-1_ROCoQpdol"
	},
	aws: {
		defaultRegion: "us-east-1"
	},
	s3: {
		aiBucket: "ai.ambifi.com",
		contentBucket: "content.ambifi.com",
		converterBucket: "converter.ambifi.com",
		assetsBucket: "assets-ambifi-com",
		athenaResultsBucket: "prod-athena-query-results-ambifi-com",
		ambiFiLearningKey: "afd4947f-40dc-4b7c-a10f-72a7c61ccb00",
		ambiFiLearningContentStructure: "b672d73d-1760-43e4-862f-031e9ba6f2ef",
		userUploadBucket: "upload-ambifi-com",
		tmpCredentialsBucket: "temp-credentials.ambifi.com",
		contentBucketPrefix: "https://s3.amazonaws.com/content.ambifi.com/",
		errorReportsBucket: "error-reports.ambifi.com"
	},
	mysql: {
		historyTable: "history"
	},
	routes: {
		login: "/login",
		forgotPassword: "/forgotPassword",
		verify: "/verify",
		devices: "/devices"
	},
	artifacts: {
		inventoryFileName: "experiences.json"
	},
	algolia: {
		applicationId: "A6ZL85XHSB",
		apiKey: "ce7db63af9f98709db061fc8c6427cc2",
		apiKey2: "1d3430002be29a0a2b9d09230a2bfb84",
		index: "prod_AmbiFi",
		indexHistory: "prod_HISTORY",
		historyIndexSettings: {
			attributesForFaceting: [
				"searchable(name)",
				"searchable(displayName)",
				"completionStateString",
				"activityResult",
				"workflowStatusString",
				"workflowSubStatusString",
				"searchable(genre)",
				"searchable(tags)",
				"createdDateTime",
				"lastUpdatedDateTime",
				"activityId",
				"identityId",
				"dueDate"
			],
			ranking: [
				"desc(rankingScore)",
				"asc(dueDate)",
				"desc(lastUpdatedDateTime)",
				"typo",
				"geo",
				"words",
				"filters",
				"proximity",
				"attribute",
				"exact",
				"custom"
			],
			searchableAttributes: [
				"rankingScore",
				"sessionName",
				"id",
				"activityResult",
				"workflowStatusString",
				"workflowSubStatusString",
				"name",
				"displayName",
				"description",
				"itemValues",
				"genre",
				"tags",
				"completionStateString",
				"createdDateTimeIsoString",
				"lastUpdatedDateTimeIsoString",
				"notes",
				"stickers",
				"identityId",
				"dueDateIsoString"
			]
		},
		orgIndexSettings: {
			attributesForFaceting: [
				"genre",
				"tags",
				"name"
			],
			ranking: [
				"desc(rankingScore)",
				"desc(lastUpdatedDateTime)",
				"typo",
				"geo",
				"words",
				"filters",
				"proximity",
				"attribute",
				"exact",
				"custom"
			],
			searchableAttributes: [
				"id",
				"name",
				"displayName",
				"description",
				"itemValues",
				"genre",
				"tags"
			]
		}
	},
	cloudFront: {
		assetUrls: {
			public: "https://public-assets.ambifi.com",
			private: "https://private-assets.ambifi.com"
		},
		fontsUrl: "https://fonts.ambifi.com"
	},
	apiGateway: {
		baseUrl: "https://api.ambifi.com",
		endpoints: {
			jwtOauth2ServerToServer: "/jwtOauth2ServerToServer",
			jwtOauth2Stream: "/jwtOauth2Stream",
			jwtOauth2Dolby: "/jwtOauth2Dolby",
			auth: "/auth",
			generateMagicLink: "/ml/generate-magic-link",
			userInitAuth: "/userInitAuth",
			userInitAuthGetOrgLoginInfo: (orgDomain) => {
				if (!orgDomain) {
					throw new Error("An organization domain is required to get information about the orgs login.");
				}
				return `/userInitAuth/login-config/${orgDomain}`;
			},
			user: {
				init: "/user/init",
				auth: "/user/auth",
				orgInfo: "/user/orgInfo/v2",
				timerTemplates: "/user/timerTemplates",
				inventory: "/user/inventory/v2",
				drive: "/user/drive",
				driveMap: "/user/driveMap",
				contentModels: "/user/contentModels",
				schedules: "/user/schedules"
			},
			orgs: "/orgs",
			members: (orgId) => {
				if (!orgId) {
					throw new Error("An organization id is required to build the members endpoint");
				}
				return `/orgs/${orgId}/members`;
			},
			membersForCompany: "/orgs/members/company",
			acceptOrgInvite: "/orgs/invite/accept",
			orgPreferences: (orgId) => {
				return `/orgs/${orgId}/preferences`;
			},
			orgPreference: (orgId, name) => {
				return `/orgs/${orgId}/preferences/${name}`;
			},
			orgInvite: "/orgs/invite",
			orgCreateInviteLink: "/orgs/createInviteLink",
			orgInviteDelete: (orgId, inviteId) => {
				if (!orgId) {
					throw new Error("An organization id is required to build the delete pending invite endpoint");
				}
				if (!inviteId && !_.isNumber(inviteId)) {
					throw new Error("An inviteid is required to build the delete pending invite endpoint");
				}
				return `/orgs/${orgId}/invite/${inviteId}`;
			},
			editOrgMember: (orgId) => {
				if (!orgId) {
					throw new Error("An organization id is required to build the removeMember endpoint");
				}
				return `/orgs/${orgId}/member/`;
			},
			editCognitoUser: (username) => {
				if (!username) {
					throw new Error("An username is required to build the editCognitoUser endpoint");
				}
				return `/orgs/cognitoUser/${username}`;
			},
			getCurrentUserMembershipInfoEndpoint: "/orgs/member/",
			removeMember: (orgId, memberId) => {
				if (!orgId || !memberId) {
					throw new Error("An organization id and member id are required to build the removeMember endpoint");
				}
				return `/orgs/${orgId}/member/${memberId}`;
			},
			reactivateMember: (orgId, memberId) => {
				if (!orgId || !memberId) {
					throw new Error("An organization id and member id are required to build the removeMember endpoint");
				}
				return `/orgs/${orgId}/member/${memberId}/reactivate`;
			},
			signup: "/signup",
			group: (orgId) => {
				if (!orgId) {
					throw new Error("An organization id is required to build the removeMember endpoint");
				}
				return `/orgs/${orgId}/group`;
			},
			share: "/artifacts/share",
			removeShare: "/artifacts/shares/delete",
			removeShareWithParam: (sharedArtifactId) => {
				if (!sharedArtifactId) {
					throw new Error("A shared artifact id is required to build the removeMember endpoint");
				}
				return `/artifacts/shares/${sharedArtifactId}/delete`;
			},
			updateShareMetadata: (sharedArtifactId) => {
				if (!sharedArtifactId) {
					throw new Error("A shared artifact id is required to build the update metadata endpoint");
				}
				return `/artifacts/shares/${sharedArtifactId}/metaData`;
			},
			updateShareAuthoringGrants: (sharedArtifactId) => {
				if (!sharedArtifactId) {
					throw new Error("A shared artifact id is required to build the update authoring grants endpoint");
				}
				return `/artifacts/shares/${sharedArtifactId}/authoringGrant`;
			},
			getShareAuthoringGrant: (sharedArtifactId) => {
				if (!sharedArtifactId) {
					throw new Error("A shared artifact id is required to get the authoring grants endpoint");
				}
				return `/artifacts/shares/${sharedArtifactId}/hasAuthoringGrant`;
			},
			assembleInventory: "/artifacts/assemble",
			getArtifactShares: (sharedArtifactId) => {
				if (!sharedArtifactId) {
					throw new Error("A shared artifact id is required to get the artifact shares endpoint");
				}
				return `/artifacts/shares/${sharedArtifactId}`;
			},
			printPdf: "/services/printer",
			openAi: {
				chatCompletions: "/services/openai/chat/completions",
				imagesGenerate: "/services/openai/images/generate"
			},
			drive: {
				list: "/services/drive",
				listGuidToResource: "/services/drive/mapofguids",
				createObject: "/services/drive/object",
				moveObject: "/services/drive/object/move",
				copyObject: "/services/drive/object/copy",
				aliasObject: "/services/drive/object/alias",
				deleteObjects: "/services/drive/object/delete",
				getObjectUrl: (objectGuid) => {
					if (!objectGuid) {
						throw new Error("An object guid is required to build the get object URL endpoint");
					}
					return `/services/drive/object/${objectGuid}/url`;
				},
				deleteObject: (objectGuid) => {
					if (!objectGuid) {
						throw new Error("An object guid is required to build the delete object URL endpoint");
					}
					return `/services/drive/object/${objectGuid}`;
				}
			},
			assignments: {
				list: "/services/assignment",
				remove: "/services/assignment/delete",
				getSchedule: "/services/assignment/schedule",
				getScheduleForMemberId: (memberId) => {
					if (!memberId) {
						throw new Error("A memberId is required to build the get member schedule URL endpoint");
					}
					return `/services/assignment/schedule/${memberId}`;
				},
				create: "/services/assignment",
				update: "/services/assignment"
			},
			publications: {
				publish: "/services/publications",
				status: (jobToken) => {
					if (!jobToken) {
						throw new Error("Expecting a jobToken to be provided to the status endpoint");
					}
					return `/services/publications/status/${jobToken}`;
				},
				approval: (jobToken) => {
					if (!jobToken) {
						throw new Error("Expecting a jobToken to be provided to the approval endpoint");
					}
					return `/services/publications/${jobToken}/approval`;
				},
				delete: (jobToken) => {
					if (!jobToken) {
						throw new Error("Expecting a jobToken to be provided to the delete endpoint");
					}
					return `/services/publications/${jobToken}/delete`;
				}
			},
			analytics: {
				users: "/services/analytics/users",
				activities: "/services/analytics/activities",
				activity: (activityId) => {
					if (!activityId) {
						throw new Error("Expecting an activityId to be provided to the analytics endpoint");
					}

					return `/services/analytics/activity/${activityId}`;
				},
				activityInstances: (activityId) => {
					if (!activityId) {
						throw new Error("Expecting an activityId to be provided to the analytics endpoint");
					}

					return `/services/analytics/activity/${activityId}/instances`;
				},
				activityWithInstance: (activityId, instanceId) => {
					if (!activityId) {
						throw new Error("You must provide an activityId");
					}
					if (instanceId !== null && (!_.isString(instanceId) || instanceId === "")) {
						throw new Error("A valid instance id must be provided.");
					}
					return `/services/analytics/activity/${activityId}/instance/${instanceId}`;
				}
			},
			artifacts: {
				activity: (activityId) => {
					if (!activityId) {
						throw new Error("Expecting an activityId to be provided to the artifacts/activity endpoint");
					}
					return `/artifacts/activity/${activityId}`;
				},
				getActivityMetadata: (activityId) => {
					if (!activityId) {
						throw new Error("An activityId is required to build the metadata endpoint");
					}
					return `/artifacts/activity/${activityId}/metaData`;
				},
				transferActivity: (activityId, dstIdentityId) => {
					if (!activityId || !dstIdentityId) {
						throw new Error("Expecting an activityId and identity id to be provided to the artifacts/activity endpoint");
					}
					return `/artifacts/activity/${activityId}/transfer/${dstIdentityId}`;

				},
				import: "/artifacts/import"
			},
			audio: {
				generateTts: {
					generate: "/services/audio/tts",
					preview: "/services/audio/ttsPreview",
					batchSize: 10
				}
			},
			workflow: {
				associateActivityWithWorkflow: (activityId, workflowGuid) => {
					if (!activityId) {
						throw new Error("You must provide an activityId");
					}
					if (workflowGuid !== null && (!_.isString(workflowGuid) || workflowGuid === "")) {
						throw new Error("A valid workflow guid must be provided.");
					}
					return `/artifacts/activity/${activityId}/workflow/${workflowGuid}`;
				},
				associateUsersAndGroupsWithWorkflowStages: (activityId) => {
					return `/artifacts/activity/${activityId}/workflow/participants`;
				},
				transitionActivityToStage: (activityId, stageGuid) => {
					if (!activityId) {
						throw new Error("You must provide an activityId");
					}
					if (!_.isString(stageGuid) || stageGuid === "") {
						throw new Error("A valid stage guid must be provided.");
					}
					return `/artifacts/activity/${activityId}/workflow/stage/${stageGuid}`;
				}
			},
			video: {
				create: "/services/video",
				status: (jobToken) => {
					return `/services/video/status/${jobToken}`;
				}
			},
			streamChat: {
				updateUser: "/services/streamChat/updateUser",
				updateChannelName: "/services/streamChat/updateChannelName",
				disableChannel: "/services/streamChat/disableChannel",
				enableChannel: "/services/streamChat/enableChannel"
			},
			healthStream: {
				learningEvent: "/services/healthStream/learningEvent"
			},
			ambifi: {
				event: "/services/ambifi/event"
			},
			admin: {
				ml: {
					generateMagicLink: "/admin/ml/generate-magic-link"
				}
			}
		}
	},
	apiGatewayAmbiFiApi: {
		baseUrl: "https://oc0jndu7g4.execute-api.us-east-1.amazonaws.com/prod",
		endpoints: {
			instances: "/instances"
		}
	},
	dynamoDb: {
		userHistoryTable: "UserHistoryProd",
		driveTable: "ProdDrive"
	},
	lambda: {
		importCsvFunction: "ambifi-csv-import-lambda-web-prod",
		importFunction: "ambifi-import-lambda-prod",
		importFunctionForOrg: "ambifi-artifact-management-lambda-prod",
		extractHistoryCsvFunction: "ambifi-history-csv-extract-lambda-prod",
		searchIndexFunction: "ambifi-search-index-prod",
		sendEmailFunction: "ambifi-artifact-management-lambda-prod",
		aiAssistant: "aiAssistant-prod"
	},
	config: {
		useFancyAlerts: true,
		defaultPlan: "pro-plan-unlimited"
	},
	defaultColors: [
		{ title: "black", color: "#000000" },
		{ title: "gray", color: "#808080" },
		{ title: "silver", color: "#C0C0C0" },
		{ title: "white", color: "#FFFFFF" },
		{ title: "maroon", color: "#800000" },
		{ title: "red", color: "#FF0000" },
		{ title: "olive", color: "#808000" },
		{ title: "yellow", color: "#FFFF00" },
		{ title: "green", color: "#008000" },
		{ title: "lime", color: "#00FF00" },
		{ title: "teal", color: "#008080" },
		{ title: "aqua", color: "#00FFFF" },
		{ title: "navy", color: "#000080" },
		{ title: "blue", color: "#0000FF" },
		{ title: "purple", color: "#800080" },
		{ title: "fuchshia", color: "#FF00FF" }
	],
	headingColors: [
		{ title: "noteyellow", color: "#FFF8C6" },
		{ title: "lightblue", color: "#ADD8E6" },
		{ title: "lightpink", color: "#FFB6C1" },
		{ title: "lightcyan", color: "#E0FFFF" },
		{ title: "lightgreen", color: "#90EE90" },
		{ title: "lightsalmon", color: "#FFA07A" },
		{ title: "lightskyblue", color: "#87CEFA" },
		{ title: "lightsteelblue", color: "#B0C4DE" },
		{ title: "gold", color: "#FFD700" },
		{ title: "thistle", color: "#D8BFD8" },
		{ title: "powderblue", color: "#B0E0E6" },
		{ title: "khaki", color: "#F0E68C" },
		{ title: "lightgray", color: "#D3D3D3" },
		{ title: "whitesmoke", color: "#F5F5F5" },
		{ title: "white", color: "#FFFFFF" },
		{ title: "transparent", color: "transparent" }
	],
	google: {
		apiKey: "AIzaSyB8F8WiJBwuSPTdj0BDZT-mHbhupgP-IHk"
	},
	weather: {
		url: "https://oc0jndu7g4.execute-api.us-east-1.amazonaws.com/prod/services/weather"
	},
	zoid: {
		components: {
			ambifiPlayerWeb: {
				tag: "ambifi-player-web",
				path: "/ambifiPlayerWeb/index.html"
			}
		}
	}
};

export default globals;
import AWS from "aws-sdk";

import env from "../constants/env";

AWS.config.update({ region: "us-east-1" });

export const getUserPromise = function (identityId) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: "Users",
				Key: { // a map of attribute name to AttributeValue for all primary key attributes
					identityId: identityId
				}
			};
			docClient.get(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};

export const putUserPromise = function (user) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: "Users",
				Item: cleanObj(user)
			};
			docClient.put(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};


export const getUserHistoryPromise = function (identityId) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: env.dynamoDb.userHistoryTable,
				ExpressionAttributeValues: {
					":s": identityId
				},
				KeyConditionExpression: "identityId = :s"
			};
			docClient.query(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};

export const getPrivateSharePromise = function (shareCode) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: "PrivateShares",
				Key: { // a map of attribute name to AttributeValue for all primary key attributes
					shareCode: shareCode
				}
			};
			docClient.get(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};

export const putPrivateSharePromise = function (privateShare) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: "PrivateShares",
				Item: cleanObj(privateShare)
			};
			docClient.put(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};

export const putPrivateShareReplacedPromise = function (privateShareReplaced) {
	return new Promise(
		function (resolve, reject) {
			AWS.config.update({
				region: "us-east-1"
			});

			let docClient = new AWS.DynamoDB.DocumentClient();

			let params = {
				TableName: "PrivateSharesReplaced",
				Item: cleanObj(privateShareReplaced)
			};
			docClient.put(params, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		}
	);
};

function cleanObj(o) {
	if (o) {
		if (typeof o === "boolean") {
			return o;
		}
		else if (typeof o === "number") {
			return o;
		}
		else if (typeof o === "string") {
			return o;
		}
		else if (Array.isArray(o)) {
			let x = [], i = -1, l = o.length, r = 0;
			while (++i < l) {
				if (o[i]) {
					x[r++] = cleanObj(o[i]);
				}

			}
			return x;
		} else if (typeof o === "object") {
			for (const k in o) { o[k] ? o[k] = cleanObj(o[k]) : delete o[k]; } // eslint-disable-line no-unused-vars
			return o;
		} else {
			return "Argument Error - Unknown Item";
		}
	}
}
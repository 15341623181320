import React from "react";
//import Modal from "react-responsive-modal";
import ReactModal from "react-modal";
import { branch } from "baobab-react/higher-order";
import autoBind from "react-autobind";
import _ from "lodash"; import Baobab from "baobab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as actions from "../actions/actions";
import env from "../constants/env";
import PlayerPaths from "../constants/paths/playerPaths";
import webPlayerState from "../state/webPlayerState";
import ambifiWebPlayerConfig from "../constants/webPlayerConfig/default";
import state from "../state/state";
import PlayerZoid from "../components/player/playerZoid";
import AppConstants from "../constants/appConstants";

import PreviewWrapper from "./shared/previewWrapper";

class ChecklistPreviewModal extends React.PureComponent {

	constructor(props) {
		super(props);
		autoBind(this);
		this.webPlayerRef = React.createRef();
		this.initWebPlayerState(props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.previewChecklist.showModal === false && nextProps.previewChecklist.showModal) {
			this.initWebPlayerState(nextProps);
		}
	}

	initWebPlayerState(props) {
		let newWebPlayerState = new Baobab(_.cloneDeep(webPlayerState.get()));
		// newWebPlayerState.set(["player", "readOnly"], true);
		newWebPlayerState.set(["user"], props.user);
		newWebPlayerState.set(["_config"], { showControls: false, showHeaderPanel: true, startInShowMode: true, listViewMode: "list" });
		newWebPlayerState.set(["appPreferences", "checkbarLocation"], "hidden");
		newWebPlayerState.set(["timerTemplates"], _.cloneDeep(state.get(["timerTemplates"])));

		this.newWebPlayerState = newWebPlayerState.get();
	}

	render() {
		if (!this.props.previewChecklist || (this.props.previewChecklist && !this.props.previewChecklist.checklist.hasOwnProperty("id"))) {
			return null;
		}

		let closeButton =
			<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", position: "absolute", right: "10px", top: "12px", width: "32px" }}>
				<div className="custom-player-modal-close-button">
					<FontAwesomeIcon icon="times" onClick={() => this.props.dispatch(actions.closePreviewModal)} />
				</div>
			</div>
		// let modalTitle = "Preview";
		console.log(this.props.previewChecklist.checklist);
		console.log(this.props.selectedActivityId);
		let orgPreferences = {};
		if (this.props.selectedOrg) {
			const { preferences } = this.props.selectedOrg;
			orgPreferences = _.pick(preferences, ["tts-phonetics", "theme", "stylesheet", "fonts", "playlists"]); // Limit this for now to prevent very large props
		}
		const addtlProps = {};
		if (this.props.previewChecklist && _.isNumber(this.props.previewChecklist.session)) {
			addtlProps.key = `cache-key-${this.props.previewChecklist.session}`;
		}

		let activity = this.props.previewChecklist.checklist ? <div style={{ flex: 1 }} className="ambifi-web-player-container status"><PlayerZoid
			activities={[this.props.previewChecklist.checklist]}
			activityId={this.props.previewChecklist.checklist.id}
			config={ambifiWebPlayerConfig}
			env={_.cloneDeep(env)}
			drive={{ guidToResource: this.props?.drive?.guidToResource || {} }}
			state={this.newWebPlayerState}
			onExit={this.handleExit}
			onLoad={() => actions.hideDocumentsSpinner()}
			onLoadError={this.handleOnLoadError}
			orgPreferences={orgPreferences}
			ref={this.webPlayerRef}
			{...addtlProps} /></div> : <div><FontAwesomeIcon
				name='spinner'
				spin /> Loading activity for preview, please wait...</div>;




		const component = <ReactModal style={{ overlay: { zIndex: AppConstants.react.components.reactModal.zIndex, backgroundColor: "#666666" } }} className="custom-player-modal" isOpen={this.props.previewChecklist.showModal} little>
			{/* <h3 style={{ textAlign: "center" }}>{modalTitle}</h3> */}
			<div style={{ display: "flex", height: "100vh", overflow: "auto", flexDirection: "column" }}>
				<PreviewWrapper compact={true}>
					{activity}
				</PreviewWrapper>
			</div>
			{closeButton}
		</ReactModal>;

		return component;
	}
}

export default branch({
	user: ["user"],
	drive: ["appState", "previewChecklist", "drive"],
	player: PlayerPaths.ROOT,
	selectedActivityId: PlayerPaths.SELECTED_ACTIVITY_ID,
	appCapabilities: ["appCapabilities"],
	selectedOrg: ["selectedOrg"],
	previewChecklist: ["appState", "previewChecklist"]
}, ChecklistPreviewModal);
import state from "../state/state";

export function clearSearchItems(tree) {
	tree.set(["appState", "searchItems"], []);
}

export function setSearchItems(items) {
	state.set(["appState", "searchItems"], items);
}

export function addSearchItem(tree, item) {
	tree.select(["appState", "searchItems"]).push(item);
}

export function selectSearchItem(itemId) {
	if (state.get(["appCapabilities", "multiSelect"])) {
		let selected = state.get(["appState", "selectedSearchItems"]);

		// Check if already selected, if so splice, if not push
		const index = selected.indexOf(itemId);
		if (index > -1) {
			selected.splice(index, 1);
		} else {
			selected.push(itemId);
		}

		state.set(["appState", "selectedSearchItems"], Object.assign([], selected));
		console.log("Selected Search Items " + selected);

	}
}

export function resetSelectedSearchItems(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		tree.set(["appState", "selectedSearchItems"], Object.assign([]));
	}
}

export function selectAllSearchItems(tree) {
	if (tree.get(["appCapabilities", "multiSelect"])) {
		const searchItems = tree.get(["appState", "searchItems"]);

		let selected = [];
		for (let i = 0; i < searchItems.length; i++) {
			const searchItem = searchItems[i];

			selected.push(searchItem.id);
		}

		tree.set(["appState", "selectedSearchItems"], Object.assign([], selected));
	}
}

export function selectTemplate(templateSearchHit) {
	const currentTemplate = state.get(["appState", "selectedTemplate"]);
	if (currentTemplate && currentTemplate.id === templateSearchHit.id) {
		state.set(["appState", "selectedTemplate"], null);
	} else {
		state.set(["appState", "selectedTemplate"], templateSearchHit);
	}
}